import { t } from '@app/common/translationsService'
import classnames from 'classnames'
import React from 'react'
import styled from 'styled-components'

const Styled = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colorGrayAccent};
  width: 256px;
  display: flex;
  flex-direction: column;

  .sidebar-header {
    background: ${({ theme }) => theme.colorBackground};
    padding: 20px;
    ${({ theme }) => theme.sectionTitle};
    color: ${({ theme }) => theme.color};
  }

  .sidebar-menu {
    overflow-x: hidden;
    overflow-y: auto;

    ${({ theme }) => theme.scroll(theme)};
    &--item {
      padding: 20px;
      text-align: left;
      color: ${({ theme }) => theme.colorGray};
      border-bottom: 1px solid ${({ theme }) => theme.colorGrayAccent};
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      &:hover {
        background-color: ${({ theme }) => theme.colorLight};
      }

      &.selected {
        background-color: ${({ theme }) => theme.colorLight};
      }
    }
  }
`
export const AppTourSidebar = ({ sections, onSectionClick, selectedSectionIndex }) => {
  return (
    <Styled>
      <div className="sidebar-header">{t('tour_features')}</div>
      <div className="sidebar-menu">
        {sections.map((group, index) => (
          <div
            className={classnames(
              'sidebar-menu--item',
              index === selectedSectionIndex && 'selected'
            )}
            onClick={() => {
              onSectionClick(index)
            }}
          >
            {t(group.title)}
          </div>
        ))}
      </div>
    </Styled>
  )
}
