import styled from 'styled-components'
import Block from '../Block'

export const ContainerItems = styled(Block)`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding-left: ${({ theme }) => theme.sizeSmall};
  padding-right: ${({ theme }) => theme.sizeSmall};

  @supports (display: grid) {
    display: grid;
    grid-column-gap: ${({ theme }) => theme.sizeSmall};
    grid-row-gap: ${({ theme }) => theme.sizeSmall};
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`

export const Image = styled.img`
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  max-height: calc(${({ theme }) => theme.size} * 8);
`

export const ItemStyled = styled(Block)`
  background-color: ${({ selected, theme: { colorLight, colorTransparent } }) =>
    selected ? colorLight : colorTransparent};
  border-radius: ${({ theme }) => theme.radius};
  border: solid ${({ theme: { line, colorAccent } }) => [line, colorAccent]};
  box-shadow: -1px 2px 3px rgba(160, 160, 159, 0.6);
  display: flex;
  flex-basis: 300px;
  flex-direction: column;
  flex: auto;
  height: calc(${({ theme }) => theme.size} * 16);
  justify-content: space-between;
  margin: ${({ theme }) => theme.sizeSmall};
  max-height: calc(${({ theme }) => theme.size} * 16);
  max-width: 300px;
  min-width: 300px;
  overflow: hidden;
  position: relative;

  @supports (display: grid) {
    margin: auto;
    max-width: unset;
  }

  & div {
    a,
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorLight};
  }

  p {
    overflow: hidden;
  }

  .highlighted {
    background-color: ${({ theme }) => theme.color};
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const Badge = styled.div`
  position: absolute;
  top: -15px;
  right: -5px;
  margin-top: 4px;
  text-align: center;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colorGrayLight};
  color: ${({ theme }) => theme.colorGrayLight};
  min-width: 40px;
  padding: 3px 0;
  border-radius: 3px;
  cursor: pointer;
  opacity: 0.85;
`
