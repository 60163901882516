import styled from 'styled-components'
import { Cookie } from '@new-lucentum'

export const StyledCookieBanner = styled(Cookie)`
  position: fixed;
  background-color: rgb(63, 63, 63);
  bottom: 0;
  z-index: 1001;

  > div:first-child > div:first-child > div:first-child {
    display: flex;
    align-items: stretch;
  }

  > div:first-child > div:first-child {
    align-items: center;
    > div:first-child {
      align-items: center;
      div {
        height: 100%;
        :first-child {
          width: auto;
          margin-right: ${({ theme }) => theme.sizeSmall};
          i {
            font-size: 28px;
            height: 83px;
          }
        }
        :nth-child(2) {
          div {
            display: flex;
            gap: ${({ theme }) => theme.sizeSmall};
            button {
              padding: 7px 14px;
              line-height: 14px;
              :hover {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  > div:first-child > div:first-child > div:nth-child(2) > button:first-child {
    i {
      font-size: 28px;
    }
  }

  > div:first-child > div:first-child {
    padding: 14px;
  }

  > * {
    max-width: 960px;
  }
`
