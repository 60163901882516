import styled from 'styled-components'

import Block from '../Block'
import ErrorMessage from '../ErrorMessage'

export const Textfield = styled.div`
  align-items: center;
  display: flex;
  flex: 1 auto;
  position: relative;

  & input {
    border-radius: ${({ theme }) => theme.radius};
    border: ${({ theme }) => `solid ${theme.line} ${theme.colorGray}`};
    box-sizing: border-box;
    display: inline-block;
    font: inherit;
    padding: ${({ theme }) => theme.sizeSmall} ${({ theme }) => theme.size};
    width: 100%;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.color};
      box-shadow: ${({ theme }) => theme.focusShadow};
    }

    &:not(:focus):invalid {
      border-color: ${({ theme }) => theme.colorError};
      box-shadow: ${({ theme }) => theme.focusShadowError};
    }
  }
`

export const Wrapper = styled(Block)`
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  padding-right: 20px;
  justify-content: flex-end;
  button {
    opacity: ${({ showClear }) => (showClear ? 1 : 0)};
    cursor: ${({ showClear }) => (showClear ? 'pointer' : 'initial')};
    padding: 0;
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colorTransparent};
      color: ${({ theme }) => theme.colorGray};
    }
  }

  .clear-button {
    ${({ theme }) => theme.baseText};
    color: ${({ theme }) => theme.colorGrayLight};
    padding-right: 7px;
  }
`

export const Error = styled(ErrorMessage)`
  input:not(:focus):invalid + & {
    display: inline-block;
  }
`
