import {
  TMDSAuthService,
  TMDSGlobalsService,
  TMDSTagsService,
  TMDSToastService,
} from '@app/common/services'
import React, { Fragment } from 'react'
import { LoginRequiredModal } from '@app/common/LoginRequiredModal'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t } from '@app/common/translationsService'
import { arrayUtils } from '@app/common/utils'
import { ROUTES } from '@app/containers/App/routes'
import ModalMobile from '@app/new-lucentum/components/Modal/Modal.mobile'
import { Button, Flex, Grid } from '@new-lucentum'
import { compose, lifecycle, shallowEqual, shouldUpdate, withHandlers, withState } from 'recompose'

import { TagDropdownMenuMobile } from './components/TagDropdownMenu.mobile'
import { StyledModalHeader } from './TagButton.mobile.styles'

const TagButtonMobileComponent = props => {
  const {
    st13List,
    noBadge,
    icon = 'tag',
    borderless,
    isDisabled = false,
    resetOnSubmit = false,
    inheritPosition,
    buttonProps = { isExpanded: false },
    badgeNumber,
    setBadgeNumber,
    preloadedValues,
    setPreloadedValues,
    applyDisabled,
    setApplyDisabled,
    availableTags,
    handleApply,
    title,
    isModalOpen,
    setIsModalOpen,
  } = props

  const {
    alertsConfig: { maxAlertsCreation },
  } = TMDSGlobalsService.get()

  const hasNoSt13 = Array.isArray(st13List) && st13List.length < 1
  const hasMoreSt13ThanAllowed = Array.isArray(st13List) && st13List.length > maxAlertsCreation
  const isHidden = !TMDSAuthService.isLogged || hasNoSt13 || hasMoreSt13ThanAllowed || isDisabled
  const onClick = () => TMDSRouterService.navigate(ROUTES.TAGS)

  const prepareModalTitle = () => {
    return (
      <StyledModalHeader>
        <Flex justify="space-between" flex="1">
          <div className="modal-title">{t('results_button_tags_tooltip_title')}</div>
          <div>
            <Button
              borderless
              data-test-id="alerts-button"
              isBlue={true}
              onClick={onClick}
              title="Go to Tags page"
            />
          </div>
        </Flex>
      </StyledModalHeader>
    )
  }

  const prepareButtonText = () => {
    return badgeNumber ? `${title} (${badgeNumber})` : title
  }

  return !isHidden ? (
    <>
      <Button
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        {prepareButtonText()}
      </Button>

      <ModalMobile
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        showCloseButton={false}
        customTitle={prepareModalTitle()}
      >
        <TagDropdownMenuMobile
          preloadedValues={preloadedValues}
          setPreloadedValues={setPreloadedValues}
          st13List={st13List}
          applyDisabled={applyDisabled}
          setIsModalOpen={setIsModalOpen}
          setApplyDisabled={setApplyDisabled}
          setBadgeNumber={setBadgeNumber}
          resetOnSubmit={resetOnSubmit}
          inheritPosition={inheritPosition}
          noBadge={noBadge}
          availableTags={availableTags}
          handleApply={handleApply}
          borderless={borderless}
          buttonProps={buttonProps}
          icon={icon}
        />
      </ModalMobile>
    </>
  ) : (
    <Fragment>
      <Button
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        {prepareButtonText()}
      </Button>
      {isModalOpen && (
        <LoginRequiredModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          title={`<b>${t('results_button_tags_tooltip_title')}</b> ${t(
            'mobile_functionality_loggedIn_users'
          )}`}
        />
      )}
    </Fragment>
  )
}

export const TagButtonMobile = compose(
  withState('applyDisabled', 'setApplyDisabled', true),
  withState('preloadedValues', 'setPreloadedValues', false),
  withState('availableTags', 'setAvailableTags', []),
  withState('badgeNumber', 'setBadgeNumber', 0),
  withState('isModalOpen', 'setIsModalOpen', false),
  withHandlers({
    handleExternalValues: props => () => {
      const {
        externalValues,
        noBadge,
        setBadgeNumber,
        setPreloadedValues,
        setAvailableTags,
        setApplyDisabled,
      } = props
      if (TMDSAuthService.isLogged) {
        const triggeredValues = externalValues.appliedTags.length
        triggeredValues > 0 && setApplyDisabled(false)

        if (!noBadge) {
          setBadgeNumber(triggeredValues)
        }
        setPreloadedValues(externalValues.appliedTags)
        setAvailableTags(externalValues.availableTags)
      }
    },
    handlePreloadedValues: props => () => {
      const { st13List, noBadge } = props
      const { setPreloadedValues, setBadgeNumber, setAvailableTags, setApplyDisabled } = props
      if (TMDSAuthService.isLogged && Array.isArray(st13List)) {
        if (st13List.length === 1) {
          Promise.all([
            TMDSTagsService.getAppliedTagGroups(st13List),
            TMDSTagsService.getAvailableTagGroups(),
          ]).then(([{ tags: appliedTags }, { tags: availableTags }]) => {
            const triggeredValues = appliedTags.length
            triggeredValues > 0 && setApplyDisabled(false)
            setPreloadedValues(appliedTags)
            setAvailableTags(availableTags)
            if (!noBadge) {
              setBadgeNumber(triggeredValues)
            }
          })
        } else {
          TMDSTagsService.getAvailableTagGroups().then(({ tags: availableTags }) => {
            setAvailableTags(availableTags)
          })
        }
      }
    },
    handleApply: props => selectedTags => {
      const {
        setPreloadedValues,
        setAvailableTags,
        setBadgeNumber,
        setApplyDisabled,
        availableTags,
        st13List,
        onSetTagsSuccess,
        noBadge,
        preloadedValues,
        resetOnSubmit = false,
        isBulk = false,
        setIsModalOpen,
      } = props

      const preparedTags = selectedTags.map(selectedTag => {
        return selectedTag.className ? { value: selectedTag.value } : selectedTag
      })

      const applyPromise = TMDSTagsService.setTags(preparedTags, st13List, isBulk)
      applyPromise.then(response => {
        const { tags } = response
        const triggeredValues = tags.length
        const afterApplyValues = resetOnSubmit ? [] : tags
        setPreloadedValues(afterApplyValues)
        const newAvailableTags = arrayUtils.mergeUnique('id', availableTags, tags)
        setAvailableTags(newAvailableTags)
        triggeredValues && setApplyDisabled(false)
        setIsModalOpen(false)
        onSetTagsSuccess && onSetTagsSuccess(tags, st13List)
        if (!noBadge) {
          setBadgeNumber(selectedTags.length)
        }

        const toastMessage = triggeredValues > 0 ? 'tag_selected_edited' : 'tags_removed'

        if (preloadedValues.length > 0) {
          TMDSToastService.showToast(t(toastMessage))
        }
      })
      return applyPromise
    },
  }),
  lifecycle({
    componentDidMount() {
      const { preload, externalValues } = this.props
      const { handlePreloadedValues, handleExternalValues } = this.props
      preload && !externalValues && handlePreloadedValues()
      externalValues && handleExternalValues()
    },
    componentDidUpdate(prevProps) {
      const { externalValues } = this.props
      const { handleExternalValues } = this.props
      if (externalValues && !shallowEqual(prevProps.externalValues, externalValues)) {
        handleExternalValues()
      }
    },
  }),
  shouldUpdate((props, nextProps) => {
    return (
      !shallowEqual(props.buttonProps, nextProps.buttonProps) ||
      !shallowEqual(props.st13List, nextProps.st13List) ||
      !shallowEqual(props.preloadedValues, nextProps.preloadedValues) ||
      !shallowEqual(props.availableTags, nextProps.availableTags) ||
      !shallowEqual(props.externalValues, nextProps.externalValues) ||
      props.badgeNumber !== nextProps.badgeNumber ||
      props.isModalOpen !== nextProps.isModalOpen
    )
  })
)(TagButtonMobileComponent)
