import React from 'react'
import { Icon, Modal } from '@new-lucentum'
import styled from 'styled-components'
import { MediaPlayer } from '@app/components/MediaViewer/MediaPlayer'
import { ModelViewer } from '@app/components/ModelViewer'

const Styled = styled.div`
  margin-top: 50px;
  .trigger-icon {
    color: ${({ theme }) => theme.color};
    font-size: 50px;
    cursor: pointer;
  }
`

export function MultimediaModal({ data, modalTitle }) {
  const [showModal, setShowModal] = React.useState(false)
  const videos = data.video
  const sounds = data.sound
  const threeDModels = data.threeDModel
  return (
    <Styled>
      <Icon play className="trigger-icon" onClick={() => setShowModal(!showModal)} />
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} title={modalTitle}>
        {videos && videos.map((video, index) => <MediaPlayer src={video.uri} key={index} />)}
        {sounds &&
          sounds.map((audio, index) => (
            <MediaPlayer sourceType="audio" src={audio.uri} key={index} />
          ))}
        {threeDModels &&
          threeDModels.map((model, index) => (
            <ModelViewer src={model.uri} format={model.fileFormat} key={index} />
          ))}
      </Modal>
    </Styled>
  )
}
