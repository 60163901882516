import { DESIGNS_FIELDS, OFFICE_FIELDS } from '@app/common/dataManagers/types'

const getDesignAbsolutePath = relativePath => {
  return `${DESIGNS_FIELDS.KEY}.${relativePath}`
}

const getOfficeAbsolutePath = relativePath => {
  return `${OFFICE_FIELDS.KEY}.${relativePath}`
}

export const officeFields = [
  {
    label: 'registrationofficecode.key',
    path: getDesignAbsolutePath(DESIGNS_FIELDS.registrationOfficeCode),
  },
  {
    label: 'office.last.updated.key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.OFFICE_LAST_UPDATE_DATE),
    isDate: true,
  },
  {
    label: 'number.of.designs.key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.OFFICE_NUMBER_OF_DESIGNS),
  },
  {
    label: 'tm_details_application_case_law_key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.APPLICATION_CASE_LAW_URL),
    type: 'link',
    testId: 'office-view-application-case-law-link',
  },
  {
    label: 'tm_details_case_law_key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.OFFICE_CASE_LAW_URL),
    type: 'link',
    testId: 'office-view-case-law-link',
  },
  {
    label: 'results_details_office_origin_key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.DESIGN_OFFICE_URL),
    type: 'link',
    testId: 'office-view-design-link',
  },
  {
    label: '',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.OFFICE_LOGO_URL),
    type: 'image',
    testId: 'office-image',
  },
]

export const officeFieldsMobile = [
  {
    label: 'registrationofficecode.key',
    path: getDesignAbsolutePath(DESIGNS_FIELDS.registrationOfficeCode),
  },
  {
    label: 'office.last.updated.key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.OFFICE_LAST_UPDATE_DATE),
    isDate: true,
  },
  {
    label: 'number.of.designs.key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.OFFICE_NUMBER_OF_DESIGNS),
  },
  {
    label: 'tm_details_case_law_key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.OFFICE_CASE_LAW_URL),
    type: 'link',
    testId: 'office-view-case-law-link',
  },
  {
    label: 'results_details_office_origin_key',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.DESIGN_OFFICE_URL),
    type: 'link',
    testId: 'office-view-design-link',
  },
  {
    label: '',
    path: getOfficeAbsolutePath(OFFICE_FIELDS.OFFICE_LOGO_URL),
    type: 'image',
    testId: 'office-image',
  },
]
