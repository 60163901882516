import TMDSContextService from '@app/common/tmdsContextService'
import { FIELDS } from './fields'

export const getFieldsetsLabelsByContext = () => {
  return TMDSContextService.isTmview() ? FIELDSETS_LABELS_TMVIEW : FIELDSETS_LABELS_DSVIEW
}

const FIELDSETS_LABELS_COMMON = {
  [FIELDS.APPLICATION_NUMBER]: 'applicationnumber.key',
  [FIELDS.TERRITORIES]: 'advanced_search_territories_key',
  [FIELDS.OFFICES]: 'offices.key',
  [FIELDS.REGISTRATION_DATE]: 'registration.date.key',
}

const FIELDSETS_LABELS_TMVIEW = {
  ...FIELDSETS_LABELS_COMMON,
  [FIELDS.APPLICANT_NAME]: 'advanced.search.applicant.name.key',
  [FIELDS.APPLICATION_DATE]: 'advanced.search.application.date.key',
  [FIELDS.CURRENTLY_OPPOSABLE]: 'advanced_search_other_key',
  [FIELDS.GOODS_AND_SERVICES]: 'tm_details_section_goods_and_services',
  [FIELDS.REGISTRATION_NUMBER]: 'registrationnumber.key',
  [FIELDS.TRADEMARK_STATUS]: 'advanced.search.trade.mark.status.key',
  [FIELDS.TRADEMARK_TYPE]: 'advanced.search.trade.mark.type.key',
  [FIELDS.VIENNA_CODE]: 'advanced.search.vienna.code.key',
}

const FIELDSETS_LABELS_DSVIEW = {
  ...FIELDSETS_LABELS_COMMON,
  [FIELDS.DESIGNER_NAME]: 'designer.name.key',
  [FIELDS.DESIGN_NUMBER]: 'design.number.as.key',
  [FIELDS.DESIGN_STATUS]: 'design.status.key',
  [FIELDS.EXHIBITION_PRIORITY]: 'exhibition.priority.key',
  [FIELDS.LOCARNO]: 'locarno.classification.key',
  [FIELDS.OWNER_NAME]: 'owner.name.key',
  [FIELDS.PREVIOUS_OWNER_NAME]: 'previous.applicant.details.key',
  [FIELDS.PRIORITY_COUNTRY]: 'priority.country.key',
  [FIELDS.PRIORITY_NUMBER]: 'advanced_search_priority_number_key',
  [FIELDS.REPRESENTATIVE_NAME]: 'representatives.name.key',
  [FIELDS.VERBAL_ELEMENT]: 'verbal.element.key',
  [FIELDS.EFFECTIVE_DATE]: 'effective.date.key',
  [FIELDS.END_DEFERMENT_DATE]: 'deferment.expiration.date.key',
  [FIELDS.EXPIRY_DATE]: 'expiry.date.key',
  [FIELDS.FILING_DATE]: 'filing.date.as.key',
  [FIELDS.PRIORITY_DATE]: 'priority.date.key',
  [FIELDS.PUBLICATION_DATE]: 'publication.date.key',
}
