import styled from 'styled-components'
import { Button } from '@new-lucentum'
export const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  i {
    font-size: ${({ theme }) => theme.fontSizeBig};
  }
  span {
    white-space: normal;
    font-size: ${({ theme }) => theme.fontSizeSmaller};
    text-align: left;
  }
`
