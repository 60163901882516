import { TMDSGlobalsService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { Flex, Icon } from '@new-lucentum'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import { OfficesSupported } from './OfficesSupported'
import { StyledPlaceholder } from './styles'

const PlaceholderComponent = ({ onDrop, errorMessage }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const {
    imageSearchConf: { filesConf },
  } = TMDSGlobalsService.get()
  const fileTypes = Object.keys(filesConf)
  return (
    <StyledPlaceholder {...getRootProps()}>
      <input {...getInputProps()} className="placeholder-input" />
      <div className="placeholder-wrapper">
        <div className="background-icon">
          <Icon similarSearch />
        </div>
        <div className="drag-description">
          <span className="drag-text">
            <b>{t('image.search.drag.drop.instructions')}</b>{' '}
          </span>
          <span className="file-types">
            {fileTypes.map((fileType, index) => (
              <span key={index}>{fileType}</span>
            ))}
          </span>
          <div className="beta-message">
            <OfficesSupported />
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      </div>
      {isDragActive && (
        <div className="drag-active">
          <Flex alignItems="center" justify="center" fullHeight>
            <Icon camera />
            <span>{t('image.drop.file')}</span>
          </Flex>
        </div>
      )}
    </StyledPlaceholder>
  )
}

export const Placeholder = PlaceholderComponent
