import React from 'react'
import ContentLoader from 'react-content-loader'

export default ({ height = 42 }) => {
  return (
    <div style={{ width: '100%', height, display: 'flex', background: '#ffffff' }}>
      <ContentLoader
        height={height}
        speed={1}
        padding={'0 10px 0 10px'}
        primaryColor="#d4d4d4"
        secondaryColor="#ecebeb"
      >
        <rect x="15" y="7" rx="5" ry="5" width="93%" height="30" />
      </ContentLoader>
    </div>
  )
}
