import { DESIGNS_FIELDS } from '@app/common/dataManagers/types'
export const datesFields = [
  {
    label: 'applicationdate.key',
    path: DESIGNS_FIELDS.applicationDate,
    isDate: true,
  },
  {
    label: 'application.publication.date.key',
    path: DESIGNS_FIELDS.applicationPublicationDate,
    isDate: true,
  },
  {
    label: 'registration.date.key',
    path: DESIGNS_FIELDS.registrationDate,
    isDate: true,
  },
  { label: 'publication.date.key', path: DESIGNS_FIELDS.publicationDate, isDate: true },
  {
    label: 'deferment.expiration.date.key',
    path: DESIGNS_FIELDS.endOfDefermentDate,
    isDate: true,
  },
  { label: 'expiry.date.key', path: DESIGNS_FIELDS.expiryDate, isDate: true },
  { label: 'effective.date.key', path: DESIGNS_FIELDS.effectiveDate, isDate: true },
  { label: 'designcurrentstatusdate.key', path: DESIGNS_FIELDS.currentStatusDate, isDate: true },
]
