export class PRIORITY_FIELDS {
  static COUNTRY_CODE = 'countryCode'
  static PRIORITY_NUMBER = 'priorityNumber'
  static PRIORITY_DATE = 'priorityDate'
  static PARTIAL_PRIORITY = 'partialPriority'
  static PARTIAL_PRIORITY_INDICATOR = 'partialPriorityIndicator'
  static STATUS_CODE = 'statusCode'
  static STATUS_CODE_TRANSLATED = 'statusCodeTranslated'
  static COMMENTS = 'comments'
}
