import React from 'react'
import { Box } from './Box'
import { ShowMore } from './ShowMore'
import { Recordal } from './components'
import { t } from '@app/common/translationsService'
import { Separator } from './styles'
import { Block } from '@new-lucentum'
import { totalBoxEntries } from './utils'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'
import { useSelector } from 'react-redux'

const BoxRenewalsComponent = ({ inidCodes, data = {}, officeExceptionMessage, ...props }) => {
  const [showMore, setShowMore] = React.useState(false)
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)
  const toggleShowMore = React.useCallback(() => setShowMore(!showMore), [showMore])
  React.useEffect(
    () => {
      const hasItemsToShow = !!data.length
      hasItemsToShow !== props.hasItemsToShow &&
        props.setHasItemsToShow('boxRenewals', hasItemsToShow)
    },
    [data, showOriginalLanguageAttributes]
  )

  return (
    <Box
      boxId="boxRenewals"
      title={`${t('tm.details.left.menu.renewals.key')} ${totalBoxEntries(data)}`}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-renewals"
      officeExceptionMessage={officeExceptionMessage}
    >
      {!!data.length && (
        <Recordal
          recordal={data[0]}
          excerpt={!showMore}
          showOriginalLanguageAttributes={showOriginalLanguageAttributes}
        />
      )}
      {showMore &&
        data.map(
          (recordal, index) =>
            index > 0 && (
              <Block key={`recordal_${index}`}>
                <Separator />
                <Recordal
                  recordal={recordal}
                  showOriginalLanguageAttributes={showOriginalLanguageAttributes}
                />
              </Block>
            )
        )}
      {data.length > 0 && (
        <ShowMore
          testId="boxRenewals-show-more"
          showMore={showMore}
          showMoreHandle={toggleShowMore}
        />
      )}
    </Box>
  )
}

export const BoxRenewals = BoxRenewalsComponent
