const defaultState = {
  recaptchaResponseKey: null,
  text: '',
  email: '',
  files: [],
  fileValue: '',
  officeId: 0,
  sendToEmail: '',
  notification: {
    show: false,
    error: false,
    messageLiteral: '',
  },
  officeSupportedLang: [],
  showFeedBackModal: false,
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'CHANGE_ENQUIRY_TYPE_OPTION':
      return {
        ...state,
        officeId: payload.officeId,
        officeSupportedLang: payload.supportedLanguages,
        sendToEmail: payload.sendToEmail,
      }
    case 'CHANGE_FEEDBACK_TEXTAREA':
      return {
        ...state,
        text: payload.text,
      }
    case 'CHANGE_FEEDBACK_EMAIL':
      return {
        ...state,
        email: payload.email,
      }
    case 'SEND_FEEDBACK_FULFILLED':
    case 'RESET_FEEDBACK_FORM':
      return defaultState
    case 'SEND_FEEDBACK_MOBILE_FULFILLED':
    case 'RESET_FEEDBACK_FORM_FROM_MOBILE':
      return {
        ...defaultState,
        showFeedBackModal: true,
      }
    case 'CLOSE_ERROR_NOTIFICATION':
      return {
        ...state,
        notification: {
          show: false,
          error: false,
          messageLiteral: '',
        },
      }
    case 'CHANGE_FEEDBACK_FILE':
      return {
        ...state,
        files: payload.files,
        fileValue: payload.fileValue,
      }
    case 'INVALID_FEEDBACK':
      return {
        ...state,
        notification: {
          show: true,
          error: true,
          messageLiteral: payload.errorMessage,
        },
      }
    case 'SEND_FEEDBACK_REJECTED':
    case 'SEND_FEEDBACK_MOBILE_REJECTED':
      return {
        ...state,
        notification: {
          show: true,
          error: true,
          messageLiteral: 'user.form.error.key',
        },
      }
    case 'CHANGE_FEEDBACK_CAPTCHA':
      return {
        ...state,
        recaptchaResponseKey: payload.recaptchaResponseKey,
      }
    case 'OPEN_FEEDBACK_MODAL':
      return {
        ...state,
        showFeedBackModal: true,
      }
    case 'CLOSE_FEEDBACK_MODAL':
      return {
        ...state,
        showFeedBackModal: false,
        notification: {
          show: false,
          error: false,
          messageLiteral: '',
        },
      }
    default:
      return state
  }
}
