const loadTrackingScript = trackingScriptUrl => {
  const scriptPromise = new Promise((resolve, reject) => {
    const script = document.createElement('script')
    const firstScript = document.getElementsByTagName('script')[0]
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.src = trackingScriptUrl
    firstScript.parentNode.insertBefore(script, firstScript)
    script.onload = resolve
    script.onerror = reject
  })
  return scriptPromise
}

export const setupTrackingScript = async (settings, doNotTrack = false) => {
  window._paq = window._paq || []
  let instance = window._paq
  const scriptUrl = `${settings.trackerURL}piwik.js`
  const trackerUrl = `${settings.trackerURL}piwik.php`

  instance.push(['setCookieDomain', settings.cookieDomain])

  instance.push(['setDoNotTrack', doNotTrack])
  if (doNotTrack) {
    instance.push(['disableCookies'])
  }

  instance.push(['alwaysUseSendBeacon'])
  instance.push([
    'setDocumentTitle',
    '404/URL = ' +
      encodeURIComponent(document.location.pathname + document.location.search) +
      '/From = ' +
      encodeURIComponent(document.referrer),
  ])
  instance.push(['trackPageView'])
  instance.push(['setTrackerUrl', trackerUrl])
  instance.push(['setSiteId', settings.siteId])
  instance.push(['enableHeartBeatTimer'])
  instance.push(['enableLinkTracking'])
  try {
    await loadTrackingScript(scriptUrl)
    return true
  } catch (error) {
    console.error('Matomo could not be loaded')
    return false
  }
}

export const prepareActionFromObject = object => {
  return Object.entries(object)
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ')
}

export const prepareCleanActionFromObject = object => {
  return Object.entries(object)
    .filter(([key, value]) => (Array.isArray(value) ? value.length > 0 : !!value))
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ')
}

export const prepareActionFromArray = (changedFilters, initialFilters, storedFilters) => {
  const filtersToCheck = storedFilters ? storedFilters : initialFilters

  return filtersToCheck
    ?.filter(filter => {
      const filterKey = filter.filter
      const initialFilter = initialFilters.find(f => f.filter === filterKey)
      const currentFilter = changedFilters.find(f => f.filter === filterKey)
      const storedFilter = storedFilters
        ? storedFilters.find(f => f.filter === filterKey)
        : undefined

      const initialShown = initialFilter ? initialFilter.shown : undefined
      const currentShown = currentFilter ? currentFilter.shown : undefined
      const storedShown = storedFilter ? storedFilter.shown : undefined

      return storedFilters ? currentShown !== storedShown : currentShown !== initialShown
    })
    ?.map(filter => filter.filter)
    ?.join(', ')
}

export const getFullSearchCriteriaValue = val => {
  let result
  switch (val) {
    case 'C':
      result = 'Contains'
      break
    case 'W':
      result = 'Word'
      break
    case 'B':
      result = 'Begins with'
      break
    case 'E':
      result = 'Ends with'
      break
    case 'F':
      result = 'Fuzzy search'
      break
    case 'I':
      result = 'Is'
      break
    default:
      result = val
  }
  return result
}
