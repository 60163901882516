import {
  authenticateCertificate,
  getCertificateStatus,
  getContactDetails,
  setCertificateType,
  toggleRequestCertificateModal,
} from '@app/actions/certificate'
import React from 'react'
import { useDispatch } from 'react-redux'

import { CertificatesItemContainer, Description, Label } from '../CertificatesButton.styles'
import { CERTIFICATE_TYPES } from '../constants'
import CertificateFileUpload from './CertificateFileUpload'

const CertificatesItem = ({ label, description, type, st13 }) => {
  const dispatch = useDispatch()
  const isAuthenticate = type === CERTIFICATE_TYPES.AUTHENTICATE

  const toggleRequestHandler = () => {
    dispatch(toggleRequestCertificateModal())

    if (!isAuthenticate) {
      const certificateType = dispatch(setCertificateType(type)).payload
      dispatch(getCertificateStatus({ st13, certificateType }))
      dispatch(getContactDetails())
    }

    if (isAuthenticate) {
      const fileInput = document.getElementById('fileInput')
      if (fileInput) {
        fileInput.click()
      }
    }
  }

  const handleFileUpload = event => {
    const selectedFile = event.target.files[0]

    if (selectedFile) {
      const body = new FormData()
      body.append('certificate', selectedFile)

      return dispatch(authenticateCertificate(st13, body))
    }
  }

  return (
    <CertificatesItemContainer onClick={toggleRequestHandler}>
      <Label>{label}</Label>
      <Description>{description}</Description>
      {isAuthenticate && <CertificateFileUpload onChange={handleFileUpload} />}
    </CertificatesItemContainer>
  )
}

export default CertificatesItem
