import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 0 auto;
  max-width: ${({ theme, maxWidth }) => (maxWidth ? 'max-width: 100%' : theme.maxWidth)};
  padding: 0;
  width: 100%;
`

export default ({ children, className, ...props }) => (
  <Content className={className} {...props}>
    {children}
  </Content>
)
