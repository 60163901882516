const GET_CERTIFICATE_TYPES = 'GET_CERTIFICATE_TYPES'
const GET_CERTIFICATE_STATUS = 'GET_CERTIFICATE_STATUS'
const RESET_CERTIFICATE_STATUS = 'RESET_CERTIFICATE_STATUS'
const REQUEST_CERTIFICATE = 'REQUEST_CERTIFICATE'
const TOGGLE_REQUEST_CERTIFICATE_MODAL = 'TOGGLE_REQUEST_CERTIFICATE_MODAL'
const USER_PREFERENCES_SUBMITTED = 'USER_PREFERENCES_SUBMITTED'
const RESET_PROCESS = 'RESET_PROCESS'
const CHANGE_CERTIFICATE_CAPTCHA = 'CHANGE_CERTIFICATE_CAPTCHA'
const SET_CERTIFICATE_TYPE = 'SET_CERTIFICATE_TYPE'
const DOWNLOAD_CERTIFICATE = 'DOWNLOAD_CERTIFICATE'
const GET_CONTACT_DETAILS = 'GET_CONTACT_DETAILS'

export const CERTIFICATE_TYPES = {
  GET_CERTIFICATE_TYPES,
  GET_CERTIFICATE_STATUS,
  RESET_CERTIFICATE_STATUS,
  REQUEST_CERTIFICATE,
  TOGGLE_REQUEST_CERTIFICATE_MODAL,
  USER_PREFERENCES_SUBMITTED,
  RESET_PROCESS,
  CHANGE_CERTIFICATE_CAPTCHA,
  SET_CERTIFICATE_TYPE,
  DOWNLOAD_CERTIFICATE,
  GET_CONTACT_DETAILS,
}
