import { Icon } from '@new-lucentum'
import React from 'react'
import BooleanSelect, { components } from 'react-select-boolean'

import { Circle, colour } from './styles'

const operators = [
  { value: 'OR', label: 'OR' },
  { value: 'AND', label: 'AND' },
  { value: 'NOT', label: 'NOT' },
]

const styles = {
  control: (base, state) => ({
    ...colour[state.getValue()[0].value],
    borderBottomLeftRadius: state.selectProps.menuOpen ? 0 : 10,
    borderBottomRightRadius: state.selectProps.menuOpen ? 0 : 10,
    borderColor: state.selectProps.menuOpen ? '#395676' : 'transparent',
    borderStyle: 'solid',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderWidth: 1,
    color: 'white',
    display: 'flex',
    fontSize: 14,
    height: 20,
    justifyContent: 'center',
    overflow: 'visible',
    width: 65,
  }),

  dropdownIndicator: base => ({
    ...base,
    alignItems: 'center',
    bottom: 0,
    color: 'white',
    position: 'absolute',
    right: 1,
    paddingRight: 12,
    top: 0,
    fontSize: 11,
  }),

  menu: (base, state) => ({
    ...base,
    border: 'solid 1px #395676',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: 'unset',
    marginTop: '0 !important',
    overflow: 'hidden',
    top: '99%',
  }),

  menuList: base => ({
    ...base,
    padding: 0,
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#cccccc' : 'transparent',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    paddingLeft: 7,
  }),

  singleValue: base => ({
    ...base,
    color: 'white',
  }),

  valueContainer: base => ({
    ...base,
    display: 'block',
    paddingLeft: 9,
  }),
  placeholder: base => ({
    ...base,
    color: '#aaa',
  }),
}

const SingleValue = ({ data: { label }, ...props }) => (
  <components.SingleValue {...props}>{label}</components.SingleValue>
)

const Option = props => (
  <components.Option {...props}>
    <Circle boolean={props.value} />
    {props.label}
  </components.Option>
)

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon chevronDown />
  </components.DropdownIndicator>
)

const IndicatorSeparator = false

export default class extends React.Component {
  state = { menuOpen: false }
  render() {
    return (
      <BooleanSelect
        value={{ value: this.props.boolean, label: this.props.boolean }}
        components={{ SingleValue, IndicatorSeparator, Option, DropdownIndicator }}
        formatGroupLabel={false}
        isClearable={false}
        isSearchable={false}
        options={operators}
        styles={styles}
        menuOpen={this.state.menuOpen}
        onMenuOpen={e => this.setState({ menuOpen: true })}
        onMenuClose={e => this.setState({ menuOpen: false })}
        {...this.props}
        classNamePrefix={'react-select-comp-boolean'}
      />
    )
  }
}
