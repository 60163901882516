import React from 'react'
import { compose, lifecycle, withState, withHandlers } from 'recompose'
import { includeinidCodesInLabels, formatData, hasItemsToShowInArray } from '@app/common/utilities'
import { Box } from './Box'
import { DataList } from './DataItem'
import { ShowMore } from './ShowMore'
import { DataRow } from './styles'
import { totalBoxEntries } from './utils'

const SHOWN_ROWS_LIMIT = 1
const enhance = compose(
  withState('showMore', 'setShowMore', false),
  withHandlers({
    formatedData: ({ literals, inidCodes }) => dataRow => {
      const detailRow = (literalKey, dataKey, dataRow, inidCode, isDate) => ({
        inidCode: inidCode || dataKey,
        label: literals[literalKey],
        data: formatData(dataRow[dataKey], isDate),
      })
      return includeinidCodesInLabels(inidCodes, [
        detailRow(literals.tm_details_label_kindOfRecord, 'kind', dataRow),
        detailRow('tm.details.label.recordIdentifier.key', 'identifier', dataRow),
        detailRow(
          literals.tm_details_label_recordCurrentStatusCode,
          'recordCurrentStatusCode',
          dataRow
        ),
        detailRow(
          literals.tm_details_label_recordCurrentStatusDate,
          'recordCurrentStatusDate',
          dataRow,
          null,
          true
        ),
        detailRow(
          'tm.details.label.publicationDate.key',
          'recordPublicationDate',
          dataRow,
          null,
          true
        ),
        detailRow('tm.details.label.recordal.factdecision.key', 'factDecisionText', dataRow),
        detailRow(
          literals.tm_details_label_recordEffectiveDate,
          'recordEffectiveDate',
          dataRow,
          null,
          true
        ),
        detailRow('tm.details.label.RecordFilingDate.key', 'recordFilingDate', dataRow, null, true),
        detailRow(
          'tm.details.label.RecordInterestedOfficeCode.key',
          'recordInterestedOfficeCode',
          dataRow
        ),
        detailRow(
          'tm.details.label.RecordNotificationDate.key',
          'recordNotificationDate',
          dataRow,
          null,
          true
        ),
        detailRow(
          'tm.details.label.RecordUseIntentCountryCode.key',
          'recordUseIntentCountryCode',
          dataRow
        ),
        detailRow(
          'tm.details.label.oppositionPeriodStartDate.key',
          'recordOppositionPeriodStartDate',
          dataRow,
          null,
          true
        ),
        detailRow(
          'tm.details.label.oppositionPeriodEndDate.key',
          'recordOppositionPeriodEndDate',
          dataRow,
          null,
          true
        ),
      ])
    },
    toggleShowMore: ({ showMore, setShowMore }) => () => setShowMore(!showMore),
  }),
  lifecycle({
    componentDidMount() {
      const { data, formatedData, setHasItemsToShow } = this.props
      const hasItemsToShow = hasItemsToShowInArray(data, formatedData)
      hasItemsToShow !== this.props.hasItemsToShow &&
        setHasItemsToShow('boxCancellations', hasItemsToShow)
    },
  })
)

export default enhance(
  ({
    literals,
    inidCodes,
    data = [],
    hasItemsToShow,
    formatedData,
    officeExceptionMessage,
    ...props
  }) => {
    const BoxData =
      hasItemsToShow &&
      data.map((dataRow, index) => {
        if (index < SHOWN_ROWS_LIMIT || props.showMore)
          return (
            <DataRow key={index}>
              <DataList
                testId="boxCancellations-show-more2"
                data={formatedData(dataRow)}
                literals={literals}
                shownItemsLimit={99}
              />
              {index + 1 !== data.length && props.showMore && <hr />}
            </DataRow>
          )
      })

    return (
      <Box
        boxId="boxCancellations"
        title={`${literals.tm_details_section_cancellations} ${totalBoxEntries(data)}`}
        hasItemsToShow={hasItemsToShow}
        dataTestId="item-detail-cancellations"
        officeExceptionMessage={officeExceptionMessage}
      >
        {BoxData}
        {hasItemsToShow && data.length > SHOWN_ROWS_LIMIT && (
          <ShowMore
            testId="boxCancellations-show-more"
            literals={literals}
            showMore={props.showMore}
            showMoreHandle={props.toggleShowMore}
          />
        )}
      </Box>
    )
  }
)
