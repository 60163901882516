import React from 'react'

function reverse(r1, r2) {
  var r1x = r1.x,
    r1y = r1.y,
    r2x = r2.x,
    r2y = r2.y,
    d
  if (r1x > r2x) {
    d = Math.abs(r1x - r2x)
    r1x = r2x
    r2x = r1x + d
  }
  if (r1y > r2y) {
    d = Math.abs(r1y - r2y)
    r1y = r2y
    r2y = r1y + d
  }
  return { x1: r1x, y1: r1y, x2: r2x, y2: r2y }
}

const defaultSelectionRectangle = {
  x: null,
  y: null,
  width: null,
  height: null,
  visible: false,
}

const defaultSelectionPositions = {
  start: { x: null, y: null },
  now: { x: null, y: null },
}

let selectionPositions = { ...defaultSelectionPositions }
export function useSelectionRectangle(onCreateSelectionEnd, initialSelection) {
  const [isDragging, setIsDragging] = React.useState(false)
  const [selectionRectangle, setSelectionRectangle] = React.useState(defaultSelectionRectangle)

  React.useEffect(() => {
    const { segmentSelectedId, ...initialRectangle } = initialSelection
    if (!initialSelection.segmentSelectedId && initialSelection.width > 0) {
      setSelectionRectangle({ ...initialRectangle, visible: true })
    }
  }, [])

  const updateSelectionRectangle = React.useCallback(
    nextValues => {
      const nextSelectionRectangle = { ...selectionRectangle, ...nextValues }
      setSelectionRectangle(nextSelectionRectangle)
    },
    [selectionRectangle]
  )

  const doStartDrag = React.useCallback(position => {
    selectionPositions = {
      start: position,
      now: position,
    }
  }, [])

  const doUpdateDrag = React.useCallback(
    position => {
      selectionPositions = {
        start: { ...selectionPositions.start },
        now: position,
      }
      const posRect = reverse(selectionPositions.start, position)
      const nextSelectionRectangle = {
        x: posRect.x1,
        y: posRect.y1,
        width: posRect.x2 - posRect.x1,
        height: posRect.y2 - posRect.y1,
        visible: true,
      }
      setSelectionRectangle(nextSelectionRectangle)
    },
    [selectionPositions]
  )

  const handleMouseDown = React.useCallback(e => {
    const isSelection = e.target.attrs.id === 'selection'
    const isBackdrop = e.target.attrs.id === 'selection-backdrop'
    const isAnchor = e.target.attrs.name && e.target.attrs.name.includes('_anchor')
    if (!isSelection && !isAnchor) {
      if (isBackdrop) {
        onCreateSelectionEnd(null)
      }
      setSelectionRectangle(defaultSelectionRectangle)
      selectionPositions = defaultSelectionPositions
      setIsDragging(true)
      doStartDrag({ x: e.evt.layerX, y: e.evt.layerY })
    }
  }, [])

  const handleMouseMove = React.useCallback(
    e => {
      if (isDragging) {
        doUpdateDrag({ x: e.evt.layerX, y: e.evt.layerY })
      }
    },
    [isDragging]
  )

  const handleMouseUp = React.useCallback(
    e => {
      if (isDragging) {
        setIsDragging(false)
        selectionRectangle.visible && onCreateSelectionEnd(selectionRectangle)
      }
    },
    [setIsDragging, onCreateSelectionEnd, selectionRectangle, isDragging]
  )

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isDragging,
    selectionRectangle,
    updateSelectionRectangle,
  }
}
