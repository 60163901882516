import React from 'react'

import { Block } from '@new-lucentum'
import { GoToDropdown } from '../'
import { BOXES_DSVIEW } from '@app/common/constants/details/boxes'

import { t } from '@app/common/translationsService'

export default props => {
  const sections = Object.values(BOXES_DSVIEW).map(BOX => ({
    id: BOX.ID,
    description: t(BOX.TITLE),
  }))
  return (
    <Block middle auto marginRight>
      <Block auto marginRight>
        {t('tm_details_section_go_to')}:
      </Block>
      <GoToDropdown sections={sections} {...props} />
    </Block>
  )
}
