import styled, { css } from 'styled-components'

import { addSpacing } from '../../utils'

const commonCss = ({ theme }) => css`
  font-family: ${theme.fontFamily};
  margin: 0;
  padding: 0;
`

const regularText = ({ theme, bold }) => css`
  font-size: ${theme.fontSize};
  font-weight: ${bold ? 'bold' : 400};
  line-height: 1.428;
`

export const H1 = styled.h1`
  ${commonCss};
  ${addSpacing};
  font-size: ${({ theme }) => theme.fontSizeBig};
  font-weight: ${({ bold }) => (bold ? 'bold' : 300)};
  line-height: 1.25;
`

export const H2 = styled.h2`
  ${commonCss};
  ${addSpacing};
  font-size: ${({ theme }) => theme.fontSizeLarge};
  font-weight: ${({ bold }) => (bold ? 'bold' : 300)};
  line-height: 1.428;
`

export const H3 = styled.h3`
  ${commonCss};
  ${addSpacing};
  font-size: ${({ theme }) => theme.fontSizeMedium};
  font-weight: ${({ bold }) => (bold ? 'bold' : 400)};
  line-height: 1.25;
`

export const Label = styled.label`
  ${addSpacing};
  ${({ theme }) => `
    ${theme.baseText}
    color: ${theme.colorGrayLight};
  `}
`

export const P = styled.p`
  ${commonCss};
  ${addSpacing};
  ${regularText};
`
export const Legend = styled.legend`
  ${commonCss};
  ${addSpacing};
  ${({ theme }) => `
    ${theme.baseText}
    color: ${theme.colorGrayLight};
  `}
`
export const Span = styled.span`
  ${commonCss};
  ${addSpacing};
  ${regularText};
`

export const Detail = styled.div`
  ${commonCss};
  ${addSpacing};
  color: ${({ theme }) => theme.colorGrayLight};
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: ${({ bold }) => (bold ? 'bold' : 300)};
`
