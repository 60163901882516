import React from 'react'
import ContentLoader from 'react-content-loader'
import { BlockFilterCheckbox } from './styles'

export default () => (
  <BlockFilterCheckbox>
    <ContentLoader
      height={18}
      width={210}
      speed={1}
      primaryColor="#ecebeb"
      secondaryColor="#d4d4d4"
    >
      <rect x="5" y="0" rx="5" ry="2" width="15" height="15" />
      <rect x="30" y="0" rx="5" ry="2" width="120" height="15" />
      <rect x="170" y="0" rx="5" ry="2" width="20" height="15" />
    </ContentLoader>
  </BlockFilterCheckbox>
)
