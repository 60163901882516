import styled, { css } from 'styled-components'

export const TableRow = styled.div`
  display: flex;
  border-top: solid 1px;
  border-left: solid 1px;
  border-right: solid 1px;
  border-color: #e2e4e9;
  min-height: 44px;

  :last-child {
    border-bottom: 1px solid #e2e4e9;
    border-radius: 0 0 3px 3px;
  }

  .priorities-row {
    min-height: 20px;
    border: none;
  }

  #borderless {
    border: none;
  }
`

export const TableCol = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  font-weight: normal;
  padding: 12px;

  #border-first-row {
    border-radius: 3px 3px 0 0;
  }

  ${({ padding, blueHeader, blueText, bold, rowColor, header }) =>
    css`
    ${padding &&
      css`
        padding: 40px 0 40px 0;
      `}
    ${blueHeader &&
      css`
        color: #2f509a;
      `}
      ${blueText === 'Opposition period end type' &&
        css`
          color: #2f509a;
        `}
    ${bold &&
      css`
        font-weight: bold;
      `}
    ${
      rowColor === 'red'
        ? css`
            background-color: #fdeeee;
          `
        : rowColor === 'green'
        ? css`
            background-color: #f5f9f2;
          `
        : css`
            background-color: white;
          `
    }
  ${header &&
    css`
      border-bottom: 1px solid #d3d3d3;
    `}
`}
`

export const Span = styled.span`
  display: inline-block;
  padding-left: 20%;

  .fknXcM {
    color: #171c26;
    border-color: #171c26;
    padding: 6px 4px;
    min-width: 149px;
  }

  .dropdown-btn {
    width: 200px;
  }

  @media (max-width: 942px) {
    display: inline-block;
  }

  ${({ padding }) =>
    css`
      ${padding &&
        css`
          padding-left: 10%;
        `}
    `}
`

export const Div = styled.div`
  display: inline-block;
  padding-left: 70px;

  @media (max-width: 942px) {
    padding-left: 2%;
    display: block;
  }
`
