import React from 'react'
import cn from 'classnames'
import { compose, findLastIndex, replace } from 'ramda'

import { ViennaStyled, ChevronStyled } from './styles'

const parent = id => id.map(i => `p${i}`)

const wildcardRegex = /^((\d+\.)+\*)$/g
const rangeRegex = /^((\d+\.?)+\.\.(\d+\.?)+)$/g
const clearFilter = compose(
  replace(wildcardRegex, ''),
  replace(rangeRegex, '')
)

export const viennaFilter = label => (option, filter) =>
  (option[label] && option[label].match(RegExp(clearFilter(filter), 'gi'))) ||
  // allow to search both for labelKey and valueKey
  (option.code && option.code.match(RegExp(filter, 'gi')))

export const viennaKeyDown = (e, opts) => {
  if (e.keyCode !== 13) return false
  // if it contains a range (n..n) return that range of elements
  const matches = e.target.value.match(rangeRegex)
  if (matches && matches.length) {
    const [first, last] = matches[0].split('..')
    return opts.slice(
      opts.findIndex(x => x.code === first),
      opts.findIndex(x => x.code === last) + 1
    )
  }
  // if it contains an asterisk (n.*) return the rest of the elements
  const wildcard = e.target.value.match(wildcardRegex)
  if (wildcard && wildcard.length) {
    const pattern = wildcard[0].slice(0, -1)
    const codeMatches = x => x.code.startsWith(pattern)
    return opts.slice(opts.findIndex(codeMatches), findLastIndex(codeMatches, opts) + 1)
  }
}

/**
 * Custom option template for Vienna
 */
export default ({
  children,
  className,
  handleMouseDown,
  handleMouseDropdown,
  handleMouseEnter,
  handleMouseMove,
  option,
  state,
}) => (
  <ViennaStyled
    onMouseEnter={handleMouseEnter}
    onMouseMove={handleMouseMove}
    marginLevel={option.level}
    className={cn(className, option.parent && parent(option.parent))}
  >
    {option.children && (
      <ChevronStyled
        borderless
        icon="chevron-down-small"
        chevronDown={!state.optionExpanded.includes(option.id)}
        onClick={handleMouseDropdown}
      />
    )}
    <div
      className={(!option.children && 'itemValue') || null}
      onMouseDown={handleMouseDown}
      data-test-id={
        (option.keyPrefix &&
          option.keyPrefix.length > 0 &&
          `select_${option.keyPrefix}_option_${option.code}_${option.description}`) ||
        null
      }
    >
      {!option.hideCode && option.code && <b>{option.code}</b>} {children}
    </div>
  </ViennaStyled>
)
