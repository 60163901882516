const xxs = '1px'
const xs = '5px'
const sm = '10px'
const md = '20px'
const lg = '30px'
const xl = '40px'

export const spacing = {
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
}
