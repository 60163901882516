import { css } from 'styled-components'
export const includeFonts = css`
  /* open-sans-300 - cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-300.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-300.woff') format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* open-sans-600 - cyrillic-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-600.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-600.woff') format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-cyrillic-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* open-sans-300 - cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/open-sans/open-sans-v17-cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url('./resources/fonts/open-sans/open-sans-v17-cyrillic-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-300.woff2')
        format('woff2'),
      /* Super Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-300.woff')
        format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* open-sans-600 - cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/open-sans/open-sans-v17-cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('./resources/fonts/open-sans/open-sans-v17-cyrillic-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-600.woff2')
        format('woff2'),
      /* Super Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-600.woff')
        format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-cyrillic-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-cyrillic-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* open-sans-300 - greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/open-sans/open-sans-v17-greek-ext-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url('./resources/fonts/open-sans/open-sans-v17-greek-ext-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-greek-ext-300.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./resources/fonts/open-sans/open-sans-v17-greek-ext-300.woff') format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-greek-ext-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-greek-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+1F00-1FFF;
  }
  /* open-sans-600 - greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/open-sans/open-sans-v17-greek-ext-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('./resources/fonts/open-sans/open-sans-v17-greek-ext-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-greek-ext-600.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./resources/fonts/open-sans/open-sans-v17-greek-ext-600.woff') format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-greek-ext-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-greek-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+1F00-1FFF;
  }

  /* open-sans-300 - greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/open-sans/open-sans-v17-greek-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url('./resources/fonts/open-sans/open-sans-v17-greek-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-greek-300.woff2') format('woff2'),
      /* Super Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-greek-300.woff')
        format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-greek-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-greek-300.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0370-03FF;
  }
  /* open-sans-600 - greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/open-sans/open-sans-v17-greek-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('./resources/fonts/open-sans/open-sans-v17-greek-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-greek-600.woff2') format('woff2'),
      /* Super Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-greek-600.woff')
        format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-greek-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-greek-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0370-03FF;
  }

  /* open-sans-300 - vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/open-sans/open-sans-v17-vietnamese-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url('./resources/fonts/open-sans/open-sans-v17-vietnamese-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-vietnamese-300.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./resources/fonts/open-sans/open-sans-v17-vietnamese-300.woff') format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-vietnamese-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-vietnamese-300.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
      U+1EA0-1EF9, U+20AB;
  }
  /* open-sans-600 - vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/open-sans/open-sans-v17-vietnamese-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('./resources/fonts/open-sans/open-sans-v17-vietnamese-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-vietnamese-600.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./resources/fonts/open-sans/open-sans-v17-vietnamese-600.woff') format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-vietnamese-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-vietnamese-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
      U+1EA0-1EF9, U+20AB;
  }

  /* open-sans-300 - latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/open-sans/open-sans-v17-latin-ext-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url('./resources/fonts/open-sans/open-sans-v17-latin-ext-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-latin-ext-300.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./resources/fonts/open-sans/open-sans-v17-latin-ext-300.woff') format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-latin-ext-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-latin-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* open-sans-600 - latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/open-sans/open-sans-v17-latin-ext-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('./resources/fonts/open-sans/open-sans-v17-latin-ext-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-latin-ext-600.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('./resources/fonts/open-sans/open-sans-v17-latin-ext-600.woff') format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-latin-ext-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-latin-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }

  /* open-sans-300 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/open-sans/open-sans-v17-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url('./resources/fonts/open-sans/open-sans-v17-latin-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-latin-300.woff2') format('woff2'),
      /* Super Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-latin-300.woff')
        format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-latin-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* open-sans-600 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/open-sans/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('./resources/fonts/open-sans/open-sans-v17-latin-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('./resources/fonts/open-sans/open-sans-v17-latin-600.woff2') format('woff2'),
      /* Super Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-latin-600.woff')
        format('woff'),
      /* Modern Browsers */ url('./resources/fonts/open-sans/open-sans-v17-latin-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('./resources/fonts/open-sans/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`
