import { searchDataManager, userprefsDataManager } from '@app/common/dataManagers'
import { ALERTS_TYPES } from '@app/redux-types'

export const getAlerts = (page, pageSize) => ({
  type: ALERTS_TYPES.GET_ALERTS,
  payload: userprefsDataManager.getAlerts(),
})

export const getAlertsByIds = ids => ({
  type: ALERTS_TYPES.GET_ALERTS_BY_IDS,
  payload: userprefsDataManager.getAlertsByIds(ids),
})

export const setAlerts = (st13List, alertsEvents) => ({
  type: ALERTS_TYPES.SET_ALERTS,
  payload: userprefsDataManager.setAlerts(st13List, alertsEvents),
  meta: { errors: [422] },
})

export const doSearchBySt13s = (query, queryObject) => ({
  type: 'SEARCH_BY_ST13S',
  payload: searchDataManager.doSearch(query, queryObject),
})

/* export const fetchManageAlertsThunk = pagination => dispatch => {
  const query = pagination ? `?page=${pagination.page}&pageSize=${pagination.pageSize}` : ''
  const response = dispatch(fetchManageAlerts(query))
  response.then(({ value }) => {
    const filtersTM = value.manageAlerts.map(alert => alert.ST13)
    if (filtersTM.length === 0) {
      return dispatch(searchByTMFulfilled())
    } else {
      let querySearchObj = {
        st13s: filtersTM,
      }
      if (pagination && pagination.sortColumn && pagination.sortColumn.id) {
        querySearchObj.sortColumn = pagination.sortColumn.id
      }
      return dispatch(fetchSearchByTM(querySearchObj))
    }
  })
} */

export const resetSearchTMFetch = () => ({
  type: 'FETCH_SEARCH_TM_RESET',
})

export const changeExpandedRowsAlerts = expandedRows => ({
  type: 'CHANGE_EXPANDED_ROWS_ALERTS',
  payload: expandedRows,
})

export const getAlertsHistory = (id, filterParams) => ({
  type: ALERTS_TYPES.GET_ALERTS_HISTORY,
  payload: userprefsDataManager.getAlertsHistory(id, filterParams),
})
