import styled, { css } from 'styled-components'
const spacingProps = {
  mt: ['margin-top'],
  mb: ['margin-bottom'],
  ml: ['margin-left'],
  mr: ['margin-right'],
  mx: ['margin-left', 'margin-right'],
  my: ['margin-top', 'margin-bottom'],
  pt: ['padding-top'],
  pb: ['padding-bottom'],
  pl: ['padding-left'],
  pr: ['padding-right'],
  px: ['padding-left', 'padding-right'],
  py: ['padding-top', 'padding-bottom'],
}

const prepareValue = value => {
  return Number.isNaN ? Number(value) : false
}

const prepareCssFunction = sizeBase => spacing => {
  const key = Object.keys(spacing)[0]
  const value = prepareValue(spacing[key])
  const cssProps = spacingProps[key]
  return [].concat(...cssProps.map(cssProp => `${cssProp}: ${value * sizeBase}px;`))
}

const StyledSpacing = styled.div`
  ${({ mt, mb, ml, mr, mx, my, pt, pb, pl, pr, px, py, theme }) => {
    const prepareCss = prepareCssFunction(theme.sizeBase)
    return css`
      ${mt && prepareCss({ mt })}
      ${mb && prepareCss({ mb })}
      ${ml && prepareCss({ ml })}
      ${mr && prepareCss({ mr })}
      ${mx && prepareCss({ mx })}
      ${my && prepareCss({ my })}
      ${pt && prepareCss({ pt })}
      ${pb && prepareCss({ pb })}
      ${pl && prepareCss({ pl })}
      ${pr && prepareCss({ pr })}
      ${px && prepareCss({ px })}
      ${py && prepareCss({ py })}
  `
  }}
`

export const Spacing = StyledSpacing
