import TMDSContextService from '@app/common/tmdsContextService'
import { t } from '@app/common/translationsService'
import TMForm from '@app/components/TMForm'
import ToggleButton from '@app/components/ToggleButton'
import { Block, Button, Flex, Form, Grid } from '@new-lucentum'
import React from 'react'
import { compose, lifecycle, withHandlers } from 'recompose'
import styled from 'styled-components'

import { Styled } from '../AlertButton.styles'
import { checkDaysLeft, checkForTriggeredValues, getBooleanKeys } from '../utils'
import { AlertFormDsview, alertFormEnhancer, AlertFormTmview } from './AlertForm'

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colorError};
  font-size: 0.85rem;
`

const AlertDropdownMenuComponent = props => {
  const { toggleDropdown, exposeMenuActions, applyDisabled } = props
  const {
    errors,
    isSubmitting,
    handleSubmit,
    submitForm,
    validateForm,
    values,
    setFieldValue,
    handleReset,
    resourceData,
    st13List,
  } = props
  const { handleWarningDateValidation } = props
  //exposeMenuActions({ submitForm, validateForm })
  const AlertForm = TMDSContextService.isTmview() ? AlertFormTmview : AlertFormDsview

  const isBulk = Array.isArray(st13List) && st13List.length > 1

  return (
    <Styled.DropdownMenu>
      <Flex justify="space-between">
        <div>
          <strong>{t('alert.column.key')}</strong>
        </div>
        <div>
          <ToggleButton
            activeLabel={t('select_all')}
            inactiveLabel={t('deselect_all')}
            toggleFn={isActive => {
              if (isActive) {
                const booleanKeys = getBooleanKeys(values)
                booleanKeys.forEach(key => {
                  setFieldValue(key, true)
                })
              } else {
                handleReset()
              }
            }}
          />
        </div>
      </Flex>
      <Block marginBottom>{t('alerts_dropdown_information_text')}</Block>
      <Form>
        <AlertForm
          values={values}
          handleWarningDateValidation={handleWarningDateValidation}
          resourceData={resourceData}
          isBulk={isBulk}
        />
        <Grid.Container marginTop>
          {Object.keys(errors).map(error => (
            <Grid.Spacing mb key={`${error}-error`}>
              <ErrorMessage>{errors[error]}</ErrorMessage>
            </Grid.Spacing>
          ))}
          <TMForm.WarningMessage all exceptions={['backend']} />
          <TMForm.WarningMessage name="backend" isError />
        </Grid.Container>
        <Block middle alignContents="right">
          <Block auto marginRight>
            <Button
              type="submit"
              title={t('cancel.key')}
              secondary
              onClick={toggleDropdown}
              disabled={isSubmitting}
              data-testid="login-button"
            />
          </Block>
          <Button
            type="submit"
            title={t('apply.key')}
            primary
            onClick={handleSubmit}
            disabled={(applyDisabled && checkForTriggeredValues(values) === 0) || isSubmitting}
            data-testid="edit-alert-button"
          />
        </Block>
      </Form>
    </Styled.DropdownMenu>
  )
}
export const AlertDropdownMenu = compose(
  alertFormEnhancer,
  lifecycle({
    componentDidMount() {
      const { setValues, preloadedValues } = this.props
      preloadedValues && Object.keys(preloadedValues).length > 0 && setValues(preloadedValues)
    },
  }),
  withHandlers({
    handleWarningDateValidation: props => (resourceField, field, value, warningMessage) => {
      const { setStatus, resourceData } = props
      const targetDate = resourceData && resourceData[resourceField]
      if (targetDate) {
        const daysLeft = checkDaysLeft(targetDate)
        const daysBeforeNotification = Number(value)
        const showWarning = daysLeft < daysBeforeNotification
        setStatus({
          ...(showWarning && {
            [field]: warningMessage,
          }),
        })
      }
    },
  })
)(AlertDropdownMenuComponent)
export default AlertDropdownMenu
