import styled from 'styled-components'

export default styled.nav`
  ${({ type }) => type && ' flex-direction: column'};
  ${({ type, theme }) => type === 'verticalFloating' && `position:fixed; top: ${theme.size}`};
  background-color: transparent;
  display: flex;
  a {
    border-bottom: solid ${({ theme }) => `${theme.lineThick} ${theme.colorTransparent}`};
    font-weight: bold;
    margin: ${({ type, theme }) => (type ? [theme.sizeSmall, ' 0'] : ['0 ', theme.sizeSmall])};
    padding-bottom: ${({ type, theme }) => (type ? 0 : theme.sizeSmall)};

    &:hover {
      ${({ type, theme }) => !type && `border-bottom: solid ${theme.lineThick} ${theme.color}`};
      padding-right: 0;
      text-decoration: ${({ type }) => (type ? 'underline' : 'none')};
    }

    &:focus {
      text-decoration: ${({ type }) => (type ? 'underline' : 'none')};
    }
  }
`
