import { IMAGE_SEARCH_TYPES } from '@app/redux-types'
const defaultState = {
  opened: true,
  formData: {
    imageId: null,
    imageName: '',
    segmentSelectedId: null,
    segmentLeft: null,
    segmentRight: null,
    segmentTop: null,
    segmentBottom: null,
    colour: false,
    ocr: false,
    selectionMode: 'AREA',
    freeSelectionPoints: null,
  },
  imageData: null,
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'CLOSE_IMAGE_SEARCH':
      return {
        ...state,
        opened: false,
      }
    case 'OPEN_IMAGE_SEARCH':
      return {
        ...state,
        opened: true,
      }
    case 'CHANGE_IMAGE_SEARCH_UIDATA':
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      }
    case IMAGE_SEARCH_TYPES.CHANGE_SELECTION_MODE:
      return {
        ...state,
        formData: {
          ...state.formData,
          selectionMode: payload,
          freeSelectionPoints: null,
        },
      }
    case 'CLOSE_IMAGE_SEARCH_MODE':
    case 'TOGGLE_IMAGE_SEARCH_MODE':
    case 'EMPTY_IMAGE_SEARCH_UI':
      return {
        ...defaultState,
        formData: {
          ...defaultState.formData,
        },
      }
    case IMAGE_SEARCH_TYPES.SET_IMAGE_DATA:
      return {
        ...defaultState,
        imageData: payload,
      }
    case IMAGE_SEARCH_TYPES.CLEAR_IMAGE_DATA:
      return {
        ...defaultState,
        imageData: null,
      }
    default:
      return state
  }
}
