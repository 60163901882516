import { APPLICANTS_FIELDS } from './applicants'
class EARLIER_MARK {
  static KEY = 'earlierMark'
  static EARLIER_MARK_COUNTRY_CODE = `earlierMarkcountryCode`
  static APPLICATION_NUMBER = `applicationNumber`
  static APPLICATION_DATE = `applicationDate`
  static REGISTRATION_NUMBER = `registrationNumber`
  static REGISTRATION_DATE = `registrationDate`
  static MARK_VERBAL_ELEMENT_TEXT = `markVerbalElementText`
  static CODE_TRADEMARK_TYPE = `codeTrademarkType`
  static OPPOSITION_BASIS_CODE = `oppositionBasisCode`
  static OPPOSITION_BASIS_CODE_TRANSLATED = 'oppositionBasisCodeTranslated'
  static OPPOSITION_GROUND_TEXT = `oppositionGroundText`
}

export class OPPOSITION_FIELDS {
  static OPPOSITION_IDENTIFIER = 'oppositionIdentifier'
  static OPPOSITION_DATE = 'oppositionDate'
  static OPPOSITION_REFERENCE = 'oppositionReference'
  static PROCEEDING_LANGUAGE_CODE = 'proceedingLanguageCode'
  static OPPOSITION_BASIS_CODE = 'oppositionBasisCode'
  static OPPOSITION_BASIS_CODE_TRANSLATED = 'oppositionBasisCodeTranslated'
  static OPPOSITION_GROUND_TEXT = 'oppositionGroundText'
  static OPPOSITION_CURRENT_STATUS_CODE = 'oppositionCurrentStatusCode'
  static EARLIER_MARK = EARLIER_MARK
  static APPLICANTS = APPLICANTS_FIELDS
}
