import styled, { css } from 'styled-components'

const StyledRow = styled.div`
  display: flex;
  flex-wrap: ${({ noWrap }) => (noWrap ? 'initial' : 'wrap')};
  ${({ columnsNumber }) =>
    columnsNumber &&
    css`
      > div {
        width: ${100 / columnsNumber}%;
        flex: ${100 / columnsNumber}%;
      }
    `}
`

export const Row = StyledRow
