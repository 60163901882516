import { COMPOSED_FIELDS } from '@app/common/constants/alerts'

export const getRelatedField = fieldKey => {
  const composedField = COMPOSED_FIELDS[fieldKey]
  return composedField || [false]
}

export const prepareAlertsSearchQuery = (st13List, filterParams) => {
  const queryObject = {
    ...filterParams,
    criteria: 'C',
    st13s: st13List,
  }
  const queryString = Object.keys(queryObject)
    .map(key => `${key}=${queryObject[key]}`)
    .join('&')

  return { queryString, queryObject }
}
