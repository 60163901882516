import { enumType } from '@app/common/services/tmdsToastService/tmdsToastService.constants'
import { Icon } from '@new-lucentum'
import React from 'react'

import { ToastWrapperStyled } from './styles'

const ICONS_MAP = {
  [enumType.INFO]: 'information',
  [enumType.SUCCESS]: 'check-circle',
  [enumType.WARNING]: 'information',
  [enumType.ERROR]: 'close',
}

const ToastWrapperComponent = ({ type, children }) => {
  const icon = ICONS_MAP[type]
  return (
    <ToastWrapperStyled>
      <Icon className="toast-icon" {...{ [icon]: true }} />
      {children}
    </ToastWrapperStyled>
  )
}

export const ToastWrapper = ToastWrapperComponent
