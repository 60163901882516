import styled from 'styled-components'

export const StyledImageCrop = styled.div`
  border: 1px solid ${({ theme }) => theme.colorGrayLight};
  width: fit-content;
  .image {
    width: auto;
  }

  .area-selection {
    cursor: crosshair;
  }

  .free-selection {
    cursor: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5wZW48L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJwZW4iIGZpbGwtcnVsZT0ibm9uemVybyIgZmlsbD0iIzAwMDAwMCI+ICAgICAgICAgICAgPHBhdGggZD0iTTE1LjAxNDA5ODIsMi4yNjUzNTk1MiBMMTMuMzUyNDI4MSwwLjYwMzY4OTM0OCBDMTIuNTQ3NTA4OSwtMC4yMDEyMjk3ODMgMTEuMjQ1NjEzMiwtMC4yMDEyMjk3ODMgMTAuNDQwNjk0MSwwLjYwMzY4OTM0OCBMMC4zOTQ0NDk2MzUsMTAuNjQ5OTMzOCBMMC4wMTYzODE1NTgyLDE0LjA2MTY5MzMgQy0wLjA4MTE4NDM5NzEsMTQuOTUxOTgyNiAwLjY3MTkwMjgyLDE1LjY5ODk3MiAxLjU1NjA5NDI5LDE1LjYwMTQwNiBMNC45NjQ4MDQ4NSwxNS4yMjMzMzggTDE1LjAxNDA5ODIsNS4xNzQwNDQ1NyBDMTUuODE5MDE3NCw0LjM2OTEyNTQzIDE1LjgxOTAxNzQsMy4wNjcyMjk3MiAxNS4wMTQwOTgyLDIuMjY1MzU5NTIgWiBNNC4yOTQwMzg5MSwxMy44MjM4NzYzIEwxLjQ3OTg3MDg5LDE0LjEzNzkxNjcgTDEuNzkzOTExMzEsMTEuMzIzNzQ4NyBMOC45NzExMDY4OSw0LjE0MzUwNDE2IEwxMS40NzQyODM0LDYuNjQ2NjgwNyBMNC4yOTQwMzg5MSwxMy44MjM4NzYzIFogTTEzLjk4MDUwODksNC4xNDA0NTUyMyBMMTIuNTA3ODcyOCw1LjYxMzA5MTM2IEwxMC4wMDQ2OTYyLDMuMTA5OTE0ODIgTDExLjQ3NzMzMjQsMS42MzcyNzg2OSBDMTEuNzA5MDUxNSwxLjQwNTU1OTU0IDEyLjA4NzExOTYsMS40MDU1NTk1NCAxMi4zMTg4Mzg3LDEuNjM3Mjc4NjkgTDEzLjk4MDUwODksMy4yOTg5NDg4NiBDMTQuMjEyMjI4MSwzLjUzMDY2ODAxIDE0LjIxMjIyODEsMy45MDU2ODcxNSAxMy45ODA1MDg5LDQuMTQwNDU1MjMgTDEzLjk4MDUwODksNC4xNDA0NTUyMyBaIiBpZD0iU2hhcGUiPjwvcGF0aD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==')
        0 16,
      pointer;
  }
`

export const StyledPlaceholder = styled.div`
  position: relative;
  width: 100%;
  padding: 20px;
  padding-left: 40px;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }

  span {
    font-weight: bold;
  }

  .placeholder-wrapper {
    display: flex;
  }

  .background-icon {
    span {
      opacity: 0.6;
      font-size: 35px;
      color: ${({ theme }) => theme.iconUploadPanel};
      font-weight: normal;
    }
    margin-right: 40px;
  }
  .drag-description {
    position: relative;
  }
  .drag-text {
    ${({ theme }) => theme.baseText};
  }
  .file-types {
    display: inline;
    > span {
      border: 1px solid ${({ theme }) => theme.colorGray};
      border-radius: 2px;
      padding: 2px 4px;
      margin: 0 3px;
      font-weight: normal;
      font-size: 12px;
    }
  }
  .beta-message {
    ${({ theme }) => theme.footnoteText};
    margin-top: 5px;
  }

  .error-message {
    display: block;
    background-color: rgba(185, 74, 72, 0.1);
    border: 1px solid #b94a48;
    clear: both;
    color: red;
    font-size: ${({ theme }) => theme.fontSizeSmaller};
    margin-top: 20px;
    padding: 0.6em 0;
    text-align: center;
  }
  .drag-active {
    background: rgba(255, 255, 255, 0.9);
    border: 2px dashed lightblue;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    i,
    span {
      font-size: 2em;
      color: ${({ theme }) => theme.blueDroppable};
    }
  }
`
