export const pickWithCondition = (array, conditionFn) => {
  const meets = []
  const unmeets = []
  for (const item of array) {
    const targetArray = conditionFn(item) ? meets : unmeets
    targetArray.push(item)
  }
  return [meets, unmeets]
}

export const moveItem = (array, fromIndex, toIndex) => {
  let nextArray = [...array]
  const [targetItem] = nextArray.splice(fromIndex, 1)
  nextArray.splice(toIndex, 0, targetItem)
  return nextArray
}

export const mergeUnique = (field = 'id', ...args) => {
  const merged = [].concat(...args)
  const fieldArray = merged.map(item => item[field])
  const uniqueFieldArray = [...new Set(fieldArray)]
  const mergedUnique = uniqueFieldArray.map(uniqueField =>
    merged.find(item => item[field] === uniqueField)
  )
  return mergedUnique
}

export const splitInGroups = (array, groupLength) => {
  return array.reduce((acc, item, i) => {
    if (i % groupLength === 0) acc.push(array.slice(i, i + groupLength))
    return acc
  }, [])
}

export const arrayUtils = {
  pickWithCondition,
  moveItem,
  mergeUnique,
  splitInGroups,
}
