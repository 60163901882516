import styled, { css } from 'styled-components'

import Block from '../Block'

const completHover = ({ color }) => `
  position: relative;
  &:before{
      background-color: ${color};
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 3px;
  }
`

const arrow = ({ theme }) => css`
  border-color: transparent ${theme.colorGrayLighter} ${theme.colorGrayLighter} transparent;
  border-style: solid;
  border-width: 1px;
  content: '';
  height: 4px;
  position: absolute;
  right: 10px;
  top: 50%;
  width: 4px;
`

export default styled(Block)`
  .ReactTable {
    border: 0;
    display: block;
    &,
    & .rt-table,
    & .rt-tbody {
      overflow-y: visible !important;
    }
    & .rt-thead .rt-th {
      font-weight: bold;
      outline: none;
      margin: auto 0;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.-cursor-pointer {
        .rt-resizable-header-content {
          &:after {
            font-family: 'Lucentum Icons', Helvetica, sans-serif;
            color: ${({ theme }) => theme.colorGrayAccent};
            font-size: 9px;
            content: '\\e9a5';
            margin-left: 5px;
          }

          &.-sort-desc:after {
            content: '\\e9a5';
            color: ${({ theme }) => theme.colorGray};
          }

          &.-sort-asc:after {
            content: '\\e9a6';
            color: ${({ theme }) => theme.colorGray};
          }
        }
      }
      .rt-resizer {
        position: inherit;
        right: -3px;
        width: 10px;
        height: 35px;
        border-left: 1px solid ${({ theme }) => theme.colorGrayAccent};
        z-index: 0;
      }

      &:last-child {
        .rt-resizer {
          border-left: none;
        }
      }
    }
    & .rt-thead .rt-th.-sort-desc,
    & .rt-thead .rt-th.-sort-asc {
      box-shadow: none;
      color: ${({ theme }) => theme.color};
    }
    & .rt-thead .rt-th.-sort-desc:before {
      border-color: transparent ${({ theme }) => theme.color} ${({ theme }) => theme.color}
        transparent;
    }
    & .rt-thead .rt-th.-sort-asc:after {
      border-color: transparent ${({ theme }) => theme.color} ${({ theme }) => theme.color}
        transparent;
    }
    & .rt-thead.-header {
      border-bottom: solid ${({ theme }) => [theme.line, ' ', theme.colorGrayAccent]};
      box-shadow: none;
      margin: 0;
      background-color: ${({ theme }) => theme.colorGrayLighter};
    }
    & .rt-thead .rt-tr {
      text-align: left;
    }
    .rt-thead .rt-resizable-header-content {
      padding-right: 15px;
    }
    & input[type='checkbox'] {
      border: 0;
      padding: 0;
    }
    & .rt-tbody {
      overflow: hidden;
      padding: 0;
    }
    & .rt-tbody .rt-tr-group {
      &.expanded {
        box-shadow: ${({ theme }) => theme.dropdownShadow};
        position: relative;
      }
      &:hover {
        ${({ theme }) => completHover(theme)};
        .rt-tr {
          background-color: ${({ theme }) => theme.rowSelected};
        }
      }
      & > div:nth-child(2) {
        padding-top: ${({ theme }) => theme.sizeTriple};
      }
      &:last-child {
        &.expanded {
          margin-bottom: 8px;
        }
      }
    }

    .rt-tbody .rt-tr {
      min-height: 74px;
      &.-even {
        background-color: ${({ theme }) => theme.colorGrayLighter};
      }
      &.extra-component {
        display: flex;
        min-height: auto;
      }
    }

    & .rt-tbody .rt-td {
      overflow: visible;
      text-overflow: clip;
      white-space: normal;
      align-items: center;
      display: inline-flex;
      & > div > img {
        max-height: 64px;
        max-width: 64px;
      }
    }
    & .rt-tbody .rt-td,
    & .rt-th {
      border-right: 0 !important;
    }
  }
  .DetailTable {
    & .rt-table,
    & .rt-tbody,
    & .rt-tr-group {
      border: 0px !important;
      &:hover {
        background-color: ${({ theme }) => theme.colorTransparent} !important;
        &:before {
          background-color: ${({ theme }) => theme.colorTransparent} !important;
          content: '';
          height: 0px;
          left: 0;
          position: absolute;
          top: 0;
          width: 0px;
        }
      }
    }

    & .rt-tbody .rt-td:nth-child(1) {
      display: none !important;
    }
    & .rt-thead .rt-th:nth-child(1) {
      display: none !important;
    }
    & .rt-tbody .rt-td:last-child {
      display: block !important;
    }
    & .rt-thead .rt-th:last-child {
      display: block !important;
    }
    & .rt-thead.-header {
      border-bottom: solid 1px ${({ theme }) => theme.colorGrayAccent};
    }
    & .rt-th {
      overflow: visible;
      text-overflow: clip;
      white-space: normal;
      color: ${({ theme }) => theme.colorGrayLight} !important;
    }
    & .rt-noData {
      top: 80%;
      padding: 0;
    }
  }
  .DetailTableNoBorder {
    & .rt-table,
    & .rt-tbody,
    & .rt-thead.-header {
      border-bottom: solid 0px rgba(0, 0, 0, 0.22) !important;
    }
    & .rt-th {
      padding-left: 0px !important;
    }
    & .rt-td {
      padding-left: 0px !important;
    }
  }
`

export const Expander = styled.div`
  display: flex;
  justify-content: center;
  margin-left: ${({ theme }) => theme.size};
  overflow: visible !important;
`

export const CheckboxCell = styled.div`
  display: flex;
  align-items: center;
  .separator {
    width: 1px;
    height: 25px;
    background: ${({ theme }) => theme.colorGrayAccent};
    margin-left: 11px;
  }
`
