import {
  KEY_DESIGN_STATUS,
  KEY_DESIGNER_NAME,
  KEY_FILTER_DESIGN_STATUS,
  KEY_FILTER_DESIGNER_NAME,
  KEY_FILTER_LOCARNO_CLASS,
  KEY_FILTER_OWNER_NAME,
  KEY_INDICATION_OF_PRODUCT,
  KEY_LOCARNO_CLASS,
  KEY_OWNER_NAME_EXPLICIT,
  NO_NICE_CLASS_CODE,
} from '@app/common/constants'
import TMDSContextService from '@app/common/tmdsContextService'

import { buildQueryParams, removeNullEmptyOrFalseProperties } from './utilities'

export const deleteArrayObjectDuplicates = (arr, key) =>
  arr.filter(
    (el, index, self) =>
      self.findIndex(t => {
        return t[key] === el[key]
      }) === index
  )

export const retrieveCountries = (territory, arrayTerritories) => {
  if (!territory.children) {
    return [territory.id]
  } else {
    let countries = []
    arrayTerritories.forEach(elemTerritory => {
      if (elemTerritory.parent && elemTerritory.parent.includes(territory.id)) {
        const retrievedCountries = retrieveCountries(elemTerritory, arrayTerritories)
        countries = [...countries, ...retrievedCountries]
      }
    })
    return countries
  }
}

export const getCountriesByTerritory = (territorySelected, territories) => {
  let countries = []
  const territoriesObj = territorySelected.map(teSel => {
    return territories.find(el => el.code === teSel)
  })
  territoriesObj.forEach(territory => {
    countries = [...countries, ...retrieveCountries(territory, territories)]
  })
  return countries
}

export const queryParamsToObj = paramString => {
  let paramsObj = {}
  if (paramString[0] === '?') {
    paramString = paramString.substring(1)
  }
  paramString.split('&').forEach(param => {
    const [paramName, paramValue] = param.split('=')
    if (paramName.length > 0) {
      if (paramsObj[paramName]) {
        if (!Array.isArray(paramsObj[paramName])) {
          paramsObj[paramName] = [paramsObj[paramName]]
        }
        paramsObj[paramName].push(paramValue)
      } else {
        paramsObj[paramName] = paramValue
      }
    }
  })
  return paramsObj
}

export const buildQuery = (fields, initialCharacter) => {
  const nonEmptyParams = removeNullEmptyOrFalseProperties(fields)
  return buildQueryParams(nonEmptyParams, initialCharacter)
}

const obtainValuesFromFavorites = (favoriteFields, formStateFields) => {
  const searchFieldsFormStateFieldRelation = {
    applicationNumber: 'applicationNumber',
    registrationNumber: 'registrationNumber',
    territories: 'territories',
    offices: 'offices',
    niceClass: 'niceClass',
    viennaCode: 'viennaCode',
    tmStatus: 'tmStatus',
    tmType: 'tmType',
    appName: 'appName',
    appDate: ['adFrom', 'adTo'],
    regDate: ['rdFrom', 'rdTo'],
    expiryDate: ['expiryFrom', 'expiryTo'],
    applicationFilingDate: ['applicationFilingFrom', 'applicationFilingTo'],
    publicationDate: ['publicationFrom', 'publicationTo'],
    priorityDate: ['priorityFrom', 'priorityTo'],
    effectiveDate: ['effectiveFrom', 'effectiveTo'],
    endOfDefermentDate: ['endOfDefermentFrom', 'endOfDefermentTo'],
    currentlyOpposable: 'currentlyOpposable',
    seniorityClaimed: 'seniorityClaimed',
  }
  const favoriteValues = {}
  Object.keys(searchFieldsFormStateFieldRelation).map(searchField => {
    const formStateField = searchFieldsFormStateFieldRelation[searchField]
    if (Array.isArray(formStateField)) {
      formStateField.forEach(StateField => {
        favoriteValues[StateField] =
          favoriteFields.includes && favoriteFields.includes(searchField)
            ? formStateFields[StateField]
            : ''
      })
    } else {
      favoriteValues[searchField] =
        favoriteFields.includes && favoriteFields.includes(searchField)
          ? formStateFields[searchFieldsFormStateFieldRelation[searchField]]
          : ''
    }
  })
  return removeNullEmptyOrFalseProperties(favoriteValues)
}

const addChildrenTMStatus = (tmStatusList, tmStatus, tmStatusSelected) => {
  tmStatusList.forEach(tmStatusGlobal => {
    if (tmStatusGlobal.parent && tmStatusGlobal.parent.includes(tmStatus.id)) {
      tmStatusSelected.push(tmStatusGlobal.id)
    }
  })
}

export const getParseTMStatusCode = (tmStatusCodes, tmStatusList) => {
  const tmStatusSelected = []
  tmStatusList.forEach(tmStatus => {
    if (tmStatusCodes.includes(tmStatus.id)) {
      if (tmStatus.children === true) {
        addChildrenTMStatus(tmStatusList, tmStatus, tmStatusSelected)
      } else {
        tmStatusSelected.push(tmStatus.id)
      }
    }
  })
  return tmStatusSelected
}

const buildBasicSearchParam = (context, basicSearchValue, indicationOfProductValue) => {
  return context === 'dsview'
    ? { [KEY_INDICATION_OF_PRODUCT]: indicationOfProductValue }
    : { basicSearch: basicSearchValue }
}

const obtainParametersToSearch = (
  searchMode,
  advancedSearch,
  formStateFields,
  imageSearchFields,
  favoriteFields,
  page,
  pageSize = 10,
  sortColumn,
  isWipoSearch,
  context
) => {
  const objSortColumn =
    sortColumn && sortColumn.id !== ''
      ? { sortColumn: sortColumn.id, desc: sortColumn.desc === true ? 'true' : 'false' }
      : {}
  let mergeParametres = isWipoSearch === true ? {} : { page, pageSize, ...objSortColumn }

  let {
    basicSearch,
    [KEY_INDICATION_OF_PRODUCT]: indicationOfProduct,
    ...restFormStateFields
  } = formStateFields

  if (searchMode === 'favorite' && advancedSearch === true) {
    // Advanced search mode
    mergeParametres = {
      ...mergeParametres,
      ...restFormStateFields,
      ...buildBasicSearchParam(context, basicSearch, indicationOfProduct),
    }
  } else {
    //Basic search
    mergeParametres = {
      ...mergeParametres,
      criteria: formStateFields.criteria,
      ...buildBasicSearchParam(context, basicSearch, indicationOfProduct),
    }
    //Add favorite fields to basic search
    mergeParametres = {
      ...mergeParametres,
      ...obtainValuesFromFavorites(favoriteFields, formStateFields),
    }
  }
  if (!isWipoSearch && imageSearchFields.imageId) {
    // Image search
    mergeParametres = {
      ...mergeParametres,
      imageId: imageSearchFields.imageId,
      imageName: imageSearchFields.imageName,
      segmentLeft: Math.round(imageSearchFields.segmentLeft),
      segmentRight: Math.round(imageSearchFields.segmentRight),
      segmentTop: Math.round(imageSearchFields.segmentTop),
      segmentBottom: Math.round(imageSearchFields.segmentBottom),
      colour: imageSearchFields.colour,
    }
  }
  return mergeParametres
}

export const hasAdvancedSearchValues = fieldsStringCodes =>
  Object.keys(fieldsStringCodes)
    .filter(field => field !== 'basicSearch' && field !== 'criteria')
    .some(field => !!fieldsStringCodes[field])

export const buildSearchQuery = (
  searchMode,
  advancedSearch,
  formStateFields,
  imageSearchFields,
  mainFields,
  page,
  pageSize = 10,
  sortColumn,
  filtersParam,
  isWipoSearch,
  context
) => {
  const paramObj = obtainParametersToSearch(
    searchMode,
    advancedSearch,
    formStateFields,
    imageSearchFields,
    mainFields,
    page,
    pageSize,
    sortColumn,
    isWipoSearch,
    context
  )
  const filtersParamUrl = filtersParam && !isWipoSearch ? filtersParam : ''
  return `${buildQuery(paramObj)}${filtersParamUrl}`
}

const getFilterParameterKeyForLabelKey = labelKey => {
  if (labelKey === 'offices') {
    return 'fOffices'
  } else if (labelKey === 'niceClass') {
    return 'fNiceClass'
  } else if (labelKey === 'viennaCode') {
    return 'fViennaCodes'
  } else if (labelKey === 'tmStatus') {
    return 'fTMStatus'
  } else if (labelKey === 'tmType') {
    return 'fTMType'
  } else if (labelKey === 'appName') {
    return 'fAName'
  } else if (labelKey === 'applicationDate') {
    return TMDSContextService.isTmview() ? 'fADateRanges' : 'fApplicationDateRanges'
  } else if (labelKey === 'registrationDate') {
    return TMDSContextService.isTmview() ? 'fRDateRanges' : 'fRegDateRanges'
  } else if (labelKey === 'currentlyOpposable') {
    return 'fCOpposable'
  } else if (labelKey === 'seniorityClaimed') {
    return 'fSClaimed'
  } else if (labelKey === KEY_DESIGN_STATUS) {
    return KEY_FILTER_DESIGN_STATUS
  } else if (labelKey === KEY_DESIGNER_NAME) {
    return KEY_FILTER_DESIGNER_NAME
  } else if (labelKey === KEY_LOCARNO_CLASS) {
    return KEY_FILTER_LOCARNO_CLASS
  } else if (labelKey === KEY_OWNER_NAME_EXPLICIT) {
    return KEY_FILTER_OWNER_NAME
  }
}

const getFilterParameterValueForLabelKey = (labelKey, data) => {
  if (
    labelKey === 'offices' ||
    labelKey === 'niceClass' ||
    labelKey === 'viennaCode' ||
    labelKey === 'tmType' ||
    labelKey === 'tmStatus' ||
    labelKey === 'designStatus'
  ) {
    return data
      .filter(el => el.checked)
      .map(el => (labelKey === 'niceClass' && el.code === NO_NICE_CLASS_CODE ? 'EMPTY' : el.code))
      .join(',')
  } else if (labelKey === 'applicationDate' || labelKey === 'registrationDate') {
    return data.reduce(
      (acc, v) =>
        v.checked === true ? `${acc}${acc !== '' ? ',' : ''}${v.dateFrom}..${v.dateTo}` : `${acc}`,
      ''
    )
  } else if (labelKey === 'currentlyOpposable' || labelKey === 'seniorityClaimed') {
    return data.reduce((acc, v) => (v.checked === true || acc === true ? true : acc), false)
  } else if (labelKey === 'appName') {
    return data.filter(el => el.checked).map(el => el.id)
  } else if (labelKey === 'designerName') {
    return data
      .filter(el => el.checked)
      .map(el => el.id)
      .join(',')
  } else if (labelKey === 'ownerName') {
    return data
      .filter(el => el.checked)
      .map(el => el.id)
      .join(',')
  } else if (labelKey === 'locarnoClass') {
    return data
      .filter(el => el.checked)
      .map(el => el.id)
      .join(',')
  }
}

const obtainParametersToFilter = filtersUI =>
  filtersUI.reduce((acc, filterEl) => {
    const parameterKey = getFilterParameterKeyForLabelKey(filterEl.labelKey)
    const parameterValue = getFilterParameterValueForLabelKey(filterEl.labelKey, filterEl.data)

    if (filterEl.isDate && parameterKey) {
      return {
        ...acc,
        [parameterKey]: parameterValue,
      }
    } else if (filterEl.isBoolean && parameterKey) {
      return {
        ...acc,
        [parameterKey]: parameterValue,
      }
    } else if (parameterKey) {
      return {
        ...acc,
        [parameterKey]: parameterValue,
      }
    }
  }, {})

export const buildFilterParams = filtersUI => {
  const paramObj = obtainParametersToFilter(filtersUI)
  return buildQuery(paramObj, '&')
}

const titlesBasicSearch = {
  tradeMarkName: 'tm.details.left.menu.trademark.key',
  registrationNumber: 'registrationnumber.key',
  applicationNumber: 'applicationnumber.key',
  office: 'offices.key',
}

export const adaptBasicSearchToLucentumData = (value, literals) => {
  if (value && value.length > 0) {
    const titles = { disabled: true }
    const keysObject = Object.keys(value[0])
    if (keysObject.length > 2) {
      keysObject.forEach(key => {
        titles[key] = literals[titlesBasicSearch[key]]
      })
      value.splice(0, 0, titles)
    }
  }
  return value
}

export const obtainMainFieldsInResultsSearch = filedsStringCodes => {
  const fieldsWithData = removeNullEmptyOrFalseProperties(filedsStringCodes)
  const fieldQueryParamRelations = {
    adFrom: 'appDate',
    adTo: 'appDate',
    rdFrom: 'regDate',
    rdTo: 'regDate',
    expiryFrom: 'expiryDate',
    expiryTo: 'expiryDate',
    applicationFilingFrom: 'applictionFilingDate',
    applicationFilingTo: 'applictionFilingDate',
    publicationFrom: 'publicationTo',
    publicationTo: 'publicationTo',
    effectiveFrom: 'effectiveTo',
    effectiveTo: 'effectiveTo',
    priorityFrom: 'priorityTo',
    priorityTo: 'priorityTo',
    endOfDefermentFrom: 'adTo',
    endOfDefermentTo: 'adTo',
  }
  const mainFields = new Set()
  Object.keys(fieldsWithData).map(fieldKey => {
    mainFields.add(
      fieldQueryParamRelations[fieldKey] ? fieldQueryParamRelations[fieldKey] : fieldKey
    )
  })
  return [...mainFields]
}

export const adaptDataToLucentumTable = (data, resultsUISelectedTradeMarksList) =>
  Array.isArray(data) && data.length > 0
    ? data.map(el => ({ ...el, checked: resultsUISelectedTradeMarksList.includes(el.ST13) }))
    : []
