import React from 'react'
import { useSelector } from 'react-redux'
import useImage from 'use-image'

import { SELECTION_MODE } from '../ImageSearchNew.constants'
import { AreaSelection } from './AreaSelection'
import { FreeSelection } from './FreeSelection'
import { StyledImageCrop } from './styles'

const imageInfoSelector = ({
  appState: {
    imageSearch: { data },
  },
}) => data

const MAX_WIDTH = 430

const ImageCropComponent = ({
  imageData,
  onUpdateSelection,
  onCloseFreeSelection,
  selectionMode = SELECTION_MODE.AREA,
  initialSelections = { free: [], area: {} },
}) => {
  const [parsedInfo, setParsedInfo] = React.useState({})
  const imageInfo = useSelector(imageInfoSelector)
  const { imageH, imageW } = imageInfo
  const [image] = useImage(imageData)
  const isAreaSelection = selectionMode === SELECTION_MODE.AREA
  const isFreeSelection = selectionMode === SELECTION_MODE.FREE

  React.useEffect(
    () => {
      const isGreater = imageW > MAX_WIDTH
      const width = isGreater ? MAX_WIDTH : imageW
      const scale = isGreater ? MAX_WIDTH / imageW : 1
      const height = imageH * scale
      const nextParsedInfo = {
        width,
        scale,
        height,
      }
      setParsedInfo(nextParsedInfo)
    },
    [imageW]
  )

  return (
    <React.Fragment>
      {parsedInfo.scale && (
        <StyledImageCrop>
          {isAreaSelection && (
            <AreaSelection
              image={image}
              parsedInfo={parsedInfo}
              onUpdateSelection={onUpdateSelection}
              initialSelection={initialSelections.area}
            />
          )}
          {isFreeSelection && (
            <FreeSelection
              image={image}
              parsedInfo={parsedInfo}
              onCloseFreeSelection={onCloseFreeSelection}
              initialSelection={initialSelections.free}
            />
          )}
        </StyledImageCrop>
      )}
    </React.Fragment>
  )
}

export const ImageCrop = ImageCropComponent
