import { t } from '@app/common/translationsService'
import TMForm from '@app/components/TMForm'
import { Block } from '@new-lucentum'
import React from 'react'

import { FIELDS_TMVIEW } from '../../constants'
import { CheckboxWithInput } from '../CheckboxWithInput'

export const AlertFormTmview = ({ values, handleWarningDateValidation, resourceData, isBulk }) => {
  /* const isOppPeriodDisabled =
    (resourceData && (resourceData.oppositionPeriodEndDate || resourceData.oppositionPeriodEnd)) ||
    (isBulk || !resourceData)
      ? false
      : true
  const isExpiryDateDisabled =
    (resourceData && (resourceData.expiryDate || resourceData.expirationDate)) ||
    (isBulk || !resourceData)
      ? false
      : true */

  return (
    <div>
      <Block>
        <CheckboxWithInput
          isDisabled={false}
          field={FIELDS_TMVIEW.OPPOSITION_PERIOD_CLOSE}
          inputField={FIELDS_TMVIEW.OPPOSITION_PERIOD_DAYS}
          values={values}
          label={t('alerts_events_opposition_period_close_to_end')}
          onChangeInput={value => {
            handleWarningDateValidation(
              'oppositionPeriodEndDate',
              FIELDS_TMVIEW.OPPOSITION_PERIOD_DAYS,
              value,
              t('alerts_dropdown_warning_days_higher_left', {
                eventName: t('alerts_events_opposition_period_close_to_end'),
              })
            )
          }}
        />
      </Block>
      <Block>
        <CheckboxWithInput
          isDisabled={false}
          field={FIELDS_TMVIEW.EXPIRATION_DATE_CLOSE}
          inputField={FIELDS_TMVIEW.EXPIRATION_DATE_DAYS}
          values={values}
          label={t('alerts_events_expiration_date_close_to_end')}
          onChangeInput={value => {
            handleWarningDateValidation(
              'expiryDate',
              FIELDS_TMVIEW.EXPIRATION_DATE_DAYS,
              value,
              t('alerts_dropdown_warning_days_higher_left', {
                eventName: t('alerts_events_expiration_date_close_to_end'),
              })
            )
          }}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_TMVIEW.APPLICANT_NAME_CHANGE}
          label={t('alerts_events_applicant_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_TMVIEW.STATUS_CHANGE}
          label={t('alerts_events_status_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_TMVIEW.GOODS_AND_SERVICES_CHANGE}
          label={t('alerts_events_goods_and_services_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_TMVIEW.EXPIRY_DATE_CHANGE}
          label={t('alerts_events_expiry_date_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_TMVIEW.REPRESENTATIVE_CHANGE}
          label={t('alerts_events_representative_change')}
        />
      </Block>
    </div>
  )
}
