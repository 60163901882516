import styled from 'styled-components'

export const StyledPagination = styled.div`
  .rows-per-page {
    font-size: 12px;
    font-weight: 600;
    padding-right: 10px;
  }
  .margin {
    padding: 0 10px 0 5px;
  }
`

export const StyledPushChevronLeft = styled.i`
  & {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: #bec4d0;
    padding: 0 15px 0 15px;
    cursor: pointer;
  }
  &::after,
  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 14px;
    border-left: 2px solid;
    top: 5px;
    left: 0;
    color: #bec4d0;
  }
  &::after {
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    transform: rotate(45deg);
    left: 6px;
    top: 7px;
    color: #bec4d0;
    padding-left: 5px;
`

export const StyledPushChevronRight = styled.i`
  & {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: #bec4d0;
    padding: 0 15px 0 15px;
    cursor: pointer;
  }
  &::after,
  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 14px;
    border-right: 2px solid;
    top: 5px;
    right: 0;
  }
  &::after {
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    right: 6px;
    top: 7px;
    padding-right: 5px;
  }
`

export const StyledChevronLeft = styled.i`
  & {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: #bec4d0;
    cursor: pointer;
  }
  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(45deg);
    left: 6px;
    top: 7px;
  }
`
export const StyledChevronRight = styled.i`
  & {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 10px;
    height: 12px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: #bec4d0;
    padding: 0 15px 0 15px;
    cursor: pointer;
  }
  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    right: 6px;
    top: 7px;
  }
`
