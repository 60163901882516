import React from 'react'
import { hasItemsToShow } from '@app/common/utilities'
import { Box } from './Box'
import { DataList } from './DataItem'
import { ShowMore } from './ShowMore'
import { DataRow } from './styles'
import { totalBoxEntries, getFormatedData } from './utils'
import { EXHIBITION_PRIORITY_FIELDS } from '@app/common/dataManagers/types'
import { t } from '@app/common/translationsService'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'
import { useSelector } from 'react-redux'

const SHOWN_ROWS_LIMIT = 1

const fields = [
  {
    label: 'addresscountrycode.key',
    path: EXHIBITION_PRIORITY_FIELDS.COUNTRY_CODE,
  },
  {
    label: 'tm_details_first_display_date_key',
    path: EXHIBITION_PRIORITY_FIELDS.FIRST_DISPLAY_DATE,
    isDate: true,
  },
  {
    label: 'priority.status.key',
    path: EXHIBITION_PRIORITY_FIELDS.STATUS_CODE,
    pathTranslated: EXHIBITION_PRIORITY_FIELDS.STATUS_CODE_TRANSLATED,
  },
  {
    label: 'addresscity.key',
    path: EXHIBITION_PRIORITY_FIELDS.CITY_NAME,
  },
  {
    label: 'tm.details.label.exhibitionDate.key',
    path: EXHIBITION_PRIORITY_FIELDS.EXHIBITION_DATE,
    isDate: true,
  },
  {
    label: 'tm.details.label.template.paintName.FirstName.key',
    path: EXHIBITION_PRIORITY_FIELDS.NAME,
  },
  {
    label: 'tm.details.label.partialPriority.key',
    path: EXHIBITION_PRIORITY_FIELDS.PARTIAL_PRIORITY,
  },
  {
    label: 'tm.details.label.PartialPriorityIndicator.key',
    path: EXHIBITION_PRIORITY_FIELDS.PARTIAL_PRIORITY_INDICATOR,
  },
]

const BoxExhibitionPriorityComponent = ({
  inidCodes,
  data = {},
  officeExceptionMessage,
  ...props
}) => {
  const [showMore, setShowMore] = React.useState(false)
  const [formatedData, setFormatedData] = React.useState([])
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)

  React.useEffect(
    () => {
      const nextFormatedData =
        data &&
        data.map(dataRow => {
          return getFormatedData(fields, dataRow, inidCodes)
        })
      setFormatedData(nextFormatedData)
      const hasItems =
        Array.isArray(nextFormatedData) && nextFormatedData.some(row => hasItemsToShow(row))
      hasItems !== props.hasItemsToShow &&
        props.setHasItemsToShow('boxExhibitionPriority', hasItems)
    },
    [data, showOriginalLanguageAttributes]
  )
  const toggleShowMore = React.useCallback(() => setShowMore(!showMore), [showMore])

  const BoxData =
    props.hasItemsToShow &&
    formatedData.map((dataRow, index) => {
      if (showMore || index < SHOWN_ROWS_LIMIT)
        return (
          <DataRow key={index}>
            <DataList
              testId="boxExhibitionPriority-show-more2"
              data={dataRow}
              literals={props.literals}
              shownItemsLimit={99}
            />
            {index + 1 !== data.length && showMore && <hr />}
          </DataRow>
        )
    })

  return (
    <Box
      boxId="boxExhibitionPriority"
      title={`${t('tm.details.label.ExhibitionPriority.key')} ${totalBoxEntries(data)}`}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-exhibition-priority-inner"
      officeExceptionMessage={officeExceptionMessage}
    >
      {data && !!data.length && (
        <span>
          {BoxData}
          {props.hasItemsToShow && data.length > SHOWN_ROWS_LIMIT && (
            <ShowMore
              testId="boxExhibitionPriority-show-more"
              showMore={showMore}
              showMoreHandle={toggleShowMore}
            />
          )}
        </span>
      )}
    </Box>
  )
}

export const BoxExhibitionPriority = BoxExhibitionPriorityComponent

// const enhance = compose(
//   withState('showMore', 'setShowMore', false),
//   withHandlers({
//     formatedData: ({ inidCodes }) => dataRow => {
//       return getFormatedData(fields, dataRow, inidCodes)
//     },
//     toggleShowMore: ({ showMore, setShowMore }) => () => setShowMore(!showMore),
//   }),
//   lifecycle({
//     componentDidMount() {
//       const { data, formatedData, setHasItemsToShow } = this.props
//       const hasItemsToShow = hasItemsToShowInArray(data, formatedData)
//       hasItemsToShow !== this.props.hasItemsToShow &&
//         setHasItemsToShow('boxExhibitionPriority', hasItemsToShow)
//     },
//   })
// )

// export default enhance(
//   ({ literals, inidCodes, data = [], hasItemsToShow, formatedData, ...props }) => {
//     const BoxData =
//       hasItemsToShow &&
//       data.map((dataRow, index) => {
//         if (props.showMore || index < SHOWN_ROWS_LIMIT)
//           return (
//             <DataRow key={index}>
//               <DataList
//                 testId="boxExhibitionPriority-show-more2"
//                 data={formatedData(dataRow)}
//                 literals={literals}
//                 shownItemsLimit={99}
//               />
//               {index + 1 !== data.length && props.showMore && <hr />}
//             </DataRow>
//           )
//       })

//     return (
//       <Box
//         boxId="boxExhibitionPriority"
//         title={`${literals['tm.details.label.ExhibitionPriority.key']} ${totalBoxEntries(data)}`}
//         hasItemsToShow={hasItemsToShow}
//         dataTestId="item-detail-exhibition-priority-inner"
//       >
//         {data && !!data.length && (
//           <span>
//             {BoxData}
//             {hasItemsToShow && data.length > SHOWN_ROWS_LIMIT && (
//               <ShowMore
//                 testId="boxExhibitionPriority-show-more"
//                 literals={literals}
//                 showMore={props.showMore}
//                 showMoreHandle={props.toggleShowMore}
//               />
//             )}
//           </span>
//         )}
//       </Box>
//     )
//   }
// )
