import { Field, Form } from '@app/common/formManagement'
import { obtainMainFieldsInResultsSearch } from '@app/common/searchUtilities'
import { removeNullEmptyOrFalseProperties, searchDateFormat } from '@app/common/utilities'
import { FIELDS, JAPAN_VERSION, WIPO_VERSION } from '@app/containers/TMSearch/constants'
import { getInitialValuesByContext } from '@app/containers/TMSearch/utilities'
import { IMAGE_SEARCH_TYPES, LOCARNO_TYPES, RESULTS_TYPES, SEARCH_TYPES } from '@app/redux-types'
import { TMSEARCH_CLEAR_FORM, TMSEARCH_SAVE_FORM } from '@app/redux-types/TMSearch'
import { isNil, join, map, prop, isEmpty as rIsEmpty, zipWith } from 'ramda'
import { useSelector } from 'react-redux'

const AUTOMATICALLY_POPULATED_FIELDS = [
  'criteria',
  'viennaCodeVersion',
  'viennaCodeDefault',
  'niceClassDefault',
]

const isEmpty = param => isNil(param) || rIsEmpty(param)

const defaultErrors = {
  basicSearch: { isValid: true, errorMessage: [] },
  criteria: { isValid: true, errorMessage: [] },
  applicationNumber: { isValid: true, errorMessage: [] },
  registrationNumber: { isValid: true, errorMessage: [] },
  territories: { isValid: true, errorMessage: [] },
  offices: { isValid: true, errorMessage: [] },
  niceClass: { isValid: true, errorMessage: [] },
  viennaCode: { isValid: true, errorMessage: [] },
  viennaCodeVersion: { isValid: true, errorMessage: [] },
  tmStatus: { isValid: true, errorMessage: [] },
  tmType: { isValid: true, errorMessage: [] },
  appName: { isValid: true, errorMessage: [] },
  adFrom: { isValid: true, errorMessage: [] },
  adTo: { isValid: true, errorMessage: [] },
  rdFrom: { isValid: true, errorMessage: [] },
  rdTo: { isValid: true, errorMessage: [] },
  expiryFrom: { isValid: true, errorMessage: [] },
  expiryTo: { isValid: true, errorMessage: [] },
  applicationFilingFrom: { isValid: true, errorMessage: [] },
  applicationFilingTo: { isValid: true, errorMessage: [] },
  publicationFrom: { isValid: true, errorMessage: [] },
  publicationTo: { isValid: true, errorMessage: [] },
  priorityFrom: { isValid: true, errorMessage: [] },
  priorityTo: { isValid: true, errorMessage: [] },
  effectiveFrom: { isValid: true, errorMessage: [] },
  effectiveTo: { isValid: true, errorMessage: [] },
  endOfDefermentFrom: { isValid: true, errorMessage: [] },
  endOfDefermentTo: { isValid: true, errorMessage: [] },
  currentlyOpposable: { isValid: true, errorMessage: [] },
  seniorityClaimed: { isValid: true, errorMessage: [] },
  [FIELDS.LOCARNO]: { isValid: true, errorMessage: [] },
  [FIELDS.LOCARNO_VERSION]: { isValid: true, errorMessage: [] },
}

const defaultForm = {
  basicSearch: '',
  criteria: {},
  applicationNumber: '',
  registrationNumber: '',
  territories: [],
  offices: [],
  niceClass: [],
  niceClassDefault: [],
  viennaCode: [],
  viennaCodeDefault: [],
  viennaCodeVersion: 'wipo',
  tmStatus: [],
  tmType: [],
  appName: [],
  adFrom: null,
  adTo: null,
  rdFrom: null,
  rdTo: null,
  expiryFrom: '',
  expiryTo: '',
  applicationFilingFrom: '',
  applicationFilingTo: '',
  publicationFrom: '',
  publicationTo: '',
  priorityFrom: '',
  priorityTo: '',
  effectiveFrom: '',
  effectiveTo: '',
  endOfDefermentFrom: '',
  endOfDefermentTo: '',
  currentlyOpposable: false,
  seniorityClaimed: false,
  dsStatus: '',
  ipvalue: '',
  [FIELDS.LOCARNO]: { [WIPO_VERSION]: [], [JAPAN_VERSION]: [] },
  [FIELDS.LOCARNO_VERSION]: WIPO_VERSION,
  designerName: [],
  ownerName: [],
}

const defaultFormStringCodes = {
  basicSearch: '',
  criteria: 'C',
  applicationNumber: '',
  registrationNumber: '',
  territories: '',
  offices: '',
  niceClass: '',
  viennaCode: '',
  viennaCodeVersion: 'wipo',
  tmStatus: '',
  tmType: '',
  appName: '',
  adFrom: null,
  adTo: null,
  rdFrom: null,
  rdTo: null,
  expiryFrom: null,
  expiryTo: null,
  applicationFilingFrom: null,
  applicationFilingTo: null,
  publicationFrom: null,
  publicationTo: null,
  priorityFrom: null,
  priorityTo: null,
  effectiveFrom: null,
  effectiveTo: null,
  endOfDefermentFrom: null,
  endOfDefermentTo: null,
  currentlyOpposable: false,
  seniorityClaimed: false,
  dsStatus: '',
  ipvalue: '',
  [FIELDS.LOCARNO]: '',
  [FIELDS.LOCARNO_VERSION]: WIPO_VERSION,
  designerName: '',
  ownerName: '',
}

const initForm = () => {
  return new Form()
    .addField(new Field('criteria', value => value.id, defaultForm.criteria))
    .addField(
      new Field(
        'basicSearch',
        value => (value && !Array.isArray(value) ? value.tradeMarkName : ''),
        defaultForm.basicSearch
      )
    )
    .addField(new Field('applicationNumber', value => value || '', defaultForm.applicationNumber))
    .addField(new Field('registrationNumber', value => value || '', defaultForm.registrationNumber))
    .addField(
      new Field(
        'territories',
        value => (value ? value.map(el => el.id).join(',') : ''),
        defaultForm.territories
      )
    )
    .addField(
      new Field(
        'offices',
        value => (value ? value.map(el => el.id).join(',') : ''),
        defaultForm.offices
      )
    )
    .addField(
      new Field(
        'niceClass',
        value =>
          value
            ? join(
                ',',
                zipWith(
                  (x, y) => (!y ? x : `${y},${x}`),
                  map(prop('num'), value),
                  map(prop('operator'), value)
                )
              )
            : '',
        defaultForm.niceClass
      )
    )
    .addField(
      new Field('niceClassDefault', value => (value ? value : ''), defaultForm.niceClassDefault)
    )
    .addField(
      new Field(
        'viennaCode',
        value =>
          value
            ? join(
                ',',
                zipWith(
                  (x, y) => (!y ? x : `${y},${x}`),
                  map(prop('code'), value),
                  map(prop('operator'), value)
                )
              )
            : '',
        defaultForm.viennaCode
      )
    )
    .addField(
      new Field('viennaCodeDefault', value => (value ? value : ''), defaultForm.viennaCodeDefault)
    )
    .addField(
      new Field('viennaCodeVersion', value => (value ? value : ''), defaultForm.viennaCodeVersion)
    )
    .addField(
      new Field(
        'tmStatus',
        value => (value ? value.map(el => el.id).join(',') : ''),
        defaultForm.tmStatus
      )
    )
    .addField(
      new Field(
        'tmType',
        value => (value ? value.map(el => el.id).join(',') : ''),
        defaultForm.tmType
      )
    )
    .addField(
      new Field('appName', value => value.map(el => (el.text ? el.text : '')), defaultForm.appName)
    )
    .addField(new Field('adFrom', value => (value ? value : ''), defaultForm.adFrom))
    .addField(new Field('adTo', value => (value ? value : ''), defaultForm.adTo))
    .addField(new Field('rdFrom', value => (value ? value : ''), defaultForm.rdFrom))
    .addField(new Field('rdTo', value => (value ? value : ''), defaultForm.rdTo))
    .addField(new Field('currentlyOpposable', null, defaultForm.currentlyOpposable))
    .addField(new Field('seniorityClaimed', null, defaultForm.seniorityClaimed))
}
const form = initForm()

const onValidateField = (state, fieldKey) => {
  //Add unitary fields in the future (for nice and vienna) {fieldKey: {isValid: false, error: errorMessage}}
  const error = {}
  error[fieldKey] = state.formState.errors[fieldKey]
    ? state.formState.errors[fieldKey]
    : { isValid: true, errorMessage: [] }
  return error
}

const defaultState = {
  advancedSearch: false,
  imageSearchMode: true,
  moreFieldsSearch: false,
  searchMode: 'results',
  mainMoreFieldsInSearchResults: [],
  favorites: [],
  formState: {
    showOffices: false,
    showValidation: false,
    form,
    fields: {
      ...defaultForm,
    },
    fieldsStringCodes: {
      ...defaultFormStringCodes,
    },
    isValid: true,
    errors: {
      ...defaultErrors,
    },
    isValidConditional: true,
    conditionalErrors: [],
    onlyActiveStatus: false,
  },
  isEnterBasicSearch: false,
  resetImage: false,
  nonStandaloneFields: [],
  searchFormFormik: null,
}

const setFieldValues = (payload, state) => ({
  ...state,
  formState: {
    ...state.formState,
    fields: {
      ...state.formState.fields,
      ...state.formState.form.setFieldValues(payload, state),
    },
    fieldsStringCodes: {
      ...state.formState.fieldsStringCodes,
      ...state.formState.form.setFieldStringCodesValues(payload),
    },
  },
})

const getPopulatedFields = state => {
  const populatedFieldsStringCodes =
    state && state.formState && state.formState.fieldsStringCodes
      ? removeNullEmptyOrFalseProperties(state.formState.fieldsStringCodes)
      : {}
  return Object.keys(populatedFieldsStringCodes)
}

const isStandingAlone = (fieldToEvaluate, state) => {
  /* Fields populated by default, as criteria, has not to be
   * considered when checking if a fields is standing alone
   */
  const populatedFields = getPopulatedFields(state)
  return (
    populatedFields.includes(fieldToEvaluate) &&
    populatedFields.filter(
      populatedField =>
        populatedField !== fieldToEvaluate &&
        !AUTOMATICALLY_POPULATED_FIELDS.includes(populatedField)
    ).length === 0
  )
}

const thereIsManuallyPopulatedFields = state => {
  /* Fields populated by default, as criteria, is not being
   * considered when checking if a fields is manually populated
   */
  const populatedFields = getPopulatedFields(state)
  return (
    populatedFields.filter(
      populatedField => !AUTOMATICALLY_POPULATED_FIELDS.includes(populatedField)
    ).length > 0
  )
}

const basicSearchValid = state => {
  const hasTrademarkName = state.formState.fields.basicSearch.tradeMarkName
    ? state.formState.fields.basicSearch.tradeMarkName.length > 0
    : false

  const imgData = useSelector(state => state.uiState.imageSearch.imageData)

  const hasImageSearchSelected = state.imageSearchMode && imgData
  return hasTrademarkName || thereIsManuallyPopulatedFields(state) || hasImageSearchSelected
}

const validateIndividualState = (state, fieldToValidate) => {
  const validateField = onValidateField(state, fieldToValidate)
  const nonStandaloneFields = state.nonStandaloneFields

  // Attending to just standalone logic validation, it is true if field is valid.
  const nonStandaloneValidation =
    (nonStandaloneFields && !nonStandaloneFields.includes(fieldToValidate)) ||
    state.formState.fieldsStringCodes[fieldToValidate] === '' ||
    !isStandingAlone(fieldToValidate, state)

  if (fieldToValidate === 'basicSearch') {
    if (basicSearchValid(state)) {
      validateField[fieldToValidate].errorMessage = validateField[
        fieldToValidate
      ].errorMessage.filter(error => error !== 'basic_search_invalid')
    } else {
      if (!validateField[fieldToValidate].errorMessage.includes('basic_search_invalid')) {
        validateField[fieldToValidate].errorMessage = [
          ...validateField[fieldToValidate].errorMessage,
          'basic_search_invalid',
        ]
      }
    }
  }

  if (!nonStandaloneValidation) {
    if (
      !validateField[fieldToValidate].errorMessage.includes(
        'advanced.search.standalone.criteria.key'
      )
    ) {
      validateField[fieldToValidate].errorMessage = [
        ...validateField[fieldToValidate].errorMessage,
        'advanced.search.standalone.criteria.key',
      ]
    }
  } else {
    validateField[fieldToValidate].errorMessage = validateField[
      fieldToValidate
    ].errorMessage.filter(error => error !== 'advanced.search.standalone.criteria.key')
  }

  validateField[fieldToValidate].isValid = validateField[fieldToValidate].errorMessage.length < 1
  const isValid = validateField[fieldToValidate].isValid ? state.formState.isValid : false

  return {
    ...state,
    formState: {
      ...state.formState,
      isValid: isValid,
      errors: {
        ...state.formState.errors,
        ...validateField,
      },
    },
  }
}

const validateAllFields = state =>
  Object.keys(state.formState.fields).reduce(
    (acc, fieldKey) => validateIndividualState(acc, fieldKey),
    state
  )

const validateDateRange = (fromDate, toDate) =>
  searchDateFormat(fromDate) > searchDateFormat(toDate)

const validateEntireForm = (state, validateIndividualFields, validateEntireFields) => {
  let stateValidation = { ...state }
  const populatedFields = getPopulatedFields(state)

  let errorType = []
  //Criteria and other field should be informed
  let isValidConditional = populatedFields.length >= 2
  !isValidConditional && errorType.push('oneFieldAtLeast')

  if (validateIndividualFields) {
    stateValidation = validateAllFields({
      ...state,
      formState: {
        ...state.formState,
        isValid: true,
      },
    })
  }

  stateValidation.formState.isValid = !Object.values(stateValidation.formState.errors).some(
    error => error.isValid === false
  )

  return {
    ...stateValidation,
    formState: {
      ...stateValidation.formState,
      isValidConditional: validateEntireFields === false ? true : isValidConditional,
      conditionalErrors: validateEntireFields === false ? [] : [...errorType],
    },
  }
}

const updateMainSearchFields = state => ({
  ...state,
  mainMoreFieldsInSearchResults: obtainMainFieldsInResultsSearch(state.formState.fieldsStringCodes),
})

const initializeSearchFields = (state = defaultState, selects) => ({
  ...state,
  formState: {
    ...state.formState,
    fields: {
      ...state.formState.fields,
      criteria: !isEmpty(state.formState.fields.criteria)
        ? state.formState.fields.criteria
        : selects.criteria[0],
    },
    fieldsStringCodes: {
      ...state.formState.fieldsStringCodes,
      criteria: state.formState.fieldsStringCodes.criteria
        ? state.formState.fieldsStringCodes.criteria
        : selects.criteria[0].id,
    },
  },
})

export default (state = defaultState, { type, payload, meta }) => {
  const validConditional = {
    formState: {
      ...state.formState,
      isValidConditional: true,
    },
  }
  switch (type) {
    case SEARCH_TYPES.SET_FAVORITES_FROM_COOKIE:
      return { ...state, favorites: payload.favorites }
    case SEARCH_TYPES.REMOVE_FAVORITE_FIELD_FULFILLED:
      return {
        ...state,
        formState: state.advancedSearch
          ? state.formState
          : {
              ...state.formState,
              fields: { ...state.formState.fields, [meta]: defaultForm[meta] },
              fieldsStringCodes: {
                ...state.formState.fieldsStringCodes,
                [meta]: defaultFormStringCodes[meta],
              },
            },
      }
    case 'FETCH_GLOBALS_FULFILLED':
      return { ...state, nonStandaloneFields: payload.noStandalone }

    case 'TOGGLE_ADVANCED_SEARCH':
      return {
        ...state,
        advancedSearch: !state.advancedSearch,
      }
    case 'CLOSE_ADVANCED_SEARCH':
      return {
        ...state,
        advancedSearch: false,
        ...validConditional,
      }
    case 'TOGGLE_IMAGE_SEARCH_MODE':
      return {
        ...state,
        imageSearchMode: !state.imageSearchMode,
        moreFieldsSearch: state.imageSearchMode === false ? false : state.moreFieldsSearch,
        resetImage: true,
        ...validConditional,
      }
    case 'CLOSE_IMAGE_SEARCH_MODE':
      return {
        ...state,
        imageSearchMode: false,
        ...validConditional,
      }
    case 'OPEN_IMAGE_SEARCH_MODE':
      return {
        ...state,
        imageSearchMode: true,
        ...validConditional,
      }
    case 'CLOSE_IMAGE_SEARCH':
      return {
        ...state,
        imageSearchMode: false,
        ...validConditional,
      }
    case 'CLOSE_SEARCH_NOTIFICATION':
      return {
        ...state,
        formState: {
          ...state.formState,
          isValidConditional: true,
          conditionalErrors: [],
        },
      }
    case 'TOGGLE_MORE_FIELDS_SEARCH':
      return {
        ...state,
        moreFieldsSearch: !state.moreFieldsSearch,
        imageSearchMode: state.moreFieldsSearch === false ? false : state.imageSearchMode,
        ...validConditional,
      }
    case 'TOGGLE_OFFICES':
      return {
        ...state,
        formState: {
          ...state.formState,
          showOffices: !state.formState.showOffices,
        },
      }
    case 'CLEAR_SEARCH_FORM':
      const currentCriteria = state.formState.fields.criteria
      return updateMainSearchFields({
        ...state,
        ...defaultState.mainMoreFieldsInSearchResults,
        imageSearchMode: defaultState.imageSearchMode,
        formState: {
          ...defaultState.formState,
          fields: {
            ...defaultState.formState.fields,
            criteria: currentCriteria,
          },
        },
        searchFormFormik: { ...getInitialValuesByContext() },
      })
    case 'SET_SEARCH_FORM_TO_SIMILAR_IMAGE':
      const usedCriteria = state.formState.fields.criteria
      return updateMainSearchFields({
        ...state,
        ...defaultState.mainMoreFieldsInSearchResults,
        imageSearchMode: defaultState.imageSearchMode,
        formState: {
          ...defaultState.formState,
          fields: {
            ...defaultState.formState.fields,
            criteria: usedCriteria,
          },
        },
        searchFormFormik: { ...getInitialValuesByContext(), imageSearch: true },
      })
    case LOCARNO_TYPES.CHANGE_LOCARNO_VERSION:
      return {
        ...state,
        formState: {
          ...state.formState,
          fields: {
            ...state.formState.fields,
            [FIELDS.LOCARNO_VERSION]: payload.value,
          },
        },
      }
    case 'RESET_FORM_STATE':
      return updateMainSearchFields({
        ...state,
        ...defaultState.mainMoreFieldsInSearchResults,
        formState: {
          ...defaultState.formState,
        },
      })
    case 'CHANGE_VIENNA_CODE_VERSION':
      return {
        ...state,
        formState: {
          ...state.formState,
          fields: {
            ...state.formState.fields,
            viennaCodeVersion: payload.value,
          },
        },
      }
    case 'VALIDATE_ENTIRE_FORM_SEARCH':
      return validateEntireForm(state, true, true)
    case 'INITIALIZE_SEARCH_FIELDS':
      return initializeSearchFields(state, payload.selects)
    case TMSEARCH_SAVE_FORM:
      return {
        ...state,
        searchFormFormik: { ...payload },
      }
    case TMSEARCH_CLEAR_FORM:
      return {
        ...state,
        searchFormFormik: { ...getInitialValuesByContext() },
      }
    case 'CHANGE_SEARCH_FORM':
      const stateWithNewValues = setFieldValues(payload, state)
      return updateMainSearchFields(validateEntireForm(stateWithNewValues, true, false))
    case 'CHANGE_SEARCH_FIELDS':
      const newState = { ...state }
      payload.map(fieldData => {
        newState.formState.fields[fieldData.field] = fieldData.value
        newState.formState.fieldsStringCodes[fieldData.field] = fieldData.value
        newState.formState.errors[fieldData.field] = {
          isValid: fieldData.errors.length === 0,
          errorMessage: fieldData.errors,
        }
      })
      return validateEntireForm(state, true, true)
    case 'CHANGE_SEARCH_MODE':
      return {
        ...state,
        searchMode: payload.mode,
        ...validConditional,
      }
    case 'CHANGE_IS_ENTER_BASIC_SEARCH':
      return {
        ...state,
        isEnterBasicSearch: payload,
        ...validConditional,
      }
    case IMAGE_SEARCH_TYPES.SET_RESET_IMAGE:
      return {
        ...state,
        resetImage: true,
        ...validConditional,
      }
    case 'NOT_RESET_IMAGE':
    case RESULTS_TYPES.FETCH_SEARCH_PENDING:
      return {
        ...state,
        resetImage: false,
        ...validConditional,
      }
    case 'ONLY_ACTIVE_STATUS':
      return {
        ...state,
        formState: {
          ...state.formState,
          onlyActiveStatus: payload,
        },
      }
    default:
      return state
  }
}
