import React from 'react'
import { compose } from 'recompose'

import { StyledToggle } from './Toggle.styles'

const ToggleComponent = ({ onChange, isChecked, ...props }) => {
  return (
    <StyledToggle>
      <input
        type="checkbox"
        onChange={event => {
          const nextValue = !isChecked
          onChange(nextValue)
        }}
        checked={isChecked}
      />
      <span className="slider" />
    </StyledToggle>
  )
}

export const Toggle = compose()(ToggleComponent)
