import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import { findDOMNode } from 'react-dom'
import yeast from 'yeast'

import Icon from '../Icon'
import Tooltip from '../Tooltip'
import TextField from '../TextField'
import { Legend, Span } from '../Text'

import { Wrapper, ButtonPin, Error, InputBlock, Disclaimer, Asterisk, Warning } from './styles'

const defaultProps = {
  favoriteTooltip: 'Mark as favorite to move to the top of the form',
}

export default class Fieldset extends React.Component {
  static defaultProps = defaultProps
  state = { blueIcon: false }

  find = () => {
    const node = findDOMNode(this)
    const children = node && node.children
    const { lastChild } = (children || [])[0]
    return lastChild
  }

  id = yeast()

  showTooltip = () => ReactTooltip.show(this.find())

  hideTooltip = () => ReactTooltip.hide(this.find())

  onMouseEnter = () => this.setState({ blueIcon: true })

  onMouseLeave = () => !this.props.favorite && this.setState({ blueIcon: false })

  renderChildren = children =>
    React.Children.map(children, child => {
      if (child) {
        switch (child.type) {
          case TextField:
            return React.cloneElement(child, {
              onFocus: this.showTooltip,
              onBlur: this.hideTooltip,
            })
          case Tooltip:
            return React.cloneElement(child, { id: this.id })
          default:
            return child
        }
      }
    })

  render() {
    const {
      children,
      errorMessage,
      favorite,
      showFavorite,
      label,
      onFavorite,
      showError,
      favoriteTooltip,
      helpTooltip,
      placeHelpTooltip,
      tooltipProps = {},
      showDisclaimer,
      disclaimerMessage,
      required,
      warningMessage,
      showWarning,
      ...props
    } = this.props
    const icon = favorite || this.state.blueIcon ? 'pin-outline' : 'pin'
    const idFavorite = `${this.id}favorite`
    const idHelp = `${this.id}helpTooltip`
    return (
      <Wrapper {...props}>
        <div className="label-block">
          <Legend>
            {showFavorite && (
              <ButtonPin
                borderless
                icon={icon}
                data-tip
                data-for={idFavorite}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onClick={onFavorite}
                favorite={favorite || this.state.blueIcon}
              />
            )}
            <Span>{label}</Span>
            {required && <Asterisk />}
          </Legend>
          {helpTooltip && <Icon help id={idHelp} />}
        </div>
        <InputBlock marginTop className="input-block">
          {this.renderChildren(children)}
        </InputBlock>
        {showFavorite && <Tooltip id={idFavorite}>{favoriteTooltip}</Tooltip>}
        {helpTooltip && (
          <Tooltip place={placeHelpTooltip} id={idHelp} {...tooltipProps}>
            {helpTooltip}
          </Tooltip>
        )}
        <Error isVisible={showError}>{errorMessage}</Error>

        <Warning isVisible={showWarning}>{warningMessage}</Warning>
        <Disclaimer isVisible={showDisclaimer}>{disclaimerMessage}</Disclaimer>
      </Wrapper>
    )
  }
}
