import {
  DSV_APPLICATION_CONTEXT,
  KEY_APPLICATION_DATE,
  KEY_APPLICATION_NUMBER,
  KEY_DESIGN_STATUS,
  KEY_INDICATION_OF_PRODUCT,
  KEY_LOCARNO_CLASS,
  KEY_NICE_CLASS,
  KEY_OFFICE,
  KEY_TM_NAME,
  KEY_TM_OFFICE,
  KEY_TRADE_MARK_STATUS,
  TMV_APPLICATION_CONTEXT,
} from '@app/common/constants'

export const sortableColumnsSearchResults = {
  [TMV_APPLICATION_CONTEXT]: [KEY_NICE_CLASS, KEY_TM_NAME, KEY_TM_OFFICE, KEY_TRADE_MARK_STATUS],
  [DSV_APPLICATION_CONTEXT]: [
    KEY_INDICATION_OF_PRODUCT,
    KEY_DESIGN_STATUS,
    KEY_LOCARNO_CLASS,
    KEY_OFFICE,
  ],
}

export const sortableAlertsTagsColumnsSearchResults = {
  [TMV_APPLICATION_CONTEXT]: [
    KEY_TM_NAME,
    KEY_APPLICATION_DATE,
    KEY_NICE_CLASS,
    KEY_TRADE_MARK_STATUS,
    KEY_TM_OFFICE,
    KEY_APPLICATION_NUMBER,
  ],
  [DSV_APPLICATION_CONTEXT]: [
    KEY_INDICATION_OF_PRODUCT,
    KEY_OFFICE,
    KEY_APPLICATION_NUMBER,
    KEY_APPLICATION_DATE,
    KEY_DESIGN_STATUS,
    KEY_LOCARNO_CLASS,
  ],
}
