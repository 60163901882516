import { searchDataManager } from '@app/common/dataManagers'
import { getContextFromPathname } from '@app/common/utilities'
import { DETAILS_TYPES } from '@app/redux-types'

export const fetchDetail = (id, shouldTranslate = false) => ({
  type: DETAILS_TYPES.FETCH_DETAIL,
  payload: searchDataManager.getResourceDetails(id, shouldTranslate),
  meta: { errors: [500] },
})

export const changeExportCaptcha = value => ({
  type: 'CHANGE_EXPORT_CAPTCHA_DETAIL',
  payload: { recaptchaResponseKey: value },
})

export const resetRecaptchaResponseKey = () => ({
  type: 'RESET_RECAPTCHA_DETAIL',
})

export const setTranslateLanguage = value => ({
  type: 'SET_TRANSLATE_LANGUAGE',
  payload: { languageTranslate: value },
})

export const setOriginalLanguage = value => ({
  type: DETAILS_TYPES.SET_ORIGINAL_LANGUAGE,
  payload: { showOriginalLanguageAttributes: value },
})

export const initDetailNavigationData = (navigationData, trademarkId) => {
  return {
    type: DETAILS_TYPES.INIT_DETAIL_NAVIGATION_DATA,
    payload: { navigationData: navigationData, trademarkId: trademarkId },
  }
}

export const resetDetailNavigationData = () => ({
  type: DETAILS_TYPES.RESET_DETAIL_NAVIGATION_DATA,
})

export const navToDetail = (
  navigationData,
  trademarkId,
  history,
  queryStringObj = {}
) => dispatch => {
  const context = history && getContextFromPathname(history.location.pathname)
  dispatch(initDetailNavigationData(navigationData, trademarkId))
  history && history.push({ pathname: `/${context}/detail/${trademarkId}`, ...queryStringObj })
}

export const setDetailTrademarkId = trademarkId => {
  return {
    type: DETAILS_TYPES.SET_DETAIL_TRADEMARK_ID,
    payload: trademarkId,
  }
}

export const setHasItemsToShow = (box, value) => {
  return {
    type: DETAILS_TYPES.SET_HAS_ITEMS_TO_SHOW,
    payload: { box, value },
  }
}

export const setOfficeExceptionMessages = (officeCode, officeMessages) => {
  return {
    type: DETAILS_TYPES.SET_OFFICE_EXCEPTION_MESSAGES,
    payload: { officeCode, officeMessages },
  }
}

export const setOfficeMetadata = officeMetadata => {
  return {
    type: DETAILS_TYPES.SET_OFFICE_METADATA,
    payload: officeMetadata,
  }
}
