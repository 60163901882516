import styled from 'styled-components'
import { Span } from '@new-lucentum'
import { TRANSLATION_TYPE } from '@app/common/constants'

export const ListTerms = styled(Span)`
  ${({ theme, error }) =>
    error &&
    `
    span::after {
      color: ${theme.colorRed};
      content: ' *'
    }
  `}
`

export const Term = styled(Span)`
  color: ${({ theme, translationType }) =>
    [TRANSLATION_TYPE.GOOGLE_TRANSLATE, TRANSLATION_TYPE.DEEPL].includes(translationType)
      ? theme.colorRed
      : theme.colorGray};
  text-decoration: ${({ error }) => (error ? 'underline' : 'unset')};
`

export const Error = styled(Span)`
  color: ${({ theme }) => theme.colorRed};
`
