import styled from 'styled-components'
import Block from '../Block'

export const Hero = styled(Block)`
  background-color: ${({ theme }) => theme.colorAccent};
  background-repeat: no-repeat;
  background-position-y: 36%;
  background-position-x: 50%;
  min-height: calc(${({ theme }) => theme.size} * 8);
  width: 100%;

  h1 {
    font-family: 'Open Sans Light', sans-serif;
    font-weight: 300;
    font-size: ${({ theme }) => theme.fontSizeDouble};
    line-height: 1.25;
  }

  h2 {
    font-family: 'Open Sans Regular', sans-serif;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize};
    line-height: 1.25;
  }

  img {
    margin-top: 20px;
    width: 55px;
  }
`
