import React from 'react'
import styled from 'styled-components'
import replace from 'react-string-replace'

import { P } from '@new-lucentum'

export const safeHTML = (text, allowedTags) => {
  const joinedTags = allowedTags ? allowedTags.join('|\\/?') : './'
  const regExp = new RegExp(`<(?!\/?${joinedTags}).*?>`, 'g')
  return text ? text.replace(regExp, '') : ''
}

export const safeParseHtml = literal =>
  literal.split('&#13;').map((cur, i) => (
    <P key={i}>
      {replaceIndent(cur)}
      <br />
    </P>
  ))

const replaceIndent = text =>
  replace(text, /&#09; ?(.*)/g, (match, i) => <Span key={i}>{replaceIndent(match)}</Span>)

const Span = styled.span`
  display: block;
  padding-left: 1em;
`
