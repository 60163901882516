import React from 'react'
import { Icon, Flex, Grid } from '@new-lucentum'
import { t } from '@app/common/translationsService'
import betaImage from '@img/beta-word-optimized.png'

export const PoweredByTmvision = () => {
  return (
    <Flex alignItems="center" justify="center">
      <img alt="beta" className="beta" src={betaImage} />
      <Grid.Spacing mr={0.5} />
      <span>{t('image.search.powered.by.link')}</span>
    </Flex>
  )
}
