import { SPINNER_TYPES } from '@app/redux-types'

const defaultState = {
  showing: false,
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SPINNER_TYPES.TOGGLE_SPINNER:
      return {
        ...state,
        showing: payload,
      }
    default:
      return state
  }
}
