import React from 'react'

import Button from './Button'
import Dropdown from './Dropdown'
import Toggle from './Toggle'

export default ({ type, children, ...props }) => {
  switch (type) {
    case 'dropdown':
      return <Dropdown {...props}>{children}</Dropdown>
    case 'toggle':
      return <Toggle {...props}>{children}</Toggle>
    default:
      return (
        <Button type={type} {...props}>
          {children}
        </Button>
      )
  }
}
