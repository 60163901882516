import React from 'react'
import { isEmpty } from 'ramda'

import { BASIC_RECORD_FIELDS } from '@app/common/dataManagers/types'
import { TMDSGlobalsService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { parseData, getFormatedData, parseAddress } from '@app/components/DetailTM/utils'
import { representativeFields, applicantFields, recordFields } from '../sectionsFields'

import { DataList, DataItem } from '@app/components/DetailTM'

import { Block } from '@new-lucentum'
import { RecordalRow, DataRow, SectionTitle } from '../styles'

const publicationDetailFields = [
  {
    label: 'tm.details.label.publicationIdentifier.key',
    path: BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.IDENTIFIER,
  },
  {
    label: 'publicationsection.key',
    path: BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.SECTION,
  },
  {
    label: 'tm.details.label.publicationDate.key',
    path: BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.DATE,
    isDate: true,
  },
]

const PublicationDetails = ({ data }) => {
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    <div>
      {data.map((publicationDetail, index) => {
        return (
          <Block
            key={`${
              publicationDetail[BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.SECTION]
            }-${index}`}
          >
            <SectionTitle>{t('tm.details.label.RecordPublication.key')}</SectionTitle>
            <DataList
              labelLocation="left"
              testId={`applicant-data-${index}`}
              data={getFormatedData(publicationDetailFields, publicationDetail, inidCodes, {
                labelLocation: 'left',
              })}
              shownItemsLimit={99}
            />
          </Block>
        )
      })}
    </div>
  )
}

const ClaimantDetails = ({ data }) => {
  const applicants = data && data[BASIC_RECORD_FIELDS.CLAIMANT_DETAILS.APPLICANTS.KEY]
  const hasApplicants = Array.isArray(applicants) && !!applicants.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasApplicants && (
      <Block>
        <SectionTitle>{t('tm.details.label.claimantDetails.key')}</SectionTitle>
        {applicants.map((applicant, index) => (
          <DataRow key={`applicant-${index}`}>
            <DataList
              labelLocation="left"
              testId={`applicant-data-${index}`}
              data={getFormatedData(applicantFields, parseAddress(applicant), inidCodes, {
                labelLocation: 'left',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const RepresentativeDetails = ({ data }) => {
  const representatives =
    data && data[BASIC_RECORD_FIELDS.REPRESENTATIVE_DETAILS.REPRESENTATIVES.KEY]
  const hasRepresentatives = Array.isArray(representatives) && !!representatives.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasRepresentatives && (
      <Block>
        <SectionTitle>{t('tm.details.label.representativeDetails.key')}</SectionTitle>
        {representatives.map((representative, index) => (
          <DataRow key={`representative-${index}`}>
            <DataList
              testId={`representative-data-${index}`}
              data={getFormatedData(representativeFields, parseAddress(representative), inidCodes, {
                labelLocation: 'left',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const OriginalProprietorDetails = ({ data }) => {
  const applicants = data && data[BASIC_RECORD_FIELDS.ORIGINAL_PROPRIETOR_DETAILS.APPLICANTS.KEY]
  const hasApplicants = Array.isArray(applicants) && !!applicants.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasApplicants && (
      <Block>
        <SectionTitle>{t('tm.details.label.originalProprietorDetails.key')}</SectionTitle>
        {applicants.map((applicant, index) => (
          <DataRow key={`proprietor-applicant-${index}`}>
            <DataList
              testId={`proprietor-applicant-data-${index}`}
              data={getFormatedData(applicantFields, parseAddress(applicant), inidCodes, {
                labelLocation: 'left',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const BeneficiaryDetails = ({ data }) => {
  const applicants = data && data[BASIC_RECORD_FIELDS.BENEFICIARY_DETAILS.APPLICANTS.KEY]
  const hasApplicants = Array.isArray(applicants) && !!applicants.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasApplicants && (
      <Block>
        <SectionTitle>{t('tm.details.label.beneficiaryDetails.key')}</SectionTitle>
        {applicants.map((applicant, index) => (
          <DataRow key={`beneficiary-applicant-${index}`}>
            <DataList
              testId={`beneficiary-applicant-data-${index}`}
              data={getFormatedData(applicantFields, parseAddress(applicant), inidCodes, {
                labelLocation: 'left',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const BeneficiaryRepresentativeDetails = ({ data }) => {
  const representatives =
    data && data[BASIC_RECORD_FIELDS.BENEFICIARY_REPRESENTATIVE_DETAILS.REPRESENTATIVES.KEY]
  const hasRepresentatives = Array.isArray(representatives) && !!representatives.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasRepresentatives && (
      <Block>
        <SectionTitle>{t('tm.details.label.beneficiaryRepresentativeDetails.key')}</SectionTitle>
        {representatives.map((representative, index) => (
          <DataRow key={`beneficiary-representative-${index}`}>
            <DataList
              testId={`beneficiary-representative-data-${index}`}
              data={getFormatedData(representativeFields, parseAddress(representative), inidCodes, {
                labelLocation: 'left',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const RecordalExtraData = ({ data }) => {
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    data && (
      <DataList
        testId={`recordal-extra-data-${data[BASIC_RECORD_FIELDS.IDENTIFIER]}`}
        data={getFormatedData(recordFields, data, inidCodes, {
          labelLocation: 'left',
        })}
        shownItemsLimit={99}
      />
    )
  )
}

export const Recordal = ({ recordal, excerpt }) => {
  const recordalKind = parseData(BASIC_RECORD_FIELDS.KIND, recordal)
  const recordalIdentifier = parseData(BASIC_RECORD_FIELDS.IDENTIFIER, recordal)
  const recordalStatusCode = parseData(BASIC_RECORD_FIELDS.RECORD_CURRENT_STATUS_CODE, recordal)
  const recordalStatusDate = parseData(
    BASIC_RECORD_FIELDS.RECORD_CURRENT_STATUS_DATE,
    recordal,
    true
  )
  const showRecordalRow = !!recordalIdentifier || !!recordalStatusCode || !!recordalStatusDate
  const publicationDetails = recordal[BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.KEY]
  const hasPublicationDetails = Array.isArray(publicationDetails) && !!publicationDetails.length
  const claimantDetails = recordal[BASIC_RECORD_FIELDS.CLAIMANT_DETAILS.KEY]
  const hasClaimantDetails = !isEmpty(claimantDetails)
  const representativeDetails = recordal[BASIC_RECORD_FIELDS.REPRESENTATIVE_DETAILS.KEY]
  const hasRepresentativeDetails = !isEmpty(representativeDetails)
  const originalProprietorDetails = recordal[BASIC_RECORD_FIELDS.ORIGINAL_PROPRIETOR_DETAILS.KEY]
  const hasOriginalProprietorDetails = !isEmpty(originalProprietorDetails)
  const beneficiaryDetails = recordal[BASIC_RECORD_FIELDS.BENEFICIARY_DETAILS.KEY]
  const hasBeneficiaryDetails = !isEmpty(beneficiaryDetails)
  const beneficiaryRepresentativeDetails =
    recordal[BASIC_RECORD_FIELDS.BENEFICIARY_REPRESENTATIVE_DETAILS.KEY]
  const hasBeneficiaryRepresentativeDetails = !isEmpty(beneficiaryRepresentativeDetails)

  return (
    <Block marginBottom={2}>
      <DataItem label={t('tm_details_label_kindOfRecord')} data={recordalKind} bold />
      {showRecordalRow && (
        <RecordalRow>
          <div>
            <DataItem
              label={t('record.identifier.key')}
              data={recordalIdentifier}
              forceItem
              labelOneLine
            />
          </div>
          <div>
            <DataItem
              label={t('priority.status.key')}
              data={recordalStatusCode}
              forceItem
              labelOneLine
            />
          </div>
          <div>
            <DataItem
              label={t('tm.details.label.markCurrentStatusDate.key')}
              data={recordalStatusDate}
              forceItem
              labelOneLine
            />
          </div>
        </RecordalRow>
      )}
      {!excerpt && (
        <div>
          <RecordalExtraData data={recordal} />
          {hasOriginalProprietorDetails && (
            <OriginalProprietorDetails data={originalProprietorDetails} />
          )}
          {hasPublicationDetails && <PublicationDetails data={publicationDetails} />}
          {hasClaimantDetails && <ClaimantDetails data={claimantDetails} />}
          {hasRepresentativeDetails && <RepresentativeDetails data={representativeDetails} />}
          {hasBeneficiaryDetails && <BeneficiaryDetails data={beneficiaryDetails} />}
          {hasBeneficiaryRepresentativeDetails && (
            <BeneficiaryRepresentativeDetails data={beneficiaryRepresentativeDetails} />
          )}
        </div>
      )}
    </Block>
  )
}
