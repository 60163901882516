import React from 'react'
import { Player } from 'video-react'
import styled from 'styled-components'
import soundIcon from '@img/sound_example.png'

const Styled = styled.div`
  width: 100%;
  .video-react-control-bar {
    background: rgba(47, 80, 154, 0.7);
    font-family: ${({ theme }) => theme.fontFamily};
  }

  &.audio {
    .video-react,
    .video-react-poster {
      background-color: white;
    }
  }
`

const audioProperties = {
  fluid: false,
  width: '100%',
  height: 64,
  poster: soundIcon,
}

const MediaPlayerComponent = ({ sourceType = 'video', src, autoPlay = false, ...props }) => {
  const isAudio = sourceType === 'audio'
  return (
    <Styled className={isAudio && 'audio'}>
      <Player
        playsInline
        src={src}
        preload="auto"
        autoPlay={autoPlay}
        {...isAudio && audioProperties}
      />
    </Styled>
  )
}

export const MediaPlayer = MediaPlayerComponent
