const defaultState = {
  availableTags: [],
  tagsByTradeMark: [],
  tagsByTradeMarkManage: [],
  data: {
    tradeMarks: [],
    page: 1,
    totalPages: 1,
    totalResults: 0,
  },
}
const checkedTagByTradeMark = (state, { tm, tag, checked }) =>
  state.tagsByTradeMark.map(currentTms =>
    currentTms.ST13 === tm
      ? {
          ST13: currentTms.ST13,
          tags: checked ? [tag, ...currentTms.tags] : currentTms.tags.filter(x => x.id !== tag.id),
        }
      : currentTms
  )

const updateTagsByTradeMark = (currentTMs, { ST13List, tags }) =>
  currentTMs.map(tagTM => (ST13List.includes(tagTM.ST13) ? { ...tagTM, tags } : tagTM))

const getTagsByAllMarks = (ids, tagsByMarks) => [
  ...tagsByMarks,
  ...ids
    .filter(id => !tagsByMarks.map(tag => tag.ST13).includes(id))
    .map(ST13 => ({ ST13, tags: [] })),
]

export default (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case 'ADD_TAG':
      return {
        ...state,
        availableTags: [{ id: payload, text: payload }, ...state.availableTags],
      }
    case 'UPDATE_TAG_BY_TRADEMARK':
      return {
        ...state,
        tagsByTradeMark: checkedTagByTradeMark(state, payload),
      }

    case 'CHANGE_AVAILABLE_TAGS':
    case 'FETCH_AVAILABLE_TAGS_FULFILLED':
      return {
        ...state,
        availableTags: payload,
      }
    case 'FETCH_TAGS_BY_MARKS_FULFILLED':
      return {
        ...state,
        tagsByTradeMark: getTagsByAllMarks(meta, payload),
      }
    case 'CHANGE_TAGS_FULFILLED':
      return {
        ...state,
        tagsByTradeMark: updateTagsByTradeMark(state.tagsByTradeMark, meta),
      }
    case 'FETCH_TAG_GROUPS_FULFILLED':
      return {
        ...state,
        tagsByTradeMarkManage: payload,
      }
    case 'CHANGE_TAG_GROUP_FULFILLED':
      return {
        ...state,
        tagsByTradeMarkManage: updateTagsByTradeMark(state.tagsByTradeMarkManage, meta),
      }
    case 'FETCH_SEARCH_TM_FULFILLED':
      return {
        ...state,
        data: payload,
      }
    case 'RESET_TAGS_VALUES':
      return defaultState
    default:
      return state
  }
}
