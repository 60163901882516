import * as React from 'react'

import { P } from '../Text'
import Button from '../Button'

import { BackgroundSpinner, Loader } from './styles'

export default ({
  block,
  title,
  textButton,
  theme,
  show = false,
  onClick,
  row,
  size = 2,
  ...props
}) => (
  <BackgroundSpinner show={show} theme={theme} block={block} row={row} {...props}>
    <Loader theme={theme} row={row} size={size} data-test-id="test-loader" />
    {title && <P>{title}</P>}
    {textButton && <Button onClick={onClick} title={textButton} />}
  </BackgroundSpinner>
)
