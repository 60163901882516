import styled from 'styled-components'

const width = ({ theme, small, large, big, verySmall, smaller }) => {
  if (verySmall) return `calc(2 * ${theme.size})`
  else if (smaller) return `calc(3 * ${theme.size})`
  else if (small) return `calc(4 * ${theme.size})`
  else if (large) return `calc(5 * ${theme.sizeLarge})`
  else if (big) return `calc(6 * ${theme.sizeLarge})`
}
const height = ({ theme, heightSmall, heightLarge, heightBig, heightVerySmall, heightSmaller }) => {
  if (heightVerySmall) return `calc(2 * ${theme.size})`
  else if (heightSmaller) return `calc(4 * ${theme.size})`
  else if (heightSmall) return `calc(6 * ${theme.size})`
  else if (heightLarge) return `calc(8 * ${theme.sizeLarge})`
  else if (heightBig) return `calc(10 * ${theme.sizeLarge})`
}

export const Img = styled.img`
  ${({ border, theme }) => border && `border: ${theme.line} solid ${theme.colorGrayAccent}`};
  width: ${width};
  height: ${height};

  ${({ filterGray = 0 }) =>
    filterGray &&
    `
    -webkit-filter: grayscale(${filterGray});
    filter: grayscale(${filterGray});

    &:hover {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
    }
  `}
`
