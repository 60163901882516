import cn from 'classnames'
import React from 'react'
import styled from 'styled-components'

const StyledToggleSwitch = styled.div`
  will-change: transform;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.color};
  display: flex;
  background: white;

  .switch-button {
    display: inline-block;
    background: none;
    width: 49%;
    height: 100%;
    color: ${({ theme }) => theme.color};
    text-transform: uppercase;
    position: relative;
    border: none;
    transition: 0.3s ease all;
    padding: 10px;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }

    &.is-active {
      color: white;
    }
  }

  .active {
    color: #151515;
    background-color: ${({ theme }) => theme.color};
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
    transition: 0.3s ease-out all;
    padding-bottom: 1px;
  }
`

export const ToggleSwitch = ({ leftOption, rightOption, onChange, initialSelected }) => {
  const [selected, setSelected] = React.useState(initialSelected || leftOption.value)
  const handleChange = React.useCallback(
    option => {
      setSelected(option.value)
      onChange && onChange(option)
    },
    [onChange]
  )
  return (
    <StyledToggleSwitch>
      <span className="active" style={{ left: selected === leftOption.value ? '0%' : '50%' }} />
      <button
        type="button"
        onClick={() => handleChange(leftOption)}
        className={cn('switch-button', selected === leftOption.value && 'is-active')}
      >
        {leftOption.label}
      </button>
      <button
        type="button"
        onClick={() => handleChange(rightOption)}
        className={cn('switch-button', selected === rightOption.value && 'is-active')}
      >
        {rightOption.value}
      </button>
    </StyledToggleSwitch>
  )
}
