import { isValidDateValue } from '@app/common/validators'
import { isTmview } from '@app/common/tmdsContextService'
import { COMPARE_TYPES, TRANSLATION_TYPES } from '@app/redux-types'
import { getPlainGSList } from '@app/common/goodsAndServices'
import {
  KEY_INDICATION_OF_PRODUCT,
  KEY_DESIGN_STATUS,
  KEY_OFFICE,
  KEY_APPLICATION_DATE,
  KEY_REGISTRATION_DATE,
  KEY_EXPIRY_DATE,
} from '@app/common/constants'

const defaultState = {
  remainingTradeMarks: -1,
  tradeMarksDetails: [],
  hiddenTrademarks: [],
  remainingDesigns: -1,
  designsDetails: [],
  hiddenDesigns: [],
  languageTranslate: { id: '', title: '' },
  translateNiceClass: [],
  gsTranslateFetching: [],
  gsTranslateFetched: [],
  gsTranslateError: [],
}

const defaultEmptyTrademarkDetail = {
  tradeMark: {},
  applicants: [],
  representatives: [],
  correspondenceAddress: {},
  recordals: {},
  renewals: [],
  goodsAndServicesList: [],
}

const defaultEmptyDesignDetail = {
  design: {},
  applicants: [],
  representatives: [],
  representations: [],
}

const applyLowerCase = field => (field.toLowerCase ? field.toLowerCase() : field)

const compareByField = (field1, field2, sortAscending, sortField) => {
  const compareFields = () =>
    sortField.includes('Date')
      ? { compareField1: new Date(field1), compareField2: new Date(field2), type: 'date' }
      : {
          compareField1: applyLowerCase(field1),
          compareField2: applyLowerCase(field2),
          type: 'other',
        }

  const { compareField1, compareField2, type } = compareFields()

  if (type === 'date' && !isValidDateValue(compareField1)) return 0
  if (type === 'date' && !isValidDateValue(compareField2)) return -1

  const comparison = compareField1 < compareField2 ? -1 : compareField1 > compareField2 ? 1 : -1

  return sortAscending === 'Dsc' ? comparison * -1 : comparison
}

const sortTradeMarks = (tradeMarksDetails, { field: sortField, ascending }) =>
  tradeMarksDetails &&
  Array.isArray(tradeMarksDetails) &&
  sortField &&
  [
    'tmName',
    'markCurrentStatusCode',
    'tmOffice',
    'applicationDate',
    'codeRegistrationDate',
    'expiryDate',
  ].includes(sortField)
    ? tradeMarksDetails.sort(({ tradeMark: tm1 = {} }, { tradeMark: tm2 = {} }) => {
        if (!tm2[sortField]) return -1
        if (!tm1[sortField]) return 0
        return compareByField(tm1[sortField], tm2[sortField], ascending, sortField)
      })
    : tradeMarksDetails

const sortDesigns = (designsDetails, { field: sortField, ascending }) =>
  designsDetails &&
  Array.isArray(designsDetails) &&
  sortField &&
  [
    KEY_INDICATION_OF_PRODUCT,
    KEY_DESIGN_STATUS,
    KEY_OFFICE,
    KEY_APPLICATION_DATE,
    KEY_REGISTRATION_DATE,
    KEY_EXPIRY_DATE,
  ].includes(sortField)
    ? designsDetails.sort(({ design: ds1 = {} }, { design: ds2 = {} }) => {
        if (!ds2[sortField]) return -1
        if (!ds1[sortField]) return 0
        return compareByField(ds1[sortField], ds2[sortField], ascending, sortField)
      })
    : designsDetails

const removeFromGSArray = (GSArray, ST13, niceClass) =>
  GSArray.filter(gs => gs.ST13 !== ST13 || gs.niceClass !== niceClass)

const addToGSArray = (GSArray, ST13, niceClass) => [...GSArray, { ST13, niceClass }]

export default (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case COMPARE_TYPES.FETCH_DETAIL_COMPARE_PENDING:
      return {
        ...state,
        ...((isTmview() && {
          remainingTradeMarks: state.remainingTradeMarks === -1 ? 1 : state.remainingTradeMarks + 1,
        }) || {
          remainingDesigns: state.remainingDesigns === -1 ? 1 : state.remainingDesigns + 1,
        }),
      }
    case COMPARE_TYPES.FETCH_DETAIL_COMPARE_FULFILLED:
      return {
        ...state,
        ...((isTmview() && {
          remainingTradeMarks: state.remainingTradeMarks - 1,
          tradeMarksDetails: [
            ...state.tradeMarksDetails,
            {
              ...defaultEmptyTrademarkDetail,
              ...payload,
              ST13: meta.id,
              goodsAndServicesList: getPlainGSList(payload.goodsAndServicesList),
            },
          ],
        }) || {
          remainingDesigns: state.remainingDesigns - 1,
          designsDetails: [
            ...state.designsDetails,
            {
              ...defaultEmptyDesignDetail,
              ...payload,
              ST13: meta.id,
            },
          ],
        }),
      }
    case COMPARE_TYPES.FETCH_DETAIL_COMPARE_REJECTED:
      const emptyDetail = { ...defaultEmptyTrademarkDetail, ST13: meta.id }
      return {
        ...state,
        ...((isTmview() && {
          remainingTradeMarks: state.remainingTradeMarks - 1,
          tradeMarksDetails: [emptyDetail, ...state.tradeMarksDetails],
        }) || {
          remainingDesigns: state.remainingDesigns - 1,
          designsDetails: [emptyDetail, ...state.designsDetails],
        }),
      }
    case 'HIDE_TRADE_MARK':
      return {
        ...state,
        hiddenTrademarks: [...state.hiddenTrademarks, payload],
      }
    case 'HIDE_DESIGN':
      return {
        ...state,
        hiddenDesigns: [...state.hiddenDesigns, payload],
      }
    case 'SHOW_HIDDEN_TRADE_MARKS':
      return {
        ...state,
        hiddenTrademarks: [],
      }
    case 'SHOW_HIDDEN_DESIGNS':
      return {
        ...state,
        hiddenDesigns: [],
      }
    case 'CLEAN_DETAIL_COMPARE':
      return defaultState

    case 'CHANGE_COMPARE_SORT_BY':
      return {
        ...state,
        ...((isTmview() && {
          tradeMarksDetails: sortTradeMarks(state.tradeMarksDetails, payload.compareSortBy),
        }) || {
          designsDetails: sortDesigns(state.designsDetails, payload.compareSortBy),
        }),
      }

    case 'CLEAR_COMPARE_GS_TRANSLATIONS':
      return {
        ...state,
        translateNiceClass: [],
      }

    case TRANSLATION_TYPES.FETCH_COMPARE_TRANSLATION_PENDING:
      return {
        ...state,
        ...((isTmview() && {
          gsTranslateFetching: addToGSArray(state.gsTranslateFetching, meta.ST13, meta.niceClass),
          gsTranslateFetched: removeFromGSArray(
            state.gsTranslateFetched,
            meta.ST13,
            meta.niceClass
          ),
          gsTranslateError: removeFromGSArray(state.gsTranslateError, meta.ST13, meta.niceClass),
        }) ||
          {}),
      }

    case TRANSLATION_TYPES.FETCH_COMPARE_TRANSLATION_FULFILLED:
      return {
        ...state,
        ...((isTmview() && {
          translateNiceClass: [
            ...state.translateNiceClass.filter(
              item => item.niceClass !== payload.niceClass || item.ST13 !== meta.ST13
            ),
            {
              ...payload,
              ST13: meta.ST13,
              niceClass: meta.niceClass,
            },
          ],
          gsTranslateFetching: removeFromGSArray(
            state.gsTranslateFetching,
            meta.ST13,
            meta.niceClass
          ),
          gsTranslateFetched: addToGSArray(state.gsTranslateFetched, meta.ST13, meta.niceClass),
          gsTranslateError: removeFromGSArray(state.gsTranslateError, meta.ST13, meta.niceClass),
        }) ||
          {}),
      }
    case TRANSLATION_TYPES.FETCH_COMPARE_TRANSLATION_REJECTED:
      return {
        ...state,
        ...((isTmview() && {
          translateNiceClass: [
            ...state.translateNiceClass.filter(
              item => item.niceClass !== meta.niceClass || item.ST13 !== meta.ST13
            ),
            {
              ST13: meta.ST13,
              niceClass: meta.niceClass,
              error: true,
            },
          ],
          gsTranslateFetching: removeFromGSArray(
            state.gsTranslateFetching,
            meta.ST13,
            meta.niceClass
          ),
          gsTranslateFetched: removeFromGSArray(
            state.gsTranslateFetched,
            meta.ST13,
            meta.niceClass
          ),
          gsTranslateError: addToGSArray(state.gsTranslateError, meta.ST13, meta.niceClass),
        }) ||
          {}),
      }
    case 'SET_COMPARE_LANGUAGE_TRANSLATE':
      return {
        ...state,
        languageTranslate: {
          ...state.languageTranslate,
          id: payload,
        },
      }

    default:
      return state
  }
}
