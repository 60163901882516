import { BoxPanel } from '@app/components/DetailTM/Box'
import { Block } from '@new-lucentum'
import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
`

const SkeletonListMobileComponent = () => (
  <Container>
    <ContentLoader
      height={80}
      width={625}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="3" y="20" rx="0" ry="0" width="100%" height="800" />
    </ContentLoader>
  </Container>
)

export default ({ repeat = 1 }) => (
  <Block paddingTop={1}>
    <Block marginTop={2} xmarginBottom={10}>
      <Block innerPadding width12of12>
        {[...Array(repeat)].map((value, index) => (
          <BoxPanel
            key={index}
            minHeight={'80px'}
            style={{
              marginBottom: '15px',
              paddingTop: '12px',
              boxShadow: 'none',
              borderRadius: '0px',
            }}
          >
            <SkeletonListMobileComponent />
          </BoxPanel>
        ))}
      </Block>
    </Block>
  </Block>
)
