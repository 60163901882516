import { TMDSAuthService } from '@app/common/services'

import BackendError from './BackendError'
import deepCopy from './deepCopy'
import { getCookie } from './utilities'

let controller = new AbortController()
let { signal } = controller

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

const buildFilesFormData = (files, clientType) => {
  const formData = new FormData()
  const length = files.length

  for (let i = 0; i < length; i++) {
    formData.append('file', files[i], files[i].name)
  }

  formData.append('clienttype', clientType)

  return formData
}

const defaultContentType = 'application/json; charset=utf-8'

const getCsrfToken = () => {
  const tokenFromCookies = getCookie('XSRF-TOKEN')
  return tokenFromCookies || ''
}

const NETWORK_ERROR = new BackendError(
  {},
  { message: 'generalModalErrorNetwork', type: 'UNRECOVERABLE' }
)

function replacer(key, value) {
  if (typeof value === 'string') {
    return value || null
  }
  return value
}

export default class Api {
  constructor(options = { baseUrl: 'api/', defaultOptions: {} }) {
    this.baseUrl = options.baseUrl
    this.defaultOptions = {
      credentials: 'same-origin',
      ...options.defaultOptions,
    }
    this.defaultOptions.headers = {
      Accept: 'application/json',
      'Content-Type': defaultContentType,
      ...options.defaultOptions.headers,
    }
  }

  getUrl(originalUrl) {
    const url = `${this.baseUrl}${originalUrl}`
    return url.replace('//', '/')
  }

  fetch(url, options) {
    const isUserprefs = url.includes('api/userprefs/')
    const parsedOptions = isUserprefs ? { ...options, redirect: 'manual' } : options
    return fetch(url, parsedOptions)
      .catch(response => {
        if (response?.code !== 20 && response.name !== 'abortError') {
          throw NETWORK_ERROR
        }
      })
      .then(this.parseBody)
      .then(this.checkStatus.bind(this, url, options, isUserprefs))
  }

  parseBody(response) {
    const contentType = response.headers.get('Content-Type')
    let parsePromise

    if (/json/.test(contentType)) {
      parsePromise = response.json()
    } else if (/multipart/.test(contentType)) {
      parsePromise = response.formData()
    } else if (/pdf|xml/.test(contentType)) {
      parsePromise = response.blob()
    } else {
      parsePromise = response.text()
    }
    return parsePromise.then(parsedBody => ({ response, parsedBody }))
  }

  checkStatus(url, originalOptions, isUserprefs, { response, parsedBody }) {
    if (response.ok) {
      const includeHeaders = originalOptions && originalOptions.responseWithHeaders
      const preparedResponse = includeHeaders
        ? {
            headers: response.headers,
            parsedBody,
          }
        : parsedBody
      return preparedResponse
    }

    if (response.type === 'opaqueredirect' && isUserprefs) {
      TMDSAuthService.doLoginCas()
    }

    throw new BackendError(response, parsedBody)
  }

  // Request
  genericRequest(method, originalUrl, options) {
    const url = options.completePath === true ? originalUrl : this.getUrl(originalUrl)
    const opt = { ...deepCopy(this.defaultOptions), ...options, method }
    opt.headers = { ...this.defaultOptions.headers, ...options.headers }

    const csrfToken = getCsrfToken()
    if (csrfToken) opt.headers['X-XSRF-Token'] = csrfToken

    if (options && options.body instanceof FormData) {
      delete opt.headers['Content-Type']
    }

    if (opt.headers['Content-Type'] === defaultContentType) {
      opt.body = JSON.stringify(opt.body, replacer)
    }

    return this.fetch(url, opt)
  }

  get(originalUrl, options = {}) {
    return this.genericRequest('get', originalUrl, {
      ...options,
      headers: { ...options.headers },
    })
  }

  post(originalUrl, options = {}) {
    return this.genericRequest('post', originalUrl, options)
  }

  put(originalUrl, options = {}) {
    return this.genericRequest('put', originalUrl, options)
  }

  patch(originalUrl, options = {}) {
    return this.genericRequest('PATCH', originalUrl, options)
  }

  delete(originalUrl, options = {}) {
    options.headers = options.headers || {}
    options.headers.Accept = options.headers.Accept || '*'
    return this.genericRequest('delete', originalUrl, options)
  }

  uploadFiles(url, files) {
    return this.post(url, {
      body: buildFilesFormData(files),
    })
  }

  abortableRequest(method, originalUrl, options) {
    const url = options.completePath === true ? originalUrl : this.getUrl(originalUrl)
    const opt = { ...deepCopy(this.defaultOptions), ...options, method }
    opt.headers = { ...this.defaultOptions.headers, ...options.headers }
    const csrfToken = getCsrfToken()
    if (csrfToken) opt.headers['X-XSRF-Token'] = csrfToken

    if (options && options.body instanceof FormData) {
      delete opt.headers['Content-Type']
    }

    if (opt.headers['Content-Type'] === defaultContentType) {
      opt.body = JSON.stringify(opt.body, replacer)
    }

    return {
      abort: () => {
        controller.abort()
        controller = new AbortController()
        signal = controller.signal
      },
      ready: () => {
        return fetch(url, { ...opt, signal })
          .catch(response => console.log(response))
          .then(response => {
            if (response) {
              return this.parseBody(response)
            }
          })
          .then(response => {
            if (response) {
              return this.checkStatus(url, options, false, response)
            }
          })
      },
    }
  }

  abortableUploadFiles(url, files, options = {}) {
    let clientType

    if (isMobileDevice()) {
      clientType = 'mobile'
    } else {
      clientType = 'desktop'
    }

    return this.abortableRequest('post', url, {
      ...options,
      body: buildFilesFormData(files, clientType),
    })
  }
}
