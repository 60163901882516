import { MAX_CHARACTERS_TAG_NAME, MIN_CHARACTERS_TAG_NAME } from '@app/common/constants/tags'
import { TMDSGlobalsService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { Button, Checkbox, Flex, Grid } from '@new-lucentum'
import React from 'react'
import CreatableSelect from 'react-select'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import styled from 'styled-components'

import { Styled } from '../TagButton.mobile.styles'

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colorError};
  font-size: 0.85rem;
`

const WarningMessage = styled.span`
  color: ${({ theme }) => theme.colorWarning};
  font-size: 0.85rem;
`

const TagDropdownMenuMobileComponent = props => {
  const {
    applyDisabled,
    selectedTags,
    availableTags,
    handleOnChange,
    handleOnChangeCheckbox,
    handleApplyWithCheckings,
    handleAddClick,
    setInputCurrentValue,
    inputCurrentValue,
    backendError,
    setIsModalOpen,
  } = props
  const {
    tagsConf: { maxTagsPerDocument },
  } = TMDSGlobalsService.get()
  const restAvailableTags = availableTags.filter(
    tag => !selectedTags.some(selectedTag => selectedTag.value === tag.value)
  )
  const orderedTags = [...selectedTags, ...restAvailableTags]
  const hasMoreSelectedThanAllowed = selectedTags.length > maxTagsPerDocument
  const hasMaxAllowedSelected = selectedTags.length > maxTagsPerDocument - 1
  const isAddButtonDisabled =
    inputCurrentValue.length < MIN_CHARACTERS_TAG_NAME ||
    inputCurrentValue.length > MAX_CHARACTERS_TAG_NAME ||
    orderedTags.some(
      selectedTag => selectedTag.value.toLowerCase() === inputCurrentValue.toLowerCase()
    ) ||
    hasMaxAllowedSelected

  const toggleDropdown = () => {
    setIsModalOpen(false)
  }

  return (
    <Styled.DropdownMenuMobile>
      <Flex>
        <div style={{ flex: 1, width: '80%' }}>
          <CreatableSelect
            options={restAvailableTags}
            onChange={value => {
              handleOnChange(value)
            }}
            arrowRenderer={() => null}
            placeholder={t('tags_create_tag_message')}
            noResultsText={false}
            inputProps={{ maxLength: MAX_CHARACTERS_TAG_NAME }}
            onInputChange={inputValue => {
              setInputCurrentValue(inputValue)
              return inputValue
            }}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            // onClose={() => {
            //   setTimeout(() => setInputCurrentValue(''), 1000)
            // }}
            isClearable
            labelKey="value"
            valueKey="id"
            disabled={hasMaxAllowedSelected}
            autosize={false}
            optionClassName="ignore-onclickoutside"
          />
        </div>
        <Grid.Spacing ml={0.5} />
        <Button
          className="button-add"
          primary
          onClick={handleAddClick}
          icon="plus"
          disabled={isAddButtonDisabled}
        />
      </Flex>

      <Grid.Spacing mt className="tags-container">
        {orderedTags.map(tag => {
          const isChecked = selectedTags.some(selectedTag => selectedTag.value === tag.value)
          return (
            <div key={`tag-${tag.id}`} className="tag-item">
              <Checkbox
                label={tag.value}
                value={tag.id}
                checked={isChecked}
                onChange={e => {
                  const isChecked = e.target.checked
                  handleOnChangeCheckbox(isChecked, tag)
                }}
              />
            </div>
          )
        })}
      </Grid.Spacing>

      {hasMaxAllowedSelected && (
        <div>
          <WarningMessage>
            {t('tags_error_max_groups_selected', { number: maxTagsPerDocument })}
          </WarningMessage>
        </div>
      )}

      {backendError && (
        <div>
          <ErrorMessage>{t(backendError)}</ErrorMessage>
        </div>
      )}

      <Grid.Spacing mt />
      <Flex justify="space-around" gap="5px">
        <Button
          type="submit"
          title={t('cancel.key')}
          secondary
          onClick={toggleDropdown}
          disabled={false}
          data-testid="login-button"
          style={{ flex: '1' }}
        />
        <Button
          type="submit"
          title={t('apply.key')}
          primary
          onClick={() => handleApplyWithCheckings()}
          disabled={hasMoreSelectedThanAllowed || (applyDisabled && selectedTags.length === 0)}
          data-testid="edit-alert-button"
          style={{ flex: '1' }}
        />
      </Flex>
    </Styled.DropdownMenuMobile>
  )
}
export const TagDropdownMenuMobile = compose(
  withState('selectedTags', 'setSelectedTags', []),
  withState('inputCurrentValue', 'setInputCurrentValue', ''),
  withState('backendError', 'setBackendError', ''),
  lifecycle({
    componentDidMount() {
      const { setSelectedTags, preloadedValues } = this.props
      preloadedValues && setSelectedTags(preloadedValues)
    },
  }),
  withHandlers({
    handleOnChange: props => value => {
      const { selectedTags, setSelectedTags } = props
      setSelectedTags([...selectedTags, value])
    },
    handleOnChangeCheckbox: props => (isChecked, tag) => {
      const { selectedTags, setSelectedTags } = props
      const nextSelectedTags = isChecked
        ? [...selectedTags, tag]
        : selectedTags.filter(selectedTag => selectedTag.value !== tag.value)
      setSelectedTags(nextSelectedTags)
    },
    handleAddClick: props => () => {
      const { setSelectedTags, selectedTags, inputCurrentValue, setInputCurrentValue } = props
      const option = { id: inputCurrentValue, value: inputCurrentValue, className: 'new' }
      setSelectedTags([...selectedTags, option])
      setInputCurrentValue('')
    },
    handleApplyWithCheckings: props => () => {
      const { handleApply, selectedTags, setBackendError } = props
      handleApply(selectedTags)
        .then(response => {
          setBackendError('')
        })
        .catch(({ response, parsedBody }) => {
          const { errorMessage } = parsedBody
          if (response.status === 422) {
            setBackendError(errorMessage)
          }
        })
    },
  })
)(TagDropdownMenuMobileComponent)
export default TagDropdownMenuMobile
