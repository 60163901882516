import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

import { theme as defaultTheme } from './theme'
import * as utils from './utils'
import svgFont from '../Icon/fonts/Icons.svg'
import ttfFont from '../Icon/fonts/Icons.ttf'
import woffFont from '../Icon/fonts/Icons.woff'

const ThemeGlobalStyles = createGlobalStyle`
    @font-face {
        font-display: fallback;
        font-family: '${defaultTheme.iconFont}';
        src: url('${woffFont}');
        src: url('${ttfFont}') format('truetype'),
            url('${woffFont}') format('woff'),
            url('${svgFont}#Lucentum-icon-set') format('svg');
        font-weight: normal;
        font-style: normal;
    }
`

export default ({ theme, children }) => (
  <ThemeProvider theme={{ ...defaultTheme, ...utils, ...theme }}>{children}</ThemeProvider>
)
