import { set, lensProp } from 'ramda'
import { AUTH_TYPES } from '@app/redux-types'

const lensUser = lensProp('user')
const defaultState = {
  user: {
    email: '',
    fullName: '',
    roles: [],
    systems: [],
    username: '',
  },
}

export default (state = defaultState, { type, payload: user, meta }) => {
  switch (type) {
    case AUTH_TYPES.CHECK_LOGGED_USER_FULFILLED:
    case AUTH_TYPES.FETCH_LOGIN_FULFILLED:
      meta.beforeStoreLogin && meta.beforeStoreLogin(user)
      return {
        user,
      }
    case AUTH_TYPES.FETCH_LOGOUT_FULFILLED:
      return set(lensUser, defaultState.user, state)
    default:
      return state
  }
}
