import React from 'react'
import { isEmpty } from 'ramda'

import { BASIC_RECORD_FIELDS } from '@app/common/dataManagers/types'
import { TMDSGlobalsService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { parseData, getFormatedData, parseAddress } from '../utils'
import { representativeFields, applicantFields, recordFields } from '../fields'

import { DataList, DataItem, Label } from '../DataItem'

import { Block } from '@new-lucentum'
import { RecordalRow, DataRow, SectionTitle, RecordalContainer, RecordalFields } from '../styles'
import { Collapse } from '@app/components/DetailTM'

const publicationDetailFields = [
  {
    label: 'tm.details.label.publicationIdentifier.key',
    path: BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.IDENTIFIER,
  },
  {
    label: 'publicationsection.key',
    path: BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.SECTION,
  },
  {
    label: 'tm.details.label.publicationDate.key',
    path: BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.DATE,
    isDate: true,
  },
]

const PublicationDetails = ({ data }) => {
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    <div>
      {data.map((publicationDetail, index) => {
        return (
          <Block
            key={`${
              publicationDetail[BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.SECTION]
            }-${index}`}
          >
            <SectionTitle>{t('tm.details.label.RecordPublication.key')}</SectionTitle>
            <DataList
              labelLocation="top"
              testId={`applicant-data-${index}`}
              data={getFormatedData(publicationDetailFields, publicationDetail, inidCodes, {
                labelLocation: 'top',
                dataWidth: '50%',
              })}
              shownItemsLimit={99}
            />
          </Block>
        )
      })}
    </div>
  )
}

const ClaimantDetails = ({ data }) => {
  const applicants = data && data[BASIC_RECORD_FIELDS.CLAIMANT_DETAILS.APPLICANTS.KEY]
  const hasApplicants = Array.isArray(applicants) && !!applicants.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasApplicants && (
      <Block>
        <SectionTitle>{t('tm.details.label.claimantDetails.key')}</SectionTitle>
        {applicants.map((applicant, index) => (
          <DataRow key={`applicant-${index}`}>
            <DataList
              labelLocation="top"
              testId={`applicant-data-${index}`}
              data={getFormatedData(applicantFields, parseAddress(applicant), inidCodes, {
                labelLocation: 'top',
                dataWidth: '50%',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const RepresentativeDetails = ({ data }) => {
  const representatives =
    data && data[BASIC_RECORD_FIELDS.REPRESENTATIVE_DETAILS.REPRESENTATIVES.KEY]
  const hasRepresentatives = Array.isArray(representatives) && !!representatives.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasRepresentatives && (
      <Block>
        <SectionTitle>{t('tm.details.label.representativeDetails.key')}</SectionTitle>
        {representatives.map((representative, index) => (
          <DataRow key={`representative-${index}`}>
            <DataList
              testId={`representative-data-${index}`}
              data={getFormatedData(representativeFields, parseAddress(representative), inidCodes, {
                labelLocation: 'top',
                dataWidth: '50%',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const OriginalProprietorDetails = ({ data }) => {
  const applicants = data && data[BASIC_RECORD_FIELDS.ORIGINAL_PROPRIETOR_DETAILS.APPLICANTS.KEY]
  const hasApplicants = Array.isArray(applicants) && !!applicants.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasApplicants && (
      <Block>
        <SectionTitle>{t('tm.details.label.originalProprietorDetails.key')}</SectionTitle>
        {applicants.map((applicant, index) => (
          <DataRow key={`proprietor-applicant-${index}`}>
            <DataList
              testId={`proprietor-applicant-data-${index}`}
              data={getFormatedData(applicantFields, parseAddress(applicant), inidCodes, {
                labelLocation: 'top',
                dataWidth: '50%',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const BeneficiaryDetails = ({ data }) => {
  const applicants = data && data[BASIC_RECORD_FIELDS.BENEFICIARY_DETAILS.APPLICANTS.KEY]
  const hasApplicants = Array.isArray(applicants) && !!applicants.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasApplicants && (
      <Block>
        <SectionTitle>{t('tm.details.label.beneficiaryDetails.key')}</SectionTitle>
        {applicants.map((applicant, index) => (
          <DataRow key={`beneficiary-applicant-${index}`}>
            <DataList
              testId={`beneficiary-applicant-data-${index}`}
              data={getFormatedData(applicantFields, parseAddress(applicant), inidCodes, {
                labelLocation: 'top',
                dataWidth: '50%',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const BeneficiaryRepresentativeDetails = ({ data }) => {
  const representatives =
    data && data[BASIC_RECORD_FIELDS.BENEFICIARY_REPRESENTATIVE_DETAILS.REPRESENTATIVES.KEY]
  const hasRepresentatives = Array.isArray(representatives) && !!representatives.length
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    hasRepresentatives && (
      <Block>
        <SectionTitle>{t('tm.details.label.beneficiaryRepresentativeDetails.key')}</SectionTitle>
        {representatives.map((representative, index) => (
          <DataRow key={`beneficiary-representative-${index}`}>
            <DataList
              testId={`beneficiary-representative-data-${index}`}
              data={getFormatedData(representativeFields, parseAddress(representative), inidCodes, {
                labelLocation: 'top',
                dataWidth: '50%',
              })}
              shownItemsLimit={99}
            />
          </DataRow>
        ))}
      </Block>
    )
  )
}

const RecordalExtraData = ({ data }) => {
  const { inidCodes } = TMDSGlobalsService.get()
  return (
    data && (
      <DataList
        testId={`recordal-extra-data-${data[BASIC_RECORD_FIELDS.IDENTIFIER]}`}
        data={getFormatedData(recordFields, data, inidCodes, {
          labelLocation: 'top',
          dataWidth: '50%',
        })}
        shownItemsLimit={99}
      />
    )
  )
}

export const Recordal = ({ recordal, excerpt, showOriginalLanguageAttributes }) => {
  const [collapsed, setCollapsed] = React.useState(true)
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed])

  const recordalKind = parseData(
    !showOriginalLanguageAttributes && recordal.kindTranslated
      ? BASIC_RECORD_FIELDS.KIND_TRANSLATED
      : BASIC_RECORD_FIELDS.KIND,
    recordal
  )
  const recordalIdentifier = parseData(BASIC_RECORD_FIELDS.IDENTIFIER, recordal)
  const recordalStatusCode = parseData(
    !showOriginalLanguageAttributes && recordal.currentStatusCodeTranslated
      ? BASIC_RECORD_FIELDS.RECORD_CURRENT_STATUS_CODE_TRANSLATED
      : BASIC_RECORD_FIELDS.RECORD_CURRENT_STATUS_CODE,
    recordal
  )
  const recordalStatusDate = parseData(
    BASIC_RECORD_FIELDS.RECORD_CURRENT_STATUS_DATE,
    recordal,
    true
  )
  const showRecordalRow = !!recordalIdentifier || !!recordalStatusCode || !!recordalStatusDate
  const publicationDetails = recordal[BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DETAILS.KEY]
  const hasPublicationDetails = Array.isArray(publicationDetails) && !!publicationDetails.length
  const claimantDetails = recordal[BASIC_RECORD_FIELDS.CLAIMANT_DETAILS.KEY]
  const hasClaimantDetails = !isEmpty(claimantDetails)
  const representativeDetails = recordal[BASIC_RECORD_FIELDS.REPRESENTATIVE_DETAILS.KEY]
  const hasRepresentativeDetails = !isEmpty(representativeDetails)
  const originalProprietorDetails = recordal[BASIC_RECORD_FIELDS.ORIGINAL_PROPRIETOR_DETAILS.KEY]
  const hasOriginalProprietorDetails = !isEmpty(originalProprietorDetails)
  const beneficiaryDetails = recordal[BASIC_RECORD_FIELDS.BENEFICIARY_DETAILS.KEY]
  const hasBeneficiaryDetails = !isEmpty(beneficiaryDetails)
  const beneficiaryRepresentativeDetails =
    recordal[BASIC_RECORD_FIELDS.BENEFICIARY_REPRESENTATIVE_DETAILS.KEY]
  const hasBeneficiaryRepresentativeDetails = !isEmpty(beneficiaryRepresentativeDetails)
  const wipoCodes = recordal[BASIC_RECORD_FIELDS.RECORD_DESIGNATED_COUNTRY_DETAILS.KEY]

  return (
    <RecordalContainer marginBottom={2}>
      {
        <Collapse
          testId={`box-renewals-collapsed`}
          collapsed={collapsed}
          collapseHandle={toggleCollapsed}
        />
      }
      <RecordalFields>
        <DataItem label={t('tm_details_label_kindOfRecord')} data={recordalKind} bold />
        {showRecordalRow && (
          <RecordalRow>
            <div>
              <DataItem
                label={t('record.identifier.key')}
                data={recordalIdentifier}
                forceItem
                labelOneLine
              />
            </div>
            <div>
              <DataItem
                label={t('priority.status.key')}
                data={recordalStatusCode}
                forceItem
                labelOneLine
              />
            </div>
            <div>
              <DataItem
                label={t('tm.details.label.markCurrentStatusDate.key')}
                data={recordalStatusDate}
                forceItem
                labelOneLine
              />
            </div>
          </RecordalRow>
        )}
        {!collapsed && (
          <div>
            <RecordalExtraData data={recordal} />
            {wipoCodes?.designationUnderMadridAgreement?.length > 0 ||
              (wipoCodes?.designationUnderMadridProtocol?.length > 0 && (
                <>
                  <SectionTitle>{t('tm.details.label.countryCodes.key')}</SectionTitle>
                  {wipoCodes?.designationUnderMadridProtocol?.length > 0 && (
                    <>
                      <Label>{t('designationUnderMadridProtocol.key')}</Label>
                      <div>
                        {wipoCodes.designationUnderMadridProtocol.map((country, i) => {
                          return <span key={i}>{(i ? ', ' : '') + country}</span>
                        })}
                      </div>
                    </>
                  )}
                  {wipoCodes?.designationUnderMadridAgreement?.length > 0 && (
                    <>
                      <Label>{t('designationUnderMadridAgreement.key')}</Label>
                      <div>
                        {wipoCodes.designationUnderMadridAgreement.map((country, i) => {
                          return <span key={i}>{(i ? ', ' : '') + country}</span>
                        })}
                      </div>
                    </>
                  )}
                </>
              ))}
            {hasOriginalProprietorDetails && (
              <OriginalProprietorDetails data={originalProprietorDetails} />
            )}
            {hasPublicationDetails && <PublicationDetails data={publicationDetails} />}
            {hasClaimantDetails && <ClaimantDetails data={claimantDetails} />}
            {hasRepresentativeDetails && <RepresentativeDetails data={representativeDetails} />}
            {hasBeneficiaryDetails && <BeneficiaryDetails data={beneficiaryDetails} />}
            {hasBeneficiaryRepresentativeDetails && (
              <BeneficiaryRepresentativeDetails data={beneficiaryRepresentativeDetails} />
            )}
          </div>
        )}
      </RecordalFields>
    </RecordalContainer>
  )
}
