import styled from 'styled-components'

export const DropdownMenu = styled.div`
  width: 250px;

  .tags-container {
    max-height: 200px;
    overflow: auto;
    ${({ theme }) => theme.scroll(theme)};

    .tag-item {
      margin-top: 5px;
    }
  }

  .button-add {
    height: 36px;
    display: flex;
    align-items: center;
  }
`

export const Styled = {
  DropdownMenu,
}
