import '@brainhubeu/react-carousel/lib/style.css'

import ReactCarousel, { Dots } from '@brainhubeu/react-carousel'
import { Image } from '@new-lucentum'
import React from 'react'
import { withState } from 'recompose'

import { CarouselImage } from './CarouselImage'
import { StyledArrow, StyledCarousel } from './styles'

const enhance = withState('index', 'setIndex', 0)

export const Carousel = enhance(
  ({
    smallerChevron,
    imageUrls,
    thumbnailKey = 'thumbnail',
    imageKey = 'image',
    index,
    setIndex,
    onSelectedImageClick,
    maxHeight,
    setActiveImage,
  }) => {
    React.useEffect(
      () => {
        imageUrls && setActiveImage && setActiveImage(imageUrls[index]?.imageURI)
      },
      [index, imageUrls, setActiveImage]
    )

    const Arrow = ({ chevron }) => {
      const chevronIcon = chevron === 'left' ? { chevronLeft: true } : { chevronRight: true }
      const newIndex = chevron === 'left' ? index - 1 : index + 1
      const modifiedIndex =
        newIndex < 0 ? imageUrls.length - 1 : newIndex >= imageUrls.length ? 0 : newIndex

      return (
        <StyledArrow
          fontSizeIcon={smallerChevron}
          {...chevronIcon}
          onClick={() => setIndex(modifiedIndex)}
        />
      )
    }
    const slides = imageUrls.map(image => (
      <CarouselImage src={image[imageKey]} alt="" onSelectedImageClick={onSelectedImageClick} />
    ))

    const thumbnails = imageUrls.map(image => <Image small src={image[thumbnailKey]} />)

    return (
      <StyledCarousel maxHeight={maxHeight}>
        <ReactCarousel
          infinite
          value={index}
          slides={slides}
          onChange={setIndex}
          arrowLeft={imageUrls.length > 1 && <Arrow chevron="left" />}
          arrowRight={imageUrls.length > 1 && <Arrow chevron="right" />}
        />
        {thumbnails.length > 1 && (
          <Dots
            number={thumbnails.length}
            thumbnails={thumbnails}
            value={index}
            onChange={setIndex}
          />
        )}
      </StyledCarousel>
    )
  }
)
