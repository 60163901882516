import React from 'react'

import { useResponsive } from '../hooks'

export const Platform = ({ mobile, desktop, ...props }) => {
  const { isMobile } = useResponsive()
  const [Component, setComponent] = React.useState()
  React.useEffect(
    () => {
      let targetComponent = isMobile ? mobile : desktop

      setComponent(React.lazy(targetComponent))
    },
    [isMobile]
  )

  return <React.Suspense fallback={<div />}>{Component && <Component {...props} />}</React.Suspense>
}

export const selectPlatform = options => {
  const { mobile, desktop } = options
  return props => <Platform mobile={mobile} desktop={desktop} {...props} />
}
