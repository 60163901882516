export const STYLE_SETTINGS = {
  rightButton: {
    position: 'absolute',
    right: '0px',
    zIndex: '0',
  },
  territoriesFieldset: {
    position: 'relative',
  },
  tooltipMaxWidth: {
    width: '20vw',
  },
}
