import React from 'react'

export function useOnClickOutside(ref, handler, isListenerEnabled = true) {
  React.useEffect(
    () => {
      const listener = event => {
        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          event.target.classList.contains('ignore-onclickoutside')
        ) {
          return
        }
        handler(event)
      }

      if (isListenerEnabled) {
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
      }

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler, isListenerEnabled]
  )
}
