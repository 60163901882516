/* eslint-disable */
import 'whatwg-fetch'
import '@babel/polyfill'
import './polyfills'
import '../img/*.(png|gif|jpg|svg)'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import { ThemeProvider } from './new-lucentum'
import { HashRouter } from 'react-router-dom'

import cssGlobal from '../style/default.css'
import * as theme from './theme.js'
import App from './containers/App'
import store from './store'

import svgFont from './new-lucentum/components/Icon/fonts/Icons.svg'
import ttfFont from './new-lucentum/components/Icon/fonts/Icons.ttf'
import woffFont from './new-lucentum/components/Icon/fonts/Icons.woff'

import { includeFonts } from '../style/fonts/fonts.statics'

const GlobalStyle = createGlobalStyle`
  ${cssGlobal};
  ${includeFonts};
  @font-face {
    font-display: fallback;
    font-family: "Lucentum Icons";
    src: url('${woffFont}');
    src: url('${ttfFont}') format('truetype'),
        url('${woffFont}') format('woff'),
        url('${svgFont}#Lucentum-icon-set') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  html,
  body,
  #main {
    height: 100%;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }


  @media print {
    header,footer { 
      display: none; 
    }
  }

  .bold {
    font-weight: bold;
  }
  
  .semi-transparent {
    opacity: 0.5;
  }
`

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <HashRouter>
        <App />
      </HashRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('main')
)
