import { isIE } from '@app/common/utilities'
import React from 'react'

import { Th } from './styles'

export default ({
  label,
  labelKey = '',
  items,
  children,
  scroll,
  custom,
  key,
  customItem,
  stickyHead,
  grayHead,
  headWidth,
  padding,
}) => {
  const INITIAL_LEFT = -2
  const headStyle = !!stickyHead
    ? isIE()
      ? { position: 'relative', left: INITIAL_LEFT + scroll }
      : { position: 'sticky', left: INITIAL_LEFT }
    : { left: INITIAL_LEFT }
  if (headWidth) {
    headStyle.width = headWidth
  }
  return (
    <tr key={key}>
      {/* ie11 doesn't draw dinamically updated styled-components
       * so style inline is used for left instead styled-components*/}
      <Th data-test-id={`th-title-${labelKey}`} style={headStyle} grayHead={grayHead}>
        {!!label ? label : null}
      </Th>
      {custom ? (
        <td colSpan={items ? items.length : 1}>{custom(items)}</td>
      ) : (
        items.map((item, index) => (
          <td key={index}>
            {customItem && labelKey
              ? customItem(item[labelKey], index)
              : labelKey
              ? item[labelKey]
              : children}
          </td>
        ))
      )}
    </tr>
  )
}
