export const ADDRESS_DETAILS = 'addressDetails'
const ADDRESS_DETAILS_FIELDS = {
  ADDRESS: `${ADDRESS_DETAILS}.address`,
  POSTCODE: `${ADDRESS_DETAILS}.postcode`,
  CITY: `${ADDRESS_DETAILS}.city`,
  COUNTY: `${ADDRESS_DETAILS}.county`,
  STATE: `${ADDRESS_DETAILS}.state`,
  COUNTRY_CODE: `${ADDRESS_DETAILS}.countryCode`,
}
export const SHARED_FIELDS = {
  FULL_NAME: 'fullName',
  FULL_ADDRESS: 'fullAddress',
  ADDRESS_DETAILS: ADDRESS_DETAILS_FIELDS,
  ORGANIZATION_NAME: 'organizationName',
  LEGAL_FORM: 'legalForm',
  LEGAL_ENTITY: 'legalEntity',
  LEGAL_ENTITY_TRANSLATED: 'legalEntityTranslated',
  IDENTIFIER: 'identifier',
}
