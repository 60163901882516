import React from 'react'
import yeast from 'yeast'

import { P } from '../Text'
import Block from '../Block'
import Link from '../Link'
import Tooltip from '../Tooltip'
import Fieldset from '../Fieldset'

import {
  TextStyled,
  TitleStyled,
  DayLabel,
  DateContainer,
  MonthYearContainer,
  RowStyled,
  MonthContainer,
  YearContainer,
} from './styles'

import moment from 'moment'
export default ({
  className,
  detailKey = 'detail',
  handlerClick,
  hrefKey = 'href',
  src,
  textKey = 'text',
  titleKey = 'title',
  tooltipKey = 'tooltip',
  splitText = true,
  locale,
  ...props
}) => (
  <div {...props}>
    {src.map((n, index) => {
      const idNews = yeast()
      const date = moment(n[detailKey], 'DD/MM/YYYY')
      const day = date.format('DD')
      const month = date.format('MMM').toUpperCase()
      const year = date.format('YY')
      return (
        <RowStyled index={index} key={`id-${n.id}-${idNews}`} id={n.id}>
          <DateContainer>
            <DayLabel>
              <span>{day}</span>
            </DayLabel>
            <MonthYearContainer>
              <MonthContainer>
                <span>{month}</span>
              </MonthContainer>
              <YearContainer>
                <span>{year}</span>
              </YearContainer>
            </MonthYearContainer>
          </DateContainer>

          <div>
            {n[titleKey] && (
              <TitleStyled>
                {/* <Link href={n[hrefKey]} onClick={e => handlerClick && handlerClick(e, n.id)}>
                  {n[titleKey]}
                </Link> */}
                {n[titleKey]}
              </TitleStyled>
            )}

            <Block data-for={`id-${n.id}-${idNews}`} data-tip>
              <TextStyled
                href={n[hrefKey]}
                onClick={e => handlerClick && handlerClick(e, n.id)}
                splitText={splitText}
              >
                {n[textKey]}
              </TextStyled>
              {n[tooltipKey] && (
                <Tooltip id={`id-${n.id}-${idNews}`}>
                  {Object.keys(n[tooltipKey]).map(t => (
                    <Fieldset key={t} label={t}>
                      <P>{n[tooltipKey][t]}</P>
                    </Fieldset>
                  ))}
                </Tooltip>
              )}
            </Block>
          </div>
        </RowStyled>
      )
    })}
  </div>
)
