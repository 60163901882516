import { percentualDiff } from '@app/common/utilities'
import { Block } from '@new-lucentum'
import React from 'react'
import { compose, withHandlers, withState } from 'recompose'
import styled, { css } from 'styled-components'

import ShowMore from './ShowMore'

const DEFAULT_SHOWN_ITEMS_LIMIT = 4

const enhance = compose(
  withState('showMore', 'setShowMore', false),
  withHandlers({
    toggleShowMore: ({ showMore, setShowMore }) => () => setShowMore(!showMore),
  })
)

const DataBlock = styled(Block)`
  margin-bottom: 10px;
  ${({ dataWidth }) => dataWidth && `width: ${dataWidth};`}
  ${({ labelLocation, labelMargin = '20px' }) =>
    labelLocation === 'left' &&
    `
      display: flex;
      & div:first-child {
        margin-right: ${labelMargin};
      }
    `}
`

export const Label = styled(Block)`
  ${({ labelAlign }) => labelAlign && `text-align: ${labelAlign};`}
  ${({ labelWidth }) => labelWidth && `width: ${labelWidth};`}
  ${({ labelOneLine }) =>
    labelOneLine &&
    css`
      white-space: nowrap;
    `}
  font-size:${({ theme }) => theme.fontSizeSmaller};
  color: ${({ theme }) => theme.colorGrayLight};
`

export const Data = styled(Block)`
  color: ${({ theme }) => theme.colorGray};
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`

export const DataItem = ({
  label,
  data,
  labelLocation = 'top',
  labelWidth,
  labelAlign,
  labelOneLine,
  forceItem,
  bold,
  dataWidth,
}) =>
  (!!data || !!forceItem) && (
    <DataBlock labelLocation={labelLocation} dataWidth={dataWidth}>
      <Label labelWidth={labelWidth} labelAlign={labelAlign} labelOneLine={labelOneLine}>
        {label}
      </Label>
      <Data bold={bold}>{data}</Data>
    </DataBlock>
  )

const DataListComponent = ({
  data,
  shownItemsLimit = DEFAULT_SHOWN_ITEMS_LIMIT,
  shownItemsLimitThreshold,
  testId = '',
  ...props
}) => {
  const filteredData = (Array.isArray(data) && data.filter(item => item.data)) || []
  const totalFilteredItems = filteredData.length
  const maxItemsToShow =
    shownItemsLimitThreshold &&
    shownItemsLimitThreshold > percentualDiff(shownItemsLimit, totalFilteredItems)
      ? totalFilteredItems + 1
      : shownItemsLimit

  return (
    <Block>
      {filteredData.map((item, key) =>
        (!!props.showMore || key < maxItemsToShow) && !item.custom ? (
          <DataItem key={`${key}_${item.key || item.label}`} {...item} />
        ) : (
          item.custom
        )
      )}
      {maxItemsToShow < totalFilteredItems && (
        <ShowMore testId={testId} showMore={props.showMore} showMoreHandle={props.toggleShowMore} />
      )}
    </Block>
  )
}

export const DataList = enhance(DataListComponent)
