import React from 'react'
import { Field } from 'formik'

import { TypeaheadLCT } from '@app/components/SharedStyles'

export default props => <Field {...props} component={Typeahead} />

const Typeahead = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const inputProps = props.inputProps || {}
  const selected = field.value ? [{ [props.labelKey]: field.value }] : []
  const defaultProps = {
    maxHeight: '220px',
    minLength: 3,
    error: errors[field.name],
    touched: touched[field.name],
    selected: selected,
    onChange: value => {
      setFieldValue(field.name, value)
    },
    onBlur: value => {
      const shouldValidateOnBlur = !props.notValidateOnBlur
      setFieldTouched(field.name, true, shouldValidateOnBlur)
      if (props.setValueOnBlur) {
        const preparedValue = value || ''
        setFieldValue(field.name, preparedValue)
      }
    },
  }
  const mergedProps = {
    ...defaultProps,
    ...props,
  }

  return (
    <TypeaheadLCT
      inputProps={{ name: field.name, ...inputProps, onchange: () => {} }}
      {...mergedProps}
    />
  )
}
