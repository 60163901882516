import { DETAILS_TYPES } from '@app/redux-types'

const defaultState = {
  languageTranslate: '',
  recaptchaResponseKey: null,
  showOriginalLanguageAttributes: !!JSON.parse(localStorage.getItem('originalLanguage')),
  hasItemsToShow: {
    boxBasicInfo: false,
    boxGoodsAndServices: false,
    boxViennaCodes: false,
    boxDates: false,
    boxOffice: false,
    boxOwner: false,
    boxRepresentative: false,
    boxCorrespondenceAddress: false,
    boxSeniority: false,
    boxExhibitionPriority: false,
    boxPriority: false,
    boxIRTransformation: false,
    boxPublication: false,
    boxOpposition: false,
    boxRecordals: false,
    boxAppeals: false,
    boxCancellations: false,
    boxRenewals: false,
    boxSound: false,
    boxVideo: false,
    boxThreeDimension: false,
  },
  officesExceptionMessages: {},
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'CHANGE_EXPORT_CAPTCHA_DETAIL':
      return {
        ...state,
        recaptchaResponseKey: payload.recaptchaResponseKey,
      }
    case 'SET_TRANSLATE_LANGUAGE':
      return {
        ...state,
        languageTranslate: payload.languageTranslate,
      }
    case 'SET_HAS_ITEMS_TO_SHOW':
      return {
        ...state,
        hasItemsToShow: {
          ...state.hasItemsToShow,
          [payload.box]: payload.value,
        },
      }
    case 'RESET_RECAPTCHA_DETAIL':
      return {
        ...state,
        recaptchaResponseKey: null,
      }
    case DETAILS_TYPES.SET_ORIGINAL_LANGUAGE:
      return {
        ...state,
        showOriginalLanguageAttributes: payload.showOriginalLanguageAttributes,
      }
    case DETAILS_TYPES.SET_OFFICE_EXCEPTION_MESSAGES:
      return {
        ...state,
        officesExceptionMessages: {
          ...state.officesExceptionMessages,
          [payload.officeCode]: {
            ...state.officesExceptionMessages[payload.officeCode],
            ...payload.officeMessages,
          },
        },
      }
    default:
      return state
  }
}
