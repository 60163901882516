import React from 'react'
import camel from 'lodash.camelcase'
import styled, { css } from 'styled-components'

import * as icons from './icons'

const Icon = styled.i`
  font-family: 'Lucentum Icons', Helvetica, sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: ${props =>
      icons[
        Object.keys(props)
          .map(camel)
          .find(name => icons[name])
      ]};
  }
  &:not(:only-child) {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.sizeSmall};
    }
  }
  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.sizeSmall};
  }
`

const MultiPathIcon = styled.span`
  font-family: 'Lucentum Icons', Helvetica, sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  i {
    font-style: normal;
  }
`

const Path = styled.i`
  color: ${({ color }) => color};
  &::before {
    content: ${({ content }) => content};
    ${({ index }) =>
      index > 0 &&
      css`
        margin-left: -1em !important;
      `};
  }
`

const tooltip = id => (!!id ? { 'data-tip': true, 'data-for': id } : {})

export default ({ id, color, ...props }) => {
  const iconContent =
    icons[
      Object.keys(props)
        .map(camel)
        .find(name => icons[name])
    ]

  return Array.isArray(iconContent) ? (
    <MultiPathIcon className={props.className}>
      {iconContent.map((contentData, index) => {
        const targetColor = color || contentData.color
        return <Path key={index} content={contentData.content} color={targetColor} index={index} />
      })}
    </MultiPathIcon>
  ) : (
    <Icon {...tooltip(id)} {...props} />
  )
}
