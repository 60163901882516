import { TMDSGlobalsService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import React from 'react'

export const OfficesSupported = () => {
  const { tmvisionOffices } = TMDSGlobalsService.get()

  return (
    <React.Fragment>
      {t('image.search.beta.support')} {tmvisionOffices.join(', ')}
    </React.Fragment>
  )
}
