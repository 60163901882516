export const prepareTagsSearchQuery = (st13List, filterParams) => {
  const queryObject = {
    ...filterParams,
    criteria: 'C',
    st13s: st13List,
  }
  const queryString = Object.keys(queryObject)
    .map(key => `${key}=${queryObject[key]}`)
    .join('&')

  return { queryString, queryObject }
}
