import Tooltip from 'react-tooltip'
import styled, { css } from 'styled-components'

const borderTooltip = (colorBorder, colorBackground) => css`
  border-radius: ${({ theme }) => theme.radius};
  color: ${({ theme }) => theme.colorGray};
  opacity: 1;
  padding: ${({ theme }) => theme.size};
  &.place-top:before,
  &.place-top:after,
  &.place-bottom:before,
  &.place-bottom:after {
    border-left-width: ${({ theme }) => theme.sizeSmaller};
    border-right-width: ${({ theme }) => theme.sizeSmaller};
    margin-left: -${({ theme }) => theme.sizeSmaller};
  }
  &.place-top {
    &:after {
      border-top-color: ${colorBorder};
    }
    &:before {
      border-top: solid 6px ${colorBackground};
      bottom: -5px;
      z-index: 100;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: ${colorBorder};
    }
    &:before {
      border-bottom: solid 6px ${colorBackground};
      top: -5px;
      z-index: 100;
    }
  }
  &.place-left:before,
  &.place-left:after,
  &.place-right:before,
  &.place-right:after {
    border-bottom-width: ${({ theme }) => theme.sizeSmaller};
    border-top-width: ${({ theme }) => theme.sizeSmaller};
    margin-top: -${({ theme }) => theme.sizeSmaller};
  }
  &.place-left {
    &:after {
      border-left-color: ${colorBorder};
    }
    &:before {
      border-left: solid 6px ${colorBackground};
      right: -5px;
      z-index: 100;
    }
  }

  &.place-right {
    &:after {
      border-right-color: ${colorBorder};
    }
    &:before {
      border-right: solid 6px ${colorBackground};
      left: -5px;
      z-index: 100;
    }
  }
`

export default styled(Tooltip)`
  box-shadow: ${({ theme }) => theme.dropShadow};
  &.type-dark {
    ${({ theme }) => borderTooltip(theme.colorGrayAccent, theme.colorGrayLighter)};
    background-color: ${({ theme }) => theme.colorWhite};
    border: solid ${({ theme }) => `${theme.line} ${theme.colorGrayAccent}`};
    font-size: ${({ theme }) => theme.fontSizeSmaller};
  }
  &.type-warning {
    ${({ theme }) => borderTooltip(theme.colorWarning, theme.colorWarningAccent)};
    background-color: ${({ theme }) => theme.colorWarningAccent};
    border: solid ${({ theme }) => `${theme.line} ${theme.colorWarning}`};
    visibility: visible;
  }
  &.type-error {
    ${({ theme }) => borderTooltip(theme.colorError, theme.colorErrorAccent)};
    background-color: ${({ theme }) => theme.colorErrorAccent};
    border: solid ${({ theme }) => `${theme.line} ${theme.colorError}`};
    visibility: visible;
  }
`
