import React, { Component } from 'react'
import camel from 'lodash.camelcase'

import Icon from '../Icon'
import { H1, H2 } from '../Text'
import { BlockStyled, BlockTitle, BlockDown } from './styles'

export default class extends Component {
  static defaultProps = {
    collapsed: false,
  }

  state = {
    collapsed: !!this.props.expanded && !!this.props.collapsed,
  }

  UNSAFE_componentWillReceiveProps({ expanded = true }) {
    expanded !== this.state.expanded && this.setState({ expanded, collapsed: !expanded })
  }

  handleClick = () => {
    !this.props.icon &&
      this.props.title &&
      this.setState(({ collapsed }) => ({ collapsed: !collapsed }))
    this.props.onToggle && this.props.onToggle(!this.state.collapsed)
  }

  render() {
    const { children, className, expanded, icon, small, title, onToggle, ...props } = this.props

    return (
      <BlockStyled {...props} wrapper className={className}>
        <BlockTitle small={small} onClick={this.handleClick} collapsed={this.state.collapsed}>
          {icon && !expanded && <Icon {...{ [camel(icon)]: true }} />}
          {!icon && !expanded && title && <Icon chevron-down-small />}
          {expanded && title && <Icon chevron-down-small />}
          {small ? <H2>{title}</H2> : <H1>{title}</H1>}
        </BlockTitle>

        <BlockDown lessWidth {...props} collapsed={this.state.collapsed}>
          {children}
        </BlockDown>
      </BlockStyled>
    )
  }
}
