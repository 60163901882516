import { APPLICANTS_FIELDS } from './applicants'
import { REPRESENTATIVES_FIELDS } from './representatives'

class RECORD_DESIGNATED_COUNTRY_DETAILS {
  static KEY = 'designatedCountryDetails'
  static PROTOCOL = 'designationUnderMadridProtocol'
  static AGREEMENT = 'designationUnderMadridAgreement'
  static RECORD_DESIGNATED_COUNTRY_CODE = 'recordDesignatedCountryCode'
  static DESIGNATED_COUNTRY_CODE = 'designatedCountryCode'
}

class RECORD_PUBLICATION_DETAILS {
  static KEY = 'publicationDetails'
  static IDENTIFIER = 'identifier'
  static SECTION = 'section'
  static DATE = 'date'
  static PAGE = 'page'
}

class CLASS_DESCRIPTIONS {
  static KEY = 'classDescriptions'
  static CLASS_DESCRIPTION = 'classDescription'
  static CLASS_LIMITATION_CODE = 'classLimitationCode'
  static GOODS_SERVICES_DESCRIPTION = 'goodServicesDescription'
}

class GOODS_AND_SERVICES_LIMITATION {
  static KEY = 'goodAndServicesLimitation'
  static LIMITATION_COUNTRY_CODES = 'limitationCountryCodes'
  static LIMITATION_CODE = 'limitationCode'
  static CLASS_DESCRIPTIONS = CLASS_DESCRIPTIONS
}

class ORIGINAL_PROPRIETOR_DETAILS {
  static KEY = 'originalProprietorDetails'
  static APPLICANTS = APPLICANTS_FIELDS
  static REPRESENTATIVES = REPRESENTATIVES_FIELDS
}

class CLAIMANT_DETAILS {
  static KEY = 'claimantDetails'
  static APPLICANTS = APPLICANTS_FIELDS
  static REPRESENTATIVES = REPRESENTATIVES_FIELDS
}

class REPRESENTATIVE_DETAILS {
  static KEY = 'representativeDetails'
  static APPLICANTS = APPLICANTS_FIELDS
  static REPRESENTATIVES = REPRESENTATIVES_FIELDS
}

class BENEFICIARY_DETAILS {
  static KEY = 'beneficiaryDetails'
  static APPLICANTS = APPLICANTS_FIELDS
  static REPRESENTATIVES = REPRESENTATIVES_FIELDS
}

class BENEFICIARY_REPRESENTATIVE_DETAILS {
  static KEY = 'beneficiaryRepresentativeDetails'
  static APPLICANTS = APPLICANTS_FIELDS
  static REPRESENTATIVES = REPRESENTATIVES_FIELDS
}

class BASIC_RECORD_EVENT {
  static KEY = 'basicRecordEvent'
  static BASIC_RECORD_EVENT_DATE = 'basicRecordEventDate'
  static BASIC_RECORD_EVENT_CODE = 'basicRecordEventCode'
}

class RECORD_TO_REGISTRATION {
  static KEY = 'recordToRegistration'
  static IDENTIFIER = 'identifier'
  static MARK_VERBAL_ELEMENT_TEXT = 'markVerbalElementText'
}

class RECORD_FROM_REGISTRATION {
  static KEY = 'recordFromRegistration'
  static IDENTIFIER = 'identifier'
  static MARK_VERBAL_ELEMENT_TEXT = 'markVerbalElementText'
}

class NATIONAL_MARK {
  static KEY = 'nationalMark'
  static REGISTRATION_NUMBER = 'registrationNumber'
  static FILING_DATE = 'filingDate'
  static REGISTRATION_DATE = 'registrationDate'
  static PRIORITY_DATE = 'priorityDate'
}

class LICENCE_COUNTRIES {
  static KEY = 'licenceCountries'
  static COUNTRY_CODE = 'countryCode'
  static TERRITORY_LIMITATION_TEXT = 'territoryLimitationText'
}

class LICENSE_GOODS_SERVICES_COUNTRIES_SET {
  static LICENCE_COUNTRIES = LICENCE_COUNTRIES
  static CLASS_NUMBER = 'classNumber'
  static GOODS_SERVICES_DESCRIPTION = 'goodServicesDescription'
  static CLASS_LIMITATION_CODE = 'classLimitationCode'
}

class LICENCE_DETAILS {
  static KEY = 'licenceDetails'
  static LICENCE_KIND = 'licenceKind'
  static PERIOD_LIMITATION_TEXT = 'periodLimitationText'
  static LICENSE_GOODS_SERVICES_COUNTRIES_SET = LICENSE_GOODS_SERVICES_COUNTRIES_SET
}

class BASIC_REGISTRATION_APPLICATION_DETAILS {
  static KEY = 'basicRegistrationApplicationDetails'
  static REGISTRATION_OFFICE_CODE = 'regitrationOfficeCode'
  static BASIC_APPLICATION_NUMBER = 'basicApplicationNumber'
  static BASIC_APPLICATION_DATE = 'basicApplicationDate'
  static BASIC_REGISTRATION_NUMBER = 'basicRegistrationNumber'
  static BASIC_REGISTRATION_DATE = 'basicRegistrationDate'
}

export class BASIC_RECORD_FIELDS {
  static KIND = 'kind'
  static KIND_TRANSLATED = 'kindTranslated'
  static IDENTIFIER = 'identifier'
  static REFERENCE = 'reference'
  static RECORD_FILING_DATE = 'filingDate'
  static RECORD_NOTIFICATION_DATE = 'notificationDate'
  static RECORD_EFFECTIVE_DATE = 'effectiveDate'
  static RECORD_PUBLICATION_DATE = 'publicationDate'
  static RECORD_CURRENT_STATUS_CODE = 'currentStatusCode'
  static RECORD_CURRENT_STATUS_CODE_TRANSLATED = 'currentStatusCodeTranslated'
  static RECORD_CURRENT_STATUS_DATE = 'currentStatusDate'
  static FACT_DECISION_TEXT = 'factDecisionText'
  static RECORD_INTERESTED_OFFICE_CODE = 'interestedOfficeCode'
  static RECORD_USE_INTENT_COUNTRY_CODE = 'useIntentCountryCode'
  static RECORD_PUBLICATION = 'recordPublication'
  static RECORD_OPPOSITION_PERIOD_START_DATE = 'recordOppositionPeriodStartDate'
  static RECORD_OPPOSITION_PERIOD_END_DATE = 'recordOppositionPeriodEndDate'
  static PROTOCOL = 'designationUnderMadridProtocol'
  static RECORD_DESIGNATED_COUNTRY_DETAILS = RECORD_DESIGNATED_COUNTRY_DETAILS
  static RECORD_PUBLICATION_DETAILS = RECORD_PUBLICATION_DETAILS
  static GOODS_AND_SERVICES_LIMITATION = GOODS_AND_SERVICES_LIMITATION
  static ORIGINAL_PROPRIETOR_DETAILS = ORIGINAL_PROPRIETOR_DETAILS
  static CLAIMANT_DETAILS = CLAIMANT_DETAILS
  static REPRESENTATIVE_DETAILS = REPRESENTATIVE_DETAILS
  static BENEFICIARY_DETAILS = BENEFICIARY_DETAILS
  static BENEFICIARY_REPRESENTATIVE_DETAILS = BENEFICIARY_REPRESENTATIVE_DETAILS
  static BASIC_RECORD_EVENT = BASIC_RECORD_EVENT
  static RECORD_TO_REGISTRATION = RECORD_TO_REGISTRATION
  static RECORD_FROM_REGISTRATION = RECORD_FROM_REGISTRATION
  static NATIONAL_MARK = NATIONAL_MARK
  static LICENCE_DETAILS = LICENCE_DETAILS
  static BASIC_REGISTRATION_APPLICATION_DETAILS = BASIC_REGISTRATION_APPLICATION_DETAILS
}
