import styled from 'styled-components'

import { inputAfter, inputFocus, inputWrapper } from '../ThemeProvider/utils'

export const Radio = styled.div`
  ${({ theme }) => inputWrapper(theme)};
  & > label > input {
    ${({ theme }) => inputFocus(theme)};
    margin-right: 7px;
    &:after {
      ${({ theme }) => inputAfter(theme)};
      border-radius: 50%;
      margin-left: -2px;
      margin-top: -2px;
    }
    &:checked:after {
      width: 7px;
      height: 7px;
      border: 5px solid ${({ theme }) => theme.color};
      color: ${({ theme }) => theme.colorWhite};
      background-color: ${({ theme }) => theme.colorWhite};
      font-family: ${({ theme }) => theme.iconFont};
      line-height: ${({ theme }) => theme.size};
    }
  }
`
