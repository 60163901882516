class previousRegistration {
  static key = 'previousRegistration'
  static registrationDate = `${previousRegistration.key}.registrationDate`
  static registrationNumber = `${previousRegistration.key}.registrationNumber`
  static registrationOfficeCode = `${previousRegistration.key}.registrationOfficeCode`
}

export class DESIGNS_FIELDS {
  static KEY = 'design'
  static applicationDate = 'applicationDate'
  static applicationLanguageCode = 'applicationLanguageCode'
  static applicationNumber = 'applicationNumber'
  static applicationReference = 'applicationReference'
  static asItIsprotectionIndicator = 'asItIsprotectionIndicator'
  static basicRegistrationApplication = 'basicRegistrationApplication'
  static codeRegistrationDate = 'codeRegistrationDate'
  static codeTradeDistinctiveness = 'codeTradeDistinctiveness'
  static comments = 'comments'
  static designatedCountryCodes = 'designatedCountryCodes'
  static designationUnderMadridProtocol = 'designationUnderMadridProtocol'
  static designationUnderMadridProtocol96 = 'designationUnderMadridProtocol96'
  static elementText = 'elementText'
  static expiryDate = 'expiryDate'
  static fillingPlace = 'fillingPlace'
  static formerApplicationNumber = 'formerApplicationNumber'
  static goodAndServices = 'goodAndServices'
  static internationalMarkKey = 'internationalMarkKey'
  static iprKind = 'iprKind'
  static iprKindTranslated = 'iprKindTranslated'
  static kindMark = 'kindMark'
  static kindMarkTranslated = 'kindMarkTranslated'
  static currentStatusCode = 'currentStatusCode'
  static currentStatusCodeTranslated = 'currentStatusCodeTranslated'
  static currentStatusDate = 'currentStatusDate'
  static markDisclaimer = 'markDisclaimer'
  static markFeature = 'markFeature'
  static markFeatureTranslated = 'markFeatureTranslated'
  static markImageURI = 'markImageURI'
  static markImageColourClaimedText = 'markImageColourClaimedText'
  static markTranslation = 'markTranslation'
  static markTransliteration = 'markTransliteration'
  static markUseIntentCountryCode = 'markUseIntentCountryCode'
  static oppositionPeriodStartDate = 'oppositionPeriodStartDate'
  static oppositionPeriodEndDate = 'oppositionPeriodEndDate'
  static oppositionStartDate = 'oppositionStartDate'
  static oppositionEndDate = 'oppositionEndDate'
  static previousRegistration = previousRegistration
  static receivingOfficeCode = 'receivingOfficeCode'
  static receivingOfficeDate = 'receivingOfficeDate'
  static receivingOfficeNumber = 'receivingOfficeNumber'
  static registrationNumber = 'registrationNumber'
  static registrationOfficeCode = 'registrationOfficeCode'
  static secondLanguageCode = 'secondLanguageCode'
  static seniorities = 'seniorities'
  static specialCircumstanceText = 'specialCircumstanceText'
  static terminationDate = 'terminationDate'
  static tmName = 'designDescription'
  static tmOffice = 'dsOffice'
  static totalMarkSeries = 'totalMarkSeries'
  static tradeDistinctivenessIndicator = 'tradeDistinctivenessIndicator'
  static tradeDistinctivenessText = 'tradeDistinctivenessText'
  static viennaClassification = 'viennaClassification'
  static endOfDefermentDate = 'endOfDefermentDate'
  static effectiveDate = 'effectiveDate'
  static internationalDesignIndicator = 'internationalDesignIndicator'
  static previousApplicantName = 'previousApplicantName'
  static designNumber = 'designNumber'
  static designerName = 'designerName'
  static effectiveDate = 'effectiveDate'
  static exhibitionPriority = 'exhibitionPriority'
  static expiryDate = 'expiryDate'
  static office = 'office'
  static priorityCountry = 'priorityCountry'
  static priorityDate = 'priorityDate'
  static priorityNumber = 'priorityNumber'
  static registrationDate = 'registrationDate'
  static registrationNumber = 'registrationNumber'
  static representativeName = 'representativeName'
  static verbalElement = 'verbalElement'
  static applicationPublicationDate = 'applicationPublicationDate'
  static publicationDate = 'publicationDate'
  static designDescription = 'designDescription'
  static renewalCurrentStatusCode = 'renewalCurrentStatusCode'
  static applicationReference = 'applicationReference'
  static comment = 'comment'
  static statements = 'statements'
}
