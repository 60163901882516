import styled, { css } from 'styled-components'

const cssMd = value => css`
  width: ${value};
`

const cssContentWidth = css`
  flex-grow: 0;
  flex-wrap: wrap;
`

const StyledCol = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  ${({ md, contentWidth, centerVertical, bottomVertical }) => css`
    ${contentWidth && cssContentWidth}
    ${centerVertical &&
      css`
        align-self: center;
      `}
      ${bottomVertical &&
        css`
          align-self: flex-end;
        `}
  `}
`

export const Col = StyledCol
