import * as React from 'react'

import { Wrapper } from './styles'

const Checkbox = ({ className, label, children, isChild, partially, ...props }) => {
  const checkboxChild = children =>
    React.Children.map(
      children,
      child => child.type === Checkbox && React.cloneElement(child, { isChild: true })
    )

  return (
    <Wrapper
      className={className}
      disabled={props.disabled}
      isChild={isChild}
      partially={partially}
    >
      <label>
        <input type="checkbox" {...props} />
        {label}
      </label>
      {checkboxChild(children)}
    </Wrapper>
  )
}

export default Checkbox
