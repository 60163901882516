import { Block, Card as CardLCT, Content, Link } from '@new-lucentum'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
  align-items: center;
  float: right;
  padding-top: ${({ theme }) => theme.size};
`

export const ButtonContainerMobile = styled.div`
  align-items: center;
  float: right;
  flex: 0 0 100%;
  background-color: #e8f6f9;

  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    width: 100%;
    padding-top: ${({ theme }) => theme.size};
    padding-bottom: ${({ theme }) => theme.size};
  }
`

export const CardContainer = styled(Block)`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.size};
`

export const Card = styled(CardLCT)`
  background-color: ${({ theme }) => theme.colorWhite};
  padding: unset;
  margin-bottom: ${({ theme }) => theme.sizeTriple};
  min-height: 207px;
  & > {
    color: ${({ theme }) => theme.color};
  }
`

export const CardMobile = styled(CardLCT)`
  background-color: ${({ theme }) => theme.colorWhite};
  box-shadow: none;
  padding: unset;
  margin-bottom: ${({ theme }) => theme.sizeDouble};
  & > {
    color: ${({ theme }) => theme.color};
  }
`

export const CardTitle = styled.div`
  color: ${({ theme }) => theme.color};
  > h3 {
    font-weight: bold !important;
  }
  font-weight: bold !important;
  display: flex;
  flex: 1;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colorAccent};
  padding: ${({ theme }) => theme.size} ${({ theme }) => theme.sizeTriple};
  margin-top: ${({ theme }) => theme.sizeDouble};
  flex-wrap: wrap;
`

export const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.size};
  padding-bottom: ${({ theme }) => theme.sizeDouble};
  padding-left: ${({ theme }) => theme.sizeTriple};
  padding-right: ${({ theme }) => theme.sizeTriple};
  flex-wrap: wrap;
`

export const BodyContainerMobile = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: ${({ theme }) => theme.size};
  border-bottom: 1px solid #bec4d0;
`

export const ImageContainer = styled.div`
  width: 200px;
  height: 130px;
  margin-right: ${({ theme }) => theme.sizeTriple};

  & > img {
    max-width: 100%;
    max-height: 130px;
  }
`

export const ImageContainerMobile = styled.div`
  width: 35%;
  height: 130px;
  padding-left: ${({ theme }) => theme.sizeTriple};
  padding-top: ${({ theme }) => theme.size};
  & > img {
    max-width: 100%;
    max-height: 130px;
  }
`

export const FieldsContainer = styled.div`
  flex: 1;
  padding-top: ${({ theme }) => theme.sizeTriple};
`

export const FieldsContainerMobile = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => theme.size};
  padding-right: ${({ theme }) => theme.sizeTriple};
  padding-top: ${({ theme }) => theme.size};
`

export const OfficeContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.size};
`

export const ContentStyled = styled(Content)`
  background-color: ${({ theme }) => theme.colorAccent};
`

export const DetailBlock = styled(Block)`
  padding: 0 ${({ theme }) => theme.sizeTriple};
  padding-bottom: ${({ theme }) => theme.sizeDouble};
`

export const DetailBlockMobile = styled(Block)`
  padding: 0 ${({ theme }) => theme.sizeTriple};
  padding-bottom: ${({ theme }) => theme.sizeDouble};
  padding-top: ${({ theme }) => theme.sizeDouble};
`

export const Title = styled.div`
  display: flex;
  flex: 1;
  border-bottom: black 2px solid;
  margin: 0 ${({ theme }) => theme.sizeTriple};
  padding: ${({ theme }) => theme.size} 0;
  > h2 {
    font-weight: bold;
  }
`

export const MenuHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colorGrayLighter};
  padding: ${({ theme }) => theme.size} ${({ theme }) => theme.sizeTriple};

  &.is-sticky {
    background-color: ${({ theme }) => theme.color};
    color: white;

    button.is-active {
      border-color: white;
    }

    .button-icon {
      color: white;
      i {
        color: white;
      }
    }

    .dropdown-parent {
      color: ${({ theme }) => theme.colorGray};
      border: 1px solid ${({ theme }) => theme.colorGrayLighter};
      .dropdown-options {
        .button-title {
          color: ${({ theme }) => theme.colorGray};
        }
        i {
          color: ${({ theme }) => theme.colorGray};
        }
      }
    }

    .button-title {
      color: white;
    }
  }
`

export const MenuHeaderMobile = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colorGrayLighter};
  padding: ${({ theme }) => theme.size} ${({ theme }) => theme.sizeTriple};

  & h3 {
    color: ${({ theme }) => theme.color};
  }

  & .dropdown-parent {
    background-color: ${({ theme }) => theme.colorWhite};
    .dropdown-options {
      .button-title {
        color: ${({ theme }) => theme.colorGray};
      }
      i {
        color: ${({ theme }) => theme.colorGray};
      }
    }

    > button {
      color: black;
      border: 1px solid ${({ theme }) => theme.colorGray};
    }

    > button:hover {
      color: white;
    }
  }

  &.is-sticky {
    background-color: ${({ theme }) => theme.color};
    color: white;

    button.is-active {
      border-color: white;
    }

    .button-icon {
      color: white;
      i {
        color: white;
      }
    }

    .dropdown-parent {
      background-color: ${({ theme }) => theme.color};
      .dropdown-options {
        .button-title {
          color: ${({ theme }) => theme.colorGray};
        }
        i {
          color: ${({ theme }) => theme.colorGray};
        }
      }

      > button {
        color: white;
        border: 1px solid white;
      }
    }

    .button-title,
    & h3 {
      color: white;
    }
  }
`

export const BlockStyled = styled(Block)`
  & > div:nth-of-type(1) {
    border-bottom: black 2px solid !important;
  }
`

export const BlockStyledMobile = styled(Block)`
  & > div:nth-of-type(1) h2 {
    font-size: 1rem;
    font-weight: bold;
  }
`

export const LinkStyled = styled(Link)`
  padding-left: ${({ theme }) => theme.size};
`
export const ContactBlock = styled(Block)`
  padding-bottom: ${({ theme }) => theme.size};
  border-bottom: 2px solid ${({ theme }) => theme.colorBackground};
`

export const HeaderContent = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
  margin: 0px auto;
`

export const HeaderContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  > h3 {
    font-weight: bold;
    margin: 15px 0;
  }
`
