import { setOriginalLanguage } from '@app/actions/detail'
import { TMDSTrackingService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { localStorageUtils } from '@app/common/utils'
import { Button } from '@new-lucentum'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const showOriginalLanguageSelector = ({
  uiState: {
    detail: { showOriginalLanguageAttributes },
  },
}) => showOriginalLanguageAttributes

const OriginalButtonComponent = props => {
  const dispatch = useDispatch()
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)
  const buttonText = showOriginalLanguageAttributes
    ? t('original.button.label.translated')
    : t('original.button.label')

  React.useEffect(() => {
    localStorageUtils.set('originalLanguage', showOriginalLanguageAttributes)
  }, [])

  const handleClick = React.useCallback(
    () => {
      dispatch(setOriginalLanguage(!showOriginalLanguageAttributes))
      localStorageUtils.set('originalLanguage', !showOriginalLanguageAttributes)
      TMDSTrackingService.trackOrigLangOrTransClickEvent(showOriginalLanguageAttributes)
    },
    [showOriginalLanguageAttributes]
  )

  return <Button onClick={handleClick} title={buttonText} />
}

export const OriginalButton = OriginalButtonComponent
