import styled from 'styled-components'

import Block from '../Block'

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.color};

  p {
    margin: 0;
  }
  button {
    border-color: ${({ theme }) => theme.colorWhite};
  }
  a,
  p,
  button,
  label {
    color: ${({ theme }) => theme.colorWhite};
  }

  .navCopyright {
    font-size: ${({ theme }) => `calc(${theme.fontSizeMedium} * .75)`};
  }
  position: relative;
`

export const Copy = styled(Block)`
  width: 90%;
  margin: auto;
  nav,
  div > div {
    width: max-content;
  }

  a {
    padding-left: ${({ theme }) => theme.sizeLarge};
  }
`

export const Label = styled.label``

export const Pipe = styled.label`
  width: ${({ theme }) => theme.line};
  background: ${({ theme }) => theme.colorWhite};
  margin-left: ${({ theme }) => theme.size};
  margin-right: ${({ theme }) => theme.size};
`
