import { SETTINGS_TYPES } from '@app/redux-types'

const defaultState = {}

const getSettings = (state, action) => {
  return {
    ...state,
    ...action.payload,
  }
}

const editSettings = (state, action) => {
  return {
    ...state,
    ...action.payload,
  }
}

const actionsMap = {
  [SETTINGS_TYPES.GET_SETTINGS_FULFILLED]: getSettings,
  [SETTINGS_TYPES.EDIT_SETTINGS_FULFILLED]: editSettings,
}

export const settingsReducer = (state = defaultState, action) => {
  const mutation = actionsMap[action.type]
  return mutation ? mutation(state, action) : state
}
