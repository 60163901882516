import * as React from 'react'
import { compose, withState, withHandlers } from 'recompose'

import { Block, ImageCarrousel, Pagination } from '@new-lucentum'
import Item from './Item'

import { ContainerItems } from './styles'

const getPagination = chl => React.Children.map(chl, c => c.type === Pagination && c)

const enhance = compose(
  withState('imageUrls', 'setImageUrls', []),
  withHandlers({
    onShowImageCarrousel: ({ setImageUrls }) => imageUrls => setImageUrls(imageUrls),
    onCloseImageCarrousel: ({ setImageUrls }) => () => setImageUrls([]),
  })
)

export default enhance(
  ({
    children,
    className,
    hrefOnClick,
    imageKey = 'image',
    imageUrlsKey = 'imageUrls',
    linkKey = 'link',
    matchingValue = '',
    onCheckbox,
    renderDetail,
    src = [],
    templateTooltip,
    titleKey = 'title',
    hasImageCarrouselOption,
    ...props
  }) => (
    <Block className={className} {...props}>
      <ContainerItems>
        {src.map((i, key) => (
          <Item
            hrefOnClick={hrefOnClick}
            imageKey={imageKey}
            imageUrlsKey={imageUrlsKey}
            hasImageCarrouselOption={hasImageCarrouselOption}
            onShowImageCarrousel={props.onShowImageCarrousel}
            key={key}
            linkKey={linkKey}
            matchingValue={matchingValue}
            onCheckbox={onCheckbox}
            renderDetail={renderDetail}
            src={i}
            templateTooltip={templateTooltip}
            titleKey={titleKey}
          />
        ))}
      </ContainerItems>
      <Block right>{getPagination(children)}</Block>
      {hasImageCarrouselOption && !!props.imageUrls.length && (
        <ImageCarrousel
          imageUrls={props.imageUrls}
          onCloseImageCarrousel={props.onCloseImageCarrousel}
        />
      )}
    </Block>
  )
)
