const COMMON_FIELDS = {
  APPLICATION_NUMBER: 'applicationNumber',
  FIELDS_ERRORS: 'fieldsErrors',
  OFFICES: 'offices',
  REGISTRATION_DATE: 'regDate',
  SEARCH_CRITERIA: 'criteria',
  TERRITORIES: 'territories',
  EVENT: 'event',
}

export const TMVIEW_FIELDS = {
  APPLICANT_NAME: 'appName',
  APPLICATION_DATE: 'appDate',
  BASIC_SEARCH: 'basicSearch',
  CURRENTLY_OPPOSABLE: 'currentlyOpposable',
  IMAGE_SEARCH: 'imageSearch',
  GOODS_AND_SERVICES: 'niceClass',
  REGISTRATION_NUMBER: 'registrationNumber',
  SENIORITY_CLAIMED: 'seniorityClaimed',
  TRADEMARK_STATUS: 'tmStatus',
  TRADEMARK_TYPE: 'tmType',
  VIENNA_CODE_VERSION: 'viennaCodeVersion',
  VIENNA_CODE: 'viennaCode',
}

export const DSVIEW_FIELDS = {
  DESIGN_NUMBER: 'designNumber',
  DESIGN_STATUS: 'designStatus',
  DESIGNER_NAME: 'designerName',
  EFFECTIVE_DATE: 'effectiveDate',
  END_DEFERMENT_DATE: 'endOfDefermentDate',
  EXHIBITION_PRIORITY: 'exhibitionPriority',
  EXPIRY_DATE: 'expiryDate',
  FILING_DATE: 'applicationDate',
  INDICATION_OF_PRODUCT: 'indicationOfProduct',
  LOCARNO: 'locarnoClass',
  LOCARNO_VERSION: 'locarnoVersion',
  OWNER_NAME: 'applicantName',
  PREVIOUS_OWNER_NAME: 'previousApplicantName',
  PRIORITY_COUNTRY: 'priorityCountry',
  PRIORITY_DATE: 'priorityDate',
  PRIORITY_NUMBER: 'priorityNumber',
  PUBLICATION_DATE: 'publicationDate',
  REPRESENTATIVE_NAME: 'representativeName',
  VERBAL_ELEMENT: 'verbalElement',
  IMAGE_SEARCH: 'imageSearch',
}

export const FIELDS = {
  ...COMMON_FIELDS,
  ...TMVIEW_FIELDS,
  ...DSVIEW_FIELDS,
}
