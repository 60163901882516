import React from 'react'

import { Link } from './styles'
import Icon from '../Icon'

export default ({
  active,
  activeKey,
  activeVertical,
  bold,
  children = 'ADD LINK TITLE',
  className,
  disabled = false,
  to,
  isExternal,
  ...props
}) => (
  <Link
    active={active}
    activeKey={activeKey}
    activeVertical={activeVertical}
    bold={bold}
    className={className}
    disabled={disabled}
    href={to}
    {...props.target && props.target === '_blank' && { rel: 'noopener noreferrer' }}
    {...props}
  >
    {children}
    {isExternal && <Icon externalLink color="inherit" className="external-link-icon" />}
  </Link>
)
