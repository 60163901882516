import React from 'react'
import { compose, lifecycle, withState } from 'recompose'
import { hasItemsToShow } from '@app/common/utilities'
import { Box } from './Box'
import { DataList } from './DataItem'
import { getFormatedData, parseAddress } from './utils'
import { CORRESPONDENCE_ADDRESS_FIELDS } from '@app/common/dataManagers/types'
import { totalBoxEntries } from './utils'

const fields = [
  { label: 'freeformatname.key', path: CORRESPONDENCE_ADDRESS_FIELDS.FULL_NAME },
  { label: 'addresscity.key', path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.CITY },
  {
    label: 'tm.details.label.template.paintName.OrganizationName.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ORGANIZATION_NAME,
  },
  {
    label: 'tm.details.label.template.paintName.LegalForm.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.LEGAL_FORM,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressStreet.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.ADDRESS,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressCountryCode.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.COUNTRY_CODE,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressState.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.STATE,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressPostcode.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.POSTCODE,
  },
  {
    label: 'tm.details.label.template.paintAddress.FreeFormatAddress.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.FULL_ADDRESS,
  },
]

const enhance = compose(
  withState('formatedData', 'setFormatedData', ({ inidCodes, data }) => {
    const parsedData = parseAddress(data)
    return getFormatedData(fields, parsedData, inidCodes)
  }),
  lifecycle({
    componentDidMount() {
      const { formatedData, setHasItemsToShow } = this.props
      const hasItems = hasItemsToShow(formatedData)
      hasItems !== this.props.hasItemsToShow &&
        setHasItemsToShow('boxCorrespondenceAddress', hasItems)
    },
  })
)

export default enhance(
  ({ literals, inidCodes, data = {}, formatedData, officeExceptionMessage, ...props }) => {
    return (
      <Box
        boxId="boxCorrespondenceAddress"
        title={`${literals['tm.details.left.menu.correspondenceaddress.key']} ${totalBoxEntries(
          data.correspondenceAddress
        )}`}
        hasItemsToShow={props.hasItemsToShow}
        dataTestId="item-detail-correspondence-address"
        officeExceptionMessage={officeExceptionMessage}
      >
        {props.hasItemsToShow && (
          <DataList
            testId="boxCorrespondenceAddress-show-more"
            data={formatedData}
            literals={literals}
            shownItemsLimit={2}
          />
        )}
      </Box>
    )
  }
)
