const defaultState = {
  open: false,
  type: '',
  title: 'monitor.error.button.key',
  message: 'error_message_key',
  details: [],
}

const titleLabel = {
  VALIDATION: 'validation_title_key',
  UNRECOVERABLE: 'monitor.error.button.key',
}

const getCustomTypeModalState = ({
  parsedBody: { type, message = 'error_message_key', details },
}) => {
  const commonData = {
    message,
    details,
  }

  switch (type) {
    case 'VALIDATION':
      return {
        ...commonData,
        type: 'warning',
        title: titleLabel[type],
      }
    case 'UNRECOVERABLE':
      return {
        ...commonData,
        type: 'error',
        title: titleLabel[type],
      }
    default:
      return { ...commonData }
  }
}

export default (state = defaultState, { type, payload, meta }) => {
  if (type.includes('REJECTED') && (!meta || !meta.errors)) {
    if (payload.isBackendError) {
      if (payload.response.status === 401) {
        return state
      }
      return {
        ...state,
        open: true,
        ...getCustomTypeModalState(payload),
      }
    }
    return {
      ...state,
      type: 'error',
      title: titleLabel.UNRECOVERABLE,
      message: payload.parsedBody || '' + payload || 'unknown_error',
    }
  }

  switch (type) {
    case 'OPEN_MODAL':
      return { ...state, ...payload, open: true }
    case 'CLOSE_MODAL':
      return { ...defaultState }
    default:
      return state
  }
}
