import { FIELDS } from './fields'
export const COMPOSED_QUERY_PARAMS = {
  [FIELDS.APPLICATION_DATE]: {
    FROM: 'adFrom',
    TO: 'adTo',
  },
  [FIELDS.REGISTRATION_DATE]: {
    FROM: 'rdFrom',
    TO: 'rdTo',
  },
  [FIELDS.END_DEFERMENT_DATE]: {
    FROM: 'endDefermentFrom',
    TO: 'endDefermentTo',
  },
  [FIELDS.EFFECTIVE_DATE]: {
    FROM: 'effectiveFrom',
    TO: 'effectiveTo',
  },
  [FIELDS.EXPIRY_DATE]: {
    FROM: 'expiryFrom',
    TO: 'expiryTo',
  },
  [FIELDS.PRIORITY_DATE]: {
    FROM: 'priorityFrom',
    TO: 'priorityTo',
  },
  [FIELDS.PUBLICATION_DATE]: {
    FROM: 'publicationFrom',
    TO: 'publicationTo',
  },
  [FIELDS.FILING_DATE]: {
    FROM: 'filingFrom',
    TO: 'filingTo',
  },
}
