import { searchDataManager } from '@app/common/dataManagers'
import { getHeaderFileName } from '@app/common/utilities'
import fileDownload from 'react-file-download'

import { TMDSSpinnerService } from '../tmdsSpinnerService'
import { TMDSTrackingService } from '../tmdsTrackingService'

const doExportAlerts = (extension, payload) => {
  TMDSTrackingService.trackExport('ALERTS', extension)
  const exportPromise = searchDataManager.doExport(extension, payload)
  exportPromise.then(response => {
    const { headers, parsedBody } = response
    const fileName = getHeaderFileName(headers)
    fileDownload(parsedBody, fileName ? fileName : `${'alerts_export'}.${extension}`)
  })
  TMDSSpinnerService.showSpinner(exportPromise)
  return exportPromise
}

const doExportTags = (extension, payload) => {
  TMDSTrackingService.trackExport('TAGS', extension)
  const exportPromise = searchDataManager.doExport(extension, payload)
  exportPromise.then(response => {
    const { headers, parsedBody } = response
    const fileName = getHeaderFileName(headers)
    fileDownload(parsedBody, fileName ? fileName : `${'tags_export'}.${extension}`)
  })
  TMDSSpinnerService.showSpinner(exportPromise)
  return exportPromise
}

export const TMDSExportService = {
  doExportAlerts,
  doExportTags,
}
