const defaultState = {
  languageTranslate: '',
  recaptchaResponseKey: null,
  showOriginalLanguageAttributes: !!JSON.parse(localStorage.getItem('originalLanguage')),
  exportHistory: {
    showExportPDFOptions: false,
    showExportDOCOptions: false,
    recaptchaResponseKey: null,
    notification: '',
    notificationType: '',
  },
}

const showExportStatisticsFileType = (fileType, show) =>
  (fileType === 'pdf' && { showExportPDFOptions: show }) ||
  (fileType === 'xls' && { showExportXLSOptions: show }) ||
  (fileType === 'doc' && { showExportDOCOptions: show }) ||
  {}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'CHANGE_EXPORT_CAPTCHA_HISTORY':
      return {
        ...state,
        recaptchaResponseKey: payload.recaptchaResponseKey,
      }
    case 'RESET_RECAPTCHA_HISTORY':
      return {
        ...state,
        recaptchaResponseKey: null,
      }
    case 'SHOW_EXPORT_PDF_OPTIONS':
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          recaptchaResponseKey: null,
          showExportPDFOptions: true,
        },
      }
    case 'SHOW_EXPORT_DOC_OPTIONS':
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          recaptchaResponseKey: null,
          showExportDOCOptions: true,
        },
      }
    case 'SHOW_EXPORT_HISTORY_OPTIONS':
      return {
        ...state,
        exportStatistics: {
          ...state.exportStatistics,
          ...showExportStatisticsFileType(payload.fileType, payload.show),
        },
      }
    case 'CANCEL_EXPORT':
      return {
        ...state,
        exportHistory: {
          ...defaultState.exportHistory,
        },
      }
    case 'EXPORT_PDF_PENDING':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          showExportPDFOptions: false,
        },
      }
    case 'EXPORT_DOC_PENDING':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          showExportDOCOptions: false,
        },
      }
    case 'SET_EXPORT_HISTORY_NOTIFICATION':
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          notification: payload.notification,
          notificationType: payload.notificationType,
        },
      }
    case 'CLOSE_EXPORT_HISTORY_NOTIFICATION':
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          notification: '',
          notificationType: '',
        },
      }
    default:
      return state
  }
}
