import { t } from '@app/common/translationsService'

import { CERTIFICATE_TYPES } from './constants'

export const setDropdownOptions = options => {
  const dropdownOptions = []

  if (!options.length) {
    dropdownOptions.push({
      certificateType: CERTIFICATE_TYPES.AUTHENTICATE,
      label: t('certificates.authenticate.certificate'),
      description: t('certs_authenticate_certificates_EM'),
    })
  } else {
    for (const option of options) {
      switch (option) {
        case CERTIFICATE_TYPES.APPLICATION:
          dropdownOptions.push({
            certificateType: CERTIFICATE_TYPES.APPLICATION,
            label: t('certified.copies.of.the.application.form'),
            description: t('certs_certified_copies_of_the_application_form_EM'),
          })
          break
        case CERTIFICATE_TYPES.REGISTRATION:
          dropdownOptions.push({
            certificateType: CERTIFICATE_TYPES.REGISTRATION,
            label: t('certified.copies.of.the.registration.certificate'),
            description: t('certs_certified_copies_of_the_registration_certificate_EM'),
          })
          break
        default:
          break
      }
    }

    dropdownOptions.push({
      certificateType: CERTIFICATE_TYPES.AUTHENTICATE,
      label: t('certificates.authenticate.certificate'),
      description: t('certs_authenticate_certificates_EM'),
    })
  }

  return dropdownOptions
}

export const getCountries = territories => {
  const selectOption = { value: '', label: t('select.country.key') }

  const countries = territories
    .filter(item => !item.children)
    .map(item => ({ value: item.id, label: t(item.description) }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return [selectOption, ...countries]
}
