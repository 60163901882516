import React from 'react'

let _literals = null

/**
 * Set the literals for this service.
 *
 * @param {object} literals Contains all the literals
 */
export const setLiterals = literals => {
  _literals = literals
}

/**
 * Get the literals.
 *
 */
export const getLiterals = () => {
  return _literals
}

/**
 * Return literal given a key.
 *
 * @param {string} key Literal key
 * @param {object} params Contains the interpolations
 * @returns {string}
 */
export const t = (key, params) => {
  if (!(key in _literals)) {
    return key
  }
  let targetLiteral = _literals[key]
  for (const param in params) {
    targetLiteral = targetLiteral.replace(`{${param}}`, params[param])
  }
  return targetLiteral || key
}

/**
 * Return literal with components in params given a key.
 *
 * @param {string} key Literal key
 * @param {object} params Contains the interpolations with components
 * @returns {Component}
 */
export const Translation = ({ literalKey, params = {} }) => {
  const targetLiteral = _literals[literalKey] || false
  const paramsKeys = Object.keys(params)
  if (!targetLiteral || !paramsKeys.length) {
    return <span>{literalKey}</span>
  }

  const paramsIndexes = paramsKeys.map(key => {
    const startIndex = targetLiteral.indexOf(`{${key}}`)
    if (startIndex > -1) {
      const endIndex = startIndex + key.length + 2
      const indexesObject = { startIndex, endIndex, key }
      return indexesObject
    }
  })

  const orderedIndexes = paramsIndexes
    .filter(index => index)
    .sort((a, b) => a.startIndex - b.startIndex)

  if (!orderedIndexes.length) {
    return <span>{targetLiteral}</span>
  }

  const childrens = orderedIndexes.reduce((acc, curr, iterationIndex) => {
    const previousEndIndex = iterationIndex === 0 ? 0 : orderedIndexes[iterationIndex - 1].endIndex
    const nextString = targetLiteral.substring(previousEndIndex, curr.startIndex)
    acc.push(nextString)
    acc.push(params[curr.key])
    if (iterationIndex === orderedIndexes.length - 1) {
      const lastString = targetLiteral.substring(curr.endIndex, targetLiteral.length)
      lastString.length && acc.push(lastString)
    }
    return acc
  }, [])

  return (
    <span>
      {childrens.map((element, index) => (
        <span key={index}>{element}</span>
      ))}
    </span>
  )
}
