import React, { useState, useEffect } from 'react'
import { StyledCookieBanner } from './styles'
import { Block, Button } from '@new-lucentum'

import {
  acceptAllCookies,
  dataProtectionCookieExists,
  getCookiesBanner,
  onlyEssentialCookies,
} from './CookiesBanner.service'
import { fetchGlobals } from '@app/actions/globals'
import { useDispatch, useSelector } from 'react-redux'
import { GLOBALS_TYPES } from '@app/redux-types/globals'
import { TMDSTrackingService } from '@app/common/services'

const DEFAULT_BTN_ACCEPT_TEXT = 'Accept all cookies'
const DEFAULT_BTN_REJECT_TEXT = 'No thanks, only essential cookies'

const CookiesBanner = ({ cookiesUrl, language }) => {
  const dispatch = useDispatch()
  const globals = useSelector(state => state.globals?.data)
  const { cookiesBanner, matomoProperties } = globals
  const [checkingCookieExistance, setCheckingCookieExistance] = useState(true)
  const [cookieExists, setCookieExists] = useState(false)
  const [cookieContent, setCookieContent] = useState(null)
  // only init when we can determine the cookie name to check whether we should disable the Matomo analytics or not.
  const [canInitMatomo, setCanInitMatomo] = useState(false)

  const shouldShowCookieBanner =
    !checkingCookieExistance && !cookieExists && cookieContent?.bannerContent

  useEffect(() => {
    if (cookiesUrl) {
      const preparedUrl = `${cookiesUrl}${language}`

      getCookiesBanner(preparedUrl)
        .then(data => {
          setCookieContent(data.cookiesBanner || {})
        })
        .catch(e => {
          console.log('Failed to get cookie banner content', e)
        })
        .finally(() => setCanInitMatomo(true))
    }
  }, [])

  useEffect(
    () => {
      dispatch({
        type: GLOBALS_TYPES.SET_COOKIE_BANNER_CONTENT,
        payload: cookieContent,
      })
    },
    [cookieContent, dispatch]
  )

  useEffect(
    () => {
      // when matomo properties changed (when rejected), we need to reinitialize the tracking service.
      if (canInitMatomo) {
        TMDSTrackingService.init(matomoProperties)
      }
    },
    [cookiesBanner, matomoProperties, canInitMatomo]
  )

  useEffect(() => {
    setCookieExists(dataProtectionCookieExists())
    setCheckingCookieExistance(false)
  }, [])

  const resetCookieBannerStatus = () => {
    setCookieExists(dataProtectionCookieExists())
  }

  const handleCookieAction = (accepted = true) => {
    if (accepted) {
      acceptAllCookies(cookieContent?.cookieName, cookieContent?.cookieValue)
    } else {
      onlyEssentialCookies(cookieContent?.cookieName)
      // by default, all Matomo cookies are allowed, so we only reset the globals to get cookie properties when user rejects the cookies.
      dispatch(fetchGlobals())
    }
    resetCookieBannerStatus()
  }

  if (!shouldShowCookieBanner) return null

  return (
    <Block>
      <StyledCookieBanner onClose={() => handleCookieAction()}>
        <p
          dangerouslySetInnerHTML={{ __html: cookieContent?.bannerContent }}
          style={{ fontSize: 16, marginTop: 0 }}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button className="button" onClick={() => handleCookieAction()}>
            {cookieContent?.buttonAccept || DEFAULT_BTN_ACCEPT_TEXT}
          </Button>
          <Button className="button" onClick={() => handleCookieAction(false)}>
            {cookieContent?.buttonReject || DEFAULT_BTN_REJECT_TEXT}
          </Button>
        </div>
      </StyledCookieBanner>
    </Block>
  )
}

export default CookiesBanner
