import styled, { css } from 'styled-components'

import Block from '../Block'

//Remove import del ThemeProvider and put this for compatibility
export const scroll = ({ color }) => css`
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${color};
    border-radius: 100px;
    height: 50%;
  }
`
//from here the same like as lucentum
export const Th = styled.th`
  background-color: ${({ theme, grayHead }) =>
    grayHead ? theme.colorGrayLighter : theme.colorWhite};
  font-size: ${({ theme }) => theme.fontSizeSmaller};
  ${({ theme, grayHead }) => grayHead && ` font-weight: ${theme.fontWeightSemiBold}; `};
  max-width: 400px;
  min-width: 150px;
  text-align: left;
`

export const TransposedStyled = styled(Block)`
  overflow-x: auto;
  ${({ topScrollBar }) =>
    topScrollBar &&
    css`
      transform: rotateX(180deg);
      margin-top: 5px;
      table {
        transform: rotateX(180deg);
      }
    `}
  table {
    border-spacing: 0;
  }
  table tbody tr th {
    z-index: 10;
  }
  ${({ theme }) => scroll(theme)};
  width: 100%;

  tbody {
    ${Th}, td {
      padding: ${({ theme }) => theme.size};
      vertical-align: top;
      color: ${({ theme }) => theme.colorGray};
    }
    ${Th} {
      padding-right: ${({ theme }) => theme.sizeDouble};
    }
    td {
      min-width: 140px;
      max-width: 300px;
      text-overflow: ellipsis;
      ${({ theme }) => theme.baseText};
    }
    ${({ theme, stripedVertically, withRowHead }) =>
      !!stripedVertically
        ? // If rows have a head column, the column striping starts on the 2nd column
          `
        td:nth-child(${withRowHead ? 'odd' : 'even'}) {
          background-color: ${theme.colorLighterFiftyPercent};
        }
        td:nth-child(${withRowHead ? 'even' : 'odd'}) {
          background-color: ${theme.colorWhite};
        }
        td:first-of-type {
          padding-left: ${({ theme }) => theme.sizeDouble};
        }
    `
        : ``}
  }
`
