import React from 'react'

import { Radio } from './styles'

export default ({ id, className, label, checked = false, ...props }) => (
  <Radio className={className}>
    <label>
      <input type="radio" name={id} defaultChecked={checked} {...props} />
      {label}
    </label>
  </Radio>
)
