import React from 'react'
import Base from './Block'
import TitledBlock from './TitledBlock'

const Block = ({ title, icon, children, ...props }) =>
  title || icon ? (
    <TitledBlock title={title} icon={icon} {...props}>
      {children}
    </TitledBlock>
  ) : (
    <Base {...props}>{children}</Base>
  )

export default Block
