import Api from '@app/common/api'
import TMDSContextService from '@app/common/tmdsContextService'

const api = new Api()

const USERPREFS_PATH = '/userprefs'
const PATHS = {
  FAVORITE: {
    dsview: 'dsv/favorite',
    tmview: 'favorite',
  },
  TAGS: {
    dsview: 'tags/dsv',
    tmview: 'tags/tmv',
  },
  ALERTS: {
    dsview: 'alerts',
    tmview: 'alerts',
  },
  ALERTS_HISTORY: {
    dsview: 'history/alerts',
    tmview: 'history/alerts',
  },
  SAVE_SEARCH: {
    dsview: 'searchWatch/dsv',
    tmview: 'searchWatch/tmv',
  },
  NOTIFICATIONS: {
    dsview: 'notifications/dsv',
    tmview: 'notifications/tmv',
  },
  GET_SETTINGS: {
    dsview: 'settings/dsv',
    tmview: 'settings/tmv',
  },
  PATCH_SETTINGS: {
    EMAIL: {
      dsview: 'settings/email-notifications/dsv',
      tmview: 'settings/email-notifications/tmv',
    },
    CERTIFICATE: {
      dsview: 'settings/certificate-legal-notes/dsv',
      tmview: 'settings/certificate-legal-notes/tmv',
    },
  },
  SAVE_FILTERS: {
    dsview: 'resultsfilters/dsv',
    tmview: 'resultsfilters/tmv',
  },
}

const noCache = {
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: 0,
  },
}

const context = TMDSContextService.get

const toggleFavoriteField = (field, isFavorite) => {
  const url = `/${PATHS.FAVORITE[context()]}`
  const payload = { idSearchField: field, favorite: isFavorite }
  const options = {
    body: payload,
  }
  return api.put(url, options)
}

const getTags = ids => {
  const query = ids ? { id: ids.split(',') } : {}
  const url = `${USERPREFS_PATH}/${PATHS.TAGS[context()]}/search`
  const options = {
    ...noCache,
    body: {
      ...query,
    },
  }
  return api.post(url, options)
}

const getTagGroups = () => {
  const url = `${USERPREFS_PATH}/${PATHS.TAGS[context()]}/groups`
  return api.get(url)
}

const editTagGroup = (id, tagName) => {
  const url = `${USERPREFS_PATH}/${PATHS.TAGS[context()]}/groups/${id}`
  const options = {
    body: {
      name: tagName,
    },
  }
  return api.patch(url, options)
}

const removeTagGroup = id => {
  const url = `${USERPREFS_PATH}/${PATHS.TAGS[context()]}/groups/${id}`
  return api.delete(url)
}

const getResourceTagGroups = st13s => {
  const st13sList = st13s.join(',')
  const url = `${USERPREFS_PATH}/${PATHS.TAGS[context()]}/groups/?st13=${st13sList}`
  return api.get(url)
}

const setTags = payload => {
  const url = `${USERPREFS_PATH}/${PATHS.TAGS[context()]}`
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const getAlerts = (page, pageSize) => {
  const url = `${USERPREFS_PATH}/${
    PATHS.ALERTS[context()]
  }/search?applicationId=${TMDSContextService.getApplicationId()}`
  const options = {
    ...noCache,
  }
  return api.get(url, options)
}

const getAlertsByIds = ids => {
  const url = `${USERPREFS_PATH}/${PATHS.ALERTS[context()]}/search`
  const options = {
    body: { applicationId: TMDSContextService.getApplicationId(), alertsST13: ids },
  }
  return api.post(url, options)
}

const setAlerts = (ids, alertsEvents) => {
  const url = `${USERPREFS_PATH}/${PATHS.ALERTS[context()]}`
  const options = {
    body: {
      applicationId: TMDSContextService.getApplicationId(),
      alertsST13: ids,
      ...alertsEvents,
    },
  }
  return api.post(url, options)
}

const getAlertsHistory = (id, filterParams) => {
  const queryBuild = Object.keys(filterParams)
    .map(key => key + '=' + filterParams[key])
    .join('&')
  const query = queryBuild ? `&${queryBuild}` : `${queryBuild}`
  const url = `${USERPREFS_PATH}/${
    PATHS.ALERTS_HISTORY[context()]
  }/${id}?applicationId=${TMDSContextService.getApplicationId()}${query}`
  const options = {
    ...noCache,
  }

  return api.get(url, options)
}

const exportAlertEvents = (selectedIds, recaptchaResponseKey, documentType) => {
  const subPath = TMDSContextService.isTmview() ? 'tmv' : 'dsv'
  const url = `${USERPREFS_PATH}/${
    PATHS.ALERTS_HISTORY[context()]
  }/${subPath}/export/${documentType}`
  const options = {
    ...noCache,
    body: {
      selectedIds,
      recaptchaResponseKey,
    },
    responseWithHeaders: true,
  }
  return api.post(url, options)
}

const getSavedSearches = (page, pageSize, name) => {
  let queryParams = `?page=${page}&pageSize=${pageSize}`
  if (name) {
    queryParams = queryParams + `&name=${name}`
  }
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_SEARCH[context()]}${queryParams}`
  const options = {
    ...noCache,
  }
  return api.get(url, options)
}

const setSaveSearch = (
  searchName,
  searchQuery,
  searchValues,
  createdDate,
  searchId,
  searchImage = null
) => {
  const parsedCreatedDate = createdDate || new Date().toISOString()
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_SEARCH[context()]}`
  const payload = {
    name: searchName,
    searchQuery: searchQuery,
    searchValues: searchValues,
    searchImage: searchImage,
    createdDate: parsedCreatedDate,
    ...(searchId && { id: searchId }),
  }
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const editSavedSearch = (id, searchName) => {
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_SEARCH[context()]}/${id}`
  const options = {
    body: {
      name: searchName,
    },
  }
  return api.patch(url, options)
}

const getSavedSearch = id => {
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_SEARCH[context()]}/${id}`
  return api.get(url)
}

const getSavedSearchHistory = (id, queryString = '') => {
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_SEARCH[context()]}/${id}/events?${queryString}`
  return api.get(url)
}

const removeSavedSearch = id => {
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_SEARCH[context()]}/${id}`
  return api.delete(url)
}

const exportSavedSearchEvents = (selectedIds, recaptchaResponseKey, documentType) => {
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_SEARCH[context()]}/export/${documentType}`
  const options = {
    ...noCache,
    body: {
      selectedIds,
      recaptchaResponseKey,
    },
    responseWithHeaders: true,
  }
  return api.post(url, options)
}

const getRecentNotifications = () => {
  const url = `${USERPREFS_PATH}/${PATHS.NOTIFICATIONS[context()]}/recent`
  return api.get(url)
}

const getNotifications = filterParams => {
  const url = `${USERPREFS_PATH}/${PATHS.NOTIFICATIONS[context()]}`
  const options = {
    ...noCache,
    body: {
      ...filterParams,
    },
  }
  return api.post(url, options)
}

const exportNotifications = (selectedIds, recaptchaResponseKey, documentType) => {
  const url = `${USERPREFS_PATH}/${PATHS.NOTIFICATIONS[context()]}/export/${documentType}`
  const options = {
    ...noCache,
    body: {
      selectedIds,
      recaptchaResponseKey,
    },
    responseWithHeaders: true,
  }
  return api.post(url, options)
}

const getUserSettings = () => {
  const url = `${USERPREFS_PATH}/${PATHS.GET_SETTINGS[context()]}`
  return api.get(url)
}

const editUserSettings = (patch, isEmail) => {
  const url = `${USERPREFS_PATH}/${
    PATHS.PATCH_SETTINGS[isEmail ? 'EMAIL' : 'CERTIFICATE'][context()]
  }`
  const options = {
    body: {
      ...patch,
    },
  }
  return api.patch(url, options)
}

const setSaveFilters = filters => {
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_FILTERS[context()]}`
  const payload = {
    userResultsFilters: filters,
  }
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const getSavedFilters = (page, pageSize, name) => {
  let queryParams = `?page=${page}&pageSize=${pageSize}`
  if (name) {
    queryParams = queryParams + `&name=${name}`
  }
  const url = `${USERPREFS_PATH}/${PATHS.SAVE_FILTERS[context()]}${queryParams}`
  const options = {
    ...noCache,
  }
  return api.get(url, options)
}
export const userprefsDataManager = {
  toggleFavoriteField,
  getTags,
  getTagGroups,
  getResourceTagGroups,
  editTagGroup,
  removeTagGroup,
  setTags,
  getAlerts,
  getAlertsByIds,
  setAlerts,
  getAlertsHistory,
  exportAlertEvents,
  getSavedSearches,
  setSaveSearch,
  removeSavedSearch,
  editSavedSearch,
  getSavedSearch,
  getSavedSearchHistory,
  exportSavedSearchEvents,
  getRecentNotifications,
  getNotifications,
  exportNotifications,
  getUserSettings,
  editUserSettings,
  setSaveFilters,
  getSavedFilters,
}
