const defaultState = {
  about: null,
  help: null,
  disclaimer: null,
}

export default (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case 'FETCH_STATIC_CONTENT_FULFILLED':
      const language = ['help', 'about', 'disclaimer'].includes(meta.pageName)
        ? { helpLanguage: meta.language }
        : {}
      return {
        ...state,
        ...language,
        [meta.pageName]: payload,
      }
    default:
      return state
  }
}
