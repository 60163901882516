import { resetResultState } from '@app/actions/results'
import {
  clearSearchForm,
  onlyActiveStatus,
  setSaveSearch as setSaveSearchAction,
} from '@app/actions/search'
import {
  FILTERS,
  SEARCH_QUERY_PARAMS,
  SELECTS_GLOBALS,
  TERRITORIES_FIELDS,
} from '@app/common/constants'
import { userprefsDataManager } from '@app/common/dataManagers'
import { queryParamsToObj } from '@app/common/searchUtilities'
import { TMDSGlobalsService } from '@app/common/services'
import tmdsContextService from '@app/common/tmdsContextService'
import { manageExport } from '@app/common/utils/exportManagement'
import store from '@app/store'

import {
  buildTerritoriesList,
  getQueriesAndFilters,
  getWipoQuery,
  queryObjectToString,
} from './utils'

let currentSearchQuery = null
let lastSearchUrl = null

const parseSearchQueryParams = searchQueryParams => {
  let parsedSearchQueryParams = { ...searchQueryParams }
  if (TERRITORIES_FIELDS.includes(SEARCH_QUERY_PARAMS.TERRITORIES)) {
    const { selects } = TMDSGlobalsService.get()
    TERRITORIES_FIELDS.forEach(
      item =>
        parsedSearchQueryParams[item] &&
        (parsedSearchQueryParams[item] = buildTerritoriesList(
          parsedSearchQueryParams[item],
          selects[SELECTS_GLOBALS.TERRITORIES]
        ))
    )
  }

  const arrayFields = [
    SEARCH_QUERY_PARAMS.GOODS_AND_SERVICES,
    SEARCH_QUERY_PARAMS.OFFICES,
    SEARCH_QUERY_PARAMS.TERRITORIES,
    SEARCH_QUERY_PARAMS.TRADEMARK_STATUS,
    SEARCH_QUERY_PARAMS.TRADEMARK_TYPE,
    SEARCH_QUERY_PARAMS.VIENNA_CODE,
    SEARCH_QUERY_PARAMS.APPLICANT_NAME,
    SEARCH_QUERY_PARAMS.DESIGNER_NAME,
    SEARCH_QUERY_PARAMS.OWNER_NAME,
    SEARCH_QUERY_PARAMS.PREVIOUS_OWNER_NAME,
    SEARCH_QUERY_PARAMS.LOCARNO,
    SEARCH_QUERY_PARAMS.DESIGN_STATUS,
    SEARCH_QUERY_PARAMS.REPRESENTATIVE_NAME,
    SEARCH_QUERY_PARAMS.PRIORITY_COUNTRY,
  ]

  arrayFields.forEach(field => {
    const stringValue = parsedSearchQueryParams[field]
    if (stringValue) {
      const targetArray = Array.isArray(stringValue) ? stringValue : stringValue.split(',')
      parsedSearchQueryParams[field] = targetArray.map(item => decodeURIComponent(item))
    }
  })

  const encodedFields = [
    SEARCH_QUERY_PARAMS.BASIC_SEARCH,
    SEARCH_QUERY_PARAMS.REGISTRATION_NUMBER,
    SEARCH_QUERY_PARAMS.INDICATION_OF_PRODUCT,
    SEARCH_QUERY_PARAMS.DESIGN_NUMBER,
    SEARCH_QUERY_PARAMS.FREE_SELECTION_POINTS,
    SEARCH_QUERY_PARAMS.APPLICATION_NUMBER,
    SEARCH_QUERY_PARAMS.VERBAL_ELEMENT,
    SEARCH_QUERY_PARAMS.PRIORITY_NUMBER,
    SEARCH_QUERY_PARAMS.EXHIBITION_PRIORITY,
  ]

  encodedFields.forEach(field => {
    const stringValue = parsedSearchQueryParams[field]
    if (stringValue) {
      parsedSearchQueryParams[field] = decodeURIComponent(stringValue)
    }
  })

  return { ...parsedSearchQueryParams }
}

const parseFilterQueryParams = filterQueryParams => {
  let parsedFilterQueryParams = { ...filterQueryParams }
  const arrayFields = [
    FILTERS.OFFICES,
    FILTERS.GOODS_AND_SERVICES,
    FILTERS.VIENNA_CODE,
    FILTERS.TRADEMARK_STATUS,
    FILTERS.TRADEMARK_TYPE,
    FILTERS.APPLICATION_DATE,
    FILTERS.APPLICANT_NAME,
    FILTERS.DESIGNER_NAME,
    FILTERS.OWNER_NAME,
    FILTERS.LOCARNO,
    FILTERS.DESIGN_STATUS,
    FILTERS.REGISTRATION_DATE_DS,
    FILTERS.REGISTRATION_DATE,
    FILTERS.FILING_DATE,
  ]

  arrayFields.forEach(field => {
    const stringValue = parsedFilterQueryParams[field]
    if (stringValue) {
      const targetArray = Array.isArray(stringValue) ? stringValue : stringValue.split(',')
      parsedFilterQueryParams[field] = targetArray.map(item => decodeURIComponent(item))
    }
  })

  return parsedFilterQueryParams
}

const prepareSearchQuery = queryParamsString => {
  let queryParamsObject = queryParamsToObj(queryParamsString)
  const { filterQueryParams, searchQueryParams } = getQueriesAndFilters(queryParamsObject)

  const parsedSearchQueryParams = parseSearchQueryParams(searchQueryParams)
  const parsedFilterQueryParams = parseFilterQueryParams(filterQueryParams)
  const searchQueryObject = {
    ...parsedSearchQueryParams,
    ...parsedFilterQueryParams,
  }
  const searchQueryString = queryObjectToString(searchQueryObject)

  const preparedSearchQuery = {
    searchQueryString,
    searchQueryObject,
    filterQueryParams,
    searchQueryParams,
    parsedSearchQueryParams,
    parsedFilterQueryParams,
  }
  currentSearchQuery = preparedSearchQuery
  return preparedSearchQuery
}

const prepareSearchQueryString = queryParamsObject => {
  return queryObjectToString(queryParamsObject)
}

const getCurrentSearchQuery = () => {
  return currentSearchQuery
}

const getCurrentWipoQuery = () => {
  const wipoQueryParams = getWipoQuery(currentSearchQuery.parsedSearchQueryParams)
  const wipoQueryString = queryObjectToString(wipoQueryParams)
  return wipoQueryString
}

const prepareSortedQueryString = (sortColumn, order) => {
  const { parsedSearchQueryParams, parsedFilterQueryParams } = currentSearchQuery
  const searchQueryParamsWithSort = {
    ...parsedSearchQueryParams,
    [SEARCH_QUERY_PARAMS.SORT_COLUMN_ID]: sortColumn,
    [SEARCH_QUERY_PARAMS.SORT_COLUMN_ORDER]: order,
    [SEARCH_QUERY_PARAMS.PAGE]: 1,
  }
  return queryObjectToString({ ...searchQueryParamsWithSort, ...parsedFilterQueryParams })
}

const prepareExportQuery = () => {
  if (!currentSearchQuery) {
    return
  }
  const { parsedSearchQueryParams, parsedFilterQueryParams } = currentSearchQuery
  const forceArrayFieldsTmview = [FILTERS.APPLICANT_NAME, SEARCH_QUERY_PARAMS.APPLICANT_NAME]
  const forceArrayFieldsDsview = [
    SEARCH_QUERY_PARAMS.OWNER_NAME,
    SEARCH_QUERY_PARAMS.DESIGNER_NAME,
    SEARCH_QUERY_PARAMS.DESIGN_STATUS,
    SEARCH_QUERY_PARAMS.LOCARNO,
    SEARCH_QUERY_PARAMS.OFFICES,
    SEARCH_QUERY_PARAMS.PREVIOUS_OWNER_NAME,
    SEARCH_QUERY_PARAMS.PRIORITY_COUNTRY,
    SEARCH_QUERY_PARAMS.REPRESENTATIVE_NAME,
    SEARCH_QUERY_PARAMS.TERRITORIES,
    FILTERS.OWNER_NAME,
    FILTERS.DESIGNER_NAME,
    FILTERS.DESIGN_STATUS,
    FILTERS.LOCARNO,
    FILTERS.OFFICES,
  ]

  const fieldsToDecodeTmview = [
    SEARCH_QUERY_PARAMS.BASIC_SEARCH,
    SEARCH_QUERY_PARAMS.REGISTRATION_NUMBER,
  ]
  const fieldsToDecodeDsview = [
    SEARCH_QUERY_PARAMS.INDICATION_OF_PRODUCT,
    SEARCH_QUERY_PARAMS.DESIGN_NUMBER,
  ]
  const fieldsToDecode = tmdsContextService.isTmview() ? fieldsToDecodeTmview : fieldsToDecodeDsview

  const fieldsToCheck = tmdsContextService.isTmview()
    ? forceArrayFieldsTmview
    : forceArrayFieldsDsview
  let preparedFields = { ...parsedSearchQueryParams, ...parsedFilterQueryParams }
  fieldsToCheck.forEach(field => {
    if (preparedFields[field] && !Array.isArray(preparedFields[field])) {
      preparedFields[field] = preparedFields[field].split(',')
    }
  })

  fieldsToDecode.forEach(field => {
    if (preparedFields[field]) {
      const encodedValue = encodeURIComponent(preparedFields[field])
      try {
        preparedFields[field] = decodeURIComponent(encodedValue)
      } catch (error) {
        console.error(`Error decoding URI component for field ${field}: ${error.message}`)
      }
    }
  })

  return preparedFields
}

const getSavedSearches = (page, pageSize, name) => {
  return userprefsDataManager.getSavedSearches(page, pageSize, name)
}

const { dispatch } = store

const saveSearch = (searchName, searchQuery, searchValues, createdDate, searchId, searchImage) => {
  return dispatch(
    setSaveSearchAction(searchName, searchQuery, searchValues, createdDate, searchId, searchImage)
  )
}

const editSavedSearch = (id, searchName) => {
  return userprefsDataManager.editSavedSearch(id, searchName)
}

const getSavedSearch = id => {
  return userprefsDataManager.getSavedSearch(id)
}

const getSavedSearchHistory = (id, filterParams) => {
  const allowedQueryParams = ['page', 'pageSize', 'from', 'to']
  const parsedQueryParams = allowedQueryParams.reduce((acc, current) => {
    return filterParams[current] ? { ...acc, [current]: filterParams[current] } : acc
  }, {})

  const queryString = queryObjectToString(parsedQueryParams)
  return userprefsDataManager.getSavedSearchHistory(id, queryString)
}

const removeSavedSearch = id => {
  return userprefsDataManager.removeSavedSearch(id)
}

const resetResults = () => {
  dispatch(resetResultState())
  dispatch(clearSearchForm())
}

const exportSavedSearchEvents = (selectedIds, recaptchaResponse, documentType) => {
  const exportPromise = userprefsDataManager.exportSavedSearchEvents(
    selectedIds,
    recaptchaResponse,
    documentType
  )
  manageExport(exportPromise, 'saved-search-history', documentType)
  return exportPromise
}

const setLastSearchUrl = url => {
  lastSearchUrl = url
}

const getLastSearchUrl = () => lastSearchUrl || '/'

export const setOnlyActiveStatus = active => {
  dispatch(onlyActiveStatus(active))
}

export const TMDSSearchService = {
  prepareSearchQuery,
  prepareSearchQueryString,
  getCurrentSearchQuery,
  getCurrentWipoQuery,
  prepareSortedQueryString,
  prepareExportQuery,
  getSavedSearches,
  saveSearch,
  editSavedSearch,
  getSavedSearch,
  getSavedSearchHistory,
  removeSavedSearch,
  resetResults,
  exportSavedSearchEvents,
  setLastSearchUrl,
  getLastSearchUrl,
}
