import React, { Component } from 'react'

import { Option } from './styles'

import Checkbox from '../Checkbox'

export default class extends Component {
  state = { checked: !!this.props.checked }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ checked: props.checked })
  }

  onChange(e) {
    this.setState(({ checked }) => ({ checked: !checked }))
    this.props.onChange && this.props.onChange(e)
  }

  render() {
    const { info, name, count, ...props } = this.props
    return (
      <Option>
        <Checkbox
          label={info ? `${name} (${info})` : name}
          checked={this.state.checked}
          {...props}
          onChange={e => this.onChange(e)}
        />
        <span>{count}</span>
      </Option>
    )
  }
}
