import * as React from 'react'

import Icon from '../Icon'
import Block from '../Block'
import Button from '../Button'
import Content from '../Content'
import { Cookie } from './styles'

export default ({ className, children, onClose, ...props }) => (
  <Cookie className={className} {...props}>
    <Content>
      <Block padding middle>
        <Block width5of6 middle>
          <Block middle width1of12>
            <Icon information />
          </Block>
          <Block width11of12>{children}</Block>
        </Block>
        <Block middle width1of6 right>
          <Button borderless onClick={onClose} icon="multiply" />
        </Block>
      </Block>
    </Content>
  </Cookie>
)
