import styled, { css } from 'styled-components'

import { completHover, scroll } from '../ThemeProvider/utils'

const ButtonPrimary = ({ theme, active }) =>
  css`
    background-color: ${active ? theme.color : theme.color};
    border-color: ${active ? theme.color : theme.colorTransparent};
    color: ${theme.colorWhite};
    line-height: 1.25;
    font-weight: 400;
    &:hover {
      border-color: ${theme.colorTransparent};
      box-shadow: ${theme.dropShadow};
    }
    &:active {
      background-color: ${theme.color};
      border-color: ${theme.colorWhite};
      box-shadow: ${theme.dropShadow};
    }
    &:disabled {
      background-color: ${theme.colorGrayAccent};
      border-color: ${theme.colorTransparent};
      cursor: not-allowed;
      opacity: 0.2;
    }
  `

const ButtonDefault = ({ theme, active }) => css`
  ${active && `box-shadow: 0 0 0 1px ${theme.colorLight}`}
  background-color: ${theme.colorTransparent};
  border-color: ${theme.color};
  color: ${theme.color};
  &:hover {
    background-color: ${theme.color};
    color: ${theme.colorWhite};
    box-shadow: ${theme.dropShadow};

    i {
      color: ${theme.colorWhite};
    }
  }

  ${active &&
    css`
      &:hover {
        border-color: inherit;
      }
      &:focus {
        border-color: inherit;
      }
    `}
  &:active {
    box-shadow: ${theme.dropShadow};
  }
  &:disabled {
    box-shadow: unset;
    color: white;
    background-color: ${theme.colorGrayAccent};
    border-color: ${theme.colorGrayAccent};
    cursor: not-allowed;

    i {
      color: ${theme.colorWhite};
    }
  }
`

const ButtonBorderless = ({ theme, active, underline, isHoverable }) => css`
  background-color: ${theme.colorTransparent};
  border-color: ${active ? theme.color : theme.colorTransparent};
  ${underline && 'text-decoration: underline;'}
  color: ${theme.color};
  padding: 0;
  &:hover {
    border-color: ${isHoverable ? theme.color : theme.colorTransparent};
  }
  &:active {
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`

const ButtonLink = ({ theme, active, variant }) => css`
 ${active && 'text-decoration: underline;'}
  background-color: ${theme.colorTransparent};
  border-color: ${active ? theme.colorLight : theme.colorTransparent};
  color: ${theme.color};
  text-decoration: underline;

  ${variant === 'header' &&
    css`
      text-decoration: none;
      color: ${theme.color};
    `}

  &:hover, &:active {
    background-color: ${theme.color};
    color: ${theme.colorWhite};
    border-color: ${theme.colorTransparent};
    text-decoration: underline;
  }
  &:disabled {
    border-color: ${theme.colorTransparent};
    cursor: not-allowed;
    color: ${theme.colorGrayAccent};
    text-decoration: unset;
  }
`

const ButtonGhost = ({ theme, isExpanded, active }) => css`
  display: flex;
  flex-direction: column;
  border-color: ${active ? theme.color : 'transparent'};
  background-color: transparent;
  .button-icon {
    font-size: 20px;
    margin: 0;
  }

  .button-title {
    display: none;
  }

  :hover {
    border: 1px solid ${theme.color};
  }

  :active {
    border: 1px solid ${theme.color};
    background: ${theme.colorGrayLighter};
  }

  :focus {
    border: 1px solid ${theme.color};
  }

  :disabled {
    opacity: 0.5;
  }

  ${isExpanded &&
    css`
      .button-icon {
        font-size: 30px;
        padding-bottom: 5px;
      }

      .button-title {
        display: block;
      }
      .;
    `}
`

export const Button = styled.button`
  ${({ theme, activeKey }) => activeKey && completHover(theme)};
  border-width: ${({ theme }) => theme.line};
  border-radius: ${({ theme }) => theme.radius};
  border-style: solid;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  flex: 0 1 auto;
  ${({ theme }) => theme.uiText};
  padding: ${({ theme, onlyIcon }) => `${theme.size} ${onlyIcon ? theme.size : theme.size}`};
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${props =>
    props.withIcon &&
    !props.onlyIcon &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button-icon {
        font-size: ${({ iconSize, theme }) =>
          iconSize ? `${iconSize * theme.sizeBase}px` : '30px'};
        ${props.withIcon === 'icon' &&
          css`
            margin-right: 10px;
          `}
        ${props.withIcon === 'iconAfter' &&
          css`
            margin-left: 10px;
          `}
      }
    `};

  ${props => {
    if (props.primary) return ButtonPrimary
    else if (props.borderless) return ButtonBorderless
    else if (props.link) return ButtonLink
    else if (props.ghost) return ButtonGhost
    else return ButtonDefault
  }};

  &:focus {
    outline-width: 0;
  }

  > * {
    pointer-events: none;
  }
`

export const Toggle = styled.div`
  display: flex;
  > button {
    &.active,
    &:active {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  ${({ theme, isExpanded }) =>
    isExpanded &&
    css`
      flex-direction: column;
      > button {
        flex-direction: row;
        padding: 2px 10px;
        margin: 2.5px 0;
        .button-title {
          display: inherit;
        }
        .button-icon {
          margin-right: 10px;
        }
      }
    `}
`

export const Options = styled.div`
  ${({ alignLeft, theme }) => (!alignLeft && !theme.isRTL(theme) ? 'right: 0;' : 'left: 0;')}
  ${({ theme, big }) =>
    big &&
    `
      padding: ${theme.size} ${theme.sizeSmaller};
      min-width: 650px;
    `};
  background-color: ${({ theme }) => theme.colorWhite};
  border-radius: ${({ theme }) => theme.lineThick};
  box-shadow: ${({ theme }) => theme.focusShadow};
  display: ${({ focus, display }) => (focus ? display || 'block' : 'none')};
  position: absolute;
  top: ${({ theme }) => `calc(100% + ${theme.sizeSmall})`};
  z-index: 10;

  .options-options {
    overflow-y: scroll;
    ${({ theme }) => scroll(theme)}
    ${({ maxOptionsHeight }) =>
      maxOptionsHeight && `max-height: ${maxOptionsHeight}px;`}

    & > button {
      border-radius: 0;
      border-width: ${({ theme }) => `0 0 ${theme.line} 0 `};
      border-color: ${({ theme }) => theme.colorGrayAccent};
      color: ${({ theme }) => theme.fontColor};
      margin: 0;
      text-align: left;
      white-space: nowrap;
      width: 100%;
      font-size: inherit;

      &:disabled {
        background: white;
        color: ${({ theme }) => theme.colorGray};
        opacity: 0.4;
      }

      &:hover {
        ${({ theme }) => completHover(theme)};
        background-color: ${({ theme }) => theme.colorAccent};
        color: black;
      }
    }
  }
`

export const Parent = styled.div`
  display: inline-block;
  position: relative;
  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.sizeSmall};
  }
  > button {
    width: 100%;
    /* display: flex;
    flex-direction: row-reverse;
    position: relative;
    justify-content: space-between; */

    /* ${props => css`
      .button-icon {
        font-size: 10px;
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    `} */

    ${({ ghost }) =>
      !ghost &&
      css`
        .button-icon {
          font-size: 20px;
        }
      `}

    i {
      align-self: center;
    }

    ${({ focus }) =>
      focus &&
      `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `};
  }
`
