class previousRegistration {
  static key = 'previousRegistration'
  static registrationDate = `${previousRegistration.key}.registrationDate`
  static registrationNumber = `${previousRegistration.key}.registrationNumber`
  static registrationOfficeCode = `${previousRegistration.key}.registrationOfficeCode`
}

export class TRADEMARK_FIELDS {
  static applicationDate = 'applicationDate'
  static applicationLanguageCode = 'applicationLanguageCode'
  static applicationNumber = 'applicationNumber'
  static applicationReference = 'applicationReference'
  static asItIsprotectionIndicator = 'asItIsprotectionIndicator'
  static basicRegistrationApplication = 'basicRegistrationApplication'
  static codeRegistrationDate = 'codeRegistrationDate'
  static codeTradeDistinctiveness = 'codeTradeDistinctiveness'
  static comments = 'comments'
  static designatedCountries = 'designatedCountries'
  static designationUnderMadridProtocol = 'designationUnderMadridProtocol'
  static designationUnderMadridProtocol96 = 'designationUnderMadridProtocol96'
  static elementText = 'elementText'
  static expiryDate = 'expiryDate'
  static fillingPlace = 'fillingPlace'
  static formerApplicationNumber = 'formerApplicationNumber'
  static goodAndServices = 'goodAndServices'
  static imageDescription = 'imageDescription'
  static internationalMarkKey = 'internationalMarkKey'
  static iprKind = 'iprKind'
  static iprKindTranslated = 'iprKindTranslated'
  static kindMark = 'kindMark'
  static kindMarkTranslated = 'kindMarkTranslated'
  static markCurrentStatusCode = 'markCurrentStatusCode'
  static markCurrentStatusCodeTranslated = 'markCurrentStatusCodeTranslated'
  static markCurrentStatusDate = 'markCurrentStatusDate'
  static markDisclaimer = 'markDisclaimer'
  static markFeature = 'markFeature'
  static markFeatureTranslated = 'markFeatureTranslated'
  static markImageURI = 'markImageURI'
  static markImageColourClaimedText = 'markImageColourClaimedText'
  static markTranslation = 'markTranslation'
  static markTransliteration = 'markTransliteration'
  static markUseIntentCountryCode = 'markUseIntentCountryCode'
  static niceClass = 'niceClass'
  static oppositionPeriodStartDate = 'oppositionPeriodStartDate'
  static oppositionPeriodEndDate = 'oppositionPeriodEndDate'
  static oppositionStartDate = 'oppositionStartDate'
  static oppositionEndDate = 'oppositionEndDate'
  static previousRegistration = previousRegistration
  static receivingOfficeCode = 'receivingOfficeCode'
  static receivingOfficeDate = 'receivingOfficeDate'
  static receivingOfficeNumber = 'receivingOfficeNumber'
  static registrationNumber = 'registrationNumber'
  static registrationOfficeCode = 'registrationOfficeCode'
  static secondLanguageCode = 'secondLanguageCode'
  static seniorities = 'seniorities'
  static seniority = 'seniority'
  static specialCircumstanceText = 'specialCircumstanceText'
  static terminationDate = 'terminationDate'
  static tmName = 'tmName'
  static tmOffice = 'tmOffice'
  static totalMarkSeries = 'totalMarkSeries'
  static tradeDistinctivenessIndicator = 'tradeDistinctivenessIndicator'
  static tradeDistinctivenessText = 'tradeDistinctivenessText'
  static viennaClassification = 'viennaClassification'
}
