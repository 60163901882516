import React, { Component } from 'react'

import { Button } from './styles'

const jumpToSection = (e, section) => {
  e.preventDefault()
  const destination = document.getElementById(section)
  const actionBar = document.getElementById('action-bar-expandable')
  const menuHeader = document.getElementById('menu-header')
  let yOffset = 0
  if (actionBar) {
    const isSticked = actionBar.classList.contains('is-sticky')
    const actionBarHeight = actionBar.offsetHeight
    yOffset = isSticked ? 0 : -actionBarHeight
  } else if (menuHeader) {
    const header = document.getElementsByTagName('header')[0]
    const isSticked = menuHeader.classList.contains('is-sticky')
    const menuHeaderHeight = menuHeader.offsetHeight
    const title = document.getElementById('offices-title')
    if (title) {
      yOffset = isSticked ? -menuHeaderHeight - 20 : -menuHeaderHeight - header.offsetHeight
    } else {
      yOffset = isSticked ? -menuHeaderHeight - 20 : -menuHeaderHeight - header.offsetHeight - 70
    }
  }

  const y = destination.getBoundingClientRect().top + window.pageYOffset + yOffset
  destination && window.scrollTo({ top: y, behavior: 'smooth' })
}

export default class extends Component {
  state = { title: '' }

  componentDidMount() {
    const sectionId = (this.props.history && this.props.history.location.sectionId) || ''
    this.setState({
      title: sectionId
        ? this.props.sections.find(el => el.id === sectionId).description
        : this.props.sections.length && this.props.sections[0].description,
    })
  }

  render() {
    const { hasItemsToShow, sections } = this.props
    const { title } = this.state
    return (
      <Button testId="go-to-dropdown" type="dropdown" title={title} maxOptionsHeight={350}>
        {sections.map(section => (
          <Button
            data-test-id={`go-to-${section.id}`}
            disabled={hasItemsToShow && !hasItemsToShow[section.id]}
            key={section.id}
            title={section.description}
            onClick={e => {
              jumpToSection(e, section.id)
              this.setState({ title: section.description })
            }}
          />
        ))}
      </Button>
    )
  }
}
