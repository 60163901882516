import React from 'react'
import { hasItemsToShow } from '@app/common/utilities'
import { Box } from './Box'
import { DataList, DataItem } from './DataItem'
import { ShowMore } from './ShowMore'
import { DataRow } from './styles'
import { t } from '@app/common/translationsService'
import { totalBoxEntries, getFormatedData } from './utils'
import { PRIORITY_FIELDS } from '@app/common/dataManagers/types'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'
import { useSelector } from 'react-redux'
const SHOWN_ROWS_LIMIT = 1

const fields = [
  {
    label: 'date.key',
    path: PRIORITY_FIELDS.PRIORITY_DATE,
    isDate: true,
  },
  {
    label: 'country.key',
    path: PRIORITY_FIELDS.COUNTRY_CODE,
  },
  {
    label: 'priority.status.key',
    path: PRIORITY_FIELDS.STATUS_CODE,
    pathTranslated: PRIORITY_FIELDS.STATUS_CODE_TRANSLATED,
  },
  {
    label: 'tm.details.label.priorityNumber.key',
    path: PRIORITY_FIELDS.PRIORITY_NUMBER,
  },
  {
    label: 'tm.details.label.partialPriority.key',
    path: PRIORITY_FIELDS.PARTIAL_PRIORITY,
  },
  {
    label: 'tm.details.label.PartialPriorityIndicator.key',
    path: PRIORITY_FIELDS.PARTIAL_PRIORITY_INDICATOR,
  },
  {
    label: 'tm.details.label.comment.key',
    path: PRIORITY_FIELDS.COMMENTS,
  },
]

const BoxPriorityComponent = ({ inidCodes, data = {}, officeExceptionMessage, ...props }) => {
  const [showMore, setShowMore] = React.useState(false)
  const [formatedData, setFormatedData] = React.useState([])
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)

  React.useEffect(
    () => {
      const nextFormatedData = data.map(dataRow => getFormatedData(fields, dataRow, inidCodes))
      setFormatedData(nextFormatedData)
      const hasItems =
        Array.isArray(nextFormatedData) && nextFormatedData.some(row => hasItemsToShow(row))
      hasItems !== props.hasItemsToShow && props.setHasItemsToShow('boxPriority', hasItems)
    },
    [data, showOriginalLanguageAttributes]
  )
  const toggleShowMore = React.useCallback(() => setShowMore(!showMore), [showMore])
  const BoxData =
    props.hasItemsToShow &&
    formatedData.map((dataRow, index) => {
      if (showMore || index < SHOWN_ROWS_LIMIT) {
        const { goodAndServices } = dataRow
        return (
          <DataRow key={index}>
            <DataList
              testId="boxPriority-show-more2"
              data={dataRow}
              literals={props.literals}
              shownItemsLimit={99}
            />
            {showMore && goodAndServices && (
              <div>
                <DataItem
                  label={t('tm.details.label.classificationVersion.key')}
                  data={goodAndServices.langCode}
                />
                {goodAndServices &&
                  Array.isArray(goodAndServices.classDescription) &&
                  goodAndServices.classDescription.map((goodAndService, index) => {
                    return (
                      <div>
                        <DataItem
                          label={t('tm.details.label.classNumber.key')}
                          data={goodAndService.classNumber}
                        />
                        <DataItem
                          label={t('tm.details.label.goodsServicesDescription.key')}
                          data={goodAndService.goodsServicesDescription}
                        />
                        {index + 1 !== goodAndServices.classDescription.length && <hr />}
                      </div>
                    )
                  })}
              </div>
            )}
            {index + 1 !== data.length && showMore && <hr />}
          </DataRow>
        )
      }
    })

  return (
    <Box
      boxId="boxPriority"
      title={`${t('tm.details.left.menu.priority.key')} ${totalBoxEntries(data)}`}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-priority-inner"
      officeExceptionMessage={officeExceptionMessage}
    >
      {BoxData}
      {((props.hasItemsToShow && data.length > SHOWN_ROWS_LIMIT) ||
        (data && data[0] && data[0].goodAndServices)) && (
        <ShowMore
          testId="boxPriority-show-more"
          showMore={showMore}
          showMoreHandle={toggleShowMore}
        />
      )}
    </Box>
  )
}
export const BoxPriority = BoxPriorityComponent
