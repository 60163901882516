import styled from 'styled-components'

export const BackToTopButtonStyled = styled.button`
  ${({ theme }) => theme.uiText};
  background-color: ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.colorWhite};
  position: fixed;
  bottom: ${({ theme }) => theme.sizeDouble};
  right: 15vw;
  height: 38px;
  z-index: 1000;
  cursor: pointer;
  border-width: 0;
  border-radius: 3px;
  padding: 8px 20px;
  outline: none;
  align-items: center;
  box-shadow: ${({ theme }) => theme.dropShadow};

  &:hover {
    background-color: rgba(198, 205, 212, 1);
  }

  i {
    transform: rotate(180deg);
    margin-right: 5px;
  }
`
