import { setHistoryIds } from '@app/actions/history'
import { TMDSTrackingService } from '@app/common/services'
import { isTmview } from '@app/common/tmdsContextService'
import { t } from '@app/common/translationsService'
import Block from '@app/new-lucentum/components/Block/Block'
import { Button, Icon, TooltipTrigger } from '@new-lucentum'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CompareTable from './CompareTable'
import CompareTableDS from './CompareTableDS'
import { Div, Span } from './styles'

export const HistoryComparison = props => {
  const historyData = useSelector(state => state.appState.history.data.transactionInstances)
  const dispatch = useDispatch()
  const historyDate = historyData.map((h, i) => {
    return {
      historyId: h.historyID,
      historyDate: h.historyDate,
    }
  })

  const [selectedFirstOption, setSelectedFirstOption] = useState(historyDate[0]?.historyDate)
  const [selectedSecOption, setSelectedSecOption] = useState('')
  const [historyId, setHistoryId] = useState(null)
  const [targetHistoryId, setTargetHistoryId] = useState(null)

  useEffect(
    () => {
      if (!selectedFirstOption) {
        setSelectedFirstOption(historyDate[0]?.historyDate)
      }
    },
    [historyDate]
  )

  useEffect(
    () => {
      // dispatch(fetchHistory(props.match.params.id))
      prepareFirstOptions()
      prepareSecondOptions()
    },

    [selectedFirstOption, selectedSecOption]
  )

  const prepareFirstOptions = () => {
    return historyDate.filter(
      d => d.historyDate !== selectedFirstOption && d.historyDate !== selectedSecOption
    )
  }

  const prepareSecondOptions = () => {
    return historyDate.filter(
      d => d.historyDate !== selectedFirstOption && d.historyDate !== selectedSecOption
    )
  }

  const prepareFirstDate = date => {
    return setSelectedFirstOption(date)
  }

  const prepareSecDate = date => {
    return setSelectedSecOption(date)
  }

  const createTooltip = content => (
    <Block>{content && <div dangerouslySetInnerHTML={{ __html: content }} />}</Block>
  )

  const text = t('compare.history.tooltip.message.key')

  useEffect(
    () => {
      if (!historyId && targetHistoryId) {
        dispatch(setHistoryIds(historyDate[0].historyId, targetHistoryId))
      } else if (historyId && targetHistoryId) {
        dispatch(setHistoryIds(historyId, targetHistoryId))
      }
    },
    [historyId, targetHistoryId]
  )

  return (
    <Fragment>
      <Span>
        <Div>{t('history.date.key')}: </Div>
        <Button
          testId="go-to-dropdown"
          maxOptionsHeight={220}
          type="dropdown"
          title={
            moment(selectedFirstOption)
              .utc()
              .format('DD/MM/YYYY HH:mm:ss') ||
            moment(historyDate[0]?.historyDate)
              .utc()
              .format('DD/MM/YYYY HH:mm:ss')
          }
        >
          {prepareFirstOptions().map((h, i) => {
            return (
              <Button
                key={i}
                title={moment(h.historyDate)
                  .utc()
                  .format('DD/MM/YYYY HH:mm:ss')}
                onClick={() => {
                  prepareFirstDate(h.historyDate)
                  setHistoryId(h.historyId)
                  TMDSTrackingService.trackHistoryCompare()
                }}
              />
            )
          })}
        </Button>
      </Span>
      {historyDate.length > 1 ? (
        <Span padding>
          <Div>{t('compare.with.key')}: </Div>
          <Button
            maxOptionsHeight={220}
            className="dropdown-btn"
            testId="go-to-dropdown"
            type="dropdown"
            title={
              selectedSecOption
                ? moment(selectedSecOption)
                    .utc()
                    .format('DD/MM/YYYY HH:mm:ss')
                : t('choose.date.message.key')
            }
          >
            {prepareSecondOptions().map((h, i) => (
              <Button
                key={i}
                title={moment(h.historyDate)
                  .utc()
                  .format('DD/MM/YYYY HH:mm:ss')}
                onClick={() => {
                  prepareSecDate(h.historyDate)
                  setTargetHistoryId(h.historyId)
                  TMDSTrackingService.trackHistoryCompare()
                }}
              />
            ))}
          </Button>
        </Span>
      ) : historyDate.length === 1 ? (
        <Fragment>
          <Span>
            <Div style={{ color: '#c5c7c9' }}>{t('compare.with.key')}: </Div>
            <Button
              maxOptionsHeight={220}
              style={{ width: '200px' }}
              testId="go-to-dropdown"
              type="dropdown"
              title={selectedSecOption ? selectedSecOption : t('choose.date.message.key')}
              disabled
            >
              {prepareSecondOptions().map((h, i) => (
                <Button
                  key={i}
                  title={moment(h.historyDate)
                    .utc()
                    .format('DD/MM/YYYY')}
                  onClick={() => prepareSecDate(h.historyDate)}
                />
              ))}
            </Button>
          </Span>
          <Span style={{ padding: '1%', position: 'sticky' }}>
            <TooltipTrigger
              style={{ padding: '10px', position: 'sticky' }}
              tooltipId={t('compare.history.tooltip.message.key')}
              place="bottom"
              renderTooltip={createTooltip(text)}
              delayHide={500}
            >
              <Icon information style={{ color: '#939497', marginRight: 0 }} />
            </TooltipTrigger>
          </Span>
        </Fragment>
      ) : (
        <></>
      )}
      {selectedSecOption ? (
        <span>
          {isTmview() ? (
            <CompareTable
              selectedFirstOption={selectedFirstOption}
              selectedSecOption={selectedSecOption}
            />
          ) : (
            <CompareTableDS
              selectedFirstOption={selectedFirstOption}
              selectedSecOption={selectedSecOption}
            />
          )}
        </span>
      ) : (
        <div style={{ textAlign: 'center', padding: '40px 0 40px 0' }}>
          {t('choose.date.to.compare.with.message.key')}
        </div>
      )}
    </Fragment>
  )
}

export default HistoryComparison
