import React from 'react'
import cn from 'classnames'
import yeast from 'yeast'
import { Icon, Span } from '@new-lucentum'
import Tooltip from '../Tooltip'
import { TooltipValue } from './styles'

/**
 * Higher-Order Component to generate custom value templates
 */
export default self => props => {
  const idNews = yeast()
  const IS_VIENNA = self.props.optionClassName === 'vienna'
  const valueState = self.state.value
  const { value, onRemove } = props
  const isChild = () =>
    IS_VIENNA &&
    Array.isArray(valueState) &&
    valueState.some(x => value.parent && value.parent.some(y => y === x.id))
  const valueLabel = value[IS_VIENNA ? self.props.valueKey : self.props.labelKey]
  return (
    <div data-tip data-for={idNews} className={cn('Select-value', isChild() && 'show')}>
      <Span className="Select-value-label">
        {value.href ? (
          <a href={value.href} target="_blank" rel="noopener noreferrer">
            {valueLabel}
          </a>
        ) : (
          valueLabel
        )}
        {onRemove && (
          <Span
            className="Select-clear"
            onClick={() => self.handlerClick(value, onRemove)}
            onMouseDown={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onTouchStart={e => {
              e.stopPropagation()
            }}
            onTouchEnd={e => {
              e.preventDefault()
              e.stopPropagation()
              self.handlerClick(value, onRemove)
            }}
          >
            <Icon close />
          </Span>
        )}
      </Span>
      {(value.info || value.description) && (
        <Tooltip place={self.props.valueTooltipPlace} id={idNews}>
          <TooltipValue>
            {IS_VIENNA || !value.info ? (
              value.description
            ) : (
              <ul>
                {value.info.map((a, key) => (
                  <li key={key}>{a}</li>
                ))}
              </ul>
            )}
          </TooltipValue>
        </Tooltip>
      )}
    </div>
  )
}
