import React from 'react'
import { Icon } from '@new-lucentum'

export const Collapse = ({ collapsed, collapseHandle }) => {
  return collapsed ? (
    <Icon chevronDown onClick={collapseHandle} />
  ) : (
    <Icon chevronUp onClick={collapseHandle} />
  )
}

export default Collapse
