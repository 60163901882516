import styled, { css } from 'styled-components'

export const Flex = styled.div`
  display: flex;
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `}

  ${({ column }) =>
    column &&
    css`
      flex-flow: column;
    `}

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `}
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${overflow};
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}

`
