import React, { Component } from 'react'

import { Block, Span } from '@new-lucentum'
import { TabsStyled, TabStyled } from './styles'

export default class Tabs extends Component {
  state = { selected: this.props.selected || 0 }

  static defaultProps = {
    selected: 0,
  }

  handleClick = (selected, e) => {
    e.preventDefault()
    this.props.onChange && this.props.onChange(this.state.selected, selected)
    this.setState({ selected })
    this.props.onSelectTab && this.props.onSelectTab(selected)
  }

  renderTitles = () =>
    this.props.children.map((child, index) => {
      return (
        child && (
          <TabStyled
            key={index}
            onClick={this.handleClick.bind(this, index)}
            active={this.state.selected === index}
            className={this.state.selected === index && 'tab-selected'}
            selectedColor={this.props.selectedColor}
          >
            <Span bold={this.state.selected === index} data-test-id={child.props.testId || ''}>
              {child.props.label}
            </Span>
          </TabStyled>
        )
      )
    })

  renderContent = () => this.props.children[this.state.selected]

  render = () => {
    const { onSelectTab, ...tabsProps } = this.props
    return (
      <Block>
        <TabsStyled {...tabsProps}>{this.renderTitles()}</TabsStyled>
        {this.renderContent()}
      </Block>
    )
  }
}
