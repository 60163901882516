import React from 'react'

import { BubbleContainer, BubbleItem, BubbleLabel } from './styles'

const Bubble = ({ indicator, type }) => {
  switch (type) {
    case 'bubble':
      return <BubbleItem>{indicator}</BubbleItem>
    default:
      return <BubbleLabel>({indicator})</BubbleLabel>
  }
}

export default ({ children = '', indicator = 0, showEmpty = false, type, ...props }) => {
  return (
    <BubbleContainer {...props}>
      <span>{children}</span>
      {showEmpty || (indicator && indicator > 0) ? (
        <Bubble indicator={indicator} type={type} />
      ) : (
        ''
      )}
    </BubbleContainer>
  )
}
