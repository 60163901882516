import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import promise from 'redux-promise-middleware'
import { compose, applyMiddleware, createStore } from 'redux'

import reducers from './reducers'

const HIDDEN_ACTIONS = ['EXAMPLE_HIDDEN_ACTION']
const middlewares = [promise(), thunk]
const isDev = process.env.NODE_ENV !== 'production'
const enhancers = (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const logger = createLogger({
  predicate: (_, { type }) => !HIDDEN_ACTIONS.includes(type),
  collapsed: (_, __, logEntry) => !logEntry.error,
})

export default createStore(
  reducers,
  enhancers(applyMiddleware(...(isDev ? [...middlewares, logger] : middlewares)))
)
