import { fetchDetail } from '@app/actions/detail'
import { TMDSTrackingService } from '@app/common/services'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t } from '@app/common/translationsService'
import { Block, Image } from '@new-lucentum'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TableCol, TableRow } from './styledHistoryLayoutData'

function HistoryHeaderTM({ ...props }) {
  const isLoading = useSelector(state => state.appState.history.isLoading)

  const details = useSelector(state => state.appState.detail.data)

  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(fetchDetail(props.match.params.id))
    },
    [dispatch, props.match.params.id]
  )

  let data = props.info

  return (
    !isLoading && (
      <Block maxWidth className="block-table">
        <TableRow>
          {data?.imageUrl && (
            <TableCol id="image-col">
              <Image className="image" src={data.imageUrl} />
            </TableCol>
          )}
          <TableCol style={{ flexGrow: 3 }}>
            <TableRow className="name">{data?.trademarkName}</TableRow>
            <br />
            <TableRow>
              <TableCol>
                <TableRow>{t('applicationnumber.key')}</TableRow>
                <TableRow>
                  <span
                    className="link-number"
                    onClick={() => {
                      TMDSRouterService.navigate(`/detail/${encodeURIComponent(details.ST13)}`)
                      TMDSTrackingService.trackHistoryView()
                    }}
                  >
                    {data?.applicationNumber}
                  </span>
                </TableRow>
              </TableCol>
              <TableCol style={{ flexGrow: 5 }}>
                <TableRow>{t('tm.details.label.registrationOfficeCode.key')}</TableRow>
                <TableRow className="color-gray">{data?.registrationOffice}</TableRow>
              </TableCol>
            </TableRow>
          </TableCol>
        </TableRow>
      </Block>
    )
  )
}

export default HistoryHeaderTM
