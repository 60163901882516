export default {
  'Last month': {
    startDate: now => now.add(-1, 'months').add(1, 'days'),
    endDate: now => now,
  },
  'Last 6 months': {
    startDate: now => now.add(-6, 'months').add(1, 'days'),
    endDate: now => now,
  },
  'Last year': {
    startDate: now => now.add(-1, 'years').add(1, 'days'),
    endDate: now => now,
  },
  'Last 2 years': {
    startDate: now => now.add(-2, 'years').add(1, 'days'),
    endDate: now => now,
  },
}
