import { toggleSpinner } from '@app/actions/globalSpinner'
import { fetchHistory, fetchHistoryDS } from '@app/actions/history'
import { HistoryDataManager } from '@app/common/dataManagers'
import { TMDSTrackingService } from '@app/common/services'
import tmdsContextService, { isTmview } from '@app/common/tmdsContextService'
import { t } from '@app/common/translationsService'
import { NoResultFound } from '@app/components/ResultsRepresentation/styles'
import { Expander } from '@app/new-lucentum/components/Table/styles'
import { Block, Flex, H1, H3, Icon, Modal } from '@new-lucentum'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import disclaimerContent from '../../../../resources/static-files/tmview/disclaimer.html'
import ActionBarHistory from '../../components/TMHistory/ActionBarHistory'
import Pagination from '../../components/TMHistory/Pagination'
import { CardTitle } from '../Participating/styles'
import ExpandedHistoryDSView from './ExpandedHistoryDSView'
import ExpandedHistoryView from './ExpandedHistoryView'
import HistoryComparison from './HistoryComparison'
import HistoryHeaderDS from './HistoryHeaderDS'
import HistoryHeaderTM from './HistoryHeaderTM'
import { Content, StyledHistoryPage, StyledHistoryTable } from './styledHistoryLayoutData'
import { getCurrentLanguage } from '@app/reducers/uiState/language'
import { getContextFromPathname } from '@app/common/utilities'

function TMHistoryTable({ ...props }) {
  const [error, setError] = useState(false)
  const [isSorted, setIsSorted] = useState(false)
  const [template, setTemplate] = useState('')

  const historyData = useSelector(state => state.appState.history.data.transactionInstances)
  const isLoading = useSelector(state => state.appState.history.isLoading)
  const headerInfo = useSelector(state => state.appState.history.data.historyMetaDetails)
  const registrationOfficeCodeTM = useSelector(
    state => state.appState?.detail?.data?.tradeMark?.registrationOfficeCode
  )
  const registrationOfficeCodeDS = useSelector(
    state => state.appState?.detail?.data?.design?.registrationOfficeCode
  )
  const isIPregisterOperational = useSelector(state => state.appState.history.isIPOperational)
  const [showModal, setShowModal] = useState(false)
  const [startDate, setStartDate] = useState(null)

  const dispatch = useDispatch()

  const context = tmdsContextService.get()
  const [language, setLanguage] = useState(getCurrentLanguage())
  const languageSuffix = `_${language}`
  const disclaimerURL =
    context &&
    `${
      context === 'tmview' ? 'tmview' : 'tmdsview-web'
    }/resources/${context}/disclaimer${languageSuffix}.html`

  const parsedTemplate = { __html: template }

  useEffect(
    () => {
      if (disclaimerURL) {
        fetch(disclaimerURL)
          .then(response => response.text())
          .then(text => setTemplate(text))
          .catch(error => {
            console.error(error)
            setLanguage('en')
          })
      }
    },
    [disclaimerURL]
  )

  useEffect(
    () => {
      // toggle spinner
      dispatch(toggleSpinner(isLoading))
    },
    [isLoading, dispatch]
  )

  useEffect(
    () => {
      isTmview()
        ? dispatch(fetchHistory(props.match.params.id))
        : dispatch(fetchHistoryDS(props.match.params.id))
    },
    [dispatch, props.match.params.id]
  )

  useEffect(
    () => {
      if (!isLoading && isIPregisterOperational === false) {
        setError(true)
      }
    },
    [isLoading, isIPregisterOperational]
  )

  const onSort = () => {
    setIsSorted(!isSorted)
  }
  const goToDisclaimer = () => {
    setShowModal(true)
  }

  const columns = [
    {
      width: 30,
      expander: true,
      sortable: false,
      Expander: ({ isExpanded }) => {
        const icon = isExpanded ? 'chevronUp' : 'chevronDown'
        if (isExpanded) {
          TMDSTrackingService.trackHistoryView()
        }
        return (
          <Expander>
            <Icon {...{ [icon]: true }} />
          </Expander>
        )
      },
    },
    {
      Header: props => (
        <span>
          {t('history.date.key')}
          {isSorted ? <Icon arrowDown className="arrow" /> : <Icon arrowUp className="arrow" />}
        </span>
      ),
      accessor: 'historyDate',
      sortable: true,
      Cell: row => moment(row.value).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      Header: props => <span>{t('status.key')}</span>,
      accessor: 'statusCode',
      sortable: false,
    },
  ]

  useEffect(
    () => {
      if (isTmview() && registrationOfficeCodeTM) {
        HistoryDataManager.isBlockchain(registrationOfficeCodeTM).then(res => {
          setStartDate(res.historyStartDate)
        })
      }
    },
    [registrationOfficeCodeTM]
  )

  useEffect(
    () => {
      if (!isTmview() && registrationOfficeCodeDS) {
        HistoryDataManager.isBlockchain(registrationOfficeCodeDS).then(res => {
          setStartDate(res.historyStartDate)
        })
      }
    },
    [registrationOfficeCodeDS]
  )

  return (
    <StyledHistoryPage>
      <ActionBarHistory {...props} />
      <Flex className="header">
        <H1>
          {isTmview() ? t('trade.mark.history.key') : t('design.history.key')}
          <span className="disclaimer" onClick={() => goToDisclaimer()}>
            {t('disclaimer.key')}
          </span>
        </H1>
        <Modal
          reactModalContentStyle={{
            height: 'auto',
            maxHeight: '85%',
          }}
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          contentStyle={{ overflow: 'auto' }}
          modalSize="large"
          title={t('disclaimer.key')}
        >
          <div dangerouslySetInnerHTML={parsedTemplate} />
        </Modal>
      </Flex>
      <Content maxWidth>
        <Content>
          <Flex>
            <Block maxWidth className="services-table">
              <div className="services-header">{t('history.registers.blockchain.key')}</div>
              <div className="services-info">{t('history.services.info.key')}</div>
            </Block>
          </Flex>
          <Flex className="history-text">
            {isTmview() ? t('tm.history.text.key') : t('ds.history.text.key')}
          </Flex>
          <Flex>
            {isTmview() ? (
              <HistoryHeaderTM {...props} info={headerInfo} />
            ) : (
              <HistoryHeaderDS info={headerInfo} {...props} />
            )}
          </Flex>
          <Flex>
            {historyData?.length > 1 && !isLoading && !error ? (
              <Block maxWidth className="block-table">
                <Block middle marginBottom={2}>
                  <CardTitle>
                    <H3>{t('autocomplete.history.key')}</H3>
                  </CardTitle>
                  {startDate &&
                    t('history.startDate.key', {
                      0: startDate,
                      1: headerInfo.registrationOffice,
                    })}
                </Block>
                <StyledHistoryTable
                  data={historyData}
                  columns={columns}
                  onSortedChange={onSort}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={5}
                  minRows={1}
                  SubComponent={row =>
                    isTmview() ? (
                      <ExpandedHistoryView {...props} selectedRow={historyData[row.index]} />
                    ) : (
                      <ExpandedHistoryDSView selectedRow={historyData[row.index]} />
                    )
                  }
                  PaginationComponent={Pagination}
                  resizable={false}
                />
              </Block>
            ) : !isLoading && !error ? (
              <NoResultFound className="block-table">
                {t('history.data.are.not.available.key')}
              </NoResultFound>
            ) : (
              error && (
                <NoResultFound className="block-table" id="red-color">
                  {t('system.unavailable.key')}
                </NoResultFound>
              )
            )}
          </Flex>
          <Flex>
            {!isLoading && historyData?.length > 1 && !error && (
              <Block maxWidth className="block-table">
                <Block marginBottom={1}>
                  <CardTitle>
                    <H3>{t('compare.history.title.key')}</H3>
                  </CardTitle>
                </Block>
                <HistoryComparison />
              </Block>
            )}
          </Flex>
        </Content>
      </Content>
    </StyledHistoryPage>
  )
}

export default TMHistoryTable
