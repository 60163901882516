import styled from 'styled-components'
import Block from '../Block/Block'

const C_ERROR = 'error'
const C_SUCCES = 'success'
const C_WARNING = 'warning'

const color = props => {
  if (props[C_ERROR] || props.color === C_ERROR)
    return `
            background-color: ${props.theme.colorErrorAccent};
            border-color: ${props.theme.colorError};
            i { color: ${props.theme.colorError}}`
  else if (props[C_SUCCES] || props.color === C_SUCCES)
    return `
            background-color: ${props.theme.colorSuccessAccent};
            border-color: ${props.theme.colorSuccess};
            i { color: ${props.theme.colorSuccess}}`
  else if (props[C_WARNING] || props.color === C_WARNING)
    return `
            background-color: ${props.theme.colorWarningAccent};
            border-color: ${props.theme.colorWarning};
            i { color: ${props.theme.colorWarning};}`
  else {
    return `
        background-color: ${props.theme.colorGrayAccent};`
  }
}

export const NotificationStyled = styled(Block)`
  ${props => color(props)};
  ${({ theme }) => theme.baseText}
  padding: 20px;
  border-radius: ${({ theme }) => theme.radius};
  border-style: solid;
  border-width: ${({ theme }) => theme.line};
  color: ${({ theme }) => theme.colorGray};
  margin: ${({ theme }) => theme.size} 0;

  i {
    font-size: ${({ theme }) => theme.sizeDouble};
  }

  button {
    background-color: ${({ theme }) => theme.colorTransparent};
    border-color: ${({ theme }) => theme.colorTransparent};
    color: ${({ theme }) => theme.colorGray};

    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colorTransparent};
      color: ${({ theme }) => theme.colorGray};
    }
    i {
      color: ${({ theme }) => theme.colorGray};
    }
  }
`
