import React from 'react'
import { Rect, Transformer } from 'react-konva'

export const Rectangle = ({
  shapeProps,
  isSelected,
  showTransform,
  onSelect,
  onChange = values => console.log({ values }),
}) => {
  const shapeRef = React.useRef()
  const trRef = React.useRef()

  React.useEffect(
    () => {
      if (showTransform) {
        // we need to attach transformer manually
        trRef.current.nodes([shapeRef.current])
        trRef.current.getLayer().batchDraw()
      }
    },
    [showTransform]
  )

  const lineProps = isSelected ? { strokeWidth: 3, dashEnabled: false } : {}

  return (
    <React.Fragment>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable
        {...shapeProps}
        {...lineProps}
        onDragEnd={e => {
          onChange({
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        onMouseEnter={e => {
          const container = e.target.getStage().container()
          container.style.cursor = e.currentTarget.id() === 'selection' ? 'move' : ''
        }}
        onMouseLeave={e => {
          const container = e.target.getStage().container()
          container.style.cursor = ''
        }}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          // we will reset it back
          node.scaleX(1)
          node.scaleY(1)
          onChange({
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          })
        }}
      />
      {showTransform && (
        <Transformer
          id="selection-transformer"
          ref={trRef}
          rotateEnabled={false}
          borderStroke="red"
          anchorStroke="red"
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}
