import { getDigitsFromString } from '@app/common/utilities'
import { RESULTS_TYPES } from '@app/redux-types'
import { ALERTS_TYPES } from '@app/redux-types'

const defaultState = {
  alertsByTradeMark: [],
  data: {
    manageAlerts: [],
    page: 1,
    totalPages: 1,
    totalResults: 0,
    tradeMarks: [],
  },
}

const getAlertsByTradeMark = (alertsByTradeMark, modifiedAlert) => {
  if (!modifiedAlert.dateDaysLimit) {
    modifiedAlert.dateDaysLimit = ''
  }
  modifiedAlert.dateDaysLimit = getDigitsFromString(modifiedAlert.dateDaysLimit)
  return alertsByTradeMark.map(alert => (alert.ST13 === modifiedAlert.ST13 ? modifiedAlert : alert))
}

const getAlert = (alertsByTM, ST13) => alertsByTM.find(alertTM => alertTM.ST13 === ST13)

const getAlertsByTradeMarkList = (alertsByTradeMark, modifiedAlert) => {
  if (!modifiedAlert.dateDaysLimit) {
    modifiedAlert.dateDaysLimit = ''
  }
  return alertsByTradeMark.map(alert => {
    if (modifiedAlert.alertsByTM.map(alertTM => alertTM.ST13).includes(alert.ST13)) {
      const { dateDaysLimit, nameChange, oppPeriodChange, statusChange } = modifiedAlert
      alert = {
        ...alert,
        ST13: alert.ST13,
        idAlert: getAlert(modifiedAlert.alertsByTM, alert.ST13).idAlert,
        dateDaysLimit,
        nameChange,
        oppPeriodChange,
        statusChange,
      }
    }
    return alert
  })
}

const getTradeMarksWithExtraData = (detail, tradeMarks, meta) =>
  tradeMarks &&
  tradeMarks.map(tm => {
    if (tm.ST13 === meta) {
      const { tradeMark, goodsAndServicesList } = detail
      const [firstElement] = goodsAndServicesList || []
      tm.goodsAndServicesList =
        tradeMark && tradeMark.goodAndServices ? tradeMark.goodAndServices : []
      tm.expirationDate = tradeMark ? tradeMark.expiryDate : tm.expirationDate
      tm.goodAndServices = firstElement ? firstElement.goodAndServices : []
    }
    return tm
  })

export default (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case 'FETCH_ALERTS_BY_MARKS_FULFILLED':
      return {
        ...state,
        alertsByTradeMark: payload,
      }
    case 'CHANGE_DAYS_LIMIT':
      return {
        ...state,
        data: {
          ...state.data,
          manageAlerts: getAlertsByTradeMark(state.data.manageAlerts, payload),
        },
      }
    case 'CHANGE_OPPO_PERIOD':
      return {
        ...state,
        data: {
          ...state.data,
          manageAlerts: getAlertsByTradeMark(state.data.manageAlerts, payload),
        },
      }
    case 'CHANGE_OPPO_PERIOD_MANAGE':
      return {
        ...state,
        data: {
          ...state.data,
          manageAlerts: getAlertsByTradeMark(state.data.manageAlerts, payload),
        },
      }
    case 'CHANGE_DAYS_LIMIT_MANAGE':
      return {
        ...state,
        data: {
          ...state.data,
          manageAlerts: getAlertsByTradeMark(state.data.manageAlerts, payload),
        },
      }
    case ALERTS_TYPES.GET_ALERTS_FULLFILLED:
      return {
        ...state,
        data: payload,
      }
    case 'ADD_ALERTS_MANAGE_FULFILLED':
      return {
        ...state,
        data: {
          ...state.data,
          manageAlerts: getAlertsByTradeMarkList(state.data.manageAlerts, payload),
        },
      }
    case ALERTS_TYPES.SET_ALERTS_FULFILLED:
      return {
        ...state,
        data: {
          ...state.data,
        },
      }
    case RESULTS_TYPES.FETCH_DETAIL_EXPANDED_FULFILLED:
      return {
        ...state,
        data: {
          ...state.data,
          tradeMarks: getTradeMarksWithExtraData(payload, state.data.tradeMarks, meta.id),
        },
      }
    case 'FETCH_SEARCH_TM_FULFILLED':
      const tradeMarks = payload.tradeMarks
        ? payload.tradeMarks.map(tm => ({
            ...tm,
            ...state.data.manageAlerts.find(atm => tm.ST13 === atm.ST13),
          }))
        : []
      return {
        ...state,
        data: { ...state.data, tradeMarks: tradeMarks },
      }

    case 'FETCH_SEARCH_TM_REJECTED':
      return {
        ...state,
        data: { ...state.data, tradeMarks: [] },
      }

    case 'RESET_MANAGE_ALERTS':
      return defaultState

    default:
      return state
  }
}
