import { REPRESENTATIVES_FIELDS } from '@app/common/dataManagers/types'

export const representativeFields = [
  {
    label: 'freeformatname.key',
    path: REPRESENTATIVES_FIELDS.FULL_NAME,
  },
  {
    label: 'applicantincorporationcountrycode.key',
    path: REPRESENTATIVES_FIELDS.INCORPORATION_COUNTRY_CODE,
  },
  {
    label: 'tm.details.label.template.paintName.OrganizationName.key',
    path: REPRESENTATIVES_FIELDS.ORGANIZATION_NAME,
  },
  {
    label: 'representativeidentifier.key',
    path: REPRESENTATIVES_FIELDS.IDENTIFIER,
  },
  {
    label: 'representativelegalentity.key',
    path: REPRESENTATIVES_FIELDS.LEGAL_ENTITY,
    pathTranslated: REPRESENTATIVES_FIELDS.LEGAL_ENTITY_TRANSLATED,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.RepresentativeKindCode.key',
    path: REPRESENTATIVES_FIELDS.KIND_CODE,
    pathTranslated: REPRESENTATIVES_FIELDS.KIND_CODE_TRANSLATED,
  },
  {
    label: 'representativenationalitycode.key',
    path: REPRESENTATIVES_FIELDS.NATIONALITY_CODE,
  },
  {
    label: 'representativeincorporationcountrycode.key',
    path: REPRESENTATIVES_FIELDS.INCORPORATION_STATE,
  },
  {
    label: 'address.key',
    path: REPRESENTATIVES_FIELDS.FULL_ADDRESS,
  },
  {
    label: 'phone.key',
    path: REPRESENTATIVES_FIELDS.PHONE,
  },
  { label: 'fax.key', path: REPRESENTATIVES_FIELDS.FAX },
  { label: 'email.key', path: REPRESENTATIVES_FIELDS.EMAIL },
  { label: 'url.key', path: REPRESENTATIVES_FIELDS.URL },
  {
    label: 'address.key',
    path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.ADDRESS,
  },
  { label: 'addresscity.key', path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.CITY },
  {
    label: 'tm.details.label.template.paintAddress.AddressState.key',
    path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.STATE,
  },
  {
    label: 'addresspostcode.key',
    path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.POSTCODE,
  },
  {
    label: 'addresscountrycode.key',
    path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.COUNTRY_CODE,
  },
]
