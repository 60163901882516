import { fullfilled, pending, rejected } from './utils/reduxPromise'
const LOAD_LOCARNO = 'LOAD_LOCARNO'
const LOAD_LOCARNO_SUBCLASSES = 'LOAD_LOCARNO_SUBCLASSES'
const LOAD_LOCARNO_CHILDREN = 'LOAD_LOCARNO_CHILDREN'
const TOGGLE_LOCARNO = 'TOGGLE_LOCARNO'
const RESTORE_INITIAL_LOCARNO = 'RESTORE_INITIAL_LOCARNO'
const CHANGE_LOCARNO_VERSION = 'CHANGE_LOCARNO_VERSION'
const SEARCH_LOCARNO_SUCCESS = 'SEARCH_LOCARNO_SUCCESS'
const SEARCH_LOCARNO_ERROR = 'SEARCH_LOCARNO_ERROR'
const LOAD_EXPANDED_LOCARNO = 'LOAD_EXPANDED_LOCARNO'

export const LOCARNO_TYPES = {
  LOAD_LOCARNO,
  LOAD_LOCARNO_FULFILLED: fullfilled(LOAD_LOCARNO),
  LOAD_LOCARNO_PENDING: pending(LOAD_LOCARNO),
  LOAD_LOCARNO_REJECTED: rejected(LOAD_LOCARNO),
  LOAD_LOCARNO_CHILDREN,
  LOAD_LOCARNO_CHILDREN_FULFILLED: fullfilled(LOAD_LOCARNO_CHILDREN),
  LOAD_LOCARNO_CHILDREN_PENDING: pending(LOAD_LOCARNO_CHILDREN),
  LOAD_LOCARNO_CHILDREN_REJECTED: rejected(LOAD_LOCARNO_CHILDREN),
  LOAD_LOCARNO_SUBCLASSES,
  LOAD_LOCARNO_SUBCLASSES_FULFILLED: fullfilled(LOAD_LOCARNO_SUBCLASSES),
  TOGGLE_LOCARNO,
  RESTORE_INITIAL_LOCARNO,
  CHANGE_LOCARNO_VERSION,
  SEARCH_LOCARNO_SUCCESS,
  SEARCH_LOCARNO_ERROR,
  LOAD_EXPANDED_LOCARNO,
  LOAD_EXPANDED_LOCARNO_FULFILLED: fullfilled(LOAD_EXPANDED_LOCARNO),
  LOAD_EXPANDED_LOCARNO_PENDING: pending(LOAD_EXPANDED_LOCARNO),
  LOAD_EXPANDED_LOCARNO_REJECTED: rejected(LOAD_EXPANDED_LOCARNO),
}
