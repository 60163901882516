import { initDetailNavigationData, setOfficeExceptionMessages } from '@app/actions/detail'
import { searchDataManager } from '@app/common/dataManagers'
import store from '@app/store'

const { dispatch } = store

const setDetailNavigationData = (st13, navigationData) => {
  return dispatch(initDetailNavigationData(navigationData, st13))
}

const getOfficeExceptionMessages = (officeCode, language, currentOfficeExceptionMessages) => {
  const officeExceptionMessages = currentOfficeExceptionMessages[officeCode]
  const currentLanguageExceptionMessages =
    officeExceptionMessages && officeExceptionMessages[language]
  if (!currentLanguageExceptionMessages) {
    searchDataManager.getOfficeExceptionMessages(officeCode).then(officeMessages => {
      if (officeMessages && officeMessages.length) {
        const officeMessagesPerSection = officeMessages.reduce((acc, officeMessage) => {
          acc[officeMessage.section] = officeMessage
          return acc
        }, {})
        const preparedOfficeMessages = {
          [`${language}`]: officeMessagesPerSection,
        }
        dispatch(setOfficeExceptionMessages(officeCode, preparedOfficeMessages))
      }
    })
  }
}

export const TMDSDetailsService = {
  setDetailNavigationData,
  getOfficeExceptionMessages,
}
