import { EVENT_KEY_CODES } from '@app/common/constants'
import { useResponsive } from '@app/common/hooks'
import { pad } from '@app/common/utilities'
import { Icon, P, Tooltip } from '@new-lucentum'
import debounce from 'lodash.debounce'
import React from 'react'
import Highlighter from 'react-highlight-words'
import AsyncBoolean, { components } from 'react-select-boolean'
import { compose, withHandlers, withState } from 'recompose'
import { withTheme } from 'styled-components'

import Boolean from './Boolean'
import MenuList from './MenuList'
import {
  BlockTooltip,
  Button,
  ClearAllIcon,
  Container,
  ControlContainer,
  ErrorMsg,
  Group,
  GroupBadge,
  Option,
  styleSelect,
  ValueWrapper as VWrapper,
} from './styles'

//const WRITTING_VIENNA_CODE_REGEX = /^\d{1,2}(\.\d{0,2}){0,2}$/g
const VIENNA_CODE_REGEX = /^\d{1,2}(\.\d{1,2}){0,2}$/g
const VIENNA_CODE_RANGE_REGEX = /^((\d+\.\d+.?)+\.\.(\d+\.\d+.?)+)$/g
const ZERO_VIENNA_CODE_REGEX = /^\d{1,2}(\.\d{0,2}){0,2}$/g
const ACTION_INPUT_CHANGE = 'input-change'
const ACTION_MENU_CLOSE = 'menu-close'
const LOAD_OPTIONS = 'loadOptions'
const LOAD_OPTION_SEARCH = 'loadOptionSearch'
const LOAD_OPTION_CHILDREN = 'loadAndAddOption'
const { ENTER, COMMA, SPACE, SPACE_TAB, KEYCODE_SPACE, KEYCODE_COMMA } = EVENT_KEY_CODES

const formatGroupLabel = ({ description, options }) => (
  <Group>
    <span>{description}</span>
    <GroupBadge>{options.length}</GroupBadge>
  </Group>
)

const IndicatorSeparator = false

const onChangeBoolean = (bl, { data, selectProps }) =>
  selectProps.onChange(
    selectProps.value.map(opt =>
      opt.code === data.code
        ? {
            ...data,
            operator: bl.value,
          }
        : opt
    )
  )

const onClickOption = ({ value, hasValue, setValue, getValue, parent, description }) => {
  setValue([
    ...getValue(),
    {
      value,
      parent,
      code: value,
      description,
      operator: hasValue ? 'OR' : null,
    },
  ])
}

const defaultGetValueTooltip = (options, code, description) => {
  const option = code && options && options.find && options.find(x => x.code === code)
  return (option && option.description) || description || ''
}

const ValueWrapper = getValueTooltip => props => {
  props.selectProps.value &&
    props.selectProps.value[0] &&
    props.selectProps.value[0].operator &&
    (props.selectProps.value[0].operator = null)

  return (
    <VWrapper>
      {!props.selectProps.hideBooleanComponent && props.data.operator && (
        <Boolean
          boolean={props.data.operator}
          onChange={e => onChangeBoolean(e, props)}
          {...props}
        />
      )}
      <div data-tip data-for={`valueV${props.data.code}`}>
        <components.MultiValueContainer boolean={props.data.operator} {...props} />
      </div>
      {props.selectProps.options && props.data.code && props.data.description && (
        <Tooltip id={`valueV${props.data.code}`} offset={{ top: -5 }}>
          <BlockTooltip>
            {getValueTooltip(props.selectProps.options, props.data.code, props.data.description)}
          </BlockTooltip>
        </Tooltip>
      )}
    </VWrapper>
  )
}
const MultiValueLabel = ({ ...props }) => {
  const label = props.selectProps.formatLabel
    ? props.selectProps.formatLabel(props.data.value, props.data.description)
    : props.data.value
  return <components.MultiValueLabel {...props}>{label}</components.MultiValueLabel>
}

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon close />
    </components.MultiValueRemove>
  )
}

const CustomControl = props => (
  <ControlContainer data-test-id={'react-select-control-container'}>
    <components.Control {...props} />
  </ControlContainer>
)

const CustomOption = ({ data, innerProps, ...props }) => {
  return (
    <Option
      key={data.code}
      isFocused={props.isFocused}
      level={data.level}
      vienna
      data-test-id={'react-select-option-container'}
    >
      <components.Option {...props}>
        <div className="containerOption">
          {data.children && (
            <Button
              onClick={() =>
                data.childrenLoaded &&
                props.selectProps.toggleOption &&
                props.selectProps.loadOptionChildren
                  ? props.selectProps.toggleOption(data.code, !data.chevron)
                  : props.selectProps.loadOptionChildren(data.code)
              }
              icon={data.chevron ? 'chevron-down' : 'chevron-right'}
            />
          )}
          <div
            className="title"
            onClick={() => {
              onClickOption({ ...props, ...data })
              props.selectProps.toggleOption && props.selectProps.toggleOption(data.code)
            }}
          >
            <strong>
              <Highlighter
                searchWords={[props.selectProps.inputValue]}
                textToHighlight={data.code}
                highlightClassName="highlighted"
              />
            </strong>
            <P>
              <Highlighter
                searchWords={[props.selectProps.inputValue]}
                textToHighlight={data.description}
                highlightClassName="highlighted"
              />
            </P>
          </div>
        </div>
      </components.Option>
    </Option>
  )
}

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon chevronDown />
  </components.DropdownIndicator>
)

const fetchingVienna = debounce((type, props, inputValue) => {
  props.setLoading({ search: true })
  switch (type) {
    case LOAD_OPTIONS:
      props.loadInitOption() && props.setLoading({ search: false })
      break
    case LOAD_OPTION_SEARCH:
      props
        .searchOption(inputValue)
        .then(() => props.setLoading({ search: false }), () => props.setLoading({ search: false }))
      break
    case LOAD_OPTION_CHILDREN:
      props
        .searchOptionHierarchically(inputValue)
        .then(() => props.setLoading({ search: false }), () => props.setLoading({ search: false }))
      break
    default:
      break
  }
}, 1000)

const setValueOfRange = (props, value) => {
  props.setLoading({ range: true })
  props.searchOption(value, true).then(
    data =>
      Array.isArray(data) &&
      !props.setAutoCompleteOptions(
        data.map((x, i) => ({
          ...x,
          value: x.code,
          operator: i === 0 && props.value.length === 0 ? null : 'OR',
        }))
      ) &&
      props.setLoading({ range: false }),
    err => () => console.error(err) || props.setLoading({ range: false })
  )
}

const onInputValue = props => (value, { action }) => {
  props.setInputValue(value)
  if (action === ACTION_INPUT_CHANGE && value) {
    const isRangedTyped = value.match(VIENNA_CODE_RANGE_REGEX)
    if (isRangedTyped && isRangedTyped.length) {
      setValueOfRange(props, value)
      fetchingVienna(LOAD_OPTION_SEARCH, props, value)
    } else if (!value.includes('..') && value && action === ACTION_INPUT_CHANGE) {
      fetchingVienna(LOAD_OPTION_SEARCH, props, value)
    } else {
      props.setAutoCompleteOptions([])
    }
  } else if (action === ACTION_MENU_CLOSE && !value) {
    props.setAutoCompleteOptions([])
    fetchingVienna(LOAD_OPTIONS, props)
  }
}

const blurManagement = props => e => {
  onKeyDown({ keyCode: ENTER, preventDefault: e.preventDefault }, props)
  !props.setFocus(false) && props.loadInitOption()
  props.onBlurFn && props.onBlurFn(props.value)
}

const enhance = compose(
  withState('inputValue', 'setInputValue', ''),
  withState('loading', 'setLoading', { search: false, range: false }),
  withState('focus', 'setFocus', false),
  withState('autoCompleteOptions', 'setAutoCompleteOptions', []),
  withState('shownOptionsVersion', 'setShownOptionsVersion', props => props.optionsVersion || ''),
  withHandlers({
    onInputValue: onInputValue,
    onFocus: ({ setFocus }) => () => setFocus(true),
    onBlur: blurManagement,
    onChangeValue: props => values => {
      const valuesSet = values.length ? [{ ...values[0], operator: null }, ...values.slice(1)] : []
      const filteredValues = filterInputValues(valuesSet)
      props.onChange && props.onChange(filteredValues)
      fetchingVienna(LOAD_OPTIONS, props)
    },
  })
)

const filterInputValues = inputValues =>
  inputValues.reduce((acc, curr) => {
    const newAcc = [...acc.filter(x => !(x.parent && x.parent.includes(curr.code)))]
    if (!newAcc.some(x => curr.code == x.code || (curr.parent && curr.parent.includes(x.code)))) {
      newAcc.push(curr)
    }
    return newAcc
  }, [])

const onKeyDown = (e, props, isMobile) => {
  if (!isMobile) {
    if (
      [ENTER, SPACE, SPACE_TAB].includes(e.keyCode) &&
      (!props.inputValue.length || props.loading.search || props.loading.range)
    ) {
      e.preventDefault()
      return
    }
    if (
      [ENTER, COMMA, SPACE, SPACE_TAB].includes(e.keyCode) &&
      props.inputValue.match(VIENNA_CODE_RANGE_REGEX)
    ) {
      e.preventDefault()
      props.onChange(filterInputValues([...props.value, ...props.autoCompleteOptions]))
      !props.setInputValue('') &&
        !fetchingVienna(LOAD_OPTIONS, props) &&
        props.setAutoCompleteOptions([])
    } else if (
      [ENTER, COMMA, SPACE, SPACE_TAB].includes(e.keyCode) &&
      props.inputValue.match(props.customCodeRegex ? props.customCodeRegex : VIENNA_CODE_REGEX)
    ) {
      e.preventDefault()
      const newViennaCode = props.options[0]
      newViennaCode.operator = 'OR'
      newViennaCode.value = newViennaCode.code
      newViennaCode.code ===
        props.inputValue
          .split('.')
          .map(n => pad(n, 2, 0))
          .join('.') &&
        !props.onChange(filterInputValues([...props.value, newViennaCode])) &&
        !props.setInputValue('') &&
        fetchingVienna(LOAD_OPTIONS, props)
    } else if (e.keyCode === ENTER && props.options.length > 0) {
      e.preventDefault()
      const newViennaCode = props.options[0]
      newViennaCode.operator = 'OR'
      newViennaCode.value = newViennaCode.code
      const reDescription = new RegExp(props.inputValue, 'i')
      reDescription.test(newViennaCode.description) &&
        !newViennaCode.code.match(ZERO_VIENNA_CODE_REGEX) &&
        !props.onChange(filterInputValues([...props.value, newViennaCode])) &&
        !props.setInputValue('') &&
        fetchingVienna(LOAD_OPTIONS, props)
    }
  } else {
    if (
      [ENTER, SPACE, SPACE_TAB, KEYCODE_SPACE].includes(e.keyCode) &&
      (!props.inputValue.length || props.loading.search || props.loading.range)
    ) {
      e.preventDefault()
      return
    }
    if (
      [ENTER, COMMA, SPACE, SPACE_TAB, KEYCODE_SPACE, KEYCODE_COMMA].includes(e.keyCode) &&
      props.inputValue.match(VIENNA_CODE_RANGE_REGEX)
    ) {
      e.preventDefault()
      props.onChange(filterInputValues([...props.value, ...props.autoCompleteOptions]))
      !props.setInputValue('') &&
        !fetchingVienna(LOAD_OPTIONS, props) &&
        props.setAutoCompleteOptions([])
    } else if (
      [ENTER, COMMA, SPACE, SPACE_TAB, KEYCODE_SPACE, KEYCODE_COMMA].includes(e.keyCode) &&
      props.inputValue.match(props.customCodeRegex ? props.customCodeRegex : VIENNA_CODE_REGEX)
    ) {
      e.preventDefault()
      const newViennaCode = props.options[0]
      newViennaCode.operator = 'OR'
      newViennaCode.value = newViennaCode.code
      newViennaCode.code ===
        props.inputValue
          .split('.')
          .map(n => pad(n, 2, 0))
          .join('.') &&
        !props.onChange(filterInputValues([...props.value, newViennaCode])) &&
        !props.setInputValue('') &&
        fetchingVienna(LOAD_OPTIONS, props)
    } else if (e.keyCode === ENTER && props.options.length > 0) {
      e.preventDefault()
      const newViennaCode = props.options[0]
      newViennaCode.operator = 'OR'
      newViennaCode.value = newViennaCode.code
      const reDescription = new RegExp(props.inputValue, 'i')
      reDescription.test(newViennaCode.description) &&
        !newViennaCode.code.match(ZERO_VIENNA_CODE_REGEX) &&
        !props.onChange(filterInputValues([...props.value, newViennaCode])) &&
        !props.setInputValue('') &&
        fetchingVienna(LOAD_OPTIONS, props)
    }
  }
}

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <ClearAllIcon close />
  </components.ClearIndicator>
)

const getFilterOption = props => ({ data }) =>
  !data.hide &&
  !!data.code &&
  (!props.value.length || !props.value.some(value => data.code.indexOf(value.code) === 0))

const Vienna = props => {
  const { isMobile } = useResponsive()

  React.useEffect(
    () => {
      props.onChangeValue([])
      props.setShownOptionsVersion(props.optionsVersion)
    },
    [props.optionsVersion]
  )
  return (
    <Container>
      <div data-tip data-for="viennaTooltip">
        <AsyncBoolean
          cacheOptions
          defaultOptions
          components={{
            IndicatorSeparator,
            MultiValueContainer: ValueWrapper(
              !!props.getValueTooltip ? props.getValueTooltip : defaultGetValueTooltip
            ),
            MultiValueLabel,
            Option: CustomOption,
            Control: CustomControl,
            DropdownIndicator,
            ClearIndicator,
            MenuList,
            MultiValueRemove,
          }}
          isLoading={props.loading.search || props.loading.range}
          closeMenuOnSelect={false}
          filterOption={getFilterOption(props)}
          formatGroupLabel={formatGroupLabel}
          isMulti
          inputValue={props.inputValue}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onInputChange={props.onInputValue}
          onKeyDown={e => onKeyDown(e, props, isMobile)}
          styles={styleSelect}
          {...props}
          onChange={props.onChangeValue}
          classNamePrefix={'react-select-comp'}
        />
      </div>
      {props.tooltip && !props.focus && (
        <Tooltip offset={{ right: 320 }} id="viennaTooltip" place="bottom">
          <BlockTooltip>{props.tooltip()}</BlockTooltip>
        </Tooltip>
      )}
      {!!props.errorMessage && !props.focus && <ErrorMsg>{props.errorMessage}</ErrorMsg>}

      {props.max && props.maxMessage && !props.focus && (
        <Tooltip offset={{ right: 320 }} id="viennaTooltip" place="bottom" type="warning">
          <BlockTooltip>{props.maxMessage}</BlockTooltip>
        </Tooltip>
      )}
    </Container>
  )
}

export default enhance(withTheme(Vienna))
