import Api from '@app/common/api'
import { AUTH_TYPES } from '@app/redux-types'
import { getCookie } from '@app/common/utilities'
import { SESSION_PERSISTANCE_KEY } from '@app/common/services/tmdsAuthService/constants'

import TMDSContextService from '@app/common/tmdsContextService'

const api = new Api()

export const login = (email, password, beforeStoreLogin) => dispatch =>
  dispatch({
    type: AUTH_TYPES.FETCH_LOGIN,
    payload: api.post('/userprefs/legacyLogin', {
      body: {
        username: email,
        password: password,
        applicationId: TMDSContextService.getApplicationId(),
      },
    }),
    meta: { errors: [500], beforeStoreLogin },
  })

export const register = (email, password, recaptchaResponseField) => dispatch =>
  dispatch({
    type: AUTH_TYPES.FETCH_REGISTER,
    payload: api.post('/register', {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `email=${encodeURI(email)}&password=${encodeURI(
        password
      )}&recaptcha_response_field=${recaptchaResponseField}`,
    }),
    meta: { errors: [500] },
  })

export const logout = () => ({
  type: AUTH_TYPES.FETCH_LOGOUT,
  payload: api.get('/users/logout'),
})

export const checkLoggedUser = () => ({
  type: AUTH_TYPES.CHECK_LOGGED_USER,
  payload: api.get('/users/details'),
  meta: { errors: [500] },
})

export const changePassword = (email, oldPassword, password) => ({
  type: AUTH_TYPES.CHANGE_PASSWORD,
  payload: api.post('/changePassword?enableChangePassword=true', {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `email=${encodeURI(
      email
    )}&applicationId=${TMDSContextService.getApplicationId()}&oldpassword=${oldPassword}&password=${password}`,
  }),
  meta: { errors: [500] },
})

export const remindPassword = email => ({
  type: AUTH_TYPES.FETCH_REMIND_PASSWORD,
  payload: api.post('/remindPassword?reminder=true', {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `email=${encodeURI(email)}&applicationId=${TMDSContextService.getApplicationId()}`,
  }),
  meta: { errors: [500] },
})
