import { t } from '@app/common/translationsService'
import { Icon } from '@new-lucentum'
import React, { useState } from 'react'

import { BackToTopButtonStyled } from './styles'

const BackToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false)
  const [buttonOffset, setButtonOffset] = useState({
    right: 30,
  })

  const scrollTop = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  React.useEffect(
    () => {
      const checkScrollTop = () => {
        const nextShowScroll = window.pageYOffset > 400
        if (nextShowScroll !== showScroll) {
          setShowScroll(nextShowScroll)
        }
      }

      window.addEventListener('scroll', checkScrollTop)
      const rightOffset = window.innerWidth > 1280 ? (window.innerWidth - 1280) / 2 : 0
      setButtonOffset({ right: rightOffset + 30 })
      return () => {
        window.removeEventListener('scroll', checkScrollTop)
      }
    },
    [showScroll]
  )

  return (
    <BackToTopButtonStyled
      onClick={scrollTop}
      style={{ display: showScroll ? 'flex' : 'none', right: 30 }}
    >
      <Icon chevronDownSmall />
      {t('back.to.top.button')}
    </BackToTopButtonStyled>
  )
}

export default BackToTopButton
