import { TMDSGlobalsService } from '@app/common/services/tmdsGlobalsService'
import { TMDSSeoService } from '@app/common/services/tmdsSeoService'
import { TMDSTrackingService } from '@app/common/services/tmdsTrackingService'
import { GLOBALS_TYPES } from '@app/redux-types/globals'

const defaultState = {
  data: {},
  fetching: false,
  fetched: false,
  error: null,
}

const getCountries = ({ territories, map }) => {
  if (map) {
    return map.countries.reduce(
      (arr, elem) => ({ ...arr, [elem.code]: { code: elem.code, name: elem.name } }),
      {}
    )
  } else {
    return territories.reduce(
      (arr, elem) => ({ ...arr, [elem.id]: { code: elem.id, name: elem.description } }),
      {}
    )
  }
}

const enchanceOfficesBuilder = globals => {
  const {
    selects: { offices, territories },
    map,
  } = globals
  const countriesList = getCountries({ territories, map })
  offices.map(item => {
    const descShort = item.text.split(' ')[1]
    item.text = countriesList[item.id] ? `${countriesList[item.id].name}-${descShort}` : item.text
    return item
  })
  return offices
}

const enchanceGlobals = globals => ({
  ...globals,
  selects: { ...globals.selects, offices: enchanceOfficesBuilder(globals) },
})

const getOfficeName = (offices, payload) => {
  return offices && Array.isArray(payload)
    ? offices.map(office => {
        const applicationOfficeObject =
          office.id &&
          payload.find(
            applicationOffice =>
              applicationOffice.office &&
              applicationOffice.office.officeCode &&
              applicationOffice.office.officeCode === office.id
          )
        return applicationOfficeObject &&
          applicationOfficeObject.office &&
          applicationOfficeObject.office.name
          ? { ...office, description: applicationOfficeObject.office.name }
          : office
      })
    : offices
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_GLOBALS_PENDING':
      return { ...state, fetching: true, fetched: false, error: null }
    case 'FETCH_GLOBALS_REJECTED':
      return { ...state, fetching: false, fetched: false, error: action.payload }
    case 'FETCH_GLOBALS_FULFILLED':
      const { regionalConfig } = action.payload

      const globalPreprocessed = enchanceGlobals(action.payload)
      TMDSGlobalsService.set(globalPreprocessed)
      if (regionalConfig && regionalConfig.regionalEnabled) {
        TMDSSeoService.set({
          title: 'CAN TMView',
        })
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: globalPreprocessed,
      }
    case 'FETCH_APPLICATION_OFFICES_FULFILLED':
      return state.data && state.data.selects && state.data.selects.offices
        ? {
            ...state,
            data: {
              ...state.data,
              selects: {
                ...state.data.selects,
                offices: getOfficeName(state.data.selects.offices, action.payload),
              },
            },
          }
        : state
    case GLOBALS_TYPES.SET_COOKIE_BANNER_CONTENT:
      return {
        ...state,
        data: {
          ...state.data,
          cookiesBanner: action.payload,
        },
      }
    default:
      return state
  }
}
