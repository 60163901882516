export const FIELDS_TMVIEW = {
  STATUS_CHANGE: 'statusChange',
  APPLICANT_NAME_CHANGE: 'applicantNameChange',
  OPPOSITION_PERIOD_CLOSE: 'oppPeriodClose',
  OPPOSITION_PERIOD_DAYS: 'oppPeriodDays',
  EXPIRATION_DATE_CLOSE: 'expDateClose',
  EXPIRATION_DATE_DAYS: 'expDateDays',
  GOODS_AND_SERVICES_CHANGE: 'goodsAndServicesChange',
  EXPIRY_DATE_CHANGE: 'expiryDateChange',
  REPRESENTATIVE_CHANGE: 'representativeChange',
}

export const FIELDS_DSVIEW = {
  STATUS_CHANGE: 'statusChange',
  OWNER_NAME_CHANGE: 'ownerNameChange',
  EXPIRATION_DATE_CLOSE: 'expDateClose',
  EXPIRATION_DATE_DAYS: 'expDateDays',
  END_OF_DEFERMENT_DATE_CLOSE: 'endDeferDateClose',
  END_OF_DEFERMENT_DATE_DAYS: 'endDeferDateCloseDays',
  LOCARNO_CLASS_CHANGE: 'locarnoClassChange',
  EXPIRY_DATE_CHANGE: 'expiryDateChange',
  REPRESENTATIVE_CHANGE: 'representativeChange',
  END_OF_DEFERMENT_DATE_CHANGE: 'endDeferDateChange',
}

export const INITIAL_VALUES_TMVIEW = {
  [FIELDS_TMVIEW.STATUS_CHANGE]: false,
  [FIELDS_TMVIEW.APPLICANT_NAME_CHANGE]: false,
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_CLOSE]: false,
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_DAYS]: '',
  [FIELDS_TMVIEW.EXPIRATION_DATE_CLOSE]: false,
  [FIELDS_TMVIEW.EXPIRATION_DATE_DAYS]: '',
  [FIELDS_TMVIEW.GOODS_AND_SERVICES_CHANGE]: false,
  [FIELDS_TMVIEW.EXPIRY_DATE_CHANGE]: false,
  [FIELDS_TMVIEW.REPRESENTATIVE_CHANGE]: false,
}

export const INITIAL_VALUES_DSVIEW = {
  [FIELDS_DSVIEW.STATUS_CHANGE]: false,
  [FIELDS_DSVIEW.OWNER_NAME_CHANGE]: false,
  [FIELDS_DSVIEW.EXPIRATION_DATE_CLOSE]: false,
  [FIELDS_DSVIEW.EXPIRATION_DATE_DAYS]: '',
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CLOSE]: false,
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_DAYS]: '',
  [FIELDS_DSVIEW.LOCARNO_CLASS_CHANGE]: false,
  [FIELDS_DSVIEW.EXPIRY_DATE_CHANGE]: false,
  [FIELDS_DSVIEW.REPRESENTATIVE_CHANGE]: false,
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CHANGE]: false,
}
