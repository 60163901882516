import { generalDataManager } from '@app/common/dataManagers'
import TMDSContextService from '@app/common/tmdsContextService'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t } from '@app/common/translationsService'
import { localStorageUtils } from '@app/common/utils'
import { MediaPlayer } from '@app/components/MediaViewer/MediaPlayer'
import { Button, Flex, Grid, Icon, Link, Modal, TooltipTrigger } from '@new-lucentum'
import cn from 'classnames'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { AppTourSidebar } from './AppTourSidebar'

const TOUR_VERSION_KEY = 'tourVersion'
const TOUR_FINISHED_KEY = 'tourFinished'
const getLocalstorageKey = key => {
  const prefix = TMDSContextService.isTmview() ? 'TMVIEW' : 'DSVIEW'
  return `${prefix}_${key}`
}

const ButtonStyled = styled(Button)`
  font-size: 16px;
`
const StyledLink = styled(Link)`
  text-decoration: underline;
`
const Styled = styled.div`
  height: 100%;
  .tour-container {
    overflow: hidden;
    height: 100%;
  }
  .slide-container {
    padding: 20px 30px 20px 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .slide-nav {
      height: 110px;
    }
  }

  .slide {
    display: flex;
    flex-direction: column;

    &-content {
      ${({ theme }) => theme.scroll(theme)};
      overflow-y: auto;
    }
  }
  .slide-title {
    color: ${({ theme }) => theme.color};
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    &.finished {
      font-weight: normal;
      font-size: 28px;
    }
  }
  .slide-text {
    color: ${({ theme }) => theme.colorGrayLight};
    font-size: 16px;
    text-align: left;
    min-height: 88px;
    &.finished {
      font-size: 13px;
    }
    &.slide-center {
      text-align: center;
      font-size: 26px;
      line-height: 142.8%;
      font-style: normal;
    }
  }
  .slide-multimedia {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 275px;
    max-width: 720px;

    img {
      border: 1px solid ${({ theme }) => theme.colorGrayAccent};
      max-width: 360px;
    }

    .slide-video-container {
      width: 100%;
    }
  }

  .slide-tabs {
    margin: auto;
    max-width: 400px;

    .slide-tab-tooltip {
      &:first-child {
        .slide-tab {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
      &:last-child {
        .slide-tab {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
  .slide-tab-tooltip {
    flex: 1;
  }
  .slide-tab {
    flex: 1;
    height: 6px;
    background-color: ${({ theme }) => theme.colorGrayLighter};
    cursor: pointer;
    &.selected {
      background-color: ${({ theme }) => theme.color};
    }
  }

  .active-slide-tab-tooltip .__react_component_tooltip {
    background-color: #fff;
  }

  .tour-navigation-buttons {
    .button-icon {
      font-size: inherit;
      color: ${({ theme }) => theme.colorWhite};
    }
  }

  .finished-icon {
    font-size: 250px;
    color: ${({ theme }) => theme.color};
  }
`

const StyledModal = styled(Modal)`
  .close-modal-wrapper {
    position: absolute;
    padding: 20px;
    margin: 0;
  }
`

const prepareMultimediaUrl = (url, version) => {
  return `${url}?version=${version}`
}

const FinishedSlide = () => {
  return (
    <Grid.Spacing px={2} py={2}>
      <div className="slide">
        <div className="slide-title finished">{t('tour_congratulations')}</div>
        <div className="slide-title finished">{t('tour_reached_end')}</div>
        <Grid.Spacing mb={2} />
        <div className="slide-text">{t('tour_you_can_revisit')}</div>
        <Grid.Spacing mb={1} />
        <Flex justify="center">
          <Icon className="finished-icon" walkthrough />
        </Flex>
      </div>
    </Grid.Spacing>
  )
}

const AppTourComponent = props => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.uiState.tour.isOpen)
  const [version, setVersion] = React.useState(0)
  const [groups, setGroups] = React.useState([])
  const [selectedTourGroupIndex, setSelectedTourGroupIndex] = React.useState(0)
  const [slides, setSlides] = React.useState([])
  const [selectedSlideIndex, setSelectedSlideIndex] = React.useState(0)
  const [isFinished, setIsFinished] = React.useState(false)
  const [navButtonWidth, setNavButtonWidth] = React.useState(0)
  const selectedSlide = slides[selectedSlideIndex]

  const nextButtonRef = React.createRef()
  const previousButtonRef = React.createRef()

  React.useEffect(() => {
    generalDataManager.getTour().then(tour => {
      if (tour && tour.tourGroups) {
        const { version, tourGroups } = tour
        const storedVersion = localStorageUtils.get(getLocalstorageKey(TOUR_VERSION_KEY))
        const isTourFinished = localStorageUtils.get(getLocalstorageKey(TOUR_FINISHED_KEY))

        setVersion(version)
        setGroups(tourGroups)
        setSlides(tourGroups[0].tourTopics)
        if (!storedVersion || storedVersion < version || !isTourFinished) {
          localStorageUtils.set(getLocalstorageKey(TOUR_VERSION_KEY), version)
          dispatch({ type: 'TOUR_TOGGLE', payload: { isOpen: true } })
        } else {
          const { queryParamsObject } = TMDSRouterService.getQueryParams()
          if (queryParamsObject.openAppTour) {
            dispatch({ type: 'TOUR_TOGGLE', payload: { isOpen: true } })
          }
        }
      }
    })
  }, [])

  React.useLayoutEffect(
    () => {
      setTimeout(() => {
        if (nextButtonRef.current && previousButtonRef.current) {
          const next = nextButtonRef.current.clientWidth
          const previous = previousButtonRef.current.clientWidth
          setNavButtonWidth(Math.max(next, previous))
        }
      }, 0)
    },
    [isOpen]
  )

  const handleNavigation = operation => {
    const targetSlideIndex = selectedSlideIndex + operation
    setSelectedSlideIndex(targetSlideIndex)
  }

  const closeTour = () => {
    dispatch({ type: 'TOUR_TOGGLE_TOOLTIP', payload: true })
    dispatch({ type: 'TOUR_TOGGLE', payload: { isOpen: false } })
    setTimeout(() => {
      dispatch({ type: 'TOUR_TOGGLE_TOOLTIP', payload: false })
    }, 3000)

    localStorageUtils.set(getLocalstorageKey(TOUR_FINISHED_KEY), true)
    setSelectedSlideIndex(0)
    setSelectedTourGroupIndex(0)
    setSlides(groups[0].tourTopics)
    setIsFinished(false)
  }

  const moveToFinished = React.useCallback(
    () => {
      setIsFinished(true)
    },
    [setIsFinished]
  )

  const handleGroupNavigation = index => {
    setSlides(groups[index].tourTopics)
    setSelectedSlideIndex(0)
    setSelectedTourGroupIndex(index)
  }
  const isWelcomePage = selectedTourGroupIndex === 0
  const isLastPage = selectedSlideIndex === slides.length - 1
  const isLastGroup = selectedTourGroupIndex === groups.length - 1

  return (
    <StyledModal
      noTitle
      skipButton
      contentStyle={{
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        minWidth: 1000,
        maxWidth: 1024,
        height: 650,
      }}
      reactModalContentStyle={{
        width: '95%',
        height: 'auto',
        maxHeight: '95%',
        maxWidth: '1024px',
        margin: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      divWrapperStyle={{ height: '100%' }}
      isOpen={isOpen}
      onRequestClose={() => {
        closeTour()
      }}
      shouldCloseOnOverlayClick={false}
    >
      <Styled>
        {isFinished ? (
          <FinishedSlide />
        ) : (
          <Flex className="tour-container">
            <AppTourSidebar
              sections={groups}
              onSectionClick={handleGroupNavigation}
              selectedSectionIndex={selectedTourGroupIndex}
            />
            {slides.length > 0 && (
              <div className="slide-container">
                <div className="slide">
                  <div className="slide-title">{t(selectedSlide.title)}</div>
                  <Grid.Spacing mb={isWelcomePage ? 1 : 2} />
                  <div className="slide-content">
                    {!isWelcomePage && <div className="slide-text">{t(selectedSlide.text)}</div>}
                    <Grid.Spacing mb={1} />
                    {isWelcomePage ? (
                      <Fragment>
                        <div className="slide-multimedia">
                          {selectedSlide.multimedia && selectedSlide.multimedia.type === 'IMAGE' ? (
                            <img
                              src={prepareMultimediaUrl(selectedSlide.multimedia.url, version)}
                              alt={t(selectedSlide.title)}
                            />
                          ) : (
                            <div className="slide-video-container">
                              <MediaPlayer
                                src={prepareMultimediaUrl(selectedSlide.multimedia.url, version)}
                                autoPlay
                              />
                            </div>
                          )}
                        </div>
                        <div className="slide-text slide-center">{t(selectedSlide.text)}</div>
                        <Flex justify="center" alignItems="center" padding="20px 0 20px 0">
                          <Flex justify="flex-end">
                            <Button
                              style={{ padding: '10px 25px 10px 25px' }}
                              onClick={() => {
                                closeTour()
                              }}
                            >
                              {t('walkthrough.welcome.notnow')}
                            </Button>
                            <Grid.Spacing mr={2} />
                            <ButtonStyled
                              iconAfter="chevronRight"
                              primary
                              onClick={() => {
                                handleGroupNavigation(1)
                              }}
                              style={{ width: '200px' }}
                            >
                              {t('start_tour')}
                            </ButtonStyled>
                          </Flex>
                        </Flex>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="slide-multimedia">
                          {selectedSlide.multimedia && selectedSlide.multimedia.type === 'IMAGE' ? (
                            <img
                              src={prepareMultimediaUrl(selectedSlide.multimedia.url, version)}
                              alt={t(selectedSlide.title)}
                            />
                          ) : (
                            <div className="slide-video-container">
                              <MediaPlayer
                                src={prepareMultimediaUrl(selectedSlide.multimedia.url, version)}
                                autoPlay
                              />
                            </div>
                          )}
                        </div>
                        <div className="slide-nav">
                          <Grid.Spacing mb={1} />
                          <Grid.Group className="slide-tabs">
                            {slides.map((slide, index) => {
                              return (
                                <TooltipTrigger
                                  tooltipId={`slide-tab-tooltip-${index}`}
                                  className={`slide-tab-tooltip ${
                                    selectedSlideIndex === index ? 'active-slide-tab-tooltip' : ''
                                  }`}
                                  key={index}
                                  renderTooltip={<div>{t(slide.title)}</div>}
                                >
                                  <div
                                    className={cn(
                                      'slide-tab',
                                      index === selectedSlideIndex && 'selected'
                                    )}
                                    onClick={() => setSelectedSlideIndex(index)}
                                  />
                                </TooltipTrigger>
                              )
                            })}
                          </Grid.Group>
                          <Flex justify="flex-end" alignItems="center" padding="20px 0 20px 0">
                            <Flex justify="flex-end" padding="0 20px 0 0" margin="0 20px 0 0">
                              <StyledLink
                                onClick={() => {
                                  closeTour()
                                }}
                              >
                                {t('tour_skip')}
                              </StyledLink>
                            </Flex>
                            <Flex justify="flex-end" className="tour-navigation-buttons">
                              <div ref={previousButtonRef}>
                                <Button
                                  icon="chevronLeft"
                                  disabled={selectedSlideIndex === 0}
                                  onClick={() => handleNavigation(-1)}
                                  {...navButtonWidth > 0 && { style: { width: navButtonWidth } }}
                                >
                                  {t('button_previous')}
                                </Button>
                              </div>
                              <Grid.Spacing ml={2} />
                              <div ref={nextButtonRef}>
                                <ButtonStyled
                                  iconAfter="chevronRight"
                                  primary
                                  disabled={isLastPage && !isLastGroup}
                                  onClick={() =>
                                    isLastPage && isLastGroup
                                      ? moveToFinished()
                                      : handleNavigation(1)
                                  }
                                  {...navButtonWidth > 0 && { style: { width: navButtonWidth } }}
                                >
                                  {t('button_next')}
                                </ButtonStyled>
                              </div>
                            </Flex>
                          </Flex>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Flex>
        )}
      </Styled>
    </StyledModal>
  )
}

export const AppTour = AppTourComponent
