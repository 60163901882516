import { searchDataManager } from '@app/common/dataManagers'
import { TMDSSpinnerService, TMDSTrackingService } from '@app/common/services'
import { isTmview } from '@app/common/tmdsContextService'
import { getHeaderFileName } from '@app/common/utilities'
import fileDownload from 'react-file-download'

export const handleExport = (
  documentType,
  resource,
  selectedItems,
  recaptchaResponseKey,
  targetLanguage = '',
  query = '',
  queryString = '',
  baseUrl = window.location.href
) => dispatch => {
  const exportType = () => {
    if (window.location.href.includes('results')) {
      return 'RESULTS'
    } else if (window.location.href.includes('detail')) {
      return 'DETAILS'
    } else if (window.location.href.includes('compare')) {
      return 'COMPARISON'
    }
  }

  TMDSTrackingService.trackExport(resource, documentType)
  const requestBody = isTmview()
    ? {
        selectedTrademarks: selectedItems,
        recaptchaResponseKey,
        targetLanguage,
        query,
        queryString,
        baseUrl,
        type: exportType(),
      }
    : {
        selectedDesigns: selectedItems,
        recaptchaResponseKey,
        targetLanguage,
        type: exportType(),
      }
  const exportPromise = dispatch({
    type: `EXPORT_DETAIL_${resource.toUpperCase()}`,
    payload: searchDataManager.doExportResource(resource, documentType, requestBody),
  })

  TMDSSpinnerService.showSpinner(exportPromise)
  exportPromise.then(({ value: { headers, parsedBody } }) => {
    const fileName = getHeaderFileName(headers)
    fileDownload(parsedBody, fileName ? fileName : `${resource}.${documentType}`)
  })
}

export const handleExportHistory = (
  id,
  resource,
  documentType,
  recaptchaResponseKey,
  historyId,
  targetHistoryId,
  pageSize = 150
) => dispatch => {
  TMDSTrackingService.trackExport(resource, documentType)

  const exportPromise = dispatch({
    type: `EXPORT_HISTORY_${resource.toUpperCase()}`,
    payload: searchDataManager.doExportHistory({
      id,
      resource,
      documentType,
      recaptchaResponseKey,
      historyId,
      targetHistoryId,
      pageSize,
    }),
  })

  TMDSSpinnerService.showSpinner(exportPromise)
  exportPromise.then(({ value: { headers, parsedBody } }) => {
    const fileName = getHeaderFileName(headers)
    fileDownload(
      parsedBody,
      fileName ? `${fileName}.${documentType}` : `${resource}.${documentType}`
    )
  })
}
