import TMDSContextService from '@app/common/tmdsContextService'
import { FORM_IS_TOUCHED, MOBILE_FILTERS, RESULTS_TYPES, TRANSLATION_TYPES } from '@app/redux-types'

const defaultState = {
  data: {
    availableFilters: [],
    initialAvailableFilters: [],
    designs: [],
    filters: {},
    page: 1,
    shouldOpenWipoUrl: false,
    totalPages: 1,
    totalResults: 0,
    initialTotalResults: 0,
    tradeMarks: [],
    resultsSettings: {},
    wipoUrl: '',
    mobileFiltersSelected: null,
    isTouched: false,
  },
  ST13gsTranslated: {},
  ST13LocarnoTranslated: {},
}

const getTradeMarksWithExtraData = (detail, tradeMarks, meta) =>
  tradeMarks.map(tm => {
    if (tm.ST13 === meta) {
      const {
        tradeMark,
        goodsAndServicesList,
        officeUrl,
        officeLogoUrl,
        officeLastUpdateDate,
        officeNumberOfTradeMark,
        representatives = [],
        error = false,
        fetching = false,
      } = detail
      const [firstElement] = goodsAndServicesList || []
      tm.expirationDate = tradeMark ? tradeMark.expiryDate : tm.expirationDate
      tm.goodAndServices = firstElement ? firstElement.goodAndServices : []
      tm.goodsAndServicesList =
        tm.goodAndServices &&
        tm.goodAndServices.goodAndServiceList &&
        Array.isArray(tm.goodAndServices.goodAndServiceList) &&
        tm.goodAndServices.goodAndServiceList.map(gs => ({
          ...gs,
          language: tm.goodAndServices.language,
        }))
      tm.officeUrl = officeUrl
      tm.representatives = representatives
      tm.error = error
      tm.fetching = fetching
      tm.office = {
        logo: officeLogoUrl,
        name: tradeMark ? tradeMark.tmOffice : '',
        trademarksCount: officeNumberOfTradeMark,
        lastUpdate: officeLastUpdateDate,
      }
    }
    return tm
  })

const getDesignsWithExtraData = (detail, details, id) => {
  return details.map(ds => {
    if (ds.ST13 === id) {
      const { domesticClasses = [] } = detail
      const extraData = { domesticClasses }
      return { ...ds, ...extraData }
    } else {
      return ds
    }
  })
}

const getTranslatedGoodsAndServices = (ST13gsTranslated, tradeMarks, ST13, targetLanguage) =>
  tradeMarks.map(tm => {
    if (tm.ST13 === ST13) {
      ST13gsTranslated[ST13].data.forEach(translatedGS => {
        const index = tm.goodsAndServicesList.findIndex(
          gs => parseInt(gs.niceClass) === parseInt(translatedGS.niceClass)
        )
        if (index > -1) {
          if (!translatedGS.error) {
            tm.goodsAndServicesList[index].goodsAndServices = translatedGS.goodsAndServices
            tm.goodsAndServicesList[index].language = targetLanguage
          } else {
            tm.goodsAndServicesList[index].error = true
          }
        }
      })
    }
    return tm
  })

const translatedGoodsAndServicesState = (state, ST13, payload, targetLanguage) => {
  const { gsTranslateFetching, gsTranslateFetched, gsTranslateError, ...dataNiceClass } = payload
  let translatedTerm = []
  if (state.ST13gsTranslated[ST13].data) {
    translatedTerm = [...state.ST13gsTranslated[ST13].data]
  }
  let ST13gsTranslated = {
    ...state.ST13gsTranslated,
    [ST13]: {
      gsTranslateFetching,
      gsTranslateFetched,
      gsTranslateError,
      data: [...translatedTerm, dataNiceClass],
    },
  }
  return {
    ...state,
    ST13gsTranslated,
    data: {
      ...state.data,
      tradeMarks: getTranslatedGoodsAndServices(
        ST13gsTranslated,
        state.data.tradeMarks,
        ST13,
        targetLanguage
      ),
    },
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESULTS_TYPES.CLEAR_RESULTS:
      return defaultState
    case RESULTS_TYPES.FETCH_SEARCH_REJECTED:
      return defaultState
    case RESULTS_TYPES.FETCH_SEARCH_FULFILLED:
      const { lastAppliedFilter } = action.meta
      const {
        availableFilters,
        designResults: designs = null,
        totalResults,
        ...restOfPayload
      } = action.payload
      /*  const fieldsFiltersMap = getFieldsFiltersMapByContext()
      const shouldUpdateInitialAvailableFilters =
        !lastAppliedFilter ||
        (lastAppliedFilter &&
          lastAppliedFilter.action === FILTER_ACTIONS.REMOVE &&
          totalResults > state.data.initialTotalResults)

      const initialAvailableFilters = shouldUpdateInitialAvailableFilters
        ? availableFilters
        : availableFilters.map(availableFilter => {
            let targetAvailableFilter = availableFilter
            if (fieldsFiltersMap[availableFilter.labelKey] === lastAppliedFilter.id) {
              targetAvailableFilter = state.data.initialAvailableFilters.find(
                initialAvailableFilter =>
                  initialAvailableFilter.labelKey === availableFilter.labelKey
              )
            }
            return targetAvailableFilter
          }) */

      const initialTotalResults = totalResults
      return {
        ...state,
        data: {
          ...state.data,
          filters: state.data.filters,
          availableFilters,
          /*  initialAvailableFilters: initialAvailableFilters, */
          initialTotalResults,
          totalResults,
          designs,
          ...restOfPayload,
        },
      }
    case RESULTS_TYPES.FETCH_DETAIL_EXPANDED_PENDING:
      return {
        ...state,
        data: {
          ...state.data,
          resultsSettings: {
            ...state.data.resultsSettings,
            [action.meta.id]: { ...state.data.resultsSettings[action.meta.id], fetching: true },
          },
        },
      }
    case RESULTS_TYPES.FETCH_DETAIL_EXPANDED_FULFILLED:
      return {
        ...state,
        data: {
          ...state.data,
          tradeMarks: TMDSContextService.isTmview()
            ? getTradeMarksWithExtraData(
                { ...action.payload },
                state.data.tradeMarks,
                action.meta.id
              )
            : null,
          designs: TMDSContextService.isDsview()
            ? getDesignsWithExtraData({ ...action.payload }, state.data.designs, action.meta.id)
            : null,
          resultsSettings: {
            ...state.data.resultsSettings,
            [action.meta.id]: { ...state.data.resultsSettings[action.meta.id], fetching: false },
          },
        },
      }
    case RESULTS_TYPES.FETCH_DETAIL_EXPANDED_REJECTED:
      return {
        ...state,
        data: {
          ...state.data,
          tradeMarks: TMDSContextService.isTmview()
            ? getTradeMarksWithExtraData({ error: true }, state.data.tradeMarks, action.meta.id)
            : null,
          designs: TMDSContextService.isDsview()
            ? getDesignsWithExtraData({ error: true }, state.data.designs, action.meta.id)
            : null,
          resultsSettings: {
            ...state.data.resultsSettings,
            [action.meta.id]: { ...state.data.resultsSettings[action.meta.id], fetching: false },
          },
        },
      }
    case RESULTS_TYPES.FETCH_DETAILS_LIST:
      return {
        ...state,
        data: {
          ...state.data,
          resultsSettings:
            (state.data.tradeMarks &&
              Object.fromEntries(state.data.tradeMarks.map(tm => [tm.ST13, { fetching: true }]))) ||
            {},
        },
      }
    case 'FETCH_WIPO_SEARCH_FULFILLED':
      return {
        ...state,
        wipoUrl: action.payload.wipoUrl,
        shouldOpenWipoUrl: true,
      }
    case 'CHANGE_OPEN_WIPO':
      return {
        ...state,
        wipoUrl: '',
        shouldOpenWipoUrl: false,
      }
    case 'RESET_GS_TRANSLATIONS':
      state.ST13gsTranslated[action.payload.ST13] = {}
      let restoredTradeMarks = action.payload.restore
        ? state.data.tradeMarks.map(tm => {
            if (tm.ST13 === action.payload.ST13) {
              tm.goodsAndServicesList = tm.goodsAndServicesList.map(gsList => {
                gsList.language = tm.goodAndServices.language
                gsList.goodsAndServices = gsList.goodsAndServices.map(term => ({ term: term.term }))
                return gsList
              })
            }
            return tm
          })
        : [...state.data.tradeMarks]
      return {
        ...state,
        data: {
          ...state.data,
          tradeMarks: restoredTradeMarks,
        },
      }
    case TRANSLATION_TYPES.FETCH_GS_TRANSLATIONS_PENDING:
      const translatedStateFullPending = state.ST13gsTranslated[action.meta.ST13]
      return translatedGoodsAndServicesState(state, action.meta.ST13, {
        niceClass: action.meta.niceClass,
        goodsAndServices: [],
        error: false,
        gsTranslateFetching: [
          ...(translatedStateFullPending.gsTranslateFetching || []),
          action.meta.niceClass,
        ],
        gsTranslateFetched:
          (translatedStateFullPending.gsTranslateFetched &&
            translatedStateFullPending.gsTranslateFetched.filter(
              gs => gs !== action.meta.niceClass
            )) ||
          [],
        gsTranslateError:
          (translatedStateFullPending.gsTranslateError &&
            translatedStateFullPending.gsTranslateError.filter(
              gs => gs !== action.meta.niceClass
            )) ||
          [],
      })
    case TRANSLATION_TYPES.FETCH_GS_TRANSLATIONS_FULFILLED:
      const translatedStateFullFilled = state.ST13gsTranslated[action.meta.ST13]
      return translatedGoodsAndServicesState(
        state,
        action.meta.ST13,
        {
          ...action.payload,
          error: false,
          gsTranslateFetching: translatedStateFullFilled.gsTranslateFetching.filter(
            gs => gs !== action.meta.niceClass
          ),
          gsTranslateFetched: [
            ...(translatedStateFullFilled.gsTranslateFetched || []),
            action.meta.niceClass,
          ],
          gsTranslateError: translatedStateFullFilled.gsTranslateError.filter(
            gs => gs !== action.meta.niceClass
          ),
        },
        action.meta.targetLanguage
      )
    case TRANSLATION_TYPES.FETCH_GS_TRANSLATIONS_REJECTED:
      const translatedStateFullRejected = state.ST13gsTranslated[action.meta.ST13]
      return translatedGoodsAndServicesState(state, action.meta.ST13, {
        niceClass: action.meta.niceClass,
        goodsAndServices: [],
        error: true,
        gsTranslateFetching: translatedStateFullRejected.gsTranslateFetching.filter(
          gs => gs !== action.meta.niceClass
        ),
        gsTranslateFetched: translatedStateFullRejected.gsTranslateFetched.filter(
          gs => gs !== action.meta.niceClass
        ),
        gsTranslateError: [
          ...(translatedStateFullRejected.gsTranslateError || []),
          action.meta.niceClass,
        ],
      })
    case RESULTS_TYPES.FETCH_FILTER_FULFILLED:
      const filterInfo = action.payload.data
      return {
        ...state,
        data: {
          ...state.data,
          filters: {
            ...state.data.filters,
            [action.meta.filter]: {
              data: action.meta.preventUiUpdate
                ? state.data.filters[action.meta.filter].data
                : filterInfo || [],
              fetching: false,
            },
          },
          latestFilters: {
            ...state.data.latestFilters,
            [action.meta.filter]: filterInfo || [],
          },
        },
      }
    case RESULTS_TYPES.FETCH_FILTER_PENDING:
      return {
        ...state,
        data: {
          ...state.data,
          filters: {
            ...state.data.filters,
            [action.meta.filter]: {
              fetching: action.meta.preventUiUpdate ? false : true,
              data:
                (state.data.filters[action.meta.filter] &&
                  state.data.filters[action.meta.filter].data) ||
                [],
            },
          },
        },
      }
    case RESULTS_TYPES.FETCH_SPECIAL_FILTERS_FULFILLED:
      const specialFilters =
        action.payload &&
        action.payload.availableFilters.reduce((acc, filter) => {
          acc[filter.labelKey] = {
            fetching: false,
            data: filter.data,
          }
          return acc
        }, {})
      return {
        ...state,
        data: {
          ...state.data,
          filters: {
            ...state.data.filters,
            ...specialFilters,
          },
        },
      }
    case MOBILE_FILTERS:
      return {
        ...state,
        data: {
          ...state.data,
          mobileFiltersSelected: action.payload,
        },
      }
    case FORM_IS_TOUCHED:
      return {
        ...state,
        data: {
          ...state.data,
          isTouched: action.payload,
        },
      }
    default:
      return state
  }
}
