import { ViennaCodesLCT } from '@app/components/SharedStyles'
import { Field } from 'formik'
import React from 'react'

export default props => <Field {...props} component={ViennaCodes} />

const ViennaCodes = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const defaultProps = {
    error: errors[field.name],
    touched: touched[field.name],
    onChange: value => {
      setFieldValue(field.name, value)
    },
    onBlur: value => {
      setFieldTouched(field.name, true)
    },
  }
  const mergedProps = {
    ...defaultProps,
    ...props,
  }
  return <ViennaCodesLCT {...field} {...mergedProps} />
}
