import { combineReducers } from 'redux'

import fetch from './fetch'
import globals from './globals'
import literals from './literals'
import appState from './appState'
import uiState from './uiState'
import modal from './modal'
import applicationOffices from './applicationOffices'
import { notificationsReducer } from './notifications'
import { settingsReducer } from './settings'

export default combineReducers({
  fetch,
  globals,
  literals,
  appState,
  uiState,
  modal,
  applicationOffices,
  notifications: notificationsReducer,
  settings: settingsReducer,
})
