import React from 'react'
import { StyledAccordion } from './Accordion.styles'
import Icon from '../Icon'
import { Flex } from '../Flex'

const AccordionComponent = ({ summary, children, ...props }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const icon = isExpanded ? 'chevronUp' : 'chevronDown'
  return (
    <StyledAccordion>
      <Flex justify="space-between" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="accordion-summary">{summary}</div>
        <div className="accordion-toggler">
          <Icon {...{ [icon]: true }} />
        </div>
      </Flex>
      {isExpanded && <div className="accordion-body">{children}</div>}
    </StyledAccordion>
  )
}

export const Accordion = AccordionComponent
