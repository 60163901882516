import styled, { css } from 'styled-components'

const config = (size = '') => ({
  width: 'width' + size,
  hidden: 'hidden' + size,
  visible: 'visible' + size,
  right: 'right' + size,
  justify: 'justify' + size,
  middle: 'middle' + size,
  bottom: 'bottom' + size,
  column: 'column' + size,
  center: 'center' + size,
})

const marginAndPadding = [
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
]

const displayFlex = ['middle', 'bottom', 'right', 'justify', 'center', 'column']
const textAlign = ['textLeft', 'textCenter', 'textRight']

const justifyContent = responsive => props => {
  if (props.alignContents === 'right' || props[responsive.right]) {
    return 'justify-content: flex-end'
  } else if (props.alignContents === 'justify' || props[responsive.justify]) {
    return 'justify-content: space-between'
  } else if (props.alignContents === 'center' || props[responsive.center]) {
    return 'justify-content: center'
  }
}

const subProp = main => sub => obj => obj[main][sub]
const them = subProp('theme')
const matchWidth = x => x.match(/([a-zA-Z]+)/g)[0]
const blockSize = x => {
  const [dd, dv] = x.match(/(\d+)/g)
  return (dd * 100) / dv
}

const buildPropsCss = (props, key) => {
  const [name, adj] = key.split(/(?=[A-Z])/)
  return !!adj
    ? `${name}-${adj.toLowerCase()}:${props[key] * props.theme.sizeBase}px;`
    : `${name}:${props[key] * props.theme.sizeBase}px;`
}

const checkFlexDisplay = responsive => props =>
  props.alignContents || displayFlex.some(e => Object.keys(props).includes(responsive[e]))

const addMargin = props =>
  !props.lessWidth &&
  Object.keys(props).map(name => marginAndPadding.includes(name) && buildPropsCss(props, name))

const hiddenOrVisible = responsive => props => {
  if (!props.lessWidth && props[responsive.hidden]) {
    return 'display: none'
  }
  if (props[responsive.visible]) {
    return !props.wrapper && (props.alignContents || checkFlexDisplay(responsive)(props))
      ? 'display: flex'
      : 'display: block'
  }
  if (!props.wrapper && (props.alignContents || checkFlexDisplay(responsive)(props))) {
    return 'display: flex'
  }
}

const alignItems = middle => (middle ? 'center' : 'flex-end')

const chooseWidth = (find, width) => {
  if (find) {
    return `width: ${blockSize(find)}%`
  } else if (width === config().width) {
    return 'width:100%;'
  }
}

const addWidth = ({ width }) => props =>
  !props.lessWidth &&
  chooseWidth(
    Object.keys(props).find(key => key.includes(width) && width === matchWidth(key) && props[key]),
    width
  )

const addTextAlign = props =>
  props.wrapper ||
  (props.base &&
    Object.keys(props).map(x => textAlign.includes(x) && `text-align: ${x.split('text')[1]}`))

const addBackground = ({ highlight, lessWidth, theme }) =>
  !lessWidth && highlight && `background-color:${theme.colorGrayAccent}`

const responsive = size => props => css`
  ${hiddenOrVisible(config(size))(props)};
  ${addWidth(config(size))(props)};
  ${!props.wrapper && justifyContent(config(size))(props)};
  ${!props.wrapper && props[config(size).column] && 'flex-direction: column'};
  ${!props.wrapper &&
    (props[config(size).middle] || props[config(size).bottom]) &&
    `align-items:${alignItems(props[config(size).middle])}`};
`

export const BlockStyled = styled.div`
  ${addBackground};
  ${addMargin};
  ${addTextAlign};
  ${responsive()};
  box-sizing: border-box;
  float: left;
  position: relative;
  ${({ theme }) => theme.isRTL(theme) && `direction: rtl;`}

  @media (${them('breakpointXS')}) {
    ${responsive('XS')};
  }
  @media (${them('breakpointSM')}) {
    ${responsive('SM')};
  }
  @media (${them('breakpointMD')}) {
    ${responsive('MD')};
  }
  @media (${them('breakpointLG')}) {
    ${responsive('LG')};
  }
  @media (${them('breakpointXL')}) {
    ${responsive('XL')};
  }
  ${({ auto }) =>
    auto &&
    css`
      width: auto;
    `};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
  ${({ top }) =>
    top &&
    css`
      display: flex;
      align-items: flex-start;
    `};
  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${overflow};
    `};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};
`

export const BlockTitle = styled.div`
  align-items: center;
  border-bottom: ${({ small, theme }) => (small ? 0 : `${theme.line} solid ${theme.colorGray}`)};
  cursor: default;
  display: flex;
  height: ${({ theme }) => theme.sizeDouble};
  margin-bottom: ${({ theme }) => theme.size};
  padding: ${({ theme: { line, size } }) => `0 ${line} ${size} ${line}`};
  i {
    font-size: ${({ small, theme }) => (small ? theme.size : theme.fontSizeLarge)};
    ${({ collapsed }) => collapsed && 'transform: rotate(-90deg); transition: transform 500ms;'};
  }
  h1 {
    line-height: ${({ theme }) => theme.sizeLarge};
  }
  h2 {
    line-height: ${({ theme }) => theme.sizeLarge};
    margin: 0;
  }
`

export const BlockDown = styled(BlockStyled)`
  opacity: ${({ collapsed }) => +!collapsed};
  transition: max-height 700ms, opacity 200ms;
  width: 100%;
  ${({ collapsed }) => (collapsed ? 'max-height: 0; overflow:hidden;' : 'max-height: 9999999px;')};
  ${({ collapsed }) => collapsed && 'pointer-events: none'};
`
