import React from 'react'
import ContentLoader from 'react-content-loader'

export default ({ height, width }) => {
  const quantityRows = height / 20
  const quantityArray = new Array(Math.round(quantityRows)).fill()
  const splitWidth = width / 2
  let rowHeight = -10

  return (
    <ContentLoader
      height={height}
      width={625}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="3" y="0" rx="0" ry="0" width={splitWidth - 25} height={height} />
      {quantityArray.map(
        (x, i) =>
          (rowHeight = rowHeight + 30) && (
            <rect key={i} x="312" y={rowHeight} rx="0" ry="0" width={splitWidth} height="20" />
          )
      )}
    </ContentLoader>
  )
}
