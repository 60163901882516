import styled, { css } from 'styled-components'

export const FilterBulletList = styled.div`
  margin-top: 40px;
  width: 100%;
  .skeleton-filter-title {
    height: 18px;
  }
`

export const TableRowContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 99;

  ${({ outerTableLoader }) =>
    outerTableLoader
      ? css`
          position: relative;
          z-index: 0;
        `
      : css`
          position: absolute;
          top: 70px;
        `}

  .row {
    height: 90px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    > div {
      width: 100%;
      max-height: 100%;

      svg {
        max-height: 90px;
      }
    }
  }
  .solid-background {
    background-color: ${({ theme }) => theme.rowCebra};
  }
`

export const TableRowInlineContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 99;
  position: absolute;
  top: 66px;
  .row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    > div {
      width: 100%;
      max-height: 100%;

      svg {
        max-height: 90px;
      }
    }
  }
  .solid-background {
    background-color: ${({ theme }) => theme.rowCebra};
  }
`

export const Loader = styled.div`
  width: 310px;
`

export const TransposedColumnContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 4000;
  padding-top: ${({ theme }) => theme.size};
  position: relative;
`
export const BlockSearchInfo = styled.div`
  width: 400px;
  height: ${({ height }) => (height !== 40 ? `${height}px` : '33px')};
`

export const BulletListFilters = styled.div`
  margin-bottom: 30px;
`

export const BlockFilterCheckbox = styled.div`
  width: 210px;
  height: 18px;
`
