import styled from 'styled-components'

import Icon from '../Icon'

export const StyledArrow = styled(Icon)`
  font-size: 45px;
  color: ${({ theme }) => theme.colorGrayLight};
  font-weight: 900;
  cursor: pointer;
`
export const ImageSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`
