export default (state = [], { type, options, optionId, chevron, parsedLang }) => {
  const findIndexOption = optionId && state.viennaCodes.findIndex(e => e.code === optionId)
  switch (type) {
    case 'LOAD_INITIAL_VIENNA_SUCCESS':
      return {
        language: parsedLang,
        initialViennaCodes: [...options],
        viennaCodes: [...options],
      }
    case 'LOAD_VIENNA_SUCCESS':
      return {
        ...state,
        viennaCodes: [...state.initialViennaCodes],
      }
    case 'LOAD_VIENNA_CHILDREN_SUCCESS':
      return {
        ...state,
        viennaCodes: [
          ...state.viennaCodes.slice(0, findIndexOption),
          { ...state.viennaCodes[findIndexOption], chevron: true, childrenLoaded: true },
          ...options.map(opt => ({ ...opt, hide: false })),
          ...state.viennaCodes.slice(findIndexOption + 1),
        ],
      }
    case 'TOOGLE_VIENNA_CHILDREN_SUCCESS':
      return {
        ...state,
        viennaCodes: [
          ...state.viennaCodes.slice(0, findIndexOption),
          { ...state.viennaCodes[findIndexOption], chevron: chevron },
          ...state.viennaCodes.slice(findIndexOption + 1).map(opt =>
            opt.parent && opt.parent.includes(optionId)
              ? {
                  ...opt,
                  hide:
                    state.viennaCodes[findIndexOption].level === 1 && opt.level === 3
                      ? true
                      : !chevron,
                  ...(opt.children && { chevron: !chevron }),
                }
              : opt
          ),
        ],
      }
    case 'LOAD_SEARCH_VIENNA_SUCCESS':
      return {
        ...state,
        viennaCodes: [
          ...options.map(opt =>
            opt.children || opt.parent
              ? {
                  ...opt,
                  ...(opt.children && { chevron: true, childrenLoaded: true }),
                  ...(opt.parent && { hide: false }),
                }
              : opt
          ),
        ],
      }
    case 'LOAD_SEARCH_VIENNA_ERROR':
      return []
    default:
      return state
  }
}
