import styled from 'styled-components'
import { Block, Span } from '@new-lucentum'

export const StyledFileInput = styled(Block)`
  > input {
    width: 0.1px !important;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .labelAsInput {
    display: block;
    width: fit-content;
    text-align: center;
    cursor: pointer;
    color: ${({ theme }) => theme.color};
    border: ${({ theme }) => `${theme.line} solid ${theme.color}`};
    border-radius: ${({ theme }) => theme.radius};
    background-color: ${({ theme }) => theme.colorTransparent};
    padding: ${({ theme }) => `${theme.sizeSmall} ${theme.sizeDouble}`};
    margin: ${({ theme }) => `${theme.size} ${theme.size} ${theme.size} 0px`};
    :hover {
      background-color: ${({ theme }) => theme.colorLighter};
    }
    :focus {
      box-shadow: ${({ theme }) => `0 0 0 ${theme.line} ${theme.colorLight}`};
    }
    :active {
      color: ${({ theme }) => theme.colorWhite};
      background-color: ${({ theme }) => theme.color};
      border: ${({ theme }) => `${theme.line} solid ${theme.colorLight}`};
    }
    :disabled {
      box-shadow: unset;
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`

export const Error = styled(Span)`
  color: ${({ theme }) => theme.colorError};
  font-size: ${({ theme }) => theme.fontSizeSmaller};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

export const StyledAddImageButton = styled(Block)`
  > label.labelAsInput {
    box-sizing: border-box;
    width: 100%;

    font-weight: bold;
    border: none;
    padding: 10px 20px;
    background-color: #e8f6f9;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    & i {
      color: #2f509a;
      font-size: 23px;
      font-weight: 600;
    }
  }
`
