import { TMDSAuthService } from '@app/common/services'
import { ROUTES } from '../routes'

const canActivate = () => {
  return TMDSAuthService.isLogged
}

const getFallbackPath = history => {
  const { location } = history
  TMDSAuthService.redirectUrl = location.pathname
  return `${ROUTES.LOGIN}`
}

const getFallbackAction = () => {
  return TMDSAuthService.doLoginCas
}

export const AUTH_GUARD = {
  canActivate,
  getFallbackPath,
  getFallbackAction,
}
