import { t } from '@app/common/translationsService'
import moment from 'moment'
import React, { Component } from 'react'
import InputMask from 'react-input-mask'
import onClickOutside from 'react-onclickoutside'
import { shallowEqual } from 'recompose'

import Block from '../Block'
import TextField from '../TextField'
import Calendar from './Calendar'
import { CalendarErrorModal } from './CalendarErrorModal'
import PredefinedRanges from './PredefinedRanges'
import { CalendarWrapper, Container, Flex, Tooltip } from './styles'
import {
  getDatesDataFromKeyboardInput,
  getInputValueFromDatesData,
  getRangeFromPredefinedRangesSource,
  momentDatesDataToStringDatesData,
  orderRange,
  stringDatesToStringRange,
} from './utils/datePickerUtils'
import ranges from './utils/defaultRanges'
import parseInput from './utils/parseInput'

const CALENDAR_ID = 'Calendar'
const PREDEFINED_RANGES_ID = 'Predefined'

const defaultProps = {
  linkedCalendars: false,
  format: 'DD/MM/YYYY',
  calendars: 2,
  twoStepChange: false,
  fromLabel: 'From',
  periodLabel: 'Period of Time',
  toLabel: 'To',
  tooltipTop: false,
}

class DatePicker extends Component {
  constructor(props, context) {
    super(props, context)
    const { format, linkedCalendars } = props

    const startDate = !props.startDate ? '' : parseInput(props.startDate, format, 'startOf')
    const endDate = !props.endDate ? '' : parseInput(props.endDate, format, 'endOf')
    //Local state
    this.state = {
      inputValue: '',
      range: { startDate, endDate },
      errors: [],
      link: linkedCalendars && endDate,
      isHidden: true,
      isModalOpen: false,
    }
    this.datesSetFromCalendar = []
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  static defaultProps = defaultProps
  promisedSetState = newState => {
    return new Promise(resolve => {
      this.setState(newState, () => {
        resolve()
      })
    })
  }

  setIsHidden(value) {
    if (this.state.isHidden !== value) {
      const promise = this.promisedSetState({ isHidden: value })
      promise.then(() => {
        value ? this.props.disableOnClickOutside() : this.props.enableOnClickOutside()
      })
      return promise
    }
  }

  setErrors(value) {
    if (value.length) {
      this.setState({ isModalOpen: true })
    }
    let promise = null
    if (!shallowEqual(this.state.errors, value)) {
      promise = this.promisedSetState({ errors: value })
      promise.then(() => {
        const { onErrorsChange } = this.props
        onErrorsChange && onErrorsChange(this.state.errors)
      })
    }
    return promise
  }

  setInputValue(value) {
    return this.promisedSetState({ inputValue: value })
  }

  setRange(value) {
    return this.promisedSetState({ range: value })
  }

  componentDidMount() {
    const { onInit, onErrorsChange } = this.props
    onInit && onInit(this.state.range)
    setTimeout(() => {
      this.props.disableOnClickOutside()
    }, 0)
    onErrorsChange && onErrorsChange(this.state.errors)
    this.prepareInitialInputValue()
  }

  prepareInitialInputValue() {
    const { startDate, endDate, format } = this.props
    if (startDate || endDate) {
      const start = moment(startDate).format(format)
      const end = moment(endDate).format(format)
      this.setInputValue(stringDatesToStringRange(start, end))
    }
  }

  getRangeFromCalendarSource = momentDatePicked => {
    let dates = this.datesSetFromCalendar
    const range = { startDate: '', endDate: '' }
    range.endDate = momentDatePicked
    dates.length === 2 && dates.splice(0, dates.length)
    dates.push(momentDatePicked)
    range.startDate = dates[0]
    return orderRange(range)
  }

  getDatesDataFromSelect = (datePickerInput, inputSource) => {
    const datesData = { range: { startDate: '', endDate: '' }, errors: [] }
    switch (inputSource) {
      case CALENDAR_ID:
        datesData.range = this.getRangeFromCalendarSource(datePickerInput)
        break
      case PREDEFINED_RANGES_ID:
        datesData.range = getRangeFromPredefinedRangesSource(datePickerInput)
        this.datesSetFromCalendar = []
        break
      default:
    }
    return datesData
  }

  launchOnChange = async lastState => {
    const needUpdateParent =
      this.props.onChange &&
      (lastState.errors.length !== this.state.errors.length ||
        (lastState.errors.length &&
          this.state.errors.length &&
          lastState.errors[0] !== this.state.errors[0]) ||
        (!this.state.range.startDate && lastState.range.startDate) ||
        (!this.state.range.endDate && lastState.range.endDate) ||
        (this.state.range.startDate && !lastState.range.startDate) ||
        (this.state.range.endDate && !lastState.range.endDate) ||
        (this.state.range.startDate &&
          !this.state.range.startDate.isSame(lastState.range.startDate)) ||
        (this.state.range.endDate && !this.state.range.endDate.isSame(lastState.range.endDate)) ||
        this.state.errors[0] !== lastState.errors[0])

    needUpdateParent &&
      (await this.props.onChange({
        range: momentDatesDataToStringDatesData('YYYY-MM-DD')(this.state.range),
        errors: this.state.errors,
      }))
  }

  textFieldChangeHandler = e => {
    e.stopPropagation()
    const datesData = getDatesDataFromKeyboardInput(
      e.target.value,
      this.props.disableDaysAfterToday,
      this.props.selectableEndYear
    )
    this.setErrors(datesData.errors)
    this.setInputValue(datesData.inputValue)
    if (!datesData.errors.length) {
      this.updateStateUsingDatesData(datesData)
    }
  }

  updateStateUsingDatesData = datesData => {
    datesData.format = this.props.format
    // console.log(this.datesSetFromCalendar.length)
    // const isHidden = this.datesSetFromCalendar.length !== 1
    const lastState = { ...this.state }
    const parsedInputValue =
      datesData.inputValue || datesData.inputValue === ''
        ? datesData.inputValue
        : getInputValueFromDatesData(datesData)
    Promise.all([
      this.setInputValue(parsedInputValue),
      this.setRange(datesData.range),
      this.setErrors(datesData.errors),
      // this.setIsHidden(isHidden)
    ]).then(async () => {
      await this.launchOnChange(lastState)
      this.handleOnLeaving()
    })
  }

  handleSelect(date, source) {
    this.updateStateUsingDatesData(this.getDatesDataFromSelect(date, source))
  }

  handleLinkChange(direction) {
    const { link } = this.state

    this.setState({
      link: link.clone().add(direction, 'months'),
    })
  }

  handleOnLeaving() {
    this.props.onLeaving && this.props.onLeaving()
  }

  onClear(shouldHandleOnLeaving = true) {
    const clearedState = { inputValue: '', range: { startDate: '', endDate: '' }, errors: [] }
    Promise.all([
      this.setInputValue(clearedState.inputValue),
      this.setRange(clearedState.range),
      this.setErrors(clearedState.errors),
    ]).then(() => {
      this.props.onChange && this.props.onChange(clearedState)
      if (shouldHandleOnLeaving) {
        this.handleOnLeaving()
      }
    })
  }

  handleClickOutside = () => {
    if (!this.state.isHidden) {
      this.setIsHidden(true).then(() => {
        this.datesSetFromCalendar = []
        this.handleOnLeaving()
      })
    }
  }

  shouldComponentUpdate = (nextProps, nextState) =>
    nextProps.startDate !== this.props.startDate ||
    nextProps.endDate !== this.props.endDate ||
    nextState.inputValue !== this.state.inputValue ||
    nextState.isHidden !== this.state.isHidden ||
    nextProps.solidBackground !== this.props.solidBackground ||
    this.state.isModalOpen !== nextState.isModalOpen

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (!nextProps.startDate || !nextProps.endDate) {
      const hasChangedStartDate = this.props.startDate !== nextProps.startDate
      const hasChangedEndDate = this.props.endDate !== nextProps.endDate
      const isResetting = this.props.isResetting
      const shouldHandleOnLeaving = !isResetting
      if (hasChangedStartDate || hasChangedEndDate || isResetting) {
        this.onClear(shouldHandleOnLeaving)
      }
    }
  }

  setInputRef = e => {
    e.target.selectionStart = 0
    e.target.selectionEnd = 0
  }

  render() {
    const {
      format,
      linkedCalendars,
      calendars,
      firstDayOfWeek,
      minDate,
      maxDate,
      disableDaysBeforeToday,
      disableDaysAfterToday,
      shownDate,
      showMonthArrow,
      periodLabel,
      fromLabel,
      toLabel,
      tooltip,
      tooltipTop,
      calendarLabels,
      numYearsRange,
      selectableStartYear,
      selectableEndYear,
      onLeaving,
      onErrorsChange,
      uiState,
      ...restProps
    } = this.props
    const { range, link, isHidden, inputValue } = this.state
    // Parses range string dates to moment dates
    const renderingRange = { ...range }
    const parsedStartDate = parseInput(renderingRange.startDate, format, 'startOf')
    renderingRange.startDate = parsedStartDate.isValid()
      ? parsedStartDate
      : parseInput('', format, 'startOf')
    const parsedEndDate = parseInput(renderingRange.endDate, format, 'endOf')
    renderingRange.endDate = parsedEndDate.isValid()
      ? parsedEndDate
      : parseInput('', format, 'endOf')

    const calendarsCount = Number(calendars) - 1

    return (
      <>
        <Container {...restProps}>
          <InputMask
            mask="99/99/9999 - 99/99/9999"
            disabled={false}
            maskChar="_"
            value={inputValue}
            onChange={this.textFieldChangeHandler}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                showClear={inputValue !== ''}
                getInstance={instance => (this.inputInstance = instance)}
                onClick={() => this.setIsHidden(false)}
                onClear={() => this.onClear()}
                icon="calendar"
                className="datepicker-textfield"
                placeholder={stringDatesToStringRange(format)}
              />
            )}
          </InputMask>

          <div>{tooltip && <Tooltip tooltipTop={tooltipTop}>{tooltip()}</Tooltip>}</div>
          <Block marginTop={1.5} hidden={isHidden} overflow={'hidden'} className={'calendar-block'}>
            <CalendarWrapper>
              {ranges && (
                <Flex flex={4} justify={'flex-start'} maxWidth={'160px'}>
                  <PredefinedRanges
                    ranges={this.props.ranges || ranges}
                    range={renderingRange}
                    onSelect={date => this.handleSelect(date, PREDEFINED_RANGES_ID)}
                    periodLabel={periodLabel}
                  />
                </Flex>
              )}
              <Flex flex={9} flexWrap={'wrap'}>
                {(() => {
                  const _calendars = []
                  const _method = 'push'
                  for (let i = calendarsCount; i >= 0; i--) {
                    const offset = -i
                    _calendars[_method](
                      <Flex
                        flex={3}
                        key={i}
                        marginRight={'1.2rem'}
                        justify={i !== calendarsCount ? 'flex-end' : 'flex-start'}
                        minWidth={'160px'}
                        padding={'10px 0 0 0'}
                      >
                        <Calendar
                          disableDaysAfterToday={disableDaysAfterToday}
                          showMonthArrow={showMonthArrow}
                          shownDate={shownDate}
                          disableDaysBeforeToday={disableDaysBeforeToday}
                          label={i ? fromLabel : toLabel}
                          key={i}
                          offset={offset}
                          link={linkedCalendars && link}
                          linkCB={this.handleLinkChange.bind(this)}
                          range={renderingRange}
                          format={format}
                          firstDayOfWeek={firstDayOfWeek}
                          minDate={minDate}
                          maxDate={maxDate}
                          calendarNumber={i}
                          calendarLabels={calendarLabels}
                          numYearsRange={numYearsRange}
                          selectableStartYear={selectableStartYear}
                          selectableEndYear={selectableEndYear}
                          onChange={date => this.handleSelect(date, CALENDAR_ID)}
                        />
                      </Flex>
                    )
                  }
                  return _calendars
                })()}
              </Flex>
            </CalendarWrapper>
          </Block>
          {this.props.isMobile && (
            <CalendarErrorModal
              isModalOpen={this.state.isModalOpen}
              title={t('invalid_date_range')}
              toggleModal={this.toggleModal}
            />
          )}
        </Container>
      </>
    )
  }
}

export default onClickOutside(DatePicker)
