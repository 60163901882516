import { getCookie } from '@app/common/utilities'
import { PERSISTANCE_OPTIONS, SESSION_PERSISTANCE_KEY } from './constants'

export const getStoredUser = (mechanism = PERSISTANCE_OPTIONS.LOCAL_STORAGE) => {
  const mechanismGetters = {
    [PERSISTANCE_OPTIONS.LOCAL_STORAGE]: () => localStorage.getItem('user'),
    [PERSISTANCE_OPTIONS.COOKIES]: () => getCookie(SESSION_PERSISTANCE_KEY),
  }
  return mechanismGetters[mechanism] && mechanismGetters[mechanism]()
}

export const getJSessionId = user => {
  const jSessionIdString = user.sessionCookie && user.sessionCookie.split(';')[0]
  const jSessionIdValue = jSessionIdString && jSessionIdString.split('=')[1]
  return jSessionIdValue
}
