import React from 'react'
import { isNil, isEmpty as rIsEmpty } from 'ramda'
import { compose, lifecycle, withState, withHandlers } from 'recompose'
import { formatData, getDateColumnWithoutSort } from '@app/common/utilities'
import { Block, Tabs, Tab, Column } from '@new-lucentum'
import { t } from '@app/common/translationsService'
import { Box } from './Box'
import { DataList, DataItem } from './DataItem'
import { ShowMore } from './ShowMore'
import { Table, DataRow, Separator } from './styles'
import { getFormatedData, parseAddress } from './utils'
import { OPPOSITION_FIELDS } from '@app/common/dataManagers/types'
import { totalBoxEntries } from './utils'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'
import { useSelector } from 'react-redux'

const SHOWN_ROWS_LIMIT = 1

const labelProps = { labelLocation: 'left', labelWidth: '200px', labelAlign: 'left' }

const isEmpty = param => isNil(param) || rIsEmpty(param)

const hasInformation = data => ({
  hasGeneralInformation: data && !isEmpty(data),
  hasEarlyMarksInformation: data && !isEmpty(data.earlierMark),
  hasApplicantsInformation: data && !isEmpty(data.applicants),
})

const generalDataFields = [
  {
    label: 'tm.details.label.oppositionIdentifier.key',
    path: OPPOSITION_FIELDS.OPPOSITION_IDENTIFIER,
  },
  {
    label: 'tm.details.label.oppositionDate.key',
    path: OPPOSITION_FIELDS.OPPOSITION_DATE,
    isDate: true,
  },
  {
    label: 'tm.details.label.oppositionReference.key',
    path: OPPOSITION_FIELDS.OPPOSITION_REFERENCE,
  },
  {
    label: 'tm.details.label.proceedingLanguageCode.key',
    path: OPPOSITION_FIELDS.PROCEEDING_LANGUAGE_CODE,
  },
  {
    label: 'tm.details.label.oppositionBasisCode.key',
    path: OPPOSITION_FIELDS.OPPOSITION_BASIS_CODE,
    pathTranslated: OPPOSITION_FIELDS.OPPOSITION_BASIS_CODE_TRANSLATED,
  },
  {
    label: 'tm.details.label.oppositionGroundText.key',
    path: OPPOSITION_FIELDS.OPPOSITION_GROUND_TEXT,
  },
  {
    label: 'tm.details.label.oppositionStatus.key',
    path: OPPOSITION_FIELDS.OPPOSITION_CURRENT_STATUS_CODE,
  },
]

const earlierMarksDataFields = [
  {
    label: 'tm.details.label.earlierMarkCountryCode.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.EARLIER_MARK_COUNTRY_CODE,
  },
  {
    label: 'tm.details.label.applicationNumber.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.APPLICATION_NUMBER,
  },
  {
    label: 'tm.details.label.applicationDate.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.APPLICATION_DATE,
    isDate: true,
  },
  {
    label: 'tm.details.label.registrationNumber.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.REGISTRATION_NUMBER,
  },
  {
    label: 'tm.details.label.registrationDate.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.REGISTRATION_DATE,
    isDate: true,
  },
  {
    label: 'tm.details.label.MarkVerbalElementText.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.MARK_VERBAL_ELEMENT_TEXT,
  },
  {
    label: 'tm.details.label.markFeature.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.CODE_TRADEMARK_TYPE,
  },
  {
    label: 'tm.details.label.oppositionBasisCode.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.OPPOSITION_BASIS_CODE,
    pathTranslated: OPPOSITION_FIELDS.EARLIER_MARK.OPPOSITION_BASIS_CODE_TRANSLATED,
  },
  {
    label: 'tm.details.label.oppositionGroundText.key',
    path: OPPOSITION_FIELDS.EARLIER_MARK.OPPOSITION_GROUND_TEXT,
  },
]

const applicantDetailsDataFields = [
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantIdentifier.key',
    path: OPPOSITION_FIELDS.APPLICANTS.IDENTIFIER,
  },
  {
    label: 'tm.details.label.template.paintName.FirstName.key',
    path: OPPOSITION_FIELDS.APPLICANTS.NAME,
  },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantLegalEntity.key',
    path: OPPOSITION_FIELDS.APPLICANTS.LEGAL_ENTITY,
    pathTranslated: OPPOSITION_FIELDS.APPLICANTS.LEGAL_ENTITY_TRANSLATED,
    inidCode: 'applicantLegalEntity',
  },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantNationalityCode.key',
    path: OPPOSITION_FIELDS.APPLICANTS.NATIONALITY_CODE,
    inidCode: 'applicantNationalityCountry',
  },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantIncorporationCountryCode.key',
    path: OPPOSITION_FIELDS.APPLICANTS.INCORPORATION_COUNTRY_CODE,
  },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantIncorporationState.key',
    path: OPPOSITION_FIELDS.APPLICANTS.INCORPORATION_STATE,
    inidCode: 'applicantIncorporationState',
  },
  {
    label: 'tm.details.label.template.paintApplicant.EntitlementNationalityCode.key',
    path: OPPOSITION_FIELDS.APPLICANTS.ENTITLEMENT_NATIONALITY_CODE,
  },
  {
    label: 'tm.details.label.template.paintApplicant.EntitlementEstablishmentCode.key',
    path: OPPOSITION_FIELDS.APPLICANTS.ENTITLEMENT_ESTABLISHMENT_CODE,
  },
  {
    label: 'tm.details.label.template.paintApplicant.EntitlementDomiciledCode.key',
    path: OPPOSITION_FIELDS.APPLICANTS.ENTITLEMENT_DOMICILED_CODE,
  },
  {
    label: 'tm.details.label.template.paintAddress.FreeFormatAddress.key',
    path: OPPOSITION_FIELDS.APPLICANTS.FULL_ADDRESS,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.Phone.key',
    path: OPPOSITION_FIELDS.APPLICANTS.PHONE,
  },
  { label: 'tm.details.label.template.fax.key', path: OPPOSITION_FIELDS.APPLICANTS.FAX },
  { label: 'email.key', path: OPPOSITION_FIELDS.APPLICANTS.EMAIL },
  { label: 'tm.details.label.template.url.key', path: OPPOSITION_FIELDS.APPLICANTS.URL },
  {
    label: 'tm.details.label.template.paintAddress.FreeFormatAddress.key',
    path: OPPOSITION_FIELDS.APPLICANTS.ADDRESS_DETAILS.ADDRESS,
  },
  { label: 'addresscity.key', path: OPPOSITION_FIELDS.APPLICANTS.ADDRESS_DETAILS.CITY },
  {
    label: 'tm.details.label.template.paintAddress.AddressState.key',
    path: OPPOSITION_FIELDS.APPLICANTS.ADDRESS_DETAILS.STATE,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressPostcode.key',
    path: OPPOSITION_FIELDS.APPLICANTS.ADDRESS_DETAILS.POSTCODE,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressCountryCode.key',
    path: OPPOSITION_FIELDS.APPLICANTS.ADDRESS_DETAILS.COUNTRY_CODE,
  },
]
const OppositionBlockComponent = ({ inidCodes, data = {}, ...props }) => {
  const [showMore, setShowMore] = React.useState(false)
  const [generalData, setGeneralData] = React.useState([])
  const [earlierMarksData, setEarlierMarksData] = React.useState([])
  const [applicantDetailsData, setApplicantDetailsData] = React.useState([])

  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)

  React.useEffect(
    () => {
      const nextGeneralData = getFormatedData(generalDataFields, data, inidCodes, labelProps)
      setGeneralData(nextGeneralData)

      const nextFormatedEarlierMarks =
        data &&
        Array.isArray(data.earlierMark) &&
        data.earlierMark.map(mark =>
          getFormatedData(earlierMarksDataFields, mark, inidCodes, labelProps)
        )
      setEarlierMarksData(nextFormatedEarlierMarks)

      const nextFormatedApplicantDetails =
        data &&
        Array.isArray(data.applicants) &&
        data.applicants.map(applicant =>
          getFormatedData(
            applicantDetailsDataFields,
            parseAddress(applicant),
            inidCodes,
            labelProps
          )
        )
      setApplicantDetailsData(nextFormatedApplicantDetails)
    },
    [data, showOriginalLanguageAttributes]
  )

  const toggleShowMore = React.useCallback(() => setShowMore(!showMore), [showMore])

  const BoxOppositionEarlyMarkData =
    earlierMarksData &&
    earlierMarksData.map(
      (dataRow, index) =>
        (index < SHOWN_ROWS_LIMIT || showMore) && (
          <DataRow key={index}>
            <DataList testId="earlyMarks-show-more2" data={dataRow} shownItemsLimit={99} />
            {index + 1 !== data.earlierMark.length && showMore && <hr />}
          </DataRow>
        )
    )

  const BoxOppositionApplicantsData =
    applicantDetailsData &&
    applicantDetailsData.map(
      (dataRow, index) =>
        (index < SHOWN_ROWS_LIMIT || showMore) && (
          <DataRow key={index}>
            <DataList testId="applicant-show-more2" data={dataRow} shownItemsLimit={99} />
            {index + 1 !== data.applicants.length && showMore && <hr />}
          </DataRow>
        )
    )

  const BoxGeneralData = generalData && (
    <DataRow>
      <DataList testId="general-show-more2" data={generalData} shownItemsLimit={99} />
    </DataRow>
  )

  const {
    hasGeneralInformation,
    hasEarlyMarksInformation,
    hasApplicantsInformation,
  } = hasInformation(data)

  return (
    <Block>
      {!isEmpty(data) && (
        <Block justify>
          <DataItem
            labelLocation="left"
            label={t('tm.details.label.oppositionIdentifier.key')}
            data={formatData(data[OPPOSITION_FIELDS.OPPOSITION_IDENTIFIER])}
          />
          <DataItem
            labelLocation="left"
            label={t('status.key')}
            data={formatData(data[OPPOSITION_FIELDS.OPPOSITION_CURRENT_STATUS_CODE])}
          />
          <DataItem
            labelLocation="left"
            label={t('date.key')}
            data={formatData(data[OPPOSITION_FIELDS.OPPOSITION_DATE], true)}
          />
        </Block>
      )}
      <Block marginTop>
        <Tabs>
          {hasGeneralInformation && (
            <Tab
              data-test-id="BoxOpposition-general-tab"
              label={t('detail_tm_generalInfo')}
              originalValue={showOriginalLanguageAttributes}
            >
              <span>
                <Block padding innerPadding width6of12>
                  {BoxGeneralData}
                </Block>
                <Block padding innerPadding width6of12>
                  {data && !isEmpty(data.events) && (
                    <Table
                      className="DetailTable DetailTableNoBorder"
                      src={data.events}
                      hideCheckboxes
                    >
                      {getDateColumnWithoutSort(
                        'oppositionEventDate',
                        t('tm.details.label.oppositionEventDate.key')
                      )}
                      <Column
                        sortable={false}
                        resizable={false}
                        labelKey={
                          !showOriginalLanguageAttributes
                            ? 'oppositionEventCode'
                            : 'oppositionEventCodeTranslated'
                        }
                        label={t('tm.details.label.oppositionEventCode.key')}
                      />
                      <Column />
                    </Table>
                  )}
                </Block>
              </span>
            </Tab>
          )}
          {hasEarlyMarksInformation && (
            <Tab
              data-test-id="BoxOpposition-earlyMarks-tab"
              label={t('tm.details.label.earlierMarks.key')}
            >
              <Block padding>{BoxOppositionEarlyMarkData}</Block>
              {hasEarlyMarksInformation &&
                data.earlierMark.length > 1 &&
                data.earlierMark.length > SHOWN_ROWS_LIMIT && (
                  <ShowMore
                    testId="earlyMarks-show-more"
                    showMore={showMore}
                    showMoreHandle={toggleShowMore}
                  />
                )}
            </Tab>
          )}
          {hasApplicantsInformation && (
            <Tab data-test-id="BoxOpposition-applicant-tab" label={t('detail_tm_oppo_app')}>
              <Block padding>{BoxOppositionApplicantsData}</Block>
              {hasApplicantsInformation &&
                data.applicants.length > 1 &&
                data.applicants.length > SHOWN_ROWS_LIMIT && (
                  <ShowMore
                    testId="applicant-show-more"
                    showMore={showMore}
                    showMoreHandle={toggleShowMore}
                  />
                )}
            </Tab>
          )}
        </Tabs>
      </Block>
    </Block>
  )
}

const OppositionBlock = OppositionBlockComponent

const BoxOppositionComponent = ({ inidCodes, data = {}, officeExceptionMessage, ...props }) => {
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)
  React.useEffect(
    () => {
      const hasItems =
        data &&
        data.reduce((accumulator, currentValue) => {
          const {
            hasGeneralInformation,
            hasEarlyMarksInformation,
            hasApplicantsInformation,
          } = hasInformation(currentValue)
          const _hasItems =
            hasGeneralInformation || hasEarlyMarksInformation || hasApplicantsInformation
          return _hasItems || accumulator
        }, false)
      hasItems !== props.hasItemsToShow && props.setHasItemsToShow('boxOpposition', hasItems)
    },
    [data, showOriginalLanguageAttributes]
  )
  return (
    <Box
      boxId="boxOpposition"
      title={`${t('tm.details.left.menu.opposition.key')} ${totalBoxEntries(data)}`}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-opposition-inner"
      officeExceptionMessage={officeExceptionMessage}
    >
      {data &&
        data.map((oppositionBlock, index) => {
          return (
            <Block key={`opposition-block-${index}`}>
              {data.length > 1 && index > 0 && <Separator />}
              <OppositionBlock data={oppositionBlock} inidCodes={inidCodes} />
            </Block>
          )
        })}
    </Box>
  )
}

export const BoxOpposition = BoxOppositionComponent
