import camel from 'lodash.camelcase'
import { clone, concat, prop, uniqBy } from 'ramda'
import * as React from 'react'
import Highlighter from 'react-highlight-words'
import Select from 'react-select'

import Icon from '../Icon'
import Tooltip from '../Tooltip'
import customOption from './Options'
import { BlockTooltip, ErrorMsg, MaxMessage, Wrapper } from './styles'
import customValue from './Values'

const defaultProps = {
  customOption: child => child,
  collapsed: false,
  icon: 'chevron-down',
  labelKey: 'text',
  createOptionClass: 'Select-create-option-placeholder',
  multi: true,
  onBlurResetsInput: false,
  placeholder: 'Select a value',
  src: [],
  textClass: 'Class',
  textSearchFor: 'search for',
  textSelectAll: 'Select All',
  valueKey: 'text',
  closeOnSelect: false,
  valueTooltipPlace: 'top',
  optionProps: {},
  rtl: false,
}

export default class extends React.PureComponent {
  inputValue
  ReactSelect = Select
  src = clone(this.props.src)
  state = {
    value: clone(this.props.value),
    options: this.src,
    optionExpanded: [],
    focus: false,
  }

  static defaultProps = defaultProps

  UNSAFE_componentWillMount() {
    if (this.props.async) {
      this.ReactSelect = Select.Async
    } else if (this.props.creatable) {
      this.ReactSelect = Select.Creatable
    } else if (this.props.asyncCreatable) {
      this.ReactSelect = Select.AsyncCreatable
    }
    if (this.props.collapsed) {
      this.setState({ options: this.src.filter(e => e.level === 1) })
    } else {
      this.setState({ optionExpanded: this.src.filter(prop('children')).map(prop('id')) })
    }
  }

  UNSAFE_componentWillReceiveProps({ value, src }) {
    value && this.setState({ value: clone(value) })
    src && (this.src = clone(src))
  }

  onClose = () => {
    this.inputValue = null
    this.props.onClose && this.props.onClose()
  }

  // When you remove a father value, restore children inside options.
  handlerClick = (val, onRemove) => {
    if (this.props.optionClassName === 'vienna') {
      const allSelected =
        val.children &&
        Array.isArray(this.state.value) &&
        this.state.value.filter(
          x => !(x.id === val.id) && !(x.parent && x.parent.some(y => y === val.id))
        )

      allSelected ? this.setState({ value: allSelected }) : onRemove(val)

      this.props.onChange && allSelected && this.props.onChange(this.removeAllChildren(allSelected))
    } else {
      onRemove(val)
    }
  }

  addChildren = () =>
    this.props.multi && this.state.value && this.props.optionClassName === 'vienna'
      ? this.src.filter(
          ({ id, parent }) =>
            (this.state.value || []).some(v => v.id === id) ||
            (parent && (this.state.value || []).some(p => parent.includes(p.id)))
        )
      : this.state.value

  // If the value has children, remove children of the array for notificate only father in onChange.
  removeAllChildren = value =>
    Array.isArray(value) &&
    value.filter(
      x => !x.parent || (x.parent && !x.parent.some(y => (value || []).some(e => e.id === y)))
    )

  onChange = value => {
    if (this.props.optionClassName === 'nice') {
      value = value.filter(x => x.num)
    }

    this.props.max
      ? value.length <= this.props.max && this.setState({ value })
      : this.setState({ value })

    this.props.onChange &&
      (this.props.optionClassName && this.props.optionClassName === 'vienna'
        ? this.props.onChange(this.removeAllChildren(value))
        : this.props.onChange(value))
  }

  onInputChange = val => {
    this.inputValue = val
    if (this.props.onInputChange) {
      const newVals = this.props.onInputChange(val, this.props) || []
      newVals.length && this.setState(({ value }) => ({ value: [...value, ...newVals] }))
    } else {
      return this.inputValue
    }
  }

  onKeyDown = e => {
    const { onKeyDown, src, valueKey } = this.props
    if (onKeyDown) {
      const opts = onKeyDown(e, src)
      if (opts) {
        e.preventDefault()
        this.setState(({ value }) => ({
          value: value ? uniqBy(prop(valueKey), concat(value, opts)) : opts,
        }))
      }
    }
  }
  onBlur = e => {
    this.props.onBlur && this.props.onBlur(e, this.state.value)
  }
  arrowRenderer = () => <Icon {...{ [camel(this.props.icon)]: true }} />

  // Function to escape special characters in a string for use in a regular expression
  escapeRegExp(string) {
    if (!string?.length) return ''
    return string.replace(/\\/g, '\\$&')
  }

  optionRenderer = (option, index) => {
    const text = option[this.props.labelKey] || ''
    const testId = this.props.getOptionTestId && this.props.getOptionTestId(index)
    const optionProps = {
      ...(testId && { 'data-test-id': testId }),
    }
    return option['className'] === this.props.createOptionClass ? (
      <span {...optionProps}>{text}</span>
    ) : (
      <span {...optionProps}>
        <Highlighter
          searchWords={[this.escapeRegExp(this.inputValue)]}
          textToHighlight={this.escapeRegExp(text)}
          highlightClassName="highlighted"
        />
      </span>
    )
  }

  handleMouseOver = ({ num, info }) => this.setState({ info, num, visible: true })

  handleMouseOut = () => this.setState({ visible: false })

  render = () => {
    const { ReactSelect, props } = this
    const testIdProps = { ...(props.selectorTestId && { id: props.selectorTestId }) }
    return (
      <Wrapper
        type={props.optionClassName}
        fullWidth={props.fullWidth}
        padding={props.padding}
        mobile={props.mobile}
      >
        <div data-tip data-for={props.nameTooltip}>
          <ReactSelect
            rtl={props.rtl}
            multi={props.multi}
            options={props.options || this.state.options}
            valueComponent={customValue(this)}
            optionComponent={customOption({
              component: props.customOption,
              handleMouseOut: this.handleMouseOut,
              handleMouseOver: this.handleMouseOver,
              hiddenColumns: props.hiddenColumns,
              infoKey: props.infoKey,
              nameLink: props.nameLink,
              parentClassName: props.optionClassName,
              self: this,
              src: this.src,
              state: this.state,
              textClass: props.textClass,
              textLink: props.textLink,
              textSearchFor: props.textSearchFor,
              textSelectAll: props.textSelectAll,
              title: props.labelKey,
              toLink: props.toLink,
              valueKey: props.valueKey,
            })}
            arrowRenderer={this.arrowRenderer}
            optionRenderer={this.optionRenderer}
            {...this.props}
            value={this.addChildren()}
            onClose={this.onClose}
            onChange={this.onChange}
            onInputKeyDown={this.onKeyDown}
            onInputChange={this.onInputChange}
            onBlur={this.onBlur}
            clearRenderer={() => <Icon close />}
            {...testIdProps}
          />
        </div>
        {props.tooltip && !this.state.focus && (
          <Tooltip
            offset={{ right: props.tooltipRight ? props.tooltipRight : 0 }}
            id={props.nameTooltip}
            place="bottom"
          >
            <BlockTooltip>{props.tooltip()}</BlockTooltip>
          </Tooltip>
        )}

        {props.errorMessage && !this.state.focus && (
          <ErrorMsg errorMessage>
            <p>{props.errorMessage}</p>
          </ErrorMsg>
        )}

        {props.max && props.maxMessage && !this.state.focus && (
          <MaxMessage>
            <p>{props.maxMessage}</p>
          </MaxMessage>
        )}
      </Wrapper>
    )
  }
}
