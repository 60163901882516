import { t } from '@app/common/translationsService'
import moment from 'moment'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { TableCol, TableRow } from './styles'

const CompareTableDS = props => {
  const historyData = useSelector(state => state.appState.history.data.transactionInstances)

  const selectedFirstOption = historyData.filter(x => x.historyDate === props.selectedFirstOption)
  const selectedSecOption = historyData.filter(x => x.historyDate === props.selectedSecOption)

  const compareDatesFirstColumn = (date1, date2) => {
    let firstDate = moment(date1)
      .utc()
      .format('DD/MM/YYYY')
    let secondDate = moment(date2)
      .utc()
      .format('DD/MM/YYYY')
    if (firstDate !== secondDate) {
      return 'red'
    } else {
      return null
    }
  }

  const compareDatesSecondColumn = (date1, date2) => {
    let firstDate = moment(date1)
      .utc()
      .format('DD/MM/YYYY')
    let secondDate = moment(date2)
      .utc()
      .format('DD/MM/YYYY')
    if (firstDate !== secondDate) {
      return 'green'
    } else {
      return null
    }
  }

  let firstOption = selectedFirstOption[0]
  let secondOption = selectedSecOption[0]

  const firstOptionIndications = firstOption?.indicationProducts?.map(ind => {
    return { ...ind, option: 'first' }
  })

  const secondOptionIndications = secondOption?.indicationProducts?.map(ind => {
    return { ...ind, option: 'second' }
  })

  let longestArray = []
  let shortestArray = []
  if (firstOptionIndications?.length > secondOptionIndications?.length) {
    longestArray = firstOptionIndications
    shortestArray = secondOptionIndications
  } else {
    longestArray = secondOptionIndications
    shortestArray = firstOptionIndications
  }

  const comparePriorities = (firstPriority, secondPriority, col) => {
    const firstPriorities = firstPriority.map(priority => {
      const { formattedDate, ...priorities } = priority
      return priorities
    })

    const secondPriorities = secondPriority.map(priority => {
      const { formattedDate, ...priorities } = priority
      return priorities
    })
    const areEqual =
      firstPriorities.length === secondPriorities.length &&
      JSON.stringify(firstPriorities) === JSON.stringify(secondPriorities)

    if (!areEqual) {
      if (col === 'firstCol') return 'red'
      if (col === 'secondCol') return 'green'
    }
    return null
  }

  const commomElements = longestArray
    .filter(value => shortestArray?.find(x => x.languageCode === value.languageCode))
    .map(value => {
      const firstOption = longestArray.find(x => x.languageCode === value.languageCode)
        .indicationOfProduct
      const secondOption = shortestArray?.find(x => x.languageCode === value.languageCode)
        .indicationOfProduct
      return {
        label: `${t('search.report.column.indprod.key')} (${value.languageCode.toUpperCase()})`,
        firstOption,
        secondOption,
        firstOptionClass: firstOption === secondOption ? null : 'red',
        secondOptionClass: firstOption === secondOption ? null : 'green',
      }
    })

  const exclusiveFirstOptions = longestArray
    .filter(value => shortestArray.find(x => x.languageCode === value.languageCode) === undefined)
    .map(value => ({
      label: `${t('search.report.column.indprod.key')} (${value.languageCode.toUpperCase()})`,
      firstOption: value.indicationOfProduct,
      secondOption: '',
      firstOptionClass: 'red',
      secondOptionClass: null,
    }))

  const exclusiveSecondOptions = shortestArray
    .filter(value => longestArray.find(x => x.languageCode === value.languageCode) === undefined)
    .map(value => ({
      label: `${t('search.report.column.indprod.key')} (${value.languageCode.toUpperCase()})`,
      firstOption: '',
      secondOption: value.indicationOfProduct,
      firstOptionClass: null,
      secondOptionClass: 'green',
    }))

  const productIndications = [
    ...commomElements,
    ...exclusiveFirstOptions,
    ...exclusiveSecondOptions,
  ].map((productIndication, index) => (
    <TableRow key={index}>
      <TableCol bold>{productIndication.label}</TableCol>
      <TableCol rowColor={productIndication.firstOptionClass}>
        {productIndication.firstOption}
      </TableCol>
      <TableCol rowColor={productIndication.secondOptionClass}>
        {productIndication.secondOption}
      </TableCol>
    </TableRow>
  ))

  return (
    <Fragment>
      <TableCol padding>
        <TableRow id="border-first-row">
          <TableCol bold>{t('status.key')}</TableCol>
          <TableCol rowColor={firstOption.statusCode !== secondOption.statusCode ? 'red' : null}>
            {firstOption?.statusCode}
          </TableCol>
          <TableCol rowColor={firstOption.statusCode !== secondOption.statusCode ? 'green' : null}>
            {secondOption?.statusCode}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('applicationdate.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.applicationDate,
              secondOption?.applicationDate
            )}
          >
            {firstOption?.applicationDate &&
              moment(firstOption.applicationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.applicationDate,
              secondOption?.applicationDate
            )}
          >
            {secondOption?.applicationDate &&
              moment(secondOption.applicationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('history.application.publication.date.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.applicationPublicationDate,
              secondOption?.applicationPublicationDate
            )}
          >
            {firstOption?.applicationPublicationDate &&
              moment(firstOption.applicationPublicationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.applicationPublicationDate,
              secondOption?.applicationPublicationDate
            )}
          >
            {secondOption?.applicationPublicationDate &&
              moment(secondOption.applicationPublicationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('history.deferment.expiration.date.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.defermentExpirationDate,
              secondOption?.defermentExpirationDate
            )}
          >
            {firstOption?.defermentExpirationDate &&
              moment(firstOption.defermentExpirationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.defermentExpirationDate,
              secondOption?.defermentExpirationDate
            )}
          >
            {secondOption?.defermentExpirationDate &&
              moment(secondOption.defermentExpirationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('history.design.identifier.key')}</TableCol>
          <TableCol
            rowColor={firstOption.designIdentifier !== secondOption.designIdentifier ? 'red' : null}
          >
            {firstOption?.designIdentifier}
          </TableCol>
          <TableCol
            rowColor={
              firstOption.designIdentifier !== secondOption.designIdentifier ? 'green' : null
            }
          >
            {secondOption?.designIdentifier}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('effective.date.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.effectiveDate,
              secondOption?.effectiveDate
            )}
          >
            {firstOption?.effectiveDate &&
              moment(firstOption.effectiveDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.effectiveDate,
              secondOption?.effectiveDate
            )}
          >
            {secondOption?.effectiveDate &&
              moment(secondOption.effectiveDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('verbalelementtext.key')}</TableCol>
          <TableCol
            rowColor={
              firstOption.verbalElementText !== secondOption.verbalElementText ? 'red' : null
            }
          >
            {firstOption?.verbalElementText}
          </TableCol>
          <TableCol
            rowColor={
              firstOption.verbalElementText !== secondOption.verbalElementText ? 'green' : null
            }
          >
            {secondOption?.verbalElementText}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('locarno.classification.key')}</TableCol>
          <TableCol rowColor={firstOption.classNumber !== secondOption.classNumber ? 'red' : null}>
            {firstOption?.classNumber}
          </TableCol>
          <TableCol
            rowColor={firstOption.classNumber !== secondOption.classNumber ? 'green' : null}
          >
            {secondOption?.classNumber}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('publication.date.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.publicationDate,
              secondOption?.publicationDate
            )}
          >
            {firstOption?.publicationDate &&
              moment(firstOption.publicationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.publicationDate,
              secondOption?.publicationDate
            )}
          >
            {secondOption?.publicationDate &&
              moment(secondOption.publicationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        {(firstOption?.eventName || secondOption?.eventDate) && (
          <TableRow>
            <TableCol bold>{t('history.event.name')}</TableCol>
            <TableCol rowColor={firstOption?.eventName !== secondOption?.eventName ? 'red' : null}>
              {firstOption?.eventName}
            </TableCol>
            <TableCol rowColor={firstOption.eventName !== secondOption.eventName ? 'green' : null}>
              {secondOption?.eventName}
            </TableCol>
          </TableRow>
        )}
        {(firstOption?.eventDate || secondOption?.eventDate) && (
          <TableRow>
            <TableCol bold>{t('history.event.date')}</TableCol>
            <TableCol
              rowColor={compareDatesFirstColumn(firstOption?.eventDate, secondOption?.eventDate)}
            >
              {firstOption?.eventDate &&
                moment(firstOption.eventDate)
                  .utc()
                  .format('DD/MM/YYYY')}
            </TableCol>
            <TableCol
              rowColor={compareDatesSecondColumn(firstOption?.eventDate, secondOption?.eventDate)}
            >
              {secondOption?.eventDate &&
                moment(secondOption.eventDate)
                  .utc()
                  .format('DD/MM/YYYY')}
            </TableCol>
          </TableRow>
        )}
        <TableRow>
          <TableCol bold>{t('registration.date.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption.registrationDate,
              secondOption.registrationDate
            )}
          >
            {firstOption?.registrationDate &&
              moment(firstOption.registrationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption.registrationDate,
              secondOption.registrationDate
            )}
          >
            {secondOption?.registrationDate &&
              moment(secondOption.registrationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('tm.details.label.registrationNumber.key')}</TableCol>
          <TableCol
            rowColor={
              firstOption.registrationNumber !== secondOption.registrationNumber ? 'red' : null
            }
          >
            {firstOption?.registrationNumber}
          </TableCol>
          <TableCol
            rowColor={
              firstOption.registrationNumber !== secondOption.registrationNumber ? 'green' : null
            }
          >
            {secondOption?.registrationNumber}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('history.exhibition.name.key')}</TableCol>
          {firstOption?.exhibitionPriorities && secondOption?.exhibitionPriorities && (
            <>
              <TableCol
                rowColor={
                  JSON.stringify(firstOption.exhibitionPriorities) !==
                  JSON.stringify(secondOption.exhibitionPriorities)
                }
              >
                {firstOption.exhibitionPriorities.map(exhibit => exhibit.exhibitionName).join(', ')}
              </TableCol>
              <TableCol
                rowColor={
                  JSON.stringify(firstOption.exhibitionPriorities) !==
                  JSON.stringify(secondOption.exhibitionPriorities)
                    ? 'green'
                    : null
                }
              >
                {secondOption.exhibitionPriorities
                  .map(exhibit => exhibit.exhibitionName)
                  .join(', ')}
              </TableCol>
            </>
          )}
        </TableRow>
        {firstOption?.priorities?.length > secondOption?.priorities?.length ||
        secondOption.priorities == null
          ? firstOption?.priorities?.map((priority, index) => (
              <>
                <TableRow key={index} style={{ borderTop: index !== 0 ? 'none' : '' }}>
                  <TableCol bold>
                    <TableRow className="priorities-row">
                      {t('convention.priority.number.key')}
                    </TableRow>
                    <TableRow className="priorities-row">{t('priority.date.key')}</TableRow>
                    <TableRow className="priorities-row">{t('priority.country.key')}</TableRow>
                  </TableCol>
                  <TableCol
                    rowColor={comparePriorities(firstOption?.priorities, secondOption?.priorities)}
                  >
                    <TableRow className="priorities-row">{priority?.priorityNumber}</TableRow>
                    <TableRow className="priorities-row">
                      {moment(priority.priorityDate)
                        .utc()
                        .format('DD/MM/YYYY')}
                    </TableRow>
                    <TableRow className="priorities-row">{priority?.priorityCountry}</TableRow>
                  </TableCol>
                  <TableCol
                    rowColor={firstOption?.priorities !== secondOption?.priorities ? 'green' : null}
                  >
                    <TableRow className="priorities-row">
                      {secondOption.priorities !== null &&
                        secondOption?.priorities[index]?.priorityNumber}
                    </TableRow>
                    <TableRow className="priorities-row">
                      {secondOption.priorities !== null &&
                        secondOption.priorities[index]?.priorityDate !== undefined &&
                        moment(secondOption?.priorities[index]?.priorityDate)
                          .utc()
                          .format('DD/MM/YYYY')}
                    </TableRow>
                    <TableRow id="borderless">
                      {secondOption.priorities !== null &&
                        secondOption?.priorities[index]?.priorityCountry}
                    </TableRow>
                  </TableCol>
                </TableRow>
              </>
            ))
          : secondOption?.priorities !== null &&
            secondOption?.priorities?.map((priority, index) => (
              <TableRow key={index} style={{ borderTop: index !== 0 ? 'none' : '' }}>
                <TableCol bold>
                  <TableRow className="priorities-row">
                    {t('convention.priority.number.key')}
                  </TableRow>
                  <TableRow className="priorities-row">{t('priority.date.key')}</TableRow>
                  <TableRow className="priorities-row">{t('priority.country.key')}</TableRow>
                </TableCol>

                <TableCol
                  rowColor={comparePriorities(
                    firstOption?.priorities,
                    secondOption?.priorities,
                    'firstCol'
                  )}
                >
                  <TableRow className="priorities-row">
                    {firstOption.priorities !== null &&
                      firstOption?.priorities[index]?.priorityNumber}
                  </TableRow>
                  <TableRow className="priorities-row">
                    {firstOption.priorities !== null &&
                      firstOption.priorities[index]?.priorityDate !== undefined &&
                      moment(firstOption?.priorities[index]?.priorityDate)
                        .utc()
                        .format('DD/MM/YYYY')}
                  </TableRow>
                  <TableRow style={{ border: 'none' }}>
                    {firstOption?.priorities !== null &&
                      firstOption?.priorities[index]?.priorityCountry}
                  </TableRow>
                </TableCol>
                <TableCol
                  rowColor={comparePriorities(
                    firstOption?.priorities,
                    secondOption?.priorities,
                    'secondCol'
                  )}
                >
                  <TableRow className="priorities-row">{priority?.priorityNumber}</TableRow>
                  <TableRow className="priorities-row">
                    {moment(priority.priorityDate)
                      .utc()
                      .format('DD/MM/YYYY')}
                  </TableRow>
                  <TableRow className="priorities-row">{priority?.priorityCountry}</TableRow>
                </TableCol>
              </TableRow>
            ))}
        {firstOption.priorities === null && secondOption.priorities === null ? (
          <TableRow>
            <TableCol bold>
              <TableRow className="priorities-row">{t('convention.priority.number.key')}</TableRow>
              <TableRow className="priorities-row">{t('priority.date.key')}</TableRow>
              <TableRow className="priorities-row">{t('priority.country.key')}</TableRow>
            </TableCol>
            <TableCol />
            <TableCol />
          </TableRow>
        ) : null}
        {productIndications}
      </TableCol>
    </Fragment>
  )
}

export default CompareTableDS
