import { DESIGNS_FIELDS } from '@app/common/dataManagers/types'

export const designFields = [
  { label: 'registrationofficecode.key', path: DESIGNS_FIELDS.registrationOfficeCode },
  { label: 'design.number.key', path: DESIGNS_FIELDS.designNumber },
  { label: 'applicationnumber.key', path: DESIGNS_FIELDS.applicationNumber },
  { label: 'applicationlanguagecode.key', path: DESIGNS_FIELDS.applicationLanguageCode },
  { label: 'secondlanguagecode.key', path: DESIGNS_FIELDS.secondLanguageCode },
  {
    label: 'applicationdate.key',
    path: DESIGNS_FIELDS.applicationDate,
    isDate: true,
  },
  {
    label: 'application.publication.date.key',
    path: DESIGNS_FIELDS.applicationPublicationDate,
    isDate: true,
  },
  { label: 'registrationnumber.key', path: DESIGNS_FIELDS.registrationNumber },
  {
    label: 'registration.date.key',
    path: DESIGNS_FIELDS.registrationDate,
    isDate: true,
  },
  { label: 'publication.date.key', path: DESIGNS_FIELDS.publicationDate, isDate: true },
  { label: 'verbal.element.key', path: DESIGNS_FIELDS.verbalElement },
  { label: 'designdescription.key', path: DESIGNS_FIELDS.designDescription },
  {
    label: 'deferment.expiration.date.key',
    path: DESIGNS_FIELDS.endOfDefermentDate,
    isDate: true,
  },
  { label: 'expiry.date.key', path: DESIGNS_FIELDS.expiryDate, isDate: true },
  { label: 'effective.date.key', path: DESIGNS_FIELDS.effectiveDate, isDate: true },
  {
    label: 'designcurrentstatuscode.key',
    path: DESIGNS_FIELDS.currentStatusCode,
    pathTranslated: DESIGNS_FIELDS.currentStatusCodeTranslated,
  },
  { label: 'designcurrentstatusdate.key', path: DESIGNS_FIELDS.currentStatusDate, isDate: true },
  {
    label: 'ds.details.label.DesignatedCountryCode.key',
    path: DESIGNS_FIELDS.designatedCountryCodes,
  },
  { label: 'ds.details.label.comment.key', path: DESIGNS_FIELDS.comment },
  { label: 'ds.details.label.statements.key', path: DESIGNS_FIELDS.statements },
  { label: 'renewal.current.status.code.key', path: DESIGNS_FIELDS.renewalCurrentStatusCode },
  { label: 'applicationreference.key', path: DESIGNS_FIELDS.applicationReference },
]
