import { TRADEMARK_FIELDS } from '@app/common/dataManagers/types'
import { TMDSTrackingService } from '@app/common/services'
import { hasItemsToShow } from '@app/common/utilities'
import { Block } from '@new-lucentum'
import React, { Fragment } from 'react'
import { compose, lifecycle, withState } from 'recompose'

import { Box } from './Box'
import { DataList } from './DataItem'
import { Image, Link } from './styles'
import { getFormatedData } from './utils'

const fields = [
  { label: 'tm.details.label.tmOffice.key', path: TRADEMARK_FIELDS.tmOffice },
  {
    label: 'tm.details.label.registrationOfficeCode.key',
    path: TRADEMARK_FIELDS.registrationOfficeCode,
    inidCode: 'registrationAuthority',
  },
]

const enhance = compose(
  withState('formatedData', 'setFormatedData', ({ inidCodes, data }) =>
    data.tradeMark ? getFormatedData(fields, data.tradeMark, inidCodes) : []
  ),
  lifecycle({
    componentDidMount() {
      const { formatedData, setHasItemsToShow, data } = this.props
      const { officeLogoUrl, officeCaseLawUrl, tradeMark = {} } = data
      const { tmOfficeURL } = tradeMark
      const hasItems =
        officeLogoUrl || officeCaseLawUrl || tmOfficeURL || hasItemsToShow(formatedData)
      hasItems !== this.props.hasItemsToShow && setHasItemsToShow('boxOffice', hasItems)
    },
  })
)

export default enhance(
  ({ literals, inidCodes, data = {}, formatedData, officeExceptionMessage, ...props }) => {
    const { officeLogoUrl, tmOfficeURL, tradeMark = {} } = data
    const { tmName = '' } = tradeMark

    return (
      <Box
        boxId="boxOffice"
        title={literals.tm_details_section_office}
        hasItemsToShow={props.hasItemsToShow}
        dataTestId="item-detail-offices"
        officeExceptionMessage={officeExceptionMessage}
      >
        {props.hasItemsToShow && (
          <Block column>
            {officeLogoUrl && <Image bigPicture center src={officeLogoUrl} alt={tmName} />}
            {tmOfficeURL && (
              <Block paddingTop={1} paddingBottom={2}>
                {
                  <Fragment>
                    <Link
                      data-test-id="office-view-trademark-link"
                      fontSizePercent={85}
                      href={tmOfficeURL}
                      target="_blank"
                      onClick={() => TMDSTrackingService.trackViewOfficeOfOriginClickEvent()}
                    >
                      {literals.results_details_view_trademark_key}
                    </Link>
                    <br />
                    {data?.tradeMark?.caseLaw?.officeUrl && (
                      <Link
                        data-test-id="case-law-link"
                        fontSizePercent={85}
                        href={data?.tradeMark?.caseLaw?.officeUrl}
                        target="_blank"
                        onClick={() => TMDSTrackingService.trackViewOfficeOfOriginClickEvent()}
                      >
                        {literals.office_of_origin_case_law}
                      </Link>
                    )}
                  </Fragment>
                }
              </Block>
            )}
            <DataList data={formatedData} literals={literals} shownItemsLimit={2} />
          </Block>
        )}
      </Box>
    )
  }
)
