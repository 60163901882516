import { useOnClickOutside } from '@app/common/hooks'
import React from 'react'
import { usePopper } from 'react-popper'
import { compose, withHandlers, withState } from 'recompose'

import { StyledDropdownMenu } from './Dropdown.styles'

const DropdownComponent = props => {
  const { toggleDropdown, isDropdownOpen, closeDropdown } = props
  const {
    menuComponent: CustomMenuComponent,
    menuExtraProps = {},
    exposeMenuActions,
    alignMenuRight,
    noPadding,
  } = props
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  })
  const ref = React.useRef()
  useOnClickOutside(ref, () => closeDropdown(), isDropdownOpen)
  return (
    <div ref={ref}>
      <div ref={setReferenceElement}>
        {props.children({ toggleDropdown, isDropdownOpen, ref: setReferenceElement })}
      </div>

      {isDropdownOpen && (
        <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 19 }} {...attributes.popper}>
          <StyledDropdownMenu
            alignMenuRight={alignMenuRight}
            noPadding={noPadding}
            className="dropdown-menu"
          >
            <CustomMenuComponent {...{ toggleDropdown, exposeMenuActions, ...menuExtraProps }} />
          </StyledDropdownMenu>
        </div>
      )}
    </div>
  )
}

export const Dropdown = compose(
  withState('isDropdownOpen', 'setIsDropdownOpen', false),
  withHandlers(() => {
    let _menuActions = null
    return {
      toggleDropdown: props => () => {
        const nextIsDropdownOpen = !props.isDropdownOpen
        props.setIsDropdownOpen(nextIsDropdownOpen)
      },
      closeDropdown: props => () => {
        const { onTryCloseDropdown = false } = props
        const canClose = onTryCloseDropdown ? onTryCloseDropdown(_menuActions) : true
        const isPromise = typeof canClose.then === 'function'
        const applyClosing = () => {
          props.setIsDropdownOpen(false)
        }
        if (isPromise) {
          canClose.then(() => {
            applyClosing()
          })
        } else {
          canClose && applyClosing()
        }
      },
      exposeMenuActions: props => menuActions => {
        _menuActions = menuActions
      },
    }
  })
)(DropdownComponent)
