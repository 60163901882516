import React from 'react'
import { t } from '@app/common/translationsService'
import { Block, Modal } from '@new-lucentum'
import { ClassificationsTranslatedTerms } from './ClassificationsTranslatedTerms'
import { TranslationsProvidedBy } from './TranslationsProvidedBy'
import { ScrollBlock } from './styles'

const getClassificationTerms = (gs, i, gsTranslated, languageTranslate, originalLanguage) => {
  const goodServicesTranslated = gsTranslated.find(
    gsTrans => gsTrans.niceClass.toString() === gs.niceClass.toString()
  )
  return (
    <ClassificationsTranslatedTerms
      key={i}
      niceClass={gs.niceClass}
      terms={goodServicesTranslated ? goodServicesTranslated : gs}
      termsOriginal={gs}
      languageTranslation={languageTranslate}
      originalLanguage={originalLanguage}
    />
  )
}

export const ModalCompareTranslations = ({
  languageObject = {},
  originalLanguageObject = {},
  goodAndServiceList,
  gsTranslated,
  isOpen,
  onCloseModal,
}) => {
  const { code: languageCode = '', lang: languageText = '' } = languageObject
  const {
    code: originalLanguageCode = '',
    lang: originalLanguageText = '',
  } = originalLanguageObject
  const title = `${t('tm_details_section_goods_and_services')} - ${t(
    'detail_tm_gs_compare_translations'
  )}`
  return (
    isOpen && (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        title={title}
        modalSize="large"
        ariaHideApp={false}
      >
        <Block alignContents="right">
          <TranslationsProvidedBy padding auto language={languageCode} />
        </Block>
        <Block width6of12 padding>
          <strong>{`${originalLanguageText} (${t('detail_tm_gs_original')})`}</strong>
        </Block>
        <Block width6of12 padding>
          <strong>{languageText}</strong>
        </Block>
        <ScrollBlock height="40vh">
          {goodAndServiceList &&
            goodAndServiceList.map((gs, i) =>
              getClassificationTerms(gs, i, gsTranslated, languageCode, originalLanguageCode)
            )}
        </ScrollBlock>
      </Modal>
    )
  )
}

export default ModalCompareTranslations
