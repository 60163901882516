import {
  editSettings as editSettingsAction,
  getSettings as getSettingsAction,
} from '@app/actions/settings'
import store from '@app/store'

const { dispatch } = store

const getSettings = () => {
  return dispatch(getSettingsAction())
}

const editSettings = (patch, isEmail) => {
  return dispatch(editSettingsAction(patch, isEmail))
}

export const TMDSUserSettingsService = {
  getSettings,
  editSettings,
}
