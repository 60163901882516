import { fullfilled, pending, rejected } from './utils/reduxPromise'

const SET_RESET_IMAGE = 'SET_RESET_IMAGE'
const UPLOAD_IMAGE_SEARCH = 'UPLOAD_IMAGE_SEARCH'
const RESTORE_IMAGE_SEARCH = 'RESTORE_IMAGE_SEARCH'
const CHANGE_SELECTION_MODE = 'CHANGE_SELECTION_MODE'
const SET_IMAGE_DATA = 'SET_IMAGE_DATA'
const CLEAR_IMAGE_DATA = 'CLEAR_IMAGE_DATA'

export const IMAGE_SEARCH_TYPES = {
  SET_RESET_IMAGE,
  UPLOAD_IMAGE_SEARCH,
  UPLOAD_IMAGE_SEARCH_FULFILLED: fullfilled(UPLOAD_IMAGE_SEARCH),
  UPLOAD_IMAGE_SEARCH_PENDING: pending(UPLOAD_IMAGE_SEARCH),
  UPLOAD_IMAGE_SEARCH_REJECTED: rejected(UPLOAD_IMAGE_SEARCH),
  RESTORE_IMAGE_SEARCH,
  CHANGE_SELECTION_MODE,
  SET_IMAGE_DATA,
  CLEAR_IMAGE_DATA,
}
