import { EVENT_KEY_CODES } from '@app/common/constants'
import { Block, Button, Grid, Icon, Link, P, Tooltip } from '@new-lucentum'
import React, { Fragment } from 'react'
import Highlighter from 'react-highlight-words'
import BooleanSelect, { components } from 'react-select-boolean'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { withTheme } from 'styled-components'

import { useResponsive } from '../../common/hooks'
import Boolean from './Boolean'
import {
  BlockMenu,
  BlockTooltip,
  Checkbox,
  ClearAllIcon,
  Container,
  ControlContainer,
  Group,
  Option,
  StyledButton,
  styleSelect,
  ValueWrapper as VWrapper,
  VerticalAlign,
} from './styles'

const { ENTER, SPACE_TAB, SPACE } = EVENT_KEY_CODES
const DEFAULT_OPERATOR_VALUE = 'OR'
const EMPTY_VALUE = 'EMPTY'
const HREF_EUIPO = 'http://euipo.europa.eu/ec2/'
const IndicatorSeparator = false
const rangeRegex = /^((\d+\.?)+\.\.(\d+\.?)+)$/g
const codeRegex = /^\d+$$/g

const styles = {
  ...styleSelect,
  option: base => ({
    ...styleSelect.option(base),
    borderBottom: 'solid 1px #f0f0f1',
    color: 'black',
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  placeholder: base => ({
    ...base,
    color: '#aaa',
  }),
}

const onSelecAll = (opt, props) => {
  const optionFiltered = opt.reduce(
    (arr, prev, key) =>
      !props.value.length || !props.value.some(y => y.num === prev.num)
        ? [
            ...arr,
            {
              ...prev,
              value: prev.num,
              ...((props.value.length || key !== 0) && { operator: DEFAULT_OPERATOR_VALUE }),
            },
          ]
        : arr,
    []
  )
  optionFiltered.length && props.onChange([...props.value, ...optionFiltered])
}
const formatGroupLabel = ({ description, options }, props) => (
  <Group>
    <span>{description}</span>
    <Button link onClick={() => onSelecAll(options, props)}>
      {props.literals['select.all.key']} {description}
    </Button>
  </Group>
)

const onNoNiceClass = ({ literals, noNiceClass, onChange, setNoNiceClass }) => ({
  setValue,
  getValue,
}) => {
  const values = getValue()
  noNiceClass
    ? onChange([...values.filter(nice => nice.value && nice.value !== EMPTY_VALUE)])
    : setValue([
        ...values,
        {
          num: EMPTY_VALUE,
          value: EMPTY_VALUE,
          operator: DEFAULT_OPERATOR_VALUE,
        },
      ])
  setNoNiceClass(!noNiceClass)
}

const onChangeBoolean = (bl, { data, selectProps }) =>
  selectProps.onChange &&
  selectProps.onChange([
    ...selectProps.value.map(opt =>
      opt.num === data.num
        ? {
            ...data,
            operator: bl.value,
          }
        : opt
    ),
  ])

const onClickOption = ({ value, hasValue, setValue, getValue, info }) =>
  setValue([
    ...getValue(),
    {
      info,
      num: value,
      operator: hasValue ? DEFAULT_OPERATOR_VALUE : null,
      value,
    },
  ])

const ValueWrapper = props => {
  const { isMobile } = useResponsive()

  props.selectProps.value &&
    props.selectProps.value[0] &&
    props.selectProps.value[0].operator &&
    (props.selectProps.value[0].operator = null)

  return (
    <VWrapper>
      {props.data.operator && !isMobile && (
        <Boolean
          boolean={props.data.operator}
          onChange={e => onChangeBoolean(e, props)}
          {...props}
        />
      )}
      <div data-tip data-for={`valueN${props.data.num}`}>
        <components.MultiValueContainer boolean={props.data.operator} {...props} />
      </div>
      {props.data.info && (
        <Tooltip id={`valueN${props.data.num}`} offset={{ top: -5 }}>
          <BlockTooltip>{props.selectProps.infos[props.data.num]}</BlockTooltip>
        </Tooltip>
      )}
    </VWrapper>
  )
}

const MultiValueLabel = ({ data: { num, info }, selectProps, ...props }) => {
  const label =
    typeof num === 'string' && num.includes(EMPTY_VALUE)
      ? selectProps.literals.advanced_search_nice_class_button_no_nice
      : selectProps.formatLabel
      ? selectProps.formatLabel(num, info[0])
      : num
  return <components.MultiValueLabel {...props}>{label}</components.MultiValueLabel>
}

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon close />
    </components.MultiValueRemove>
  )
}

const CustomControl = props => (
  <ControlContainer>
    <components.Control {...props} />
  </ControlContainer>
)

const CustomOption = ({ expanded, onExpanded, data, innerProps: { onClick }, ...props }) => {
  return (
    <Option key={data.num} isFocused={props.isFocused} expanded={expanded} onClick={onExpanded}>
      <components.Option {...props}>
        <Checkbox
          checked={props.isSelected}
          onChange={() => (props.isSelected ? onClick() : onClickOption({ ...props, ...data }))}
          type="checkbox"
        />
        <strong>{data.num} - </strong>
        <P>
          <Highlighter
            searchWords={[props.selectProps.inputValue]}
            textToHighlight={data.info.toString()}
            highlightClassName="highlighted"
          />
        </P>
      </components.Option>
    </Option>
  )
}

const filterOption = (opt, input) =>
  !input ||
  opt.value.toString() === input.toString() ||
  opt.data.info.some(x => x.toLowerCase().includes(input.toLowerCase()))

const onInputChange = props => input => {
  const matches = input.match(rangeRegex)
  if (input.includes(',')) {
    const [inputValue] = input.split(',')
    const [goods, services] = props.options
    const option = [...goods.options, ...services.options].find(
      y => y.num.toString() === inputValue
    )
    const checkExistsInValues = props.value.some(x => x.num.toString() === inputValue)
    return !checkExistsInValues
      ? option &&
          !props.onChange([
            ...props.value,
            { ...option, value: option.num, operator: DEFAULT_OPERATOR_VALUE },
          ]) &&
          props.setInputValue('')
      : props.setInputValue(inputValue)
  } else if (matches && matches.length) {
    const [first, last] = matches[0].split('..')
    const options = [...props.options[0].options, ...props.options[1].options]
    const rangeOptionsFiltered = options
      .slice(
        options.findIndex(x => x.num.toString() === first),
        options.findIndex(x => x.num.toString() === last) + 1
      )
      .reduce((memo, currentValue, index) => {
        return !props.value.some(y => y.num === currentValue.num)
          ? [
              ...memo,
              {
                ...currentValue,
                ...((props.value.length || index !== 0) && { operator: DEFAULT_OPERATOR_VALUE }),
                value: currentValue.num,
              },
            ]
          : memo
      }, [])
    props.setAutoCompleteOptions(rangeOptionsFiltered)
  } else {
    props.setAutoCompleteOptions([])
  }
  props.setInputValue(input)
}

const onBlur = props => e => {
  props.inputValue.length && onKeyDown({ keyCode: ENTER, preventDefault: e.preventDefault }, props)
  props.setFocus(false)
  if (props.onBlurFn) {
    props.onBlurFn(props.value)
  }
}

const addValuesWithPressKey = (e, props) => {
  const [goods, services] = props.options
  const option = [...goods.options, ...services.options].find(
    y => y.num.toString() === props.inputValue
  )
  const checkExistsInValues = props.value.some(x => x.num.toString() === props.inputValue)
  props.inputValue.match(rangeRegex)
    ? !props.onChange([...props.value, ...props.autoCompleteOptions]) &&
      !props.setInputValue('') &&
      props.setAutoCompleteOptions([])
    : !checkExistsInValues &&
      option &&
      !props.onChange([
        ...props.value,
        { ...option, value: option.num, operator: DEFAULT_OPERATOR_VALUE },
      ]) &&
      props.setInputValue('')
}

const onKeyDown = (e, props) => {
  switch (e.keyCode) {
    case SPACE_TAB:
      e.preventDefault()
      break
    case ENTER:
      e.preventDefault()
      addValuesWithPressKey(e, props)
      break
    case SPACE:
      const containCodes = props.inputValue.match(codeRegex) || props.inputValue.match(rangeRegex)
      return containCodes
        ? !e.preventDefault() && addValuesWithPressKey(e, props)
        : props.inputValue.length && !containCodes
        ? props.inputValue
        : e.preventDefault()
    default:
      break
  }
}

const onChangeValue = ({ onChange, setNoNiceClass }) => values => {
  const isNoNiceClass = values.some(nice => nice.value && nice.value === EMPTY_VALUE)
  const valuesFiltered = values.length
    ? [{ num: values[0].num, info: values[0].info, value: values[0].value }, ...values.slice(1)]
    : []
  onChange && onChange(valuesFiltered)
  setNoNiceClass(isNoNiceClass)
}

const enhance = compose(
  withState('inputValue', 'setInputValue', ''),
  withState('infos', 'setInfos', {}),
  withState('expanded', 'setExpanded', false),
  withState('focus', 'setFocus', false),
  withState('autoCompleteOptions', 'setAutoCompleteOptions', []),
  withState('noNiceClass', 'setNoNiceClass', false),
  withHandlers({
    onExpanded: props => () => props.setExpanded(!props.expanded),
    onFocus: ({ setFocus }) => () => setFocus(true),
    onBlur,
    onChangeValue,
    onInputChange,
    onNoNiceClass,
  }),
  lifecycle({
    componentDidMount() {
      const infos = [...this.props.options[0].options, ...this.props.options[1].options]
      const infosInObject = infos.reduce(
        (acc, option) => ({ ...acc, [option.num]: option.info[0] }),
        {}
      )
      this.props.setInfos(infosInObject)
    },
  })
)

const Menu = ({ children, selectProps: { literals, ...selectProps }, ...props }) => {
  const { isMobile } = useResponsive()

  return (
    <components.Menu {...props}>
      {children}
      <BlockMenu justify padding>
        <Block middle>
          {isMobile ? (
            <Grid.Container>
              <Grid.Row>
                <Block auto marginRight>
                  <P>{literals.advanced_search_nice_class_text}</P>
                </Block>
              </Grid.Row>
              <br />
              <Grid.Row>
                <Link target="_blank" href={HREF_EUIPO}>
                  {literals.advanced_search_nice_class_link_click}
                </Link>
              </Grid.Row>
            </Grid.Container>
          ) : (
            <Fragment>
              <Block auto marginRight className="menu-info">
                <P>{literals.advanced_search_nice_class_text}</P>
              </Block>
              <Link target="_blank" href={HREF_EUIPO} className="menu-info">
                {literals.advanced_search_nice_class_link_click}
              </Link>
            </Fragment>
          )}
        </Block>

        <VerticalAlign isMobile={isMobile}>
          <StyledButton
            active={selectProps.noNiceClass}
            className="noWrap"
            isMobile={isMobile}
            onClick={() => selectProps.onNoNiceClass(props)}
          >
            {literals.advanced_search_nice_class_button_no_nice}
          </StyledButton>
        </VerticalAlign>
      </BlockMenu>
    </components.Menu>
  )
}

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon chevronDown />
  </components.DropdownIndicator>
)

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <ClearAllIcon close />
  </components.ClearIndicator>
)

const MenuList = ({ innerRef, innerProps, ...props }) => {
  return <components.MenuList {...props}>{props.children}</components.MenuList>
}

const Nice = props => (
  <Container data-tip data-for="niceTooltip">
    <BooleanSelect
      cacheOptions
      filterOption={filterOption}
      closeMenuOnSelect={false}
      components={{
        Control: CustomControl,
        IndicatorSeparator,
        Menu,
        MultiValueContainer: ValueWrapper,
        MultiValueLabel,
        Option: enhance(CustomOption),
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        MenuList,
      }}
      formatGroupLabel={x => formatGroupLabel(x, props)}
      inputValue={props.inputValue}
      hideSelectedOptions={false}
      isMulti
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onKeyDown={e => onKeyDown(e, props)}
      onInputChange={props.inputChange}
      styles={styles}
      {...props}
      onChange={props.onChangeValue}
      classNamePrefix={'react-select-comp'}
      clearRenderer={() => <Icon close />}
    />
  </Container>
)

export default enhance(withTheme(Nice))
