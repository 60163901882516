import { Checkbox as CheckboxLCT } from '@new-lucentum'
import { Field } from 'formik'
import React from 'react'

export default props => <Field {...props} component={Checkbox} />

const Checkbox = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { bindValue, resetFieldsOnChange, ...restProps } = props
  const hasBindValue = !!bindValue
  const checked = hasBindValue ? !!field.value : field.value
  const checkForDependencies = () => {
    Array.isArray(resetFieldsOnChange) &&
      resetFieldsOnChange.forEach(dependantField => {
        form.setStatus({ ...form.status, [dependantField]: null })
        form.setFieldValue(dependantField, form.initialValues[dependantField])
      })
  }
  return (
    <CheckboxLCT
      {...field}
      {...restProps}
      onChange={() => {
        let nextValue = false
        if (!!bindValue) {
          nextValue = !!field.value ? undefined : bindValue
        } else {
          nextValue = !field.value
        }
        form.setFieldValue(field.name, nextValue)
        !nextValue && checkForDependencies()
      }}
      checked={checked}
    />
  )
}
