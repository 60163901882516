import React from 'react'

const CertificateFileUpload = ({ onChange }) => {
  return (
    <input
      type="file"
      accept=".pdf"
      onChange={onChange}
      style={{ display: 'none' }}
      id="fileInput"
    />
  )
}

export default CertificateFileUpload
