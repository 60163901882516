import { toast, Slide } from 'react-toastify'
import { ToastWrapper } from '@app/components/ToastWrapper/ToastWrapper'
import { enumType } from './tmdsToastService.constants'
import React from 'react'

const defaultOptions = {
  position: toast.POSITION.TOP_CENTER,
  pauseOnHover: true,
  autoClose: false,
  hideProgressBar: true,
  className: enumType.INFO,
  onOpen: toastComponentProps => {},
  onClose: toastComponentProps => {},
  transition: Slide,
  draggable: false,
  closeButton: true,
}

const defaultToastOptions = { ...defaultOptions, autoClose: 5000, closeButton: false }

const showToast = (toastMessage, toastOptions = {}) => {
  const preparedToastOptions = {
    ...defaultToastOptions,
    ...toastOptions,
  }
  toast(<ToastWrapper type={enumType.INFO}>{toastMessage}</ToastWrapper>, preparedToastOptions)
}

const showNotification = (toastMessage, toastOptions = {}, type = enumType.INFO) => {
  const preparedToastOptions = {
    ...defaultOptions,
    ...toastOptions,
    className: type,
  }
  toast(<ToastWrapper type={type}>{toastMessage}</ToastWrapper>, preparedToastOptions)
}

const showNotificationInfo = (toastMessage, notificationOptions) => {
  showNotification(toastMessage, notificationOptions, enumType.INFO)
}

const showNotificationSuccess = (toastMessage, notificationOptions) => {
  showNotification(toastMessage, notificationOptions, enumType.SUCCESS)
}

const showNotificationWarning = (toastMessage, notificationOptions) => {
  showNotification(toastMessage, notificationOptions, enumType.WARNING)
}

const showNotificationError = (toastMessage, notificationOptions) => {
  showNotification(toastMessage, notificationOptions, enumType.ERROR)
}

const isActive = id => {
  return toast.isActive(id)
}

const update = (id, options) => {
  toast.update(id, options)
}

export const TMDSToastService = {
  showToast,
  showNotificationInfo,
  showNotificationSuccess,
  showNotificationWarning,
  showNotificationError,
  isActive,
  update,
  POSITION: toast.POSITION,
}
