import { LoginRequiredModal } from '@app/common/LoginRequiredModal'
import { TMDSAlertsService, TMDSAuthService, TMDSGlobalsService } from '@app/common/services'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t } from '@app/common/translationsService'
import { ROUTES } from '@app/containers/App/routes'
import ModalMobile from '@app/new-lucentum/components/Modal/Modal.mobile'
import { Button, Flex, Grid } from '@new-lucentum'
import React, { Fragment } from 'react'
import { compose, lifecycle, shallowEqual, shouldUpdate, withHandlers, withState } from 'recompose'

import { StyledModalHeader } from './AlertButton.mobile.styles'
import { AlertDropdownMenuMobile } from './components'
import { checkForTriggeredValues, prepareFieldsValues } from './utils'

const AlertButtonMobileComponent = props => {
  const {
    st13List,
    noBadge,
    resourceData,
    isDisabled = false,
    preloadedValues,
    setPreloadedValues,
    applyDisabled,
    setApplyDisabled,
    onSetAlertSuccess,
    setBadgeNumber,
    resetOnSubmit = false,
    inheritPosition,
    title,
    badgeNumber,
  } = props

  const {
    alertsConfig: { maxAlertsCreation },
  } = TMDSGlobalsService.get()

  const hasNoSt13 = Array.isArray(st13List) && st13List.length < 1
  const hasMoreSt13ThanAllowed = Array.isArray(st13List) && st13List.length > maxAlertsCreation
  const isHidden = !TMDSAuthService.isLogged || hasNoSt13 || hasMoreSt13ThanAllowed || isDisabled
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const onClick = () => TMDSRouterService.navigate(ROUTES.ALERTS)

  const prepareModalTitle = () => {
    return (
      <StyledModalHeader>
        <Flex justify="space-between" flex="1">
          <div className="modal-title">{t('alert.column.key')}</div>
          <div>
            <Button
              borderless
              data-test-id="alerts-button"
              isBlue={true}
              onClick={onClick}
              title="Go to Alerts page"
            />
          </div>
        </Flex>
      </StyledModalHeader>
    )
  }

  const prepareButtonText = () => {
    return badgeNumber ? `${title} (${badgeNumber})` : title
  }

  return !isHidden ? (
    <Fragment>
      <Button
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        {prepareButtonText()}
      </Button>
      {isModalOpen && (
        <ModalMobile
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          showCloseButton={false}
          customTitle={prepareModalTitle()}
        >
          <AlertDropdownMenuMobile
            preloadedValues={preloadedValues}
            setPreloadedValues={setPreloadedValues}
            st13List={st13List}
            applyDisabled={applyDisabled}
            resourceData={resourceData}
            setIsModalOpen={setIsModalOpen}
            onSetAlertSuccess={onSetAlertSuccess}
            setApplyDisabled={setApplyDisabled}
            setBadgeNumber={setBadgeNumber}
            resetOnSubmit={resetOnSubmit}
            inheritPosition={inheritPosition}
            noBadge={noBadge}
          />
        </ModalMobile>
      )}
    </Fragment>
  ) : (
    <Fragment>
      <Button
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        {prepareButtonText()}
      </Button>
      {isModalOpen && (
        <LoginRequiredModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          title={`<b>${t('alert.column.key')}</b> ${t('mobile_functionality_loggedIn_users')}`}
        />
      )}
    </Fragment>
  )
}

export const AlertButtonMobile = compose(
  withState('applyDisabled', 'setApplyDisabled', true),
  withState('preloadedValues', 'setPreloadedValues', false),
  withState('badgeNumber', 'setBadgeNumber', 0),
  withHandlers({
    handleExternalValues: props => () => {
      const {
        externalValues,
        noBadge,
        setBadgeNumber,
        setPreloadedValues,
        setApplyDisabled,
      } = props
      if (TMDSAuthService.isLogged) {
        const preparedValues = prepareFieldsValues(externalValues)
        const triggeredValues = checkForTriggeredValues(preparedValues)
        triggeredValues > 0 && setApplyDisabled(false)

        if (!noBadge) {
          setBadgeNumber(triggeredValues)
        }
        setPreloadedValues && setPreloadedValues(preparedValues)
      }
    },
    handlePreloadedValues: props => () => {
      const { preload, st13List, noBadge } = props
      const { setPreloadedValues, setBadgeNumber, setApplyDisabled } = props
      TMDSAuthService.isLogged &&
        preload &&
        Array.isArray(st13List) &&
        st13List.length === 1 &&
        TMDSAlertsService.getAlertsByIds(st13List).then(response => {
          const alerts = response && response.value
          Array.isArray(alerts) &&
            alerts.forEach(alert => {
              const preparedValues = prepareFieldsValues(alert)
              const triggeredValues = checkForTriggeredValues(preparedValues)
              triggeredValues > 0 && setApplyDisabled(false)
              if (!noBadge) {
                setBadgeNumber(triggeredValues)
              }
              setPreloadedValues && setPreloadedValues(preparedValues)
            })
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { preload, externalValues } = this.props
      const { handlePreloadedValues, handleExternalValues } = this.props
      preload && handlePreloadedValues()
      externalValues && handleExternalValues()
    },
    componentDidUpdate(prevProps) {
      const { externalValues } = this.props
      const { handleExternalValues } = this.props
      if (externalValues && !shallowEqual(prevProps.externalValues, externalValues)) {
        handleExternalValues()
      }
    },
  }),
  shouldUpdate((props, nextProps) => {
    return (
      !shallowEqual(props.buttonProps, nextProps.buttonProps) ||
      !shallowEqual(props.st13List, nextProps.st13List) ||
      !shallowEqual(props.preloadedValues, nextProps.preloadedValues) ||
      !shallowEqual(props.externalValues, nextProps.externalValues) ||
      props.badgeNumber !== nextProps.badgeNumber
    )
  })
)(AlertButtonMobileComponent)
