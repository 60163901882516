import { Field, Form } from '@app/common/formManagement'
import { AUTH_TYPES } from '@app/redux-types'
import {
  setFieldValues,
  setInvalidFieldKey,
  validateEntireForm,
  validateIndividualState,
} from '@app/common/utilities'

const defaultErrors = {
  email: { isValid: true, errorMessage: '' },
  password: { isValid: true, errorMessage: '' },
}

const defaultForm = {
  email: '',
  password: '',
}

const initForm = () =>
  new Form()
    .addField(new Field('email', null, defaultForm.email))
    .addField(new Field('password', null, defaultForm.password))

const form = initForm()

const defaultState = {
  formState: {
    form,
    backEndErrorMessage: {
      error: false,
      errorMessage: '',
      errorDetails: [],
    },
    fields: {
      ...defaultForm,
    },
    isValid: true,
    errors: {
      ...defaultErrors,
    },
  },
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'EMPTY_LOGIN_FIELDS':
      return defaultState
    case AUTH_TYPES.FETCH_LOGIN_FULFILLED:
      return {
        ...state,
        successMessage: true,
      }
    case AUTH_TYPES.FETCH_LOGIN_REJECTED:
      return {
        ...state,
        formState: {
          ...state.formState,
          backEndErrorMessage: {
            ...state.formState.backEndErrorMessage,
            error: true,
          },
        },
      }
    case 'SET_INVALID_LOGIN_FIELD':
      return validateEntireForm(setInvalidFieldKey(state, payload.fieldKey, payload.errorMessage))
    case 'CHANGE_LOGIN_FIELD':
      return validateEntireForm(
        validateIndividualState(setFieldValues(payload, state), payload.field)
      )
    default:
      return state
  }
}
