const defaultState = {
  data: {},
  fetching: false,
  fetched: false,
  error: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_APPLICATION_OFFICES_PENDING':
      return { ...state, fetching: true, fetched: false, error: null }
    case 'FETCH_APPLICATION_OFFICES_REJECTED':
      return { ...state, fetching: false, fetched: false, error: action.payload }
    case 'FETCH_APPLICATION_OFFICES_FULFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: action.payload,
      }
    default:
      return state
  }
}
