import { fullfilled, pending, rejected } from './utils/reduxPromise'

const FETCH_SEARCH = 'FETCH_SEARCH'
const FETCH_DETAIL_EXPANDED = 'FETCH_DETAIL_EXPANDED'
const FETCH_NEWS = 'FETCH_NEWS'
const CLEAR_RESULTS = 'CLEAR_RESULTS'
const CHANGE_LAST_APPLIED_FILTER = 'CHANGE_LAST_APPLIED_FILTER'
const FETCH_DETAILS_LIST = 'FETCH_DETAILS_LIST'
const FETCH_FILTER = 'FETCH_FILTER'
const FETCH_SPECIAL_FILTERS = 'FETCH_SPECIAL_FILTERS'
export const MOBILE_FILTERS = 'MOBILE_FILTERS_CHANGED'
export const FORM_IS_TOUCHED = 'FORM_IS_TOUCHED'

export const RESULTS_TYPES = {
  FETCH_SEARCH,
  FETCH_SEARCH_FULFILLED: fullfilled(FETCH_SEARCH),
  FETCH_SEARCH_PENDING: pending(FETCH_SEARCH),
  FETCH_SEARCH_REJECTED: rejected(FETCH_SEARCH),
  FETCH_DETAIL_EXPANDED,
  FETCH_DETAIL_EXPANDED_FULFILLED: fullfilled(FETCH_DETAIL_EXPANDED),
  FETCH_DETAIL_EXPANDED_PENDING: pending(FETCH_DETAIL_EXPANDED),
  FETCH_DETAIL_EXPANDED_REJECTED: rejected(FETCH_DETAIL_EXPANDED),
  FETCH_NEWS,
  FETCH_NEWS_FULFILLED: fullfilled(FETCH_NEWS),
  FETCH_NEWS_PENDING: pending(FETCH_NEWS),
  FETCH_NEWS_REJECTED: rejected(FETCH_NEWS),
  CLEAR_RESULTS,
  CHANGE_LAST_APPLIED_FILTER,
  FETCH_DETAILS_LIST,
  FETCH_FILTER,
  FETCH_FILTER_FULFILLED: fullfilled(FETCH_FILTER),
  FETCH_FILTER_PENDING: pending(FETCH_FILTER),
  FETCH_FILTER_REJECTED: rejected(FETCH_FILTER),
  FETCH_SPECIAL_FILTERS,
  FETCH_SPECIAL_FILTERS_FULFILLED: fullfilled(FETCH_SPECIAL_FILTERS),
  FETCH_SPECIAL_FILTERS_PENDING: pending(FETCH_SPECIAL_FILTERS),
  FETCH_SPECIAL_FILTERS_REJECTED: rejected(FETCH_SPECIAL_FILTERS),
}
