import {
  KEY_APP_NAME,
  KEY_APPLICANT_NAME,
  KEY_APPLICATION_NUMBER,
  KEY_BASIC_SEARCH,
  KEY_CRITERIA,
  KEY_CURRENTLY_OPPOSABLE,
  KEY_DESIGN_NUMBER,
  KEY_DESIGN_STATUS,
  KEY_DESIGNER_NAME,
  KEY_EXHIBITION_PRIORITY,
  KEY_INDICATION_OF_PRODUCT,
  KEY_LOCARNO_CLASS,
  KEY_NICE_CLASS,
  KEY_OFFICES,
  KEY_PREVIOUS_APPLICANT_NAME,
  KEY_PRIORITY_COUNTRY,
  KEY_PRIORITY_NUMBER,
  KEY_REGISTRATION_NUMBER,
  KEY_REPRESENTATIVE_NAME,
  KEY_SENIORITY_CLAIMED,
  KEY_TERRITORIES,
  KEY_TM_STATUS,
  KEY_TM_TYPE,
  KEY_VERBAL_ELEMENT,
  KEY_VIENNA_CODE,
} from './constants'

const COMMON_FIELDS = {
  APPLICATION_NUMBER: KEY_APPLICATION_NUMBER,
  OFFICES: KEY_OFFICES,
  REGISTRATION_DATE_FROM: 'rdFrom',
  REGISTRATION_DATE_TO: 'rdTo',
  SEARCH_CRITERIA: KEY_CRITERIA,
  TERRITORIES: KEY_TERRITORIES,
  PAGE: 'page',
  PAGE_SIZE: 'pageSize',
  SORT_COLUMN_ID: 'sortColumn',
  SORT_COLUMN_ORDER: 'desc',
}

const IMAGE_SEARCH_QUERY_PARAMS = {
  IMAGE_ID: 'imageId',
  IMAGE_NAME: 'imageName',
  SEGMENT_LEFT: 'segmentLeft',
  SEGMENT_RIGHT: 'segmentRight',
  SEGMENT_TOP: 'segmentTop',
  SEGMENT_BOTTOM: 'segmentBottom',
  COLOUR: 'colour',
  OCR: 'ocr',
  FREE_SELECTION_POINTS: 'freeSelectionPoints',
}

const TMVIEW_FIELDS = {
  APPLICANT_NAME: KEY_APP_NAME,
  APPLICATION_DATE_FROM: 'adFrom',
  APPLICATION_DATE_TO: 'adTo',
  BASIC_SEARCH: KEY_BASIC_SEARCH,
  CURRENTLY_OPPOSABLE: KEY_CURRENTLY_OPPOSABLE,
  IMAGE_SEARCH: 'imageSearch',
  GOODS_AND_SERVICES: KEY_NICE_CLASS,
  REGISTRATION_NUMBER: KEY_REGISTRATION_NUMBER,
  SENIORITY_CLAIMED: KEY_SENIORITY_CLAIMED,
  TRADEMARK_STATUS: KEY_TM_STATUS,
  TRADEMARK_TYPE: KEY_TM_TYPE,
  VIENNA_CODE_VERSION: 'viennaCodeVersion',
  VIENNA_CODE: KEY_VIENNA_CODE,
  ...IMAGE_SEARCH_QUERY_PARAMS,
}

const DSVIEW_FIELDS = {
  DESIGN_NUMBER: KEY_DESIGN_NUMBER,
  DESIGN_STATUS: KEY_DESIGN_STATUS,
  DESIGNER_NAME: KEY_DESIGNER_NAME,
  EFFECTIVE_DATE_FROM: 'effectiveFrom',
  EFFECTIVE_DATE_TO: 'effectiveTo',
  END_DEFERMENT_DATE_FROM: 'endDefermentFrom',
  END_DEFERMENT_DATE_TO: 'endDefermentTo',
  EXHIBITION_PRIORITY: KEY_EXHIBITION_PRIORITY,
  EXPIRY_DATE_FROM: 'expiryFrom',
  EXPIRY_DATE_TO: 'expiryTo',
  FILING_DATE_FROM: 'filingFrom',
  FILING_DATE_TO: 'filingTo',
  INDICATION_OF_PRODUCT: KEY_INDICATION_OF_PRODUCT,
  LOCARNO: KEY_LOCARNO_CLASS,
  OWNER_NAME: KEY_APPLICANT_NAME,
  PREVIOUS_OWNER_NAME: KEY_PREVIOUS_APPLICANT_NAME,
  PRIORITY_COUNTRY: KEY_PRIORITY_COUNTRY,
  PRIORITY_DATE_FROM: 'priorityFrom',
  PRIORITY_DATE_TO: 'priorityTo',
  PRIORITY_NUMBER: KEY_PRIORITY_NUMBER,
  PUBLICATION_DATE_FROM: 'publicationFrom',
  PUBLICATION_DATE_TO: 'publicationTo',
  REPRESENTATIVE_NAME: KEY_REPRESENTATIVE_NAME,
  VERBAL_ELEMENT: KEY_VERBAL_ELEMENT,
  IMAGE_SEARCH: 'imageSearch',
  ...IMAGE_SEARCH_QUERY_PARAMS,
}

export const SEARCH_QUERY_PARAMS = {
  ...COMMON_FIELDS,
  ...TMVIEW_FIELDS,
  ...DSVIEW_FIELDS,
}
