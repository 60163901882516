import { fetchDetail } from '@app/actions/detail'
import { t } from '@app/common/translationsService'
import { Icon } from '@new-lucentum'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StyledExpandedView, TableCol, TableRow } from './styledHistoryLayoutData'

export const ExpandedHistoryView = props => {
  const dispatch = useDispatch()

  const [showId, setShowId] = useState(false)
  const details = useSelector(state => state.appState.detail.data)

  useEffect(() => {
    dispatch(fetchDetail(props.match.params.id))
  }, [])

  let data = props.selectedRow

  return (
    <StyledExpandedView>
      {data ? (
        <span>
          <TableCol>
            <TableRow>
              <TableCol className="verbalText"> {details.tradeMark?.tmName}</TableCol>
            </TableRow>
          </TableCol>
          <TableRow>
            <TableCol className="column">
              <TableRow>
                <TableCol>{t('applicationdate.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.applicationDate && moment(data.applicationDate).format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('advanced_search_expiry_date_key')}</TableCol>
                <TableCol className="data-col">
                  {data?.expiryDate && moment(data.expiryDate).format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('mark.feature.type.key')}</TableCol>
                <TableCol className="data-col">{data?.featureType}</TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('seniority.indicator.key')}</TableCol>
                <TableCol className="data-col">{data?.seniorityIndicatorTranslated}</TableCol>
              </TableRow>
              {data?.eventName && (
                <TableRow>
                  <TableCol>{t('history.event.name')}</TableCol>
                  <TableCol className="data-col">{data?.eventName}</TableCol>
                </TableRow>
              )}
              {data?.eventDate && (
                <TableRow>
                  <TableCol>{t('history.event.date')}</TableCol>
                  <TableCol className="data-col">
                    {data?.eventDate && moment(data.eventDate).format('DD/MM/YYYY')}
                  </TableCol>
                </TableRow>
              )}
              <TableRow>
                <TableCol>{t('class.number.key')}</TableCol>
                <TableCol className="data-col">{data?.classNumber}</TableCol>
              </TableRow>
            </TableCol>
            <TableCol className="column">
              <TableRow>
                <TableCol>{t('tm.details.label.oppositionPeriodStartDate.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.oppositionStartDate &&
                    moment(data.oppositionStartDate).format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('tm.details.label.oppositionPeriodEndDate.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.oppositionEndDate && moment(data.oppositionEndDate).format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('registration.date.detail.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.registrationDate && moment(data.registrationDate).format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('tm.details.label.registrationNumber.key')}</TableCol>
                <TableCol className="data-col">{data?.registrationNumber}</TableCol>
              </TableRow>
            </TableCol>
          </TableRow>
          <TableRow />
          <TableRow>
            <TableCol>
              <TableRow className="header-color" id="history-id-data">
                <TableCol>
                  {t('history.id.key')}
                  <Icon className="chevron" chevronRight onClick={() => setShowId(!showId)} />
                  {showId && Object.entries(data)[0][1]}
                </TableCol>
              </TableRow>
            </TableCol>
          </TableRow>
        </span>
      ) : (
        <div className="null-data-text">{t('null.data.key')}</div>
      )}
    </StyledExpandedView>
  )
}

export default ExpandedHistoryView
