import styled, { css } from 'styled-components'

const prepareValue = value => {
  const parsedNumber = Number(value)
  return Number.isNaN(parsedNumber) ? 1 : parsedNumber
}

const prepareRowGroupCss = pixelDistance => css`
  > *:not(:last-child) {
    margin-right: ${pixelDistance}px;
  }

  > *:last-child {
    margin-right: 0;
  }
`

const prepareColumnGroupCss = pixelDistance => css`
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: ${pixelDistance}px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

const StyledGroup = styled.div`
  display: flex;
  ${({ column, distance, theme }) => {
    const preparedDistance = prepareValue(distance)
    const pixelDistance = preparedDistance * theme.sizeBase
    return column ? prepareColumnGroupCss(pixelDistance) : prepareRowGroupCss(pixelDistance)
  }}
`

export const Group = StyledGroup
