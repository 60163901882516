import { getLiterals } from '@app/common/translationsService'
import { Nice } from '@app/components/Boolean'
import { Field } from 'formik'
import React from 'react'

export default props => <Field {...props} component={NiceField} />

const NiceField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <Nice
      {...field}
      {...props}
      getOptionLabel={option => option.num}
      getOptionValue={option => option.num}
      literals={getLiterals()}
      error={errors[field.name]}
      touched={touched[field.name]}
      onChange={value => {
        setFieldValue(field.name, value)
      }}
      onBlurFn={value => {
        setFieldTouched(field.name, true)
      }}
    />
  )
}
