import React from 'react'
import ContentLoader from 'react-content-loader'

export default () => (
  <ContentLoader height={460} width={703} speed={1} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
    <rect x="-1" y="0" rx="0" ry="0" width="702" height="33" />
    <rect x="147" y="100" rx="0" ry="0" width="423" height="28" />
    <rect x="109" y="100" rx="0" ry="0" width="35" height="28" />
    <rect x="109" y="201" rx="0" ry="0" width="460" height="76" />
    <rect x="109" y="301" rx="0" ry="0" width="221" height="137" />
    <rect x="350" y="301" rx="0" ry="0" width="222" height="139" />
    <rect x="388" y="220" rx="0" ry="0" width="0" height="0" />
  </ContentLoader>
)
