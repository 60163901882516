import styled from 'styled-components'

import Block from '../Block'

export const Cookie = styled(Block)`
  background-color: ${({ theme }) => theme.colorGray};
  color: ${({ theme }) => theme.colorWhite};
  i {
    font-size: ${({ theme }) => theme.sizeDouble};
  }
  a {
    color: ${({ theme }) => theme.colorWhite};
  }
  button {
    color: ${({ theme }) => theme.colorWhite};
    &,
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colorTransparent};
    }
  }
`
