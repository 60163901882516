import React from 'react'
import ContentLoader from 'react-content-loader'
import { FilterBulletList, BulletListFilters } from './styles'
import SkeletonFilterCheckbox from './SkeletonFilterCheckbox'

const BULLET_LIST_ITEMS = 6

const ListTitle = () => (
  <ContentLoader height={18} width={210} speed={1} primaryColor="#ecebeb" secondaryColor="#d4d4d4">
    <rect x="0" y="0" rx="5" ry="2" width="120" height="15" />
  </ContentLoader>
)

export default ({ itemsToShow = BULLET_LIST_ITEMS }) => (
  <FilterBulletList>
    {[...Array(itemsToShow)].map((x, i) => (
      <BulletListFilters key={i}>
        <div className="skeleton-filter-title">
          <ListTitle />
        </div>
        <SkeletonFilterCheckbox />
        <SkeletonFilterCheckbox />
        <SkeletonFilterCheckbox />
      </BulletListFilters>
    ))}
  </FilterBulletList>
)
