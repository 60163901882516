import { ROUTES } from '@app/containers/App/routes'

export const TITLE = {
  [ROUTES.HOME]: {
    tmview: 'TMView',
    dsview: 'DSView',
  },
}
export const META = {
  [ROUTES.HOME]: {
    tmview: { description: 'TMView' },
    dsview: { description: 'DSView' },
  },
}
