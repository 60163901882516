import TMDSContextService from '@app/common/tmdsContextService'
import isEqual from 'lodash.isequal'
import moment from 'moment'

import { searchDateFormat } from './utilities'

const TM_LAST_SEARCHES_KEY = 'lastSearches'
const DS_LAST_SEARCHES_KEY = 'dsLastSearches'
const SHOWN_COLUMNS_KEY = {
  tmview: 'shownColumns',
  dsview: 'shownColumnsDS',
}
const ORDER_COLUMNS_KEY = {
  tmview: 'orderColumns',
  dsview: 'orderColumnsDS',
}

const ORDER_FILTERS_KEY = {
  tmview: 'orderFilters',
  dsview: 'orderFiltersDS',
}

export const SEARCH_QUERY_KEY = 'tmviewSearchQuery'

const storeInLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value))
const getFromLocalStorage = key => JSON.parse(localStorage.getItem(key)) || []

export const getLastSearches = context => {
  const lastSearchKey = context === 'dsview' ? DS_LAST_SEARCHES_KEY : TM_LAST_SEARCHES_KEY
  return (
    getFromLocalStorage(lastSearchKey).map(search => ({
      ...search,
      searchValues: {
        ...search.searchValues,
        adFrom: searchDateFormat(search.searchValues.adFrom),
        adTo: searchDateFormat(search.searchValues.adTo),
        rdFrom: searchDateFormat(search.searchValues.rdFrom),
        rdTo: searchDateFormat(search.searchValues.rdTo),
        expiryFrom: searchDateFormat(search.searchValues.expiryFrom),
        expiryTo: searchDateFormat(search.searchValues.expiryTo),
        applicationFilingFrom: searchDateFormat(search.searchValues.applicationFilingFrom),
        applicationFilingTo: searchDateFormat(search.searchValues.applicationFilingTo),
        publicationFrom: searchDateFormat(search.searchValues.publicationFrom),
        publicationTo: searchDateFormat(search.searchValues.publicationTo),
        priorityFrom: searchDateFormat(search.searchValues.priorityFrom),
        priorityTo: searchDateFormat(search.searchValues.priorityTo),
        effectiveFrom: searchDateFormat(search.searchValues.effectiveFrom),
        effectiveTo: searchDateFormat(search.searchValues.effectiveTo),
        endOfDefermentFrom: searchDateFormat(search.searchValues.endOfDefermentFrom),
        endOfDefermentTo: searchDateFormat(search.searchValues.endOfDefermentTo),
      },
    })) || []
  )
}

export const getLastSearchesFormik = () => {
  const key = getLastSearchesKey()
  return getFromLocalStorage(key)
}

const useLocalStorage = (get, context, type, value) =>
  get ? getFromLocalStorage(type[context]) || [] : storeInLocalStorage(type[context], value)

export const getShownColumns = context => useLocalStorage(true, context, SHOWN_COLUMNS_KEY)
export const getLocalStorageOrderColumns = context =>
  useLocalStorage(true, context, ORDER_COLUMNS_KEY)
export const setShownColumns = (value, context) =>
  useLocalStorage(false, context, SHOWN_COLUMNS_KEY, value)
export const setLocalStorageOrderColumns = (value, context) =>
  useLocalStorage(false, context, ORDER_COLUMNS_KEY, value)
export const getLocalStorageOrderFilters = context =>
  useLocalStorage(true, context, ORDER_FILTERS_KEY)
export const setLocalStorageOrderFilters = (value, context) =>
  useLocalStorage(false, context, ORDER_FILTERS_KEY, value)

const searchesAreDifferentByDay = (lastSearches, { searchDate, searchValues }) =>
  !lastSearches
    .filter(search => searchDateFormat(search.searchDate) === searchDateFormat(searchDate))
    .some(search => isEqual(search.searchValues, searchValues))

const sortTextList = textList =>
  textList
    ? textList
        .split(',')
        .sort()
        .join(',')
    : ''

const isToday = date => {
  return moment(date).isSame(new Date(), 'day')
}

const getLastSearchesKey = () =>
  TMDSContextService.isDsview() ? 'dsLastSearchesList' : 'tmLastSearchesList'

export const addLastSearchFormik = (values, query, maxLastSearches) => {
  const preparedSearch = {
    searchDate: new Date().toISOString(),
    searchValues: values,
    searchQuery: query,
  }
  const key = getLastSearchesKey()
  const currentLastSearches = getFromLocalStorage(key)
  const currentDaySearches = currentLastSearches.filter(
    search => isToday(search.searchDate) && !(search.searchQuery === preparedSearch.searchQuery)
  )
  const otherDaysSearches = currentLastSearches.filter(search => !isToday(search.searchDate))
  const nextLastSearches = [preparedSearch, ...currentDaySearches, ...otherDaysSearches]
  storeInLocalStorage(key, nextLastSearches.slice(0, maxLastSearches))
}

export const moveLastSearchToTop = index => {
  const key = getLastSearchesKey()
  const currentLastSearches = getFromLocalStorage(key)
  const targetSearch = currentLastSearches[index]
  targetSearch.searchDate = new Date().toISOString()
  const restSearches = currentLastSearches.filter((search, i) => i !== index)
  const nextLastSearches = [targetSearch, ...restSearches]
  storeInLocalStorage(key, nextLastSearches)
}

export const addLastSearch = (searchValues, maxLastSearches, context) => {
  const lastSearchKey = TMDSContextService.isDsview() ? DS_LAST_SEARCHES_KEY : TM_LAST_SEARCHES_KEY
  const objSearch = {
    searchDate: new Date().toISOString(),
    searchValues: {
      basicSearch: searchValues.basicSearch,
      criteria: searchValues.criteria,
      applicationNumber: searchValues.applicationNumber,
      registrationNumber: searchValues.registrationNumber,
      territories: sortTextList(searchValues.territories),
      offices: sortTextList(searchValues.offices),
      niceClass: searchValues.niceClass,
      viennaCode: searchValues.viennaCode,
      tmStatus: sortTextList(searchValues.tmStatus),
      tmType: sortTextList(searchValues.tmType),
      appName: decodeURIComponent(searchValues.appName || ''),
      adFrom: searchDateFormat(searchValues.adFrom),
      adTo: searchDateFormat(searchValues.adTo),
      rdFrom: searchDateFormat(searchValues.rdFrom),
      rdTo: searchDateFormat(searchValues.rdTo),
      expiryFrom: searchDateFormat(searchValues.expiryFrom),
      expiryTo: searchDateFormat(searchValues.expiryTo),
      applicationFilingFrom: searchDateFormat(searchValues.applicationFilingFrom),
      applicationFilingTo: searchDateFormat(searchValues.applicationFilingTo),
      publicationFrom: searchDateFormat(searchValues.publicationFrom),
      publicationTo: searchDateFormat(searchValues.publicationTo),
      priorityFrom: searchDateFormat(searchValues.priorityFrom),
      priorityTo: searchDateFormat(searchValues.priorityTo),
      effectiveFrom: searchDateFormat(searchValues.effectiveFrom),
      effectiveTo: searchDateFormat(searchValues.effectiveTo),
      endOfDefermentFrom: searchDateFormat(searchValues.endOfDefermentFrom),
      endOfDefermentTo: searchDateFormat(searchValues.endOfDefermentTo),
      currentlyOpposable: searchValues.currentlyOpposable,
      seniorityClaimed: searchValues.seniorityClaimed,
    },
  }
  const lastSearches = getLastSearches(context)
  if (!lastSearches.length || searchesAreDifferentByDay(lastSearches, objSearch)) {
    lastSearches.unshift(objSearch)
    storeInLocalStorage(lastSearchKey, lastSearches.slice(0, maxLastSearches))
  } else {
    const index = lastSearches.findIndex(item => isEqual(item.searchValues, objSearch.searchValues))
    lastSearches.splice(index, 1)
    lastSearches.unshift(objSearch)
    storeInLocalStorage(lastSearchKey, lastSearches.slice(0, maxLastSearches))
  }
}

export const moveLastSearchToFirstPosition = (index, maxLastSearches, context) => {
  const lastSearchKey = context === 'dsview' ? DS_LAST_SEARCHES_KEY : TM_LAST_SEARCHES_KEY
  const lastSearches = getLastSearches(context)
  lastSearches[index].searchDate = new Date().toISOString()
  lastSearches.splice(0, 0, lastSearches.splice(index, 1)[0])
  storeInLocalStorage(lastSearchKey, lastSearches.slice(0, maxLastSearches))
}
