/*Fade out*/
.animation-fade-out-appear {
    opacity: 1;
}

.animation-fade-out-appear.animation-fade-out-appear-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.animation-fade-out-enter {
    opacity: 1;
}

.animation-fade-out-enter.animation-fade-out-enter-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.animation-fade-out-leave {
    opacity: 1;
}

.animation-fade-out-leave.animation-fade-out-leave-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}
