import React from 'react'
import { connect } from 'react-redux'
import { Block } from '@new-lucentum'

const mapStateToProps = ({
  literals: {
    data: { detail_tm_no_information: literalDetailTMNoInformation },
  },
}) => ({ literalDetailTMNoInformation })

const NoInformationComponent = ({ literalDetailTMNoInformation, ...props }) => (
  <Block {...props}>{literalDetailTMNoInformation}</Block>
)

export const NoInformation = connect(mapStateToProps)(NoInformationComponent)

export default NoInformation
