import React from 'react'
import ContentLoader from 'react-content-loader'

export default ({ height = 10, width = 135 }) => {
  return (
    <div style={{ width, height, display: 'flex' }}>
      <ContentLoader
        height={height}
        width={width}
        speed={1}
        primaryColor="#d4d4d4"
        secondaryColor="#ecebeb"
      >
        <rect x="5" y={0} rx="5" ry="5" width="20" height={height} />
        <rect x="30" y={0} rx="5" ry="5" width="15" height={height} />
        <rect x="50" y={0} rx="5" ry="5" width="60" height={height} />
        <rect x="115" y={0} rx="5" ry="5" width="15" height={height} />
      </ContentLoader>
    </div>
  )
}
