import { searchDataManager } from '@app/common/dataManagers'
import { IMAGE_SEARCH_TYPES } from '@app/redux-types'

export const uploadImage = file => ({
  type: IMAGE_SEARCH_TYPES.UPLOAD_IMAGE_SEARCH,
  payload: searchDataManager.uploadImageSearch(file),
})

export const abortableUploadImage = file => dispatch => {
  const promise = searchDataManager.abortableUploadImageSearch(file).ready()
  dispatch({
    type: IMAGE_SEARCH_TYPES.UPLOAD_IMAGE_SEARCH,
    payload: promise,
  })

  return { promise, abort: searchDataManager.abortableUploadImageSearch(file).abort }
}

export const restoreImageSearch = payload => ({
  type: IMAGE_SEARCH_TYPES.RESTORE_IMAGE_SEARCH,
  payload,
})

export const getTrademarkImageForSearch = ST13 => ({
  type: IMAGE_SEARCH_TYPES.UPLOAD_IMAGE_SEARCH,
  payload: searchDataManager.getResourceImageForSearch(ST13),
})

export const emptyImageSearchApp = () => ({
  type: 'EMPTY_IMAGE_SEARCH_APP',
})

export const openImageSearch = () => ({
  type: 'OPEN_IMAGE_SEARCH',
})

export const closeImageSearch = () => ({
  type: 'CLOSE_IMAGE_SEARCH',
})

export const updateImageSearchUiData = payload => ({
  type: 'CHANGE_IMAGE_SEARCH_UIDATA',
  payload: payload,
})

export const emptyImageSearchUI = () => ({
  type: 'EMPTY_IMAGE_SEARCH_UI',
})

export const changeResetImage = () => ({
  type: 'NOT_RESET_IMAGE',
})

export const setResetImage = () => ({
  type: IMAGE_SEARCH_TYPES.SET_RESET_IMAGE,
})

export const changeImageSelectionMode = selectionMode => ({
  type: IMAGE_SEARCH_TYPES.CHANGE_SELECTION_MODE,
  payload: selectionMode,
})

export const setImageData = data => ({
  type: IMAGE_SEARCH_TYPES.SET_IMAGE_DATA,
  payload: data,
})

export const clearImageData = () => ({
  type: IMAGE_SEARCH_TYPES.CLEAR_IMAGE_DATA,
})
