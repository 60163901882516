export const CONTEXT_ROUTE = '/:context(tmview|dsview)'

export const ROUTES = {
  HOME: '',
  RESULTS: '/results',
  NEW_RESULTS: '/newresults',
  DETAILS: '/detail/:id',
  COMPARE: '/compare',
  TAGS: '/tags',
  ALERTS: '/alerts',
  BOOKMARK: '/bookmark',
  NEWS: '/news',
  OFFICES: '/participating',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgotpassword',
  CHANGE_PASSWORD: '/changepassword',
  ALERTS_HISTORY: '/alerts/:id',
  NOTIFICATIONS: '/notifications',
  SAVED_SEARCHES: '/savedSearches',
  SAVED_SEARCH_EVENTS: '/savedSearches/:id',
  HELP: '/help',
  DATA_PROTECTION: '/data-protection',
  TM_HISTORY: '/history/:id',
  ABOUT: '/about',
  DISCLAIMER: '/disclaimer',
}

export const ROUTES_WITH_CONTEXT = {
  HOME: `${CONTEXT_ROUTE}${ROUTES.HOME}`,
  RESULTS: `${CONTEXT_ROUTE}${ROUTES.RESULTS}`,
  NEW_RESULTS: `${CONTEXT_ROUTE}${ROUTES.NEW_RESULTS}`,
  DETAILS: `${CONTEXT_ROUTE}${ROUTES.DETAILS}`,
  COMPARE: `${CONTEXT_ROUTE}${ROUTES.COMPARE}`,
  TAGS: `${CONTEXT_ROUTE}${ROUTES.TAGS}`,
  ALERTS: `${CONTEXT_ROUTE}${ROUTES.ALERTS}`,
  BOOKMARK: `${CONTEXT_ROUTE}${ROUTES.BOOKMARK}`,
  NEWS: `${CONTEXT_ROUTE}${ROUTES.NEWS}`,
  OFFICES: `${CONTEXT_ROUTE}${ROUTES.OFFICES}`,
  LOGIN: `${CONTEXT_ROUTE}${ROUTES.LOGIN}`,
  REGISTER: `${CONTEXT_ROUTE}${ROUTES.REGISTER}`,
  FORGOT_PASSWORD: `${CONTEXT_ROUTE}${ROUTES.FORGOT_PASSWORD}`,
  CHANGE_PASSWORD: `${CONTEXT_ROUTE}${ROUTES.CHANGE_PASSWORD}`,
  ALERTS_HISTORY: `${CONTEXT_ROUTE}${ROUTES.ALERTS_HISTORY}`,
  NOTIFICATIONS: `${CONTEXT_ROUTE}${ROUTES.NOTIFICATIONS}`,
  SAVED_SEARCHES: `${CONTEXT_ROUTE}${ROUTES.SAVED_SEARCHES}`,
  SAVED_SEARCH_EVENTS: `${CONTEXT_ROUTE}${ROUTES.SAVED_SEARCH_EVENTS}`,
  HELP: `${CONTEXT_ROUTE}${ROUTES.HELP}`,
  DATA_PROTECTION: `${CONTEXT_ROUTE}${ROUTES.DATA_PROTECTION}`,
  TM_HISTORY: `${CONTEXT_ROUTE}${ROUTES.TM_HISTORY}`,
}
