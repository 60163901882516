export const FIELDS_TMVIEW = {
  STATUS_CHANGE: 'statusChange',
  APPLICANT_NAME_CHANGE: 'applicantNameChange',
  OPPOSITION_PERIOD_CLOSE: 'oppPeriodClose',
  OPPOSITION_PERIOD_DAYS: 'oppPeriodDays',
  EXPIRATION_DATE_CLOSE: 'expDateClose',
  EXPIRATION_DATE_DAYS: 'expDateDays',
  GOODS_AND_SERVICES_CHANGE: 'goodsAndServicesChange',
  EXPIRY_DATE_CHANGE: 'expiryDateChange',
  REPRESENTATIVE_CHANGE: 'representativeChange',
}

export const FIELDS_DSVIEW = {
  STATUS_CHANGE: 'statusChange',
  OWNER_NAME_CHANGE: 'ownerNameChange',
  EXPIRATION_DATE_CLOSE: 'expDateClose',
  EXPIRATION_DATE_DAYS: 'expDateDays',
  END_OF_DEFERMENT_DATE_CLOSE: 'endDeferDateClose',
  END_OF_DEFERMENT_DATE_DAYS: 'endDeferDateCloseDays',
  LOCARNO_CLASS_CHANGE: 'locarnoClassChange',
  EXPIRY_DATE_CHANGE: 'expiryDateChange',
  REPRESENTATIVE_CHANGE: 'representativeChange',
  END_OF_DEFERMENT_DATE_CHANGE: 'endDeferDateChange',
}

export const INITIAL_VALUES_TMVIEW = {
  [FIELDS_TMVIEW.STATUS_CHANGE]: false,
  [FIELDS_TMVIEW.APPLICANT_NAME_CHANGE]: false,
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_CLOSE]: false,
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_DAYS]: '',
  [FIELDS_TMVIEW.EXPIRATION_DATE_CLOSE]: false,
  [FIELDS_TMVIEW.EXPIRATION_DATE_DAYS]: '',
  [FIELDS_TMVIEW.GOODS_AND_SERVICES_CHANGE]: false,
  [FIELDS_TMVIEW.EXPIRY_DATE_CHANGE]: false,
  [FIELDS_TMVIEW.REPRESENTATIVE_CHANGE]: false,
}

export const INITIAL_VALUES_DSVIEW = {
  [FIELDS_DSVIEW.STATUS_CHANGE]: false,
  [FIELDS_DSVIEW.OWNER_NAME_CHANGE]: false,
  [FIELDS_DSVIEW.EXPIRATION_DATE_CLOSE]: false,
  [FIELDS_DSVIEW.EXPIRATION_DATE_DAYS]: '',
  [FIELDS_DSVIEW.EXPIRY_DATE_CHANGE]: false,
  [FIELDS_DSVIEW.REPRESENTATIVE_CHANGE]: false,
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CLOSE]: false,
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_DAYS]: '',
  [FIELDS_DSVIEW.LOCARNO_CLASS_CHANGE]: false,
  [FIELDS_DSVIEW.EXPIRY_DATE_CHANGE]: false,
  [FIELDS_DSVIEW.REPRESENTATIVE_CHANGE]: false,
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CHANGE]: false,
}

export const LABELS_TMVIEW = {
  [FIELDS_TMVIEW.STATUS_CHANGE]: 'alerts_events_status_change',
  [FIELDS_TMVIEW.APPLICANT_NAME_CHANGE]: 'alerts_events_applicant_change',
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_CLOSE]: 'alerts_events_opposition_period_close_to_end',
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_DAYS]: 'alerts_events_days_opposition_period_ends',
  [FIELDS_TMVIEW.EXPIRATION_DATE_CLOSE]: 'alerts_events_expiration_date_close_to_end',
  [FIELDS_TMVIEW.EXPIRATION_DATE_DAYS]: 'alerts_events_expiration_date_days',
  [FIELDS_TMVIEW.GOODS_AND_SERVICES_CHANGE]: 'alerts_events_goods_and_services_change',
  [FIELDS_TMVIEW.EXPIRY_DATE_CHANGE]: 'alerts_events_expiry_date_change',
  [FIELDS_TMVIEW.REPRESENTATIVE_CHANGE]: 'alerts_events_representative_change',
}

export const LABELS_DSVIEW = {
  [FIELDS_DSVIEW.STATUS_CHANGE]: 'alerts_events_status_change',
  [FIELDS_DSVIEW.OWNER_NAME_CHANGE]: 'alerts_events_owner_change',
  [FIELDS_DSVIEW.EXPIRATION_DATE_CLOSE]: 'alerts_events_expiration_date_close_to_end',
  [FIELDS_DSVIEW.EXPIRATION_DATE_DAYS]: 'alerts_events_expiration_date_days',
  [FIELDS_DSVIEW.REPRESENTATIVE_CHANGE]: 'alerts_events_representative_change',
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CLOSE]: 'alerts_events_end_deferment_date_close_to_end',
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_DAYS]: 'alerts_events_end_deferment_date_days',
  [FIELDS_DSVIEW.LOCARNO_CLASS_CHANGE]: 'alerts_events_locarno_class_change',
  [FIELDS_DSVIEW.EXPIRY_DATE_CHANGE]: 'alerts_events_expiry_date_change',
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CHANGE]: 'alerts_events_end_deferment_date_change',
}

export const COMPOSED_FIELDS = {
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_CLOSE]: [
    FIELDS_TMVIEW.OPPOSITION_PERIOD_DAYS,
    INITIAL_VALUES_TMVIEW[FIELDS_TMVIEW.OPPOSITION_PERIOD_DAYS],
  ],
  [FIELDS_DSVIEW.EXPIRATION_DATE_CLOSE]: [
    FIELDS_DSVIEW.EXPIRATION_DATE_DAYS,
    INITIAL_VALUES_DSVIEW[FIELDS_DSVIEW.EXPIRATION_DATE_DAYS],
  ],
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CLOSE]: [
    FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_DAYS,
    INITIAL_VALUES_DSVIEW[FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_DAYS],
  ],
}
