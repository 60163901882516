import styled from 'styled-components'

export const TabsStyled = styled.nav`
  border-bottom: solid ${({ theme }) => [theme.line, theme.colorGrayLight]};
  display: flex;
  justify-content: space-between;
`

export const TabStyled = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.size};
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  ${({ active, theme, selectedColor }) =>
    active &&
    `
        border-left: solid ${theme.line} ${theme.colorGrayLight};
        border-right: solid ${theme.line} ${theme.colorGrayLight};
        border-top: solid ${theme.lineThick} ${selectedColor || theme.color};
        font-weight: bold;
        background-color: ${theme.colorWhite};

        &:after {
            background-color: ${theme.colorWhite};
            bottom: -2px;
            content: '';
            height: ${theme.lineThick};
            left: 0;
            position: absolute;
            right: 0;
        }
    `};
`
