import {
  DSV_APPLICATION_CONTEXT,
  KEY_APPLICANT_NAME,
  KEY_APPLICATION_DATE,
  KEY_APPLICATION_NUMBER,
  KEY_DESIGN_NUMBER,
  KEY_DESIGN_STATUS,
  KEY_DESIGNER_NAME,
  KEY_EFFECTIVE_DATE,
  KEY_END_OF_DEFERMENT_DATE,
  KEY_EXHIBITION_PRIORITY,
  KEY_EXPIRY_DATE,
  KEY_INDICATION_OF_PRODUCT,
  KEY_LOCARNO_CLASS,
  KEY_MARK_URI,
  KEY_NICE_CLASS,
  KEY_OFFICE,
  KEY_OPPOSITION_DEAD_LINE,
  KEY_OPPOSITION_PERIOD_START,
  KEY_OWNER_NAME,
  KEY_PREFERED_IMAGE_OF_DESIGN,
  KEY_PREVIOUS_APPLICANT_NAME,
  KEY_PRIORITY_COUNTRY,
  KEY_PRIORITY_DATE,
  KEY_PRIORITY_NUMBER,
  KEY_PUBLICATION_DATE,
  KEY_REGISTRATION_DATE,
  KEY_REGISTRATION_NUMBER,
  KEY_REPRESENTATIVE_NAME,
  KEY_SENIORITY_CLAIMED,
  KEY_T_PROTECTION,
  KEY_THUMBNAIL,
  KEY_TM_NAME,
  KEY_TM_OFFICE,
  KEY_TRADE_MARK_STATUS,
  KEY_TRADE_MARK_TYPE,
  KEY_VERBAL_ELEMENT,
  KEY_VIENNA_CODES,
  TMV_APPLICATION_CONTEXT,
} from '@app/common/constants'
import { t } from '@app/common/translationsService'
import { searchDateFormat } from '@app/common/utilities'

export const columnLiterals = () => ({
  [TMV_APPLICATION_CONTEXT]: {
    [KEY_APPLICANT_NAME]: t('results.column.tm.applicant.name.key'),
    [KEY_APPLICATION_DATE]: t('results.column.tm.application.date.key'),
    [KEY_APPLICATION_NUMBER]: t('applicationnumber.key'),
    [KEY_MARK_URI]: t('advanced.search.graphic.representation.key'),
    [KEY_NICE_CLASS]: t('tm_details_section_goods_and_services'),
    [KEY_OPPOSITION_DEAD_LINE]: t('results.column.tm.opposition.deadline.key'),
    [KEY_OPPOSITION_PERIOD_START]: t('results.column.tm.opposition.period.key'),
    [KEY_REGISTRATION_DATE]: t('registration.date.detail.key'),
    [KEY_REGISTRATION_NUMBER]: t('tm.details.label.registrationNumber.key'),
    [KEY_SENIORITY_CLAIMED]: t('results.column.tm.seniority.key'),
    [KEY_TM_NAME]: t('results.column.tm.name.key'),
    [KEY_TM_OFFICE]: t('results.column.tm.office.key'),
    [KEY_T_PROTECTION]: t('results.column.tm.designatedTerritory.key'),
    [KEY_TRADE_MARK_STATUS]: t('results.column.tm.status.key'),
    [KEY_TRADE_MARK_TYPE]: t('results.column.tm.type.key'),
    [KEY_VIENNA_CODES]: t('results.column.tm.vienna.code.key'),
  },
  [DSV_APPLICATION_CONTEXT]: {
    [KEY_INDICATION_OF_PRODUCT]: t('indication.of.the.product.column.key'),
    [KEY_APPLICANT_NAME]: t('results.column.tm.applicant.name.key'),
    [KEY_APPLICATION_DATE]: t('filing.date.key'),
    [KEY_APPLICATION_NUMBER]: t('applicationnumber.key'),
    [KEY_DESIGN_NUMBER]: t('design.number.key'),
    [KEY_DESIGNER_NAME]: t('designer.name.key'),
    [KEY_DESIGN_STATUS]: t('design.status.key'),
    [KEY_EFFECTIVE_DATE]: t('effective.date.key'),
    [KEY_END_OF_DEFERMENT_DATE]: t('deferment.expiration.date.key'),
    [KEY_EXHIBITION_PRIORITY]: t('exhibition.priority.key'),
    [KEY_EXPIRY_DATE]: t('expiry.date.key'),
    [KEY_LOCARNO_CLASS]: t('locarno.classification.key'),
    [KEY_OFFICE]: t('ds.office.key'),
    [KEY_OWNER_NAME]: t('owner.name.key'),
    [KEY_PREFERED_IMAGE_OF_DESIGN]: t('design.key'),
    [KEY_PREVIOUS_APPLICANT_NAME]: t('previous.applicant.details.key'),
    [KEY_PRIORITY_COUNTRY]: t('priority.country.key'),
    [KEY_PRIORITY_DATE]: t('priority.date.key'),
    [KEY_PRIORITY_NUMBER]: t('convention.priority.number.key'),
    [KEY_PUBLICATION_DATE]: t('publication.date.key'),
    [KEY_REGISTRATION_DATE]: t('registration.date.detail.key'),
    [KEY_REPRESENTATIVE_NAME]: t('representatives.name.key'),
    [KEY_REGISTRATION_NUMBER]: t('registrationnumber.key'),
    [KEY_T_PROTECTION]: t('advanced_search_territories_key'),
    [KEY_THUMBNAIL]: t('design.key'),
    [KEY_VERBAL_ELEMENT]: t('verbal.element.key'),
  },
})

export const headerColumns = context => columnLiterals()[context]

export const parseSearchDatesQueryParams = queryParams => {
  return {
    ...queryParams,
    adFrom: searchDateFormat(queryParams.adFrom || ''),
    adTo: searchDateFormat(queryParams.adTo || ''),
    rdFrom: searchDateFormat(queryParams.rdFrom || ''),
    rdTo: searchDateFormat(queryParams.rdTo || ''),
    expiryFrom: searchDateFormat(queryParams.expiryFrom || ''),
    expiryTo: searchDateFormat(queryParams.expiryTo || ''),
    applicationFilingFrom: searchDateFormat(queryParams.applicationFilingFrom || ''),
    applicationFilingTo: searchDateFormat(queryParams.applicationFilingTo || ''),
    publicationFrom: searchDateFormat(queryParams.publicationFrom || ''),
    publicationTo: searchDateFormat(queryParams.publicationTo || ''),
    priorityFrom: searchDateFormat(queryParams.priorityFrom || ''),
    priorityTo: searchDateFormat(queryParams.priorityTo || ''),
    effectiveFrom: searchDateFormat(queryParams.effectiveFrom || ''),
    effectiveTo: searchDateFormat(queryParams.effectiveTo || ''),
    endOfDefermentFrom: searchDateFormat(queryParams.endOfDefermentFrom || ''),
    endOfDefermentTo: searchDateFormat(queryParams.endOfDefermentTo || ''),
  }
}
