export const blackDroppable = '#404040'
export const blueDroppable = '#30577a'
export const colorBorderBox = '#D3D3D3'
export const colorRed = '#ca6a6b'
export const colorTranslucid = 'rgba(255, 255, 255, 0.87)'
export const colorWhiteTransp80 = '#ffffffba'
export const colorWhiteTransp50 = '#ffffff6b'
export const colorGrayBG = '#EDEEF1'
export const dragIconSize = '2em'
export const dragLineHeight = '5em'
export const droppableHeight = '190px'
export const grayBorder = '#ddd'
export const grayHomeTitle = '#989898'
export const imageWidth = '180px'
export const maxWidth = '1280px'
export const itemsToScroll = 10
export const tabTextColor = '#737373'
export const resultsLinkDetails = '#5570ade8'
export const iconUploadPanel = '#9fb2c3'
export const breakpointXXL = 'min-width: 1500px'
export const breakpointUltraWide = 'min-width: 1800px'

export const statisticsColors = [
  '#0059A9',
  '#2871A5',
  '#61C5B9',
  '#F9CD67',
  '#F29D33',
  '#EB5A59',
  '#4F81BC',
  '#4597C0',
  '#8BD1C9',
  '#FBDB91',
  '#F6B85A',
  '#EE8885',

  '#F29D33',
  '#F4AB50',
  '#F9CF9B',
  '#FCE6CA',
  '#F9D2A1',
  '#F6BE78',
  '#F9D2A1',
  '#FAD6A9',
  '#FCE8D0',
  '#FDF3E7',
  '#FCE9D2',
  '#FBE1C0',

  '#EB5A59',
  '#EE7170',
  '#F5AEAE',
  '#FAD4D4',
  '#F6B3B3',
  '#F29291',
  '#F08786',
  '#F18F8E',
  '#F8C2C2',
  '#FBE0DF',
  '#F8C7C7',
  '#F5B0AF',

  '#0059A9',
  '#3768B2',
  '#8799CD',
  '#BAC3E3',
  '#8D9ED0',
  '#6580BF',
  '#5677BA',
  '#617DBE',
  '#A1AED8',
  '#CBD1EA',
  '#A7B3DB',
  '#899BCE',

  '#2871A5',
  '#4685B2',
  '#96B9D3',
  '#C7DAE8',
  '#9CBED6',
  '#71A1C4',
  '#6297BD',
  '#6D9EC2',
  '#AFCADE',
  '#D6E4EE',
  '#B6CFE0',
  '#98BBD4',

  '#61C5B9',
  '#77CDC3',
  '#B2E3DD',
  '#D6F0ED',
  '#B6E4DF',
  '#97D9D1',
  '#8CD5CC',
  '#94D8CF',
  '#C5EAE5',
  '#E1F4F2',
  '#C9EBE7',
  '#B3E3DD',

  '#F9CD67',
  '#FAD47C',
  '#FCE7B5',
  '#FDF2D7',
  '#FCE8B9',
  '#FBDE9B',
  '#FBDB90',
  '#FBDD98',
  '#FDEDC7',
  '#FEF6E2',
  '#FDEECB',
  '#FCE7B6',
]
