import React from 'react'
import ContentLoader from 'react-content-loader'

import { Loader } from './styles.js'
import { TransposedColumnContainer } from './styles'

const TABLE_ROW_ITEMS = 9

export const TransposedColumn = () => {
  const random = Math.random() * (1 - 0.7) + 0.7
  return (
    <Loader>
      <ContentLoader
        width={100}
        height={200}
        speed={2}
        primaryColor="#e2e2e2"
        secondaryColor="#ecebeb"
        preserveAspectRatio={'xMidYMax meet'}
      >
        <rect x="12" y="7" rx="0" ry="0" width="60" height="50" />
        <rect x="12" y="60" rx="2" ry="2" width={60 * random} height="5" />
        <rect x="12" y="70" rx="2" ry="2" width={40 * random} height="5" />
        <rect x="12" y="80" rx="2" ry="2" width={50 * random} height="5" />
        <rect x="12" y="90" rx="2" ry="2" width={55 * random} height="5" />
        <rect x="12" y="100" rx="2" ry="2" width={40 * random} height="5" />
        <rect x="12" y="110" rx="2" ry="2" width={50 * random} height="5" />
        <rect x="12" y="120" rx="2" ry="2" width={20 * random} height="5" />
        <rect x="12" y="130" rx="2" ry="2" width={30 * random} height="5" />
        <rect x="12" y="140" rx="2" ry="2" width={60 * random} height="5" />
        <rect x="12" y="150" rx="2" ry="2" width={40 * random} height="5" />
        <rect x="12" y="160" rx="2" ry="2" width={50 * random} height="5" />
        <rect x="12" y="170" rx="2" ry="2" width={55 * random} height="5" />
        <rect x="12" y="190" rx="2" ry="2" width={40 * random} height="5" />
        <rect x="12" y="200" rx="2" ry="2" width={50 * random} height="5" />
        <rect x="12" y="210" rx="2" ry="2" width={20 * random} height="5" />
        <rect x="12" y="220" rx="2" ry="2" width={30 * random} height="5" />
      </ContentLoader>
    </Loader>
  )
}

export default ({ itemsToShow = TABLE_ROW_ITEMS }) => {
  const items = !itemsToShow ? TABLE_ROW_ITEMS : itemsToShow + 1
  return (
    <TransposedColumnContainer>
      {[...Array(items)].map((x, i) => (
        <TransposedColumn key={i} />
      ))}
    </TransposedColumnContainer>
  )
}
