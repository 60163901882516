import { NO_TRANSLATE } from '@app/common/constants'
import { translateIndicationOfProduct } from '@app/common/dataManagers'
import {
  getIndicationOfProductOriginalLanguage,
  getIndicationOfProductText,
} from '@app/common/fields'
import { TMDSTrackingService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { SkeletonWrapper } from '@app/components/SkeletonLoader'
import TranslationsDropdown from '@app/components/TranslationsDropdown'
import { Block, Link } from '@new-lucentum'
import React from 'react'
import { List } from 'react-content-loader'
import { compose, lifecycle, withHandlers, withState } from 'recompose'

import CompareTranslations from './CompareTranslations'
import ErrorTranslation from './ErrorTranslation'
import IndicationOfProductText from './IndicationOfProductText'
import ShowMore from './ShowMore'
import TranslatedIndicationOfProduct from './TranslatedIndicationOfProduct'

const splitIndicationOfProductInTerms = (indicationOfProductText, separator = '; ') =>
  indicationOfProductText.split
    ? indicationOfProductText.split(separator).map(term => ({ term }))
    : []

const validTranslate = translatedArray =>
  Array.isArray(translatedArray) &&
  !!translatedArray.length &&
  translatedArray.some(term => !!term.translatedTerm)

const enhance = compose(
  withState('indicationOfProductText', 'setIndicationOfProductText', ''),
  withState('arrayTranslatedTerms', 'setArrayTranslatedTerms', []),
  withState('languageSelected', 'setLanguageSelected', ''),
  withState('initialLanguageCode', 'setInitialLanguageCode', ''),
  withState('showCompareTranslations', 'setShowCompareTranslations', false),
  withState('loading', 'setLoading', false),
  withState('error', 'setError', false),
  withHandlers({
    fetchTranslation: props => (originalLanguage, languageSelected) => {
      const errorOnTranslation = () => {
        props.setError(true)
        props.setIndicationOfProductText(
          getIndicationOfProductText(props.indicationOfProduct, props.initialLanguageCode)
        )
        typeof props.setShowTranslationLegend === 'function' && props.setShowTranslationLegend(true)
        typeof props.setErrorTranslation === 'function' && props.setErrorTranslation(true)
      }
      props.setIndicationOfProductText('')
      props.setArrayTranslatedTerms([])
      props.setError(false)
      props.setLoading(true)
      translateIndicationOfProduct(originalLanguage, languageSelected, {
        terms: splitIndicationOfProductInTerms(
          getIndicationOfProductText(props.indicationOfProduct)
        ),
        locarnoClasses: props.locarnoClasses,
      })
        .then(data => {
          props.setLoading(false)
          if (validTranslate(data)) {
            props.setIndicationOfProductText('')
            props.setArrayTranslatedTerms(data)
            typeof props.setShowTranslationLegend === 'function' &&
              props.setShowTranslationLegend(true)
          } else {
            errorOnTranslation()
          }
        })
        .catch(e => {
          props.setLoading(false)
          errorOnTranslation()
        })
    },
  }),
  withHandlers({
    onTranslate: props => (languageSelected, shouldTrack = true) => {
      if (shouldTrack) {
        TMDSTrackingService.trackTranslateIndicationOfProduct(props.section, languageSelected)
      }
      props.setLanguageSelected(languageSelected)
      props.setLanguageOuter && props.setLanguageOuter(languageSelected)
      if (Array.isArray(props.indicationOfProduct) && props.indicationOfProduct) {
        const textState = getIndicationOfProductText(
          props.indicationOfProduct,
          languageSelected === NO_TRANSLATE ? props.originalLanguage : languageSelected
        )
        if (textState) {
          props.setIndicationOfProductText(textState)
          typeof props.setShowTranslationLegend === 'function' &&
            props.setShowTranslationLegend(false)
          props.setError(false)
        } else {
          props.fetchTranslation(props.originalLanguage, languageSelected)
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        indicationOfProduct = [],
        setIndicationOfProductText,
        setLanguageSelected,
        initialLanguage,
        originalLanguage = 'en',
        setLanguageOuter,
        setInitialLanguageCode,
      } = this.props
      let initialLanguageCode = initialLanguage || originalLanguage
      let initialLanguageText = getIndicationOfProductText(indicationOfProduct, initialLanguageCode)
      if (!initialLanguageText) {
        initialLanguageCode = 'en'
        initialLanguageText = getIndicationOfProductText(indicationOfProduct, initialLanguageCode)
      }
      if (!initialLanguageText) {
        initialLanguageCode = getIndicationOfProductOriginalLanguage(indicationOfProduct)
        initialLanguageText = getIndicationOfProductText(indicationOfProduct, initialLanguageCode)
      }
      setIndicationOfProductText(initialLanguageText)
      setLanguageSelected(initialLanguageCode)
      setInitialLanguageCode(initialLanguageCode)
      setLanguageOuter && setLanguageOuter(originalLanguage)
    },
    componentDidUpdate(prevProps) {
      const { translateLanguage, onTranslate, languageSelected } = this.props
      const hasChangedTranslateLanguage = translateLanguage !== prevProps.translateLanguage
      const isExternalValueDifferentThanSelected = translateLanguage !== languageSelected
      if (
        translateLanguage &&
        (hasChangedTranslateLanguage || isExternalValueDifferentThanSelected)
      ) {
        onTranslate(translateLanguage, hasChangedTranslateLanguage)
      }
    },
  })
)

const IndicationOfProductTranslate = ({
  ST13,
  languageList,
  indicationOfProduct,
  indicationOfProductText,
  arrayTranslatedTerms,
  textStyle = {},
  maxOptionsHeight,
  loading,
  error,
  showMore = false,
  hideTranslationsDropdown = false,
  languageSelected,
  originalLanguage = 'en',
  noOriginalLanguage = false,
  showCompareLink = false,
  setNavigation,
  onTranslate,
  showCompareTranslations,
  setShowCompareTranslations,
  onClickText = () => {},
}) => {
  return (
    <Block auto>
      <SkeletonWrapper showSkeleton={loading} skeleton={<List width={500} height={50} />}>
        {!!indicationOfProductText && (
          <IndicationOfProductText
            ST13={ST13}
            text={indicationOfProductText}
            textStyle={textStyle}
            error={error}
            onClickText={onClickText}
          />
        )}
        {!indicationOfProductText &&
          Array.isArray(arrayTranslatedTerms) &&
          !!arrayTranslatedTerms.length && (
            <TranslatedIndicationOfProduct
              ST13={ST13}
              translatedTerms={arrayTranslatedTerms}
              textStyle={textStyle}
            />
          )}
      </SkeletonWrapper>
      {!hideTranslationsDropdown && languageSelected && (
        <Block auto marginTop>
          <Block auto>
            <TranslationsDropdown
              maxOptionsHeight={maxOptionsHeight}
              languages={languageList}
              originalLanguageCode={noOriginalLanguage ? languageSelected : originalLanguage}
              noOriginalLanguage={noOriginalLanguage}
              onLanguageSelected={onTranslate}
              data-test-id="translate-indications-of-product-button"
            />
          </Block>
          {showMore && (
            <Block auto paddingLeft marginLeft marginTop>
              <ShowMore ST13={ST13} setNavigation={setNavigation} />
            </Block>
          )}
          {showCompareLink && languageSelected !== originalLanguage && (
            <Block auto marginTop marginLeft>
              <Link onClick={() => setShowCompareTranslations(true)}>
                {t('translate_indication_of_product_compare')}
              </Link>
            </Block>
          )}
          <CompareTranslations
            isOpen={showCompareTranslations}
            ST13={ST13}
            originalLanguage={originalLanguage}
            languageSelected={languageSelected}
            indicationOfProductText={getIndicationOfProductText(indicationOfProduct)}
            indicationOfProductText2={indicationOfProductText}
            arrayTranslatedTerms={arrayTranslatedTerms}
            textStyle={textStyle}
            error={error}
            onCloseModal={() => setShowCompareTranslations(false)}
          />
          {error && (
            <Block marginTop>
              <ErrorTranslation />
            </Block>
          )}
        </Block>
      )}
    </Block>
  )
}

export default enhance(IndicationOfProductTranslate)
