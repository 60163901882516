import styled from 'styled-components'

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.sizeSmall};
  }
  span {
    color: ${({ theme }) => theme.colorGrayLight};
    margin-left: ${({ theme }) => theme.sizeSmall};
  }
`
