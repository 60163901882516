export const KEY_FILTER_APP_NAME = 'fAName'
export const KEY_FILTER_APPLICATION_DATE = 'fADateRanges'
export const KEY_FILTER_CURRENTLY_OPPOSABLE = 'fCOpposable'
export const KEY_FILTER_DESIGN_STATUS = 'fDesignStatus'
export const KEY_FILTER_DESIGNER_NAME = 'fDesignerName'
export const KEY_FILTER_LOCARNO_CLASS = 'fLocarnoClass'
export const KEY_FILTER_NICE_CLASS = 'fNiceClass'
export const KEY_FILTER_OFFICES = 'fOffices'
export const KEY_FILTER_OWNER_NAME = 'fApplicantName'
export const KEY_FILTER_REGISTRATION_DATE = 'fRDateRanges'
export const KEY_FILTER_SENIORITY_CLAIMED = 'fSClaimed'
export const KEY_FILTER_TERRITORIES = 'fTerr'
export const KEY_FILTER_TM_STATUS = 'fTMStatus'
export const KEY_FILTER_TM_TYPE = 'fTMType'
export const KEY_FILTER_VIENNA_CODE = 'fViennaCodes'
export const KEY_FILTER_FILING_DATE = 'fApplicationDateRanges'
export const KEY_FILTER_EVENTS = 'event'
export const KEY_FILTER_REG_DATES = 'fRegDateRanges'

export const FILTERS_COMMON = {
  OFFICES: KEY_FILTER_OFFICES,
  TERRITORIES: KEY_FILTER_TERRITORIES,
  REGISTRATION_DATE: KEY_FILTER_REGISTRATION_DATE,
  EVENTS: KEY_FILTER_EVENTS,
}

export const FILTERS_TMVIEW = {
  GOODS_AND_SERVICES: KEY_FILTER_NICE_CLASS,
  VIENNA_CODE: KEY_FILTER_VIENNA_CODE,
  TRADEMARK_STATUS: KEY_FILTER_TM_STATUS,
  TRADEMARK_TYPE: KEY_FILTER_TM_TYPE,
  APPLICANT_NAME: KEY_FILTER_APP_NAME,
  APPLICATION_DATE: KEY_FILTER_APPLICATION_DATE,
  CURRENTLY_OPPOSABLE: KEY_FILTER_CURRENTLY_OPPOSABLE,
  SENIORITY_CLAIMED: KEY_FILTER_SENIORITY_CLAIMED,
}

export const FILTERS_DSVIEW = {
  DESIGN_STATUS: KEY_FILTER_DESIGN_STATUS,
  OWNER_NAME: KEY_FILTER_OWNER_NAME,
  DESIGNER_NAME: KEY_FILTER_DESIGNER_NAME,
  LOCARNO: KEY_FILTER_LOCARNO_CLASS,
  FILING_DATE: KEY_FILTER_FILING_DATE,
  REGISTRATION_DATE_DS: 'fRegDateRanges',
}

export const FILTERS = {
  ...FILTERS_COMMON,
  ...FILTERS_TMVIEW,
  ...FILTERS_DSVIEW,
}

export const FILTER_ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
}
