import { resetCertificateStatus, resetProcess } from '@app/actions/certificate'
import { t } from '@app/common/translationsService'
import { Modal } from '@new-lucentum'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .close-modal-wrapper {
    position: absolute;
    padding: 20px;
    margin: 0;
  }
`

const CertificatePending = () => {
  const dispatch = useDispatch()
  const certificateStatus = useSelector(state => state.uiState.certificate.certificate.status)

  const closeHandler = () => {
    dispatch(resetProcess())
  }

  const submitHandler = () => {
    dispatch(resetCertificateStatus())
  }

  return (
    <StyledModal
      noTitle
      isOpen={certificateStatus}
      onRequestClose={closeHandler}
      textButtonClose={t('wait.key')}
      textButtonSubmit={t('new.request.key')}
      shouldCloseOnOverlayClick={false}
      onSubmit={submitHandler}
    >
      <span>{t('certificate.status.pending.modal.message')}</span>
    </StyledModal>
  )
}

export default CertificatePending
