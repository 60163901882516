import { FIELDS } from './fields'
export const PLACEHOLDERS = {
  [FIELDS.APPLICANT_NAME]: 'advanced_search_TypeValue',
  [FIELDS.APPLICATION_NUMBER]: 'advanced_search_application_number_type_key',
  [FIELDS.REGISTRATION_NUMBER]: 'advanced_search_registration_number_type_key',
  [FIELDS.VERBAL_ELEMENT]: 'advanced_search_verbal_element_type',
  [FIELDS.DESIGN_NUMBER]: 'advanced_search_design_number_type',
  [FIELDS.PRIORITY_NUMBER]: 'advanced_search_priority_number_placeholder_key',
  [FIELDS.EXHIBITION_PRIORITY]: 'advanced_search_exhibition_priority_placeholder_key',
  [FIELDS.OWNER_NAME]: 'advanced_search_owner_name',
  [FIELDS.REPRESENTATIVE_NAME]: 'advanced_search_representative_name',
  [FIELDS.PREVIOUS_OWNER_NAME]: 'advanced_search_owner_name',
  [FIELDS.DESIGNER_NAME]: 'advanced_search_designer_name',
}
