import { withFormik } from 'formik'
import * as Yup from 'yup'
//import { t } from '@app/common/translationsService'
import { TMDSAlertsService, TMDSToastService } from '@app/common/services'
import TMDSContextService from '@app/common/tmdsContextService'
import { checkForTriggeredValues } from '../../utils'
import {
  FIELDS_TMVIEW,
  FIELDS_DSVIEW,
  INITIAL_VALUES_TMVIEW,
  INITIAL_VALUES_DSVIEW,
} from '@app/common/constants/alerts'
import { t } from '@app/common/translationsService'

const AlertValidationTmview = Yup.object().shape({
  [FIELDS_TMVIEW.STATUS_CHANGE]: Yup.boolean(),
  [FIELDS_TMVIEW.APPLICANT_NAME_CHANGE]: Yup.boolean(),
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_CLOSE]: Yup.boolean(),
  [FIELDS_TMVIEW.EXPIRATION_DATE_CLOSE]: Yup.boolean(),
  [FIELDS_TMVIEW.GOODS_AND_SERVICES_CHANGE]: Yup.boolean(),
  [FIELDS_TMVIEW.EXPIRY_DATE_CHANGE]: Yup.boolean(),
  [FIELDS_TMVIEW.REPRESENTATIVE_CHANGE]: Yup.boolean(),
  [FIELDS_TMVIEW.OPPOSITION_PERIOD_DAYS]: Yup.number()
    .integer()
    .nullable()
    .when(FIELDS_TMVIEW.OPPOSITION_PERIOD_CLOSE, {
      is: true,
      then: Yup.number()
        .integer()
        .nullable()
        .required(() => t('alerts_dropdown_opposition_days_error')),
    }),
  [FIELDS_TMVIEW.EXPIRATION_DATE_DAYS]: Yup.number()
    .integer()
    .nullable()
    .when(FIELDS_TMVIEW.EXPIRATION_DATE_CLOSE, {
      is: true,
      then: Yup.number()
        .integer()
        .nullable()
        .required(() => t('alerts_dropdown_expiration_days_error')),
    }),
})

const AlertValidationDsview = Yup.object().shape({
  [FIELDS_DSVIEW.STATUS_CHANGE]: Yup.boolean(),
  [FIELDS_DSVIEW.OWNER_NAME_CHANGE]: Yup.boolean(),
  [FIELDS_DSVIEW.EXPIRATION_DATE_CLOSE]: Yup.boolean(),
  [FIELDS_DSVIEW.EXPIRY_DATE_CHANGE]: Yup.boolean(),
  [FIELDS_DSVIEW.REPRESENTATIVE_CHANGE]: Yup.boolean(),
  [FIELDS_DSVIEW.LOCARNO_CLASS_CHANGE]: Yup.boolean(),
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CHANGE]: Yup.boolean(),
  [FIELDS_DSVIEW.EXPIRATION_DATE_DAYS]: Yup.number()
    .integer()
    .nullable()
    .when(FIELDS_DSVIEW.EXPIRATION_DATE_CLOSE, {
      is: true,
      then: Yup.number()
        .integer()
        .nullable()
        .required(() => t('alerts_dropdown_expiration_days_error')),
    }),
  [FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_DAYS]: Yup.number()
    .integer()
    .nullable()
    .when(FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CLOSE, {
      is: true,
      then: Yup.number()
        .integer()
        .nullable()
        .required(() => t('alerts_dropdown_end_deferment_days_error')),
    }),
})

export const alertFormEnhancer = withFormik({
  mapPropsToValues: props => {
    const initialValues = TMDSContextService.isTmview()
      ? INITIAL_VALUES_TMVIEW
      : INITIAL_VALUES_DSVIEW
    return { ...initialValues }
  },
  validationSchema: TMDSContextService.isTmview() ? AlertValidationTmview : AlertValidationDsview,
  handleSubmit: (values, { props, setSubmitting, resetForm, setStatus, status }) => {
    const {
      toggleDropdown,
      st13List,
      setBadgeNumber,
      preloadedValues,
      setPreloadedValues,
      setApplyDisabled,
      noBadge,
      onSetAlertSuccess,
      resetOnSubmit = false,
      setIsModalOpen,
    } = props

    TMDSAlertsService.setAlerts(st13List, values)
      .then(() => {
        const isBulk = Array.isArray(st13List) && st13List.length > 1
        let toastMessage = 'alerts_selected_activated'

        if (!isBulk) {
          const triggeredValues = checkForTriggeredValues(values)
          const previousTriggeredValues = checkForTriggeredValues(preloadedValues)
          triggeredValues > 0 ? setApplyDisabled(false) : setApplyDisabled(true)
          const triggeredValuesMessage =
            previousTriggeredValues === 0 ? toastMessage : 'alerts_selected_edited'
          toastMessage = triggeredValues > 0 ? triggeredValuesMessage : 'alerts_removed'
          if (!noBadge) {
            setBadgeNumber(triggeredValues)
          }
        }

        TMDSToastService.showToast(t(toastMessage))

        if (resetOnSubmit) {
          resetForm()
        } else {
          setPreloadedValues && setPreloadedValues(values)
        }

        if (toggleDropdown) {
          toggleDropdown()
        }

        if (setIsModalOpen) {
          setIsModalOpen(false)
        }

        onSetAlertSuccess && onSetAlertSuccess(values, st13List)
      })
      .catch(error => {
        const { parsedBody, response } = error
        if (response.status === 422) {
          const { errorMessage } = parsedBody
          const nextStatus = { ...status, backend: t(errorMessage) }
          setStatus(nextStatus)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  },
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
})
