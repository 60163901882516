import styled from 'styled-components'

export const TextArea = styled.textarea`
  display: block;
  width: 95%;
  min-height: 200px;
  border-bottom: solid ${({ theme }) => theme.colorGray} ${({ theme }) => theme.lineThick};
  border-left: 0;
  border-radius: ${({ theme }) => theme.radius};
  border-right: 0;
  border-top: 0;
  color: ${({ theme }) => theme.colorGray};
  background-color: ${({ theme }) => theme.colorGrayAccent};
  padding: ${({ theme }) => `${theme.sizeSmall} ${theme.size}`} !important;
  font-size: ${({ theme }) => theme.fontSize};
  font-family: ${({ theme }) => theme.fontFamily};
  &:hover {
    border-bottom-color: ${({ theme }) => theme.colorLight};
  }
  &:focus {
    border-bottom-color: ${({ theme }) => theme.color};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colorGrayLight};
  }
`
