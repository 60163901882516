import { css } from 'styled-components'
export const typography = {
  footnoteText: css`
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.428;
  `,
  baseText: css`
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;
  `,
  uiText: css`
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
  `,
  titleText: css`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.25;
  `,
  sectionTitle: css`
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 1.428;
  `,
  pageTitle: css`
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 32px;
    line-height: 1.25;
  `,
}
