import Api from '@app/common/api'
import TMDSContextService from '@app/common/tmdsContextService'

const api = new Api()

const PATHS = {
  CERTIFICATE_TYPES: {
    dsview: 'rest/certificate-types/dsv',
    tmview: 'rest/certificate-types/tmv',
  },
  REQUEST: {
    dsview: 'dsv/request-certificate',
    tmview: 'tmv/request-certificate',
  },
  REQUEST_PENDING: {
    dsview: 'dsv/request-pending',
    tmview: 'tmv/request-pending',
  },
  USER_PREFS: {
    CERTIFICATES: 'userprefs/certificates',
  },
  DOWNLOAD: {
    dsview: 'dsv/download',
    tmview: 'tmv/download',
  },
}

const context = TMDSContextService.get

const getCertificateTypes = ST13 => {
  const url = api.get(`/${PATHS.CERTIFICATE_TYPES[context()]}?st13=${ST13}`)
  return url
}

const getCertificateStatus = payload => {
  const url = `/${PATHS.USER_PREFS.CERTIFICATES}/${PATHS.REQUEST_PENDING[context()]}`
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const requestCertificate = payload => {
  const url = `/${PATHS.USER_PREFS.CERTIFICATES}/${PATHS.REQUEST[context()]}`
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const authenticateCertificate = (st13, pdf) => {
  const url = `/${PATHS.USER_PREFS.CERTIFICATES}/authenticate-certificate/${st13}`
  const options = {
    body: pdf,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  return api.post(url, options)
}

const getFilenameFromContentDisposition = contentDisposition => {
  const match = /filename="(.*)"/.exec(contentDisposition)
  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}

const downloadCertificate = async certificateId => {
  const url = `api/${PATHS.USER_PREFS.CERTIFICATES}/${PATHS.DOWNLOAD[context()]}/${certificateId}`
  const res = await fetch(url)

  if (res.status >= 200 && res.status < 300) {
    const filename = getFilenameFromContentDisposition(res.headers.get('Content-Disposition'))
    const pdfBlob = await res.blob()
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob && navigator.msSaveBlob(res, filename)
    } else {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(pdfBlob)
      a.download = filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  return res
}

const getContactDetails = () => {
  const url = api.get(`/userprefs/contactdetails`)
  return url
}

export const CertificateDataManager = {
  getCertificateTypes,
  getCertificateStatus,
  requestCertificate,
  authenticateCertificate,
  downloadCertificate,
  getContactDetails,
}
