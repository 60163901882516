import styled from 'styled-components'

import { Button as ButtonLct } from '@new-lucentum'

export const Button = styled(ButtonLct)`
  &.dropdown-parent {
    > button {
      padding: 5px 10px;
    }
  }
  .dropdown-options {
    max-height: 350px;
  }
`
