import { HISTORY_TYPES } from '@app/redux-types'

const defaultState = {
  data: {
    transactionInstances: [],
    historyMetaDetails: {},
  },
  isLoading: false,
  recaptchaResponseKey: null,
  showOriginalLanguageAttributes: !!JSON.parse(localStorage.getItem('originalLanguage')),
  exportHistory: {
    showExportPDFOptions: false,
    showExportDOCOptions: false,
    recaptchaResponseKey: null,
    historyId: null,
    targetHistoryId: null,
  },
  isIPOperational: true,
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case HISTORY_TYPES.FETCH_HISTORY_PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case HISTORY_TYPES.FETCH_HISTORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isIPOperational: false,
      }
    case HISTORY_TYPES.FETCH_HISTORY_FULFILLED:
      return {
        ...state,
        data: {
          ...state.data,
          transactionInstances: payload.results.transactionInstances,
          historyMetaDetails: payload.results.historyMetaDetails,
        },
        isLoading: false,
      }
    case 'CHANGE_EXPORT_CAPTCHA_HISTORY':
      return {
        ...state,
        recaptchaResponseKey: payload.recaptchaResponseKey,
      }
    case 'RESET_RECAPTCHA_HISTORY':
      return {
        ...state,
        recaptchaResponseKey: null,
      }
    case 'SHOW_EXPORT_PDF_OPTIONS':
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          recaptchaResponseKey: null,
          showExportPDFOptions: true,
        },
      }
    case 'SHOW_EXPORT_DOC_OPTIONS':
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          recaptchaResponseKey: null,
          showExportDOCOptions: true,
        },
      }
    case 'SHOW_EXPORT_HISTORY_OPTIONS':
      return {
        ...state,
        exportStatistics: {
          ...state.exportStatistics,
          ...showExportStatisticsFileType(payload.fileType, payload.show),
        },
      }
    case 'CANCEL_EXPORT':
      return {
        ...state,
        exportHistory: {
          ...defaultState.exportHistory,
        },
      }
    case 'EXPORT_PDF_PENDING':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          showExportPDFOptions: false,
        },
      }
    case 'EXPORT_DOC_PENDING':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          showExportDOCOptions: false,
        },
      }
    case 'SET_EXPORT_HISTORY_IDS':
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          ...payload,
        },
      }

    default:
      return state
  }
}
