import { APPLICANTS_FIELDS } from '@app/common/dataManagers/types'
export const applicantFields = [
  {
    label: 'tm.details.label.template.paintName.OrganizationName.key',
    path: APPLICANTS_FIELDS.ORGANIZATION_NAME,
  },
  { label: 'advanced.search.applicant.name.key', path: APPLICANTS_FIELDS.FULL_NAME },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantIncorporationCountryCode.key',
    path: APPLICANTS_FIELDS.INCORPORATION_COUNTRY_CODE,
  },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantIdentifier.key',
    path: APPLICANTS_FIELDS.IDENTIFIER,
  },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantNationalityCode.key',
    path: APPLICANTS_FIELDS.NATIONALITY_CODE,
    inidCode: 'applicantNationalityCountry',
  },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantLegalEntity.key',
    path: APPLICANTS_FIELDS.LEGAL_ENTITY,
    pathTranslated: APPLICANTS_FIELDS.LEGAL_ENTITY_TRANSLATED,
    inidCode: 'applicantLegalEntity',
  },
  {
    label: 'tm.details.label.template.paintApplicant.ApplicantIncorporationState.key',
    path: APPLICANTS_FIELDS.INCORPORATION_STATE,
    inidCode: 'applicantIncorporationState',
  },
  {
    label: 'tm.details.label.template.paintApplicant.EntitlementNationalityCode.key',
    path: APPLICANTS_FIELDS.ENTITLEMENT_NATIONALITY_CODE,
  },
  {
    label: 'tm.details.label.template.paintApplicant.EntitlementEstablishmentCode.key',
    path: APPLICANTS_FIELDS.ENTITLEMENT_ESTABLISHMENT_CODE,
  },
  {
    label: 'tm.details.label.template.paintApplicant.EntitlementDomiciledCode.key',
    path: APPLICANTS_FIELDS.ENTITLEMENT_DOMICILED_CODE,
  },
  {
    label: 'tm.details.label.template.paintAddress.FreeFormatAddress.key',
    path: APPLICANTS_FIELDS.FULL_ADDRESS,
  },
  {
    label: 'tm.details.label.template.paintAddress.FreeFormatAddress.key',
    path: APPLICANTS_FIELDS.ADDRESS_DETAILS.ADDRESS,
  },
  { label: 'addresscity.key', path: APPLICANTS_FIELDS.ADDRESS_DETAILS.CITY },
  {
    label: 'tm.details.label.template.paintAddress.AddressState.key',
    path: APPLICANTS_FIELDS.ADDRESS_DETAILS.STATE,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressPostcode.key',
    path: APPLICANTS_FIELDS.ADDRESS_DETAILS.POSTCODE,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.Phone.key',
    path: APPLICANTS_FIELDS.PHONE,
  },
  { label: 'tm.details.label.template.fax.key', path: APPLICANTS_FIELDS.FAX },
  { label: 'email.key', path: APPLICANTS_FIELDS.EMAIL },
  { label: 'tm.details.label.template.url.key', path: APPLICANTS_FIELDS.URL },
  {
    label: 'tm.details.label.template.paintAddress.AddressCountryCode.key',
    path: APPLICANTS_FIELDS.ADDRESS_DETAILS.COUNTRY_CODE,
  },
]
