import Api from '@app/common/api'

const api = new Api()

const getHistoryTM = id => {
  const url = api.get(`/mocks/history/compare/${id}`)
  return url
}

const getHistory = id => {
  const url = api.get(`/history/trademarks/${id}?pageSize=1000`)
  return url
}

const getHistoryDS = id => {
  const url = api.get(`/history/designs/${id}?pageSize=1000`)
  return url
}

const isBlockchain = office => {
  const url = api.get(`/history/office-metadata/${office}`)
  return url
}

export const HistoryDataManager = {
  getHistory,
  getHistoryDS,
  getHistoryTM,
  isBlockchain,
}
