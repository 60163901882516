import {
  getTrademarkImageForSearch,
  setImageData,
  restoreImageSearch,
  updateImageSearchUiData,
} from '@app/actions/imageSearch'
import {
  closeAdvancedSearch,
  openImageSearchMode,
  setSearchFormToSimilarImage,
} from '@app/actions/search'
import { TMDSTrackingService } from '@app/common/services'
import TMDSContextService from '@app/common/tmdsContextService'
import { buildQueryParams } from '@app/common/utilities'
import { Modal } from '@new-lucentum'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ImageSearchContainer from '../ImageSearchContainer'
import { StyledButton } from './styles'

class SearchByImageButtonComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      imageData: null,
      oldImageData: null,
      oldImageSearchData: {},
      oldImageSearchUiData: {},
    }
  }

  handleImageSearch = null

  toggleModal = () => {
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }))
  }

  revertImageData = () => {
    this.setState({ imageData: this.state.oldImageData })
    this.props.setImageData(this.state.oldImageData)
    this.props.restoreImageSearch(this.state.oldImageSearchData)
    this.props.updateImageSearchUiData(this.state.oldImageSearchUiData)
  }

  getUpdatedImageUIDataFormData = uiImageFormData => {
    const imageData = this.props.appState.imageSearch.data
    const { UiDataFromImageDataStored, ...parsedUiImageFormData } = uiImageFormData
    const updatedFormData = {
      imageId: imageData.imageId,
      imageName: imageData.imageName,
      ...parsedUiImageFormData,
    }

    return updatedFormData
  }

  handleClick = () => {
    this.prepareImage()
    this.toggleModal()
    TMDSTrackingService.trackSearchSimilarImagesClickEvent()
  }

  handleSearch = () => {
    const {
      history,
      globals,
      setSearchFormToSimilarImage,
      openImageSearchMode,
      closeAdvancedSearch,
    } = this.props
    const { freeSelectionPoints, ...uiImageFormData } =
      this.handleImageSearch && this.handleImageSearch()

    const params = {
      page: 1,
      pageSize: globals.resultsConf.pageSize,
      criteria: 'C',
      ...this.getUpdatedImageUIDataFormData(uiImageFormData || {}),
    }

    const queryParams = buildQueryParams(params)
    const extraQueryParams = freeSelectionPoints
      ? `&freeSelectionPoints=${JSON.stringify(freeSelectionPoints)}`
      : ''

    const context = this.props.match.params.context
    setSearchFormToSimilarImage()
    openImageSearchMode()
    closeAdvancedSearch()
    this.toggleModal()
    history.push(`/${context}/results${queryParams}${extraQueryParams}`)
  }

  prepareImage = () => {
    const { getTrademarkImageForSearch, ST13 } = this.props

    // back up current image data
    this.setState({ oldImageData: this.props.uiState?.imageSearch?.imageData })
    this.setState({ oldImageSearchData: this.props.appState?.imageSearch?.data })
    this.setState({ oldImageSearchUiData: this.props.uiState?.imageSearch?.formData })

    getTrademarkImageForSearch(ST13).then(data => {
      if (data && data.value && data.value.imageData) {
        const base64WithoutPrefix = data.value.imageData.replace(/^data:image\/\w+;base64,/, '')
        const uint8Array = new Uint8Array(
          atob(base64WithoutPrefix)
            .split('')
            .map(char => char.charCodeAt(0))
        )
        const blob = new Blob([uint8Array], { type: 'image/png' })
        const blobUrl = URL.createObjectURL(blob)
        this.setState({ imageData: blobUrl })

        // also, update the state of the image search form
        this.props.setImageData(this.state.imageData)
      }
    })
  }

  render() {
    const { literals, enable, uiState } = this.props

    return (
      <div>
        {this.state.isModalOpen && (
          <Modal
            isOpen={this.state.isModalOpen}
            modalSize="large"
            title={
              literals[
                `search_similar_${
                  TMDSContextService.isTmview() ? 'trademarks' : 'designs'
                }_modal_title`
              ]
            }
            onSubmit={() => this.handleSearch()}
            onRequestClose={() => {
              this.revertImageData()
              this.toggleModal()
            }}
            textButtonSubmit={
              literals[
                `search_similar_${
                  TMDSContextService.isTmview() ? 'trademarks' : 'designs'
                }_modal_buttons_confirm`
              ]
            }
            textButtonClose={literals['cancel.key']}
            ariaHideApp={false}
          >
            <ImageSearchContainer
              handleClose={() => {}}
              opened={uiState.search.imageSearchMode}
              resetImage={uiState.search.resetImage}
              preloadedImage={this.state.imageData}
              inModal
              setHandleInModal={handleInModal => (this.handleImageSearch = handleInModal)}
            />
          </Modal>
        )}
        <StyledButton
          data-test-id="search-by-image-button"
          icon="similarSearch"
          title={
            literals[
              `search.similar.${TMDSContextService.isTmview() ? 'trademarks' : 'designs'}.title`
            ]
          }
          onClick={enable ? () => this.handleClick() : null}
          // Lucentum works disabling the active button.
          disabled={!enable}
        />
      </div>
    )
  }
}

const mergeProps = ({ ...stateProps }, { ...actionProps }, { ...ownProps }) => ({
  ...stateProps,
  ...ownProps,
  ...actionProps,
})

const mapStateToProps = ({
  globals: { data: globals },
  literals: { data: literals },
  appState,
  uiState,
}) => ({
  globals,
  literals,
  appState,
  uiState,
})

const mapDispatchToProps = {
  getTrademarkImageForSearch,
  openImageSearchMode,
  updateImageSearchUiData,
  setSearchFormToSimilarImage,
  closeAdvancedSearch,
  setImageData,
  restoreImageSearch,
}

export const SearchByImageButton = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withRouter(SearchByImageButtonComponent))

export default SearchByImageButton
