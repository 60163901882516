import { setLiterals } from '@app/common/translationsService'

const defaultState = {
  data: {},
  fetching: false,
  fetched: false,
  error: null,
}

const proxyHandler = {
  get: (target, name) => (target[name] === undefined ? name : target[name]),
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'FETCH_LITERALS_PENDING':
      return { ...state, fetching: true, fetched: false, error: null }
    case 'FETCH_LITERALS_REJECTED':
      return { ...state, fetching: false, fetched: false, error: payload }
    case 'FETCH_LITERALS_FULFILLED':
      setLiterals(payload)
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: window.Proxy ? new window.Proxy(payload, proxyHandler) : payload,
      }
    default:
      return state
  }
}
