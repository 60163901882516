import store from '@app/store'
import {
  getAlerts as getAlertsAction,
  getAlertsByIds as getAlertsByIdsAction,
  setAlerts as setAlertsAction,
  doSearchBySt13s,
  getAlertsHistory as getAlertsHistoryAction,
} from '@app/actions/alerts'
import { userprefsDataManager } from '@app/common/dataManagers'
import { manageExport } from '@app/common/utils/exportManagement'
import { getRelatedField, prepareAlertsSearchQuery } from './tmdsAlertsService.utils'

const { dispatch } = store

const setAlerts = (st13List, alertsEvents) => {
  return dispatch(setAlertsAction(st13List, alertsEvents))
}

const getAlertsByIds = st13List => {
  return dispatch(getAlertsByIdsAction(st13List))
}

const getAlerts = (page = 1, pageSize = 999) => {
  return dispatch(getAlertsAction(page, pageSize))
}

const doAlertsSearch = (st13List, filterParams, columns) => {
  const { queryString, queryObject } = prepareAlertsSearchQuery(st13List, filterParams)
  return dispatch(doSearchBySt13s(queryString, { ...queryObject, fields: columns }))
}

const removeAlertEvent = (st13, eventKey, existingAlerts) => {
  const [relatedField, relatedFieldValue] = getRelatedField(eventKey)
  let nextEvent = {
    [eventKey]: false,
    ...(relatedField && {
      [relatedField]: relatedFieldValue,
    }),
  }

  const nextAlerts = { ...existingAlerts, ...nextEvent }
  return dispatch(setAlertsAction([st13], nextAlerts))
}

const getAlertsHistory = (st13, filterParams) => {
  return dispatch(getAlertsHistoryAction(st13, filterParams))
}

const exportAlertEvents = (selectedIds, recaptchaResponse, documentType) => {
  const exportPromise = userprefsDataManager.exportAlertEvents(
    selectedIds,
    recaptchaResponse,
    documentType
  )
  manageExport(exportPromise, 'alert-history', documentType)
  return exportPromise
}

export const TMDSAlertsService = {
  getAlerts,
  getAlertsByIds,
  setAlerts,
  removeAlertEvent,
  doAlertsSearch,
  getAlertsHistory,
  exportAlertEvents,
}
