import Icon from '@app/new-lucentum/components/Icon'
import { Block, Button, Detail as DetailLCT, Image, Link, P, Table } from '@new-lucentum'
import Highlighter from 'react-highlight-words'
import styled, { css } from 'styled-components'

export const Black = styled.b`
  color: black;
  margin-left: ${({ theme }) => theme.lineThick};
`

export const Span = styled.span`
  margin-right: ${({ theme }) => theme.sizeSmall};
`
export const Detail = styled(DetailLCT)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const StyledHighlighter = styled(Highlighter)`
  .hightlighted {
    background-color: ${({ theme }) => theme.color};
    color: ${({ theme }) => theme.colorWhite};
    padding: 3px;
  }
`
export const LinkDetails = styled(Link)`
  & > span {
    white-space: pre-line;
    height: 68px;
    max-height: 68px;
    overflow: hidden;
    color: ${({ theme }) => theme.colorGray};
    ${({ strongStyled, theme }) =>
      strongStyled &&
      css`
        font-weight: bold;
        color: ${theme.resultsLinkDetails};
      `};
  }
`

export const StyledBlock = styled(Block)`
  position: relative;
  .ReactTable {
    .rt-table {
      overflow: visible;
      overflow-x: auto;
    }
    & .rt-thead {
      & .rt-th {
        white-space: normal;
      }
    }
    & .rt-tbody {
      overflow-x: visible;
      overflow-y: visible !important;

      & .rt-tr {
        min-height: 80px;
      }
      & .rt-td {
        & > *:first-child:not(.termList) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .text-ellipsis-2-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.25rem;
        max-height: 2.5rem;
      }
      .text-ellipsis-3-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        max-height: 4rem;
      }
    }
  }
`
export const TrademarkImage = styled(Image)`
  max-height: 45px;
  max-width: 120px;
`

export const StyledTooltip = styled(Block)`
  pointer-events: auto;
  ${({ dsGridView }) => dsGridView && 'max-width: 300px;'};
  ${({ tmGridView }) => tmGridView && 'max-width: 300px;'};
  ${({ tmGraphic }) => tmGraphic && 'max-width: 200px;'};
  img {
    width: 100%;
  }
  .single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledSpanWithIcon = styled.span`
  display: flex;
  align-items: center;
`

export const StyledRightIconInSpan = styled(Icon)`
  margin-left: 7px;
  cursor: pointer;
  color: ${({ theme }) => theme.colorGrayLight};
`

export const ListTooltip = styled(Block)`
  max-width: 320px;
  white-space: normal;
`

export const NoResultFound = styled(Block)`
  text-align: center;
`
export const TableMainResults = styled(Table)`
  .rt-tr-group {
    &.expanded::before {
      background-color: #2f509a;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 3px;
    }
  }
`

export const Status = styled(P)`
  color: ${({ endedColor, theme }) => (endedColor ? theme.colorError : theme.colorSuccess)};
`

export const SortSelect = styled(Button)`
  & > button {
    min-width: 120px;
    min-height: 31px;
  }
`

export const Comma = styled.span`
  display: inline-block;
  margin-right: 3px;
`

export const InfoIcon = styled(Icon)`
  color: #2f509a;

  &:not(:first-child) {
    margin-left: 10px;
  }
`
