import React from 'react'
import { ErrorMessage } from 'formik'
import styled from 'styled-components'

const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.colorError};
  font-size: 0.85rem;
`

export default props => <ErrorMessage {...props} component={StyledErrorMessage} />
