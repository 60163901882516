import styled from 'styled-components'

export const DropdownMenu = styled.div`
  max-width: 300px;
  min-width: 250px;
`

export const Styled = {
  DropdownMenu,
}
