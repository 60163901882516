import React from 'react'
import { AsyncTypeahead, Hint, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead'
import { Button } from '@new-lucentum'
import styled from 'styled-components'
import { StyledTypeaheadNew } from './styles'
import { EVENT_KEY_CODES } from '@app/common/constants'

const { UP, DOWN, ENTER } = EVENT_KEY_CODES

const Spinner = styled.div`
  border: 2px solid ${({ theme }) => theme.colorGrayAccent};
  border-radius: 50%;
  border-top: 2px solid ${({ theme }) => theme.color};
  width: 11px;
  height: 11px;
  animation: load3 1.2s linear infinite;

  @keyframes load3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const TypeaheadNewComponent = ({
  onSearch,
  onBlur,
  onChange,
  clearButton,
  id = 'typeahead',
  submit,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [lastKeyDown, setLastKeyDown] = React.useState(null)

  const handleSearch = async query => {
    setIsLoading(true)
    try {
      const response = await onSearch(query)
      setOptions(response.options)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const handleBlur = React.useCallback(
    event => {
      const { value } = event.target
      onBlur && onBlur(value)
    },
    [onBlur]
  )

  const handleOnChange = React.useCallback(
    ([value]) => {
      const inputValue = value ? value[props.labelKey] : ''
      onChange && onChange(inputValue)
    },
    [onChange]
  )

  const handleKeyDown = React.useCallback(
    e => {
      const { value } = event.target
      const eventNumber = e.which
      if (!!options.length && [UP, DOWN].includes(eventNumber)) {
        setLastKeyDown(eventNumber)
      } else if (eventNumber === ENTER) {
        if ([UP, DOWN].includes(lastKeyDown)) {
          setLastKeyDown(eventNumber)
        } else {
          submit && submit(value)
        }
      }
    },
    [submit, lastKeyDown, setLastKeyDown, options]
  )

  return (
    <StyledTypeaheadNew>
      <AsyncTypeahead
        id={id}
        isLoading={isLoading}
        minLength={3}
        onSearch={handleSearch}
        onBlur={handleBlur}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        searchText={''}
        promptText={''}
        options={options}
        renderMenu={(results, menuProps, state) => {
          if (!results.length) {
            return null
          }
          return (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem key={index} option={result} position={index}>
                  <Highlighter search={state.text}>{result[props.labelKey]}</Highlighter>
                </MenuItem>
              ))}
            </Menu>
          )
        }}
        {...props}
      >
        {({ onClear, selected, ...restProps }) => (
          <div className="rbt-aux">
            {!!selected.length && clearButton && (
              <Button
                className="button-clear"
                icon={'close'}
                onClick={onClear}
                borderless
                style={{ pointerEvents: 'auto' }}
              />
            )}
            {isLoading && <Spinner />}
          </div>
        )}
      </AsyncTypeahead>
    </StyledTypeaheadNew>
  )
}

export const TypeaheadNew = TypeaheadNewComponent
