import styled, { css } from 'styled-components'
import TooltipToBeStyled from '../Tooltip'
import LinkToBeStyled from '../Link'

export const Tooltip = styled(TooltipToBeStyled)`
  max-width: 500px;
  > span {
    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export default styled.div`
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  ${props =>
    props.theme.responsive(css`
      font-weight: bold;
    `)};
  a {
    margin: 0 ${({ theme }) => theme.sizeSmall};
    text-decoration: none;
    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:first-child {
      margin-right: -${({ theme }) => theme.sizeSmall};
    }
  }

  i {
    font-size: calc(${({ theme }) => theme.size} + 3px);
  }

  span {
    margin-top: -${({ theme }) => theme.lineThick};
  }
`

export const Div = styled.div`
  ${({ theme }) => (theme.isRTL({}) ? `transform: rotate(180deg) scaleX(1);` : `transform:none;`)};
  ${props =>
    props.theme.responsive(css`
      display: flex;
    `)};
`

export const Link = styled(LinkToBeStyled)`
  ${props => !!props.unsetLinkPointerEvents && 'pointer-events: unset; cursor: default;'}
  ${props =>
    props.theme.responsive(css`
      width: 40px;
      height: 40px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${props.theme.color};
      border-radius: 2px;
      font-weight: bold;

      i {
        font-size: 20px;
        font-weight: bold;
      }

      &.link-left {
        margin-left: 16px;
      }

      a {
        &:first-child {
          margin-right: unset;
        }
      }

      ${props.disabled &&
        css`
          background-color: ${props.theme.colorGrayAccent};
          border-color: ${props.theme.colorGrayAccent};
          color: white;
        `}
    `)}
`
