import {
  setUserPreference,
  toggleRequestCertificateModal,
  userPreferencesSubmitted,
} from '@app/actions/certificate'
import { t } from '@app/common/translationsService'
import { Checkbox, Modal } from '@new-lucentum'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .close-modal-wrapper {
    position: absolute;
    padding: 20px;
    margin: 0;
  }
`

const UserPreferencesModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.uiState.certificate.isOpen)
  const [checkedValue, setChackedValue] = useState(false)

  const closeRequest = () => {
    dispatch(toggleRequestCertificateModal())
  }

  const onSubmitHandler = () => {
    dispatch(userPreferencesSubmitted({ userPreferencesSubmitted: true }))
    dispatch(setUserPreference({ certificateLegalNotesShown: !checkedValue }))
  }

  const userPrefHandler = value => {
    setChackedValue(value)
  }

  return (
    <StyledModal
      noTitle
      isOpen={isOpen}
      onRequestClose={closeRequest}
      textButtonClose={t('cancel.key')}
      textButtonSubmit={t('accept.key')}
      shouldCloseOnOverlayClick={false}
      onSubmit={onSubmitHandler}
      customBottomLeftAction={
        <Checkbox
          label={t('do.not.show.this.message.again')}
          checked={checkedValue}
          value={!checkedValue}
          onChange={e => {
            const isChecked = e.target.checked
            userPrefHandler(isChecked)
          }}
        />
      }
    >
      <span>{t('certificate.user.preferences.modal.message')}</span>
    </StyledModal>
  )
}

export default UserPreferencesModal
