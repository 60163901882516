import styled from 'styled-components'
import Button from '../../Button'

export default styled.div`
  ${({ theme, marginLevel }) => {
    switch (marginLevel) {
      case 1:
        return `padding-left: ${theme.size}!important`
      case 2:
        return `padding-left: calc(${theme.size} * 3.2)!important`
      case 3:
        return `padding-left: calc(${theme.size} * 5.4)!important`
      default:
        break
    }
  }};
`

export const ChevronStyled = styled(Button)`
  ${({ chevronDown }) => chevronDown && `transform: rotate(-90deg)`};
`
