import { Flex, Grid } from '@new-lucentum'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from '@app/common/translationsService'

import { DropdownMenu, CertificatesPoweredBy } from '../CertificatesButton.styles'
import { setDropdownOptions } from '../utils'
import CertificatesItem from './CertificatesItem'

const CertificatesDropdownMenu = ({ st13 }) => {
  const [certificateTypes, setCertificateTypes] = useState([])
  const certificateTypesFromState = useSelector(
    state => state.uiState.certificate.certificate.types
  )

  useEffect(
    () => {
      setCertificateTypes(setDropdownOptions(certificateTypesFromState))
    },
    [certificateTypesFromState]
  )

  return (
    <DropdownMenu>
      <Flex column fullHeight justify="space-between">
        <Grid.Group column distance={1}>
          {certificateTypes.map((type, index) => (
            <CertificatesItem
              key={index}
              label={type.label}
              description={type.description}
              type={type.certificateType}
              st13={st13}
            />
          ))}
        </Grid.Group>
        <CertificatesPoweredBy>{t('certificates.powered')}</CertificatesPoweredBy>
      </Flex>
    </DropdownMenu>
  )
}

export default CertificatesDropdownMenu
