import React from 'react'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button, Tooltip, Modal, Block, Notification } from '@new-lucentum'
import { IconButton, IconDropdown } from '../IconButton'
import { t } from '@app/common/translationsService'

class ExportButton extends React.Component {
  defaultState = {
    error: false,
    typeExport: '',
    exportMenuOpen: false,
    modalOpen: false,
    showRecaptchaError: false,
    isSubmitButtonDisabled: true,
  }

  constructor(props) {
    super(props)
    const { reCaptchaEnabled = false } = props
    this.state = {
      ...this.defaultState,
      isSubmitButtonDisabled: reCaptchaEnabled,
    }
  }

  handleExportOptions = fileType => this.setState({ modalOpen: true, typeExport: fileType })

  setExportMenuOpen = menuOpen => this.setState({ exportMenuOpen: menuOpen })

  resetState = reCaptchaEnabled => {
    this.setState({ ...this.defaultState, isSubmitButtonDisabled: reCaptchaEnabled })
    this.props.resetRecaptchaResponseKey()
  }

  setRecaptchaErrorVisibility = visibility => {
    this.setState(prevState => ({ showRecaptchaError: visibility }))
  }

  exportItems = (
    recaptchaResponseKey,
    reCaptchaEnabled,
    exportAction,
    fileName,
    items,
    languageTranslate,
    query,
    queryString
  ) => {
    if (recaptchaResponseKey === null && reCaptchaEnabled) {
      this.setRecaptchaErrorVisibility(true)
      return false
    }
    exportAction(
      this.state.typeExport,
      fileName,
      items,
      recaptchaResponseKey,
      languageTranslate && languageTranslate !== null ? languageTranslate.toUpperCase() : '',
      query,
      queryString
    )
    this.resetState(reCaptchaEnabled)
    return true
  }

  render() {
    const {
      languageTranslate,
      exportAction,
      items,
      reCaptchaEnabled,
      reCaptchaSiteKey,
      recaptchaResponseKey,
      changeExportCaptcha,
      query,
      queryString,
      exportOptions,
      fileName,
    } = this.props
    const fileTypes = Object.keys(exportOptions)
    return (
      <div>
        {this.state.modalOpen && (
          <Modal
            dataTestId="security-check-modal"
            isOpen={this.state.modalOpen}
            onSubmit={() =>
              this.exportItems(
                recaptchaResponseKey,
                reCaptchaEnabled,
                exportAction,
                fileName,
                items,
                languageTranslate,
                query,
                queryString
              )
            }
            onRequestClose={() => this.resetState(reCaptchaEnabled)}
            textButtonClose={t('close.key')}
            textButtonSubmit={t('tmdsv.captcha.button.download.key')}
            submitButtonProps={{
              'data-test-id': 'download-button',
              disabled: this.state.isSubmitButtonDisabled,
            }}
            title={t('tmdsv.captcha.main.title.key')}
          >
            {reCaptchaEnabled && (
              <Block center>
                <ReCAPTCHA
                  sitekey={reCaptchaSiteKey}
                  onChange={value => {
                    this.setState({ isSubmitButtonDisabled: false })
                    changeExportCaptcha(value)
                  }}
                />
              </Block>
            )}
            {this.state.showRecaptchaError && !recaptchaResponseKey && (
              <Notification error onClose={() => this.setState({ error: false })}>
                {t('recaptcha_missing')}
              </Notification>
            )}
          </Modal>
        )}
        {fileTypes.length === 1 ? (
          <Button
            ghost
            icon="exportFile"
            disabled={!Array.isArray(items) || !items.length}
            onClick={() => this.handleExportOptions(fileTypes[0])}
            data-for="export-details-button-tooltip"
            data-tip
            data-test-id="item-detail-export-button"
            isExpanded={this.props.isExpanded}
            title={t('export_key')}
          />
        ) : (
          <Block>
            <Button
              alignLeft
              ghost
              title={t('export_key')}
              type="dropdown"
              icon="exportFile"
              tooltipId="export-details-button-tooltip"
              onFocus={this.setExportMenuOpen}
              dropdownButtonProps={{
                'data-test-id': 'item-detail-export-button',
                isExpanded: this.props.isExpanded,
              }}
            >
              {fileTypes.map(fileType => (
                <Button
                  key={fileType}
                  id={fileType}
                  data-test-id={`${fileType}-button`}
                  title={exportOptions[fileType]}
                  onClick={() => this.handleExportOptions(fileType)}
                />
              ))}
            </Button>
            {!this.state.exportMenuOpen && (
              <Tooltip id="export-details-button-tooltip" place="bottom">
                <strong>{t('export_key')}</strong>
              </Tooltip>
            )}
          </Block>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({
  globals: {
    data: {
      reCaptcha: { reCaptchaEnabled, reCaptchaSiteKey },
    },
  },
}) => ({
  reCaptchaEnabled,
  reCaptchaSiteKey,
})

export default connect(mapStateToProps)(ExportButton)
