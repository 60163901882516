import { fetchDetail } from '@app/actions/detail'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t } from '@app/common/translationsService'
import { Carousel } from '@app/components/DetailDS/components'
import { Block, Flex } from '@new-lucentum'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Content, TableCol, TableRow } from './styledHistoryLayoutData'

function HistoryHeaderDS({ ...props }) {
  const isLoading = useSelector(state => state.appState.history.isLoading)
  const details = useSelector(state => state.appState.detail.data)

  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(fetchDetail(props.match.params.id))
    },
    [dispatch, props.match.params.id]
  )

  let data = props.info

  return (
    !isLoading && (
      <Content>
        <Flex>
          <Block maxWidth className="block-table">
            <TableRow>
              {Array.isArray(details.representations) &&
                details.representations &&
                details.representations.length > 0 && (
                  <div className="imageContainer">
                    <Carousel
                      maxHeight={'130px'}
                      imageUrls={details.representations}
                      smallerChevron={true}
                      thumbnailKey="thumbnailURI"
                      imageKey="imageURI"
                    />
                  </div>
                )}
              <TableCol style={{ flexGrow: 3 }}>
                <TableRow>
                  <TableCol>
                    <TableRow>{t('applicationnumber.key')}</TableRow>
                    <TableRow>
                      <span
                        className="link-number"
                        onClick={() => {
                          TMDSRouterService.navigate(`/detail/${encodeURIComponent(details.ST13)}`)
                        }}
                      >
                        {data?.applicationNumber}
                      </span>
                    </TableRow>
                  </TableCol>
                  <TableCol style={{ flexGrow: 3 }}>
                    <TableRow>{t('history.registration.office.key')}</TableRow>
                    <TableRow className="color-gray">{data?.registrationOffice}</TableRow>
                  </TableCol>
                </TableRow>
              </TableCol>
            </TableRow>
          </Block>
        </Flex>
      </Content>
    )
  )
}

export default HistoryHeaderDS
