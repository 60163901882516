import { resetProcess } from '@app/actions/certificate'
import { t } from '@app/common/translationsService'
import { Modal } from '@new-lucentum'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .close-modal-wrapper {
    position: absolute;
    padding: 20px;
    margin: 0;
  }
`

const CertificatesRequestSuccessModal = () => {
  const dispatch = useDispatch()
  const isRequestSuccessful = useSelector(
    state => state.uiState.certificate.certificate.isRequestSuccessful
  )

  const closeRequest = () => {
    dispatch(resetProcess())
  }

  return (
    <StyledModal
      noTitle
      isOpen={isRequestSuccessful}
      onRequestClose={closeRequest}
      textButtonSubmit={t('ok.key')}
      shouldCloseOnOverlayClick={false}
      onSubmit={closeRequest}
    >
      <span>{t('request.certificate.success.modal.message')}</span>
    </StyledModal>
  )
}

export default CertificatesRequestSuccessModal
