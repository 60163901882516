import Api from '@app/common/api'
import TMDSContextService from '@app/common/tmdsContextService'
import { setAppCookie } from '@app/common/utilities'
import { JAPAN_VERSION } from '@app/containers/TMSearch/constants'

const api = new Api()

const GENERAL_PATH = '/general'
const PATHS = {
  GLOBALS: {
    dsview: 'dsv/globals',
    tmview: 'globals',
  },
  LITERALS: {
    dsview: 'dsv/literals',
    tmview: 'literals',
  },
  NEWS: {
    dsview: 'news/dsv',
    tmview: 'news/tmv',
  },
  BOOKMARK: {
    dsview: 'bookmark',
    tmview: 'bookmark',
  },
  LANGUAGE: {
    dsview: 'language',
    tmview: 'language',
  },
  APPLICATION_OFFICE: {
    dsview: 'application/applicationOffice/dsv',
    tmview: 'application/applicationOffice/tmv',
  },
  VIENNA_CODE: {
    dsview: '',
    tmview: 'viennacode',
  },
  LOCARNO_CODE_WIPO: {
    dsview: 'dsv/locarno',
    tmview: '',
  },
  LOCARNO_CODE_JAPAN: {
    dsview: 'dsv/japanese-classes',
    tmview: '',
  },
  WIPO_SEARCH: {
    dsview: 'wipoSearch/dsv',
    tmview: 'wipoSearch',
  },
  FEEDBACK: {
    dsview: 'dsv/feedback',
    tmview: 'feedback',
  },
  EMAIL: {
    dsview: 'email',
    tmview: 'email',
  },
  SHORT_LINK: {
    dsview: 'shortlink',
    tmview: 'shortlink',
  },
  GOOD_AND_SERVICES: {
    dsview: '',
    tmview: 'goodServices',
  },
  INDICATION_OF_PRODUCT: {
    dsview: 'indicationOfProduct',
    tmview: '',
  },
  TOUR: {
    dsview: 'tour/dsv',
    tmview: 'tour/tmv',
  },
}

const noCache = {
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: 0,
  },
}

const context = TMDSContextService.get

const getGlobals = async language => {
  const url = `${GENERAL_PATH}/${PATHS.GLOBALS[context()]}?language=${language}`
  return api.get(url)
}

const getTour = () => {
  const url = `${GENERAL_PATH}/${PATHS.TOUR[context()]}`
  const options = { ...noCache }
  return api.get(url, options)
}

const getLiterals = language => {
  const url = `${GENERAL_PATH}/${PATHS.LITERALS[context()]}?language=${language}`
  return api.get(url)
}

const getNews = () => {
  const url = `${GENERAL_PATH}/${PATHS.NEWS[context()]}`
  const options = { ...noCache }
  return api.get(url, options)
}

const getBookmark = query => {
  const url = `${GENERAL_PATH}/${PATHS.BOOKMARK[context()]}${query}`
  return api.get(url)
}

const getApplicationOffices = () => {
  const url = `${GENERAL_PATH}/${PATHS.APPLICATION_OFFICE[context()]}`
  const options = {
    ...noCache,
  }
  return api.get(url, options)
}

const changeLanguage = language => {
  setAppCookie('language', language)

  return Promise.resolve()

  /**
   * @deprecated will be remove in the future
   */
  // const url = `${GENERAL_PATH}/${PATHS.LANGUAGE[context()]}`
  // const payload = { language: language }
  // const options = {
  //   body: payload,
  //   ...noCache,
  // }
  // return api.put(url, options)
}

const getViennaCodeHierarchy = (language, viennaCode) => {
  let url = `${GENERAL_PATH}/${PATHS.VIENNA_CODE[context()]}/hierarchy/${language}`
  if (viennaCode) {
    url += `/${viennaCode}`
  }
  return api.get(url)
}

const getViennaCodeSearch = (language, filter) => {
  let url = `${GENERAL_PATH}/${PATHS.VIENNA_CODE[context()]}/search/${language}/${filter}`
  return api.get(url)
}

const getWipoSearch = query => {
  let url = `${GENERAL_PATH}/${PATHS.WIPO_SEARCH[context()]}${query}`
  return api.get(url)
}

const sendFeedback = payload => {
  const url = `${GENERAL_PATH}/${PATHS.FEEDBACK[context()]}`
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const sendEmail = payload => {
  const url = `${GENERAL_PATH}/${PATHS.EMAIL[context()]}`
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const doShortLink = searchQuery => {
  const url = `${GENERAL_PATH}/${PATHS.SHORT_LINK[context()]}`
  const options = {
    body: { searchQuery },
    headers: { Accept: 'text/plain' },
  }
  return api.post(url, options)
}

const translateGoodAndServices = (sourceLanguage, targetLanguage, payload) => {
  const url = `/${PATHS.GOOD_AND_SERVICES[context()]}/${sourceLanguage}/${targetLanguage}`
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

export const translateIndicationOfProduct = (sourceLanguage, targetLanguage, payload) => {
  const url = `/${
    PATHS.INDICATION_OF_PRODUCT[context()]
  }/translate/${sourceLanguage}/${targetLanguage}`
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const getLocarnoHierarchy = (language, locarnoVersion, locarnoCode) => {
  const isJapanCodeVersion = locarnoVersion === JAPAN_VERSION
  const locarnoCodeVersionPath =
    (isJapanCodeVersion && PATHS.LOCARNO_CODE_JAPAN[context()]) ||
    PATHS.LOCARNO_CODE_WIPO[context()]
  let url = `${GENERAL_PATH}/${locarnoCodeVersionPath}/hierarchy/${(!isJapanCodeVersion &&
    language) ||
    ''}`
  if (locarnoCode) {
    url += `/${locarnoCode}`
  }
  return api.get(url)
}

const getLocarnoAutocomplete = (language, locarnoVersion, filter, exclude) => {
  const isJapanCodeVersion = locarnoVersion === JAPAN_VERSION
  const locarnoCodeVersionPath =
    (isJapanCodeVersion && PATHS.LOCARNO_CODE_JAPAN[context()]) ||
    PATHS.LOCARNO_CODE_WIPO[context()]
  let url = `${GENERAL_PATH}/${locarnoCodeVersionPath}/search/${(!isJapanCodeVersion &&
    `${language}/`) ||
    ''}${filter}`
  if (exclude) {
    url += `&exclude=${exclude}`
  }
  return api.get(url)
}

const translateLocarno = (sourceLanguage, targetLanguage, payload) => {
  const url = `/${PATHS.LOCARNO_CODE_WIPO[context()]}/${sourceLanguage}/${targetLanguage}`
  const options = {
    body: payload,
  }
  return api.post(url, options)
}

const getVersions = () => {
  const url = api.get('internal/search/version')
  return url
}

export const generalDataManager = {
  getGlobals,
  getLiterals,
  getTour,
  getNews,
  getBookmark,
  getApplicationOffices,
  getViennaCodeHierarchy,
  getViennaCodeSearch,
  getWipoSearch,
  changeLanguage,
  sendFeedback,
  sendEmail,
  doShortLink,
  translateGoodAndServices,
  translateIndicationOfProduct,
  getLocarnoAutocomplete,
  getLocarnoHierarchy,
  translateLocarno,
  getVersions,
}
