import { fullfilled, pending, rejected } from './utils/reduxPromise'
const GET_ALERTS = 'GET_ALERTS'
const GET_ALERTS_BY_IDS = 'GET_ALERTS_BY_IDS'
const SET_ALERTS = 'SET_ALERTS'
const GET_ALERTS_HISTORY = 'GET_ALERTS_HISTORY'

export const ALERTS_TYPES = {
  GET_ALERTS,
  GET_ALERTS_FULLFILLED: fullfilled(GET_ALERTS),
  GET_ALERTS_PENDING: pending(GET_ALERTS),
  GET_ALERTS_REJECTED: rejected(GET_ALERTS),
  GET_ALERTS_BY_IDS,
  GET_ALERTS_BY_IDS_FULFILLED: fullfilled(GET_ALERTS_BY_IDS),
  GET_ALERTS_BY_IDS_PENDING: pending(GET_ALERTS_BY_IDS),
  GET_ALERTS_BY_IDS_REJECTED: rejected(GET_ALERTS_BY_IDS),
  SET_ALERTS,
  SET_ALERTS_FULFILLED: fullfilled(SET_ALERTS),
  SET_ALERTS_PENDING: pending(SET_ALERTS),
  SET_ALERTS_REJECTED: rejected(SET_ALERTS),
  GET_ALERTS_HISTORY,
  GET_ALERTS_HISTORY_FULFILLED: fullfilled(GET_ALERTS_HISTORY),
  GET_ALERTS_HISTORY_PENDING: pending(GET_ALERTS_HISTORY),
  GET_ALERTS_HISTORY_REJECTED: rejected(GET_ALERTS_HISTORY),
}
