import { Block, Button, Tooltip as TooltipLucentum } from '@new-lucentum'
import styled from 'styled-components'

export const completHover = ({ color }) => `
    position: relative;
    &:before{
        background-color: ${color};
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 3px;
    }
`

export const scroll = ({ color }) => `
    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${color};
        border-radius: 100px;
        height: 50%;
    }
`

export const BlockTooltip = styled(Block)`
  max-width: 300px;
`

export const Wrapper = styled.div`
  position: relative;

  .Select--single.is-focused .Select-control:after,
  .Select--multi.is-focused .Select-control:after {
    background: none;
    border: solid ${({ theme }) => `${theme.lineThick} ${theme.colorLight}`};
    bottom: 0;
    content: '';
    left: 0;
    overflow: visible;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  .show:not(.__react_component_tooltip) {
    display: none !important;
  }

  .Select-aria-only {
    display: none;
  }

  .Select-control {
    border-radius: ${({ theme }) => theme.radius};
    border: ${({ theme }) => `${theme.line} solid ${theme.colorGray}`};
    height: auto;
    padding-left: ${({ theme }) => theme.size};
    padding-right: ${({ theme }) => theme.size};
    > *:last-child {
      padding-right: 0;
    }
  }

  .Select-arrow-zone {
    text-align: right;
  }

  .Select-placeholder {
    line-height: inherit;
  }

  .Select-clear {
    cursor: pointer;
    margin-left: 3px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .Select-clear-zone {
    color: ${({ theme }) => theme.colorGrayLight};
    .Select-clear {
      ${({ theme }) => theme.baseText};
      color: ${({ theme }) => theme.colorGrayLight};
    }
  }

  .Select-input {
    height: inherit;
    margin-left: ${({ theme }) => theme.sizeSmall};
    padding-left: 0;
    padding-right: 0;

    & input {
      padding: ${({ theme }) => theme.sizeSmall} 0;
      border: none;
    }
  }

  .Select-clear-zone:hover {
    color: ${({ theme }) => theme.color};
  }

  .Select-menu-outer {
    border-radius: ${({ theme }) => theme.radius};
    border: unset;
    box-shadow: ${({ theme }) => theme.dropdownShadow};
    margin-top: ${({ theme }) => theme.size};
    max-height: ${({ theme }) => `calc(${theme.sizeCuadruple}*${theme.itemsToScroll})`};
    z-index: 100;
    .Select-menu {
      ${({ type }) => type === 'nice' && ' overflow: hidden;'};
    }
  }

  .Select-menu {
    ${({ theme }) => scroll(theme)};
    max-height: ${({ theme }) => `calc(${theme.sizeCuadruple}*${theme.itemsToScroll})`};
  }

  .Select-option {
    align-items: center;
    border-bottom: ${({ theme }) => `${theme.line} solid ${theme.colorGrayAccent}`};
    display: flex;
    height: ${({ theme }) => theme.sizeCuadruple};
    padding: 0 ${({ theme }) => theme.sizeLarge};
    text-align: left;
    button {
      margin-right: ${({ theme }) => theme.sizeSmall};
      padding: ${({ theme }) => theme.size} 0;
      &,
      &:hover {
        background-color: ${({ theme }) => theme.colorTransparent};
        color: initial;
      }
    }
    b {
      color: ${({ theme }) => theme.color};
    }
    & > span {
      align-items: center;
      display: flex;
      height: 100%;
      overflow: hidden;
      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    & > div {
      padding: ${({ theme }) => theme.size} 0;
      white-space: nowrap;
      width: 100%;
    }
    &:hover {
      ${({ theme }) => completHover(theme)};
      background-color: ${({ theme }) => theme.colorAccent};
    }
    &.is-disabled {
      color: ${({ theme }) => theme.colorGrayLight};
      pointer-events: none;
    }
    &.is-focused:not(.is-disabled) {
      ${({ theme }) => completHover(theme)};
      background-color: ${({ theme }) => theme.colorAccent};
    }
  }

  .Select-multi-value-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  .Select-value {
    background-color: ${({ theme }) => theme.colorWhite};
    border-radius: 2px;
    border: solid 1px ${({ theme }) => theme.colorGray};
    color: ${({ theme }) => theme.colorGray};
    margin: ${({ theme }) => `${theme.lineThick} ${theme.sizeSmall} ${theme.lineThick} 0`};
    padding: 0;
    position: relative;
    white-space: nowrap;
  }
  /* TMView */
  .Select-option.TMView {
    color: ${({ theme }) => theme.color};
    width: 100%;
    &:first-of-type.is-disabled {
      background-color: ${({ theme }) => theme.colorWhite};
      padding-bottom: 0;
      position: absolute;
      white-space: nowrap;
      width: calc(100% - 11px); // 11px of the scroll.
      z-index: 99;
      & + .Select-option:nth-child(2) {
        margin-top: ${({ theme }) => theme.sizeLarge} * 2;
      }
    }
    & span strong {
      color: ${({ theme }) => theme.colorGrayLight};
      font-weight: normal;
    }
    & div {
      display: inline-block;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      vertical-align: top;
      &:nth-child(1) {
        width: 35%;
      }
      &:nth-child(2) {
        width: 22%;
      }
      &:nth-child(3) {
        width: 22%;
      }
      &:nth-child(4) {
        width: 21%;
      }
    }
  }

  .highlighted {
    ${({ theme }) => theme.baseText};
    background-color: ${({ theme }) => theme.colorGrayAccent};
    color: ${({ theme }) => theme.colorGray};
  }
`

export const TooltipValue = styled(TooltipLucentum)`
  z-index: 99999;
  & > div {
    max-width: 280px;
  }
`

export const ViennaStyled = styled.div`
  ${({ theme, marginLevel }) => {
    switch (marginLevel) {
      case 1:
        return `padding-left: ${theme.size}!important`
      case 2:
        return `padding-left: calc(${theme.size} * 3.2)!important`
      case 3:
        return `padding-left: calc(${theme.size} * 5.4)!important`
      default:
        break
    }
  }};
  > .itemValue {
    padding-left: ${({ theme }) => theme.size} !important;
  }
`

export const ChevronStyled = styled(Button)`
  ${({ chevronDown }) => chevronDown && `transform: rotate(-90deg)`};
`
