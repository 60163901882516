import { fullfilled, pending, rejected } from './utils/reduxPromise'
const GET_TAGS = 'GET_TAGS'
const GET_TAGS_BY_IDS = 'GET_TAGS_BY_IDS'
const SET_TAGS = 'SET_TAGS'

export const TAGS_TYPES = {
  GET_TAGS,
  GET_TAGS_FULLFILLED: fullfilled(GET_TAGS),
  GET_TAGS_PENDING: pending(GET_TAGS),
  GET_TAGS_REJECTED: rejected(GET_TAGS),
  GET_TAGS_BY_IDS,
  GET_TAGS_BY_IDS_FULFILLED: fullfilled(GET_TAGS_BY_IDS),
  GET_TAGS_BY_IDS_PENDING: pending(GET_TAGS_BY_IDS),
  GET_TAGS_BY_IDS_REJECTED: rejected(GET_TAGS_BY_IDS),
  SET_TAGS,
  SET_TAGS_FULFILLED: fullfilled(SET_TAGS),
  SET_TAGS_PENDING: pending(SET_TAGS),
  SET_TAGS_REJECTED: rejected(SET_TAGS),
}
