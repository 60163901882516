import TMDSContextService from '@app/common/tmdsContextService'
import { t } from '@app/common/translationsService'
import TMForm from '@app/components/TMForm'
import ToggleCheckbox from '@app/components/ToggleButton/ToggleCheckbox'
import { Block, Button, Flex, Form, Grid } from '@new-lucentum'
import React from 'react'
import { compose, lifecycle, withHandlers } from 'recompose'
import styled from 'styled-components'

import { Styled } from '../AlertButton.mobile.styles'
import { checkDaysLeft, checkForTriggeredValues, getBooleanKeys } from '../utils'
import { AlertFormDsview, alertFormEnhancer, AlertFormTmview } from './AlertForm'

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colorError};
  font-size: 0.85rem;
`

const AlertDropdownMenuMobileComponent = props => {
  const {
    setIsModalOpen,
    applyDisabled,
    errors,
    isSubmitting,
    handleSubmit,
    values,
    setFieldValue,
    handleReset,
    resourceData,
    st13List,
    handleWarningDateValidation,
  } = props

  const AlertForm = TMDSContextService.isTmview() ? AlertFormTmview : AlertFormDsview

  const isBulk = Array.isArray(st13List) && st13List.length > 1

  const toggleDropdown = () => {
    setIsModalOpen(false)
  }

  return (
    <Styled.DropdownMenuMobile>
      <Grid.Row>
        <Block marginBottom>{t('alerts_dropdown_information_text')}</Block>
      </Grid.Row>
      <Grid.Row>
        <Flex justify="space-between">
          <ToggleCheckbox
            label={t('select_all')}
            toggleFn={isActive => {
              if (isActive) {
                const booleanKeys = getBooleanKeys(values)
                booleanKeys.forEach(key => {
                  setFieldValue(key, true)
                })
              } else {
                handleReset()
              }
            }}
          />
        </Flex>
      </Grid.Row>
      <Form>
        <AlertForm
          values={values}
          handleWarningDateValidation={handleWarningDateValidation}
          resourceData={resourceData}
          isBulk={isBulk}
        />
        <Grid.Container marginTop>
          {Object.keys(errors).map(error => (
            <Grid.Spacing mb key={`${error}-error`}>
              <ErrorMessage>{errors[error]}</ErrorMessage>
            </Grid.Spacing>
          ))}
          <TMForm.WarningMessage all exceptions={['backend']} />
          <TMForm.WarningMessage name="backend" isError />
        </Grid.Container>
        <Flex justify="space-around" gap="5px">
          <Button
            type="submit"
            title={t('cancel.key')}
            secondary
            onClick={toggleDropdown}
            disabled={isSubmitting}
            data-testid="login-button"
            style={{ flex: '1' }}
          />
          <Button
            type="submit"
            title={t('apply.key')}
            primary
            onClick={handleSubmit}
            disabled={(applyDisabled && checkForTriggeredValues(values) === 0) || isSubmitting}
            data-testid="edit-alert-button"
            style={{ flex: '1' }}
          />
        </Flex>
      </Form>
    </Styled.DropdownMenuMobile>
  )
}
export const AlertDropdownMenuMobile = compose(
  alertFormEnhancer,
  lifecycle({
    componentDidMount() {
      const { setValues, preloadedValues } = this.props
      preloadedValues && setValues(preloadedValues)
    },
  }),
  withHandlers({
    handleWarningDateValidation: props => (resourceField, field, value, warningMessage) => {
      const { setStatus, resourceData } = props
      const targetDate = resourceData && resourceData[resourceField]
      if (targetDate) {
        const daysLeft = checkDaysLeft(targetDate)
        const daysBeforeNotification = Number(value)
        const showWarning = daysLeft < daysBeforeNotification
        setStatus({
          ...(showWarning && {
            [field]: warningMessage,
          }),
        })
      }
    },
  })
)(AlertDropdownMenuMobileComponent)
export default AlertDropdownMenuMobile
