import { TagButton } from '@app//components/TagButton'
import { setOfficeMetadata } from '@app/actions/detail'
import { fetchHistory, fetchHistoryDS } from '@app/actions/history'
import { HistoryDataManager } from '@app/common/dataManagers'
import { TMDSGlobalsService, TMDSTrackingService } from '@app/common/services'
import TMDSContextService, { isTmview } from '@app/common/tmdsContextService'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t } from '@app/common/translationsService'
import { ActionBarExpandable } from '@app/components/ActionBarExpandable'
import { AlertButton } from '@app/components/AlertButton'
import ExportButton from '@app/components/ExportButton'
import { Sticky } from '@app/components/Sticky'
import { Button, Flex, Grid, Tooltip } from '@new-lucentum'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import GoToButtonDsview from '../DetailDS/GoToButton'
import ShareSearch from '../ShareSearch'
import { GoToButton as GoToButtonTmview } from './GoToButton'
import { OriginalButton } from './OriginalButton'
import TMNavigation from './TMNavigation'
import { ViewHistoryButton } from './ViewHistoryButton'

const BackButton = styled(Button)`
  padding: 10px;
  display: flex;
  align-items: center;
  i {
    font-size: inherit !important;
    transform: rotate(90deg);
  }
`

const StyledDetailActionBar = styled.div`
  flex: 1;
  .navigation {
    position: absolute;
    padding-bottom: 10px;
    right: 47%;
  }
`

const ActionBarComponent = ({ tmDetail = {}, ...props }) => {
  const GoToButton = TMDSContextService.isTmview() ? GoToButtonTmview : GoToButtonDsview
  const resourceData = TMDSContextService.isTmview() ? tmDetail.tradeMark : tmDetail.design
  const routeState = TMDSRouterService.getRouteState()
  const { fromSeniority } = routeState || {}
  const isTranslationEnabled = TMDSGlobalsService.getPath('globalConfig.detailsTranslationEnabled')
  const { regionalEnabled } = TMDSGlobalsService.getRegionalConfig()
  const urlToBack = props.navigationData && props.navigationData.URLToBack

  const [isHistoryVisible, setIsHistoryVisible] = useState(false)
  const [errorService, setErrorService] = useState(false)
  const [isBlockChain, setIsBlockchain] = useState(false)

  const historyData = useSelector(state => state.appState.history.data.transactionInstances)
  const isLoading = useSelector(state => state.appState.history.isLoading)
  const isIPregisterOperational = useSelector(state => state.appState.history.isIPOperational)

  const dispatch = useDispatch()

  const getHistory = () => {
    if (resourceData?.registrationOfficeCode) {
      HistoryDataManager.isBlockchain(resourceData.registrationOfficeCode).then(res => {
        dispatch(setOfficeMetadata(res))
        if (errorService === false && res.isBlockChain === true) {
          setIsBlockchain(true)
          dispatch(fetchHistory(props.match.params.id))
        }
      })
    }
  }

  const getHistoryDS = () => {
    if (tmDetail?.design?.registrationOfficeCode) {
      HistoryDataManager.isBlockchain(tmDetail.design.registrationOfficeCode).then(res => {
        dispatch(setOfficeMetadata(res))
        if (errorService === false && res.isBlockChain === true) {
          setIsBlockchain(true)
          dispatch(fetchHistoryDS(props.match.params.id))
        }
      })
    }
  }

  useEffect(() => {
    dispatch(setOfficeMetadata({ isBlockChain: false }))
    isTmview() ? getHistory() : getHistoryDS()
  }, [])

  useEffect(
    () => {
      if (!isLoading && isIPregisterOperational === false) {
        setErrorService(true)
      }
      isTmview() ? blockChain() : blockChainDS()
    },
    [isLoading, errorService, historyData]
  )

  const blockChain = () => {
    if (resourceData?.registrationOfficeCode) {
      if (errorService === false && isBlockChain === true && !isLoading && historyData.length > 1) {
        setIsHistoryVisible(true)
      }
    }
  }

  const blockChainDS = () => {
    if (tmDetail?.design?.registrationOfficeCode) {
      if (errorService === false && isBlockChain === true && !isLoading && historyData.length > 1) {
        setIsHistoryVisible(true)
      }
    }
  }

  const goToHistory = () => {
    TMDSRouterService.navigate(`/history/${encodeURIComponent(tmDetail.ST13)}`)
    TMDSTrackingService.trackHistoryAccess()
  }

  const [isExpanded, setIsExpanded] = React.useState(true)
  const [isCollapsedByUser, setIsCollapsedByUser] = React.useState(false)
  const [scrollTop, setScrollTop] = React.useState(0)
  const [isScrolledDown, setIsScrolledDown] = React.useState(false)

  const toggleExpanded = React.useCallback(
    () => {
      setIsCollapsedByUser(isExpanded)
      setIsExpanded(!isExpanded)
    },
    [isExpanded]
  )

  // Automatically collapse/expand action bar on scroll if it wasn't collapsed manually by the user
  React.useEffect(
    () => {
      const onScroll = debounce(e => {
        setScrollTop(e.target.documentElement.scrollTop)
        setIsScrolledDown(e.target.documentElement.scrollTop > 154)
      }, 10)
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    },
    [scrollTop]
  )
  React.useEffect(
    () => {
      if (!isCollapsedByUser) {
        setIsExpanded(!isScrolledDown)
      }
    },
    [isScrolledDown]
  )

  return (
    <Sticky>
      <ActionBarExpandable variant="details">
        {({ isExpanded }) => (
          <StyledDetailActionBar>
            <Flex flex={1} justify="space-between">
              <Flex alignItems="flex-end">
                {fromSeniority && (
                  <React.Fragment>
                    <BackButton
                      onClick={() => {
                        TMDSRouterService.goBack()
                      }}
                      borderless
                      icon="chevronDownSmall"
                    >
                      {t('button_back')}
                    </BackButton>
                  </React.Fragment>
                )}
                {!fromSeniority && urlToBack && (
                  <React.Fragment>
                    <BackButton
                      onClick={() => {
                        props.history.push(urlToBack)
                      }}
                      borderless
                      icon="chevronDownSmall"
                      title={t('button_back')}
                    />
                  </React.Fragment>
                )}
                <ExportButton
                  changeExportCaptcha={props.changeExportCaptcha}
                  exportOptions={{
                    doc: t('exportdoc_key'),
                    pdf: t('exportpdf.key'),
                  }}
                  exportAction={props.handleExport}
                  fileName="detail"
                  languageTranslate={props.uiState.detail.languageTranslate}
                  recaptchaResponseKey={props.uiState.detail.recaptchaResponseKey}
                  resetRecaptchaResponseKey={props.resetRecaptchaResponseKey}
                  items={[props.trademarkId]}
                  isExpanded={isExpanded}
                />

                {!regionalEnabled && (
                  <React.Fragment>
                    <AlertButton
                      st13List={[props.trademarkId]}
                      preload
                      resourceData={resourceData}
                      inheritPosition
                      buttonProps={{ isExpanded }}
                    />
                    <Grid.Spacing mr />
                  </React.Fragment>
                )}

                {!regionalEnabled && (
                  <React.Fragment>
                    <TagButton
                      st13List={[props.trademarkId]}
                      preload
                      resourceData={resourceData}
                      inheritPosition
                      buttonProps={{ isExpanded }}
                    />
                    <Grid.Spacing mr />
                  </React.Fragment>
                )}

                <Button
                  ghost
                  isExpanded={isExpanded}
                  title={t('results_share_by_email_key')}
                  data-test-id="share-email-button"
                  icon="shareEmail"
                  onClick={() => props.createShortLink(props.match.url)}
                  data-tip
                  data-for="email-button-tooltip"
                />
                <div className="navigation">
                  {props.navigationData && !fromSeniority && <TMNavigation {...props} />}

                  <Tooltip id="email-button-tooltip" place="bottom">
                    <strong>{props.literals.results_share_by_email_key}</strong>
                  </Tooltip>
                </div>
              </Flex>
              <Flex alignItems="center">
                {!isLoading && isHistoryVisible && <ViewHistoryButton onClick={goToHistory} />}
                <Grid.Spacing mr={2} />
                {isTranslationEnabled && <OriginalButton />}
                <Grid.Spacing mr={2} />
                <GoToButton
                  literals={props.literals}
                  hasItemsToShow={props.uiState.detail.hasItemsToShow}
                  history={props.history}
                />
              </Flex>
            </Flex>
            <ShareSearch
              reCaptchaSiteKey={props.reCaptcha.reCaptchaSiteKey}
              backEndErrorMessage={props.uiState.shareSearch.backEndErrorMessage}
              successShared={props.uiState.shareSearch.successShared}
              onChangeEmailField={props.onChangeEmailField}
              literals={props.literals}
              showModalShareByEmail={props.uiState.shareSearch.showModalShareByEmail}
              sendEmail={props.sendEmail}
              shareSearchFieldsValues={props.uiState.shareSearch.formState.fields}
              setShareEmailNotification={props.setShareEmailNotification}
              validateEntireForm={props.validateEntireForm}
              errorsShareLink={props.uiState.shareSearch.formState.errors}
              clearShareSearchForm={props.clearShareSearchForm}
              closeModalShareByEmail={props.closeModalShareByEmail}
            />
          </StyledDetailActionBar>
        )}
      </ActionBarExpandable>
    </Sticky>
  )
}

export default ActionBarComponent
