import React from 'react'
import styled from 'styled-components'
import { Block, TooltipTrigger, Icon } from '@new-lucentum'
import { safeParseHtml } from '@app/common/safeHTML'
import { NoInformation } from './NoInformation'

export const BoxPanel = styled(Block)`
  background-color: white;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  border-style: solid;
  border-width: 0px;
  box-shadow: ${({ theme: { line: px, colorBorderBox: c } }) =>
    `-${px} ${px} ${px} ${px} ${c} , ${px} 0px ${px} ${px} ${c}`};
  box-sizing: border-box;
  opacity: ${({ opacityLow }) => (opacityLow ? 0.3 : 1)};
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || '120px'};
`
export const Title = styled(Block)`
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizeMedium};
  font-weight: bold;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
`

const Anchor = styled.div`
  position: relative;
  top: -120px;
`

const createTooltip = content => (
  <Block>{content && <div dangerouslySetInnerHTML={{ __html: content }} />}</Block>
)

export const BoxTitle = ({ officeExceptionMessage, title }) => {
  return (
    <Block middle center>
      <Title>{title}</Title>
      {officeExceptionMessage && officeExceptionMessage.displayMessage && (
        <TooltipTrigger
          tooltipId={`${officeExceptionMessage.section}-message`}
          place="bottom"
          renderTooltip={createTooltip(officeExceptionMessage.text)}
          delayHide={500}
        >
          <Icon information style={{ color: '#939497', marginRight: 0 }} />
        </TooltipTrigger>
      )}
    </Block>
  )
}

export const Box = ({
  title,
  renderTitle,
  children,
  boxId = '',
  minHeight,
  hasItemsToShow,
  dataTestId,
  officeExceptionMessage,
  ...props
}) => (
  <BoxPanel
    opacityLow={!hasItemsToShow}
    padding
    minHeight={minHeight}
    data-test-id={dataTestId}
    {...props}
  >
    <Anchor id={boxId} />
    {(title || renderTitle) && !!renderTitle ? (
      renderTitle(title)
    ) : title ? (
      <BoxTitle officeExceptionMessage={officeExceptionMessage} title={title} />
    ) : null}
    <Block paddingTop>{!hasItemsToShow ? <NoInformation /> : children}</Block>
  </BoxPanel>
)

export default Box
