import { t } from '@app/common/translationsService'
import moment from 'moment'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { TableCol, TableRow } from './styles'

const CompareTable = props => {
  const historyData = useSelector(state => state.appState.history.data.transactionInstances)

  const selectedFirstOption = historyData.filter(x => x.historyDate === props.selectedFirstOption)
  const selectedSecOption = historyData.filter(x => x.historyDate === props.selectedSecOption)

  let firstOption = selectedFirstOption[0]
  let secondOption = selectedSecOption[0]

  const compareDatesFirstColumn = (date1, date2) => {
    let firstDate = moment(date1)
      .utc()
      .format('DD/MM/YYYY')
    let secondDate = moment(date2)
      .utc()
      .format('DD/MM/YYYY')
    if (firstDate !== secondDate) {
      return 'red'
    } else {
      return null
    }
  }

  const compareDatesSecondColumn = (date1, date2) => {
    let firstDate = moment(date1)
      .utc()
      .format('DD/MM/YYYY')
    let secondDate = moment(date2)
      .utc()
      .format('DD/MM/YYYY')
    if (firstDate !== secondDate) {
      return 'green'
    } else {
      return null
    }
  }

  return (
    <Fragment>
      <TableCol padding>
        <TableRow id="border-first-row">
          <TableCol bold blueHeader>
            {t('results.column.tm.name.key')}
          </TableCol>
          <TableCol
            rowColor={firstOption.trademarkName !== secondOption.trademarkName ? 'red' : null}
          >
            {firstOption?.trademarkName}
          </TableCol>
          <TableCol
            rowColor={firstOption.trademarkName !== secondOption.trademarkName ? 'green' : null}
          >
            {secondOption?.trademarkName}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold blueHeader>
            {t('status.key')}
          </TableCol>
          <TableCol rowColor={firstOption.statusCode !== secondOption.statusCode ? 'red' : null}>
            {firstOption?.statusCode}
          </TableCol>
          <TableCol rowColor={firstOption.statusCode !== secondOption.statusCode ? 'green' : null}>
            {secondOption?.statusCode}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('results.column.tm.application.date.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.applicationDate,
              secondOption?.applicationDate
            )}
          >
            {firstOption?.applicationDate &&
              moment(firstOption.applicationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.applicationDate,
              secondOption?.applicationDate
            )}
          >
            {secondOption?.applicationDate &&
              moment(secondOption.applicationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('advanced_search_expiry_date_key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(firstOption?.expiryDate, secondOption?.expiryDate)}
          >
            {firstOption?.expiryDate &&
              moment(firstOption.expiryDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(firstOption?.expiryDate, secondOption?.expiryDate)}
          >
            {secondOption?.expiryDate &&
              moment(secondOption.expiryDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('mark.feature.type.key')}</TableCol>
          <TableCol rowColor={firstOption.featureType !== secondOption.featureType ? 'red' : null}>
            {firstOption.featureType}
          </TableCol>
          <TableCol
            rowColor={firstOption.featureType !== secondOption.featureType ? 'green' : null}
          >
            {secondOption?.featureType}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('seniority.indicator.key')}</TableCol>
          <TableCol
            rowColor={
              firstOption?.seniorityIndicatorTranslated !==
              secondOption?.seniorityIndicatorTranslated
                ? 'red'
                : null
            }
          >
            {firstOption?.seniorityIndicatorTranslated}
          </TableCol>
          <TableCol
            rowColor={
              firstOption.seniorityIndicatorTranslated !== secondOption.seniorityIndicatorTranslated
                ? 'green'
                : null
            }
          >
            {secondOption?.seniorityIndicatorTranslated}
          </TableCol>
        </TableRow>
        {(firstOption?.eventName || secondOption?.eventDate) && (
          <TableRow>
            <TableCol bold>{t('history.event.name')}</TableCol>
            <TableCol rowColor={firstOption?.eventName !== secondOption?.eventName ? 'red' : null}>
              {firstOption?.eventName}
            </TableCol>
            <TableCol rowColor={firstOption.eventName !== secondOption.eventName ? 'green' : null}>
              {secondOption?.eventName}
            </TableCol>
          </TableRow>
        )}
        {(firstOption?.eventDate || secondOption?.eventDate) && (
          <TableRow>
            <TableCol bold>{t('history.event.date')}</TableCol>
            <TableCol
              rowColor={compareDatesFirstColumn(firstOption?.eventDate, secondOption?.eventDate)}
            >
              {firstOption?.eventDate &&
                moment(firstOption.eventDate)
                  .utc()
                  .format('DD/MM/YYYY')}
            </TableCol>
            <TableCol
              rrowColor={compareDatesSecondColumn(firstOption?.eventDate, secondOption?.eventDate)}
            >
              {secondOption?.eventDate &&
                moment(secondOption.eventDate)
                  .utc()
                  .format('DD/MM/YYYY')}
            </TableCol>
          </TableRow>
        )}
        <TableRow>
          <TableCol bold>{t('tm.details.label.oppositionPeriodStartDate.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.oppositionStartDate,
              secondOption?.oppositionStartDate
            )}
          >
            {firstOption?.oppositionStartDate &&
              moment(firstOption.oppositionStartDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.oppositionStartDate,
              secondOption?.oppositionStartDate
            )}
          >
            {secondOption.oppositionStartDate &&
              moment(secondOption.oppositionStartDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('tm.details.label.oppositionPeriodEndDate.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.oppositionEndDate,
              secondOption?.oppositionEndDate
            )}
          >
            {firstOption.oppositionEndDate &&
              moment(firstOption.oppositionEndDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.oppositionEndDate,
              secondOption?.oppositionEndDate
            )}
          >
            {secondOption.oppositionEndDate &&
              moment(secondOption.oppositionEndDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol bold>{t('registration.date.detail.key')}</TableCol>
          <TableCol
            rowColor={compareDatesFirstColumn(
              firstOption?.registrationDate,
              secondOption?.registrationDate
            )}
          >
            {firstOption?.registrationDate &&
              moment(firstOption.registrationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
          <TableCol
            rowColor={compareDatesSecondColumn(
              firstOption?.registrationDate,
              secondOption?.registrationDate
            )}
          >
            {secondOption?.registrationDate &&
              moment(secondOption.registrationDate)
                .utc()
                .format('DD/MM/YYYY')}
          </TableCol>
        </TableRow>

        <TableRow>
          <TableCol bold>{t('tm.details.label.registrationNumber.key')}</TableCol>
          <TableCol
            rowColor={
              firstOption.registrationNumber !== secondOption.registrationNumber ? 'red' : null
            }
          >
            {firstOption?.registrationNumber}
          </TableCol>
          <TableCol
            rowColor={
              firstOption.registrationNumber !== secondOption.registrationNumber ? 'green' : null
            }
          >
            {secondOption?.registrationNumber}
          </TableCol>
        </TableRow>
        <TableRow id="border-last-row">
          <TableCol bold>{t('class.number.key')}</TableCol>
          <TableCol rowColor={firstOption.classNumber !== secondOption.classNumber ? 'red' : null}>
            {firstOption?.classNumber}
          </TableCol>
          <TableCol
            rowColor={firstOption.classNumber !== secondOption.classNumber ? 'green' : null}
          >
            {secondOption?.classNumber}
          </TableCol>
        </TableRow>
      </TableCol>
    </Fragment>
  )
}

export default CompareTable
