import { combineReducers } from 'redux'

import alerts from './alerts'
import certificate from './certificate'
import changePassword from './changePassword'
import compare from './compare'
import detail from './detail'
import feedback from './feedback'
import forgotPassword from './forgotPassword'
import spinner from './globalSpinner'
import history from './history'
import home from './home'
import imageSearch from './imageSearch'
import language from './language'
import login from './login'
import register from './register'
import results from './results'
import search from './search'
import shareSearch from './shareSearch'
import tags from './tags'
import { tourReducer as tour } from './tour'

export default combineReducers({
  imageSearch,
  home,
  search,
  results,
  detail,
  login,
  register,
  forgotPassword,
  changePassword,
  shareSearch,
  tags,
  alerts,
  certificate,
  language,
  compare,
  feedback,
  spinner,
  tour,
  history,
})
