import styled, { css } from 'styled-components'

import { check, minus } from '../Icon/icons'
import * as util from '../ThemeProvider/utils'

export const Wrapper = styled.div`
  ${({ theme }) => util.inputWrapper(theme)};
  ${({ disabled }) => disabled && 'opacity: 0.3'};
  ${({ theme, isChild }) =>
    isChild &&
    css`
      margin-left: ${theme.sizeLarge};
      margin-top: ${theme.sizeSmall};
    `};

  & > label > input {
    ${({ theme }) => util.input(theme)};
    ${({ theme, disabled }) => util.inputFocus(theme, disabled)};
    ${({ theme }) =>
      theme.isRTL(theme)
        ? css`
            margin-left: ${theme.sizeSmall};
          `
        : css`
            margin-right: ${theme.sizeSmall};
          `};
    &:after {
      ${({ theme }) => util.inputAfter(theme)};
      border-radius: ${({ theme }) => theme.radius};
    }
    &:checked:after {
      ${({ theme, partially }) => util.inputCheckedAfter(theme, () => (partially ? minus : check))};
    }
  }
`
