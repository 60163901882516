import styled from 'styled-components'
import {
  Block as BlockLCT,
  Button as ButtonLCT,
  Table as TableLCT,
  Link as LinkLCT,
  Image as ImageLCT,
  Language as LanguageLCT,
} from '@new-lucentum'

export const modalStyles = {
  content: {
    left: 'auto',
    margin: '0',
    right: 'auto',
    bottom: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '9999',
  },
}

export const ButtonImage = styled(ButtonLCT)`
  padding: 0;
  position: relative;

  &,
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colorWhite};
  }
  i {
    background-color: ${({ theme }) => theme.transparent};
    bottom: 5px;
    color: ${({ theme }) => theme.colorGrayAccent};
    font-size: ${({ theme }) => theme.fontSizeLarge};
    padding: ${({ theme }) => theme.sizeSmall};
    position: absolute;
    right: 0;
    transform: rotate(-45deg);
  }
`

export const Block = styled(BlockLCT)`
  margin-top: 15px;
`

export const Link = styled(LinkLCT)`
  ${({ fontSizePercent }) =>
    typeof fontSizePercent === 'number' ? `font-size: ${fontSizePercent}%` : `font-size: 100%`}
  font-weight: bold;
  text-decoration: underline;
`

export const LinkMobile = styled(LinkLCT)`
  ${({ fontSizePercent }) =>
    typeof fontSizePercent === 'number' ? `font-size: ${fontSizePercent}%` : `font-size: 100%`}
  font-weight: bold;
  text-decoration: none;
  width: 100%;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  background-color: #e8f6f9;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    text-decoration: none;
  }
`

export const Image = styled(ImageLCT)`
  ${({ fullImageSize }) => fullImageSize && 'max-height: 68vh;'}
  ${({ bigPicture }) => bigPicture && 'width: 75%;'}
  ${({ center }) => center && 'margin:auto'}
`

export const DataRow = styled.div`
  width: 100%;
  hr {
    display: flex;
    width: 100%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  }
`

export const TrademarkLeftBlock = styled(BlockLCT)`
  .imageContainer {
    min-height: 300px;
    padding-top: 10px;
    text-align: center;
  }
  .imageContainerMobile {
    padding-top: 10px;
    text-align: center;
  }
  img {
    width: 100%;
  }

  .buttonContainer {
    text-align: center;
    padding-top: 10px;
  }
`

export const ActionBar = styled.div`
  align-content: center;
  background-color: ${({ theme }) => theme.colorLighter};
  display: flex;
  position: ${({ position }) => position};
  max-width: ${({ theme }) => theme.maxWidth};
  left: ${({ leftPosition }) => leftPosition};
  right: 0;
  top: 0;
  z-index: 100;

  .icons {
    flex: 2;
  }

  .navigation {
    flex: 3;
    .back-button {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
`

export const Button = styled(ButtonLCT)`
  & > div {
    ${({ left }) =>
      left &&
      `
        left: 0;
        right: auto
        top:98%;
    `};
  }
`

export const BlockTooltip = styled(Block)`
  max-width: 180px;
`

export const Table = styled(TableLCT)`
  .rt-thead {
    &.-header:first-child {
      padding-left: 0 !important;
    }
    .rt-th {
      font-weight: normal !important;
      color: ${({ theme }) => theme.colorGrayLight};
    }
  }

  .rt-tbody .rt-tr-group {
    padding-left: 0 !important;
    & > div:nth-child(2) {
      padding-top: 0;
    }
  }

  .rt-tbody .rt-tr {
    min-height: 25px !important;
  }

  .rt-tbody .rt-td:first-child,
  .rt-th:first-child {
    padding-left: 0 !important;
  }

  .rt-tbody .rt-td:not(:first-child),
  .rt-th:not(:first-child) {
    padding: 5px;
  }

  .rt-expandable + .rt-td {
    padding-left: 0 !important;
  }
`

export const Bullet = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 3px 10px;
  ${({ theme, red, gray }) =>
    (red || gray) && `background-color: ${red ? theme.colorRed : theme.colorGray};`};
`

export const Language = styled(LanguageLCT)`
  padding-left: ${({ theme }) => theme.sizeSmall};
`

export const ScrollBlock = styled(Block)`
  ${({ theme }) => theme.scroll(theme)};
  margin: 0;
  padding: 0;
  max-height: ${({ height }) => height};
  overflow: auto;
`

export const RecordalRow = styled.div`
  display: flex;
  width: 100%;
  > div {
    flex: 1;
    margin-right: 10px;
  }
  > div:last-child {
    margin-right: 0;
  }
  &.event {
    margin-bottom: 10px;
  }
  .event-date {
    width: 100px;
  }
`

export const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colorGrayLighter};
  margin-top: ${({ theme }) => theme.size};
  margin-bottom: ${({ theme }) => theme.sizeDouble};
`

export const GoodsAndServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: bold;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
`
export const RecordalContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const RecordalFields = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${({ theme }) => theme.size};
`

export const StyledButton = styled(ButtonLCT)`
  display: flex;
  width: 82.5% !important;
  padding-right: 0px !important;
  text-align: left !important;
  margin-left: 0px !important;
  margin-top: 5px !important;
  .case-law-option-button {
    width: 100% !important;
  }
  .options-options {
    overflow-y: visible !important;
  }
`
