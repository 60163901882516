import styled from 'styled-components'
import { Legend as LegendLCT, Link as LinkLCT, P as PLCT, Span as SpanLCT } from '@new-lucentum'

const styleText = splitText => `
    // This only works in Chrome and Safary
    ${splitText &&
      `
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
    `};
`

export const Link = styled(LinkLCT)`
  color: unset;
  &:hover {
    text-decoration: none;
  }
`

export const Legend = styled(LegendLCT)`
  ${({ splitText }) =>
    splitText &&
    `
        max-height: 9em; /* em because depends on the size of the text */
        overflow: hidden;
    `};
`

export const Span = styled(SpanLCT)`
  ${({ theme }) => styleText(theme, true)};
`

export const P = styled(PLCT)`
  ${({ theme, splitText }) => styleText(theme, splitText)};
  ${({ splitText }) =>
    splitText &&
    `
        max-height: 3em; /* em because depends on the size of the text */
        -webkit-line-clamp: 2 !important; /* number of lines to show */
    `};
`
