import {
  FILTERABLE_FIELDS,
  KEY_OPPOSITION_PERIOD_END,
  KEY_OPPOSITION_PERIOD_START,
} from '@app/common/constants'
import {
  generalDataManager,
  searchDataManager,
  userprefsDataManager,
} from '@app/common/dataManagers'
import {
  TMDSSearchService,
  TMDSSpinnerService,
  TMDSToastService,
  TMDSTrackingService,
} from '@app/common/services'
import { getHeaderFileName, prepareResultsFields } from '@app/common/utilities'
import { FORM_IS_TOUCHED, MOBILE_FILTERS, RESULTS_TYPES } from '@app/redux-types/results'
import store from '@app/store'
import fileDownload from 'react-file-download'

const FILTERS_GET_DATA_MAP = {
  [FILTERABLE_FIELDS.TERRITORIES]: searchDataManager.getTerritoryFilter,
  [FILTERABLE_FIELDS.OFFICES]: searchDataManager.getOfficeFilter,
  [FILTERABLE_FIELDS.APPLICANT_NAME]: searchDataManager.getApplicantNameFilter,
  [FILTERABLE_FIELDS.TRADEMARK_TYPE]: searchDataManager.getTrademarkTypeFilter,
  [FILTERABLE_FIELDS.GOODS_AND_SERVICES]: searchDataManager.getGoodsAndServicesFilter,
  [FILTERABLE_FIELDS.TRADEMARK_STATUS]: searchDataManager.getTrademarkStatusFilter,
  [FILTERABLE_FIELDS.VIENNA_CODE]: searchDataManager.getViennaCodeFilter,
  [FILTERABLE_FIELDS.OWNER_NAME]: searchDataManager.getOwnerNameFilter,
  [FILTERABLE_FIELDS.DESIGN_STATUS]: searchDataManager.getDesignStatusFilter,
  [FILTERABLE_FIELDS.DESIGNER_NAME]: searchDataManager.getDesignerNameFilter,
  [FILTERABLE_FIELDS.LOCARNO]: searchDataManager.getLocarnoFilter,
  [FILTERABLE_FIELDS.APPLICANT_NAME_OFFICES]: searchDataManager.getApplicantNameOfficesFilter,
  [FILTERABLE_FIELDS.OWNER_NAME_OFFICES]: searchDataManager.getOwnerNameOfficesFilter,
  specials: searchDataManager.getSpecialFilters,
}

export const fetchSearch = (
  queryParams,
  displayModes,
  isChangeFilters,
  context,
  lastAppliedFilter,
  columns
) => {
  const storeState = store.getState()
  const {
    uiState: { search: searchUI },
  } = storeState
  const newQueryParam = isChangeFilters === false ? `${queryParams}&newPage=true` : queryParams
  let { searchQueryObject } = TMDSSearchService.prepareSearchQuery(queryParams)
  const withImageSearch = searchUI.imageSearchMode && searchQueryObject.imageId

  if (withImageSearch) {
    searchQueryObject = {
      ...searchQueryObject,
      segmentLeft: Math.round(searchQueryObject.segmentLeft).toString(),
      segmentRight: Math.round(searchQueryObject.segmentRight).toString(),
      segmentTop: Math.round(searchQueryObject.segmentTop).toString(),
      segmentBottom: Math.round(searchQueryObject.segmentBottom).toString(),
    }
  }

  const newQueryParamObject =
    isChangeFilters === false ? { ...searchQueryObject, newPage: true } : searchQueryObject
  const preparedQueryObject =
    columns && columns.length > 0
      ? {
          ...newQueryParamObject,
          fields: ['ST13', ...prepareResultsFields(columns)],
        }
      : newQueryParamObject

  if (preparedQueryObject.fields?.includes(KEY_OPPOSITION_PERIOD_START)) {
    preparedQueryObject.fields.push(KEY_OPPOSITION_PERIOD_END)
  }

  return {
    type: RESULTS_TYPES.FETCH_SEARCH,
    payload: searchDataManager.doSearch(newQueryParam, preparedQueryObject),
    meta: { ...displayModes, isChangeFilters, context, lastAppliedFilter, errors: [401] },
  }
}

export const fetchFilter = (filter, getDataPayload, preventUiUpdate = false) => {
  return {
    type: RESULTS_TYPES.FETCH_FILTER,
    payload: FILTERS_GET_DATA_MAP[filter](getDataPayload),
    meta: { filter, preventUiUpdate },
  }
}

export const fetchSpecialFilters = () => {
  return {
    type: RESULTS_TYPES.FETCH_SPECIAL_FILTERS,
    payload: searchDataManager.getSpecialFilters(),
  }
}

export const changeDisplayFormat = viewMode => ({
  type: 'CHANGE_DISPLAY_FORMAT',
  payload: { viewMode: viewMode },
})

export const currentTabView = tabView => ({
  type: 'CURRENT_TAB_VIEW',
  payload: { tabView: tabView },
})

export const isShowSearchFilter = displayStatus => ({
  type: 'IS_SHOW_SEARCH_FILTER',
  payload: displayStatus,
})

export const toggleSearchFilter = isEnabled => ({
  type: 'TOGGLE_SEARCH_FILTER',
  payload: isEnabled,
})

export const toggleTradeMarkPopup = tradeMark => ({
  type: 'TOGGLE_TRADEMARK_POPUP',
  payload: tradeMark,
})

export const toggleFilters = () => ({
  type: 'TOGGLE_FILTERS',
})

export const clearFilters = () => ({
  type: 'CLEAR_FILTERS',
})

export const setChangeFilters = isChangeFilters => ({
  type: 'IS_CHANGE_FILTERS',
  payload: isChangeFilters,
})

export const changeSearchFieldsFetched = (searchFields, fieldsStringCodes) => ({
  type: 'CHANGE_SEARCH_FIELDS_FETCHED',
  payload: { searchFields, fieldsStringCodes },
})

export const changeSortColumn = sort => ({
  type: 'CHANGE_SORT_COLUMN',
  payload: sort,
})

export const resetResultState = () => ({
  type: RESULTS_TYPES.CLEAR_RESULTS,
})

export const showTableMenu = () => ({
  type: 'SHOW_TABLE_MENU',
})

export const hideTableMenu = () => ({
  type: 'HIDE_TABLE_MENU',
})

export const updateTableColumns = (nextColumns, context) => ({
  type: 'UPDATE_TABLE_COLUMNS',
  payload: nextColumns,
  meta: { context },
})

export const showTableColumn = (columnKey, context) => ({
  type: 'SHOW_TABLE_COLUMN',
  payload: { columnKey },
  meta: { context },
})

export const hideTableColumn = (columnKey, context) => ({
  type: 'HIDE_TABLE_COLUMN',
  payload: { columnKey },
  meta: { context },
})

export const reorderColumn = orderedColumns => ({
  type: 'ORDER_TABLE_COLUMN',
  payload: { orderedColumns },
})

export const toggleTrademarkFromSelectedList = (ST13, data) => ({
  type: 'TOGGLE_TRADEMARK_FROM_SELECTED_LIST',
  payload: { ST13, data },
})

export const addAllTrademarksToSelectedList = (ST13Array, data) => ({
  type: 'ADD_ALL_TRADEMARKS_TO_SELECTED_LIST',
  payload: { ST13Array, data },
})

export const removeAllTrademarksFromSelectedList = (ST13Array, data) => ({
  type: 'REMOVE_ALL_TRADEMARKS_FROM_SELECTED_LIST',
  payload: { ST13Array, data },
})

export const emptySelectedTradeMarksList = () => ({
  type: 'EMPTY_SELECTED_TRADE_MARKS_LIST',
})

export const changeExportCaptcha = value => ({
  type: 'CHANGE_EXPORT_CAPTCHA',
  payload: { recaptchaResponseKey: value },
})

export const showExportPDFOptions = displayedColumnNames => ({
  type: 'SHOW_EXPORT_PDF_OPTIONS',
  payload: { displayedColumnNames: displayedColumnNames },
})

export const showExportExcelOptions = displayedColumnNames => ({
  type: 'SHOW_EXPORT_XLS_OPTIONS',
  payload: { displayedColumnNames: displayedColumnNames },
})

export const showExportWordOptions = displayedColumnNames => ({
  type: 'SHOW_EXPORT_DOC_OPTIONS',
  payload: { displayedColumnNames: displayedColumnNames },
})

export const showExportStatisticsOptions = (fileType, show) => ({
  type: 'SHOW_EXPORT_STATS_OPTIONS',
  payload: { fileType, show: show || false },
})

export const setGraphLegends = (legendKey, legends) => ({
  type: 'SET_GRAPH_LEGENDS',
  payload: { legendKey, legends },
})

export const selectColumnForExport = column => ({
  type: 'SELECT_COLUMN_FOR_EXPORT',
  payload: { columnSelected: column },
})

export const toggleAllColumnsForExport = checked => ({
  type: 'TOGGLE_ALL_COLUMNS_FOR_EXPORT',
  payload: checked,
})

export const closeResultsNotification = () => ({
  type: 'CLOSE_RESULTS_NOTIFICATION',
})

export const setExportTrademarksNotification = (notification, type) => ({
  type: 'SET_EXPORT_TRADEMARKS_NOTIFICATION',
  payload: { notification: notification, notificationType: type },
})

export const closeExportNotification = () => ({
  type: 'CLOSE_EXPORT_TRADEMARKS_NOTIFICATION',
})

export const cancelExportTrademarks = () => ({
  type: 'CANCEL_EXPORT',
})

export const exportFileType = (defaultFileName, extension, errMsg, body) => dispatch => {
  TMDSTrackingService.trackExport('RESULTS', extension)
  const exportPromise = dispatch({
    type: `EXPORT_${extension.toUpperCase()}`,
    payload: searchDataManager.doExport(extension, body),
  })

  TMDSSpinnerService.showSpinner(exportPromise)
  exportPromise
    .then(({ value: { headers, parsedBody } }) => {
      const fileName = getHeaderFileName(headers)
      fileDownload(parsedBody, fileName ? fileName : `${defaultFileName}.${extension}`)
    })
    .catch(() => {
      dispatch({
        type: 'SET_RESULTS_NOTIFICATION',
        payload: { type: 'error', notification: errMsg },
      })
    })
  return exportPromise
}

export const statsExportFileType = (
  literalErrorExport,
  defaultFileName,
  extension,
  body
) => dispatch => {
  TMDSTrackingService.trackExport('STATISTICS', extension)
  const exportPromise = dispatch({
    type: `STATS_EXPORT_${extension.toUpperCase()}`,
    payload: searchDataManager.doExportStatistics(extension, body),
  })

  TMDSSpinnerService.showSpinner(exportPromise)
  exportPromise
    .then(({ value: { headers, parsedBody } }) => {
      const fileName = getHeaderFileName(headers)
      fileDownload(parsedBody, fileName ? fileName : `${defaultFileName}.${extension}`)
    })
    .catch(err => TMDSToastService.showNotificationError(literalErrorExport))
  return exportPromise
}
export const searchWipo = params => dispatch =>
  dispatch({
    type: 'FETCH_WIPO_SEARCH',
    payload: generalDataManager.getWipoSearch(params),
  })

export const changeOpenWipo = () => ({
  type: 'CHANGE_OPEN_WIPO',
})

export const changeExpandedRows = expandedRows => ({
  type: 'CHANGE_EXPANDED_ROWS',
  payload: expandedRows,
})

export const changefilterTag = filterTag => ({
  type: 'CHANGE_FILTER_TAG',
  payload: filterTag,
})

export const fetchTagGroupsResults = tags => {
  return {
    type: 'FETCH_TAG_GROUPS_RESULTS',
    payload: userprefsDataManager.getTagGroups(tags),
  }
}

export const fetchDetailExpanded = id => ({
  type: RESULTS_TYPES.FETCH_DETAIL_EXPANDED,
  payload: searchDataManager.getResourceDetails(id),
  meta: { id, errors: [500] },
})

export const fetchListDetailExpanded = (resultsList = []) => dispatch => {
  dispatch({ type: RESULTS_TYPES.FETCH_DETAILS_LIST })
  setTimeout(() => resultsList.map(result => dispatch(fetchDetailExpanded(result.ST13))), 500)
}

export const changeIsFiltered = isFiltered => ({
  type: 'IS_FILTERED',
  payload: isFiltered,
})

export const resetGSTranslations = (ST13, restore = false) => ({
  type: 'RESET_GS_TRANSLATIONS',
  payload: { ST13, restore },
})
export const changeUrlWhenFilter = (history, getSearchQuery, context) => (dispatch, getState) => {
  history.push(
    `/${context}/results${getSearchQuery(
      getState().uiState.search,
      getState().uiState.results,
      getState().appState.results,
      getState().uiState.imageSearch
    )}`
  )
}
export const clearResults = () => {
  return {
    type: RESULTS_TYPES.CLEAR_RESULTS,
  }
}

export const changeLastAppliedFilter = filter => {
  return {
    type: RESULTS_TYPES.CHANGE_LAST_APPLIED_FILTER,
    payload: filter,
  }
}

export const setMobileFilters = mobileFilters => ({
  type: MOBILE_FILTERS,
  payload: mobileFilters,
})

export const setIsFormTouched = isTouched => ({
  type: FORM_IS_TOUCHED,
  payload: isTouched,
})

export const reorderFilter = orderedFilters => ({
  type: 'ORDER_FILTER',
  payload: { orderedFilters },
})
