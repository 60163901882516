/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { compose, lifecycle, withState } from 'recompose'

import {
  isIE,
  changeLanguageSelector,
  getContextFromPathname,
  prepareActiveLanguages,
  isFavoriteCookieSet,
} from '@app/common/utilities'
import TMDSContextService from '@app/common/tmdsContextService'
import TMDSRouterService from '@app/common/tmdsRouterService'
import {
  TMDSSeoService,
  TMDSAuthService,
  TMDSUserSettingsService,
  TMDSNotificationsService,
} from '@app/common/services'
import { AppRoute } from './AppRoute'
import { AUTH_GUARD, UNAUTH_GUARD } from './guards'
import { TITLE, META } from '@app/common/constants/seo'

import { fetchGlobals } from '@app/actions/globals'
import { fetchLiterals } from '@app/actions/literals'
import { closeModal } from '@app/actions/modal'
import { changeLanguage } from '@app/actions/language'
import {
  clearSearchForm,
  closeAdvancedSearch,
  setDefaultFavorites,
  setFavoritesFromCookie,
} from '@app/actions/search'
import { openFeedbackModal } from '@app/actions/feedback'
import { clearImageData, setResetImage } from '@app/actions/imageSearch'

import Feedback from '@app/containers/Feedback'
import Footer from '@app/components/Footer'

import Header from '@app/components/Header'

import InfoPage from '@app/containers/InfoPage'
import Modal from '@app/components/Modal'

import { SkeletonHome } from '@app/components/SkeletonLoader'
import { CookiesBanner, BackToTopButton } from '@app/components'
import { AppTour } from '@app/components/AppTour'
import { useResponsive } from '@app/common/hooks'

import { ROUTES, ROUTES_WITH_CONTEXT, CONTEXT_ROUTE } from './routes'

import { Spinner } from '@new-lucentum'

import { Block, Toast } from './styles'

import Home from '@app/containers/Home'
import Details from '@app/containers/Details'

import asyncComponent from '@app/common/AsyncComponent'
import TMHistoryTable from '../TMHistory/TMHistoryTable'

const AsyncParticipating = asyncComponent(() => import('@app/containers/Participating'))
const AsyncNews = asyncComponent(() => import('@app/containers/News'))
const AsyncResults = asyncComponent(() => import('@app/containers/TMResults'))
const AsyncCompare = asyncComponent(() => import('@app/containers/Compare'))
const AsyncForgotPassword = asyncComponent(() => import('@app/containers/ForgotPassword'))
const AsyncChangePassword = asyncComponent(() => import('@app/containers/ChangePassword'))
const AsyncBookmark = asyncComponent(() => import('@app/containers/Bookmark'))
const AsyncTags = asyncComponent(() => import('@app/containers/Tags'))
const AsyncAlerts = asyncComponent(() => import('@app/containers/Alerts'))
const AsyncAlertsHistory = asyncComponent(() => import('@app/containers/AlertsHistory'))
const AsyncSavedSearches = asyncComponent(() => import('@app/containers/SavedSearches'))
const AsyncSavedSearchEvents = asyncComponent(() => import('@app/containers/SavedSearchEvents'))
const AsyncNotifications = asyncComponent(() => import('@app/containers/Notifications'))
const AsyncDataProtection = asyncComponent(() => import('@app/containers/DataProtection'))

const DSVIEW_PATH = process.env.DSVIEW_PATH || 'tmdsview-web'

const isDsviewUrl = () => {
  return window.location.pathname.includes(DSVIEW_PATH)
}
const DEFAULT_CONTEXT = isDsviewUrl() ? 'dsview' : 'tmview'
const setSeoParams = context => {
  TMDSSeoService.set({
    title: TITLE[ROUTES.HOME][context],
    meta: {
      ...META[ROUTES.HOME][context],
      appVersion: process.env.REACT_APP_VERSION,
    },
  })
}
setSeoParams(DEFAULT_CONTEXT)

const onLoggedIn = () => {
  TMDSUserSettingsService.getSettings()
  TMDSNotificationsService.getRecentNotifications()
}

const authSettings = {
  persistance: 'cookies',
  onLoggedIn,
}

const didMount = lifecycle({
  componentDidMount() {
    const { setAuthInitialized } = this.props
    const context = getContextFromPathname(this.props.location.pathname) || DEFAULT_CONTEXT
    TMDSContextService.set(context)
    TMDSRouterService.setHistory(this.props.history)
    setSeoParams(context)
    TMDSAuthService.init(authSettings).then(isLoggedIn => {
      setAuthInitialized(true)
    })

    if (isIE() && isIE() <= 10) {
      window.location.replace('https://www.tmdn.org/tmview/welcome')
    } else {
      this.props.fetchGlobals()
      this.props.fetchLiterals()
    }

    if (!isFavoriteCookieSet()) {
      this.props.setDefaultFavorites()
    } else {
      this.props.setFavoritesFromCookie()
    }
  },
  componentDidUpdate(oldProps) {
    const context = getContextFromPathname(this.props.location.pathname) || DEFAULT_CONTEXT
    const oldContext = getContextFromPathname(oldProps.location.pathname) || DEFAULT_CONTEXT
    if (context !== oldContext) {
      TMDSContextService.set(context)
      setSeoParams(context)
      this.props.fetchGlobals()
      this.props.fetchLiterals()
      this.props.setFavoritesFromCookie()
    }
  },
})

const App = ({
  changeLanguage,
  clearSearchForm,
  closeAdvancedSearch,
  closeModal,
  openFeedbackModal,
  fetchGlobals,
  fetchLiterals,
  globals,
  history,
  language,
  literals,
  location,
  logout,
  modal,
  pages,
  searchUI,
  userApp,
  showFeedBackModal,
  setResetImage,
  clearImageData,
  spinner,
  ...props
}) => {
  const activeLanguages = prepareActiveLanguages(globals.languages) || []
  const currentLanguage = language.language
  const showSkeleton = !(props.globalsFetched && props.literalsFetched && props.authInitialized)
  const { isMobile } = useResponsive()

  return showSkeleton ? (
    <SkeletonSwitcher {...{ location }} />
  ) : (
    <Block height="100%" column>
      <Spinner show={spinner.showing} />
      {modal.open && (
        <Modal {...modal} open={modal.open} literals={literals} handleClose={closeModal} />
      )}
      {showFeedBackModal && <Feedback title={literals.your_feedback_key} />}
      <Toast isMobile={isMobile} />
      {globals.tourConfig && globals.tourConfig.isEnabled && !isMobile && <AppTour />}
      <Route
        path={CONTEXT_ROUTE}
        render={({ match }) => (
          <Header
            fontSizeIncrement={globals.fontSizeIncrement}
            languages={changeLanguageSelector(activeLanguages)}
            literals={literals}
            userApp={userApp}
            handleSignIn={() => history.push(`/${match.params.context}/login`)}
            handleSignUp={() => history.push(`/${match.params.context}/register`)}
            handleSignOut={() => TMDSAuthService.doLogout()}
            history={history}
            changeLanguage={lang =>
              changeLanguage(
                lang.key,
                fetchGlobals,
                fetchLiterals,
                location.pathname.replace(/^\/|\/$/g, '')
              )
            }
            language={language}
            navToClearedSearch={() => {
              closeAdvancedSearch()
              setResetImage()
              clearImageData()
              clearSearchForm()
              history.push(`/${match.params.context}`)
            }}
            context={match.params.context}
            isAdvancedSearch={searchUI.advancedSearch}
            openFeedbackModal={openFeedbackModal}
          />
        )}
      />

      <Block paddingTop={0} content="true">
        <Switch>
          <Route path={ROUTES_WITH_CONTEXT.DETAILS} component={Details} />
          <Route path={ROUTES_WITH_CONTEXT.RESULTS} component={AsyncResults} />
          <Route path={ROUTES_WITH_CONTEXT.FORGOT_PASSWORD} component={AsyncForgotPassword} />
          <Route path={ROUTES_WITH_CONTEXT.CHANGE_PASSWORD} component={AsyncChangePassword} />
          <Route path={ROUTES_WITH_CONTEXT.COMPARE} component={AsyncCompare} />
          <Route path={ROUTES_WITH_CONTEXT.TM_HISTORY} component={TMHistoryTable} />
          <AppRoute
            path={ROUTES_WITH_CONTEXT.TAGS}
            component={AsyncTags}
            canActivate={[AUTH_GUARD]}
          />
          <AppRoute
            exact
            path={ROUTES_WITH_CONTEXT.ALERTS}
            component={AsyncAlerts}
            canActivate={[AUTH_GUARD]}
          />
          <AppRoute
            path={ROUTES_WITH_CONTEXT.ALERTS_HISTORY}
            component={AsyncAlertsHistory}
            canActivate={[AUTH_GUARD]}
          />
          <AppRoute
            exact
            path={ROUTES_WITH_CONTEXT.SAVED_SEARCHES}
            component={AsyncSavedSearches}
            canActivate={[AUTH_GUARD]}
          />
          <AppRoute
            path={ROUTES_WITH_CONTEXT.SAVED_SEARCH_EVENTS}
            component={AsyncSavedSearchEvents}
            canActivate={[AUTH_GUARD]}
          />
          <AppRoute
            path={ROUTES_WITH_CONTEXT.NOTIFICATIONS}
            component={AsyncNotifications}
            canActivate={[AUTH_GUARD]}
          />
          <Route path={ROUTES_WITH_CONTEXT.BOOKMARK} component={AsyncBookmark} />
          <Route path={ROUTES_WITH_CONTEXT.NEWS} component={AsyncNews} />
          <Route path={ROUTES_WITH_CONTEXT.OFFICES} component={AsyncParticipating} />
          <Route path={ROUTES_WITH_CONTEXT.DATA_PROTECTION} component={AsyncDataProtection} />
          {Object.keys(pages).map(pageName => (
            <Route
              key={pageName}
              path={`${CONTEXT_ROUTE}/${pageName}`}
              render={() => <InfoPage pageName={pageName} />}
            />
          ))}
          <Route path={ROUTES_WITH_CONTEXT.HOME} component={Home} />
          <Redirect to={`/${DEFAULT_CONTEXT}`} />
        </Switch>
      </Block>
      <Route
        path={CONTEXT_ROUTE}
        render={({ match }) => (
          <Footer
            footerLinks={globals.footerLinks}
            context={match.params.context}
            openFeedbackModal={openFeedbackModal}
          />
        )}
      />
      <CookiesBanner cookiesUrl={globals.dataProtectionCookieUrl} language={currentLanguage} />
      <BackToTopButton />
    </Block>
  )
}

const mapStateToProps = ({
  appState: { user: userApp, pages },
  globals: { fetched: globalsFetched, data: globals },
  literals: { fetched: literalsFetched, data: literals },
  modal,
  uiState: {
    language,
    search: searchUI,
    feedback: { showFeedBackModal },
    spinner,
  },
}) => ({
  globals,
  globalsFetched,
  language,
  literals,
  literalsFetched,
  modal,
  pages,
  searchUI,
  userApp,
  showFeedBackModal,
  spinner,
})

const mapDispatchToProps = {
  fetchGlobals,
  fetchLiterals,
  closeModal,
  changeLanguage,
  clearSearchForm,
  setResetImage,
  closeAdvancedSearch,
  openFeedbackModal,
  setDefaultFavorites,
  setFavoritesFromCookie,
  clearImageData,
}

const Skeletons = {
  '/': <SkeletonHome />,
  [ROUTES.HOME]: <SkeletonHome />,
}
const SkeletonSwitcher = props => {
  const parsedRoute =
    props && props.location && props.location.pathname.replace(/\/tmview|\/dsview/, '')
  return Skeletons[parsedRoute] || <Spinner show />
}

export default compose(
  withState('authInitialized', 'setAuthInitialized', false),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  didMount
)(App)
