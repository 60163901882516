import { Field, Form } from '@app/common/formManagement'
import {
  setFieldValues,
  validationMessage,
  setInvalidFieldKey,
  validateEntireForm,
  validateIndividualState,
} from '@app/common/utilities'
import { AUTH_TYPES } from '@app/redux-types'

const defaultErrors = {
  email: { isValid: true, errorMessage: '' },
}

const defaultForm = {
  email: '',
}

const initForm = () => new Form().addField(new Field('email', null, defaultForm.email))

const form = initForm()

const defaultState = {
  successMessage: false,
  backEndErrorMessage: {
    error: false,
    errorMessage: '',
    errorDetails: [],
  },
  formState: {
    form,
    fields: {
      ...defaultForm,
    },
    isValid: true,
    errors: {
      ...defaultErrors,
    },
  },
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'EMPTY_FORGOT_PASSWORD_FIELDS':
      return defaultState
    case 'SET_INVALID_FORGOT_PASSWORD_FIELD':
      return validateEntireForm(setInvalidFieldKey(state, payload.fieldKey, payload.errorMessage))
    case 'CHANGE_FORGOT_PASSWORD_FIELD':
      return validateEntireForm(
        validateIndividualState(setFieldValues(payload, state), payload.field)
      )
    case AUTH_TYPES.FETCH_REMIND_PASSWORD_FULFILLED:
      return {
        ...state,
        successMessage: true,
      }
    case 'CLOSE_FORGOT_PASSWORD_SUCCESS':
      return {
        ...state,
        successMessage: false,
      }
    case AUTH_TYPES.FETCH_REMIND_PASSWORD_REJECTED:
      return validationMessage(state, payload)
    case 'CLOSE_FORGOT_PASSWORD_BE_ERROR':
      return {
        ...state,
        backEndErrorMessage: defaultState.backEndErrorMessage,
      }
    default:
      return state
  }
}
