import React from 'react'
import debounce from 'lodash.debounce'
import { chain, compose, innerJoin, length, map, match, prop, range, split, uniq } from 'ramda'

import { P, H3 } from '../../Text'
import Block from '../../Block'
import Link from '../../Link'
import { ButtonStyled, InfoStyled, NiceStyled, LinkStyled } from './styles'

const NICE_REGEX = /(\d+)\.{2}(\d+)|(\d+)(?=,|\s|$)/g

const eqBy = key => (x, y) => x[key] === y
const unwrap = x => (x.length > 1 ? range(+x[0], +x[1] + 1) : x)
const selected = ({ value }) => (num, valueKey) => value && value.map(prop(valueKey)).includes(num)
const getValues = compose(
  uniq,
  map(Number),
  chain(unwrap),
  map(split('..')),
  match(NICE_REGEX)
)

export const niceFilter = label => (option, filter) =>
  !filter || length(innerJoin(eqBy(label), option.numbers || [], getValues(filter)))

export const onKeyDown = (e, opt) =>
  e.keyCode === 13 && innerJoin(eqBy('num'), chain(prop('numbers'))(opt), getValues(e.target.value))

/**
 * Custom option template for Nice
 */
export default ({
  handleMouseNumber,
  handleMouseOut,
  handleMouseOver,
  handleMouseSelectAll,
  nameLink,
  option,
  state,
  textClass,
  textLink,
  textSelectAll,
  toLink,
  valueKey,
}) => (
  <NiceStyled option={option.id !== 1}>
    <Block padding>
      <Block justify paddingRight={4}>
        <H3>{option.description}</H3>
        <Link onMouseDown={handleMouseSelectAll} href="#">
          {textSelectAll}
        </Link>
      </Block>
      <Block>
        {option.numbers.map(n => (
          <ButtonStyled
            key={n[valueKey]}
            disabled={selected(state)(n[valueKey], valueKey)}
            onMouseOut={debounce(handleMouseOut, 300)}
            onMouseOver={debounce(() => handleMouseOver(n), 300)}
            onMouseDown={e => handleMouseNumber(e, n)}
          >
            {n[valueKey]}
          </ButtonStyled>
        ))}
      </Block>
      {textLink && (
        <LinkStyled middle visible={option.id === 2}>
          <P>{textLink}</P>
          <Link to={toLink} target="_blank">
            {nameLink}
          </Link>
        </LinkStyled>
      )}
    </Block>
    <InfoStyled visible={state.visible && option.id === 1} displayInfo={option.id !== 1}>
      <Block padding>
        <H3>
          {textClass} {state[valueKey]}
        </H3>
        {state.info && (
          <ul>
            {state.info.map(a => (
              <li>{a}</li>
            ))}
          </ul>
        )}
      </Block>
    </InfoStyled>
  </NiceStyled>
)
