import { toggleSpinner } from '@app/actions/globalSpinner'
import store from '@app/store'
const { dispatch } = store

const showSpinner = requestPromise => {
  dispatch(toggleSpinner(true))
  requestPromise.finally(() => {
    dispatch(toggleSpinner(false))
  })
}

export const TMDSSpinnerService = {
  showSpinner,
}
