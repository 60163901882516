import { NO_DATA_CHARACTER } from '@app/common/constants'

export const getLocarnoClassText = (locarnoClass = [], separator = ', ', maxClassesToShow) => {
  if (!Array.isArray(locarnoClass) || !locarnoClass.length) {
    return NO_DATA_CHARACTER
  }

  if (maxClassesToShow && maxClassesToShow < locarnoClass.length) {
    return `${locarnoClass.slice(0, maxClassesToShow).join(separator)}...`
  } else {
    return locarnoClass.join(separator)
  }
}
