import React from 'react'

const checkMobileBrowser = () => {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
  const isMobileBrowser = Boolean(
    userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
  )
  return isMobileBrowser
}

const iOSInfo = () => {
  const value = navigator.userAgent
  let platform = ''
  if (value.match(/iPhone;/)) {
    platform = 'iPhone'
  } else if (value.match(/iPad;/)) {
    platform = 'iPad'
  } else if (value.match(/iPod;/)) {
    platform = 'iPod'
  }

  const versionResult = /Version\/([\d.]+)/.exec(value)
  let version = versionResult ? Number(versionResult[1]) : 0

  const isAppleWebkit = value.match(/AppleWebKit/)

  return { platform, version, isAppleWebkit }
}

let mobileBrowser = false

export function useResponsive() {
  const [width, setWidth] = React.useState(window.innerWidth)
  let timeoutId = null
  const handleWindowResize = () => {
    // prevent execution of previous setTimeout
    clearTimeout(timeoutId)
    // change width from the state object after 150 milliseconds
    timeoutId = setTimeout(() => {
      setWidth(window.innerWidth)
      mobileBrowser = checkMobileBrowser()
    }, 150)
  }

  React.useEffect(() => {
    mobileBrowser = checkMobileBrowser()
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return {
    isMobile: width < 550 || mobileBrowser,
    ...iOSInfo(),
  }
}
