import React from 'react'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { t } from '@app/common/translationsService'
import { TMDSGlobalsService } from '@app/common/services'
import { fetchAllTranslation, cleanGoodsAndServicesList } from '@app/common/utilities'
import { DataItem } from './DataItem'
import { Block } from '@new-lucentum'
import TranslationsDropdown from '@app/components/TranslationsDropdown'
import { Box } from './Box'
import { ClassificationsTerms } from './ClassificationsTerms'
import { ModalCompareTranslations } from './ModalCompareTranslations'
import { ShowMore } from './ShowMore'
import { TranslationsProvidedBy } from './TranslationsProvidedBy'
import { GSErrorTranslation } from '../GoodsAndServices'
import { Link, Separator, GoodsAndServicesWrapper } from './styles'
import { totalBoxEntries } from './utils'
import { BOXES } from './constants.js'

const BOX = BOXES.GOODS_AND_SERVICES

const getClassificationTerms = (
  gs,
  gsTranslated,
  languageTranslate,
  gsTranslateError
) => fetching => (
  <ClassificationsTerms
    gsTranslated={gsTranslated}
    fetching={fetching}
    languageTranslation={languageTranslate}
    niceClass={gs.niceClass}
    terms={gs.goodsAndServices}
    gsTranslateError={gsTranslateError}
  />
)

const getGoodAndServices = (data, gsTranslated, language) => {
  try {
    const [
      {
        goodAndServices: { goodAndServiceList, language: originalLanguage },
        classificationKind,
      },
    ] = data
    const filteredGsTranslated = gsTranslated.filter(
      translation => translation.classificationKind === classificationKind
    )
    const _language = filteredGsTranslated.length
      ? language && language.toLowerCase()
      : originalLanguage
    return {
      goodAndServiceList: cleanGoodsAndServicesList(goodAndServiceList),
      language: _language,
      originalLanguage,
    }
  } catch (e) {
    return {}
  }
}

const enhance = compose(
  withState('showMore', 'setShowMore', false),
  withState('language', 'setLanguage', null),
  withState('modalOpen', 'setModalOpen', false),
  withHandlers({
    toggleShowMore: ({ showMore, setShowMore }) => () => setShowMore(!showMore),
  }),
  lifecycle({
    componentDidMount() {
      const { data, gsTranslated } = this.props
      const { language } =
        data && data.length ? getGoodAndServices(data, gsTranslated, this.props.language) : {}
      language && language.toLowerCase && this.props.setLanguage(language.toLowerCase())
    },
  })
)

const fetchTranslations = (data, languageCode, fetchDetailTranslations) => {
  const [
    {
      classificationKind,
      goodAndServices: {
        goodAndServiceList: originalGoodAndServiceList,
        language: originalLanguage,
      },
    },
  ] = data
  fetchAllTranslation(
    originalGoodAndServiceList,
    originalLanguage,
    languageCode,
    classificationKind,
    fetchDetailTranslations
  )
}

const GoodsAndServicesBlockComponent = ({
  data,
  languages,
  gsTranslated,
  fetching,
  gsTranslateError,
  ...props
}) => {
  const { goodAndServiceList, language, originalLanguage } = getGoodAndServices(
    [data],
    gsTranslated,
    props.language
  )
  const languageCode = props.language || language
  const getLanguageObject = languageCode =>
    languages.find(language => language.code.toLowerCase() === languageCode.toLowerCase())
  const languageObject = languageCode ? getLanguageObject(languageCode) : null
  const originalLanguageObject = originalLanguage && getLanguageObject(originalLanguage)
  const isOriginalLanguage =
    (language || '').toLowerCase() === (originalLanguage || '').toLowerCase()
  const limitMore = 4
  const classificationKindWithTotal =
    data && data.classificationKind
      ? `${data.classificationKind} ${totalBoxEntries(goodAndServiceList)}`
      : ''
  const { regionalEnabled } = TMDSGlobalsService.getRegionalConfig()

  return (
    <Block>
      {data && !!goodAndServiceList && !!goodAndServiceList.length && (
        <div>
          <DataItem
            label={t('tm.details.label.ClassificationKindCode.key')}
            data={classificationKindWithTotal}
          />
          <DataItem
            label={t('tm.details.label.classificationVersion.key')}
            data={data.classificationVersion}
          />
          <ModalCompareTranslations
            isOpen={props.modalOpen}
            languageObject={languageObject || {}}
            originalLanguageObject={originalLanguageObject}
            goodAndServiceList={goodAndServiceList}
            gsTranslated={gsTranslated}
            onCloseModal={() => props.setModalOpen(false)}
          />
          {!regionalEnabled && (
            <Block middle width7of12>
              <span>{t('results_details_language_key')}:</span>
              <TranslationsDropdown
                languages={languages}
                originalLanguageCode={originalLanguage}
                onLanguageSelected={(selectedCode, isOriginalLanguage) => {
                  props.setTranslateLanguage(selectedCode)
                  if (!isOriginalLanguage) {
                    fetchTranslations([data], selectedCode, props.fetchDetailTranslations)
                  } else {
                    props.clearTranslations(data.classificationKind)
                  }
                  props.setLanguage(selectedCode.toLowerCase())
                }}
                testId="trademark-detail-language-button"
                dropdownOptionsProps={{ 'data-test-id': 'language-dropdown-options' }}
              />
            </Block>
          )}
        </div>
      )}
      {!isOriginalLanguage && !!gsTranslated && !!gsTranslated.length && (
        <Block middle width5of12>
          <Link onClick={() => props.setModalOpen(true)}>
            {t('detail_tm_gs_compare_translations')}
          </Link>
        </Block>
      )}
      <Block paddingTop>
        {!isOriginalLanguage && (
          <TranslationsProvidedBy marginTop paddingTop paddingBottom language={language} />
        )}
        {!!goodAndServiceList &&
          goodAndServiceList.map((gs, i) => {
            return (
              <Block key={gs.niceClass}>
                {i < limitMore
                  ? getClassificationTerms(gs, gsTranslated, languageCode, gsTranslateError)(
                      fetching
                    )
                  : props.showMore &&
                    getClassificationTerms(gs, gsTranslated, languageCode, gsTranslateError)(
                      fetching
                    )}
              </Block>
            )
          })}
        {gsTranslated.find(term => term.error) && (
          <Block alignContents="center" paddingBottom marginBottom>
            <GSErrorTranslation />
          </Block>
        )}
        {!!goodAndServiceList &&
          !!goodAndServiceList.length &&
          goodAndServiceList.length > limitMore && (
            <ShowMore
              testId="boxGoodsAndServices-show-more"
              showMore={props.showMore}
              showMoreHandle={props.toggleShowMore}
            />
          )}
      </Block>
    </Block>
  )
}

const GoodsAndServicesBlock = enhance(GoodsAndServicesBlockComponent)

const BoxGoodsAndServicesComponent = ({
  data = [],
  hasItemsToShow,
  officeExceptionMessage,
  ...props
}) => {
  return (
    <Box
      boxId="boxGoodsAndServices"
      title={t('tm_details_section_goods_and_services')}
      hasItemsToShow={hasItemsToShow}
      dataTestId="item-detail-goods-services"
      officeExceptionMessage={officeExceptionMessage}
    >
      {data &&
        Array.isArray(data) &&
        data.map((goodsAndServicesBlock, index) => {
          return (
            <GoodsAndServicesWrapper key={`${goodsAndServicesBlock.classificationKind}-${index}`}>
              <GoodsAndServicesBlock data={goodsAndServicesBlock} {...props} />
              {data.length > 1 && index < data.length - 1 && <Separator />}
            </GoodsAndServicesWrapper>
          )
        })}
    </Box>
  )
}

const withDidComponent = compose(
  lifecycle({
    componentDidMount() {
      const { clearTranslations, data, setHasItemsToShow } = this.props
      const hasItems = Array.isArray(data) && data.length > 0
      hasItems !== this.props.hasItemsToShow && setHasItemsToShow(BOX.ID, hasItems)
      clearTranslations()
    },
  })
)

export default withDidComponent(BoxGoodsAndServicesComponent)
