import { REPRESENTATIVES_FIELDS } from '@app/common/dataManagers/types'

export const representativeFields = [
  { label: 'freeformatname.key', path: REPRESENTATIVES_FIELDS.FULL_NAME },
  {
    label:
      'tm.details.label.template.paintRepresentative.RepresentativeIncorporationCountryCode.key',
    path: REPRESENTATIVES_FIELDS.INCORPORATION_COUNTRY_CODE,
  },
  {
    label: 'tm.details.label.template.paintName.OrganizationName.key',
    path: REPRESENTATIVES_FIELDS.ORGANIZATION_NAME,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.RepresentativeIdentifier.key',
    path: REPRESENTATIVES_FIELDS.IDENTIFIER,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.RepresentativeLegalEntity.key',
    path: REPRESENTATIVES_FIELDS.LEGAL_ENTITY,
    pathTranslated: REPRESENTATIVES_FIELDS.LEGAL_ENTITY_TRANSLATED,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.RepresentativeKindCode.key',
    path: REPRESENTATIVES_FIELDS.KIND_CODE,
    pathTranslated: REPRESENTATIVES_FIELDS.KIND_CODE_TRANSLATED,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.RepresentativeNationalityCode.key',
    path: REPRESENTATIVES_FIELDS.NATIONALITY_CODE,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.RepresentativeIncorporationState.key',
    path: REPRESENTATIVES_FIELDS.INCORPORATION_STATE,
  },
  {
    label: 'tm.details.label.template.paintAddress.FreeFormatAddress.key',
    path: REPRESENTATIVES_FIELDS.FULL_ADDRESS,
  },
  {
    label: 'tm.details.label.template.paintRepresentative.Phone.key',
    path: REPRESENTATIVES_FIELDS.PHONE,
  },
  { label: 'tm.details.label.template.fax.key', path: REPRESENTATIVES_FIELDS.FAX },
  { label: 'tm.details.label.template.email.key', path: REPRESENTATIVES_FIELDS.EMAIL },
  { label: 'tm.details.label.template.url.key', path: REPRESENTATIVES_FIELDS.URL },
  {
    label: 'tm.details.label.template.paintAddress.FreeFormatAddress.key',
    path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.ADDRESS,
  },
  { label: 'addresscity.key', path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.CITY },
  {
    label: 'tm.details.label.template.paintAddress.AddressState.key',
    path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.STATE,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressPostcode.key',
    path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.POSTCODE,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressCountryCode.key',
    path: REPRESENTATIVES_FIELDS.ADDRESS_DETAILS.COUNTRY_CODE,
  },
]
