import { TMDSAuthService } from '@app/common/services'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t, Translation } from '@app/common/translationsService'
import { ROUTES } from '@app/containers/App/routes'
import certificateIcon from '@img/icon-certificates.svg'
import { Dropdown, Link, TooltipTrigger } from '@new-lucentum'
import React from 'react'
import { useSelector } from 'react-redux'

import { IconButton } from '../IconButton'
import CertificatePending from './components/CertificatePending'
import CertificatesDropdownMenu from './components/CertificatesDropdownMenu'
import CertificatesMandatoryInfoModal from './components/CertificatesMandatoryInfoModal'
import CertificatesRequestSuccessModal from './components/CertificatesRequestSuccessModal'
import UserPreferencesModal from './components/UserPreferencesModal'

const ButtonTooltip = () => {
  const loginUrl = TMDSRouterService.getPreparedRoute(ROUTES.LOGIN)
  const registerUrl = TMDSRouterService.getPreparedRoute(ROUTES.REGISTER)

  return (
    <div style={{ pointerEvents: 'auto' }}>
      <strong>{t('certificates.key')}</strong>
      {!TMDSAuthService.isLogged && (
        <div>
          <Translation
            literalKey="login_needed_tooltip"
            params={{
              loginLink: <Link to={loginUrl}>{t('login.key')}</Link>,
              registerLink: <Link to={registerUrl}>{t('registration.key')}</Link>,
            }}
          />
        </div>
      )}
    </div>
  )
}

const onTryCloseDropdown = isOpen => {
  return !isOpen
}

const CertificatesButton = ({ st13 }) => {
  const isOpen = useSelector(state => state.uiState.certificate.isOpen)
  const isUserPrefSubmitted = useSelector(state => state.uiState.certificate.isUserPrefSubmitted)
  const certificateStatus = useSelector(state => state.uiState.certificate.certificate.status)
  const isRequestSuccessful = useSelector(
    state => state.uiState.certificate.certificate.isRequestSuccessful
  )
  const isLoading = useSelector(state => state.uiState.spinner.showing)
  const userPref = useSelector(state => state.settings.certificateLegalNotesShown)

  return (
    !isLoading && (
      <>
        {isOpen && certificateStatus ? (
          <CertificatePending />
        ) : (
          <>
            {!isUserPrefSubmitted && !isRequestSuccessful && userPref && <UserPreferencesModal />}
            {(isUserPrefSubmitted || !userPref) && !isRequestSuccessful && (
              <CertificatesMandatoryInfoModal st13={st13} />
            )}
            {isRequestSuccessful && <CertificatesRequestSuccessModal />}
          </>
        )}
        <Dropdown
          menuComponent={CertificatesDropdownMenu}
          onTryCloseDropdown={() => onTryCloseDropdown(isOpen)}
          menuExtraProps={{ st13 }}
          noPadding
        >
          {({ toggleDropdown, isDropdownOpen }) => (
            <TooltipTrigger
              tooltipId={`certificates-button-tooltip`}
              place="bottom"
              renderTooltip={!isDropdownOpen && <ButtonTooltip />}
              delayHide={500}
            >
              <IconButton
                onClick={toggleDropdown}
                disabled={!TMDSAuthService.isLogged}
                style={{ display: 'flex', gap: '12px', alignItems: 'center' }}
              >
                <img
                  src={certificateIcon}
                  alt="certificate-icon"
                  style={{ width: 30, height: 30 }}
                />
                {t('certificates.key')}
              </IconButton>
            </TooltipTrigger>
          )}
        </Dropdown>
      </>
    )
  )
}

export default CertificatesButton
