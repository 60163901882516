import styled from 'styled-components'

import Link from '../Link'
import { completHover } from '../ThemeProvider/utils'

export const LanguageLink = styled(Link)`
  color: ${({ theme }) => theme.colorGray};
  display: inline-block;
  margin: ${({ theme }) => theme.line};
  padding-bottom: ${({ theme }) => theme.sizeSmall};
  padding-left: ${({ theme }) => theme.size};
  padding-top: ${({ theme }) => theme.sizeSmall};
  white-space: nowrap;
  width: 29%;
  &:hover {
    ${({ theme }) => completHover(theme)};
    background-color: ${({ theme }) => theme.colorAccent};
    text-decoration: none;
  }
  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }
`
