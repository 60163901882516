import React from 'react'
import { compose, lifecycle, withState } from 'recompose'
import { Box } from './Box'
import { DataList } from './DataItem'
import { formatData, hasItemsToShow } from '@app/common/utilities'

const getFormatedData = data => {
  const labelProps = { labelLocation: 'left', labelWidth: '120px', labelAlign: 'right' }
  return (
    Array.isArray(data) &&
    data.map(viennaCode => ({
      label: formatData(viennaCode.code),
      data: formatData(viennaCode.description),
      ...labelProps,
    }))
  )
}

const enhance = compose(
  withState('formatedData', 'setFormatedData', ({ data }) => getFormatedData(data)),
  lifecycle({
    componentDidMount() {
      const { formatedData, setHasItemsToShow } = this.props
      const hasItems = hasItemsToShow(formatedData)
      hasItems !== this.props.hasItemsToShow && setHasItemsToShow('boxViennaCodes', hasItems)
    },
  })
)

export default enhance(
  ({ literals, data = [], formatedData, officeExceptionMessage, ...props }) => (
    <Box
      boxId="boxViennaCodes"
      title={literals['results.column.tm.vienna.code.key']}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-vienna-codes"
      officeExceptionMessage={officeExceptionMessage}
    >
      {props.hasItemsToShow && (
        <DataList
          testId="boxViennaCodes-show-more"
          data={formatedData}
          literals={literals}
          shownItemsLimit={4}
        />
      )}
    </Box>
  )
)
