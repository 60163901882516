import React, { Children, cloneElement } from 'react'

import Nav from './styles'

const renderChildren = (c, t) =>
  t === 'vertical' || t === 'verticalFloating'
    ? Children.map(c, child =>
        child && child.props.active ? cloneElement(child, { activeVertical: true }) : child
      )
    : c

export default ({ className, children, type, ...props }) => (
  <Nav className={className} type={type} {...props}>
    {renderChildren(children, type)}
  </Nav>
)
