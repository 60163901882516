import React from 'react'

import { Notification } from '@new-lucentum'

const mapDetails = (detail, i, literals) => <li key={i}>{literals[detail] || detail}</li>

const NotificationError = ({ literals, message, details, onClose }) => (
  <Notification error onClose={onClose ? onClose : false}>
    {literals[message] || message}
    {Array.isArray(details) && details.length > 0 && (
      <ul>{details.map((detail, i) => mapDetails(detail, i, literals))}</ul>
    )}
  </Notification>
)

export default NotificationError
