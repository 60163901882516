import { getCertificateTypes } from '@app/actions/certificate'
import { TRADEMARK_FIELDS } from '@app/common/dataManagers/types'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'
import { TMDSGlobalsService } from '@app/common/services'
import tmdsRouterService from '@app/common/tmdsRouterService'
import { t } from '@app/common/translationsService'
import { hasItemsToShow } from '@app/common/utilities'
import { MultimediaModal } from '@app/components/MultimediaModal'
import { SearchByImageButton } from '@app/components/SearchByImageButton'
import { ROUTES } from '@app/containers/App/routes'
import { Block, Button, H2, Link, Modal } from '@new-lucentum'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CertificatesButton from '../CertificatesButton/CertificatesButton'
import { Box, Title } from './Box'
import { DataList } from './DataItem'
import { ButtonImage, Image, modalStyles, StyledButton, TrademarkLeftBlock } from './styles'
import { getFormatedData } from './utils'

const fields = [
  {
    label: 'results.column.tm.status.key',
    path: `tradeMark.${TRADEMARK_FIELDS.markCurrentStatusCode}`,
    pathTranslated: `tradeMark.${TRADEMARK_FIELDS.markCurrentStatusCodeTranslated}`,
    isLiteral: true,
  },
  {
    label: 'tm.details.label.seniorityIndicator.claimed.key',
    path: 'seniority',

    templateFunction: value => {
      const isEmptySeniorities = value.every(
        seniority => Object.keys(seniority?.seniorityBeenClaimed).length === 0
      )
      return value && value.length && !isEmptySeniorities ? (
        <span>
          {value?.map((tm, i) => {
            return (
              <Link
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  tmdsRouterService.navigate(
                    { pathname: ROUTES.DETAILS, state: { fromSeniority: true } },
                    { id: tm?.seniorityBeenClaimed?.seniorityBeenClaimedST13 }
                  )
                }}
              >
                {(i ? ', ' : ' ') + tm?.seniorityBeenClaimed?.seniorityBeenClaimedNumber}
              </Link>
            )
          })}
        </span>
      ) : null
    },
  },
  {
    label: 'tm.details.label.markCurrentStatusDate.key',
    path: `tradeMark.${TRADEMARK_FIELDS.markCurrentStatusDate}`,
    isDate: true,
  },
  { label: 'applicationnumber.key', path: `tradeMark.${TRADEMARK_FIELDS.applicationNumber}` },
  {
    label: 'tm.details.label.receivingOfficeCode.key',
    path: `tradeMark.${TRADEMARK_FIELDS.receivingOfficeCode}`,
  },
  {
    label: 'tm.details.label.registrationNumber.key',
    path: `tradeMark.${TRADEMARK_FIELDS.registrationNumber}`,
  },
  {
    label: 'tm.details.label.viennaClassification.key',
    path: `tradeMark.${TRADEMARK_FIELDS.viennaClassification}`,
  },
  {
    label: 'tm.details.label.IPRKind.key',
    path: `tradeMark.${TRADEMARK_FIELDS.iprKind}`,
    pathTranslated: `tradeMark.${TRADEMARK_FIELDS.iprKindTranslated}`,
    isLiteral: true,
  },
  {
    label: 'tm.details.label.markFeature.key',
    path: `tradeMark.${TRADEMARK_FIELDS.markFeature}`,
    pathTranslated: `tradeMark.${TRADEMARK_FIELDS.markFeatureTranslated}`,
    inidCode: 'trademarkType',
  },
  {
    label: 'tm.details.label.kindMark.key',
    path: `tradeMark.${TRADEMARK_FIELDS.kindMark}`,
    pathTranslated: `tradeMark.${TRADEMARK_FIELDS.kindMarkTranslated}`,
    isLiteral: true,
  },
  {
    label: 'tmdetails.label.mark.image.description',
    path: `tradeMark.${TRADEMARK_FIELDS.imageDescription}`,
  },
  {
    label: 'applicationlanguagecode.key',
    path: `tradeMark.${TRADEMARK_FIELDS.applicationLanguageCode}`,
  },
  {
    label: 'tm.details.label.secondLanguage.key',
    path: `tradeMark.${TRADEMARK_FIELDS.secondLanguageCode}`,
  },
  { label: 'applicationreference.key', path: `tradeMark.${TRADEMARK_FIELDS.applicationReference}` },
  { label: 'tm.details.label.filingPlace.key', path: `tradeMark.${TRADEMARK_FIELDS.fillingPlace}` },
  {
    label: 'tm.details.label.niceClassification.key',
    path: `tradeMark.${TRADEMARK_FIELDS.niceClass}`,
  },
  {
    label: 'tm.details.label.designationUnderMadridProtocol.key',
    path: `tradeMark.${TRADEMARK_FIELDS.designationUnderMadridProtocol}`,
    inidCode: 'designationsMadridProtocol',
  },
  {
    label: 'tm.details.label.designationUnderMadridProtocol96.key',
    path: `tradeMark.${TRADEMARK_FIELDS.designationUnderMadridProtocol96}`,
    inidCode: 'designationUnderMadridProtocol96',
  },
  {
    label: 'tm.details.label.RecordUseIntentCountryCode.key',
    path: `tradeMark.${TRADEMARK_FIELDS.markUseIntentCountryCode}`,
    inidCode: 'useIntentOffice',
  },
  {
    label: 'tm.details.label.basicApplicationNumber.key',
    path: 'tradeMark.basicRegistrationApplication.basicApplications.0.applicationNumber',
  },
  {
    label: 'tm.details.label.basicApplicationDate.key',
    path: 'tradeMark.basicRegistrationApplication.basicApplications.0.applicationDate',
    isDate: true,
  },
  {
    label: 'tm.details.label.basicRegistrationNumber.key',
    path: 'tradeMark.basicRegistrationApplication.basicRegistrations.0.registrationNumber',
  },
  {
    label: 'tm.details.label.basicRegistrationDate.key',
    path: 'tradeMark.basicRegistrationApplication.basicRegistrations.0.registrationDate',
    isDate: true,
  },
  {
    label: 'tm.details.label.totalMarkSeries.key',
    path: `tradeMark.${TRADEMARK_FIELDS.totalMarkSeries}`,
  },
  {
    label: 'tm.details.label.markColourClaimed.key',
    path: `tradeMark.${TRADEMARK_FIELDS.markImageColourClaimedText}`,
  },
  {
    label: 'tm.details.label.markTransliteration.key',
    path: `tradeMark.${TRADEMARK_FIELDS.markTransliteration}`,
  },
  {
    label: 'tm.details.label.markTranslation.key',
    path: `tradeMark.${TRADEMARK_FIELDS.markTranslation}`,
  },
  {
    label: 'tm.details.label.specialCircumstance.key',
    path: `tradeMark.${TRADEMARK_FIELDS.specialCircumstanceText}`,
  },
  {
    label: 'tm.details.label.internationalMarkKey.key',
    path: `tradeMark.${TRADEMARK_FIELDS.internationalMarkKey}`,
  },
  {
    label: 'tm.details.label.acquiredDistinctiveness.key',
    path: `tradeMark.${TRADEMARK_FIELDS.tradeDistinctivenessIndicator}`,
  },
  {
    label: 'tm.details.label.TradeDistinctivenessText.key',
    path: `tradeMark.${TRADEMARK_FIELDS.tradeDistinctivenessText}`,
  },
  {
    label: 'tm.details.label.AsItIsprotectionIndicator.key',
    path: `tradeMark.${TRADEMARK_FIELDS.asItIsprotectionIndicator}`,
  },
  {
    label: 'tm.details.label.formerApplicationNumber.key',
    path: `tradeMark.${TRADEMARK_FIELDS.formerApplicationNumber}`,
  },
  {
    label: 'tm.details.label.markDisclaimer.key',
    path: `tradeMark.${TRADEMARK_FIELDS.markDisclaimer}`,
  },
  {
    label: 'tm.details.label.receivingOfficeDate.key',
    path: `tradeMark.${TRADEMARK_FIELDS.receivingOfficeDate}`,
    isDate: true,
  },
  {
    label: 'tm.details.label.receivingOfficeNumber.key',
    path: `tradeMark.${TRADEMARK_FIELDS.receivingOfficeNumber}`,
  },
  { label: 'tm.details.label.comment.key', path: `tradeMark.${TRADEMARK_FIELDS.comments}` },
]

const checkHasMultimedia = data => {
  const hasVideo = data.video && data.video.length > 0
  const hasSound = data.sound && data.sound.length > 0
  const hasThreeDimensions = data.threeDModel && data.threeDModel.length > 0
  return hasVideo || hasSound || hasThreeDimensions
}

const BoxTrademarkComponent = ({ inidCodes, data = {}, officeExceptionMessage, ...props }) => {
  const [formatedData, setFormatedData] = React.useState([])
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)
  const isIPCertificate = useSelector(state => state.uiState.certificate.certificate.types.length)
  const officeMetadata = useSelector(state => state.appState.detail.officeMetadata)
  const [modalOpen, setModalOpen] = React.useState(false)
  const dispatch = useDispatch()

  const onCloseModal = React.useCallback(() => setModalOpen(false), [])
  const onOpenModal = React.useCallback(() => setModalOpen(true), [])
  const { regionalEnabled } = TMDSGlobalsService.getRegionalConfig()
  const { hasItemsToShow: propsHasItemsToShow, setHasItemsToShow } = props

  React.useEffect(
    () => {
      const newFormatedData = getFormatedData(fields, data, inidCodes)
      setFormatedData(newFormatedData)
      const hasItems =
        hasItemsToShow(newFormatedData) ||
        (data.tradeMark && (data.tradeMark.tmName || data.tradeMark.markImageURI))
      hasItems !== propsHasItemsToShow && setHasItemsToShow('boxBasicInfo', hasItems)
    },
    [data, showOriginalLanguageAttributes, propsHasItemsToShow, setHasItemsToShow, inidCodes]
  )

  const getResultsSum = () => {
    if (data?.tradeMark?.caseLaw) {
      const results = Object.values(data.tradeMark.caseLaw)
      if (results?.length) {
        const resultsNumber = results
          .filter(r => r.results)
          .map(r => r.results)
          .reduce((a, b) => a + b, 0)
        return resultsNumber
      }
      return 0
    }
    return 0
  }

  const isCaseLawVisibe = () => {
    return getResultsSum() > 0
  }

  const prepareCaseLawDefaultValue = () => {
    return `${getResultsSum()} ${t('caselawresults.key')}`
  }

  const prepareCaseLawOptions = () => {
    const options = []
    if (data?.tradeMark?.caseLaw) {
      if (
        data.tradeMark.caseLaw?.applicationNumber &&
        data.tradeMark.caseLaw?.applicationNumber?.results > 0
      ) {
        options.push({
          id: data.tradeMark.caseLaw?.applicationNumber?.url,
          title: `${data.tradeMark.caseLaw?.applicationNumber?.results} ${t(
            'caselawresultsfor.key'
          )} ${data.tradeMark.applicationNumber} ${t('applicationnumber.key')}`,
        })
      }
      if (data.tradeMark.caseLaw?.description && data.tradeMark.caseLaw?.description?.results > 0) {
        options.push({
          id: data.tradeMark.caseLaw?.description?.url,
          title: `${data.tradeMark.caseLaw?.description?.results} ${t('caselawresultsfor.key')} ${
            data.tradeMark.tmName
          }`,
        })
      }
    }
    return options
  }

  const getIPCertificateService = useCallback(
    () => {
      if (data.ST13) {
        dispatch(getCertificateTypes(data.ST13))
      }
    },
    [data.ST13, dispatch]
  )

  React.useEffect(
    () => {
      if (officeMetadata.isBlockChain) {
        getIPCertificateService()
      }
    },
    [officeMetadata, getIPCertificateService]
  )

  return (
    <Box
      boxId="boxBasicInfo"
      hasItemsToShow={propsHasItemsToShow}
      dataTestId="item-detail-main"
      officeExceptionMessage={officeExceptionMessage}
      title=" "
    >
      {propsHasItemsToShow && (
        <span>
          <div style={{ display: 'flex' }}>
            <Title>{!!data.tradeMark && data.tradeMark.tmName}</Title>
            {isIPCertificate > 0 && <CertificatesButton st13={data.ST13} />}
          </div>
          <TrademarkLeftBlock padding innerPadding width6of12>
            <div className="imageContainer">
              {data.tradeMark && data.tradeMark.markImageURI ? (
                <Block>
                  <ButtonImage borderless onClick={onOpenModal}>
                    <Image src={data.tradeMark.markImageURI} alt={data.tradeMark.tmName} />
                  </ButtonImage>
                  {modalOpen && (
                    <Modal
                      isOpen={modalOpen}
                      onRequestClose={onCloseModal}
                      title={data.tradeMark.tmName}
                      style={modalStyles}
                    >
                      <Block center>
                        <Image
                          fullImageSize
                          src={data.tradeMark.markImageURI}
                          alt={data.tradeMark.tmName}
                        />
                      </Block>
                    </Modal>
                  )}
                </Block>
              ) : checkHasMultimedia(data) ? (
                <MultimediaModal data={data} modalTitle={data.tradeMark.tmName} />
              ) : (
                <H2 bold>{data.tradeMark.tmName}</H2>
              )}
            </div>
            {!regionalEnabled && data.tradeMark && data.ST13 && (
              <div className="buttonContainer">
                {!regionalEnabled && data.tradeMark && data.tradeMark.markImageURI && data.ST13 && (
                  <SearchByImageButton
                    enable={data.tradeMark.markImageURI}
                    title={t('search.similar.trademarks.title')}
                    content={t('search.similar.trademarks.content')}
                    yes={t('yes.key')}
                    close={t('close.key')}
                    ST13={data.ST13}
                  />
                )}
                {isCaseLawVisibe() ? (
                  <StyledButton alignLeft type="dropdown" title={prepareCaseLawDefaultValue()}>
                    {prepareCaseLawOptions()?.map(({ id, title }, index) => {
                      return (
                        <a href={id} rel="noopener noreferrer" target="_blank">
                          <Button className="case-law-option-button" key={index} title={title} />{' '}
                        </a>
                      )
                    })}
                  </StyledButton>
                ) : null}
              </div>
            )}
          </TrademarkLeftBlock>

          <Block padding innerPadding width6of12>
            <DataList testId="boxTrademark-show-more" data={formatedData} shownItemsLimit={10} />
          </Block>
        </span>
      )}
    </Box>
  )
}

export const BoxTrademark = BoxTrademarkComponent
