import { NOTIFICATIONS_TYPES } from '@app/redux-types'

const defaultState = {
  unseenCounter: 0,
  notificationsData: [],
}

const checkUnseenNotifications = (state, action) => {
  if (state.unseenCounter > 0) {
    return {
      ...state,
      unseenCounter: 0,
      notificationsData: state.notificationsData.map(notification => ({
        ...notification,
        seen: true,
      })),
    }
  } else {
    return state
  }
}

const storeRecentNotifications = (state, action) => {
  return {
    ...state,
    unseenCounter: action.payload.unseenCounter,
    notificationsData: action.payload.notificationsData,
  }
}

const actionsMap = {
  [NOTIFICATIONS_TYPES.CHECK_UNSEEN_NOTIFICATIONS]: checkUnseenNotifications,
  [NOTIFICATIONS_TYPES.STORE_RECENT_NOTIFICATIONS]: storeRecentNotifications,
}

export const notificationsReducer = (state = defaultState, action) => {
  const mutation = actionsMap[action.type]
  return mutation ? mutation(state, action) : state
}
