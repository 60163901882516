import { Children } from 'react'

const isFilter = x => x && x.type && x.type.name && x.type.name === 'Filter'

const getKeys = (ch, src) =>
  isFilter(ch)
    ? Children.map(ch, ({ props: { label, labelKey } }) => ({
        label,
        labelKey,
      }))
    : Object.keys(src[0]).map(labelKey => ({ labelKey }))

const from = src => key => src.map(x => x[key])

const flatten = options => [].concat(...options)

const reducer = (ac, name) => {
  !ac.length && ac.concat({ name, count: 1 })
  const names = ac.map(x => x.name)
  names.includes(name) ? ac[names.indexOf(name)].count++ : ac.push({ name, count: 1 })
  return ac
}

export const keys = (exp, src, ch) => (exp ? src : getKeys(ch, src))

export const getOptions = (exp, src) => (key, data) =>
  (exp && data) || flatten(from(src)(key)).reduce(reducer, [])
