import { FIELDS } from '@app/containers/TMSearch/constants'

export const FILTERABLE_FIELDS_COMMON = {
  OFFICES: FIELDS.OFFICES,
  TERRITORIES: FIELDS.TERRITORIES,
  REGISTRATION_DATE: 'registrationDate',
}

export const FILTERABLE_FIELDS_TMVIEW = {
  GOODS_AND_SERVICES: FIELDS.GOODS_AND_SERVICES,
  VIENNA_CODE: FIELDS.VIENNA_CODE,
  TRADEMARK_STATUS: FIELDS.TRADEMARK_STATUS,
  TRADEMARK_TYPE: FIELDS.TRADEMARK_TYPE,
  APPLICANT_NAME: FIELDS.APPLICANT_NAME,
  APPLICANT_NAME_OFFICES: 'applicantNameOffices',
  APPLICATION_DATE: 'applicationDate',
  CURRENTLY_OPPOSABLE: FIELDS.CURRENTLY_OPPOSABLE,
  SENIORITY_CLAIMED: FIELDS.SENIORITY_CLAIMED,
}

export const FILTERABLE_FIELDS_DSVIEW = {
  DESIGN_STATUS: FIELDS.DESIGN_STATUS,
  OWNER_NAME: 'ownerName',
  OWNER_NAME_OFFICES: 'ownerNameOffices',
  DESIGNER_NAME: FIELDS.DESIGNER_NAME,
  LOCARNO: FIELDS.LOCARNO,
  FILING_DATE: FIELDS.FILING_DATE,
}

export const FILTERABLE_FIELDS = {
  ...FILTERABLE_FIELDS_COMMON,
  ...FILTERABLE_FIELDS_TMVIEW,
  ...FILTERABLE_FIELDS_DSVIEW,
}
