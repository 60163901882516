import { CORRESPONDENCE_ADDRESS_FIELDS } from '@app/common/dataManagers/types'
export const correspondenceAddressFields = [
  { label: 'freeformatname.key', path: CORRESPONDENCE_ADDRESS_FIELDS.FULL_NAME },
  { label: 'addresscity.key', path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.CITY },
  {
    label: 'organizationname.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ORGANIZATION_NAME,
  },
  {
    label: 'tm.details.label.template.paintName.LegalForm.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.LEGAL_FORM,
  },
  {
    label: 'address.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.ADDRESS,
  },
  {
    label: 'addresscountrycode.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.COUNTRY_CODE,
  },
  {
    label: 'tm.details.label.template.paintAddress.AddressState.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.STATE,
  },
  {
    label: 'addresspostcode.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.ADDRESS_DETAILS.POSTCODE,
  },
  {
    label: 'address.key',
    path: CORRESPONDENCE_ADDRESS_FIELDS.FULL_ADDRESS,
  },
]
