import Api from '@app/common/api'
import {
  DEFAULT_ACCEPT_COOKIE_VALUE,
  DEFAULT_COOKIE_NAME,
  DEFAULT_REJECT_COOKIE_VALUE,
} from '@app/common/services'
import cookie from 'js-cookie'
const api = new Api()

export const getCookiesBanner = url => {
  return api.get(url, { completePath: true })
}

export const dataProtectionCookieExists = (cookieName = DEFAULT_COOKIE_NAME) => {
  return !!cookie.get(cookieName)
}

const setCookie = (cookieName, cookieValue) => {
  const expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + 180)

  cookie.set(cookieName, cookieValue, { expires: expireDate })
}

export const acceptAllCookies = (
  cookieName = DEFAULT_COOKIE_NAME,
  value = DEFAULT_ACCEPT_COOKIE_VALUE
) => {
  setCookie(cookieName, value)
}

export const onlyEssentialCookies = (
  cookieName = DEFAULT_COOKIE_NAME,
  value = DEFAULT_REJECT_COOKIE_VALUE
) => {
  setCookie(cookieName, value)
}
