import {
  getNotifications as getNotificationsAction,
  storeRecentNotifications,
} from '@app/actions/notifications'
import { userprefsDataManager } from '@app/common/dataManagers'
import { getIndicationOfProductText } from '@app/common/fields'
import TMDSContextService from '@app/common/tmdsContextService'
import { manageExport } from '@app/common/utils/exportManagement'
import store from '@app/store'

import { TMDSAuthService } from '../tmdsAuthService'

const { dispatch } = store

const getRecentNotifications = () => {
  const fetchData = async () => {
    try {
      const recentNotificationPromise = await userprefsDataManager.getRecentNotifications()

      dispatch(storeRecentNotifications(recentNotificationPromise))

      return recentNotificationPromise
    } catch (error) {
      console.error('Error occurred during long-polling:', error)
    } finally {
      setTimeout(fetchData, 60000)
    }
  }

  fetchData()
}

if (TMDSAuthService.isLogged) {
  getRecentNotifications()
}

const getNotifications = filterParams => {
  return dispatch(getNotificationsAction(filterParams))
}

const exportNotifications = (selectedIds, recaptchaResponse, documentType) => {
  const exportPromise = userprefsDataManager.exportNotifications(
    selectedIds,
    recaptchaResponse,
    documentType
  )
  manageExport(exportPromise, 'notifications', documentType)
  return exportPromise
}

const MAX_INDICATION_PRODUCT_LENGTH = 250
const prepareNotificationPrefixText = (fields = [], language = 'en') => {
  const [resourceNumber, resourceName] = fields
  let parsedResourceName = resourceName
  if (resourceName && TMDSContextService.isDsview()) {
    const preparedIndicationOfProduct =
      getIndicationOfProductText(resourceName, language) ||
      getIndicationOfProductText(resourceName) ||
      resourceName

    parsedResourceName =
      preparedIndicationOfProduct.length > MAX_INDICATION_PRODUCT_LENGTH
        ? `${preparedIndicationOfProduct.slice(0, MAX_INDICATION_PRODUCT_LENGTH)}..`
        : preparedIndicationOfProduct
  }
  const alertData = [resourceNumber, parsedResourceName].filter(item => item)
  return alertData.join(' - ')
}

export const TMDSNotificationsService = {
  getRecentNotifications,
  getNotifications,
  exportNotifications,
  prepareNotificationPrefixText,
}
