import { APPLICANTS_FIELDS } from '@app/common/dataManagers/types'
export const applicantFields = [
  {
    label: 'organizationname.key',
    path: APPLICANTS_FIELDS.ORGANIZATION_NAME,
  },
  { label: 'results.column.ds.applicant.name.key', path: APPLICANTS_FIELDS.FULL_NAME },
  {
    label: 'applicantincorporationcountrycode.key',
    path: APPLICANTS_FIELDS.INCORPORATION_COUNTRY_CODE,
  },
  {
    label: 'applicantidentifier.key',
    path: APPLICANTS_FIELDS.IDENTIFIER,
  },
  {
    label: 'applicantnationalitycode.key',
    path: APPLICANTS_FIELDS.NATIONALITY_CODE,
    inidCode: 'applicantNationalityCountry',
  },
  {
    label: 'applicantlegalentity.key',
    path: APPLICANTS_FIELDS.LEGAL_ENTITY,
    pathTranslated: APPLICANTS_FIELDS.LEGAL_ENTITY_TRANSLATED,
    inidCode: 'applicantLegalEntity',
  },
  {
    label: 'applicantincorporationcountrycode.key',
    path: APPLICANTS_FIELDS.INCORPORATION_STATE,
    inidCode: 'applicantIncorporationState',
  },
  {
    label: 'applicantnationalitycode.key',
    path: APPLICANTS_FIELDS.ENTITLEMENT_NATIONALITY_CODE,
  },
  {
    label: 'tm.details.label.template.paintApplicant.EntitlementEstablishmentCode.key',
    path: APPLICANTS_FIELDS.ENTITLEMENT_ESTABLISHMENT_CODE,
  },
  {
    label: 'tm.details.label.template.paintApplicant.EntitlementDomiciledCode.key',
    path: APPLICANTS_FIELDS.ENTITLEMENT_DOMICILED_CODE,
  },
  {
    label: 'address.key',
    path: APPLICANTS_FIELDS.FULL_ADDRESS,
  },
  {
    label: 'address.key',
    path: APPLICANTS_FIELDS.ADDRESS_DETAILS.ADDRESS,
  },
  { label: 'addresscity.key', path: APPLICANTS_FIELDS.ADDRESS_DETAILS.CITY },
  {
    label: 'tm.details.label.template.paintAddress.AddressState.key',
    path: APPLICANTS_FIELDS.ADDRESS_DETAILS.STATE,
  },
  {
    label: 'addresspostcode.key',
    path: APPLICANTS_FIELDS.ADDRESS_DETAILS.POSTCODE,
  },
  {
    label: 'phone.key',
    path: APPLICANTS_FIELDS.PHONE,
  },
  { label: 'fax.key', path: APPLICANTS_FIELDS.FAX },
  { label: 'email.key', path: APPLICANTS_FIELDS.EMAIL },
  { label: 'url.key', path: APPLICANTS_FIELDS.URL },
  {
    label: 'addresscountrycode.key',
    path: APPLICANTS_FIELDS.ADDRESS_DETAILS.COUNTRY_CODE,
  },
]
