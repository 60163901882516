import { fullfilled, pending, rejected } from './utils/reduxPromise'

const FETCH_DETAIL = 'FETCH_DETAIL'
const INIT_DETAIL_NAVIGATION_DATA = 'INIT_DETAIL_NAVIGATION_DATA'
const RESET_DETAIL_NAVIGATION_DATA = 'RESET_DETAIL_NAVIGATION_DATA'
const SET_DETAIL_TRADEMARK_ID = 'SET_DETAIL_TRADEMARK_ID'
const SET_HAS_ITEMS_TO_SHOW = 'SET_HAS_ITEMS_TO_SHOW'
const SET_ORIGINAL_LANGUAGE = 'SET_ORIGINAL_LANGUAGE'
const SET_OFFICE_EXCEPTION_MESSAGES = 'SET_OFFICE_EXCEPTION_MESSAGES'
const SET_OFFICE_METADATA = 'SET_OFFICE_METADATA'

export const DETAILS_TYPES = {
  FETCH_DETAIL,
  FETCH_DETAIL_FULFILLED: fullfilled(FETCH_DETAIL),
  FETCH_DETAIL_PENDING: pending(FETCH_DETAIL),
  FETCH_DETAIL_REJECTED: rejected(FETCH_DETAIL),
  INIT_DETAIL_NAVIGATION_DATA,
  RESET_DETAIL_NAVIGATION_DATA,
  SET_DETAIL_TRADEMARK_ID,
  SET_HAS_ITEMS_TO_SHOW,
  SET_ORIGINAL_LANGUAGE,
  SET_OFFICE_EXCEPTION_MESSAGES,
  SET_OFFICE_METADATA,
}
