export const BOXES_DSVIEW = {
  BASIC_INFO: {
    ID: 'boxBasicInfo',
    TITLE: 'design.key',
  },
  INDICATION_OF_PRODUCT: {
    ID: 'boxIndicationOfProduct',
    TITLE: 'common.report.step.column.indprod.key',
  },
  DATES: {
    ID: 'boxDates',
    TITLE: 'filters.tabs.dates.key',
  },
  OFFICE: {
    ID: 'boxOffice',
    TITLE: 'ds.office.key',
  },
  OWNER: {
    ID: 'boxOwner',
    TITLE: 'owner.key',
  },
  REPRESENTATIVE: {
    ID: 'boxRepresentative',
    TITLE: 'representative.key',
  },
  DESIGNERS: {
    ID: 'boxDesigners',
    TITLE: 'designer.key',
  },
  CORRESPONDENCE_ADDRESS: {
    ID: 'boxCorrespondenceAddress',
    TITLE: 'details.left.menu.correspondenceaddress.key',
  },
  PUBLICATION: {
    ID: 'boxPublication',
    TITLE: 'publication.key',
  },
  EXHIBITION_PRIORITY: {
    ID: 'boxExhibitionPriority',
    TITLE: 'exhibition.priority.key',
  },
  PRIORITY: {
    ID: 'boxPriority',
    TITLE: 'convention.priority.key',
  },
  RECORDALS: {
    ID: 'boxRecordals',
    TITLE: 'recordals.key',
  },
  RENEWALS: {
    ID: 'boxRenewals',
    TITLE: 'renewals.key',
  },
  SOUND: {
    ID: 'boxSound',
    TITLE: 'tm.type.sound.key',
  },
  VIDEO: {
    ID: 'boxVideo',
    TITLE: 'videos.key',
  },
  THREE_DIMENSION: {
    ID: 'boxThreeDimension',
    TITLE: 'tm.type.3d-shape.key',
  },
}

export const BOXES_TMVIEW = {
  BASIC_INFO: {
    ID: 'boxBasicInfo',
    TITLE: 'tm_details_tm_info',
  },
  DATES: {
    ID: 'boxDates',
    TITLE: 'filters.tabs.dates.key',
  },
  OFFICE: {
    ID: 'boxOffice',
    TITLE: 'tm_details_section_office',
  },
  GOODS_AND_SERVICES: {
    ID: 'boxGoodsAndServices',
    TITLE: 'tm_details_section_goods_and_services',
  },
  OWNER: {
    ID: 'boxOwner',
    TITLE: 'tm.details.left.menu.owner.key',
  },
  REPRESENTATIVE: {
    ID: 'boxRepresentative',
    TITLE: 'tm.details.left.menu.representative.key',
  },
  CORRESPONDENCE_ADDRESS: {
    ID: 'boxCorrespondenceAddress',
    TITLE: 'tm.details.left.menu.correspondenceaddress.key',
  },
  VIENNA_CODES: {
    ID: 'boxViennaCodes',
    TITLE: 'results.column.tm.vienna.code.key',
  },
  SENIORITY: {
    ID: 'boxSeniority',
    TITLE: 'tm.details.left.menu.seniority.key',
  },
  EXHIBITION_PRIORITY: {
    ID: 'boxExhibitionPriority',
    TITLE: 'tm.details.label.ExhibitionPriority.key',
  },
  PRIORITY: {
    ID: 'boxPriority',
    TITLE: 'tm.details.left.menu.priority.key',
  },

  IR_TRANSFORMATION: {
    ID: 'boxIRTransformation',
    TITLE: 'tm.details.left.menu.internationalregistration.key',
  },
  PUBLICATION: {
    ID: 'boxPublication',
    TITLE: 'tm.details.left.menu.publication.key',
  },
  OPPOSITION: {
    ID: 'boxOpposition',
    TITLE: 'tm.details.left.menu.opposition.key',
  },
  RECORDALS: {
    ID: 'boxRecordals',
    TITLE: 'tm.details.left.menu.recordals.key',
  },
  APPEALS: {
    ID: 'boxAppeals',
    TITLE: 'tm.details.left.menu.appeals.key',
  },
  CANCELLATIONS: {
    ID: 'boxCancellations',
    TITLE: 'tm_details_section_cancellations',
  },
  RENEWALS: {
    ID: 'boxRenewals',
    TITLE: 'tm.details.left.menu.renewals.key',
  },
  SOUND: {
    ID: 'boxSound',
    TITLE: 'tm.type.sound.key',
  },
  VIDEO: {
    ID: 'boxVideo',
    TITLE: 'videos.key',
  },
  THREE_DIMENSION: {
    ID: 'boxThreeDimension',
    TITLE: 'tm.type.3d-shape.key',
  },
}
