import React from 'react'

import Base from './Base'
import niceOption, { niceFilter, onKeyDown } from './Nice'
// Custom options
import tmviewOption, { tmviewFilter } from './TMView'
import viennaOption, { viennaFilter, viennaKeyDown } from './Vienna'

const Select = ({ type, src, ...props }) => {
  switch (type) {
    case 'nice':
      return (
        <Base
          src={src}
          labelKey="num"
          valueKey="num"
          infoKey="info"
          filterOption={niceFilter(props.labelKey || 'num')}
          customOption={niceOption}
          optionClassName="nice"
          onKeyDown={onKeyDown}
          {...props}
          collapsed={false}
        />
      )
    case 'tmview':
      return (
        <Base
          src={src}
          icon="camera"
          labelKey="RegistrationNumber"
          valueKey="TradeMarkIdentifier"
          filterOption={tmviewFilter(props.labelKey)}
          optionClassName="TMView"
          customOption={tmviewOption}
          {...props}
          collapsed={false}
        />
      )
    case 'vienna':
      return (
        <Base
          src={src}
          collapsed={true}
          labelKey="description"
          valueKey="code"
          filterOption={viennaFilter(props.labelKey || 'code')}
          optionClassName="vienna"
          customOption={viennaOption}
          onKeyDown={viennaKeyDown}
          {...props}
        />
      )
    default:
      return (
        <Base
          src={src}
          multi={false}
          clearable={false}
          closeOnSelect={true}
          {...props}
          collapsed={false}
        />
      )
  }
}

export default Select
