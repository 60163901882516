import React, { Children, Component } from 'react'

import Block from '../Block'
import Pagination from '../Pagination'
import { TransposedStyled } from './styles'
import Row from './Row'
import { colorLighterBackground } from '../ThemeProvider/theme'

const getPagination = chl => Children.map(chl, c => c !== null && c.type === Pagination && c)

const getRowChildren = children => Children.map(children, c => c !== null && c.type === Row && c)
const getArrayChildren = children => {
  const toReturn =
    Children.count(children) === 0 ? [] : Children.count(children) === 1 ? [children] : children
  return toReturn
}
const getChildren = children => getRowChildren(getArrayChildren(children))

export default class Transposed extends Component {
  state = { scroll: 0 }

  onScroll = e => this.setState({ scroll: e.currentTarget.scrollLeft })

  render() {
    const { scroll } = this.state
    const { children, src, ...props } = this.props
    return (
      <div>
        <TransposedStyled onScroll={this.onScroll} {...props}>
          <table>
            <tbody>
              {getChildren(children).map(
                (child, index) =>
                  child.props &&
                  React.cloneElement(
                    child,
                    { items: src, scroll, key: index },
                    child.props.children
                  )
              )}
            </tbody>
          </table>
        </TransposedStyled>
        {children && (
          <Block marginTop right>
            {getPagination(children)}
          </Block>
        )}
      </div>
    )
  }
}
