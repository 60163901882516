import React from 'react'
import { Field } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

export default props => <Field {...props} component={Recaptcha} />

const Recaptcha = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <ReCAPTCHA
    {...field}
    {...props}
    onChange={value => {
      setFieldValue(field.name, value)
      setFieldTouched(field.name, true)
    }}
  />
)
