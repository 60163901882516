import { fullfilled, pending, rejected } from './utils/reduxPromise'

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
const CHECK_UNSEEN_NOTIFICATIONS = 'CHECK_UNSEEN_NOTIFICATIONS'
const STORE_RECENT_NOTIFICATIONS = 'STORE_RECENT_NOTIFICATIONS'

export const NOTIFICATIONS_TYPES = {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FULLFILLED: fullfilled(GET_NOTIFICATIONS),
  GET_NOTIFICATIONS_PENDING: pending(GET_NOTIFICATIONS),
  GET_NOTIFICATIONS_REJECTED: rejected(GET_NOTIFICATIONS),
  CHECK_UNSEEN_NOTIFICATIONS,
  STORE_RECENT_NOTIFICATIONS,
}
