import { fullfilled, pending, rejected } from './utils/reduxPromise'
const CHANGE_PASSWORD = 'FETCH_CHANGE_PASSWORD'
const EMPTY_CHANGE_PASSWORD_FIELDS = 'EMPTY_CHANGE_PASSWORD_FIELDS'
const SET_INVALID_CHANGE_PASSWORD_FIELD = 'SET_INVALID_CHANGE_PASSWORD_FIELD'
const CHANGE_CHANGE_PASSWORD_FIELD = 'CHANGE_CHANGE_PASSWORD_FIELD'
const CLOSE_CHANGE_PASSWORD_SUCCESS = 'CLOSE_CHANGE_PASSWORD_SUCCESS'
const CLOSE_CHANGE_PASSWORD_BE_ERROR = 'CLOSE_CHANGE_PASSWORD_BE_ERROR'

const FETCH_REMIND_PASSWORD = 'FETCH_REMIND_PASSWORD'

const FETCH_LOGIN = 'FETCH_LOGIN'
const FETCH_REGISTER = 'FETCH_REGISTER'
const FETCH_LOGOUT = 'FETCH_LOGOUT'
const CHECK_LOGGED_USER = 'CHECK_LOGGED_USER'

export const AUTH_TYPES = {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FULLFILLED: fullfilled(CHANGE_PASSWORD),
  CHANGE_PASSWORD_PENDING: pending(CHANGE_PASSWORD),
  CHANGE_PASSWORD_REJECTED: rejected(CHANGE_PASSWORD),
  EMPTY_CHANGE_PASSWORD_FIELDS,
  SET_INVALID_CHANGE_PASSWORD_FIELD,
  CHANGE_CHANGE_PASSWORD_FIELD,
  CLOSE_CHANGE_PASSWORD_SUCCESS,
  CLOSE_CHANGE_PASSWORD_BE_ERROR,
  FETCH_REMIND_PASSWORD,
  FETCH_REMIND_PASSWORD_FULFILLED: fullfilled(FETCH_REMIND_PASSWORD),
  FETCH_REMIND_PASSWORD_PENDING: pending(FETCH_REMIND_PASSWORD),
  FETCH_REMIND_PASSWORD_REJECTED: rejected(FETCH_REMIND_PASSWORD),
  FETCH_LOGIN,
  FETCH_LOGIN_FULFILLED: fullfilled(FETCH_LOGIN),
  FETCH_LOGIN_PENDING: pending(FETCH_LOGIN),
  FETCH_LOGIN_REJECTED: rejected(FETCH_LOGIN),
  FETCH_REGISTER,
  FETCH_REGISTER_FULFILLED: fullfilled(FETCH_REGISTER),
  FETCH_REGISTER_PENDING: pending(FETCH_REGISTER),
  FETCH_REGISTER_REJECTED: rejected(FETCH_REGISTER),
  FETCH_LOGOUT,
  FETCH_LOGOUT_FULFILLED: fullfilled(FETCH_LOGOUT),
  FETCH_LOGOUT_PENDING: pending(FETCH_LOGOUT),
  FETCH_LOGOUT_REJECTED: rejected(FETCH_LOGOUT),
  CHECK_LOGGED_USER,
  CHECK_LOGGED_USER_FULFILLED: fullfilled(CHECK_LOGGED_USER),
  CHECK_LOGGED_USER_PENDING: pending(CHECK_LOGGED_USER),
  CHECK_LOGGED_USER_REJECTED: rejected(CHECK_LOGGED_USER),
}
