import {
  Block as BlockTMV,
  Button as ButtonLCT,
  Checkbox as CheckboxLCT,
  Icon,
} from '@new-lucentum'
import { FixedSizeList } from 'react-window'
import styled, { css } from 'styled-components'

import ErrorMessage from '../ErrorMessage/ErrorMessage'

export const colour = {
  OR: { backgroundColor: '#bcbec0', borderColor: '#bcbec0' },
  AND: { backgroundColor: '#9bba46', borderColor: '#9bba46' },
  NOT: { backgroundColor: '#ba1c21', borderColor: '#ba1c21' },
}

const completHover = ({ color }) => `
    position: relative;
    &:before {
      background-color: ${color};
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 3px;
  }
`

export const scroll = ({ color }) => `
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${color};
    border-radius: 100px;
    height: 50%;
  }
  ::-webkit-scrollbar {
      height: 6px;
      width: 6px;
  }
`

export const BlockTooltip = styled(BlockTMV)`
  max-width: 300px;
`

export const BlockMenu = styled(BlockTMV)`
  border-top: solid ${({ theme }) => `${theme.line} ${theme.colorGrayLight}`};
  .noWrap {
    white-space: nowrap;
  }

  ${({ theme }) =>
    theme.responsive(css`
      .menu-info {
        display: none;
      }
    `)}
`

export const Container = styled(BlockTMV)`
  position: relative;

  .react-select-comp {
    &__multi-value__label {
      ${({ theme }) => theme.baseText};
    }

    &__multi-value__remove {
      font-size: 12px;
      cursor: pointer;
      padding-left: 5px;
      padding-right: 7px;
      &:hover {
        background: transparent;
        color: ${({ theme }) => theme.colorGray};
      }
    }

    &__clear-indicator {
      ${({ theme }) => theme.baseText};
      padding-right: 4px;
      cursor: pointer;

      i {
        color: ${({ theme }) => theme.colorGrayLight};

        &:hover {
          color: ${({ theme }) => theme.color};
        }
      }
    }

    &__value-container--is-multi {
      & > div:first-child {
        .react-select-comp__multi-value {
          margin-left: 0;
        }
      }
    }
  }
`

export const styleSelect = {
  menuList: (base, { theme }) => ({
    ...base,
    ...[scroll(theme)],
  }),
  valueContainer: base => ({
    ...base,
    overflow: 'visible',
  }),
  indicatorsContainer: base => ({
    ...base,
    height: 40,
  }),
  control: base => ({
    ...base,
    fontSize: 16,
    backgroundColor: 'transparent',
    border: 'unset',
    boxShadow: 'unset',
    minHeight: 40,
  }),
  option: base => ({
    ...base,
    backgroundColor: 'transparent',
    padding: 14,
  }),
  multiValue: (base, state) => ({
    ...base,
    border: '1px solid #2C3F35',
    borderRadius: 2,
    backgroundColor: 'white',
    padding: 0,
    paddingLeft: 10,
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    padding: 0,
    paddingLeft: 0,
  }),
  placeholder: base => ({
    ...base,
    color: '#aaa',
  }),
  dropdownIndicator: (base, { theme }) => ({
    ...base,
    fontSize: theme.fontSize,
    color: theme.colorGray,
  }),
}

export const ControlContainer = styled.div`
  ${({ theme }) => scroll(theme)};
  background-color: ${({ theme }) => theme.colorWhite};
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${({ theme }) => theme.colorGrayAccent};
  padding: 0 20px;
  ${({ theme }) =>
    theme.responsive(css`
      padding: 0 10px;
    `)};

  ${({ theme, children }) =>
    children.props.isFocused &&
    css`
      color: inherit;
    `};
  & + div {
    z-index: 100;
  }
  .react-select-comp__value-container {
    padding: 2px 0;
  }

  .react-select-comp__dropdown-indicator {
    padding-right: 0;
  }

  .react-select-comp-boolean__dropdown-indicator {
    i {
      padding-top: 3px;
    }
  }

  .react-select-comp__placeholder {
    ${({ theme }) => theme.baseText};
    color: ${({ theme }) => theme.colorGrayLight};
    font-size: 14px;
  }
`

export const Group = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const GroupBadge = styled.span`
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizeSmaller};
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.167em 0.5em;
  text-align: center;
`

export const ValueWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const Checkbox = styled(CheckboxLCT)`
  margin-right: ${({ theme }) => theme.size};
`

export const Circle = styled.div`
  ${({ boolean }) => colour[boolean]};
  border-radius: 50%;
  height: ${({ theme }) => theme.size};
  margin-right: 5px;
  min-width: ${({ theme }) => theme.size};
`

export const Button = styled(ButtonLCT)`
  border: unset;
  left: ${({ theme }) => `-${theme.sizeLarge}`};
  padding: ${({ theme }) => theme.lineThick};
  position: absolute;
  top: 30%;
  i {
    font-size: ${({ theme }) => theme.fontSizeSmaller};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colorTransparent};
    color: ${({ theme }) => theme.color};
  }
`

export const Option = styled.div`
  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      ${completHover(theme)};
      background-color: ${theme.colorAccent};
    `};

  ${({ level, theme }) =>
    level &&
    css`
      padding-left: calc(${theme.size} * ${level});
    `};

  align-items: center;
  border-bottom: solid ${({ theme }) => `${theme.colorGrayAccent} ${theme.line}`};
  display: flex;
  &:hover {
    ${({ theme }) => completHover(theme)};
    background-color: ${({ theme }) => theme.colorAccent};
  }
  & > div {
    ${({ vienna, theme }) => vienna && `padding: 0 0 0 ${theme.size}`};
  }
  & > div,
  p {
    white-space: ${({ expanded }) => (expanded ? 'unset' : 'nowrap')};
  }
  p {
    margin-left: ${({ theme }) => theme.sizeSmall};
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  strong {
    white-space: nowrap;
  }
  .containerOption {
    position: relative;
  }
  .title {
    ${({ vienna, theme }) => vienna && `padding: ${theme.size} ${theme.size} ${theme.size} 0`};
    margin-left: 10px;
    align-items: center;
    display: flex;
    white-space: nowrap;
    position: relative;
  }

  & .highlighted {
    ${({ theme }) => theme.baseText};
    background-color: ${({ theme }) => theme.colorGrayAccent};
    color: ${({ theme }) => theme.colorGray};
  }
`

export const StyledButton = styled(ButtonLCT)`
  min-width: 182px;
  height: ${({ isMobile }) => (isMobile ? '60px;' : 'auto;')};
`

export const ClearAllIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorGrayLight};
`

export const FixedSizeListStyled = styled(FixedSizeList)`
  ${({ theme }) => scroll(theme)};
`
export const VerticalAlign = styled.div`
  line-height: ${({ isMobile }) => (isMobile ? '80px;' : 'normal;')};
`

export const ErrorMsg = styled(ErrorMessage)`
  display: inline-block;
  top: -20px;
  &:after {
    border-right-color: ${({ theme }) => theme.colorGrayAccent};
    top: 38px;
  }
`
