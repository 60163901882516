import { handleExportHistory } from '@app/actions/export'
import { changeExportCaptcha, resetRecaptchaResponseKey } from '@app/actions/history'
import { fetchLiterals } from '@app/actions/literals'
import {
  clearShareSearchForm,
  closeModalShareByEmail,
  createShortLink,
  onChangeEmailField,
  sendEmail,
  setShareEmailNotification,
  validateEntireForm,
} from '@app/actions/shareSearch'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t } from '@app/common/translationsService'
import { ActionBarExpandable } from '@app/components/ActionBarExpandable'
import ExportButton from '@app/components/ExportButton'
import { Sticky } from '@app/components/Sticky'
import { Button, Flex, Tooltip } from '@new-lucentum'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import TMNavigation from '../../components/DetailTM/TMNavigation'
import ShareSearch from '../ShareSearch'

const BackButton = styled(Button)`
  padding: 10px;
  display: flex;
  align-items: center;
  i {
    font-size: inherit !important;
    transform: rotate(90deg);
  }
`

const StyledDetailActionBar = styled.div`
  flex: 1;
  .navigation {
    position: absolute;
    padding-bottom: 10px;
    right: 47%;
  }
`

const ActionBarHistory = ({ ...props }) => {
  const state = useSelector(state => state)
  const { historyId, targetHistoryId } = useSelector(state => state.appState.history.exportHistory)
  const routeState = TMDSRouterService.getRouteState()
  const { fromSeniority } = routeState || {}
  const dispatch = useDispatch()

  useEffect(() => {
    fetchLiterals()
  }, [])

  const createLink = url => {
    dispatch(createShortLink(url))
  }

  const changeEmailField = (field, value) => {
    dispatch(onChangeEmailField(field, value))
  }

  const validateForm = () => {
    dispatch(validateEntireForm(state))
  }

  const closeEmailModal = () => {
    dispatch(closeModalShareByEmail())
  }

  const shareEmailNotification = () => {
    dispatch(setShareEmailNotification())
  }

  const clearShareForm = () => {
    dispatch(clearShareSearchForm())
  }

  const sendemail = fieldValues => {
    dispatch(sendEmail(fieldValues))
  }

  const handleChangeCaptcha = value => {
    dispatch(changeExportCaptcha(value))
  }

  const resetRecaptchaResponseKeyExport = () => {
    dispatch(resetRecaptchaResponseKey())
  }

  const handleExportButton = (documentType, resource, fileType, recaptchaResponseKey, pageSize) => {
    dispatch(
      handleExportHistory(
        fileType[0],
        resource,
        documentType,
        recaptchaResponseKey,
        historyId,
        targetHistoryId,
        150
      )
    )
  }

  return (
    <Sticky>
      <ActionBarExpandable variant="details">
        {({ isExpanded }) => (
          <StyledDetailActionBar>
            <Flex flex={1} justify="space-between">
              <Flex alignItems="flex-end">
                <React.Fragment>
                  <BackButton
                    onClick={() => {
                      TMDSRouterService.navigate(
                        `/detail/${encodeURIComponent(props.match.params.id)}`
                      )
                    }}
                    borderless
                    icon="chevronDownSmall"
                    title={t('button_back')}
                  />
                </React.Fragment>

                <ExportButton
                  changeExportCaptcha={handleChangeCaptcha}
                  exportOptions={{
                    doc: t('exportdoc_key'),
                    pdf: t('exportpdf.key'),
                  }}
                  exportAction={handleExportButton}
                  fileName="history"
                  languageTranslate={state.uiState.detail.languageTranslate}
                  recaptchaResponseKey={state.uiState.history.recaptchaResponseKey}
                  resetRecaptchaResponseKey={resetRecaptchaResponseKeyExport}
                  items={[props.match.params.id]}
                  isExpanded={isExpanded}
                />

                <Button
                  ghost
                  isExpanded={isExpanded}
                  title={t('results_share_by_email_key')}
                  data-test-id="share-email-button"
                  icon="shareEmail"
                  onClick={() => createLink(props.match.url)}
                  data-tip
                  data-for="email-button-tooltip"
                />
                <div className="navigation">
                  {props.navigationData && !fromSeniority && <TMNavigation {...props} />}
                  <Tooltip id="email-button-tooltip" place="bottom">
                    <strong>{t('results_share_by_email_key')}</strong>
                  </Tooltip>
                </div>
              </Flex>
            </Flex>

            <ShareSearch
              reCaptchaSiteKey={state.globals.data.reCaptcha.reCaptchaSiteKey}
              backEndErrorMessage={state.uiState.shareSearch.backEndErrorMessage}
              successShared={state.uiState.shareSearch.successShared}
              onChangeEmailField={changeEmailField}
              literals={state.literals.data}
              showModalShareByEmail={state.uiState.shareSearch.showModalShareByEmail}
              sendEmail={sendemail}
              shareSearchFieldsValues={state.uiState.shareSearch.formState.fields}
              setShareEmailNotification={shareEmailNotification}
              validateEntireForm={validateForm}
              errorsShareLink={state.uiState.shareSearch.formState.errors}
              clearShareSearchForm={clearShareForm}
              closeModalShareByEmail={closeEmailModal}
            />
          </StyledDetailActionBar>
        )}
      </ActionBarExpandable>
    </Sticky>
  )
}

export default ActionBarHistory
