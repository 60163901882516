import { lensProp, over, append, without } from 'ramda'

import { SEARCH_TYPES } from '@app/redux-types'

const lensFav = lensProp('favorites')
const defaultState = {
  favorites: [],
}

export default (state = defaultState, { type, meta, payload }) => {
  switch (type) {
    case SEARCH_TYPES.ADD_FAVORITE_FIELD_FULFILLED:
      return over(lensFav, append(meta), state)
    case SEARCH_TYPES.REMOVE_FAVORITE_FIELD_FULFILLED:
      return over(lensFav, without([meta]), state)
    case SEARCH_TYPES.SET_DEFAULT_FAVORITES:
      return { ...state, favorites: payload.favorites }
    case SEARCH_TYPES.SET_FAVORITES_FROM_COOKIE:
      return { ...state, favorites: payload.favorites }
    default:
      return state
  }
}
