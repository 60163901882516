export const prepareSegment = (segment, scale) => {
  const { right, left, lower, upper, id } = segment
  const width = (right - left) * scale
  const height = (lower - upper) * scale
  const x = left * scale
  const y = upper * scale
  return {
    width,
    height,
    x,
    y,
    id,
  }
}

export const transformSelectionToSegment = (selection, scale) => {
  const { x, y, width, height } = selection
  const left = x / scale
  const upper = y / scale
  const right = width / scale + left
  const lower = height / scale + upper
  return {
    right,
    left,
    lower,
    upper,
  }
}

export const transformSegmentToSelection = (segments, scale) => {
  if (segments) {
    const { right, left, lower, upper, segmentSelectedId } = segments
    const x = left * scale
    const y = upper * scale
    const width = (right - left) * scale
    const height = (lower - upper) * scale
    return {
      x,
      y,
      width,
      height,
      segmentSelectedId,
    }
  } else {
    return {}
  }
}

export const getArea = segment => {
  return (segment.right - segment.left) * (segment.lower - segment.upper)
}

export const segmentCompare = (a, b) => {
  const areaA = getArea(a)
  const areaB = getArea(b)
  if (areaA > areaB) return -1
  if (areaA < areaB) return 1
  return 0
}
export const orderSegments = segments => {
  return segments.sort(segmentCompare)
}

export const onCreateSelectionEnd = (
  updatedSelectionRectangle,
  handleUpdateSelection,
  selectionRectangle
) => {
  handleUpdateSelection(updatedSelectionRectangle, selectionRectangle)
}
