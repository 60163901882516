import * as React from 'react'

import Spinner from './Spinner'
import { BlockSpinner } from './styles'

export default ({ children, className, ...props }) =>
  children ? (
    <BlockSpinner className={className}>
      <Spinner block {...props} />
      {children}
    </BlockSpinner>
  ) : (
    <Spinner {...props} />
  )
