import React from 'react'
import TMForm from '@app/components/TMForm'
import { t } from '@app/common/translationsService'
import { CheckboxWithInput } from '../CheckboxWithInput'
import { FIELDS_DSVIEW } from '../../constants'

import { Block } from '@new-lucentum'

export const AlertFormDsview = ({ values, handleWarningDateValidation, resourceData, isBulk }) => {
  /* const isExpiryDateDisabled =
    (resourceData && resourceData.expiryDate) || (isBulk || !resourceData) ? false : true
  const isEndOfDeferDateDisabled =
    (resourceData && resourceData.endOfDefermentDate) || (isBulk || !resourceData) ? false : true */

  return (
    <div>
      <Block>
        <CheckboxWithInput
          isDisabled={false}
          field={FIELDS_DSVIEW.EXPIRATION_DATE_CLOSE}
          inputField={FIELDS_DSVIEW.EXPIRATION_DATE_DAYS}
          values={values}
          label={t('alerts_events_expiration_date_close_to_end')}
          onChangeInput={value => {
            handleWarningDateValidation(
              'expiryDate',
              FIELDS_DSVIEW.EXPIRATION_DATE_DAYS,
              value,
              t('alerts_dropdown_warning_days_higher_left', {
                eventName: t('alerts_events_expiration_date_close_to_end'),
              })
            )
          }}
        />
      </Block>
      <Block>
        <CheckboxWithInput
          isDisabled={false}
          field={FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CLOSE}
          inputField={FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_DAYS}
          values={values}
          label={t('alerts_events_end_deferment_date_close_to_end')}
          onChangeInput={value => {
            handleWarningDateValidation(
              'expiryDate',
              FIELDS_DSVIEW.EXPIRATION_DATE_DAYS,
              value,
              t('alerts_dropdown_warning_days_higher_left', {
                eventName: t('alerts_events_end_deferment_date_close_to_end'),
              })
            )
          }}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_DSVIEW.OWNER_NAME_CHANGE}
          label={t('alerts_events_owner_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_DSVIEW.STATUS_CHANGE}
          label={t('alerts_events_status_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_DSVIEW.EXPIRY_DATE_CHANGE}
          label={t('alerts_events_expiry_date_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_DSVIEW.REPRESENTATIVE_CHANGE}
          label={t('alerts_events_representative_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_DSVIEW.END_OF_DEFERMENT_DATE_CHANGE}
          label={t('alerts_events_end_deferment_date_change')}
        />
      </Block>
      <Block>
        <TMForm.Checkbox
          name={FIELDS_DSVIEW.LOCARNO_CLASS_CHANGE}
          label={t('alerts_events_locarno_class_change')}
        />
      </Block>
    </div>
  )
}
