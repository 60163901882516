import { Field, Form } from '@app/common/formManagement'
import {
  setFieldValues,
  validationMessage,
  setInvalidFieldKey,
  validateEntireForm,
  validateIndividualState,
} from '@app/common/utilities'
import { AUTH_TYPES } from '@app/redux-types'

const defaultErrors = {
  email: { isValid: true, errorMessage: '' },
  password: { isValid: true, errorMessage: '' },
  recaptchaResponseField: { isValid: true, errorMessage: '' },
}

const defaultForm = {
  email: '',
  password: '',
  recaptchaResponseField: null,
}

const initForm = () =>
  new Form()
    .addField(new Field('email', null, defaultForm.email))
    .addField(new Field('password', null, defaultForm.password))
    .addField(new Field('recaptchaResponseField', null, defaultForm.recaptchaResponseField))

const form = initForm()

const defaultState = {
  formState: {
    form,
    backEndErrorMessage: {
      error: false,
      errorMessage: '',
      errorDetails: [],
    },
    successMessage: false,
    fields: {
      ...defaultForm,
    },
    isValid: true,
    errors: {
      ...defaultErrors,
    },
  },
}

const validateRegister = (state, payload) => {
  if (!payload.registered) {
    return {
      ...state,
      backEndErrorMessage: {
        error: true,
        errorMessage: payload.message,
        errorDetails: '',
      },
    }
  }
  return {
    ...state,
    successMessage: true,
  }
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'EMPTY_REGISTER_FIELDS':
      return defaultState
    case AUTH_TYPES.FETCH_REGISTER_FULFILLED:
      return validateRegister(state, payload)
    case AUTH_TYPES.FETCH_REGISTER_REJECTED:
      return validationMessage(state, payload)
    case 'SET_INVALID_REGISTER_FIELD':
      return validateEntireForm(setInvalidFieldKey(state, payload.fieldKey, payload.errorMessage))
    case 'CHANGE_REGISTER_FIELD':
      return validateEntireForm(
        validateIndividualState(setFieldValues(payload, state), payload.field)
      )
    case 'CLOSE_REGISTER_BE_ERROR':
      return {
        ...state,
        backEndErrorMessage: defaultState.backEndErrorMessage,
      }
    case 'CLOSE_REGISTER_SUCCESS_MESSAGE':
      return {
        ...state,
        successMessage: false,
      }
    default:
      return state
  }
}
