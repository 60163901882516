import React from 'react'

import { Label, ListButton, ListRanges } from './styles'
import parseInput from './utils/parseInput.js'

const PredefinedRanges = ({ ...props }) => {
  const { periodLabel } = props
  const handleSelect = (name, e) => {
    e.preventDefault()

    const range = props.ranges[name]

    props.onSelect(
      {
        startDate: parseInput(range['startDate'], null, 'startOf'),
        endDate: parseInput(range['endDate'], null, 'endOf'),
      },
      PredefinedRanges
    )
  }

  const renderRangeList = () => {
    const { ranges, range } = props

    return Object.keys(ranges).map(name => {
      const active =
        parseInput(ranges[name].startDate, null, 'startOf').isSame(range.startDate) &&
        parseInput(ranges[name].endDate, null, 'endOf').isSame(range.endDate)

      return (
        <ListButton
          active={active}
          borderless
          key={'range-' + name}
          onClick={e => handleSelect(name, e)}
        >
          {name}
        </ListButton>
      )
    })
  }

  return (
    <ListRanges>
      <Label>{periodLabel}</Label>
      {renderRangeList()}
    </ListRanges>
  )
}

export default PredefinedRanges
