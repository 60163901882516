import React from 'react'
import { Group, Image, Layer, Stage } from 'react-konva'

import { useSelectionRectangle } from '../../hooks'
import { Rectangle } from '../Rectangle'
import {
  onCreateSelectionEnd,
  transformSegmentToSelection,
  transformSelectionToSegment,
} from './AreaSelection.utils.js'

const AreaSelectionComponent = ({ image, parsedInfo, onUpdateSelection, initialSelection }) => {
  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    selectionRectangle,
    updateSelectionRectangle,
  } = useSelectionRectangle(updatedSelectionRectangle => {
    onCreateSelectionEnd(updatedSelectionRectangle, handleUpdateSelection, selectionRectangle)
  }, transformSegmentToSelection(initialSelection, parsedInfo.scale))

  const handleUpdateSelection = React.useCallback(
    selection => {
      if (selection) {
        const parsedSelection = { ...selectionRectangle, ...selection }
        const segment = transformSelectionToSegment(parsedSelection, parsedInfo.scale)
        onUpdateSelection(segment)
      }
    },
    [onUpdateSelection, selectionRectangle, parsedInfo]
  )

  const onTransformChange = React.useCallback(
    nextValues => {
      updateSelectionRectangle(nextValues)
      handleUpdateSelection(nextValues)
    },
    [updateSelectionRectangle, handleUpdateSelection]
  )

  const stageProps = {
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
    className: 'area-selection',
  }

  return (
    <React.Fragment>
      {parsedInfo.width && (
        <Stage width={parsedInfo.width} height={parsedInfo.height} {...stageProps}>
          <Layer>
            <Image image={image} width={parsedInfo.width} height={parsedInfo.height} fill="white" />
          </Layer>
          {/* Area selection */}
          <Layer>
            {selectionRectangle.visible && (
              <Group width={parsedInfo.width} height={parsedInfo.height}>
                <Rectangle
                  shapeProps={{
                    width: parsedInfo.width,
                    height: parsedInfo.height,
                    fill: 'rgba(0, 0, 0, 0.3)',
                    id: 'selection-backdrop',
                    draggable: false,
                  }}
                />
                <Rectangle
                  shapeProps={{
                    ...selectionRectangle,
                    fill: 'white',
                    stroke: 'red',
                    dash: [2, 2],
                    globalCompositeOperation: 'destination-out',
                    id: 'selection',
                  }}
                  onChange={onTransformChange}
                  showTransform
                />
              </Group>
            )}
          </Layer>
        </Stage>
      )}
    </React.Fragment>
  )
}

export const AreaSelection = AreaSelectionComponent
