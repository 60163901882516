import React from 'react'
import { t } from '@app/common/translationsService'
import { DSV_APPLICATION_CONTEXT } from '@app/common/constants'
import { Link } from '@new-lucentum'

export default ({ ST13, setNavigation }) => (
  <Link
    onClick={e =>
      setNavigation(e, ST13, {
        sectionId: 'boxIndicationOfProduct',
      })
    }
    /* href={`#/${DSV_APPLICATION_CONTEXT}/detail/${encodeURIComponent(ST13)}?q=indicationOfProduct`} */
  >
    {t('results_details_show_more')}
  </Link>
)
