import { fullfilled, pending, rejected } from './utils/reduxPromise'

const FETCH_HISTORY = 'FETCH_HISTORY'

export const HISTORY_TYPES = {
  FETCH_HISTORY,
  FETCH_HISTORY_FULFILLED: fullfilled(FETCH_HISTORY),
  FETCH_HISTORY_PENDING: pending(FETCH_HISTORY),
  FETCH_HISTORY_REJECTED: rejected(FETCH_HISTORY),
}
