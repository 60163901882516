import styled from 'styled-components'

export const ErrorMessage = styled.div`
  background-color: ${({ theme }) => theme.colorErrorAccent};
  border-radius: ${({ theme }) => theme.radius};
  color: ${({ theme }) => theme.color};
  font-size: ${({ theme }) => theme.fontSize};
  left: 100%;
  margin-left: ${({ theme }) => theme.size};
  min-width: calc(${({ theme }) => theme.size} * 10);
  padding: ${({ theme }) => theme.size};
  position: absolute;
  z-index: 9999;

  &:after {
    border-bottom: 10px solid ${({ theme }) => theme.colorTransparent};
    border-right: solid 6px ${({ theme }) => theme.colorErrorAccent};
    border-top: 10px solid ${({ theme }) => theme.colorTransparent};
    content: '';
    left: -5px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    z-index: 99;
  }
`
