let _context = null
const TMVIEW = 'tmview'
const DSVIEW = 'dsview'
const validContext = [TMVIEW, DSVIEW]
const applicationIds = {
  [TMVIEW]: 1,
  [DSVIEW]: 2,
}
export const set = context => {
  if (validContext.includes(context) && context !== _context) {
    _context = context
  } else {
    _context = TMVIEW
  }
  return true
}

export const get = () => _context || TMVIEW
export const getApplicationId = () => applicationIds[get()]

export const isTmview = () => _context === TMVIEW
export const isDsview = () => _context === DSVIEW

export default {
  set,
  get,
  getApplicationId,
  isDsview,
  isTmview,
}
