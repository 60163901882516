const spacingProps = [
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
]

const buildPropsCss = (props, key) => {
  const [name, adj] = key.split(/(?=[A-Z])/)
  return !!adj
    ? `${name}-${adj.toLowerCase()}:${props[key] * props.theme.sizeBase}px;`
    : `${name}:${props[key] * props.theme.sizeBase}px;`
}

export const addSpacing = props => {
  return Object.keys(props).map(name => spacingProps.includes(name) && buildPropsCss(props, name))
}
