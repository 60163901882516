import camel from 'lodash.camelcase'
import React from 'react'

import Button from '../Button'
import Icon from '../Icon'
import { Error, Textfield, Wrapper } from './styles'

export default ({
  children,
  errorMessage,
  icon,
  onClear,
  showClear = false,
  showError = false,
  type = 'text',
  value,
  getInstance,
  ...props
}) => (
  <Textfield>
    <input
      defaultValue={value || children}
      type={type}
      ref={input => {
        getInstance && getInstance(input)
      }}
      value={value}
      {...props}
    />
    {(icon || onClear) && (
      <Wrapper showClear={showClear} middle center>
        {onClear && <Button className="clear-button" onClick={onClear} borderless icon="close" />}
        {icon && <Icon {...{ [camel(icon)]: true }} />}
      </Wrapper>
    )}
    {errorMessage && showError && <Error>{errorMessage}</Error>}
  </Textfield>
)
