import React from 'react'
import yeast from 'yeast'

import { Block, Tooltip, Fieldset } from '@new-lucentum'

import Box, { BoxCell } from '../Box'

import { P, Legend, Span, Link } from './styles'

export default ({
  className,
  detailKey = 'detail',
  handlerClick,
  hrefKey = 'href',
  src,
  textKey = 'text',
  titleKey = 'title',
  tooltipKey = 'tooltip',
  splitText = true,
  ...props
}) => (
  <Box {...props}>
    {src.map(n => {
      const idNews = yeast()
      return (
        <BoxCell
          paddingLeft={3}
          paddingRight={3}
          paddingTop={2}
          paddingBottom={2}
          border
          key={`id-${n.id}-${idNews}`}
        >
          <Link href={n[hrefKey]} onClick={e => handlerClick && handlerClick(e, n.id)}>
            <Legend splitText={splitText}>{n[detailKey]}</Legend>

            {n[titleKey] && <Span bold>{n[titleKey]}</Span>}

            <Block data-for={`id-${n.id}-${idNews}`} data-tip>
              <P>{n[textKey]}</P>
              {n[tooltipKey] && (
                <Tooltip id={`id-${n.id}-${idNews}`}>
                  {Object.keys(n[tooltipKey]).map(t => (
                    <Fieldset key={t} label={t}>
                      <P>{n[tooltipKey][t]}</P>
                    </Fieldset>
                  ))}
                </Tooltip>
              )}
            </Block>
          </Link>
        </BoxCell>
      )
    })}
  </Box>
)
