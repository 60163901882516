import { doSearchBySt13s } from '@app/actions/alerts'
import { userprefsDataManager } from '@app/common/dataManagers'
import store from '@app/store'

import { prepareTagsSearchQuery } from './tmdsTagsService.utils'

const { dispatch } = store

const getAppliedTagGroups = (st13s = []) => {
  return userprefsDataManager.getResourceTagGroups(st13s)
}

const getAvailableTagGroups = () => {
  return userprefsDataManager.getTagGroups()
}

const setTags = (tags, st13List, isBulk = false) => {
  const payload = {
    st13List,
    tags,
    isBulk,
  }
  return userprefsDataManager.setTags(payload)
}

const getTags = ids => {
  return userprefsDataManager.getTags(ids)
}

const doTagsSearch = (st13List, filterParams, columns) => {
  const { queryString, queryObject } = prepareTagsSearchQuery(st13List, filterParams)
  return dispatch(doSearchBySt13s(queryString, { ...queryObject, fields: columns }))
}

const removeTagEvent = (st13, eventKey, existingTags) => {
  let tags = existingTags.tags.filter(tag => {
    if (tag.id !== eventKey.id) {
      return tag
    }
  })

  const st13List = [st13]
  const payload = {
    st13List,
    tags,
    isBulk: false,
  }
  return userprefsDataManager.setTags(payload)
}

const removeTagGroup = id => {
  return userprefsDataManager.removeTagGroup(id)
}

const editTagGroup = (id, tagName) => {
  return userprefsDataManager.editTagGroup(id, tagName)
}

export const TMDSTagsService = {
  getAppliedTagGroups,
  getAvailableTagGroups,
  setTags,
  getTags,
  doTagsSearch,
  removeTagEvent,
  removeTagGroup,
  editTagGroup,
}
