import styled, { css } from 'styled-components'

const sliderThumbSize = 16
const sliderThumbBorderThickness = 2
const sliderLineThickness = 4
const transitionDuration = '0.2s'
const sliderBorderRadius = sliderLineThickness / 2
export const overallHeight = 40

export const thumb = {
  default: {
    background: '#ffffff',
    // This border color is not being used - awaiting focus state lift to props
    border: '#172b4d',
  },
  disabled: {
    boxShadow: '#B3BAC5',
  },
  focus: {
    // This border color is not being used - awaiting focus state lift to props
    background: '#ffffff',
    border: '#2684ff',
  },
}

export const track = {
  background: '#EBECF0',
  default: {
    lower: '#0052CC',
    upper: '#DFE1E6',
  },
  disabled: {
    lower: '#0052CC',
    upper: '#EBECF0',
  },
  hover: {
    lower: '#0065FF',
    upper: '#DFE1E6',
  },
}

const getBackgroundGradient = ({ lower, upper }) =>
  css`
    background: linear-gradient(${lower}, ${lower}) 0 / var(--range-inline-width) 100% no-repeat
      ${upper};
    [dir='rtl'] & {
      background-position: right;
    }
  `

// Thumb style
const sliderThumbStyle = css`
  background: ${thumb.default.background};
  border: ${sliderThumbBorderThickness}px solid transparent;
  border-radius: 50%;
  height: ${sliderThumbSize}px;
  width: ${sliderThumbSize}px;
  box-sizing: border-box;
  transition: border-color ${transitionDuration} ease-in-out;
`

// Track styles
const sliderTrackStyle = css`
  border-radius: ${sliderBorderRadius}px;
  border: 0;
  cursor: pointer;
  height: ${sliderLineThickness}px;
  width: 100%;
  transition: background-color ${transitionDuration} ease-in-out;
`

// Range input styles
const chromeRangeInputStyle = css`
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -${(sliderThumbSize - sliderLineThickness) / 2}px;
    ${sliderThumbStyle};
  }

  &:focus::-webkit-slider-thumb {
    border-color: ${thumb.focus.border};
  }

  &:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
    box-shadow: 0 0 1px ${thumb.disabled.boxShadow};
  }

  &::-webkit-slider-runnable-track {
    ${sliderTrackStyle};
    ${getBackgroundGradient(track.default)};
  }

  &:focus::-webkit-slider-runnable-track {
    ${getBackgroundGradient(track.default)};
  }

  &:active::-webkit-slider-runnable-track,
  &:hover::-webkit-slider-runnable-track {
    ${getBackgroundGradient(track.hover)};
  }

  &:disabled::-webkit-slider-runnable-track {
    ${getBackgroundGradient(track.disabled)}
    cursor: not-allowed;
  }
`

const firefoxRangeInputStyle = css`
  &::-moz-focus-outer {
    border: 0;
  }

  &::-moz-range-thumb {
    ${sliderThumbStyle};
  }

  &:focus::-moz-range-thumb {
    border-color: ${thumb.focus.border};
  }

  &:disabled::-moz-range-thumb {
    cursor: not-allowed;
    box-shadow: 0 0 1px ${thumb.disabled.boxShadow};
  }

  &::-moz-range-progress {
    ${sliderTrackStyle};
    background: ${track.default.lower};
  }

  &::-moz-range-track {
    ${sliderTrackStyle};
    background: ${track.default.upper};
  }

  &:active::-moz-range-progress,
  &:hover::-moz-range-progress {
    background: ${track.hover.lower};
  }

  &:active::-moz-range-track,
  &:hover::-moz-range-track {
    background: ${track.hover.upper};
  }

  &:disabled::-moz-range-progress {
    background: ${track.disabled.lower};
    cursor: not-allowed;
  }

  &:disabled::-moz-range-track {
    background: ${track.disabled.upper};
    cursor: not-allowed;
  }
`

const IERangeInputStyle = css`
  &::-ms-thumb {
    margin-top: 0;
    ${sliderThumbStyle};
  }

  &:focus::-ms-thumb {
    border-color: ${thumb.focus.border};
  }

  &:disabled::-ms-thumb {
    cursor: not-allowed;
    box-shadow: 0 0 1px ${thumb.disabled.boxShadow};
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
    height: ${sliderLineThickness}px;
    transition: background-color ${transitionDuration} ease-in-out;
    width: 100%;
  }

  &::-ms-fill-lower {
    background: ${track.default.lower};
    border-radius: ${sliderBorderRadius}px;
    border: 0;
  }

  &::-ms-fill-upper {
    background: ${track.default.upper};
    border-radius: ${sliderBorderRadius}px;
    border: 0;
  }

  &:active::-ms-fill-lower,
  &:hover::-ms-fill-lower {
    background: ${track.hover.lower};
  }

  &:active::-ms-fill-upper,
  &:hover::-ms-fill-upper {
    background: ${track.hover.upper};
  }

  &:disabled::-ms-fill-lower {
    background: ${track.disabled.lower};
    cursor: not-allowed;
  }

  &:disabled::-ms-fill-upper {
    background: ${track.disabled.upper};
    cursor: not-allowed;
  }
`

export const Input = styled.input`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
  height: ${overallHeight}px; /* Otherwise thumb will collide with previous box element */
  padding: 0; /* IE11 includes padding, this normalises it */
  width: 100%; /* Specific width is required for Firefox. */

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${chromeRangeInputStyle};
  ${firefoxRangeInputStyle};
  ${IERangeInputStyle};

  background-position: right;
`
