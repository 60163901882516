import React from 'react'

export function useHover() {
  const [hovered, set] = React.useState(false)
  return [
    hovered,
    {
      onMouseEnter: () => set(true),
      onMouseLeave: () => set(false),
    },
  ]
}
