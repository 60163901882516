import '@brainhubeu/react-carousel/lib/style.css'

import { TMDSGlobalsService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { SearchByImageButton } from '@app/components/SearchByImageButton'
import Carousel, { Dots } from '@brainhubeu/react-carousel'
import React from 'react'
import { withState } from 'recompose'

import Image from '../Image'
import Modal from '../Modal'
import { ImageSearchContainer, StyledArrow } from './styles'

const enhance = withState('index', 'setIndex', 0)

export default enhance(({ imageUrls, index, setIndex, onCloseImageCarrousel }) => {
  const Arrow = ({ chevron }) => {
    const chevronIcon = chevron === 'left' ? { chevronLeft: true } : { chevronRight: true }
    const newIndex = chevron === 'left' ? index - 1 : index + 1
    const modifiedIndex =
      newIndex < 0 ? imageUrls.length - 1 : newIndex >= imageUrls.length ? 0 : newIndex
    return <StyledArrow {...chevronIcon} onClick={() => setIndex(modifiedIndex)} />
  }
  const slides = imageUrls.map(image => (
    <img style={{ maxHeight: '400px', width: 'auto', maxWidth: '700px' }} src={image} alt="" />
  ))
  const thumbnails = imageUrls.map(image => <Image small src={image} />)
  const { regionalEnabled } = TMDSGlobalsService.getRegionalConfig()

  return (
    <Modal
      noTitle
      contentStyle={{ border: 'none', borderRadius: '10px' }}
      isOpen={true}
      modalSize="large"
      ariaHideApp={false}
      onRequestClose={() => onCloseImageCarrousel()}
    >
      <Carousel
        infinite
        value={index}
        slides={slides}
        onChange={setIndex}
        arrowLeft={imageUrls.length > 1 && <Arrow chevron="left" />}
        arrowRight={imageUrls.length > 1 && <Arrow chevron="right" />}
      />
      {thumbnails.length > 1 && (
        <Dots
          number={thumbnails.length}
          thumbnails={thumbnails}
          value={index}
          onChange={setIndex}
        />
      )}
      <ImageSearchContainer>
        {!regionalEnabled && imageUrls.length && (
          <SearchByImageButton
            enable={imageUrls.length}
            title={t('search.similar.designs.title')}
            content={t('search.similar.designs.content')}
            yes={t('yes.key')}
            close={t('close.key')}
            ST13={imageUrls[index].split('/').pop()}
          />
        )}
      </ImageSearchContainer>
    </Modal>
  )
})
