const defaultState = {
  showAllSearches: false,
  showAllNews: false,
}

export default (state = defaultState, { type }) => {
  switch (type) {
    case 'TOGGLE_ALL_SEARCHES':
      return {
        ...state,
        showAllSearches: !state.showAllSearches,
      }
    case 'TOGGLE_ALL_NEWS':
      return {
        ...state,
        showAllNews: !state.showAllNews,
      }
    default:
      return state
  }
}
