import styled from 'styled-components'

import line from './line.svg'

import Block from '../Block'
import Button from '../Button'
import { Span } from '../Text'
import { Textfield } from '../TextField/styles'

export const Wrapper = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  & > div:first-child {
    align-items: center;
    display: flex;
    justify-content: space-between;
    & > legend {
      word-break: break-word;
      & > span {
        color: ${({ theme }) => theme.colorGrayLight};
      }
    }
    & > i {
      visibility: hidden;
    }
  }
  &:focus-within > div > legend > span {
    color: ${({ theme }) => theme.colorGray};
  }
  &:hover > div > i {
    visibility: visible;
  }
  & > div:last-child {
    margin-top: ${({ theme }) => theme.sizeSmall};
    form & {
      display: flex;
      flex-wrap: wrap;
    }
    & > ${Textfield} {
      margin-bottom: ${({ theme }) => theme.sizeSmall};
    }
  }
  & > div:last-child > & {
    padding-left: 20px;
    width: calc(100% - 20px);
  }
  & > div:last-child > &:first-of-type {
    background-image: url(${line});
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 20px;
    position: relative;
  }
`

export const ButtonPin = styled(Button)`
  padding: ${({ theme }) => `0 ${theme.size} 0 0`};
  color: ${({ theme, favorite }) => (favorite ? theme.color : theme.colorGrayLight)};
  &:hover {
    color: ${({ theme }) => theme.color};
  }
  ${({ favorite }) =>
    favorite &&
    `i {
      display: block;
      transform: scaleX(-1);
      }
    `};
`
export const Asterisk = styled.span`
  &::before {
    content: '*';
    color: ${({ theme }) => theme.colorError};
    display: inline;
  }
`
const commonWarningError = `
font-size: ${({ theme }) => theme.fontSizeSmaller};
left: 0;
position: absolute;
top: 100%;

`
export const Error = styled(Span)`
  ${commonWarningError};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  color: ${({ theme }) => theme.colorError};
`

export const Warning = styled(Span)`
  ${commonWarningError};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  color: ${({ theme }) => theme.colorWarning};
`

export const InputBlock = styled(Block)`
  ${({ theme }) => theme.baseText}
`

export const Disclaimer = styled(Span)`
  background-color: ${({ theme }) => theme.colorWhite};
  color: ${({ theme }) => theme.colorGrayLight};
  font-size: ${({ theme }) => theme.fontSizeSmaller};
  left: 0;
  top: 100%;
  display: ${({ isVisible }) => (isVisible ? '' : 'none')};
`
