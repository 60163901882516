import { SHARED_FIELDS } from '../shared'

export class PERSON {
  static IDENTIFIER = 'identifier'
  static FULL_NAME = SHARED_FIELDS.FULL_NAME
  static ORGANIZATION_NAME = SHARED_FIELDS.ORGANIZATION_NAME
  static LEGAL_FORM = SHARED_FIELDS.LEGAL_FORM
  static LEGAL_ENTITY = SHARED_FIELDS.LEGAL_ENTITY
  static LEGAL_ENTITY_TRANSLATED = SHARED_FIELDS.LEGAL_ENTITY_TRANSLATED
  static NATIONALITY_CODE = 'nationalityCode'
  static INCORPORATION_COUNTRY_CODE = 'incorporationCountryCode'
  static INCORPORATION_STATE = 'incorporationState'
  static FULL_ADDRESS = SHARED_FIELDS.FULL_ADDRESS
  static ADDRESS_DETAILS = SHARED_FIELDS.ADDRESS_DETAILS
  static PHONE = 'phone'
  static FAX = 'fax'
  static EMAIL = 'email'
  static URL = 'url'
  static OTHER_ELECTRONIC_ADDRESS = 'otherElectronicAddress'
}
