import React from 'react'
import ContentLoader from 'react-content-loader'
import { BlockSearchInfo } from './styles'

export default ({ height = 40, width = 500 }) => {
  const centeredY = height / 2
  return (
    <BlockSearchInfo height={height}>
      <ContentLoader
        height={height}
        width={width}
        speed={1}
        primaryColor="#d4d4d4"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y={centeredY} rx="5" ry="5" width="50" height="10" />
        <rect x="60" y={centeredY} rx="5" ry="5" width="150" height="10" />

        <rect x="240" y={centeredY} rx="5" ry="5" width="50" height="10" />
        <rect x="300" y={centeredY} rx="5" ry="5" width="150" height="10" />
      </ContentLoader>
    </BlockSearchInfo>
  )
}
