import { generalDataManager } from '@app/common/dataManagers'
import { SHARE_SEARCH_TYPES } from '@app/redux-types'

export const toggleModalShareByEmail = () => ({
  type: 'TOGGLE_MODAL_SHARE_BY_EMAIL',
})

export const closeModalShareByEmail = () => ({
  type: 'CLOSE_MODAL_SHARE_BY_EMAIL',
})

export const onChangeEmailField = (field, value) => ({
  type: 'CHANGE_EMAIL_FIELD',
  payload: {
    field,
    value,
  },
})

export const sendEmail = fieldValues => {
  return {
    type: SHARE_SEARCH_TYPES.SEND_EMAIL,
    payload: generalDataManager.sendEmail(fieldValues),
    meta: { errors: [500] },
  }
}

export const setShareEmailNotification = message => {
  return {
    type: 'SET_SHARE_EMAIL_NOTIFICATION',
    payload: { message },
  }
}

export const createShortLink = searchQuery => dispatch =>
  dispatch({
    type: 'FETCH_SHORT_LINK',
    payload: generalDataManager.doShortLink(searchQuery),
  }).then(({ value }) => {
    return dispatch(toggleModalShareByEmail())
  })

export const validateEntireForm = () => ({
  type: 'VALIDATE_ENTIRE_FORM',
})

export const clearShareSearchForm = () => ({
  type: 'CLEAR_FORM',
})
