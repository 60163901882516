import { DESIGNER_FIELDS } from '@app/common/dataManagers/types'
export const designerFields = [
  {
    label: 'designeridentifier.key',
    path: DESIGNER_FIELDS.IDENTIFIER,
  },
  {
    label: 'designer.name.key',
    path: DESIGNER_FIELDS.ADDRESS,
  },
]
