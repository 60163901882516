import { useOnClickOutside } from '@app/common/hooks'
import React from 'react'

const areNearPoints = (pointA, pointB) => {
  const { x: xA, y: yA } = pointA
  const { x: xB, y: yB } = pointB
  const threshold = 6

  const dX = Math.abs(xA - xB)
  const dY = Math.abs(yA - yB)
  return dX <= threshold && dY <= threshold
}

const getFlattenedPoints = points => {
  const flattenedPoints = points.reduce((acc, current) => acc.concat(...[current.x, current.y]), [])
  return flattenedPoints
}

export function useSelectionFree(onFreeSelectionEnd, initialSelection) {
  const [isClosed, setIsClosed] = React.useState(false)
  const [points, setPoints] = React.useState([])
  const [flattenedPoints, setFlattenedPoints] = React.useState([])
  const ref = React.useRef()

  React.useEffect(() => {
    if (initialSelection && initialSelection.length) {
      const _flattenedPoints = getFlattenedPoints(initialSelection)
      setPoints(initialSelection)
      setFlattenedPoints([..._flattenedPoints])
      setIsClosed(true)
    }
  }, [])

  const resetSelection = React.useCallback(
    (e = null) => {
      setPoints([])
      setFlattenedPoints([])
      setIsClosed(false)
      onFreeSelectionEnd([])
      if (e) {
        // Start new polygon
        handleMouseDown(e)
      }
    },
    [onFreeSelectionEnd]
  )

  const closePolygon = React.useCallback(
    () => {
      const _flattenedPoints = getFlattenedPoints(points)
      setFlattenedPoints([..._flattenedPoints])
      setIsClosed(true)
      onFreeSelectionEnd(points)
    },
    [points, onFreeSelectionEnd]
  )

  const handleClickOutside = React.useCallback(
    () => {
      if (!isClosed && points.length > 0) {
        if (points.length >= 3) {
          closePolygon()
        } else {
          resetSelection()
        }
      }
    },
    [points, isClosed, closePolygon, resetSelection]
  )

  useOnClickOutside(ref, handleClickOutside)

  const handleMouseDown = React.useCallback(
    e => {
      const isBackdrop = e.target.attrs.id === 'selection-backdrop'
      if (isClosed) {
        resetSelection(e)
        return
      }

      const clickPoint = { x: e.evt.layerX, y: e.evt.layerY }

      const startPoint = points[0] || {}
      const isStartPoint = startPoint.x && areNearPoints(clickPoint, startPoint)

      if (isStartPoint && points.length >= 3) {
        closePolygon()
      } else {
        setPoints([...points, clickPoint])
      }
    },
    [points, isClosed]
  )

  const handleMouseMove = React.useCallback(
    e => {
      if (!isClosed) {
        const hoverPoint = { x: e.evt.layerX, y: e.evt.layerY }
        const _flattenedPoints = getFlattenedPoints(points)
        setFlattenedPoints([..._flattenedPoints, hoverPoint.x, hoverPoint.y])
      }
    },
    [setFlattenedPoints, points, isClosed]
  )

  return {
    handleMouseDown,
    handleMouseMove,
    points,
    isClosed,
    flattenedPoints,
    areaRef: ref,
  }
}
