import styled from 'styled-components'

export const StyledDropdownMenu = styled.div`
  display: flex;
  background: white;
  margin-top: 5px;
  padding: ${({ noPadding }) => (noPadding ? 0 : '10px')};
  box-shadow: 1px 1px 2px 0px #939497;
  border-radius: 7px;
  margin-top: 7px;
  border-color: #939497;
  z-index: 9999;
  ${({ theme }) => theme.scroll(theme)};
`
