import { userprefsDataManager } from '@app/common/dataManagers'
import { SETTINGS_TYPES } from '@app/redux-types'

export const getSettings = () => ({
  type: SETTINGS_TYPES.GET_SETTINGS,
  payload: userprefsDataManager.getUserSettings(),
})

export const editSettings = (patch, isEmail) => ({
  type: SETTINGS_TYPES.EDIT_SETTINGS,
  payload: userprefsDataManager.editUserSettings(patch, isEmail),
})
