import TMDSContextService from '@app/common/tmdsContextService'
import TMDSRouterService from '@app/common/tmdsRouterService'

import {
  COMPARE_ACTIONS,
  CONFIGURE_SEARCH_ACTIONS,
  CONFIGURE_SEARCH_NAMES,
  DETAIL_EVENT_NAMES,
  EURID_LINK_NAMES,
  EVENT_CATEGORIES,
  FIELD_NAMES,
  GLOBAL_BRAND_NAMES,
  HELP_CLICK_ACTIONS,
  HELP_CLICK_NAMES,
  HISTORY_ACTIONS,
  ORIG_LANG_OR_TRANS_CLICK_ACTIONS,
  ORIG_LANG_OR_TRANS_CLICK_NAMES,
  REGISTER_CLICK_ACTIONS,
  REGISTER_CLICK_NAMES,
  RESULTS_EVENT_NAMES,
  RESULTS_PAGINATION_CLICK_ACTIONS,
  RESULTS_PAGINATION_CLICK_NAMES,
  RESULTS_VIEW_CHANGE_ACTIONS,
  SEARCH_EVENT_NAMES,
  SEARCH_SIMILAR_IMAGES_CLICK_ACTIONS,
  SEARCH_SIMILAR_IMAGES_CLICK_NAMES,
  SHARE_BY_EMAIL_CLICK_ACTIONS,
  SHARE_BY_EMAIL_CLICK_NAMES,
  SHOW_ALL_NEWS_CLICK_ACTIONS,
  SHOW_ALL_NEWS_CLICK_NAMES,
  STATISTICS_TAB_CLICK_ACTIONS,
  STATISTICS_TAB_CLICK_NAMES,
  VIEW_OFFICE_OF_ORIGIN_CLICK_ACTIONS,
  VIEW_OFFICE_OF_ORIGIN_CLICK_NAMES,
  FEEDBACK_ACTIONS,
  FEEDBACK_NAMES,
} from './constants'
import {
  getFullSearchCriteriaValue,
  prepareActionFromArray,
  prepareActionFromObject,
  prepareCleanActionFromObject,
  setupTrackingScript,
} from './utils'
import cookie from 'js-cookie'

export const DEFAULT_COOKIE_NAME = 'data_protection_cookie'
export const DEFAULT_ACCEPT_COOKIE_VALUE = 'read'
export const DEFAULT_REJECT_COOKIE_VALUE = 'reject'

let client = null
let isClientLoaded = false
let previousPageUrl = ''
let previousSiteId = ''
let unlistenHistory = () => {}
let doNotTrack = false

const init = async (matomoSettings = {}, cookieContent = {}) => {
  const settings = { ...matomoSettings, siteId: Number(matomoSettings.siteId) }

  const acceptedValue = cookieContent?.cookieValue || DEFAULT_ACCEPT_COOKIE_VALUE

  doNotTrack = cookie.get(cookieContent?.cookieName || DEFAULT_COOKIE_NAME) !== acceptedValue

  if (settings.disabled) {
    return
  }

  if (!client) {
    const isMatomoLoaded = await setupTrackingScript(settings, doNotTrack)
    if (isMatomoLoaded) {
      client = window._paq
      isClientLoaded = true
      previousSiteId = settings.siteId
      setRoutingTracking()
    }
  } else {
    client.push(['setDoNotTrack', doNotTrack])

    if (doNotTrack) {
      client.push(['disableCookies'])
    }

    if (settings.siteId !== previousSiteId) {
      client.push(['setSiteId', settings.siteId])
    }
  }
}

const trackEvent = (category, action, name = '') => {
  const canTrack = isClientLoaded
  let trackEventData = ['trackEvent', category, action]

  if (!!name) {
    trackEventData.push(name)
  }

  canTrack && client.push(trackEventData)
}

const searchEvent = (name, action) => {
  const canTrack = isClientLoaded
  let searchEventData = ['trackSiteSearch', name, action, false]

  canTrack && client.push(searchEventData)
}

const trackSearchEvent = isAdvancedSearch => {
  const category = EVENT_CATEGORIES.TRACK_SEARCH_EVENT
  const action = SEARCH_EVENT_NAMES.SEARCH_TYPE
  const name = isAdvancedSearch
    ? SEARCH_EVENT_NAMES.ADVANCED_SEARCH
    : SEARCH_EVENT_NAMES.BASIC_SEARCH

  trackEvent(category, action, name)
}

const trackSearchQueryEvent = searchValues => {
  const category = EVENT_CATEGORIES.TRACK_SEARCH_QUERY_EVENT
  let action = ''
  let name = ''
  let fieldName = ''
  if (TMDSContextService.isTmview()) {
    if (Object.values(searchValues.appDate).every(value => value !== '')) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_APP_DATE
      name = `${searchValues.appDate.from} - ${searchValues.appDate.to}`
      fieldName = FIELD_NAMES.APP_DATE

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.appName.length) {
      searchValues.appName.forEach(appName => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_APPLICANT_NAME
        name = appName.text
        fieldName = FIELD_NAMES.APP_NAME

        searchEvent(name, action)
        trackEvent(category, action, fieldName)
      })
    }
    if (searchValues.basicSearch) {
      action = SEARCH_EVENT_NAMES.BASIC_SEARCH_TERM_GROUP
      name = searchValues.basicSearch
      fieldName = FIELD_NAMES.TRADEMARK_NAME

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.currentlyOpposable) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_CURRENTLY_OPPOSABLE
      name = searchValues.currentlyOpposable
      fieldName = FIELD_NAMES.CURRENTLY_OPPOSABLE

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.niceClass.length) {
      searchValues.niceClass.forEach(item => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_NICE_CLASS
        name = `niceClass_${item.num}`

        searchEvent(name, action)
        trackEvent(category, action, name)
      })
    }
    if (searchValues.registrationNumber) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_REG_NUMBER
      name = searchValues.registrationNumber
      fieldName = FIELD_NAMES.REG_NUMBER

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.seniorityClaimed) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_SENIORITY_CLAIMED
      name = searchValues.seniorityClaimed
      fieldName = FIELD_NAMES.SENIORITY_CLAIMED

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.tmStatus.length) {
      searchValues.tmStatus.forEach(item => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_TM_STATUS
        if (item.id === 'I') {
          name = 'Inactive'
        } else if (item.id === 'A') {
          name = 'Active'
        } else {
          name = item.id
        }

        searchEvent(name, action)
        trackEvent(category, action, name)
      })
    }
    if (searchValues.tmType.length) {
      searchValues.tmType.forEach(item => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_TM_TYPE
        name = item.id

        searchEvent(name, action)
        trackEvent(category, action, name)
      })
    }
    if (searchValues.viennaCode.length) {
      searchValues.viennaCode.forEach(item => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_VIENNA_CODE
        name = `viennaCode_${item.code}`

        searchEvent(name, action)
        trackEvent(category, action, name)
      })
    }
    if (searchValues.viennaCodeVersion && searchValues.viennaCode.length) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_VIENNA_CODE_VERSION
      name = searchValues.viennaCodeVersion
      fieldName = FIELD_NAMES.VIENNA_CODE_VERSION

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
  } else {
    if (searchValues.applicantName.length) {
      searchValues.applicantName.forEach(appName => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_APPLICANT_NAME
        name = appName.text
        fieldName = FIELD_NAMES.APPLICANT_NAME

        searchEvent(name, action)
        trackEvent(category, action, fieldName)
      })
    }
    if (Object.values(searchValues.applicationDate).every(value => value !== '')) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_APP_DATE
      name = `${searchValues.applicationDate.from} - ${searchValues.applicationDate.to}`
      fieldName = FIELD_NAMES.FILING_DATE

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.designNumber) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_DESIGN_NUMBER
      name = searchValues.designNumber
      fieldName = FIELD_NAMES.DESIGN_NUMBER

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.designStatus.length) {
      searchValues.designStatus.forEach(status => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_DESIGN_STATUS
        if (status.id === 'FI') {
          name = 'Filed'
        } else if (status.id === 'RE') {
          name = 'Registered'
        } else if (status.id === 'EN') {
          name = 'Ended'
        } else if (status.id === 'EX') {
          name = 'Expired'
        } else {
          name = status.id
        }

        searchEvent(name, action)
        trackEvent(category, action, name)
      })
    }
    if (searchValues.designerName.length) {
      searchValues.designerName.forEach(name => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_DESIGNER_NAME
        name = name.text
        fieldName = FIELD_NAMES.DESIGNER_NAME

        searchEvent(name, action)
        trackEvent(category, action, fieldName)
      })
    }
    if (Object.values(searchValues.effectiveDate).every(value => value !== '')) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_EFFECTIVE_DATE
      name = `${searchValues.effectiveDate.from} - ${searchValues.effectiveDate.to}`
      fieldName = FIELD_NAMES.EFFECTIVE_DATE

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (Object.values(searchValues.endOfDefermentDate).every(value => value !== '')) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_END_OF_DEFERMENT_DATE
      name = `${searchValues.endOfDefermentDate.from} - ${searchValues.endOfDefermentDate.to}`
      fieldName = FIELD_NAMES.END_OF_DEFERMENT

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.exhibitionPriority) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_EXHIBITION_PRIORITY
      name = searchValues.exhibitionPriority
      fieldName = FIELD_NAMES.EXHIBITION_PRIORITY

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (Object.values(searchValues.expiryDate).every(value => value !== '')) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_EXPIRY_DATE
      name = `${searchValues.expiryDate.from} - ${searchValues.expiryDate.to}`
      fieldName = FIELD_NAMES.EXPIRY_DATE

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.indicationOfProduct) {
      action = SEARCH_EVENT_NAMES.INDICATION_OF_PRODUCT
      name = searchValues.indicationOfProduct
      fieldName = FIELD_NAMES.INDICATION_OF_PRODUCT

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.locarnoClass.length) {
      searchValues.locarnoClass.forEach(loc => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_LOCARNO_CLASS
        name = `locarnoClass_${loc.code}`

        searchEvent(name, action)
        trackEvent(category, action, name)
      })
    }
    if (searchValues.locarnoVersion && searchValues.locarnoClass.length) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_LOCARNO_VERSION
      name = searchValues.locarnoVersion
      fieldName = FIELD_NAMES.LOCARNO_VERSION

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.previousApplicantName.length) {
      searchValues.previousApplicantName.forEach(name => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_PREVIOUS_APPLICANT_NAME
        name = name.text
        fieldName = FIELD_NAMES.PREVIOUS_APPLICANT_NAME

        searchEvent(name, action)
        trackEvent(category, action, fieldName)
      })
    }
    if (searchValues.priorityCountry.length) {
      searchValues.priorityCountry.forEach(country => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_PRIORITY_COUNTRY
        name = `priorityCountry_${country.code}`

        searchEvent(name, action)
        trackEvent(category, action, name)
      })
    }
    if (Object.values(searchValues.priorityDate).every(value => value !== '')) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_PRIORITY_DATE
      name = `${searchValues.priorityDate.from} - ${searchValues.priorityDate.to}`
      fieldName = FIELD_NAMES.PRIORITY_DATE

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.priorityNumber) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_PRIORITY_NUMBER
      name = searchValues.priorityNumber
      fieldName = FIELD_NAMES.PRIORITY_NUMBER

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (Object.values(searchValues.publicationDate).every(value => value !== '')) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_PUBLICATION_DATE
      name = `${searchValues.publicationDate.from} - ${searchValues.publicationDate.to}`
      fieldName = FIELD_NAMES.PUBLICATION_DATE

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
    if (searchValues.representativeName.length) {
      searchValues.representativeName.forEach(name => {
        action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_REPRESENTATIVE_NAME
        name = name.text
        fieldName = FIELD_NAMES.REPRESENTATIVE_NAME

        searchEvent(name, action)
        trackEvent(category, action, fieldName)
      })
    }
    if (searchValues.verbalElement) {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_VERBAL_ELEMENT
      name = searchValues.verbalElement
      fieldName = FIELD_NAMES.VERBAL_ELEMENT

      searchEvent(name, action)
      trackEvent(category, action, fieldName)
    }
  }

  if (searchValues.applicationNumber) {
    action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_APP_NUMBER
    name = searchValues.applicationNumber
    fieldName = FIELD_NAMES.APP_NUMBER

    searchEvent(name, action)
    trackEvent(category, action, fieldName)
  }
  if (
    Object.keys(searchValues.criteria).length &&
    ((TMDSContextService.isTmview() && searchValues.basicSearch) ||
      (!TMDSContextService.isTmview() && searchValues.indicationOfProduct))
  ) {
    action = SEARCH_EVENT_NAMES.DENOMINATION_SEARCH
    name = getFullSearchCriteriaValue(searchValues.criteria.id)

    searchEvent(name, action)
    trackEvent(category, action, name)
  }
  if (searchValues.imageSearch) {
    action = SEARCH_EVENT_NAMES.IMAGE_SEARCH
    name = searchValues.imageSearch
    fieldName = FIELD_NAMES.IMAGE_SEARCH

    searchEvent(name, action)
    trackEvent(category, action, fieldName)
  }
  if (searchValues.offices.length) {
    searchValues.offices.forEach(item => {
      action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_OFFICES
      name = `office_${item.id}`

      searchEvent(name, action)
      trackEvent(category, action, name)
    })
  }
  if (Object.values(searchValues.regDate).every(value => value !== '')) {
    action = SEARCH_EVENT_NAMES.ADVANCED_SEARCH_REG_DATE
    name = `${searchValues.regDate.from} - ${searchValues.regDate.to}`
    fieldName = FIELD_NAMES.REG_DATE

    searchEvent(name, action)
    trackEvent(category, action, fieldName)
  }
}

const trackFilterEvent = filterQuery => {
  const category = EVENT_CATEGORIES.TRACK_FILTER_EVENT
  const action = prepareCleanActionFromObject(filterQuery)
  const name = RESULTS_EVENT_NAMES.FILTER
  trackEvent(category, action, name)
}

//When user enters in a detail
const trackDetailEvent = officeCode => {
  const category = EVENT_CATEGORIES.TRACK_DETAIL_EVENT
  const action = `office_${officeCode}`
  const name = `${DETAIL_EVENT_NAMES.PREFIX}-${officeCode}`
  trackEvent(category, action, name)
}

//When user clicks wipo button in search
const trackGlobalBrand = searchData => {
  const category = EVENT_CATEGORIES.TRACK_GLOBAL_BRAND
  const action = searchData
  const name = GLOBAL_BRAND_NAMES.GLOBAL_BRAND
  trackEvent(category, action, name)
}

//When user clicks in .eu link in "More Links" section at home
const trackEurIdLink = () => {
  const category = EVENT_CATEGORIES.EURID_LINK
  const action = 'EURid-clicked'
  const name = TMDSContextService.isTmview() ? EURID_LINK_NAMES.TMVIEW : EURID_LINK_NAMES.DSVIEW
  trackEvent(category, action, name)
}

const trackLastSearch = searchData => {
  const category = EVENT_CATEGORIES.TRACK_LAST_SEARCH_EVENT
  const action = prepareActionFromObject(searchData)
  const name = SEARCH_EVENT_NAMES.LAST_SEARCH
  trackEvent(category, action, name)
}

const trackStatisticsAccess = () => {
  const category = EVENT_CATEGORIES.TRACK_STATISTICS_ACCESS
  const action = 'Statistics-Access-clicked'
  const name = RESULTS_EVENT_NAMES.STATISTICS
  trackEvent(category, action, name)
}

const trackResultExpand = ST13 => {
  const category = EVENT_CATEGORIES.TRACK_RESULT_EXPAND_EVENT
  const action = ST13
  const name = RESULTS_EVENT_NAMES.EXPAND
  trackEvent(category, action, name)
}

const trackExport = (section = '', format = '') => {
  const category = EVENT_CATEGORIES.TRACK_EXPORT_EVENT
  const action = `EXPORT ${section.toUpperCase()}`
  const name = format.toUpperCase()
  trackEvent(category, action, name)
}

const trackExportTemplateChange = (template = 'table') => {
  const category = EVENT_CATEGORIES.TRACK_EXPORT_TEMPLATE_CHANGE_EVENT
  const action = `EXPORT TEMPLATE CHANGE`
  const name = `export_${template.toUpperCase()}`
  trackEvent(category, action, name)
}

const trackTranslateIndicationOfProduct = (section, language) => {
  const category = EVENT_CATEGORIES.TRACK_IP_TRANSLATE_EVENT
  trackTranslate(section, language, category)
}

const trackTranslateGoodsAndServices = (section, language) => {
  const category = EVENT_CATEGORIES.TRACK_GS_TRANSLATE_EVENT
  trackTranslate(section, language, category)
}

const trackTranslate = (section = '', language = '', category) => {
  const action = `TRANSLATE${language && ` ${language.toUpperCase()}`}`
  const name = section.toUpperCase()
  trackEvent(category, action, name)
}

const trackEditColumns = columns => {
  const category = EVENT_CATEGORIES.TRACK_EDIT_COLUMNS_EVENT
  const action = prepareActionFromArray(columns)
  const name = RESULTS_EVENT_NAMES.EDIT_COLUMNS
  trackEvent(category, action, name)
}

const setRoutingTracking = () => {
  unlistenHistory()
  const history = TMDSRouterService.getHistory()
  unlistenHistory = history.listen(trackRouting)
}

const trackRouting = (location, action) => {
  const host = window.location.origin
  const currentPageUrl = location.path || `${location.pathname}${location.search}`
  if (currentPageUrl === previousPageUrl) {
    return
  }

  if (previousPageUrl) {
    client.push(['setReferrerUrl', `${host}/#/${previousPageUrl}`])
  }
  client.push(['setCustomUrl', `${host}/#/${currentPageUrl}`])
  client.push(['trackPageView'])

  previousPageUrl = currentPageUrl
}

export const trackHistoryAccess = () => {
  const category = EVENT_CATEGORIES.TRACK_HISTORY_EVENT
  const action = HISTORY_ACTIONS.HISTORY_ACCESS
  const name = HISTORY_ACTIONS.HISTORY_ACCESS
  trackEvent(category, action, name)
}

export const trackHistoryView = () => {
  const category = EVENT_CATEGORIES.TRACK_HISTORY_EVENT
  const action = HISTORY_ACTIONS.HISTORY_VIEW
  const name = HISTORY_ACTIONS.HISTORY_VIEW
  trackEvent(category, action, name)
}

export const trackHistoryCompare = () => {
  const category = EVENT_CATEGORIES.TRACK_HISTORY_EVENT
  const action = HISTORY_ACTIONS.HISTORY_COMPARE
  const name = HISTORY_ACTIONS.HISTORY_COMPARE
  trackEvent(category, action, name)
}

const trackEditFilters = (changedFilters, initialFilters, storedFilters) => {
  const category = EVENT_CATEGORIES.TRACK_EDIT_FILTERS_EVENT
  const action = prepareActionFromArray(changedFilters, initialFilters, storedFilters)
  const name = RESULTS_EVENT_NAMES.EDIT_FILTERS
  trackEvent(category, action, name)
}

const trackConfigureSearch = () => {
  const category = EVENT_CATEGORIES.TRACK_CONFIGURE_SEARCH_EVENT
  const action = CONFIGURE_SEARCH_ACTIONS.CONFIGURE_SEARCH_ACTION
  const name = CONFIGURE_SEARCH_NAMES.CONFIGURE_SEARCH
  trackEvent(category, action, name)
}

const trackCompareEvent = (compareType, comparedItems) => {
  const category = EVENT_CATEGORIES.TRACK_COMPARE_EVENT
  const action = COMPARE_ACTIONS.COMPARE_ACTION
  const name = `${compareType}: ${comparedItems}`
  trackEvent(category, action, name)
}

const trackResultsViewChangeEvent = viewChangeType => {
  const category = EVENT_CATEGORIES.TRACK_RESULTS_VIEW_CHANGE_EVENT
  const action = RESULTS_VIEW_CHANGE_ACTIONS.RESULTS_VIEW_CHANGE_ACTION
  const name = `results_view_change_${viewChangeType}`
  trackEvent(category, action, name)
}

const trackHelpClickEvent = () => {
  const category = EVENT_CATEGORIES.TRACK_HELP_CLICK_EVENT
  const action = HELP_CLICK_ACTIONS.HELP_CLICK_ACTION
  const name = HELP_CLICK_NAMES.HELP_CLICK_NAME
  trackEvent(category, action, name)
}

const trackYourFeedbackSubmitEvent = () => {
  const category = EVENT_CATEGORIES.TRACK_YOUR_FEEDBACK_SUBMIT_EVENT
  const action = FEEDBACK_ACTIONS.SUBMITTING_FEEDBACK_ACTION
  const name = FEEDBACK_NAMES.SUBMITTING_FEEDBACK
  trackEvent(category, action, name)
}

const trackOpenFeedbackWindowEvent = () => {
  const category = EVENT_CATEGORIES.TRACK_YOUR_FEEDBACK_SUBMIT_EVENT
  const action = FEEDBACK_ACTIONS.OPENING_FEEDBACK_WINDOW_ACTION
  const name = FEEDBACK_NAMES.OPENING_FEEDBACK_WINDOW

  trackEvent(category, action, name)
}

const trackRegisterClickEvent = () => {
  const category = EVENT_CATEGORIES.TRACK_REGISTER_CLICK_EVENT
  const action = REGISTER_CLICK_ACTIONS.REGISTER_CLICK_ACTION
  const name = REGISTER_CLICK_NAMES.REGISTER_CLICK_NAME
  trackEvent(category, action, name)
}

const trackShowAllNewsClickEvent = () => {
  const category = EVENT_CATEGORIES.TRACK_SHOW_ALL_NEWS_CLICK_EVENT
  const action = SHOW_ALL_NEWS_CLICK_ACTIONS.SHOW_ALL_NEWS_CLICK_ACTION
  const name = SHOW_ALL_NEWS_CLICK_NAMES.SHOW_ALL_NEWS_CLICK_NAME
  trackEvent(category, action, name)
}

const trackShareByEmailClickEvent = () => {
  const category = EVENT_CATEGORIES.TRACK_SHARE_BY_EMAIL_CLICK_EVENT
  const action = SHARE_BY_EMAIL_CLICK_ACTIONS.SHARE_BY_EMAIL_CLICK_ACTION
  const name = SHARE_BY_EMAIL_CLICK_NAMES.SHARE_BY_EMAIL_CLICK_NAME
  trackEvent(category, action, name)
}

const trackStatisticsTabClickEvent = tab => {
  const category = EVENT_CATEGORIES.TRACK_STATISTICS_TAB_CLICK_EVENT
  const action = STATISTICS_TAB_CLICK_ACTIONS.STATISTICS_TAB_CLICK_ACTION
  const name = `statistics_${tab}_${STATISTICS_TAB_CLICK_NAMES.STATISTICS_TAB_CLICK_NAMES}`
  trackEvent(category, action, name)
}

const trackViewOfficeOfOriginClickEvent = () => {
  const category = EVENT_CATEGORIES.TRACK_VIEW_OFFICE_OF_ORIGIN_CLICK_EVENT
  const action = VIEW_OFFICE_OF_ORIGIN_CLICK_ACTIONS.VIEW_OFFICE_OF_ORIGIN_CLICK_ACTION
  const prefix = TMDSContextService.isTmview() ? 'TM_' : 'DS_'
  const name = prefix + VIEW_OFFICE_OF_ORIGIN_CLICK_NAMES.VIEW_OFFICE_OF_ORIGIN_CLICK_NAME
  trackEvent(category, action, name)
}

const trackOrigLangOrTransClickEvent = isOriginal => {
  const category = EVENT_CATEGORIES.TRACK_ORIG_LANG_OR_TRANS_CLICK_EVENT
  const action = ORIG_LANG_OR_TRANS_CLICK_ACTIONS.ORIG_LANG_OR_TRANS_CLICK_ACTION
  const prefix = isOriginal ? 'translation_' : 'originalLanguage_'
  const name = prefix + ORIG_LANG_OR_TRANS_CLICK_NAMES.ORIG_LANG_OR_TRANS_CLICK_NAME
  trackEvent(category, action, name)
}

const trackSearchSimilarImagesClickEvent = () => {
  const category = EVENT_CATEGORIES.TRACK_SEARCH_SIMILAR_IMAGES_CLICK_EVENT
  const action = SEARCH_SIMILAR_IMAGES_CLICK_ACTIONS.SEARCH_SIMILAR_IMAGES_CLICK_ACTION
  const name = SEARCH_SIMILAR_IMAGES_CLICK_NAMES.SEARCH_SIMILAR_IMAGES_CLICK_NAME
  trackEvent(category, action, name)
}

const trackResultsPaginationClickEvents = linkType => {
  const category = EVENT_CATEGORIES.TRACK_RESULTS_PAGINATION_CLICK_EVENTS
  const action = RESULTS_PAGINATION_CLICK_ACTIONS.RESULTS_PAGINATION_CLICK_ACTION
  const name = linkType + RESULTS_PAGINATION_CLICK_NAMES.RESULTS_PAGINATION_CLICK_NAME
  trackEvent(category, action, name)
}

export const TMDSTrackingService = {
  init,
  trackSearchQueryEvent,
  trackSearchEvent,
  trackDetailEvent,
  trackGlobalBrand,
  trackEurIdLink,
  trackLastSearch,
  trackStatisticsAccess,
  trackResultExpand,
  trackExport,
  trackExportTemplateChange,
  trackTranslateGoodsAndServices,
  trackTranslateIndicationOfProduct,
  trackFilterEvent,
  trackEditColumns,
  trackHistoryAccess,
  trackHistoryView,
  trackHistoryCompare,
  trackEditFilters,
  trackConfigureSearch,
  trackCompareEvent,
  trackResultsViewChangeEvent,
  trackHelpClickEvent,
  trackYourFeedbackSubmitEvent,
  trackOpenFeedbackWindowEvent,
  trackRegisterClickEvent,
  trackShowAllNewsClickEvent,
  trackShareByEmailClickEvent,
  trackStatisticsTabClickEvent,
  trackViewOfficeOfOriginClickEvent,
  trackOrigLangOrTransClickEvent,
  trackSearchSimilarImagesClickEvent,
  trackResultsPaginationClickEvents,
}
