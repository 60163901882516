import { userprefsDataManager } from '@app/common/dataManagers'
import { NOTIFICATIONS_TYPES } from '@app/redux-types'

export const storeRecentNotifications = recentNotifications => ({
  type: NOTIFICATIONS_TYPES.STORE_RECENT_NOTIFICATIONS,
  payload: recentNotifications,
})

export const getNotifications = filterParams => ({
  type: NOTIFICATIONS_TYPES.GET_NOTIFICATIONS,
  payload: userprefsDataManager.getNotifications(filterParams),
})

export const checkUnseenNotifications = () => ({
  type: NOTIFICATIONS_TYPES.CHECK_UNSEEN_NOTIFICATIONS,
})
