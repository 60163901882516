import { t } from '@app/common/translationsService'
import TMForm from '@app/components/TMForm'
import { Block } from '@new-lucentum'
import React from 'react'
import styled from 'styled-components'

const TMFormInput = styled(TMForm.Input)`
  &&& {
    max-width: 80px;
    display: inline;
    margin-right: ${({ theme }) => `${theme.size}`};
    height: ${({ theme }) => `${theme.sizeTriple}`};
    padding: ${({ theme }) => `${theme.sizeSmall} ${theme.size}`} !important;
  }
`
const CheckboxWithInputComponent = props => {
  const { field, inputField, values, label, onChangeInput, isDisabled } = props
  return (
    <div>
      <TMForm.Checkbox
        name={field}
        label={label}
        resetFieldsOnChange={[inputField]}
        disabled={isDisabled}
      />
      <Block auto middle marginLeft={3}>
        <TMFormInput
          type="number"
          min="0"
          step="1"
          disabled={!values[field] || isDisabled}
          name={inputField}
          onChangeHandler={value => {
            onChangeInput && onChangeInput(value)
          }}
        />
        <span>{t('days_key')}</span>
      </Block>
    </div>
  )
}

export const CheckboxWithInput = CheckboxWithInputComponent
