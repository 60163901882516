import * as React from 'react'
import styled from 'styled-components'

import Block from '../Block'
import Tooltip from '../Tooltip'

const StyledTooltip = styled(Tooltip)`
  &.place-bottom:before,
  &.place-bottom:after {
    ${({ cornerLeft }) => cornerLeft && `left: ${cornerLeft};`}
  }
`

export default ({
  children,
  tooltipId,
  place,
  offset,
  cornerLeft,
  renderTooltip,
  delayHide,
  ...props
}) => (
  <Block auto data-for={tooltipId} data-tip {...props}>
    {children}
    {renderTooltip && (
      <StyledTooltip
        id={tooltipId}
        place={place}
        offset={offset}
        cornerLeft={cornerLeft}
        delayHide={delayHide}
      >
        {renderTooltip}
      </StyledTooltip>
    )}
  </Block>
)
