import ReactModal from 'react-modal'
import styled, { css } from 'styled-components'
import { Button, Block, H2 } from '@new-lucentum'

const overlay = {
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  overflow: 'hidden',
  outline: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  overflowX: 'hidden',
  overflowY: 'auto',
  position: 'fixed',
  zIndex: '10000',
}

const content = {
  background: '#fff',
  borderRadius: '2px',
  maxWidth: '600px',
  minWidth: '400px',
  outline: 'none',
  padding: '0px',
  top: 'outset',
  left: 'outset',
  right: 'outset',
  bottom: 'outset',
  position: 'relative',
  width: 'auto',
  margin: '8vh auto auto',
}

export const styles = { content, overlay }

export const CloseButton = styled(Button)`
  padding: 0;
  &,
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:hover {
    background-color: transparent;
    i {
      color: ${({ theme }) => theme.color};
    }
  }
  i {
    color: ${({ theme }) => theme.colorGrayLight};
    font-size: calc(${({ theme }) => theme.sizeLarge} * 1.5);
  }
`

export const CloseButtonFeedback = styled(Button)`
  padding: 0;
  &,
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:hover {
    background-color: transparent;
    i {
      color: ${({ theme }) => theme.color};
    }
  }
  i {
    font-size: ${({ theme }) => theme.sizeCuadruple};
  }
`

export const CancelButton = styled(Button)`
  margin-left: ${({ theme }) => theme.size};
`

export const Title = styled.div`
  color: ${({ theme }) => theme.modalHeaderTextColor};
  align-items: center;
  border-bottom: ${({ theme }) => theme.modalHeaderBorder};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.sizeDouble} ${theme.sizeTriple}`};

  ${({ theme }) =>
    theme.responsive(css`
      padding: 16px;
      border-bottom: hidden;
      ${H2} {
        ${theme.uiText};
        color: #363f4e;
        font-weight: bold;
      }
    `)}
`

export const BlockContent = styled(Block)`
  background: #fff;
  padding: ${({ theme }) => `${theme.sizeCuadruple} ${theme.sizeTriple}`};

  ${({ theme }) =>
    theme.responsive(css`
      padding: 0 4px 8px 4px;
    `)}
`
export const BlockActions = styled(Block)`
  background: ${({ theme }) => theme.colorGrayLighter};
`
export const StyledReactModal = styled(ReactModal)`
  &.ReactModal__Content {
    ${({ theme }) =>
      theme.responsive(css`
        max-width: 97% !important;
        min-width: auto !important;
        border-radius: 0 !important;
        margin: 0 !important;
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
      `)}
  }
`
