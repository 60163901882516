import React from 'react'
import { hasItemsToShow } from '@app/common/utilities'
import { applicantFields } from './fields'
import { Box } from './Box'
import { DataList } from './DataItem'
import { ShowMore } from './ShowMore'
import { getFormatedData, parseAddress } from './utils'
import { DataRow } from './styles'
import { totalBoxEntries } from './utils'
import { useSelector } from 'react-redux'
import { t } from '@app/common/translationsService'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'

const SHOWN_ROWS_LIMIT = 1
const BoxOwnersComponent = ({ inidCodes, data = {}, officeExceptionMessage, ...props }) => {
  const [showMore, setShowMore] = React.useState(false)
  const [formatedData, setFormatedData] = React.useState([])
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)

  React.useEffect(
    () => {
      const nextFormatedData =
        data.applicants &&
        data.applicants.map(applicant =>
          getFormatedData(applicantFields, parseAddress(applicant), inidCodes)
        )
      setFormatedData(nextFormatedData)
      const hasItems =
        Array.isArray(nextFormatedData) && nextFormatedData.some(row => hasItemsToShow(row))
      hasItems !== props.hasItemsToShow && props.setHasItemsToShow('boxOwner', hasItems)
    },
    [data, showOriginalLanguageAttributes]
  )

  const toggleShowMore = React.useCallback(() => setShowMore(!showMore), [showMore])

  const BoxData =
    props.hasItemsToShow &&
    formatedData.map((dataRow, index) => {
      if (index < SHOWN_ROWS_LIMIT || showMore)
        return (
          <DataRow key={index}>
            <DataList
              testId="boxOwner-show-more2"
              data={dataRow}
              literals={props.literals}
              shownItemsLimit={99}
            />
            {index + 1 !== data.applicants.length && showMore && <hr />}
          </DataRow>
        )
    })

  return (
    <Box
      boxId="boxOwner"
      title={`${t('tm.details.left.menu.owner.key')} ${totalBoxEntries(data.applicants)}`}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-owner"
      officeExceptionMessage={officeExceptionMessage}
    >
      {BoxData}
      {props.hasItemsToShow && data.applicants.length > SHOWN_ROWS_LIMIT && (
        <ShowMore testId="boxOwner-show-more" showMore={showMore} showMoreHandle={toggleShowMore} />
      )}
    </Box>
  )
}

export const BoxOwner = BoxOwnersComponent
