import { set, over, not, lensPath, compose } from 'ramda'
import { getDigitsFromString } from '@app/common/utilities'

const lenseModal = lensPath(['alertModal'])
const lense = name =>
  compose(
    lenseModal,
    lensPath([name])
  )
const defaultState = {
  alertModal: {
    showAddAlertModal: false,
    nameChange: false,
    statusChange: false,
    oppPeriodChange: false,
    dateDaysLimit: '',
  },
  showMessageAlerts: { showAlerts: false, deletedAlert: '' },
  alertsByTradeMark: [],
  currentSort: {},
  expandedRows: [],
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'TOGGLE_ALERT_MODAL':
      return over(lense('showAddAlertModal'), not, state)
    case 'TOGGLE_CHANGE_NAME_MODAL':
      return over(lense('nameChange'), not, state)
    case 'TOGGLE_CHANGE_STATUS_MODAL':
      return over(lense('statusChange'), not, state)
    case 'CHANGE_DAYS_MODAL':
      return set(lense('dateDaysLimit'), getDigitsFromString(payload), state)
    case 'TOGGLE_CHANGE_OPPO_PERIOD_MODAL':
      return over(lense('oppPeriodChange'), not, state)
    case 'RESET_VALUES_MODAL':
      return set(lenseModal, defaultState.alertModal, state)
    case 'TOGGLE_MESSAGE_ALERTS':
      return set(lensPath(['showMessageAlerts']), payload, state)
    case 'CHANGE_SORT_COLUMN':
      return set(lensPath(['currentSort']), payload, state)
    case 'CHANGE_EXPANDED_ROWS_ALERTS':
      return set(lensPath(['expandedRows']), payload, state)
    case 'RESET_MANAGE_ALERTS':
      return defaultState
    default:
      return state
  }
}
