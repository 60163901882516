export const EVENT_CATEGORIES = {
  TRACK_SEARCH_EVENT: 'TRACK-SEARCH-EVENT',
  TRACK_SEARCH_QUERY_EVENT: 'TRACK-SEARCH-QUERY-EVENT',
  TRACK_LAST_SEARCH_EVENT: 'TRACK-LAST-SEARCH-EVENT',
  TRACK_DETAIL_EVENT: 'TRACK-DETAIL-EVENT',
  TRACK_GLOBAL_BRAND: 'TRACK-GLOBAL-BRAND',
  EURID_LINK: 'EURid-Link',
  TRACK_STATISTICS_ACCESS: 'TRACK-STATISTICS-ACCESS',
  TRACK_RESULT_EXPAND_EVENT: 'TRACK-RESULT-EXPAND-EVENT',
  TRACK_EXPORT_EVENT: 'TRACK-EXPORT-EVENT',
  TRACK_EXPORT_TEMPLATE_CHANGE_EVENT: 'TRACK-EXPORT-TEMPLATE-CHANGE-EVENT',
  TRACK_GS_TRANSLATE_EVENT: 'TRACK-GS-TRANSLATE-EVENT',
  TRACK_IP_TRANSLATE_EVENT: 'TRACK-IP-TRANSLATE-EVENT',
  TRACK_FILTER_EVENT: 'TRACK-FILTER-EVENT',
  TRACK_EDIT_COLUMNS_EVENT: 'TRACK-EDIT-COLUMNS-EVENT',
  TRACK_HISTORY_EVENT: 'TRACK-HISTORY-EVENT',
  TRACK_EDIT_FILTERS_EVENT: 'TRACK-EDIT-FILTERS-EVENT',
  TRACK_CONFIGURE_SEARCH_EVENT: 'TRACK-CONFIGURE-SEARCH-EVENT',
  TRACK_COMPARE_EVENT: 'TRACK-COMPARE-EVENT',
  TRACK_RESULTS_VIEW_CHANGE_EVENT: 'TRACK-RESULTS-VIEW-CHANGE-EVENT',
  TRACK_HELP_CLICK_EVENT: 'TRACK-HELP-CLICK-EVENT',
  TRACK_YOUR_FEEDBACK_SUBMIT_EVENT: 'TRACK-YOUR-FEEDBACK-SUBMIT-EVENT',
  TRACK_REGISTER_CLICK_EVENT: 'TRACK-REGISTER-CLICK-EVENT',
  TRACK_SHOW_ALL_NEWS_CLICK_EVENT: 'TRACK-SHOW-ALL-NEWS-CLICK-EVENT',
  TRACK_SHARE_BY_EMAIL_CLICK_EVENT: 'TRACK-SHARE-BY-EMAIL-CLICK-EVENT',
  TRACK_STATISTICS_TAB_CLICK_EVENT: 'TRACK-STATISTICS-TAB-CLICK-EVENT',
  TRACK_VIEW_OFFICE_OF_ORIGIN_CLICK_EVENT: 'TRACK-VIEW-OFFICE-OF-ORIGIN-CLICK-EVENT',
  TRACK_ORIG_LANG_OR_TRANS_CLICK_EVENT: 'TRACK-ORIGINAL-LANGUAGE-OR-TRANSLATION-CLICK-EVENT',
  TRACK_SEARCH_SIMILAR_IMAGES_CLICK_EVENT: 'TRACK-SEARCH-SIMILAR-TM-IMAGES-CLICK-EVENT',
  TRACK_RESULTS_PAGINATION_CLICK_EVENTS: 'TRACK-RESULT-PAGINATION-CLICK-EVENTS',
}

export const SEARCH_EVENT_NAMES = {
  ADVANCED_SEARCH: 'advancedSearch',
  BASIC_SEARCH: 'basicSearch',
  IMAGE_SEARCH: 'imageSearch',
  LAST_SEARCH: 'lastSearch',
  SEARCH_TYPE: 'searchType',
  BASIC_SEARCH_TERM_GROUP: 'basicSearchTermGroup',
  DENOMINATION_SEARCH: 'denominationSearch',
  ADVANCED_SEARCH_APPLICANT_NAME: 'advancedSearchApplicantName',
  ADVANCED_SEARCH_APP_DATE: 'advancedSearchAppDate',
  ADVANCED_SEARCH_APP_NUMBER: 'advancedSearchAppNumber',
  ADVANCED_SEARCH_CURRENTLY_OPPOSABLE: 'advancedSearchCurrentlyOpposable',
  ADVANCED_SEARCH_NICE_CLASS: 'advancedSearchNiceClass',
  ADVANCED_SEARCH_OFFICES: 'advancedSearchOffices',
  ADVANCED_SEARCH_REG_DATE: 'advancedSearchRegDate',
  ADVANCED_SEARCH_REG_NUMBER: 'advancedSearchRegNumber',
  ADVANCED_SEARCH_SENIORITY_CLAIMED: 'advancedSearchSeniorityClaimed',
  ADVANCED_SEARCH_TERRITORIES: 'advancedSearchTerritories',
  ADVANCED_SEARCH_TM_STATUS: 'advancedSearchTmStatus',
  ADVANCED_SEARCH_TM_TYPE: 'advancedSearchTmType',
  ADVANCED_SEARCH_VIENNA_CODE: 'advancedSearchViennaCode',
  ADVANCED_SEARCH_VIENNA_CODE_VERSION: 'advancedSearchViennaCodeVersion',
  ADVANCED_SEARCH_DESIGN_NUMBER: 'advancedSearchDesignNumber',
  ADVANCED_SEARCH_DESIGN_STATUS: 'advancedSearchDesignStatus',
  ADVANCED_SEARCH_DESIGNER_NAME: 'advancedSearchDesignerName',
  ADVANCED_SEARCH_EFFECTIVE_DATE: 'advancedSearchEffectiveDate',
  ADVANCED_SEARCH_END_OF_DEFERMENT_DATE: 'advancedSearchEndOfDefermentDate',
  ADVANCED_SEARCH_EXHIBITION_PRIORITY: 'advancedSearchExhibitionPriority',
  ADVANCED_SEARCH_EXPIRY_DATE: 'advancedSearchExpiryDate',
  INDICATION_OF_PRODUCT: 'indicationOfProduct',
  ADVANCED_SEARCH_LOCARNO_CLASS: 'advancedSearchLocarnoClass',
  ADVANCED_SEARCH_LOCARNO_VERSION: 'advancedSearchLocarnoVersion',
  ADVANCED_SEARCH_PREVIOUS_APPLICANT_NAME: 'advancedSearchPreviousApplicantName',
  ADVANCED_SEARCH_PRIORITY_COUNTRY: 'advancedSearchPriorityCountry',
  ADVANCED_SEARCH_PRIORITY_DATE: 'advancedSearchPriorityDate',
  ADVANCED_SEARCH_PRIORITY_NUMBER: 'advancedSearchPriorityNumber',
  ADVANCED_SEARCH_PUBLICATION_DATE: 'advancedSearchPublicationDate',
  ADVANCED_SEARCH_REPRESENTATIVE_NAME: 'advancedSearchRepresentativeName',
  ADVANCED_SEARCH_VERBAL_ELEMENT: 'advancedSearchVerbalElement',
}

export const FIELD_NAMES = {
  APP_DATE: 'application-date',
  APP_NAME: 'applicant-name',
  TRADEMARK_NAME: 'trademark-name',
  CURRENTLY_OPPOSABLE: 'currently-opposable',
  IMAGE_SEARCH: 'image-search',
  REG_NUMBER: 'registration-number',
  SENIORITY_CLAIMED: 'seniority-claimed',
  VIENNA_CODE_VERSION: 'vienna-code',
  APPLICANT_NAME: 'owner-name',
  FILING_DATE: 'filing-date',
  DESIGN_NUMBER: 'design-number',
  DESIGNER_NAME: 'designer-name',
  EFFECTIVE_DATE: 'effective-date',
  END_OF_DEFERMENT: 'end-of-deferment',
  EXHIBITION_PRIORITY: 'exhibition-priority',
  EXPIRY_DATE: 'expiry-date',
  INDICATION_OF_PRODUCT: 'indication-of-product',
  LOCARNO_VERSION: 'locarno-classification',
  PREVIOUS_APPLICANT_NAME: 'previous-owner-name',
  PRIORITY_DATE: 'priority-date',
  PRIORITY_NUMBER: 'priority-number',
  PUBLICATION_DATE: 'publication-date',
  REPRESENTATIVE_NAME: 'representative-name',
  VERBAL_ELEMENT: 'verbal-element',
  APP_NUMBER: 'application-number',
  REG_DATE: 'registration-date',
}

export const RESULTS_EVENT_NAMES = {
  STATISTICS: 'statistics',
  EXPAND: 'expand',
  FILTER: 'filter',
  EDIT_COLUMNS: 'editColumns',
  EDIT_FILTERS: 'editFilters',
}

export const DETAIL_EVENT_NAMES = {
  PREFIX: 'detail',
}

export const GLOBAL_BRAND_NAMES = {
  GLOBAL_BRAND: 'globalBrand',
}

export const EURID_LINK_NAMES = {
  TMVIEW: 'TMview',
  DSVIEW: 'DSview',
}

export const CONFIGURE_SEARCH_NAMES = {
  CONFIGURE_SEARCH: 'configureSearch',
}

export const HELP_CLICK_NAMES = {
  HELP_CLICK_NAME: 'helpClicked',
}

export const FEEDBACK_NAMES = {
  SUBMITTING_FEEDBACK: 'yourFeedbackSubmitted',
  OPENING_FEEDBACK_WINDOW: 'openingFeedbackWindow',
}

export const REGISTER_CLICK_NAMES = {
  REGISTER_CLICK_NAME: 'registerClicked',
}

export const SHOW_ALL_NEWS_CLICK_NAMES = {
  SHOW_ALL_NEWS_CLICK_NAME: 'showAllNewsClicked',
}

export const SHARE_BY_EMAIL_CLICK_NAMES = {
  SHARE_BY_EMAIL_CLICK_NAME: 'shareByEmailClicked',
}

export const STATISTICS_TAB_CLICK_NAMES = {
  STATISTICS_TAB_CLICK_NAMES: 'tabClicked',
}

export const VIEW_OFFICE_OF_ORIGIN_CLICK_NAMES = {
  VIEW_OFFICE_OF_ORIGIN_CLICK_NAME: 'viewOfficeOfOriginClicked',
}

export const ORIG_LANG_OR_TRANS_CLICK_NAMES = {
  ORIG_LANG_OR_TRANS_CLICK_NAME: 'Clicked',
}

export const SEARCH_SIMILAR_IMAGES_CLICK_NAMES = {
  SEARCH_SIMILAR_IMAGES_CLICK_NAME: 'searchSimilarTMImagesClicked',
}

export const RESULTS_PAGINATION_CLICK_NAMES = {
  RESULTS_PAGINATION_CLICK_NAME: '_resultsPaginationClicked',
}

export const ROUTER_ACTIONS = {
  REPLACE: 'REPLACE',
  POP: 'POP',
  PUSH: 'PUSH',
}

export const EXPORT_ACTIONS = {
  PDF: 'EXPORT PDF',
  WORD: 'EXPORT WORD',
  DOC: 'EXPORT WORD',
  EXCEL: 'EXPORT EXCEL',
  XLS: 'EXPORT EXCEL',
}

export const HISTORY_ACTIONS = {
  HISTORY_ACCESS: 'HISTORY_Access',
  HISTORY_VIEW: 'HISTORY_View',
  HISTORY_COMPARE: 'HISTORY_Compare',
}

export const CONFIGURE_SEARCH_ACTIONS = {
  CONFIGURE_SEARCH_ACTION: 'configureSearchAction',
}

export const COMPARE_ACTIONS = {
  COMPARE_ACTION: 'compareAction',
}

export const RESULTS_VIEW_CHANGE_ACTIONS = {
  RESULTS_VIEW_CHANGE_ACTION: 'resultsViewChange',
}

export const HELP_CLICK_ACTIONS = {
  HELP_CLICK_ACTION: 'helpClickedAction',
}

export const FEEDBACK_ACTIONS = {
  SUBMITTING_FEEDBACK_ACTION: 'yourFeedbackSubmittedAction',
  OPENING_FEEDBACK_WINDOW_ACTION: 'openingFeedbackWindowAction',
}

export const REGISTER_CLICK_ACTIONS = {
  REGISTER_CLICK_ACTION: 'registerClickedAction',
}

export const SHOW_ALL_NEWS_CLICK_ACTIONS = {
  SHOW_ALL_NEWS_CLICK_ACTION: 'showAllNewsClickedAction',
}

export const SHARE_BY_EMAIL_CLICK_ACTIONS = {
  SHARE_BY_EMAIL_CLICK_ACTION: 'shareByEmailClickedAction',
}

export const STATISTICS_TAB_CLICK_ACTIONS = {
  STATISTICS_TAB_CLICK_ACTION: 'statisticsTabClickedAction',
}

export const VIEW_OFFICE_OF_ORIGIN_CLICK_ACTIONS = {
  VIEW_OFFICE_OF_ORIGIN_CLICK_ACTION: 'viewOfficeOfOriginClickedAction',
}

export const ORIG_LANG_OR_TRANS_CLICK_ACTIONS = {
  ORIG_LANG_OR_TRANS_CLICK_ACTION: 'originalLanguageOrTranslationClickedAction',
}

export const SEARCH_SIMILAR_IMAGES_CLICK_ACTIONS = {
  SEARCH_SIMILAR_IMAGES_CLICK_ACTION: 'searchSimilarTMImagesClickedAction',
}

export const RESULTS_PAGINATION_CLICK_ACTIONS = {
  RESULTS_PAGINATION_CLICK_ACTION: 'resultsPaginationAction',
}
