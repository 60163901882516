import cn from 'classnames'
import camel from 'lodash.camelcase'
import React from 'react'

import Icon from '../Icon'
import { Span } from '../Text'
import { Button } from './styles'

export default ({ altText, children, icon, iconAfter, title, type = 'button', ...props }) => {
  const iconName = camel(icon)
  const iconAfterName = camel(iconAfter)
  const isDefault = !props.primary && !props.borderless && !props.link && !props.ghost
  return (
    <Button
      disabled={!!props.disable ? 'disable' : false}
      type={type}
      {...props}
      onlyIcon={!children && !title}
      title={altText}
      withIcon={(icon && 'icon') || (iconAfter && 'iconAfter')}
      className={cn(
        props.className,
        props.active && 'is-active',
        props.ghost && 'is-ghost',
        props.isHoverable && 'is-hoverable',
        isDefault && 'is-default'
      )}
    >
      {icon && <Icon {...{ [iconName]: iconName }} className="button-icon" />}
      {children || (title && <Span className="button-title">{title}</Span>)}
      {iconAfter && <Icon {...{ [iconAfterName]: iconAfterName }} className="button-icon" />}
    </Button>
  )
}
