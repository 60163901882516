import { fullfilled, pending, rejected } from './utils/reduxPromise'
const FETCH_TRANSLATION = 'FETCH_TRANSLATION'
const FETCH_GS_TRANSLATIONS = 'FETCH_GS_TRANSLATIONS'
const FETCH_LOCARNO_TRANSLATIONS = 'FETCH_LOCARNO_TRANSLATIONS'
const FETCH_COMPARE_TRANSLATION = 'FETCH_COMPARE_TRANSLATION'

export const TRANSLATION_TYPES = {
  FETCH_TRANSLATION,
  FETCH_TRANSLATION_FULFILLED: fullfilled(FETCH_TRANSLATION),
  FETCH_TRANSLATION_PENDING: pending(FETCH_TRANSLATION),
  FETCH_TRANSLATION_REJECTED: rejected(FETCH_TRANSLATION),
  FETCH_GS_TRANSLATIONS,
  FETCH_GS_TRANSLATIONS_FULFILLED: fullfilled(FETCH_GS_TRANSLATIONS),
  FETCH_GS_TRANSLATIONS_PENDING: pending(FETCH_GS_TRANSLATIONS),
  FETCH_GS_TRANSLATIONS_REJECTED: rejected(FETCH_GS_TRANSLATIONS),
  FETCH_LOCARNO_TRANSLATIONS_FULFILLED: fullfilled(FETCH_LOCARNO_TRANSLATIONS),
  FETCH_LOCARNO_TRANSLATIONS_PENDING: pending(FETCH_LOCARNO_TRANSLATIONS),
  FETCH_LOCARNO_TRANSLATIONS_REJECTED: rejected(FETCH_LOCARNO_TRANSLATIONS),
  FETCH_COMPARE_TRANSLATION,
  FETCH_COMPARE_TRANSLATION_FULFILLED: fullfilled(FETCH_COMPARE_TRANSLATION),
  FETCH_COMPARE_TRANSLATION_PENDING: pending(FETCH_COMPARE_TRANSLATION),
  FETCH_COMPARE_TRANSLATION_REJECTED: rejected(FETCH_COMPARE_TRANSLATION),
}
