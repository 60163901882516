import React from 'react'
import { withState, lifecycle, compose } from 'recompose'
import PropTypes from 'prop-types'

import { t } from '@app/common/translationsService'

import { Button } from '@new-lucentum'

const MAX_OPTIONS_HEIGHT_DEFAULT = 400
const withSelectedLanguage = withState('selectedLanguage', 'setSelectedLanguage', '')
const lifeCycles = lifecycle({
  componentDidMount() {
    const { languages, originalLanguageCode, noOriginalLanguage = false } = this.props
    const originalLanguage = languages.find(
      language => language.code === originalLanguageCode.toLowerCase()
    )
    if (originalLanguage && originalLanguage.lang) {
      const originalLanguageText = !noOriginalLanguage
        ? prepareOriginalText(originalLanguage.lang)
        : originalLanguage.lang
      this.props.setSelectedLanguage(originalLanguageText)
    }
  },
})

const prepareOriginalText = language => `${language} (${t('detail_tm_gs_original')})`

const TranslationsDropdown = ({
  languages = [],
  maxOptionsHeight = MAX_OPTIONS_HEIGHT_DEFAULT,
  onLanguageSelected,
  originalLanguageCode = 'en',
  noOriginalLanguage = false,
  ...props
}) => {
  originalLanguageCode = originalLanguageCode.toLowerCase()
  return (
    <Button
      alignLeft
      {...props}
      onDropdown={e => {
        const isOriginalLanguage = e.props.id === originalLanguageCode
        onLanguageSelected && onLanguageSelected(e.props.id, isOriginalLanguage)
        props.setSelectedLanguage(e.props.title)
      }}
      title={props.selectedLanguage}
      type="dropdown"
      maxOptionsHeight={maxOptionsHeight}
    >
      {languages.map(({ lang, code }, i) => {
        const isOriginal = code === originalLanguageCode && !noOriginalLanguage
        return <Button key={i} title={isOriginal ? prepareOriginalText(lang) : lang} id={code} />
      })}
    </Button>
  )
}

TranslationsDropdown.propTypes = {
  languages: PropTypes.array,
  onLanguageSelected: PropTypes.func,
}

export default compose(
  withSelectedLanguage,
  lifeCycles
)(TranslationsDropdown)
