import moment from 'moment'
import parseInput from './parseInput'
const DATE_RANGE_MIN_YEAR = 1700
export const getRangeFromPredefinedRangesSource = momentDatesRange => orderRange(momentDatesRange)
// It expects for a object with the keys startDate and endDate containing moment dates
export const orderRange = range =>
  range.startDate.isAfter(range.endDate)
    ? {
        startDate: range.endDate,
        endDate: range.startDate,
      }
    : { ...range }

export const getDatesDataFromKeyboardInput = (
  keyboardInput,
  disableDaysAfterToday,
  selectableEndYear
) => {
  const datesData = {
    range: { startDate: '', endDate: '' },
    errors: [],
    inputValue: keyboardInput,
  }
  /* Be aware that two DatePicker props, placeholder and format, are relationated
   * with the regular expression within regexDateRange
   */
  const regexDateRange = /^(\d{2}\/){2}\d{4}\s?-\s?(\d{2}\/){2}\d{4}$/g
  if (keyboardInput === '') {
    return datesData
  }

  //Check if startDate is valid
  if (keyboardInput.length === 10) {
    let startDate = parseInput(keyboardInput, 'DD/MM/YYYY', 'startOf')
    if (!startDate.isValid()) {
      datesData.errors.push('advanced_search_error_invalid_date_format')
    }
    //Check if typed year is greated than the current
    if (startDate._i.substr(-4) > new Date().getFullYear()) {
      datesData.errors.push('advanced_search_error_invalid_date_format')
    }
  }

  if (!regexDateRange.test(keyboardInput)) {
    // datesData.errors.push('advanced_search_error_invalid_date_format')
  } else {
    // The rest of the validations only can be done once date range string is valid
    datesData.range = getMomentDatesRangeFromDatesRangeString(keyboardInput)
    datesData.errors = getErrorsFromDatesRangeObject(
      datesData.range,
      disableDaysAfterToday,
      selectableEndYear
    )
  }
  return datesData
}

const getMomentDatesRangeFromDatesRangeString = datesRangeString => {
  const momentDatesRange = { startDate: '', endDate: '' }
  datesRangeString
    .replace(/ /g, '')
    .split('-')
    .map((date, i) => {
      i === 0 && (momentDatesRange.startDate = parseInput(date, 'DD/MM/YYYY', 'startOf'))
      momentDatesRange.endDate = parseInput(date, 'DD/MM/YYYY', 'endOf')
    })
  return momentDatesRange
}

export const getErrorsFromDatesRangeObject = (
  datesRangeObject,
  disableDaysAfterToday,
  selectableEndYear
) => {
  const { startDate, endDate } = datesRangeObject
  const errors = []
  if (!startDate.isValid()) {
    errors.push('advanced_search_error_invalid_date')
  }
  if (!startDate.isValid() || !endDate.isValid()) {
    errors.push('advanced_search_error_invalid_date')
  } else {
    //Range only can be checked if dates are valid
    if (startDate.isAfter(endDate)) {
      errors.push('advanced_search_error_date_range')
    }
    if (
      !dateInAllowedRange(startDate, disableDaysAfterToday, selectableEndYear) ||
      !dateInAllowedRange(endDate, disableDaysAfterToday, selectableEndYear)
    ) {
      errors.push('advanced_search_error_invalid_date_range')
    }
  }
  return errors
}

const dateInAllowedRange = (date, disableDaysAfterToday, selectableEndYear) => {
  return date.year() > DATE_RANGE_MIN_YEAR && disableDaysAfterToday
    ? date.isBefore(
        moment()
          .add(1, 'days')
          .startOf('day')
      )
    : moment(date).isBefore(moment([selectableEndYear + 1]))
}

export const stringDatesToStringRange = (x, y = x) => `${x}-${y}`
export const getInputValueFromDatesData = datesData => {
  if (!datesData.range.startDate || !datesData.range.endDate) {
    return ''
  } else {
    const { startDate, endDate } = datesData.range
    const range = momentDatesDataToStringDatesData(datesData.format)({
      startDate: startDate,
      endDate: endDate,
    })
    return stringDatesToStringRange(range.startDate, range.endDate)
  }
}
export const momentDatesDataToStringDatesData = format => ({ startDate, endDate = startDate }) => {
  const toReturn = {
    startDate: startDate ? startDate.format(format).toString() : '',
    endDate: endDate ? endDate.format(format).toString() : '',
  }
  return toReturn
}
