import { Block } from '@new-lucentum'
import styled from 'styled-components'

export const DropdownMenu = styled.div`
  max-width: 350px;
  min-width: 250px;
  box-shadow: 1px 1px 2px 0px #939497;
  background-color: #fff;
`

export const CertificatesItemContainer = styled.div`
  cursor: pointer;
  padding: 7px;
  position: relative;
  &:hover {
    background-color: ${({ theme }) => theme.colorAccent};
  }
  &:hover:before {
    background-color: #2f509a;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
  }
`

export const Label = styled(Block)`
  color: ${({ theme }) => theme.colorGray};
  font-weight: bold;
`

export const Description = styled(Block)`
  font-size: ${({ theme }) => theme.fontSizeSmaller};
  color: ${({ theme }) => theme.colorGrayLight};
`

export const CertificateRequestFormInput = styled.input`
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${({ theme }) => theme.colorGrayAccent} !important;
  color: ${({ theme }) => theme.colorGray};
  font-size: ${({ theme }) => `${theme.fontSizeMedium} !important`};
  height: ${({ theme }) => theme.sizeCuadruple};
  padding: ${({ theme }) => `${theme.sizeSmall} 20px`} !important;
  width: -webkit-fill-available;

  &:focus {
    box-shadow: none !important;
  }
`

export const CertificateRequestFormSelect = styled.select`
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${({ theme }) => theme.colorGrayAccent} !important;
  color: ${({ theme }) => theme.colorGray};
  font-size: ${({ theme }) => `${theme.fontSizeMedium} !important`};
  height: ${({ theme }) => theme.sizeCuadruple};
  padding: ${({ theme }) => `${theme.sizeSmall} 20px`} !important;
  width: 238px;

  &:focus {
    box-shadow: none !important;
  }
`

export const CertificateRequestFormErrorText = styled.div`
  color: #d52d2c;
  font-size: ${({ theme }) => `${theme.fontSizeMedium} !important`};
  max-width: 200px;
`

export const CertificateRequestFormFlexContainer = styled.div`
  display: flex;
  gap: 30px;
`

export const CertificatesPoweredBy = styled.i`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizeSmaller};
  color: ${({ theme }) => theme.colorGrayLight};
  padding: 14px;
`
