import React from 'react'
import { Field } from 'formik'
import { InputFormLCT } from '@app/components/SharedStyles'

export default props => <Field {...props} component={Input} />

const Input = ({
  field: { onChange: onChangeField, ...restField }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onChangeHandler,
  ...props
}) => {
  return (
    <InputFormLCT
      onChange={event => {
        const value = event.target.value
        setFieldValue(restField.name, value)
        onChangeHandler && onChangeHandler(value)
      }}
      {...restField}
      {...props}
    />
  )
}
