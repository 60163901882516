import styled, { css } from 'styled-components'

import Button from '../Button'
import ErrorMessage from '../ErrorMessage'

export const DropdownYear = styled(Button)`
  & > div {
    max-height: 200px;
    overflow-y: auto;
    min-width: 73px;
  }
`

export const Tooltip = styled(ErrorMessage)`
  background-color: ${({ theme }) => theme.colorGrayAccent};
  display: none;
  width: 43%;
  top: ${({ tooltipTop }) => (tooltipTop ? 'auto' : '-20px')};
  ${({ tooltipTop }) => tooltipTop && 'bottom: -20px'};

  &:after {
    ${({ tooltipTop }) => tooltipTop && 'bottom: 28px'};
    border-right-color: ${({ theme }) => theme.colorGrayAccent};
    top: ${({ tooltipTop }) => (tooltipTop ? 'auto' : ' 38px')};
  }
`

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.lineThick};
  box-sizing: border-box;
  color: #000000;
  letter-spacing: 0;
  position: relative;

  & > div:first-child {
    display: flex;
    position: relative;
  }
  &:hover ${Tooltip} {
    display: inline-block;
  }

  & > div:last-child {
    background-color: ${({ theme }) => theme.colorWhite};
    border-radius: ${({ theme }) => theme.radius};
    border: 0;
    box-shadow: ${({ theme }) => theme.dropdownShadow};
    left: 0;
    margin-top: ${({ theme }) => theme.size};
    position: absolute;
    right: 0;
    top: 2.4rem;
    z-index: 1;
  }

  .datepicker-textfield {
    ${({ theme }) => theme.uiText};

    &::placeholder {
      color: #aaa;
    }
  }
  .calendar-block {
    width: 115%;
  }
`

/*TODO improve this function*/
const getBackground = props => {
  if (props.isSelected) return props.theme.color
  if (props.isInRange) return props.theme.colorAccent

  return 'transparent'
}

const getColor = props => {
  if (props.isSelected) return props.theme.colorWhite
  if (props.isInRange || props.isPassive) return props.theme.colorGrayLight

  return props.theme.color
}

export const DayItem = styled.div`
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  display: inline-flex;
  letter-spacing: initial;
  width: 14.27%;
  height: 22px;
  text-align: center;
  font-size: 0.75rem;
  opacity: ${({ isPassive }) => (isPassive ? '0.4' : '1')};
  cursor: pointer;
  transition: transform 0.1s ease;
  color: ${getColor};
  background-color: ${getBackground};
  ${({ isSelected, isInrange, isPassive, theme: { colorAccent } }) =>
    !isSelected && !isInrange && !isPassive ? ` &:hover { background: ${colorAccent};}` : ``};
`

export const WeekDayItem = styled.div`
  letter-spacing: initial;
  text-align: center;
  color: ${({ theme }) => theme.colorGrayLight};
  font-size: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 600;
  text-transform: uppercase;
  width: 14.27%;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const CalendarWrapper = styled.div`
  display: flex;
  margin: 1rem 0 0;
`
export const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MonthArrow = styled.span`
  box-sizing: border-box;
  height: 15px;
  width: 15px;
  padding: 0;
  margin: 0;
  background: transparent;
  float: left;
  font-size: 0.875rem;
  cursor: pointer;
  &.right {
    float: right;
  }
`

export const MonthWrapper = styled.div`
  text-align: center;
  box-sizing: border-box;
  font-size: 0.75rem;
  line-height: 18px;
  button {
    display: block;
    box-sizing: border-box;
    height: 18px;
    width: 18px;
    padding: 0px;
    margin: 0px 10px;
    border: none;
    background: rgb(189, 195, 199);
    box-shadow: none;
    outline: none;
    border-radius: 50%;
    float: left;
  }
`

export const Flex = styled.div`
  display: flex;
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

`
export const Label = styled.span`
  font-weight: bold;
  display: block;
`

export const ListRanges = styled.div`
  overflow-wrap: break-word;
  overflow-x: hidden;
  padding-right: 5px;
  span {
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0.2rem;
  }
  button {
    display: block;
    padding: 0.8rem 1rem;
    border-radius: 2px;
    width: 100%;
    text-align: left;
    &:not(:first-child) {
      margin-left: 0;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: ${({ theme: { colorAccent } }) => colorAccent};
      color: ${({ theme: { colorGray } }) => colorGray};
      border-left: 4px ${({ theme: { color } }) => color} solid;
    }
  }
`

export const ListButton = styled(Button)`
  ${({ active, theme: { color, colorGray, colorAccent } }) =>
    active
      ? ` &:hover { background: ${colorAccent};}
     background-color: ${colorAccent};;
     color: ${colorGray};
     border-left: 4px ${color} solid;
     `
      : ``};
`
