import { Divider } from '@app/components/Divider'
import { Button, Flex } from '@new-lucentum'
import React from 'react'
import styled, { css } from 'styled-components'
import debounce from 'lodash.debounce'

const StyledActionBarExpandable = styled.div`
  background-color: ${({ theme }) => theme.colorLighter};
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 5px 10px;
  padding-right: 20px;

  ${({ variant }) =>
    variant === 'details' &&
    css`
      background-color: #e7e8ea;
    `}

  &.is-sticky {
    background-color: ${({ theme }) => theme.color};
    color: white;

    i {
      color: white;
    }

    button.is-ghost:hover {
      border-color: white;
    }

    button.is-active {
      border-color: white;
    }

    .button-icon {
      color: white;
      i {
        color: white;
      }
    }

    .is-default {
      border-color: white;
    }

    .dropdown-menu {
      color: ${({ theme }) => theme.colorGray};
      i {
        color: ${({ theme }) => theme.colorGray};
      }
      .button-icon {
        color: white;
      }
      button.is-default:not(:hover) {
        border-color: ${({ theme }) => theme.color};
        .button-title {
          color: ${({ theme }) => theme.color};
        }
      }
    }

    .dropdown-parent {
      color: ${({ theme }) => theme.colorGray};

      > button:not(.is-ghost) {
        border-color: white;
      }

      .dropdown-options {
        .button-title {
          color: ${({ theme }) => theme.colorGray};
        }
        i {
          color: ${({ theme }) => theme.colorGray};
        }
      }
    }

    .button-title {
      color: white;
    }
  }
`

export const ActionBarExpandable = ({ children, ...props }) => {
  const [isExpanded, setIsExpanded] = React.useState(true)
  const expanderIcon = isExpanded ? 'iconCompress' : 'iconExpand'
  // const [isExpanded, setIsExpanded] = React.useState(true)
  const [isCollapsedByUser, setIsCollapsedByUser] = React.useState(false)
  const [scrollTop, setScrollTop] = React.useState(0)
  const [isScrolledDown, setIsScrolledDown] = React.useState(false)

  const toggleExpanded = React.useCallback(
    () => {
      setIsCollapsedByUser(isExpanded)
      setIsExpanded(!isExpanded)
    },
    [isExpanded]
  )

  // Automatically collapse/expand action bar on scroll if it wasn't collapsed manually by the user
  React.useEffect(
    () => {
      const onScroll = debounce(e => {
        setScrollTop(e.target.documentElement.scrollTop)
        setIsScrolledDown(e.target.documentElement.scrollTop > 120)
      }, 10)
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    },
    [scrollTop]
  )
  React.useEffect(
    () => {
      if (!isCollapsedByUser) {
        setIsExpanded(!isScrolledDown)
      }
    },
    [isScrolledDown]
  )
  return (
    <StyledActionBarExpandable {...props} id="action-bar-expandable">
      {children({ isExpanded })}
      <Flex alignItems="center">
        <Divider />
        <Button
          toggleExpanded={toggleExpanded}
          borderless
          isExpanded={isExpanded}
          icon={expanderIcon}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        />
      </Flex>
    </StyledActionBarExpandable>
  )
}
