import { BASIC_RECORD_FIELDS } from '@app/common/dataManagers/types'

export const recordFields = [
  {
    label: 'tm.details.label.recordReference.key',
    path: BASIC_RECORD_FIELDS.REFERENCE,
  },
  {
    label: 'tm.details.label.RecordFilingDate.key',
    path: BASIC_RECORD_FIELDS.RECORD_FILING_DATE,
    isDate: true,
  },
  {
    label: 'tm.details.label.RecordNotificationDate.key',
    path: BASIC_RECORD_FIELDS.RECORD_NOTIFICATION_DATE,
    isDate: true,
  },
  {
    label: 'tm_details_label_recordEffectiveDate',
    path: BASIC_RECORD_FIELDS.RECORD_EFFECTIVE_DATE,
    isDate: true,
  },
  {
    label: 'tm.details.label.publicationDate.key',
    path: BASIC_RECORD_FIELDS.RECORD_PUBLICATION_DATE,
    isDate: true,
  },
  {
    label: 'tm.details.label.recordal.factdecision.key',
    path: BASIC_RECORD_FIELDS.FACT_DECISION_TEXT,
  },
  {
    label: 'tm.details.label.RecordInterestedOfficeCode.key',
    path: BASIC_RECORD_FIELDS.RECORD_INTERESTED_OFFICE_CODE,
  },
  {
    label: 'tm.details.label.RecordUseIntentCountryCode.key',
    path: BASIC_RECORD_FIELDS.RECORD_USE_INTENT_COUNTRY_CODE,
  },
  {
    label: 'tm.details.label.RecordPublication.key',
    path: BASIC_RECORD_FIELDS.RECORD_PUBLICATION,
  },
  {
    label: 'tm.details.label.oppositionPeriodStartDate.key',
    path: BASIC_RECORD_FIELDS.RECORD_OPPOSITION_PERIOD_START_DATE,
    isDate: true,
  },
  {
    label: 'tm.details.label.oppositionPeriodEndDate.key',
    path: BASIC_RECORD_FIELDS.RECORD_OPPOSITION_PERIOD_END_DATE,
    isDate: true,
  },
]
