import React, { Component } from 'react'

import Fieldset from '../Fieldset'
import Form from '../Form'
import Block from '../Block'
import Button from '../Button'
import Select from '../Select'

import Option from './Option'
import { keys, getOptions } from './utils'

const TYPE_SELECT = 'select'

export default class Filters extends Component {
  state = { show: [] }

  static defaultProps = {
    explicitRender: true,
    showNumOptions: 3,
    src: [],
    textResults: ' Results',
    textSelectFilter: 'Select a filter...',
    textShowAll: 'Show all ',
    textShowLess: 'Show less',
  }

  toggle = key =>
    this.setState(({ show }) => ({
      show: show.includes(key)
        ? [...show.slice(0, show.indexOf(key)), ...show.slice(show.indexOf(key) + 1)]
        : show.concat(key),
    }))

  render = () => {
    const { show } = this.state
    const {
      children,
      explicitRender,
      onSelectFilter,
      showNumOptions,
      src,
      textResults,
      textSelectFilter,
      textShowAll,
      textShowLess,
      selectCustomEvents,
    } = this.props
    return (
      <Form>
        {keys(explicitRender, src, children).map(
          ({ labelKey: key, label = key, data = [], type, labelKey }) => (
            <Fieldset data-test-id={`test-${labelKey}`} bold key={key} label={label}>
              {(() => {
                switch (type) {
                  case TYPE_SELECT:
                    return (
                      <Block>
                        <Select
                          multi
                          options={data}
                          value={data.filter(x => x.checked)}
                          placeholder={textSelectFilter}
                          closeOnSelect={false}
                          onChange={opt =>
                            (selectCustomEvents &&
                              selectCustomEvents.onSelectFilter &&
                              selectCustomEvents.onSelectFilter(opt, data, key)) ||
                            (onSelectFilter && onSelectFilter(opt, data, key))
                          }
                          onBlur={(e, values) =>
                            selectCustomEvents &&
                            selectCustomEvents.onBlurFilter &&
                            selectCustomEvents.onBlurFilter(e, values, data, key)
                          }
                        />
                      </Block>
                    )
                  default:
                    return (
                      <Block>
                        {getOptions(explicitRender, src)(key, data)
                          .slice(
                            0,
                            show.includes(key) && explicitRender ? data.length : showNumOptions
                          )
                          .map((option, index) => (
                            <Option
                              key={index}
                              {...option}
                              onChange={e => onSelectFilter && onSelectFilter(e, option, key)}
                            />
                          ))}
                        {!show.includes(key) && data.length > showNumOptions && (
                          <Button link onClick={() => this.toggle(key)}>
                            {textShowAll}
                            {data.length}
                            {textResults}
                          </Button>
                        )}
                        {show.includes(key) && data.length > showNumOptions && (
                          <Button link onClick={() => this.toggle(key)}>
                            {textShowLess}
                          </Button>
                        )}
                      </Block>
                    )
                }
              })()}
            </Fieldset>
          )
        )}
      </Form>
    )
  }
}
