import React from 'react'
import ContentLoader from 'react-content-loader'

export default ({ height = 62, width = 400 }) => {
  return (
    <div style={{ width, height, display: 'flex' }}>
      <ContentLoader
        height={height}
        width={width}
        speed={1}
        primaryColor="#d4d4d4"
        secondaryColor="#ecebeb"
      >
        <rect x="15" y="7" rx="5" ry="5" width="40" height="45" />
        <rect x="68" y="7" rx="5" ry="5" width="42" height="45" />
        <rect x="205" y="25" rx="5" ry="5" width="60" height="15" />
        <rect x="357" y="7" rx="5" ry="5" width="40" height="45" />
      </ContentLoader>
    </div>
  )
}
