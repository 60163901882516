import React from 'react'
import { connect } from 'react-redux'
import { t } from '@app/common/translationsService'
import { Block, Modal } from '@new-lucentum'
import { DSV_APPLICATION_CONTEXT, KEY_INDICATION_OF_PRODUCT } from '@app/common/constants'
import { headerColumns } from '@app/containers/TMResults/utils'
import TranslationsProvidedBy from './TranslationsProvidedBy'
import IndicationOfProductText from './IndicationOfProductText'
import TranslatedIndicationOfProduct from './TranslatedIndicationOfProduct'
import { ScrollBlock } from './styles'

const mapStateToProps = ({
  globals: {
    data: { languages },
  },
}) => ({ languages })

const withConnect = connect(
  mapStateToProps,
  null
)

export default withConnect(
  ({
    isOpen,
    ST13,
    originalLanguage,
    languageSelected,
    indicationOfProductText,
    indicationOfProductText2,
    arrayTranslatedTerms,
    languages,
    textStyle,
    error,
    onCloseModal,
  }) => {
    const getLanguageText = languageCode => {
      const languageObject = languages.find(
        ({ code }) => code.toLowerCase() === languageCode.toLowerCase()
      )
      return (languageObject && languageObject.lang) || ''
    }
    const labels = headerColumns(DSV_APPLICATION_CONTEXT)
    const title = `${labels[KEY_INDICATION_OF_PRODUCT]} - ${t(
      'translate_indication_of_product_compare'
    )}`
    const originalLanguageText = getLanguageText(originalLanguage)
    const languageText = getLanguageText(languageSelected)
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        title={title}
        modalSize="large"
        ariaHideApp={false}
      >
        <Block alignContents="right">
          <TranslationsProvidedBy padding auto language={languageSelected} />
        </Block>
        <Block width6of12 padding>
          <strong>{`${originalLanguageText} (${t('detail_tm_gs_original')})`}</strong>
        </Block>
        <Block width6of12 padding>
          <strong>{languageText}</strong>
        </Block>
        <ScrollBlock height="40vh">
          <Block padding>
            <Block width6of12 paddingRight>
              <IndicationOfProductText
                ST13={ST13}
                text={indicationOfProductText}
                textStyle={textStyle}
                error={error}
              />
            </Block>
            <Block width6of12 paddingLeft>
              {indicationOfProductText2 ? (
                <IndicationOfProductText
                  ST13={ST13}
                  text={indicationOfProductText2}
                  textStyle={textStyle}
                  error={error}
                />
              ) : (
                Array.isArray(arrayTranslatedTerms) &&
                !!arrayTranslatedTerms.length && (
                  <TranslatedIndicationOfProduct
                    ST13={ST13}
                    translatedTerms={arrayTranslatedTerms}
                    textStyle={textStyle}
                  />
                )
              )}
            </Block>
          </Block>
        </ScrollBlock>
      </Modal>
    )
  }
)
