/* eslint-disable import/no-anonymous-default-export */
import { DETAILS_TYPES, TRANSLATION_TYPES } from '@app/redux-types'

const defaultState = {
  data: {},
  fetching: false,
  fetched: false,
  error: null,
  gsTranslateFetching: [],
  gsTranslateFetched: [],
  gsTranslateError: [],
  gsTranslated: [],
  officeMetadata: { isBlockChain: false },
}

const defaultEmptyDetail = {
  tradeMark: {},
  applicants: [],
  representatives: [],
  correspondenceAddress: {},
  recordals: {},
  renewals: [],
  goodsAndServicesList: [],
}

export default (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case DETAILS_TYPES.FETCH_DETAIL_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      }
    case DETAILS_TYPES.FETCH_DETAIL_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: {
          ...defaultEmptyDetail,
          ...payload,
        },
        gsTranslated: [],
      }
    case DETAILS_TYPES.FETCH_DETAIL_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        data: { ...defaultEmptyDetail },
        gsTranslated: [],
        error: true,
      }
    case DETAILS_TYPES.INIT_DETAIL_NAVIGATION_DATA:
      return {
        ...state,
        trademarkId: payload.trademarkId,
        navigationData: payload.navigationData,
      }
    case DETAILS_TYPES.RESET_DETAIL_NAVIGATION_DATA:
      return {
        ...state,
        trademarkId: null,
        navigationData: null,
      }
    case DETAILS_TYPES.SET_DETAIL_TRADEMARK_ID:
      return {
        ...state,
        trademarkId: payload,
      }
    case 'CLEAR_GOODS_SERVICES_TRANSLATIONS':
      return {
        ...state,
        gsTranslated: [...state.gsTranslated].filter(
          translation => translation.classificationKind !== payload.classificationKind
        ),
      }
    case TRANSLATION_TYPES.FETCH_TRANSLATION_PENDING:
      return {
        ...state,
        gsTranslateFetching: [...state.gsTranslateFetching, meta.niceClass],
        gsTranslateFetched: state.gsTranslateFetched.filter(gs => gs !== meta.niceClass),
        gsTranslateError: state.gsTranslateError.filter(gs => gs !== meta.niceClass),
      }
    case TRANSLATION_TYPES.FETCH_TRANSLATION_FULFILLED:
      const gs = {
        ...payload,
        ...meta,
      }
      const filteredGsTranslated = [...state.gsTranslated].filter(translation => {
        return translation.niceClass !== gs.niceClass
      })
      return {
        ...state,
        gsTranslateFetching: state.gsTranslateFetching.filter(gs => gs !== meta.niceClass),
        gsTranslateFetched: [...state.gsTranslateFetched, meta.niceClass],
        gsTranslateError: state.gsTranslateError.filter(gs => gs !== meta.niceClass),
        gsTranslated: [...filteredGsTranslated, gs],
      }
    case TRANSLATION_TYPES.FETCH_TRANSLATION_REJECTED:
      const gsError = {
        ...meta,
        error: true,
      }
      return {
        ...state,
        gsTranslateFetching: state.gsTranslateFetching.filter(gs => gs !== meta.niceClass),
        gsTranslateFetched: state.gsTranslateFetched.filter(gs => gs !== meta.niceClass),
        gsTranslateError: [...state.gsTranslateError, meta.niceClass],
        gsTranslated: [...state.gsTranslated, gsError],
      }
    case DETAILS_TYPES.SET_OFFICE_METADATA:
      return {
        ...state,
        officeMetadata: payload,
      }
    default:
      return state
  }
}
