import { fullfilled, pending, rejected } from './utils/reduxPromise'

const GET_SETTINGS = 'GET_SETTINGS'
const EDIT_SETTINGS = 'EDIT_SETTINGS'

export const SETTINGS_TYPES = {
  GET_SETTINGS,
  GET_SETTINGS_FULFILLED: fullfilled(GET_SETTINGS),
  GET_SETTINGS_PENDING: pending(GET_SETTINGS),
  GET_SETTINGS_REJECTED: rejected(GET_SETTINGS),
  EDIT_SETTINGS,
  EDIT_SETTINGS_FULFILLED: fullfilled(EDIT_SETTINGS),
  EDIT_SETTINGS_PENDING: pending(EDIT_SETTINGS),
  EDIT_SETTINGS_REJECTED: rejected(EDIT_SETTINGS),
}
