/*
 General
 --------------------------------------------------
 Use this to add CSS rules according to the most generic html elements like
 `html`, `body` etc. and to override bootstrap.

 require("file?name=[path][name].[ext]?[hash]!../../img/bg.png");
*/

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 ------------------------
 Centering in the unknown
 https://css-tricks.com/centering-in-the-unknown/
 */

.v-centered-parent {
  /* May want to do this if there is risk the container
    may be narrower than the element inside */
  /* white-space: nowrap; */
  /* The ghost, nudged to maintain perfect centering */
  /* The element to be centered */
  & .v-centered {
    display: inline-block;
    vertical-align: middle;
  }
}

fieldset legend {
  border: none;
  font-size: inherit;
}

.white-color {
  color: white !important;
}
