import { NEWS_TYPES } from '@app/redux-types/news'
import { RESULTS_TYPES } from '@app/redux-types/results'

const defaultState = {
  data: [],
  fetching: false,
  fetched: false,
  error: null,
  importantNewsBannerVisible: true,
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case RESULTS_TYPES.FETCH_NEWS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }
    case RESULTS_TYPES.FETCH_NEWS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }
    case RESULTS_TYPES.FETCH_NEWS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: payload,
      }
    case NEWS_TYPES.HIDE_NEWS_BANNER:
      return {
        ...state,
        importantNewsBannerVisible: false,
      }
    default:
      return state
  }
}
