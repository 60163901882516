import React from 'react'
import { Checkbox, Link } from '@new-lucentum'
import { t } from '@app/common/translationsService'
import { useDidMount } from '@app/common/hooks'

export const AdvancedOptions = ({
  onChange = () => {},
  initialValues = { colour: false, ocr: false },
}) => {
  const colourText = t('image_search_searched_only_for_colour_key').split('##')
  const isMount = useDidMount()
  const [colour, setColour] = React.useState(initialValues.colour)
  const [ocr, setOcr] = React.useState(initialValues.ocr)

  React.useEffect(
    () => {
      if (isMount) {
        const values = {
          colour,
          ocr,
        }
        onChange(values)
      }
    },
    [colour, ocr]
  )

  return (
    <React.Fragment>
      <Checkbox
        label={
          <React.Fragment>
            {colourText[0]}
            <Link to="https://euipo.europa.eu/ohimportal/en/trade-mark-definition" target="_blank">
              {colourText[1]}
            </Link>
            {colourText[2]}
          </React.Fragment>
        }
        value="Colour"
        onChange={e => {
          const isChecked = e.target.checked
          setColour(isChecked)
        }}
        checked={colour}
      />
      <Checkbox
        label={t('image_search_use_ocr')}
        value="Ocr"
        onChange={e => {
          const isChecked = e.target.checked
          setOcr(isChecked)
        }}
        checked={ocr}
      />
    </React.Fragment>
  )
}
