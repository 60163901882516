import { TMDSAuthService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { Button, Grid } from '@new-lucentum'
import * as React from 'react'

import ModalMobile from '../new-lucentum/components/Modal/Modal.mobile'

export const LoginRequiredModal = ({ isModalOpen, setIsModalOpen, title }) => {
  const prepareTitle = () => {
    return <div dangerouslySetInnerHTML={{ __html: title }} />
  }

  return (
    <ModalMobile
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      showCloseButton={false}
      customTitle={prepareTitle()}
    >
      <Button
        onClick={() => {
          TMDSAuthService.doLoginCas()
        }}
        title={t('header_sign_in_key')}
        style={{ width: '100%' }}
      />
      <Grid.Spacing mb={1} />
      <Button
        title={t('registration.key')}
        style={{ width: '100%' }}
        onClick={() => {
          TMDSAuthService.doRegisterCas()
        }}
      />
      <Grid.Spacing mb={1} />
      <Button
        primary
        onClick={() => {
          setIsModalOpen(false)
        }}
        title={t('cancel.key')}
        style={{ width: '100%' }}
      />
    </ModalMobile>
  )
}
