import * as React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

import Tooltip from '../Tooltip'

const StyledTooltip = styled(Tooltip)`
  &.place-bottom:before,
  &.place-bottom:after {
    ${({ cornerLeft }) => cornerLeft && `left: ${cornerLeft};`}
  }
  max-width: ${({ maxWidth }) => maxWidth || '300px'};
`

function useDidMount() {
  const mountRef = React.useRef(false)

  React.useEffect(() => {
    mountRef.current = true
  }, [])

  return mountRef.current
}

const TooltipTriggerComponent = ({
  children,
  tooltipId,
  place,
  offset,
  cornerLeft,
  renderTooltip,
  delayHide,
  clickable,
  className,
  wrapperStyle,
  openTooltip,
  ...props
}) => {
  const isMounted = useDidMount()
  const tooltipRef = React.useRef(null)
  React.useEffect(
    () => {
      if (isMounted) {
        if (openTooltip) {
          ReactTooltip.show(tooltipRef.current)
        } else {
          ReactTooltip.hide(tooltipRef.current)
        }
      }
    },
    [openTooltip]
  )

  return (
    <div
      ref={tooltipRef}
      data-for={tooltipId}
      data-tip
      {...className && { className }}
      {...wrapperStyle && { style: wrapperStyle }}
    >
      {children}
      {renderTooltip && (
        <StyledTooltip
          id={tooltipId}
          place={place}
          offset={offset}
          cornerLeft={cornerLeft}
          delayHide={delayHide}
          clickable={clickable}
        >
          {renderTooltip}
        </StyledTooltip>
      )}
    </div>
  )
}

export const TooltipTrigger = TooltipTriggerComponent
