import Api from '@app/common/api'
import TMDSContextService, { isTmview } from '@app/common/tmdsContextService'
import { getCurrentLanguage } from '@app/reducers/uiState/language'

const api = new Api()

const SEARCH_PATH = '/search'
const PATHS = {
  SEARCH: {
    dsview: 'dsv/results',
    tmview: 'results',
  },
  SEARCH_FILTER: {
    dsview: 'dsv/filter',
    tmview: 'filter',
  },
  AUTOCOMPLETE: {
    dsview: 'dsv/autocomplete', //TODO:
    tmview: 'autocomplete',
  },
  ST13S: {
    dsview: 'dsv/st13s',
    tmview: 'st13s',
  },
  DETAILS: {
    dsview: 'design',
    tmview: 'trademark',
  },
  EXPORT: {
    dsview: 'export',
    tmview: 'export',
  },
  IMAGE_SEARCH: {
    dsview: 'imageSearch',
    tmview: 'imageSearch',
  },
  OFFICE_EXCEPTION_MESSAGES: {
    dsview: 'officeExceptionMessages/dsv',
    tmview: 'officeExceptionMessages',
  },
}

const noCache = {
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: 0,
  },
}

const context = TMDSContextService.get

const exportContext = () => {
  if (context() === 'tmview') {
    return 'tm'
  } else {
    return 'ds'
  }
}

const doSearch = (query, queryObject) => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH[context()]}`
  const options = { body: queryObject }
  return api.post(url, options)
}

const doSearchBySt13s = (st13List, filterParams) => {
  const url = `${SEARCH_PATH}/${PATHS.ST13S[context()]}`
  const options = {
    body: {
      st13s: st13List,
      ...filterParams,
    },
  }
  return api.post(url, options)
}

const uploadImageSearch = file => {
  const url = `${PATHS.IMAGE_SEARCH[context()]}/uploadAndSegments`
  return api.uploadFiles(url, file)
}

const abortableUploadImageSearch = file => {
  const url = `${PATHS.IMAGE_SEARCH[context()]}/${isTmview() ? 'tm' : 'ds'}/uploadAndSegments`
  return api.abortableUploadFiles(url, file)
}

const getAutocompleteBasicSearch = text => {
  const url = `${SEARCH_PATH}/${
    PATHS.AUTOCOMPLETE[context()]
  }/basicSearch?text=${encodeURIComponent(text)}`
  return api.get(url)
}

const getAutocompleteApplicantName = text => {
  const url = `${SEARCH_PATH}/${
    PATHS.AUTOCOMPLETE[context()]
  }/applicantName?text=${encodeURIComponent(text)}`
  return api.get(url)
}

const getAutocompleteApplicationNumber = text => {
  const url = `${SEARCH_PATH}/${PATHS.AUTOCOMPLETE[context()]}/appNumber?text=${encodeURIComponent(
    text
  )}`
  return api.get(url)
}

const getAutocompleteRegistrationNumber = text => {
  const url = `${SEARCH_PATH}/${PATHS.AUTOCOMPLETE[context()]}/regNumber?text=${encodeURIComponent(
    text
  )}`
  return api.get(url)
}

const getAutocompleteProductIndicationName = text => {
  const url = `${SEARCH_PATH}/${
    PATHS.AUTOCOMPLETE[context()]
  }/indicationOfProduct?text=${encodeURIComponent(text)}&language=${encodeURIComponent(
    getCurrentLanguage('language')
  )}`
  return api.get(url)
}

const getAutocompleteDesignNumber = text => {
  const url = `${SEARCH_PATH}/${
    PATHS.AUTOCOMPLETE[context()]
  }/designNumber?text=${encodeURIComponent(text)}`
  return api.get(url)
}

const getAutocompleteDesignerName = text => {
  const url = `${SEARCH_PATH}/${
    PATHS.AUTOCOMPLETE[context()]
  }/designerName?text=${encodeURIComponent(text)}`
  return api.get(url)
}

const getAutocompleteRepresentativeName = text => {
  const url = `${SEARCH_PATH}/${
    PATHS.AUTOCOMPLETE[context()]
  }/representativeName?text=${encodeURIComponent(text)}`
  return api.get(url)
}

const getAutocompleteOwnerName = text => {
  const url = `${SEARCH_PATH}/${PATHS.AUTOCOMPLETE[context()]}/ownerName?text=${encodeURIComponent(
    text
  )}`
  return api.get(url)
}

const getAutocompletePreviousOwnerName = text => {
  const url = `${SEARCH_PATH}/${
    PATHS.AUTOCOMPLETE[context()]
  }/previousOwnerName?text=${encodeURIComponent(text)}`
  return api.get(url)
}

const changeLanguage = language => {
  const url = `${SEARCH_PATH}/${PATHS.LANGUAGE[context()]}`
  const payload = { language: language }
  const options = {
    body: payload,
    ...noCache,
  }
  return api.put(url, options)
}

const getResourceDetails = (id, translate = false) => {
  const url = `/${PATHS.DETAILS[context()]}/detail/${id}?translate=${translate}`
  return api.get(url)
}

const getResourceImageForSearch = id => {
  const url = `${PATHS.DETAILS[context()]}/${id}/imageSearch`
  return api.get(url)
}

const doExport = (type, payload) => {
  const getPayload = () => {
    let modifiedPayload = payload
    if (
      payload.query !== null &&
      typeof payload.query === 'object' &&
      (payload.query.viennaCodeVersion || payload.query.fTags)
    ) {
      const { viennaCodeVersion, fTags, ...queryWithoutViennaCodeVersion } = payload.query
      modifiedPayload = { ...payload, query: queryWithoutViennaCodeVersion }
    }

    if (window.location.href.includes('alerts') || window.location.href.includes('tags')) {
      return { ...modifiedPayload, type: 'RESULTS' }
    } else {
      return modifiedPayload
    }
  }

  const url = `/${PATHS.EXPORT[context()]}/${exportContext()}/${type}`
  const options = {
    body: getPayload(),
    responseWithHeaders: true,
  }
  return api.post(url, options)
}

const doExportResource = (resource, type, payload) => {
  const url = `/${PATHS.EXPORT[context()]}/${exportContext()}/${type}`
  const options = {
    body: payload,
    responseWithHeaders: true,
  }
  return api.post(url, options)
}

const doExportHistory = ({
  id,
  resource,
  documentType,
  recaptchaResponseKey,
  historyId,
  targetHistoryId,
  pageSize = 150,
}) => {
  let url = `/export/${exportContext()}/${documentType}?pageSize=${pageSize}`
  const body = isTmview()
    ? {
        recaptchaKey: recaptchaResponseKey,
        type: 'HISTORY',
        selectedTrademarks: id,
      }
    : {
        recaptchaKey: recaptchaResponseKey,
        type: 'HISTORY',
        selectedDesigns: id,
      }

  if (historyId && targetHistoryId) {
    body.historyId = historyId
    body.targetHistoryId = targetHistoryId
  }

  const headers = {
    Accept:
      documentType === 'pdf'
        ? 'application/pdf'
        : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  }

  const options = {
    body,
    headers,
    responseWithHeaders: true,
  }
  return api.post(url, options)
}

const doExportStatistics = (type, payload) => {
  const url = `/${PATHS.EXPORT[context()]}/${exportContext()}/${type}`
  const options = {
    body: payload,
    responseWithHeaders: true,
  }
  return api.post(url, options)
}

const getOfficeExceptionMessages = officeCode => {
  const url = `/${PATHS.OFFICE_EXCEPTION_MESSAGES[context()]}/${officeCode}`
  return api.get(url)
}

/* FILTERS */
const getApplicantNameFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/applicantNames`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getTrademarkTypeFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/trademarkTypes`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}
const getOfficeFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/offices`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getGoodsAndServicesFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/goodsAndServices`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getTerritoryFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/territories`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getTrademarkStatusFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/trademarkStatuses`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getViennaCodeFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/viennaCodes`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getApplicantNameOfficesFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/applicantNamesOffices`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getOwnerNameFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/ownerNames`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getDesignStatusFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/statuses`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getDesignerNameFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/designerNames`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getDesignNumberFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/designNumbers`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getLocarnoFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/locarnoClassifications`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getIndicationOfProductFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/indicationOfProducts`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getOwnerNameOfficesFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/ownerNamesOffices`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getSpecialFilters = () => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/special`
  return api.get(url)
}

const getApplicantNameNiceClassesFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/applicantNamesNiceClasses`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

const getOwnerNameLocarnoClassesFilter = searchData => {
  const url = `${SEARCH_PATH}/${PATHS.SEARCH_FILTER[context()]}/ownerNamesLocarnoClasses`
  const options = {
    body: searchData,
  }
  return api.post(url, options)
}

export const searchDataManager = {
  doSearch,
  doSearchBySt13s,
  doExport,
  doExportResource,
  doExportStatistics,
  doExportHistory,
  getAutocompleteBasicSearch,
  getAutocompleteApplicantName,
  getAutocompleteApplicationNumber,
  getAutocompleteRegistrationNumber,
  getResourceDetails,
  changeLanguage,
  uploadImageSearch,
  abortableUploadImageSearch,
  getResourceImageForSearch,
  getAutocompleteProductIndicationName,
  getAutocompleteDesignNumber,
  getAutocompleteDesignerName,
  getAutocompleteRepresentativeName,
  getAutocompleteOwnerName,
  getAutocompletePreviousOwnerName,
  getOfficeExceptionMessages,
  /* Filters */
  getApplicantNameFilter,
  getTrademarkTypeFilter,
  getOfficeFilter,
  getGoodsAndServicesFilter,
  getTerritoryFilter,
  getTrademarkStatusFilter,
  getViennaCodeFilter,
  getApplicantNameOfficesFilter,
  getApplicantNameNiceClassesFilter,
  getSpecialFilters,
  /* Filter dsview */
  getOwnerNameFilter,
  getDesignStatusFilter,
  getDesignerNameFilter,
  getDesignNumberFilter,
  getLocarnoFilter,
  getIndicationOfProductFilter,
  getOwnerNameOfficesFilter,
  getOwnerNameLocarnoClassesFilter,
}
