import { addThousands } from '@app/common/utilities'
import { isRTL } from '@new-lucentum/components/ThemeProvider/utils.js'
import React from 'react'

import Icon from '../Icon'
import Link from '../Link'
import RecordsNavigationStyled from './styles'

const LINK_BACK = 'back'
const LINK_PREVIOUS = 'previous'
const LINK_NEXT = 'next'

export default ({
  onRecordChange,
  pageKey,
  pageTotalKey,
  backLiteral,
  isMobile = false,
  ...props
}) => {
  const rtl = isRTL({})
  const forward = rtl ? <Icon chevron-left /> : <Icon chevron-right />
  const backward = rtl ? <Icon chevron-right /> : <Icon chevron-left />

  return (
    <RecordsNavigationStyled {...props}>
      {/* <Button
      onClick={e => onRecordChange(e, LINK_BACK)}
      altText={backLiteral}
      className={'back'}
      link={true}
      Primary={true}
    >
      {backLiteral}
    </Button> */}
      <Link
        href="#"
        disabled={pageKey === 1}
        onClick={e => pageKey > 1 && onRecordChange(e, LINK_PREVIOUS)}
      >
        {backward}
      </Link>
      {!isMobile && (
        <span>
          {addThousands(pageKey)} / {addThousands(pageTotalKey)}
        </span>
      )}
      {isMobile && <span>{'  '}</span>}
      <Link
        href="#"
        disabled={pageKey === pageTotalKey}
        onClick={e => pageKey < pageTotalKey && onRecordChange(e, LINK_NEXT)}
      >
        {forward}
      </Link>
    </RecordsNavigationStyled>
  )
}
