import styled from 'styled-components'
import { Block, Icon, Label, TooltipWrapper } from '@new-lucentum'
import { PropTypes } from 'prop-types'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'

export const BlockTooltip = styled(Block)`
  max-width: 300px;
`
export const BlockTooltipExtended = styled(Block)`
  white-space: pre-wrap;
`
export const TooltipWrapperExtended = styled(TooltipWrapper)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`
export const ListContainer = styled.div`
  position: absolute;
  width: 100%;
`
export const StyledAsyncTypeahead = styled(AsyncTypeahead)`
  height: ${({ theme }) => theme.sizeCuadruple};
  position: relative;
  z-index: ${({ showMenu }) => (showMenu ? 100 : 'inherit')};

  *::-webkit-scrollbar {
    width: 13px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color};
    border-right: 10px solid white;
  }

  input::placeholder {
    color: ${({ theme }) => theme.color.gray};
  }

  .rbt-input-hint,
  .rbt-input-main {
    ${({ theme }) => theme.uiText};
    padding-left: 5px !important;
  }

  .rbt-input {
    line-height: 0;
    overflow: hidden;
    margin-bottom: 2px;
  }
  .rbt-menu {
    list-style-type: none;
    margin: 0;
    margin-top: ${({ theme }) => theme.size};
    background-color: #ffffff;
    padding: 0;
    border-radius: 2px;
    box-shadow: ${({ theme }) => theme.focusShadow};
    > li {
      padding-top: ${({ theme }) => theme.sizeSmall};
      padding-bottom: ${({ theme }) => theme.sizeSmall};
      border-bottom: 1px solid ${({ theme }) => theme.colorLighter};
    }
  }
  .rbt-aux {
    position: absolute;
    top: 22px;
    right: 0;
  }
  .rbt-close {
    z-index: ${({ focus }) => (focus ? 100 : 'inherit')};
    background-color: inherit;
    font-size: ${({ theme }) => theme.fontSizeMedium};
    cursor: pointer;
    color: gray;
    border-style: none;
    height: 22px;
    background-color: transparent;
    .sr-only {
      display: none;
    }
  }

  .rbt-sr-status {
    display: none !important;
  }
`

export const StyledMenu = styled(Menu)`
  .rbt-menu > li span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rbt-menu > li span:nth-child(1) {
    width: 35%;
  }
  .rbt-menu > li span:nth-child(2) {
    width: 22%;
  }
  .rbt-menu > li span:nth-child(3) {
    width: 22%;
  }
  .rbt-menu > li span:nth-child(4) {
    width: 21%;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  ${({ theme }) => theme.baseText} :hover {
    ${({ theme }) => theme.completHover(theme)}
  }
`

const defaultMarginLeft = 2

const Column = styled.div`
  ${({ widthPercentage = 100, marginLeft = defaultMarginLeft }) =>
    `
      display: inline-block;
      width: ${widthPercentage - marginLeft}%;
      margin-left: ${marginLeft}%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`

Column.propTypes = {
  widthPercentage: PropTypes.number.isRequired,
  marginLeft: PropTypes.number,
}

export { Column }

export const StyledColumn = styled(Column)`
  ${({ alignRight }) => alignRight && 'alight-text: right;'}
  vertical-align: middle;
  color: ${({ theme }) => theme.colorGray};
  background-color: inherit;
`

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.color};
`

export const Highlighted = styled.strong`
  background-color: ${({ theme }) => theme.colorLight};
  color: ${({ theme }) => theme.colorWhite};
`

export const RenderedItem = styled.div`
  a {
    text-decoration: none;
  }
`

export const LabelDropdownItem = styled(Label)`
  margin-right: ${({ theme }) => theme.sizeSmall};
`

export const StyledTypeaheadNew = styled.div`
  width: 100%;

  .button-clear {
    ${({ theme }) => theme.baseText};
    color: ${({ theme }) => theme.colorGrayLight};
  }

  .rbt-menu {
    z-index: 1;
    list-style-type: none;
    margin: 0;
    margin-top: ${({ theme }) => theme.size};
    background-color: #ffffff;
    padding: 0;
    border-radius: 2px;
    box-shadow: ${({ theme }) => theme.focusShadow};
    ${({ theme }) => theme.scroll(theme)};
  }

  .dropdown-item {
    padding: ${({ theme }) => theme.size};
    border-bottom: 1px solid ${({ theme }) => theme.colorLighter};
    display: block;
    clear: both;
    white-space: nowrap;
    background-color: transparent;
    color: inherit;
    text-decoration: none;
    ${({ theme }) => theme.baseText};
    :hover {
      ${({ theme }) => theme.completHover(theme)};
    }

    &.active {
      ${({ theme }) => theme.completHover(theme)};
    }
  }

  .rbt-highlight-text {
    background-color: ${({ theme }) => theme.color};
    color: ${({ theme }) => theme.colorWhite};
    font-weight: bold;
  }

  input {
    font: inherit;
    appearance: none;
    width: 100%;
    border-radius: 0;
    &.focus {
      outline: none;
    }

    ::placeholder {
      ${({ theme }) => theme.uiText};
      color: ${({ theme }) => theme.colorGrayLight};
    }
  }

  .rbt-aux {
    width: auto;
    .sr-only {
      display: none;
    }
  }
`
