import { TMDSAlertsService, TMDSDetailsService } from '@app/common/services'
import TMDSContextService from '@app/common/tmdsContextService'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { RecordsNavigation } from '@new-lucentum'
import React from 'react'

const navigationActions = {
  next: results => results[0].ST13,
  previous: results => results[results.length - 1].ST13,
}

const getDetailNavigationData = (results, URLToBack) => {
  return {
    tradeMarks: results.map(td => td.ST13),
    URLToBack,
  }
}

export default props => {
  const { navigationSource, pagination, getDataProperties, URLToBack } = props.navigationData

  const resultsSelector = TMDSContextService.isTmview() ? 'tradeMarks' : 'designResults'
  const targetResults = ['alerts', 'tags'].includes(navigationSource) ? pagination : props.tmResults
  const { page: currentPage, totalPages, totalResults } = targetResults
  const { pageSize = 20, displayMode } = props.resultsConf
  const currentPageMaxIndex = currentPage * pageSize
  const baseIndex = currentPageMaxIndex - pageSize
  const currentTMIndex = props.navigationData.tradeMarks.indexOf(props.trademarkId) + 1
  const queryStrFromURLToBack = props.navigationData.URLToBack.split('?')

  const getNextPageSearchResults = (props, link, newPage, resolve) => {
    if (['alerts', 'tags'].includes(navigationSource) && getDataProperties) {
      // TO DO: Heavy refactor for managing navigation from a central place.
      const { params } = getDataProperties
      const { st13List, filterParams } = params
      const newFilterParams = { ...filterParams, page: newPage }
      params &&
        TMDSAlertsService.doAlertsSearch(st13List, newFilterParams).then(({ value }) => {
          const results = value[resultsSelector]
          const action = navigationActions[link]
          const st13 = (!!action && action(results)) || null
          const searchSt13List = results.map(result => result.ST13)
          const navigation = {
            tradeMarks: searchSt13List,
            URLToBack,
            pagination: { ...pagination, page: newPage },
            navigationSource,
            getDataProperties,
          }
          TMDSDetailsService.setDetailNavigationData(st13, navigation)
          TMDSRouterService.navigate(`/detail/${st13}`)
          resolve({ tradeMarkId: st13, newNav: true })
        })
    } else {
      const newQueryParams = queryStrFromURLToBack[1].replace(/page=[0-9]+/, `page=${newPage}`)
      props
        .fetchSearch(
          newQueryParams,
          displayMode,
          props.uiState.results.isChangeFilters,
          props.match.context
        )
        .then(({ value }) => {
          const results = value[resultsSelector]
          const action = navigationActions[link]
          const tradeMarkId = (!!action && action(results)) || null
          const nextNavigationData = getDetailNavigationData(
            results,
            props.navigationData.URLToBack
          )
          props.navToDetail(nextNavigationData, tradeMarkId)
          resolve({ tradeMarkId, newNav: true })
        })
    }
  }

  return (
    <RecordsNavigation
      pageTotalKey={totalResults}
      pageKey={baseIndex + currentTMIndex}
      onRecordChange={(e, link) => {
        e.preventDefault()
        const nextRecord = new Promise((resolve, reject) => {
          if (currentTMIndex === pageSize && currentPage < totalPages && link === 'next') {
            getNextPageSearchResults(props, link, currentPage + 1, resolve)
          } else if (currentTMIndex === 1 && link === 'previous') {
            getNextPageSearchResults(props, link, currentPage - 1, resolve)
          } else {
            resolve({ tradeMarkId: props.trademarkId, newNav: false })
          }
        })

        nextRecord.then(({ tradeMarkId, newNav }) => {
          const { tradeMarks, URLToBack } = props.navigationData
          const goToResult = offset => {
            const resultUrl = newNav
              ? tradeMarkId
              : tradeMarks[tradeMarks.indexOf(tradeMarkId) + offset]
            props.history.push(resultUrl)
          }
          const actions = {
            next: () => goToResult(1),
            previous: () => goToResult(-1),
            back: () => props.history.push(URLToBack),
          }
          const action = actions[link]
          !!action && action()
        })
      }}
      backLiteral={props.literals.button_back}
    />
  )
}
