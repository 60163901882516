import React from 'react'
import { Field } from 'formik'
import { Radio as RadioLCT } from '@new-lucentum'

export default props => <Field {...props} component={Radio} />

const Radio = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { bindValue, resetFieldsOnChange, ...restProps } = props
  const hasBindValue = !!bindValue
  const checked = hasBindValue ? field.value === bindValue : field.value
  const checkForDependencies = () => {
    Array.isArray(resetFieldsOnChange) &&
      resetFieldsOnChange.forEach(dependantField => {
        form.setFieldValue(dependantField, form.initialValues[dependantField])
      })
  }
  return (
    <RadioLCT
      {...field}
      {...restProps}
      onChange={() => {
        let nextValue = false
        if (hasBindValue) {
          nextValue = bindValue
        } else {
          nextValue = !field.value
        }
        form.setFieldValue(field.name, nextValue)
        !nextValue && checkForDependencies()
      }}
      checked={checked}
    />
  )
}
