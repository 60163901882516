import React from 'react'
import { Block } from '@new-lucentum'
import { formatData } from '@app/common/utilities'
import { Box } from './Box'
import { DataItem } from './DataItem'
import { ShowMore } from './ShowMore'
import { RecordalRow, Separator, RecordalFields } from './styles'
import { t } from '@app/common/translationsService'
import { Recordal } from './components'
import { totalBoxEntries } from './utils'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'
import { useSelector } from 'react-redux'

const BoxRecordalsComponent = ({ inidCodes, data = {}, officeExceptionMessage, ...props }) => {
  const [recordals, setRecordals] = React.useState([])
  const [showMore, setShowMore] = React.useState(false)
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)
  const toggleShowMore = React.useCallback(() => setShowMore(!showMore), [showMore])
  const [events, setEvents] = React.useState({})
  React.useEffect(
    () => {
      const nextRecordals = (data.basicRecords || []).concat(data.recordShortNotation || [])
      const nextEvents = data.events || []
      setRecordals(nextRecordals)
      setEvents(nextEvents)

      const hasItemsToShow = !!nextRecordals.length || !!data.events
      hasItemsToShow !== props.hasItemsToShow &&
        props.setHasItemsToShow('boxRecordals', hasItemsToShow)
    },
    [data, showOriginalLanguageAttributes]
  )

  const RecordalEvents = ({ events }) => {
    return (
      <Block marginBottom={2}>
        <RecordalRow>
          <div className="event-date">
            <DataItem label={t('tm.details.label.EventDate.key')} forceItem />
          </div>
          <div className="grow">
            <DataItem label={t('tm.details.label.EventDescription.key')} forceItem />
          </div>
        </RecordalRow>
        {events.length &&
          events.map((event, index) => (
            <RecordalRow className="event" key={`recordalEvent_${index}`}>
              <div className="event-date">{formatData(event.eventDate, true)}</div>
              <div className="grow">{formatData(event.eventDescription)}</div>
            </RecordalRow>
          ))}
      </Block>
    )
  }

  return (
    <Box
      boxId="boxRecordals"
      title={`${t('tm.details.left.menu.recordals.key')} ${totalBoxEntries(recordals)}`}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-recordals"
      officeExceptionMessage={officeExceptionMessage}
    >
      <RecordalFields>
        {!!events.length && <RecordalEvents events={events} />}
        {!!recordals.length && (
          <Recordal
            recordal={recordals[0]}
            excerpt={!showMore}
            showOriginalLanguageAttributes={showOriginalLanguageAttributes}
          />
        )}
        {showMore &&
          recordals.map(
            (recordal, index) =>
              index > 0 && (
                <Block key={`recordal_${index}`}>
                  <Separator />
                  <Recordal
                    recordal={recordal}
                    showOriginalLanguageAttributes={showOriginalLanguageAttributes}
                  />
                </Block>
              )
          )}
        {recordals.length > 0 && (
          <ShowMore
            testId="boxRecordals-show-more"
            showMore={showMore}
            showMoreHandle={toggleShowMore}
          />
        )}
      </RecordalFields>
    </Box>
  )
}

export const BoxRecordals = BoxRecordalsComponent
