import React from 'react'
import { DayItem } from './styles'

const DayCell = ({ ...props }) => {
  const { dayMoment } = props

  const handleSelect = e => {
    e.preventDefault()
    if (props.isPassive) return null
    props.onSelect(props.dayMoment)
  }

  return (
    <DayItem {...props} onClick={e => handleSelect(e)}>
      {dayMoment.date()}
    </DayItem>
  )
}

export default DayCell
