import store from '@app/store'
import { isRTLLanguage } from '@app/common/utilities'
import { css } from 'styled-components'

/**
 * Support the access of App store through the styled components
 * @input theme
 * @return Boolean
 */
export const isRTL = theme => {
  const currentState = store.getState()
  const {
    uiState: {
      language: { language },
    },
  } = currentState
  const _isRTL = isRTLLanguage(language)

  return _isRTL
}

/**
 * Mixins
 */

/* export const baseText = ({ fontSize, sizeDouble }) => css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: ${fontSize};
  line-height: ${sizeDouble};
` */

/* export const uiText = ({ fontSizeMedium, sizeDouble }) => css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: ${fontSize};
  line-height: ${sizeDouble};
` */

export const inputWrapper = ({ sizeSmall }) => css`
  & + & {
    margin-top: ${sizeSmall};
  }
  label {
    flex: 1;
    margin-left: ${sizeSmall};
    &:empty {
      display: none;
    }
  }
`

export const input = () => css`
  margin: 0;
  height: 14px;
  width: 14px;
`

export const inputAfter = theme => css`
  background-color: ${theme.colorWhite};
  border: ${theme.line} solid ${theme.colorGrayLight};
  content: '';
  display: block;
  height: 14px;
  width: 14px;
`

export const inputCheckedAfter = (theme, content) => css`
  background-color: ${theme.color};
  border-color: ${theme.color};
  color: ${theme.colorWhite};
  content: ${content()};
  font-family: ${theme.iconFont};
  font-size: ${theme.fontSize};
  line-height: 14px;
  text-align: center;
`

export const inputFocus = ({ colorLight }, disabled) => css`
  ${!disabled &&
    css`
      &:hover,
      &:focus {
        cursor: pointer;
        outline: none;
        &:after {
          box-shadow: 0px 0px 5px 1px ${colorLight};
        }
      }
    `}
`

export const completHover = ({ color, colorLighter }) => css`
  position: relative;
  background-color: ${colorLighter};
  &:before {
    background-color: ${color};
    content: '';
    height: 100%;
    ${isRTL(color) ? 'right:0;' : 'left:0;'}
    position: absolute;
    top: 0;
    width: 3px;
  }
`

export const scroll = ({ color }) => css`
  scrollbar-color: ${color} transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${color};
    border-radius: 100px;
    height: 50%;
  }
`
const BREAKPOINTS = {
  sm: '550px',
}

export const responsive = (responsiveCss, breakpoint = 'sm') => {
  return css`
    @media only screen and (max-width: ${BREAKPOINTS[breakpoint]}) {
      ${responsiveCss}
    }
  `
}
