import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'
import { Grid } from '@new-lucentum'

const StyledWarningMessage = styled.div`
  color: ${({ theme, isError }) => (isError ? theme.colorError : theme.colorWarning)};
  font-size: 0.85rem;
  margin-bottom: ${({ theme }) => theme.size};
`

const prepareWarningMessagesGroup = (status, exceptions, isError) => {
  return (
    <Grid.Group column distance={0.5}>
      {Object.keys(status)
        .filter(field => !exceptions.includes(field))
        .map(field => {
          const fieldStatus = status[field]
          return (
            fieldStatus && (
              <StyledWarningMessage isError={isError} key={`${field}-warning`}>
                {fieldStatus}
              </StyledWarningMessage>
            )
          )
        })}
    </Grid.Group>
  )
}

const prepareWarningMessageSingle = (status, name, isError) => {
  return (
    name &&
    status[name] && <StyledWarningMessage isError={isError}>{status[name]}</StyledWarningMessage>
  )
}

const WarningMessageComponent = ({ form: { status }, all, warningName, exceptions, isError }) => {
  return (
    <div>
      {status &&
        (all
          ? prepareWarningMessagesGroup(status, exceptions, isError)
          : prepareWarningMessageSingle(status, warningName, isError))}
    </div>
  )
}

export default ({ all, name, exceptions = [], isError }) => {
  return (
    <Field
      {...{ all, warningName: name, isError, exceptions }}
      component={WarningMessageComponent}
    />
  )
}
