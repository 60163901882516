import { JAPAN_VERSION, WIPO_VERSION } from '@app/containers/TMSearch/constants'
import { LOCARNO_TYPES } from '@app/redux-types'

const initialState = {
  initialLocarnoCodes: { [WIPO_VERSION]: [], [JAPAN_VERSION]: [] },
  data: [],
  expandedLocarnos: [],
  language: '',
}

const prepareParentLocarno = locarnoCode => locarnoCode
const prepareJapaneseLocarno = locarno => {
  const description = locarno.products || locarno.description
  return {
    ...locarno,
    description,
  }
}

export default (state = initialState, action) => {
  const loadLocarnoFulfilled = () => {
    const prepareFn =
      action.meta.locarnoVersion === JAPAN_VERSION ? prepareJapaneseLocarno : prepareParentLocarno
    const preparedLocarnoCodes = action.payload.map(prepareFn)
    return {
      ...state,
      initialLocarnoCodes: {
        ...state.initialLocarnoCodes,
        [action.meta.locarnoVersion]: preparedLocarnoCodes,
      },
      data: preparedLocarnoCodes,
      language: action.meta.language,
    }
  }

  const loadLocarnoChildrenFulfilled = () => {
    const { locarnoParent } = action.meta
    const parentIndex = state.data.findIndex(e => e.code === locarnoParent)
    return {
      ...state,
      data: [
        ...state.data.slice(0, parentIndex),
        { ...state.data[parentIndex], chevron: true, childrenLoaded: true },
        ...action.payload,
        ...state.data.slice(parentIndex + 1),
      ],
    }
  }

  const toggleLocarno = () => {
    const { optionId, chevron } = action.meta
    const parentIndex = state.data.findIndex(e => e.code === optionId)
    return {
      ...state,
      data: [
        ...state.data.slice(0, parentIndex),
        { ...state.data[parentIndex], chevron: chevron },
        ...state.data.slice(parentIndex + 1).map(opt =>
          opt.parent && opt.parent.includes(optionId)
            ? {
                ...opt,
                hide: state.data[parentIndex].level === 1 && opt.level === 3 ? true : !chevron,
                ...(opt.children && { chevron: !chevron }),
              }
            : opt
        ),
      ],
    }
  }

  const clearLocarnoData = () => ({ ...state, data: [] })

  const changeLocarnoVersion = () => clearLocarnoData()

  const prepareLocarnoFromSearch = (locarno, isJapan) => {
    const _locarno = isJapan ? prepareJapaneseLocarno(locarno) : locarno
    const isChildrenOrParent = _locarno.children || _locarno.parent
    return isChildrenOrParent
      ? {
          ..._locarno,
          ...(_locarno.children && { chevron: true, childrenLoaded: true }),
          ...(_locarno.parent && { hide: false }),
        }
      : _locarno
  }

  const searchLocarnoSuccess = () => {
    const isJapan = action.payload.locarnoVersion === JAPAN_VERSION
    const preparedLocarnoCodes = action.payload.options.map(locarno =>
      prepareLocarnoFromSearch(locarno, isJapan)
    )
    return {
      ...state,
      data: [...preparedLocarnoCodes],
    }
  }

  const searchLocarnoError = () => {
    return {
      ...state,
      data: [],
    }
  }

  const restoreInitialLocarno = () => {
    return {
      ...state,
      data: [...state.initialLocarnoCodes[action.payload]],
    }
  }

  const loadExpandedLocarnos = () => {
    const preparedExpandedLocarnos =
      Array.isArray(action.payload) &&
      action.payload.map(locarno => {
        const preparedLocarno = locarno.id.includes('.')
          ? locarno
          : { ...locarno, id: `${locarno.id}.00`, code: `${locarno.code}.00` }
        return preparedLocarno
      })
    return {
      ...state,
      expandedLocarnos: preparedExpandedLocarnos || [],
    }
  }

  const actionMap = {
    [LOCARNO_TYPES.LOAD_LOCARNO_FULFILLED]: loadLocarnoFulfilled,
    [LOCARNO_TYPES.LOAD_LOCARNO_CHILDREN_FULFILLED]: loadLocarnoChildrenFulfilled,
    [LOCARNO_TYPES.LOAD_EXPANDED_LOCARNO_FULFILLED]: loadExpandedLocarnos,
    [LOCARNO_TYPES.TOGGLE_LOCARNO]: toggleLocarno,
    [LOCARNO_TYPES.SEARCH_LOCARNO_SUCCESS]: searchLocarnoSuccess,
    [LOCARNO_TYPES.SEARCH_LOCARNO_ERROR]: searchLocarnoError,
    [LOCARNO_TYPES.RESTORE_INITIAL_LOCARNO]: restoreInitialLocarno,
    [LOCARNO_TYPES.CHANGE_LOCARNO_VERSION]: changeLocarnoVersion,
  }

  const targetAction = actionMap[action.type]
  return !!targetAction ? targetAction() : state
}
