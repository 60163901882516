import styled from 'styled-components'

export const DropdownMenuMobile = styled.div`
  .tags-container {
    max-height: 200px;
    overflow: auto;
    ${({ theme }) => theme.scroll(theme)};

    .tag-item {
      margin-top: 5px;
    }
  }

  .button-add {
    height: 36px;
    display: flex;
    align-items: center;
  }
`

export const StyledModalHeader = styled.div`
  flex: auto;
  margin-bottom: 10px;
  .modal-title {
    padding-left: 5px;
    font-weight: bold;
  }
`

export const Styled = {
  DropdownMenuMobile,
}
