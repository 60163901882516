import { HistoryDataManager } from '@app/common/dataManagers'

export const fetchHistory = id => {
  return {
    type: 'FETCH_HISTORY',
    payload: HistoryDataManager.getHistory(id),
  }
}

export const fetchHistoryDS = id => {
  return {
    type: 'FETCH_HISTORY',
    payload: HistoryDataManager.getHistoryDS(id),
  }
}

export const changeExportCaptcha = value => ({
  type: 'CHANGE_EXPORT_CAPTCHA_HISTORY',
  payload: { recaptchaResponseKey: value },
})

export const resetRecaptchaResponseKey = () => ({
  type: 'RESET_RECAPTCHA_HISTORY',
})

export const showExportPDFOptions = displayedColumnNames => ({
  type: 'SHOW_EXPORT_PDF_OPTIONS',
  payload: { displayedColumnNames: 1 },
})

export const showExportWordOptions = displayedColumnNames => ({
  type: 'SHOW_EXPORT_DOC_OPTIONS',
  payload: { displayedColumnNames: displayedColumnNames },
})

export const cancelExportHistory = () => ({
  type: 'CANCEL_EXPORT',
})

export const showExportHistoryOptions = (fileType, show) => ({
  type: 'SHOW_EXPORT_HISTORY_OPTIONS',
  payload: { fileType, show: show || false },
})

export const setExportHistoryNotification = (notification, type) => ({
  type: 'SET_EXPORT_HISTORY_NOTIFICATION',
  payload: { notification: notification, notificationType: type },
})

export const closeExportNotification = () => ({
  type: 'CLOSE_EXPORT_HISTORY_NOTIFICATION',
})

export const setHistoryIds = (historyId, targetHistoryId) => ({
  type: 'SET_EXPORT_HISTORY_IDS',
  payload: { historyId, targetHistoryId },
})
