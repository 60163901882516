const defaultState = {
  showAddTagModal: false,
  showFilters: true,
  addedTagsByTM: {},
  availableTagsInModal: [],
  selectedTagsModal: [],
  filtersTags: [],
  currentSort: {},
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'TOGGLE_ADD_TAG_MODAL':
      return {
        ...state,
        showAddTagModal: !state.showAddTagModal,
        availableTagsInModal: payload.slice(),
      }
    case 'ADD_TAGS_BY_TM':
      return {
        ...state,
        addedTagsByTM: payload,
        selectedTagsModal: payload.tags,
      }
    case 'CHANGE_FILTERS_TAGS':
      return {
        ...state,
        filtersTags: payload,
      }
    case 'CHANGE_SORT_COLUMN_TAGS':
      return {
        ...state,
        currentSort: payload,
      }
    case 'TOGGLE_TAGS_FILTERS':
      return {
        ...state,
        showFilters: !state.showFilters,
      }
    default:
  }

  return state
}
