import styled, { css } from 'styled-components'

export const Styled = styled.div`
  background: ${({ theme }) => theme.colorWhite};
  border-radius: 3px;
  border-radius: 4px;
  margin: 20px 0px 0px 0px;
  box-shadow: ${({ theme }) => theme.dropShadow};
  display: flex;
  position: relative;

  ${({ theme }) =>
    theme.responsive(css`
      box-shadow: none;
      background: transparent;
      margin-top: 30px;

      .image-remove {
        position: relative;
        font-size: inherit;
      }
    `)};

  .change-image {
    margin-top: ${({ theme }) => theme.size};
  }

  .image-tools {
    flex-basis: calc(50% - 40px);
  }

  &.standalone {
    border: none;
    box-shadow: none;
    background: transparent;
    &:after,
    :before {
      display: none !important;
    }

    .change-image {
      display: none;
    }

    .image-remove {
      display: none;
    }

    .image-search-editor > h3 {
      display: none;
    }
  }

  .image-search-editor {
    margin: 20px;
    margin-left: 40px;
    position: relative;
    width: 100%;
  }

  .image-remove {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2em;
  }

  .credits {
    text-align: center;
  }

  ${({ inModal }) =>
    inModal &&
    css`
      border: none;
      box-shadow: none;
      background: transparent;
      &:after,
      :before {
        display: none !important;
      }
    `};
  & .content {
    width: 100%;
    & .errorMessage {
      display: block;
      background-color: rgba(185, 74, 72, 0.1);
      border: 1px solid #b94a48;
      clear: both;
      color: red;
      font-size: ${({ theme }) => theme.fontSizeSmaller};
      margin: 8px 0px 8px 0px;
      padding: 0.6em 0;
      text-align: center;
    }
  }
  & .close {
    color: #404040;
    position: absolute;
    top: 0;
    right: 0;
    font-size: ${({ theme }) => theme.fontSizeLarge};
    font-weight: bold;
    line-height: 18px;
    border: none;
    background: none;
    cursor: pointer;
  }

  /* &:after,
  :before {
    bottom: 100%;
    left: calc(100% - 357px);
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(247, 247, 247, 0);
    border-bottom-color: ${({ theme }) => theme.colorWhite};
    border-width: 15px;
    margin-left: 17px;
  }
  &:before {
    border-color: rgba(192, 203, 214, 0);
    border-bottom-color: white;
    border-width: 17px;
    margin-left: 15px;
  } */

  .betaMessage {
    margin-top: 10px;
  }

  .change-image {
    color: -webkit-link;
    cursor: pointer;
  }
`

export const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  img {
    max-width: 50%;
  }
`
