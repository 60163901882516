import React from 'react'
import styled from 'styled-components'

const Styled = styled.div`
  position: relative;
  flex: 1;
  &.sticky {
    .sticky--inner {
      position: fixed;
      top: ${({ top }) => `${top}px`};
      left: 0;
      right: 0;
      z-index: 20;
    }
  }
`

const StickyComponent = ({ children, preventFooterIntersection = false, top = 0 }) => {
  const [isSticky, setSticky] = React.useState(false)
  const [offset, setOffset] = React.useState({ left: 0, right: 0 })
  const ref = React.useRef(null)
  const maxElementHeight = React.useRef(0)

  React.useEffect(() => {
    function getVisibleDimensions(element) {
      if (!element?.getBoundingClientRect) {
        return { visibleWidth: 0, visibleHeight: 0 }
      }

      const rect = element.getBoundingClientRect()

      const visibleHeight = Math.max(
        0,
        Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0)
      )
      const visibleWidth = Math.max(
        0,
        Math.min(rect.right, window.innerWidth) - Math.max(rect.left, 0)
      )

      return { visibleWidth, visibleHeight }
    }

    const handleScroll = () => {
      if (ref.current) {
        const bounding = ref.current.getBoundingClientRect()
        let offset = { left: bounding.left, right: bounding.right - bounding.width }
        if (preventFooterIntersection) {
          const elementHeight = ref.current.children[0].clientHeight
          if (elementHeight > maxElementHeight.current && bounding.top < -44) {
            maxElementHeight.current = elementHeight
          }

          const footerHeightInView = getVisibleDimensions(document.getElementById('app-footer'))
            .visibleHeight

          if (footerHeightInView > 0) {
            offset = {
              left: bounding.left,
              right: bounding.right - bounding.width,
              bottom: footerHeightInView,
            }
          }
        }

        setSticky(bounding.top <= -34)
        setOffset(offset)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const className = isSticky ? 'sticky' : ''
  return (
    <Styled ref={ref} className={className} top={top}>
      <div className="sticky--inner" style={offset}>
        {React.cloneElement(children, { className: isSticky ? 'is-sticky' : '' })}
      </div>
    </Styled>
  )
}

export const Sticky = StickyComponent
