export class OFFICE_FIELDS {
  static KEY = 'office'
  static OFFICE_URL = 'officeUrl'
  static OFFICE_LOGO_URL = 'officeLogoUrl'
  static DESIGN_OFFICE_URL = 'dsOfficeURL'
  static OFFICE_CASE_LAW_URL = 'officeCaseLawUrl'
  static APPLICATION_CASE_LAW_URL = 'applicationCaseLawUrl'
  static OFFICE_LAST_UPDATE_DATE = 'officeLastUpdateDate'
  static OFFICE_NUMBER_OF_DESIGNS = 'officeNumberOfDesigns'
}
