import fileDownload from 'react-file-download'
import { getHeaderFileName } from '@app/common/utilities'
import { TMDSTrackingService, TMDSSpinnerService } from '@app/common/services'

export const manageExport = (exportPromise, resource, documentType) => {
  TMDSTrackingService.trackExport(resource, documentType)
  TMDSSpinnerService.showSpinner(exportPromise)
  exportPromise.then(response => {
    const { headers, parsedBody } = response
    const fileName = getHeaderFileName(headers)
    fileDownload(parsedBody, fileName ? fileName : `${resource}.${documentType}`)
  })
}
