import React from 'react'
import { List } from 'react-content-loader'
import { getTermsSeparator } from '@app/common/utilities'
import { Block } from '@new-lucentum'
import { SkeletonWrapper } from '@app/components/SkeletonLoader'
import { GSListTerms, GSTerm } from '@app/components/GoodsAndServices'
import { Label } from './DataItem'

const renderTerms = (niceClass, goodServices, gsTranslated, separator, error) =>
  goodServices.map(gs => {
    const translatedNiceClassObj = gsTranslated.find(term => term.niceClass === niceClass)
    const translatedTermsArray =
      translatedNiceClassObj &&
      translatedNiceClassObj.goodsAndServices &&
      translatedNiceClassObj.goodsAndServices.length &&
      translatedNiceClassObj.goodsAndServices

    if (!Array.isArray(translatedTermsArray) || !translatedTermsArray.length)
      return (
        <GSTerm data-test-id="goods-service-text" key={`${niceClass}_${gs}`} error={error}>
          {gs.term}
        </GSTerm>
      )

    return translatedTermsArray.map((translatedTermObj, i, { length }) => {
      const gsTerm = translatedTermObj.translatedTerm || ''
      if (!gsTerm) return ''
      return (
        <GSTerm
          data-test-id="goods-service-text"
          key={`${niceClass}_${i}`}
          translationType={
            (translatedTermObj && translatedTermObj.goodsAndServicesTranslationType) || ''
          }
          error={error}
        >
          {i === length - 1 ? gsTerm : `${gsTerm}${separator} `}
        </GSTerm>
      )
    })
  })

export const ClassificationsTerms = ({
  niceClass,
  terms,
  gsTranslated,
  languageTranslation,
  fetching,
  gsTranslateError,
}) => {
  const separatorTranslate = getTermsSeparator(languageTranslation)
  const error =
    !!gsTranslateError && !!gsTranslateError.includes && gsTranslateError.includes(niceClass)
  return (
    <Block padding>
      <Block width1of12 right paddingRight>
        <Label>{niceClass}</Label>
      </Block>

      <GSListTerms error={error} width11of12>
        <SkeletonWrapper
          showSkeleton={fetching.some(nice => nice === niceClass)}
          skeleton={<List width={500} height={50} />}
        >
          {renderTerms(niceClass, terms, gsTranslated, separatorTranslate, error)}
        </SkeletonWrapper>
      </GSListTerms>
    </Block>
  )
}

export default ClassificationsTerms
