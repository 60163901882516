// Main colours
const color = '#2F509A'
const colorLight = '#E8F6F9'
const colorLighter = '#EAEFF2'
const colorLighterBackground = '#f9fafc'
const colorLighterPlus = '#f6f8f9'
const colorAccent = '#F9F9FA'
const colorLighterFiftyPercent = '#EAEFF280'

// Grey colours
const colorGray = '#2C2F35'
const colorGrayLight = '#686C73'
const colorGrayLighter = '#EFF0F2'
const colorGrayAccent = '#C6CDD4'
const colorGrayBG = '#EDEEF1'
const colorFooterBG = '#303941'

// Background colours
const colorBackground = '#E9EDF2'
const colorSecondary = '#3C3F58'

// Message colours
const colorSuccess = '#9AB954'
const colorSuccessAccent = '#F5F9F2'
const colorError = '#D52D2C'
const colorErrorAccent = '#FDEEEE'
const colorWarning = '#F88125'
const colorWarningAccent = '#FEFAEC'

// Support colours
const colorWhite = '#FFFFFF'
const colorTransparent = 'transparent'
const dropShadow = `0px 3px 2px ${colorGrayAccent}`
// Table colors
const rowCebra = '#f9f9fb'
const rowSelected = colorLight

export const colours = {
  color,
  colorLight,
  colorLighter,
  colorLighterBackground,
  colorLighterPlus,
  colorLighterFiftyPercent,
  colorAccent,
  colorGray,
  colorGrayLight,
  colorGrayLighter,
  colorGrayAccent,
  colorGrayBG,
  colorFooterBG,
  colorBackground,
  colorSecondary,
  colorSuccess,
  colorSuccessAccent,
  colorError,
  colorErrorAccent,
  colorWarning,
  colorWarningAccent,
  colorWhite,
  colorTransparent,
  dropShadow,
  rowCebra,
  rowSelected,
}
