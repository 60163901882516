export const CERTIFICATE_TYPES = {
  APPLICATION: 'APPLICATION',
  REGISTRATION: 'REGISTRATION',
  AUTHENTICATE: 'AUTHENTICATE',
}

export const VALIDATION_ERROR_MESSAGE = 'form_validation_error_required'

export const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  postCode: '',
  country: '',
}
