import { isTmview } from '@app/common/tmdsContextService'
import { selectPlatform } from '@app/common/utils'

export default selectPlatform({
  mobile: () =>
    isTmview()
      ? import('@app/containers/Details/DetailsTM.mobile')
      : import('@app/containers/Details/DetailsDS.mobile'),
  desktop: () =>
    isTmview()
      ? import('@app/containers/Details/DetailsTM')
      : import('@app/containers/Details/DetailsDS'),
})
