import React from 'react'
import { compose, lifecycle, withState, withHandlers } from 'recompose'
import { includeinidCodesInLabels, formatData, hasItemsToShowInArray } from '@app/common/utilities'
import { Box } from './Box'
import { DataList } from './DataItem'
import { ShowMore } from './ShowMore'
import { DataRow } from './styles'
import { totalBoxEntries } from './utils'

const SHOWN_ROWS_LIMIT = 1

const enhance = compose(
  withState('showMore', 'setShowMore', false),
  withHandlers({
    formatedData: ({ literals, inidCodes }) => dataRow => {
      const detailRow = (literalKey, dataKey, dataRow, inidCode, isDate) => ({
        inidCode: inidCode || dataKey,
        label: literals[literalKey],
        data: formatData(dataRow[dataKey], isDate),
      })
      return includeinidCodesInLabels(inidCodes, [
        detailRow(literals.tm_details_label_appealIdentifier, 'identifier', dataRow),
        detailRow('tm.details.label.appealDate.key', 'appealDate', dataRow, null, true),
        detailRow(
          'tm.details.label.appealDecisionDate.key',
          'appealDecisionDate',
          dataRow,
          null,
          true
        ),
        detailRow('tm.details.label.proceedingLanguageCode.key', 'proceedingLanguageCode', dataRow),
        detailRow('tm.details.label.appealNickname.key', 'appealNickName', dataRow),
        detailRow(
          'tm.details.label.oppositionDecisionStatusCode.key',
          'appealDecisionStatusCode',
          dataRow
        ),
      ])
    },
    toggleShowMore: ({ showMore, setShowMore }) => () => setShowMore(!showMore),
  }),
  lifecycle({
    componentDidMount() {
      const { data, formatedData, setHasItemsToShow } = this.props
      const hasItemsToShow = hasItemsToShowInArray(data, formatedData)
      hasItemsToShow !== this.props.hasItemsToShow &&
        setHasItemsToShow('boxAppeals', hasItemsToShow)
    },
  })
)

export default enhance(
  ({
    literals,
    inidCodes,
    data = [],
    hasItemsToShow,
    formatedData,
    officeExceptionMessage,
    ...props
  }) => {
    const BoxData =
      hasItemsToShow &&
      Array.isArray(data) &&
      data.map((dataRow, index) => {
        if (index < SHOWN_ROWS_LIMIT || props.showMore)
          return (
            <DataRow key={index}>
              <DataList
                testId="boxAppeals-show-more2"
                data={formatedData(dataRow)}
                literals={literals}
                shownItemsLimit={99}
              />
              {index + 1 !== data.length && props.showMore && <hr />}
            </DataRow>
          )
      })

    return (
      <Box
        boxId="boxAppeals"
        title={`${literals['tm.details.left.menu.appeals.key']} ${totalBoxEntries(data)}`}
        hasItemsToShow={hasItemsToShow}
        dataTestId="item-detail-appeals"
        officeExceptionMessage={officeExceptionMessage}
      >
        {BoxData}
        {hasItemsToShow && data.length > SHOWN_ROWS_LIMIT && (
          <ShowMore
            testId="boxAppeals-show-more"
            literals={literals}
            showMore={props.showMore}
            showMoreHandle={props.toggleShowMore}
          />
        )}
      </Box>
    )
  }
)
