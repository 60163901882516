import styled from 'styled-components'

import Block from '../Block'

export const Header = styled.header`
  display: flex;
  flex-direction: column;
`

export const Top = styled(Block)`
  background-color: ${({ theme }) => theme.color};
`
