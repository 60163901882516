import { t } from '@app/common/translationsService'
class TMFormHandler {
  constructor() {
    this._handleSubmit = null
  }

  set handleSubmit(handleSubmit) {
    this._handleSubmit = handleSubmit
  }

  get handleSubmit() {
    return this._handleSubmit
  }

  triggerHandleSubmit = () => {
    if (this._handleSubmit) {
      this._handleSubmit()
    }
  }
}

const getDateRanges = (nMonths = 6, nYears = [2, 5, 10]) => ({
  [t('filters.date.lastMonth.key')]: {
    startDate: now => now.add(-1, 'months').add(1, 'days'),
    endDate: now => now,
  },
  [t('filters.date.lastNMonths.key').replace('{N}', nMonths)]: {
    startDate: now => now.add(-nMonths, 'months').add(1, 'days'),
    endDate: now => now,
  },
  [t('filters.date.lastYear.key')]: {
    startDate: now => now.add(-1, 'years').add(1, 'days'),
    endDate: now => now,
  },
  [t('filters.date.lastNYears.key').replace('{N}', nYears[0])]: {
    startDate: now => now.add(-nYears[0], 'years').add(1, 'days'),
    endDate: now => now,
  },
  [t('filters.date.lastNYears.key').replace('{N}', nYears[1])]: {
    startDate: now => now.add(-nYears[1], 'years').add(1, 'days'),
    endDate: now => now,
  },
  [t('filters.date.lastNYears.key').replace('{N}', nYears[2])]: {
    startDate: now => now.add(-nYears[2], 'years').add(1, 'days'),
    endDate: now => now,
  },
})

const checkFieldErrors = async (errors, key, values, field, setFieldValue) => {
  const fieldsErrorsKey = key
  if (fieldsErrorsKey) {
    const currentFieldErrors = values[fieldsErrorsKey]
    const fieldError = currentFieldErrors[field.name]
    const path = `${fieldsErrorsKey}.${field.name}`
    const nextError = errors.length ? errors : false
    if (errors.length > 0) {
      await setFieldValue(path, nextError)
    } else if (fieldError) {
      await setFieldValue(path, nextError)
    }
  }
}

export { TMFormHandler, getDateRanges, checkFieldErrors }
