import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Block as BlockTm } from '@new-lucentum'

export const Block = styled(BlockTm)`
  ${({ content }) => content && 'flex: 1 0 auto;'};
  direction: ${({ theme }) => (theme.isRTL(theme) ? 'rtl' : 'ltr')};
`

export const Toast = styled(ToastContainer)`
  .Toastify__toast-body {
    margin: 0px;
  }
  .Toastify__close-button {
    color: black;
    opacity: 1;
    margin: auto 0;
  }
  .Toastify__toast--default {
    top: 3em;
    min-width: ${props => (props.isMobile ? '0' : '41em')};
    right: ${props => (props.isMobile ? '0' : '13em')};
    padding: 20px 20px 20px 20px;
    color: ${({ theme }) => theme.colorGray};
    font-size: ${({ theme }) => theme.baseText};
    ${({ theme }) => theme.baseText};

    .toast-icon {
      padding-right: 20px;
      font-size: 25px;
      height: 25px;
    }

    &.info {
      background-color: ${({ theme }) => theme.colorGrayAccent};
      border: 1px solid ${({ theme }) => theme.colorGrayLight};
      .toast-icon {
        color: ${({ theme }) => theme.colorGrayLight};
      }
    }
    &.success {
      background-color: ${({ theme }) => theme.colorSuccessAccent};
      border: 1px solid ${({ theme }) => theme.colorSuccess};
      .toast-icon {
        color: ${({ theme }) => theme.colorSuccess};
      }
    }
    &.warning {
      background-color: ${({ theme }) => theme.colorWarningAccent};
      border: 1px solid ${({ theme }) => theme.colorWarning};
      .toast-icon {
        color: ${({ theme }) => theme.colorWarning};
        display: inline-block;
        transform: rotateX(180deg);
      }
    }
    &.error {
      background-color: ${({ theme }) => theme.colorErrorAccent};
      border: 1px solid ${({ theme }) => theme.colorError};
      .toast-icon {
        color: ${({ theme }) => theme.colorError};
      }
    }
  }
`
