import { EXHIBITION_PRIORITY_FIELDS } from '@app/common/dataManagers/types'
export const exhibitionPriorityFields = [
  { label: 'addresscountrycode.key', path: EXHIBITION_PRIORITY_FIELDS.COUNTRY_CODE },
  {
    label: 'exhibition.first.display.date.key',
    path: EXHIBITION_PRIORITY_FIELDS.FIRST_DISPLAY_DATE,
    isDate: true,
  },
  {
    label: 'priority.status.key',
    path: EXHIBITION_PRIORITY_FIELDS.STATUS_CODE,
    pathTranslated: EXHIBITION_PRIORITY_FIELDS.STATUS_CODE_TRANSLATED,
  },
  {
    label: 'addresscity.key',
    path: EXHIBITION_PRIORITY_FIELDS.CITY_NAME,
  },
  {
    label: 'exhibitiondate.key',
    path: EXHIBITION_PRIORITY_FIELDS.EXHIBITION_DATE,
    isDate: true,
  },
  {
    label: 'firstname.key',
    path: EXHIBITION_PRIORITY_FIELDS.NAME,
  },
  {
    label: 'tm.details.label.partialPriority.key',
    path: EXHIBITION_PRIORITY_FIELDS.PARTIAL_PRIORITY,
  },
  {
    label: 'tm.details.label.PartialPriorityIndicator.key',
    path: EXHIBITION_PRIORITY_FIELDS.PARTIAL_PRIORITY_INDICATOR,
  },
]
