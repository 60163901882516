import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import TMDSContextService from '@app/common/tmdsContextService'

export const AppRoute = props => {
  const { canActivate, component: Component, ...restProps } = props
  return (
    <Route
      {...restProps}
      render={props => {
        const triggeredGuard = canActivate && canActivate.find(guard => !guard.canActivate())
        if (!!triggeredGuard) {
          const fallbackAction =
            triggeredGuard.getFallbackAction && triggeredGuard.getFallbackAction()
          if (fallbackAction) {
            fallbackAction()
            return null
          } else {
            const fallbackPath = triggeredGuard.getFallbackPath(props.history)
            return !fallbackPath ? null : (
              <Redirect
                to={`/${TMDSContextService.get()}${triggeredGuard.getFallbackPath(props.history)}`}
              />
            )
          }
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}
