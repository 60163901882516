import React from 'react'
import { t } from '@app/common/translationsService'
import { Button } from '@new-lucentum'

const ViewHistoryButtonComponent = props => {
  const buttonText = t('view.history.key')

  return <Button onClick={props.onClick} title={buttonText} />
}

export const ViewHistoryButton = ViewHistoryButtonComponent
