import validator from 'validator'
import { isEmptyString } from '@app/common/validators'
import { validationMessage } from '@app/common/utilities'
import { SHARE_SEARCH_TYPES } from '@app/redux-types'

const defaultErrors = {
  email: false,
  text: false,
  recaptchaResponseField: false,
}

const defaultForm = {
  email: '',
  recaptchaResponseField: null,
  name: '',
  subject: '',
  text: '',
}

const validateEmail = value => isEmptyString(value) || !validator.isEmail(value)

const validateField = field => {
  const validations = {
    email: validateEmail,
    text: isEmptyString,
  }
  return validations[field.field] ? { [field.field]: validations[field.field](field.value) } : null
}

const validateAllFields = fields =>
  Object.keys(fields).reduce(
    (previousField, currentField) => ({
      ...previousField,
      ...validateField({ field: currentField, value: fields[currentField] }),
    }),
    {}
  )

const setFieldValues = (payload, state) => ({
  ...state,
  formState: {
    ...state.formState,
    fields: {
      ...state.formState.fields,
      [payload.field]: payload.value,
    },
    errors: {
      ...state.formState.errors,
      ...validateField(payload),
    },
  },
})

const defaultState = {
  showModalShareByEmail: false,
  formState: {
    fields: {
      ...defaultForm,
    },
    errors: {
      ...defaultErrors,
    },
  },
  successShared: false,
  backEndErrorMessage: {
    error: false,
    errorMessage: '',
    errorDetails: [],
  },
  shortLink: '',
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'TOGGLE_MODAL_SHARE_BY_EMAIL':
      return {
        ...state,
        showModalShareByEmail: !state.showModalShareByEmail,
      }
    case 'CLOSE_MODAL_SHARE_BY_EMAIL':
      return defaultState
    case 'CHANGE_EMAIL_FIELD':
      return setFieldValues(payload, state)
    case SHARE_SEARCH_TYPES.SEND_EMAIL_FULFILLED:
      return {
        ...state,
        backEndErrorMessage: defaultState.backEndErrorMessage,
        successShared: true,
      }
    case SHARE_SEARCH_TYPES.SEND_EMAIL_REJECTED:
      return validationMessage({ ...state, successShared: false }, payload)
    case 'FETCH_SHORT_LINK_FULFILLED':
      return {
        ...state,
        shortLink: payload,
        formState: {
          ...state.formState,
          fields: {
            ...state.formState.fields,
            text: payload,
          },
        },
      }
    case 'VALIDATE_ENTIRE_FORM':
      const errors = validateAllFields(state.formState.fields)
      return {
        ...state,
        formState: {
          ...state.formState,
          errors: errors,
        },
      }
    case 'CLEAR_FORM':
      return {
        ...state,
        formState: {
          fields: {
            ...defaultForm,
            text: state.shortLink,
          },
          errors: {
            ...defaultErrors,
          },
        },
        successShared: false,
        backEndErrorMessage: {
          error: false,
          errorMessage: '',
          errorDetails: [],
        },
      }
    case 'SET_SHARE_EMAIL_NOTIFICATION':
      return {
        ...state,
        backEndErrorMessage: {
          ...state.backEndErrorMessage,
          error: true,
          errorMessage: payload.message,
        },
      }
    default:
      return state
  }
}
