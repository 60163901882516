import React from 'react'
import styled from 'styled-components'

const StyledDivider = styled.div`
  background-color: ${({ theme }) => theme.colorGrayAccent};
  &.vertical {
    width: 1px;
    height: 85%;
    margin: 0 10px;
  }

  &.horizontal {
    height: 1px;
    width: 100%;
  }
`

export const Divider = ({ direction = 'vertical' }) => {
  return <StyledDivider className={direction} />
}
