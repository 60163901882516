import React from 'react'
import { Modal as ModalLucentum, Block, Notification } from '@new-lucentum'
import styled from 'styled-components'

const NotificationLeft = styled(Notification)`
  text-align: left !important;
`

const mapDetails = (detail, i, literals) => <li key={i}>{literals[detail] || detail}</li>

const Modal = ({
  literals,
  handleClose,
  type,
  open,
  title,
  message,
  details,
  ariaHideApp = false,
}) => (
  <span>
    <ModalLucentum
      onSubmit={handleClose}
      onRequestClose={handleClose}
      isOpen={open}
      title={literals[title] || title}
      textButtonSubmit={literals['ok.key']}
      textButtonClose={literals['cancel.key']}
      ariaHideApp={ariaHideApp}
    >
      <Block textCenter>
        <NotificationLeft
          error={type === 'error' ? true : false}
          warning={type === 'warning' ? true : false}
          styleName="notification"
        >
          {literals[message] || message}
          {Array.isArray(details) && details.length > 0 && (
            <ul>{details.map((detail, i) => mapDetails(detail, i, literals))}</ul>
          )}
        </NotificationLeft>
      </Block>
    </ModalLucentum>
  </span>
)

export default Modal
