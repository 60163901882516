const defaultState = {
  showTooltip: false,
  isOpen: false,
  version: 0,
}

const toggleTour = (state, action) => {
  return {
    ...state,
    isOpen: action.payload.isOpen,
  }
}

const toggleTooltip = (state, action) => {
  return { ...state, showTooltip: action.payload }
}

const actionsMap = {
  TOUR_TOGGLE: toggleTour,
  TOUR_TOGGLE_TOOLTIP: toggleTooltip,
}

export const tourReducer = (state = defaultState, action) => {
  const mutation = actionsMap[action.type]
  return mutation ? mutation(state, action) : state
}
