import React from 'react'
import { compose, withState, withHandlers } from 'recompose'
import { ToggleButtonStyled } from './styles'
const enhance = compose(
  withState('isActive', 'setIsActive', false),
  withHandlers({
    toggle: ({ isActive, setIsActive, toggleFn }) => () => {
      const nextState = !isActive
      setIsActive(nextState)
      toggleFn(nextState)
    },
  })
)
const ToggleButton = ({ activeLabel, inactiveLabel, toggleFn, ...props }) => {
  const { isActive, toggle } = props
  const buttonText = isActive ? inactiveLabel : activeLabel
  return (
    <ToggleButtonStyled link onClick={toggle}>
      {buttonText}
    </ToggleButtonStyled>
  )
}

export default enhance(ToggleButton)
