import styled from 'styled-components'

export const StyledAccordion = styled.div`
  width: 100%;
  .accordion {
    &-summary {
    }
    &-toggler {
    }
    &-body {
      padding-left: 15px;
    }
  }
`
