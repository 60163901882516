import { isEmpty as rIsEmpty, isNil, path as ramdaPath } from 'ramda'

let _globals = null
let preparedGoodsAndServices = null
let preparedOffices = null
let preparedRegionalConfig = {}

const isEmpty = param => isNil(param) || rIsEmpty(param)

export const set = globals => {
  _globals = globals
  return true
}

export const get = () => _globals || {}

export const getPath = path => {
  const parsedDataKey = path ? path.split('.') : ['']
  let value = _globals && ramdaPath(parsedDataKey, _globals)
  return value
}

const getGoodsAndServices = () => {
  if (!preparedGoodsAndServices) {
    const {
      selects: { nice },
    } = _globals
    preparedGoodsAndServices = [].concat(...nice[0].options, ...nice[1].options)
  }
  return preparedGoodsAndServices
}

const getViennaCodes = (isUspto = false) => {
  const {
    selects: { viennaCodes, viennaCodesUSPTO },
  } = _globals
  return isUspto ? viennaCodesUSPTO : viennaCodes
}

const getOffices = () => {
  if (!preparedOffices) {
    const {
      selects: { offices },
      map: { countries },
    } = _globals
    preparedOffices = offices.map(office => {
      const officeId = office.id
      const country = countries.find(country => country.code === officeId)
      return { ...office, ...country }
    })
  }
  return preparedOffices
}

const getOfficeById = id => {
  const {
    selects: { offices },
  } = _globals

  const office = offices.find(office => office.id === id)
  return office && office.text.split('-')[1]
}

const getRegionalConfig = () => {
  if (isEmpty(preparedRegionalConfig)) {
    const { regionalConfig } = _globals
    if (regionalConfig) preparedRegionalConfig = regionalConfig
  }
  return preparedRegionalConfig
}

const getTranslationProvider = languageId => {
  const { deeplLanguages = [] } = _globals
  const sanitizedLanguage = languageId ? languageId.toUpperCase() : ''
  return deeplLanguages.includes(sanitizedLanguage) ? 'DEEPL' : 'GOOGLE'
}

export const TMDSGlobalsService = {
  set,
  get,
  getPath,
  getGoodsAndServices,
  getViennaCodes,
  getOffices,
  getOfficeById,
  getRegionalConfig,
  getTranslationProvider,
}
