import { t } from '@app/common/translationsService'
import { IndicationOfProductTranslate } from '@app/components/IndicationOfProductTranslate'
import { Button, Icon } from '@new-lucentum'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { StyledExpandedView, TableCol, TableRow } from './styledHistoryLayoutData'
import { Span } from './styles'

const indicationStyle = {
  fontWeight: '600!important',
  zIndex: 3000,
}
export const ExpandedHistoryDSView = props => {
  const [showId, setShowId] = useState(false)
  const designLanguage = useSelector(
    state => state.appState.detail.data.design.applicationLanguageCode
  )

  const [receivedLanguages, setReceivedLanguages] = useState([])
  const [selectedTranslateLang, setSelectedTranslateLang] = useState(UILang)
  const languagesGlobal = useSelector(state => state.globals.data.languages)
  const UILang = useSelector(state => state.uiState.language.language)
  const [selectedPriority, setSelectedPriority] = useState({})

  useEffect(() => {}, [props, selectedPriority])

  let data = props.selectedRow

  // remove duplicate languages (indicationProducts)
  const languages = receivedLanguages.filter(
    (lang, index) => receivedLanguages.indexOf(lang) === index
  )

  const ipRegisterLanguages = () => {
    return languagesGlobal?.map(lang => {
      return (
        data?.indicationProducts &&
        data?.indicationProducts.map(ind => {
          let lan = lang.code.toUpperCase()
          if (lan === ind.languageCode) {
            receivedLanguages.push(lang)
          }
        })
      )
    })
  }

  const getDisplayedLang = () => {
    if (languages.filter(lang => lang.code === UILang).length > 0) {
      return UILang
    } else if (languages.filter(lang => lang.code === 'en').length > 0) {
      return 'en'
    } else {
      return designLanguage
    }
  }

  useEffect(() => {
    getDisplayedLang()
  }, [])

  useEffect(
    () => {
      ipRegisterLanguages()
    },
    [receivedLanguages]
  )

  return (
    <StyledExpandedView>
      {data ? (
        <span>
          <TableCol>
            <TableRow>
              <TableCol className="verbalText"> {data?.verbalElementText}</TableCol>
            </TableRow>
          </TableCol>
          <TableRow>
            <TableCol className="column">
              <TableRow>
                <TableCol>{t('applicationdate.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.applicationDate &&
                    moment(data.applicationDate)
                      .utc()
                      .format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('history.application.publication.date.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.applicationPublicationDate &&
                    moment(data.applicationPublicationDate)
                      .utc()
                      .format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('history.deferment.expiration.date.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.defermentExpirationDate &&
                    moment(data.defermentExpirationDate)
                      .utc()
                      .format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('history.design.identifier.key')}</TableCol>
                <TableCol className="data-col">{data?.designIdentifier}</TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('effective.date.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.effectiveDate &&
                    moment(data.effectiveDate)
                      .utc()
                      .format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('publication.date.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.publicationDate &&
                    moment(data.publicationDate)
                      .utc()
                      .format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              {data?.eventName && (
                <TableRow>
                  <TableCol>{t('history.event.name')}</TableCol>
                  <TableCol className="data-col">{data?.eventName}</TableCol>
                </TableRow>
              )}
              {data?.eventDate && (
                <TableRow>
                  <TableCol>{t('history.event.date')}</TableCol>
                  <TableCol className="data-col">
                    {data?.eventDate &&
                      moment(data.eventDate)
                        .utc()
                        .format('DD/MM/YYYY')}
                  </TableCol>
                </TableRow>
              )}
              <TableRow>
                <TableCol>{t('locarno.classification.key')}</TableCol>
                <TableCol>{data?.classNumber}</TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('search.report.column.indprod.key')}</TableCol>
                <TableCol>
                  <IndicationOfProductTranslate
                    textStyle={indicationStyle}
                    noOriginalLanguage
                    indicationOfProduct={data?.indicationProducts}
                    originalLanguage={UILang}
                    languageList={languages}
                    initialLanguage={getDisplayedLang()}
                    maxOptionsHeight={150}
                    showCompareLink={false}
                    section="HISTORY"
                    setLanguageOuter={value => {
                      setSelectedTranslateLang(getDisplayedLang(value))
                    }}
                  />
                </TableCol>
              </TableRow>
            </TableCol>
            <TableCol className="column">
              <TableRow>
                <TableCol>{t('registration.date.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.registrationDate &&
                    moment(data.registrationDate)
                      .utc()
                      .format('DD/MM/YYYY')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('tm.details.label.registrationNumber.key')}</TableCol>
                <TableCol className="data-col">{data?.registrationNumber}</TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('history.exhibition.name.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.exhibitionPriorities &&
                    data?.exhibitionPriorities.map(exhibit => exhibit?.exhibitionName).join(', ')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('convention.priority.number.key')}</TableCol>
                <TableCol className="data-col">
                  {data?.priorities?.length === 1
                    ? data?.priorities && data.priorities[0]?.priorityNumber
                    : data?.priorities &&
                      data?.priorities?.length > 1 && (
                        <Span>
                          <Button
                            type="dropdown"
                            title={
                              Object.keys(selectedPriority).length > 0
                                ? selectedPriority?.priorityNumber
                                : data?.priorities && data?.priorities[0]?.priorityNumber
                            }
                          >
                            {data?.priorities &&
                              data?.priorities.length > 0 &&
                              data?.priorities?.map((priority, i) => {
                                return (
                                  <Button
                                    key={i}
                                    title={priority?.priorityNumber}
                                    onClick={() => setSelectedPriority(priority)}
                                  />
                                )
                              })}
                          </Button>
                        </Span>
                      )}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('priority.date.key')}</TableCol>
                <TableCol className="data-col">
                  {Object.keys(selectedPriority).length > 0
                    ? moment(selectedPriority.priorityDate)
                        .utc()
                        .format('DD/MM/YYYY')
                    : data?.priorities &&
                      (data?.priorities[0]?.priorityDate
                        ? moment(data.priorities[0]?.priorityDate)
                            .utc()
                            .format('DD/MM/YYYY')
                        : '')}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t('priority.country.key')}</TableCol>
                <TableCol className="data-col">
                  {Object.keys(selectedPriority).length > 0
                    ? selectedPriority?.priorityCountry
                    : data?.priorities && data?.priorities[0]?.priorityCountry}
                </TableCol>
              </TableRow>
            </TableCol>
          </TableRow>
          <TableCol>
            <TableRow id="history-id-data">
              <TableCol>
                {t('history.id.key')}
                <Icon className="chevron" chevronRight onClick={() => setShowId(!showId)} />
                {showId && Object.entries(data)[0][1]}
              </TableCol>
            </TableRow>
          </TableCol>
        </span>
      ) : (
        <div className="null-data-text">{t('null.data.key')}</div>
      )}
    </StyledExpandedView>
  )
}

export default ExpandedHistoryDSView
