import validator from 'validator'
import { isInvalidString, isEmptyString } from './validators'

export class Validation {
  constructor(type, validate, conditional) {
    this.validate = validate
    this.type = type
    this.conditional = conditional
  }
}

export class Form {
  setFieldValues(payload) {
    let obj = {}
    if (payload.value === null || typeof payload.value === 'undefined') {
      obj[payload.field] = this.fields.find(el => el.fieldName === payload.field).defaultValue
    } else {
      obj[payload.field] = payload.value
    }
    return obj
  }

  setFieldStringCodesValues(payload) {
    const field = this.fields.find(field => field.fieldName === payload.field)
    let obj = {}
    if (field && typeof field.set === 'function') {
      obj[payload.field] = field.set(payload.value)
      return obj
    }
    obj[payload.field] = payload.value
    return obj
  }

  addField(field) {
    this.fields.push(field)
    return this
  }
  constructor() {
    this.fields = []
  }
}

export class Field {
  addValidation(...validations) {
    this.validations = [...this.validations, ...validations]
    return this
  }
  constructor(fieldName, set, defaultValue) {
    this.fieldName = fieldName
    this.set = set
    this.validations = []
    this.defaultValue = defaultValue
  }
}

export const customFunctionValidations = {
  unsecureString: (maxlength = 100) => value => !isInvalidString(value, maxlength),
  documents: true,
  required: value => value && !isEmptyString(` ${value}`.trim()),
  empty: validator.isEmpty,
  email: validator.isEmail,
  maxlength: maxlength => value => validator.isLength(value, { max: maxlength }),
  minLength: minLength => value => validator.isLength(value, { min: minLength }),
  in: values => value => validator.isIn(value, values),
  date: validator.isDate,
  arrayNotEmpty: value => (value.length ? !!value.length : false),
}

export const customValidations = {
  required: new Validation('required', customFunctionValidations.required),
  less100Characters: new Validation(
    'unsecureString',
    customFunctionValidations.unsecureString(100)
  ),
  less20Characters: new Validation('unsecureString', customFunctionValidations.unsecureString(20)),
  validPhoneFax: new Validation('badFormat', customFunctionValidations.phoneFax),
  validPostalCode: new Validation('badFormat', customFunctionValidations.postalCode),
  validEmail: new Validation('badFormat', customFunctionValidations.email),
  validWebsite: new Validation('badFormat', customFunctionValidations.website),
  documents: new Validation('badFormat', customFunctionValidations.documents),
  maxlength: maxLength =>
    new Validation('maxlength', customFunctionValidations.maxlength(maxLength)),
  minLength: minLength =>
    new Validation('minlength', customFunctionValidations.minLength(minLength)),
}
