import React from 'react'
import styled, { css } from 'styled-components'

const BadgeWrapper = styled.div`
  position: relative;
`

const BadgeBubble = styled.span`
  ${({ theme, customPosition }) => css`
    background: ${theme.colorError};
    color: ${theme.colorWhite};
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(1) translate(45%, -45%);
    transform-origin: 100% 0%;
    border-radius: ${theme.sizeSmall};
    font-size: ${theme.fontSizeSmaller};
    font-weight: bold;
    padding: 1px 4px;
    ${customPosition &&
      css`
        transform: scale(1) translate(${customPosition.x}, ${customPosition.y});
      `}
  `}
`

const BadgeComponent = props => {
  const { badgeContent, customPosition } = props
  const showBadge = !!badgeContent && badgeContent > 0
  return (
    <BadgeWrapper>
      {showBadge && <BadgeBubble customPosition={customPosition}>{badgeContent}</BadgeBubble>}
      {props.children}
    </BadgeWrapper>
  )
}

export const Badge = BadgeComponent
