/**
 * Override React Table pagination component
 */
import { t } from '@app/common/translationsService'
import Block from '@app/new-lucentum/components/Block/Block'
import classnames from 'classnames'
import React, { Component } from 'react'

import {
  StyledChevronLeft,
  StyledChevronRight,
  StyledPagination,
  StyledPushChevronLeft,
  StyledPushChevronRight,
} from './stylesPagination'

class Pagination extends Component {
  constructor(props) {
    super()

    this.getSafePage = this.getSafePage.bind(this)
    this.changePage = this.changePage.bind(this)
    this.applyPage = this.applyPage.bind(this)

    this.state = {
      page: props.page,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.page })
  }

  getSafePage(page) {
    if (isNaN(page)) {
      page = this.props.page
    }
    if (this.props.canNextFromData) return page

    return Math.min(Math.max(page, 0), this.props.pages - 1)
  }

  changePage(page) {
    page = this.getSafePage(page)
    this.setState({ page })
    if (this.props.page !== page) {
      this.props.onPageChange(page)
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault()
    }
    const page = this.state.page
    this.changePage(page === '' ? this.props.page : page)
  }

  goFirstPage = () => {
    if (!this.props.canPrevious) return
    this.changePage(0)
  }

  goNextPage = () => {
    if (!this.props.canNext) return
    this.changePage(this.props.page + 1)
  }

  goPreviousPage = () => {
    if (!this.props.canPrevious) return
    this.changePage(this.props.page - 1)
  }

  goLastPage = () => {
    if (!this.props.canNext) return
    this.changePage(this.props.pages)
  }

  render() {
    const {
      pages,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      onPageSizeChange,
      className,
      showTotalPages = true,
      data,
      page,
    } = this.props

    let pageNo = page + 1
    const rangeStart = pageNo * pageSize - pageSize + 1
    const rangeEnd = Math.min(pageNo * pageSize, data.length)

    return (
      <StyledPagination>
        <Block alignContents="right">
          <div
            className={classnames(className, '-pagination')}
            id="pagination"
            style={this.props.style}
          >
            <div>
              {showPageSizeOptions && (
                <span className="select-wrap -pageSizeOptions">
                  <span className="rows-per-page">{t('rows_per_page_key')}</span>
                  <select onChange={e => onPageSizeChange(Number(e.target.value))} value={pageSize}>
                    {pageSizeOptions.map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </span>
              )}
              <span className="-pageInfo">
                {showTotalPages ? (
                  <React.Fragment>
                    <span className="-totalPages">{rangeStart} - </span>
                    <span className="-totalPages">{rangeEnd}</span> {this.props.ofText}
                    <span className="-totalPages margin">{data.length}</span>
                  </React.Fragment>
                ) : null}
              </span>
            </div>
            <StyledPushChevronLeft onClick={this.goFirstPage} />
            <StyledChevronLeft onClick={this.goPreviousPage} />
            <StyledChevronRight onClick={this.goNextPage} />
            <StyledPushChevronRight onClick={this.goLastPage} />
          </div>
        </Block>
      </StyledPagination>
    )
  }
}

export default Pagination
