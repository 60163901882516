import { queryParamsToObj } from '@app/common/searchUtilities'

import TMDSContextService from './tmdsContextService'

let _history = null
let routeHistory = []
const ROUTE_HISTORY_LENGTH = 7
export const setHistory = history => {
  _history = history

  routeHistory.push(history.location)
  _history.listen((location, action) => {
    if (action === 'PUSH') {
      routeHistory = [location, ...routeHistory.slice(0, ROUTE_HISTORY_LENGTH - 1)]
    } else if (action === 'POP') {
      const removedRoute = routeHistory.shift()
    }
  })
}

export const getHistory = () => _history
export const getRouteState = () => {
  return _history.location.state
}
export const changeRouteState = newState => {
  _history.replace({ ..._history.location, state: { ..._history.location.state, ...newState } })
}

export const refresh = () => {
  _history.replace({ ..._history.location })
}

export const getUrlWithContext = url => {
  const context = TMDSContextService.get()
  const hasContextIncluded = url.startsWith(`/${context}`)
  return hasContextIncluded ? url : `/${context}${url}`
}

const parseRouteParams = (route, routeParams) => {
  let preparedRoute = route
  if (routeParams) {
    Object.keys(routeParams).forEach(routeParam => {
      const routeParamString = `:${routeParam}`
      if (route.indexOf(routeParamString) > -1) {
        preparedRoute = preparedRoute.replace(routeParamString, routeParams[routeParam])
      }
    })
  }
  return preparedRoute
}

export const getPreparedRoute = (route, routeParams) => {
  const preparedRoute = parseRouteParams(route, routeParams)
  return `#${getUrlWithContext(preparedRoute)}`
}

export const navigate = (route, routeParams) => {
  let prepared = route
  if (typeof route === 'string') {
    const preparedRoute = parseRouteParams(route, routeParams)
    const targetUrl = getUrlWithContext(preparedRoute)
    prepared = targetUrl
  } else {
    const preparedRoute = parseRouteParams(prepared.pathname, routeParams)
    prepared.pathname = getUrlWithContext(preparedRoute)
  }
  _history.push(prepared)
}

export const goBack = () => {
  _history.goBack()
}

export const goBackRoute = (fallbackRoute = '/') => {
  const currentPathname = _history.location.pathname
  const index = routeHistory.findIndex(element => !element.pathname.includes(currentPathname))
  if (index >= 0) {
    _history.go(-index)
  } else {
    navigate(fallbackRoute)
  }
}

export const openUrl = url => {
  window.open(url, '_blank')
}

export const getCurrentUrl = () => {
  return `${_history.location.pathname}${_history.location.search}`
}

export const getQueryParams = () => {
  const { location } = _history
  const { search } = location
  const queryParams = search.substr(1)
  return {
    queryParamsString: queryParams,
    queryParamsObject: queryParamsToObj(queryParams),
  }
}

const checkEmptyValue = value => {
  const isEmptyArray = Array.isArray(value) && value.length === 0
  const isEmptyString = value === ''
  return isEmptyArray || isEmptyString
}

const sanitizeQueryParamsString = queryParamsString => {
  let sanitizedQueryParamString = queryParamsString
  if (sanitizedQueryParamString.startsWith('&')) {
    sanitizedQueryParamString = sanitizedQueryParamString.slice(1)
  }
  if (!sanitizedQueryParamString.startsWith('?')) {
    sanitizedQueryParamString = `?${sanitizedQueryParamString}`
  }
  if (sanitizedQueryParamString.endsWith('&')) {
    sanitizedQueryParamString = sanitizedQueryParamString.slice(0, -1)
  }
  return sanitizedQueryParamString
}

export const changeQueryParams = (queryParams = {}, options = { mantainEmpty: false }) => {
  const { location } = _history
  const { pathname, search } = location
  let preparedSearch = `${search}`
  Object.keys(queryParams).forEach((queryParam, index) => {
    const nextQueryParamValue = queryParams[queryParam]
    if (search.indexOf(`${queryParam}=`) > -1) {
      var pattern = new RegExp('(' + queryParam + '=).*?(&|$)')
      preparedSearch =
        checkEmptyValue(nextQueryParamValue) && !options.mantainEmpty
          ? preparedSearch.replace(pattern, '')
          : preparedSearch.replace(pattern, '$1' + nextQueryParamValue + '$2')
    } else {
      const nextQueryParam = `${queryParam}=${nextQueryParamValue}`
      if (
        options.mantainEmpty ||
        (!options.mantainEmpty && !checkEmptyValue(nextQueryParamValue))
      ) {
        preparedSearch += `&${nextQueryParam}&`
        preparedSearch = sanitizeQueryParamsString(preparedSearch)
      }
    }
  })

  const sanitizedPreparedSearch = sanitizeQueryParamsString(preparedSearch)

  navigate(`${pathname}${sanitizedPreparedSearch}`)
}

export const clearQueryParams = () => {
  const { location } = _history
  const { pathname } = location
  navigate(pathname)
}

export default {
  setHistory,
  getHistory,
  getRouteState,
  changeRouteState,
  refresh,
  navigate,
  goBack,
  goBackRoute,
  getUrlWithContext,
  openUrl,
  getCurrentUrl,
  getQueryParams,
  changeQueryParams,
  clearQueryParams,
  getPreparedRoute,
}
