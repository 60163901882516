import React from 'react'

import Button from '../Button'
import { LanguageLink } from './styles'

export default ({ src = [], onLanguage, currentLang = 'English', ...props }) => (
  <Button
    big
    {...props}
    onDropdown={e => onLanguage && onLanguage(e)}
    title={currentLang}
    type="dropdown"
  >
    {src.map(({ lang, code, title, enabled }) => (
      <LanguageLink key={code} title={title} disabled={!enabled}>
        {lang}
      </LanguageLink>
    ))}
  </Button>
)
