import { generalDataManager } from '@app/common/dataManagers'
import { TMDSTrackingService } from '@app/common/services'
import { isValidEmail } from '@app/common/validators'

const fileTypes = ['jpg', 'xls', 'csv', 'doc', 'txt', 'pdf']
const FILE_UPLOAD_MAX_SIZE_LIMIT = 5 * 1024 * 1024

export const updateFeedbackCaptcha = value => ({
  type: 'CHANGE_FEEDBACK_CAPTCHA',
  payload: { recaptchaResponseKey: value },
})

export const changeEnquiryType = (officeId, supportedLanguages, sendToEmail) => ({
  type: 'CHANGE_ENQUIRY_TYPE_OPTION',
  payload: {
    officeId,
    supportedLanguages,
    sendToEmail,
  },
})

export const updateTextArea = value => ({
  type: 'CHANGE_FEEDBACK_TEXTAREA',
  payload: { text: value },
})

export const updateEmail = value => ({
  type: 'CHANGE_FEEDBACK_EMAIL',
  payload: { email: value },
})

export const resetFeedbackForm = () => ({
  type: 'RESET_FEEDBACK_FORM',
})

export const closeErrorNotification = () => ({
  type: 'CLOSE_ERROR_NOTIFICATION',
})

const sendEmailToBackend = body => ({
  type: 'SEND_FEEDBACK',
  payload: generalDataManager.sendFeedback(body),
})

const sendEmailWithoutFile = (email, text, recaptchaResponseField, dispatch, sendToEmail) => {
  return dispatch(
    sendEmailToBackend({
      email: 'no-reply@euipo.europa.eu',
      text: `User’s Email address: ${email} \n\nComments: ${text}`,
      recaptchaResponseField,
      sendToEmail,
    })
  )
}

const sendEmailWithFile = (email, text, recaptchaResponseField, file, dispatch, sendToEmail) => {
  var reader = new FileReader()

  reader.onloadend = () => {
    return dispatch(
      sendEmailToBackend({
        email: 'no-reply@euipo.europa.eu',
        text: `User’s Email address: ${email} \n\nComments: ${text}`,
        recaptchaResponseField,
        file: {
          name: file.name,
          data: reader.result.split(',')[1],
        },
        sendToEmail,
      })
    )
  }
  if (file) {
    reader.readAsDataURL(file)
  }
}

const sendEmail = (email, text, recaptchaResponseField, file, dispatch, sendToEmail) => {
  return file
    ? sendEmailWithFile(email, text, recaptchaResponseField, file, dispatch, sendToEmail)
    : sendEmailWithoutFile(email, text, recaptchaResponseField, dispatch, sendToEmail)
}

export const updateFile = (files = [], fileValue = '') => ({
  type: 'CHANGE_FEEDBACK_FILE',
  payload: {
    files,
    fileValue,
  },
})

const showErrorMessage = errorMessage => ({
  type: 'INVALID_FEEDBACK',
  payload: {
    errorMessage,
  },
})

const isInputFileValid = files => {
  if (files.length > 0) {
    const { name, size } = files[0]
    const fileExtension = name.split('.').pop()
    return fileTypes.includes(fileExtension) && size <= FILE_UPLOAD_MAX_SIZE_LIMIT
  }
  return true
}

const validateInput = feedbackState => {
  const { email, text, recaptchaResponseKey } = feedbackState
  return email !== '' && recaptchaResponseKey !== null && text.trim() !== ''
}

export const sendFeedback = () => (dispatch, getState) => {
  const state = getState()
  const { email, text, recaptchaResponseKey, files, sendToEmail } = state.uiState.feedback
  if (validateInput(state.uiState.feedback)) {
    if (isValidEmail(email)) {
      if (isInputFileValid(files))
        sendEmail(email, text, recaptchaResponseKey, files[0], dispatch, sendToEmail)
      else dispatch(showErrorMessage('enquiries_file_validation_error'))
    } else {
      dispatch(showErrorMessage('enquiries_email_validation_error'))
    }
  } else {
    dispatch(showErrorMessage('enquiries_validation_error'))
  }
}

export const sendFeedbackForm = formData => dispatch => {
  TMDSTrackingService.trackYourFeedbackSubmitEvent()
  return sendEmail(
    formData.email,
    formData.description,
    formData.recaptcha,
    formData.file,
    dispatch,
    formData.type.email
  )
}

export const openFeedbackModal = () => {
  TMDSTrackingService.trackOpenFeedbackWindowEvent()
  return {
    type: 'OPEN_FEEDBACK_MODAL',
  }
}

export const closeFeedbackModal = () => ({
  type: 'CLOSE_FEEDBACK_MODAL',
})
