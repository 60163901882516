import * as React from 'react'
import yeast from 'yeast'

import Tooltip from '../Tooltip'
import { Card as StyledCard, Favorite } from './styles'

export default ({ favorite, onFavorite, children, className, ...props }) => {
  const iconState = favorite === 'yes'
  const icon = iconState ? 'pin-outline' : 'pin'
  const idFv = yeast()

  const renderChildren = () =>
    React.Children.map(children, child =>
      child.type === Tooltip ? React.cloneElement(child, { id: idFv }) : child
    )

  return (
    <StyledCard padding className={className} {...props}>
      {favorite && (
        <Favorite
          borderless
          data-for={idFv}
          data-tip
          icon={icon}
          iconState={iconState}
          onClick={onFavorite}
        />
      )}
      {renderChildren()}
    </StyledCard>
  )
}
