import { TMDSToastService, TMDSTrackingService } from '@app/common/services'
import NotificationError from '@app/components/NotificationError'
import store from '@app/store'
import { Block, Button, Modal } from '@new-lucentum'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { FieldsetLCT, InputFormLCT } from '../SharedStyles'
import { TextArea } from './styles'

const launchSendEmail = (
  validateAction,
  sendEmail,
  setShareEmailNotification,
  recaptchaMissing
) => {
  validateAction()

  const state = store.getState()
  const { errors, fields } = state.uiState.shareSearch.formState
  const invalid = Object.keys(errors).filter(error => errors[error] === true).length >= 1
  if (!invalid) {
    fields.recaptchaResponseField === null
      ? setShareEmailNotification(recaptchaMissing)
      : sendEmail()
  }
}

const ShareSearch = ({
  reCaptchaSiteKey,
  backEndErrorMessage,
  literals,
  showModalShareByEmail,
  sendEmail,
  shareSearchFieldsValues,
  setShareEmailNotification,
  validateEntireForm,
  errorsShareLink,
  clearShareSearchForm,
  closeModalShareByEmail,
  onChangeEmailField,
}) => {
  let captchaElem

  const sendEmailAndResetCaptcha = () => {
    sendEmail(shareSearchFieldsValues)
    captchaElem.reset()
    onChangeEmailField('recaptchaResponseField', null)
    closeModalShareByEmail()
    TMDSTrackingService.trackShareByEmailClickEvent()
    TMDSToastService.showNotificationSuccess(
      <span data-test-id="email-success-message">
        {literals.results_share_succesfully}: {shareSearchFieldsValues.email}
      </span>
    )
  }

  const clearForm = () => {
    captchaElem.reset()
    clearShareSearchForm()
  }

  return (
    showModalShareByEmail && (
      <Modal
        onSubmit={() => {
          launchSendEmail(
            validateEntireForm,
            sendEmailAndResetCaptcha,
            setShareEmailNotification,
            literals.recaptcha_missing
          )
        }}
        onRequestClose={closeModalShareByEmail}
        textButtonClose={literals['close.key']}
        isOpen={showModalShareByEmail}
        ariaHideApp={false}
        title={literals['invite.key']}
        textButtonSubmit={literals['send.key']}
        submitButtonProps={{ 'data-test-id': 'invite-send-button' }}
        closeButtonProps={{ 'data-test-id': 'invite-close-button' }}
      >
        <Block>
          {backEndErrorMessage && backEndErrorMessage.error === true && (
            <NotificationError
              literals={literals}
              message={backEndErrorMessage.errorMessage}
              details={backEndErrorMessage.errorDetails}
            />
          )}
          <FieldsetLCT label={literals['yourname.key']}>
            <InputFormLCT
              placeholder={literals.results_share_name}
              value={shareSearchFieldsValues.name}
              onChange={e => {
                onChangeEmailField('name', e.currentTarget.value)
              }}
              data-test-id="your-name"
            />
          </FieldsetLCT>
          <FieldsetLCT
            label={literals['name.key']}
            errorMessage={literals['login.register.error.empty.email.key']}
            showError={errorsShareLink.email === true}
          >
            <InputFormLCT
              placeholder={literals.results_share_email}
              value={shareSearchFieldsValues.email}
              onChange={e => {
                onChangeEmailField('email', e.currentTarget.value)
              }}
              data-test-id="friend-email-address"
            />
          </FieldsetLCT>
          <FieldsetLCT label={literals['email.invite.key']}>
            <InputFormLCT
              placeholder={literals.results_share_subject}
              value={shareSearchFieldsValues.subject}
              onChange={e => {
                onChangeEmailField('subject', e.currentTarget.value)
              }}
              data-test-id="email-subject"
            />
          </FieldsetLCT>
          <FieldsetLCT
            label={literals['yourtext.key']}
            errorMessage={literals['invite_form_error_empty_text_key']}
            showError={errorsShareLink.text === true}
          >
            <TextArea
              value={shareSearchFieldsValues.text}
              onChange={e => {
                onChangeEmailField('text', e.currentTarget.value)
              }}
              data-test-id="your-text"
            />
          </FieldsetLCT>
          <ReCAPTCHA
            sitekey={reCaptchaSiteKey}
            ref={elem => (captchaElem = elem)}
            onChange={value => onChangeEmailField('recaptchaResponseField', value)}
          />
          <Button data-test-id="invite-clear-button" link title="Clear" onClick={clearForm} />
        </Block>
      </Modal>
    )
  )
}

export default ShareSearch
