import React from 'react'

import { t } from '@app/common/translationsService'
import { TMDSGlobalsService } from '@app/common/services'

import { Block } from '@new-lucentum'

import { Bullet } from './styles'

export const TranslationsProvidedBy = ({ language = 'en', ...props }) => {
  const translationProvider = TMDSGlobalsService.getTranslationProvider(language)
  return (
    <Block middle {...props}>
      {t('goods_and_services_translation_provided_by')}
      <Bullet gray />
      {t('goods_and_services_tmclass_translate')}
      <Bullet red />
      {translationProvider === 'DEEPL' ? 'DeepL' : t('goods_and_services_google_translate')}
    </Block>
  )
}

export default TranslationsProvidedBy
