import React from 'react'
import PropTypes from 'prop-types'
import { StyledFileInput, Error, StyledAddImageButton } from './styles'
import { Block, Icon } from '@new-lucentum'

class FileInputMobile extends React.Component {
  state = { chosenFile: '' }
  render() {
    const {
      label,
      identifier,
      onChange,
      inputStyle,
      blockTitle,
      showError,
      errorMessage,
    } = this.props
    const applyChange = e => {
      this.setState({ chosenFile: e.target.value.replace(/^.*[\\\/]/, '') })
      onChange(e)
    }

    return (
      <div>
        <StyledFileInput>
          <label htmlFor={identifier}>{label.replace(/ *\([^)]*\) */g, '')}</label>
          <input type="file" id={identifier} onChange={applyChange} style={inputStyle} />
          <Block>
            <StyledAddImageButton>
              <label htmlFor={identifier} className="labelAsInput">
                <Icon camera />
                {this.state.chosenFile ? this.state.chosenFile : blockTitle}
              </label>
            </StyledAddImageButton>
          </Block>
        </StyledFileInput>
        <Error isVisible={showError}>{errorMessage}</Error>
      </div>
    )
  }
}
FileInputMobile.propTypes = {
  label: PropTypes.string,
  identifier: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

FileInputMobile.defaultProps = {
  inputStyle: { width: '100%' },
}

export default FileInputMobile
