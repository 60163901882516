import styled from 'styled-components'
import { Block } from '@new-lucentum'

export const BoxPanel = styled.div`
  border-radius: 5px;
  border-style: solid;
  border-width: 0px;
  box-shadow: ${({ theme: { line: px, colorBorderBox: c } }) =>
    `-${px} ${px} ${px} ${px} ${c} , ${px} 0px ${px} ${px} ${c}`};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow: ${({ overflow }) => overflow || 'initial'};
  width: 100%;
  background-color: white;
`

export const Title = styled(Block)`
  align-items: center;
  border-color: ${({ theme }) => theme.colorBorderBox};
  border-style: solid;
  border-width: 0px ${({ theme }) => `${theme.line} ${theme.line}`} 0px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.75rem;
  font-weight: bold;
  justify-content: center;
  left: 0;
  min-height: 90px;
  top: 0;
  width: 100%;
`
export const BoxCell = styled(Block)`
  ${({ border, theme }) =>
    border ? `border-width: 0px ${theme.line} ${theme.line} 0px ;` : `border-width: 0px ;`};
  border-color: ${({ theme }) => `${theme.colorBorderBox}`};
  border-style: solid;
  box-sizing: border-box;
  &:hover {
    box-shadow: ${({ theme }) => theme.focusShadow};
    z-index: 99;

    & .hoverTitle {
      background-color: ${({ theme }) => theme.colorLighter};
    }
  }
`
