import { PRIORITY_FIELDS } from '@app/common/dataManagers/types'
export const priorityFields = [
  {
    label: 'date.key',
    path: PRIORITY_FIELDS.PRIORITY_DATE,
    isDate: true,
  },
  { label: 'country.key', path: PRIORITY_FIELDS.COUNTRY_CODE },
  {
    label: 'priority.status.key',
    path: PRIORITY_FIELDS.STATUS_CODE,
    pathTranslated: PRIORITY_FIELDS.STATUS_CODE_TRANSLATED,
  },
  {
    label: 'convention.priority.number.key',
    path: PRIORITY_FIELDS.PRIORITY_NUMBER,
  },
  {
    label: 'tm.details.label.partialPriority.key',
    path: PRIORITY_FIELDS.PARTIAL_PRIORITY,
  },
  {
    label: 'tm.details.label.PartialPriorityIndicator.key',
    path: PRIORITY_FIELDS.PARTIAL_PRIORITY_INDICATOR,
  },
  {
    label: 'tm.details.label.comment.key',
    path: PRIORITY_FIELDS.COMMENTS,
  },
]
