import { t } from '@app/common/translationsService'
import { FieldsetLCT } from '@app/components/SharedStyles'
import { Block } from '@new-lucentum'
import { useFormik } from 'formik-latest'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import {
  CertificateRequestFormErrorText as ErrorText,
  CertificateRequestFormFlexContainer,
  CertificateRequestFormInput as Input,
  CertificateRequestFormSelect as Select,
} from '../CertificatesButton.styles'
import { VALIDATION_ERROR_MESSAGE } from '../constants'
import { getCountries } from '../utils'

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(() => t(VALIDATION_ERROR_MESSAGE))
    .max(350)
    .nullable(),
  lastName: Yup.string()
    .required(() => t(VALIDATION_ERROR_MESSAGE))
    .max(260)
    .nullable(),
  address: Yup.string()
    .required(() => t(VALIDATION_ERROR_MESSAGE))
    .max(500)
    .nullable(),
  city: Yup.string()
    .required(() => t(VALIDATION_ERROR_MESSAGE))
    .max(100)
    .nullable(),
  postCode: Yup.string()
    .required(() => t(VALIDATION_ERROR_MESSAGE))
    .max(10)
    .nullable(),
  country: Yup.string()
    .required(() => t(VALIDATION_ERROR_MESSAGE))
    .nullable(),
})

const CustomInput = ({ field, form, ...props }) => {
  const hasValue = !!form.values[field.name]

  return (
    <div>
      <Input {...field} {...props} type="text" />
      {hasValue
        ? form.errors[field.name] && <ErrorText>{form.errors[field.name]}</ErrorText>
        : form.touched[field.name] &&
          form.errors[field.name] && <ErrorText>{form.errors[field.name]}</ErrorText>}
    </div>
  )
}

const CustomSelect = ({ field, form, ...props }) => {
  const hasValue = !!form.values[field.name]

  return (
    <div>
      <Select {...field} {...props}>
        {props.options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {hasValue
        ? form.errors[field.name] && <ErrorText>{form.errors[field.name]}</ErrorText>
        : form.touched[field.name] &&
          form.errors[field.name] && <ErrorText>{form.errors[field.name]}</ErrorText>}
    </div>
  )
}

const CertificateRequestForm = ({ setFormIsValid, onChange }) => {
  const formikRef = useRef(null)
  const territories = useSelector(state => state.globals.data.selects.territories)
  const contactDetails = useSelector(state => state.uiState.certificate.contactDetails)
  const [countryOptions, setCountryOptions] = useState([])
  const contactDetailsWithDefaults = {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postCode: '',
    country: '',
    ...contactDetails,
  }
  const formik = useFormik({
    initialValues: contactDetailsWithDefaults,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  })

  useEffect(
    () => {
      if (formikRef.current) {
        formikRef.current.setValues(contactDetails)
      }
    },
    [contactDetails]
  )

  useEffect(
    () => {
      formikRef.current = formik
    },
    [formik]
  )

  useEffect(
    () => {
      setCountryOptions(getCountries(territories))
    },
    [territories]
  )

  useEffect(
    () => {
      onChange(formik.values)
    },
    [onChange, formik.values]
  )

  useEffect(
    () => {
      const isFormValid = formik.isValid
      const hasTouchedFields = Object.keys(formik.touched).length > 0
      const hasEmptyValues = Object.values(formik.values).some(value => !value)

      setFormIsValid(isFormValid && (hasTouchedFields || !hasEmptyValues))
    },
    [setFormIsValid, formik.isValid, formik.touched, formik.values]
  )

  return (
    <form>
      <CertificateRequestFormFlexContainer>
        <Block marginTop width6of12 column>
          <FieldsetLCT label={t('freeformatname.key')} required>
            <CustomInput
              type="text"
              id="firstName"
              name="firstName"
              field={formik.getFieldProps('firstName')}
              form={formik}
            />
          </FieldsetLCT>
        </Block>

        <Block marginTop width6of12 column>
          <FieldsetLCT label={t('surname.key')} required>
            <CustomInput
              type="text"
              id="lastName"
              name="lastName"
              field={formik.getFieldProps('lastName')}
              form={formik}
            />
          </FieldsetLCT>
        </Block>
      </CertificateRequestFormFlexContainer>

      <Block marginTop column style={{ float: 'inherit' }}>
        <FieldsetLCT label={t('address.key')} required>
          <CustomInput
            type="text"
            id="address"
            name="address"
            field={formik.getFieldProps('address')}
            form={formik}
          />
        </FieldsetLCT>
      </Block>

      <CertificateRequestFormFlexContainer>
        <Block marginTop width6of12 column>
          <FieldsetLCT label={t('town.key')} required>
            <CustomInput
              type="text"
              id="city"
              name="city"
              field={formik.getFieldProps('city')}
              form={formik}
            />
          </FieldsetLCT>
        </Block>

        <Block marginTop width6of12 column>
          <FieldsetLCT label={t('post.code.key')} required>
            <CustomInput
              type="text"
              id="postCode"
              name="postCode"
              field={formik.getFieldProps('postCode')}
              form={formik}
            />
          </FieldsetLCT>
        </Block>
      </CertificateRequestFormFlexContainer>

      <Block marginTop width6of12 column>
        <FieldsetLCT label={t('country.key')} required>
          <CustomSelect
            id="country"
            name="country"
            options={countryOptions}
            field={formik.getFieldProps('country')}
            form={formik}
          />
        </FieldsetLCT>
      </Block>
    </form>
  )
}

export default CertificateRequestForm
