import moment from 'moment'
import createRange from 'ramda/src/range'
import React, { Component } from 'react'

import Button from '../Button'
import Icon from '../Icon'
import DayCell from './DayCell.js'
import {
  CalendarHeader,
  DropdownYear,
  Flex,
  Label,
  MonthArrow,
  MonthWrapper,
  WeekDayItem,
} from './styles'
import ranges from './utils/defaultRanges'
import parseInput from './utils/parseInput.js'

function checkRange(dayMoment, range) {
  return (
    dayMoment.isBetween(range['startDate'], range['endDate']) ||
    dayMoment.isBetween(range['endDate'], range['startDate'])
  )
}

function checkStartEdge(dayMoment, range) {
  const { startDate } = range

  return startDate ? dayMoment.startOf('day').isSame(startDate.startOf('day')) : ''
}

function checkEndEdge(dayMoment, range) {
  const { endDate } = range

  return endDate ? dayMoment.endOf('day').isSame(endDate.endOf('day')) : ''
}

function isOusideMinMax(dayMoment, minDate, maxDate, format) {
  return (
    (minDate && dayMoment.isBefore(parseInput(minDate, format, 'startOf'))) ||
    (maxDate && dayMoment.isAfter(parseInput(maxDate, format, 'endOf')))
  )
}

const defaultProps = {
  showMonthArrow: true,
  disableDaysBeforeToday: false,
  disableDaysAfterToday: false,
  classNames: {},
  firstDayOfWeek: 1,
  currentYear: new Date().getFullYear(),
  format: 'DD/MM/YYYY',
  fromLabel: 'From',
  numYearsRange: 300,
  periodLabel: 'Period of Time',
  ranges,
  toLabel: 'To',
}

class Calendar extends Component {
  constructor(props, context) {
    super(props, context)

    const { format, range, offset, firstDayOfWeek, locale, shownDate } = props

    if (locale) moment.locale(locale)

    const date = parseInput(props.date, format, 'startOf')
    const state = {
      date,
      shownDate: (shownDate || (range && range['endDate']) || date).clone().add(offset, 'months'),
      firstDayOfWeek: firstDayOfWeek || moment.localeData().firstDayOfWeek(),
      yearValue: (shownDate || (range && range['endDate']) || date).clone().year(),
    }

    this.state = state

    this.years = createRange(
      this.props.selectableStartYear || +this.props.currentYear - +this.props.numYearsRange,
      (this.props.selectableEndYear || this.props.currentYear) + 1
    ).reverse()
  }

  static defaultProps = defaultProps

  componentDidMount() {
    const { onInit } = this.props
    onInit && onInit(this.state.date)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { range, offset, calendarNumber } = nextProps
    const oldRange = this.props.oldRange
    if (
      range &&
      range['endDate'] &&
      range['endDate'].isSame(range['startDate'], 'month') &&
      (range && range['endDate'] && !range['endDate'].isSame(range['startDate'], 'day')) &&
      (range['startDate'] && range['endDate'])
    ) {
      this.setState({ shownDate: range['endDate'].clone().add(offset, 'month') })
    } else if (
      (range && range['endDate'] && !range['endDate'].isSame(range['startDate'], 'day')) ||
      (oldRange && !oldRange['startDate'].isSame(range['startDate']))
    ) {
      calendarNumber
        ? this.setState({ shownDate: range['startDate'].clone() })
        : this.setState({ shownDate: range['endDate'].clone() })
    }
  }

  getShownDate() {
    const { link, offset } = this.props

    const shownDate = link ? link.clone().add(offset, 'months') : this.state.shownDate

    return shownDate
  }

  handleSelect(newDate) {
    const { link, onChange } = this.props

    onChange && onChange(newDate, Calendar)

    if (!link) {
      this.setState({ date: newDate })
    }
  }

  changeMonth(direction, event) {
    event.preventDefault()
    const { link, linkCB } = this.props

    if (link && linkCB) {
      return linkCB(direction)
    }

    const newMonth = this.state.shownDate.clone().add(direction, 'months')

    this.setState({
      shownDate: newMonth,
    })
  }

  changeYear(year) {
    const selectedYear = this.state.shownDate.year(year)

    this.setState({
      shownDate: selectedYear,
    })
  }

  renderMonthAndYear() {
    const shownDate = this.getShownDate()
    let month = moment.months(shownDate.month())
    const year = shownDate.year()
    const { showMonthArrow, label, calendarLabels } = this.props

    const monthLower = month.toLowerCase()
    month = calendarLabels && calendarLabels[monthLower] ? calendarLabels[monthLower] : month

    return (
      <CalendarHeader>
        <Flex flex="auto" justify="flex-start">
          <Label>{label}</Label>
        </Flex>
        <Flex flex="auto">
          <MonthWrapper>
            {showMonthArrow ? (
              <MonthArrow onClick={this.changeMonth.bind(this, -1)}>
                <Icon chevronLeft />
              </MonthArrow>
            ) : null}
            <span>{month}</span>
            {showMonthArrow ? (
              <MonthArrow className="right" onClick={this.changeMonth.bind(this, +1)}>
                <Icon chevronRight />
              </MonthArrow>
            ) : null}
          </MonthWrapper>
        </Flex>
        <Flex flex="auto" justify="flex-end">
          <DropdownYear
            borderless
            type="dropdown"
            onDropdown={e => this.changeYear(e.key)}
            title={year}
          >
            {this.years.map(y => (
              <Button key={y}>{y}</Button>
            ))}
          </DropdownYear>
        </Flex>
      </CalendarHeader>
    )
  }

  renderWeekdays() {
    const dow = this.state.firstDayOfWeek
    const weekdays = []
    const { calendarLabels } = this.props

    for (let i = dow; i < 7 + dow; i++) {
      let day = moment.weekdaysMin(i)
      const dayLower = day.toLowerCase()
      day = calendarLabels && calendarLabels[dayLower] ? calendarLabels[dayLower] : day
      weekdays.push(<WeekDayItem key={i + day}>{day}</WeekDayItem>)
    }

    return weekdays
  }

  renderDays() {
    // TODO: Split this logic into smaller chunks

    const {
      range,
      minDate,
      maxDate,
      format,
      disableDaysBeforeToday,
      disableDaysAfterToday,
    } = this.props

    const shownDate = this.getShownDate()
    const { date, firstDayOfWeek } = this.state
    const dateUnix = date.unix()

    const monthNumber = shownDate.month()
    const dayCount = shownDate.daysInMonth()
    const startOfMonth = shownDate
      .clone()
      .startOf('month')
      .isoWeekday()

    const lastMonth = shownDate.clone().month(monthNumber - 1)
    const lastMonthDayCount = lastMonth.daysInMonth()

    const nextMonth = shownDate.clone().month(monthNumber + 1)

    const days = []

    // Previous month's days
    const diff = Math.abs(firstDayOfWeek - (startOfMonth + 7)) % 7
    for (let i = diff - 1; i >= 0; i--) {
      const dayMoment = lastMonth.clone().date(lastMonthDayCount - i)
      days.push({ dayMoment, isPassive: true })
    }

    // Current month's days
    for (let i = 1; i <= dayCount; i++) {
      const dayMoment = shownDate.clone().date(i)
      // set days before today to isPassive
      const _today = moment().startOf('day')
      if (
        (disableDaysBeforeToday && Number(dayMoment.diff(_today, 'days')) <= -1) ||
        (disableDaysAfterToday && Number(dayMoment.diff(_today, 'days')) >= 1)
      ) {
        days.push({ dayMoment, isPassive: true })
      } else {
        days.push({ dayMoment })
      }
    }

    // Next month's days
    const remainingCells = 42 - days.length // 42cells = 7days * 6rows
    for (let i = 1; i <= remainingCells; i++) {
      const dayMoment = nextMonth.clone().date(i)
      days.push({ dayMoment, isPassive: true })
    }

    const today = moment().startOf('day')
    return days.map((data, index) => {
      const { dayMoment, isPassive } = data
      const isSelected = !range && dayMoment.unix() === dateUnix
      const isInRange = range && checkRange(dayMoment, range)
      const isStartEdge = range && checkStartEdge(dayMoment, range)
      const isEndEdge = range && checkEndEdge(dayMoment, range)
      const isEdge = isStartEdge || isEndEdge
      const isToday = today.isSame(dayMoment)
      const isSunday = dayMoment.day() === 0

      const isOutsideMinMax = isOusideMinMax(dayMoment, minDate, maxDate, format)

      return (
        <DayCell
          onSelect={this.handleSelect.bind(this)}
          {...data}
          isStartEdge={isStartEdge}
          isEndEdge={isEndEdge}
          isSelected={isSelected || isEdge}
          isInRange={isInRange}
          isSunday={isSunday}
          isToday={isToday}
          key={index}
          isPassive={isPassive || isOutsideMinMax}
        />
      )
    })
  }

  render() {
    return (
      <div>
        {this.renderMonthAndYear()}
        {this.renderWeekdays()}
        {this.renderDays()}
      </div>
    )
  }
}

export default Calendar
