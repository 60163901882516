import { getTermsSeparator } from '../utilities'

export const getIndicationOfProductText = (
  indicationOfProduct = [],
  languageCode = '',
  separator = ''
  // languageCode and separator are optional parameters
) => {
  if (Array.isArray(indicationOfProduct) && indicationOfProduct.length) {
    const languageToFind = languageCode || indicationOfProduct[0].languageCode || ''
    const separatorOfText = separator || getTermsSeparator(languageToFind)
    return indicationOfProduct.reduce((acc, indication, index) => {
      if (
        (indication.languageCode || '').toLowerCase() === languageToFind.toLowerCase() &&
        indication.indicationOfProduct
      ) {
        return acc
          ? `${acc}${separatorOfText} ${indication.indicationOfProduct}`
          : indication.indicationOfProduct
      } else return acc
    }, '')
  } else return ''
}

export const getIndicationOfProductOriginalLanguage = indicationOfProduct =>
  Array.isArray(indicationOfProduct) && indicationOfProduct.length
    ? indicationOfProduct[0].languageCode || ''
    : ''
