import styled from 'styled-components'
import Fieldset from '../Fieldset/Fieldset'
import Block from '../Block/Block'
import Link from '../Link/Link'

const styleText = (theme, splitText) => `
    color: ${theme.colorGray};
    // This only works in Chrome and Safary
    ${splitText &&
      `
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
    `};
    
    &:hover {
        color: ${theme.color}; 
        text-decoration: none;
    }
`

export const NewStyled = styled(Fieldset)`
  ${({ splitText }) =>
    splitText &&
    `
        max-height: 9em; /* em because depends on the size of the text */
        overflow: hidden;
    `};

  p {
    margin: 0;
  }

  & > i {
    font-size: ${({ theme }) => theme.sizeDouble};
  }

  & > div:last-child {
    margin: 0;
  }

  legend {
    color: ${({ theme }) => theme.colorGrayLight};
    font-weight: 200;
    margin: 0;
  }

  fieldset:last-child {
    margin-bottom: 0;
  }
`

/* ${({ theme }) => styleText(theme, true)}; */
export const TitleStyled = styled(Block)`
  color: ${({ theme }) => theme.colorGrayLight};
  ${({ theme }) => theme.footnoteText};
  & > a {
    color: ${({ theme }) => theme.colorGrayLight};
    ${({ theme }) => theme.footnoteText};
  }
`

/* ${({ theme, splitText }) => styleText(theme, splitText)}; */
export const TextStyled = styled.div`
  ${({ theme }) => theme.baseText};
  ${({ splitText }) =>
    splitText &&
    `
        max-height: 3em; /* em because depends on the size of the text */
        -webkit-line-clamp: 2 !important; /* number of lines to show */
    `};
`
export const DayLabel = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 35px;
  line-height: 1.25;

  & > span {
    border-right: 1px solid ${({ theme }) => theme.colorGrayLight};
    padding-right: ${({ theme }) => theme.size};
    padding-top: ${({ theme }) => theme.sizeSmall};
    padding-bottom: ${({ theme }) => theme.sizeSmall};
    color: ${({ theme }) => theme.color};
  }
`

export const DateContainer = styled.div`
  display: flex;
  padding-right: ${({ theme }) => theme.sizeCuadruple};
`

export const MonthYearContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.size};
  padding-top: ${({ theme }) => theme.sizeSmall};
  padding-bottom: ${({ theme }) => theme.sizeSmall};
`

export const RowStyled = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.sizeDouble};
  line-height: 1.25;
  padding-left: ${({ theme }) => theme.sizeTriple};
  background-color: ${({ index }) => (index % 2 ? 'white' : 'unset')};
`

export const MonthContainer = styled.div`
  & > span {
    line-height: 1.25;
    padding-bottom: ${({ theme }) => theme.sizeSmall};
    color: ${({ theme }) => theme.colorGrayLight};
    font-size: 11px;
  }
`

export const YearContainer = styled.div`
  & > span {
    color: ${({ theme }) => theme.colorGrayLight};
    font-size: 17px;
  }
`
