import React, { Component } from 'react'
import { t } from '@app/common/translationsService'
import { Block } from '@new-lucentum'
import { FixedSizeListStyled as List } from './styles'

const height = 40

export default class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props
    const [value] = getValue()
    const initialOffset = options.indexOf(value) * height
    const totalHeight = height * (options.length || 1)
    const preparedHeight = Math.min(maxHeight, totalHeight)
    const hasOptions = Array.isArray(options) && !!options.length
    return hasOptions ? (
      <List
        height={preparedHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children && children[index]}</div>}
      </List>
    ) : (
      <Block alignContents="center" padding={2}>
        {t('detail_tm_no_information')}
      </Block>
    )
  }
}
