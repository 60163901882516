import React from 'react'
import { Field } from 'formik'
import FileInputMobile from '@app/components/FileInput/FileInputMobile'
import { t } from '@app/common/translationsService'

export default props => <Field {...props} component={FileWithIconMobile} />

const FileWithIconMobile = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div>
    <FileInputMobile
      {...field}
      {...props}
      error={errors[field.name]}
      touched={touched[field.name]}
      placeholder={t('file_input_not_choosen')}
      blockTitle={t('image.drop.file.here')}
      onChange={event => {
        setFieldValue(field.name, event.currentTarget.files[0])
        setFieldTouched(field.name, true)
      }}
      showError={errors[field.name] && touched[field.name]}
      errorMessage={errors[field.name]}
    />
  </div>
)
