import {
  generalDataManager,
  searchDataManager,
  userprefsDataManager,
} from '@app/common/dataManagers'
import { adaptBasicSearchToLucentumData } from '@app/common/searchUtilities'
import TMDSContextService from '@app/common/tmdsContextService'
import { getFavorites, setFavoritesCookie } from '@app/common/utilities'
import { FIELDS } from '@app/containers/TMSearch/constants'
import { LOCARNO_TYPES, SEARCH_TYPES } from '@app/redux-types'
import { TMSEARCH_CLEAR_FORM, TMSEARCH_SAVE_FORM } from '@app/redux-types/TMSearch'

const defaultFavoritesTmView = [FIELDS.TERRITORIES, FIELDS.GOODS_AND_SERVICES]
const defaultFavoritesDsView = [FIELDS.TERRITORIES]
const USPTO = 'uspto'

const getDefaultFavoritesByContext = () => {
  return TMDSContextService.isTmview() ? defaultFavoritesTmView : defaultFavoritesDsView
}

export const addFavorite = idSearchField => ({
  type: SEARCH_TYPES.ADD_FAVORITE_FIELD,
  payload: userprefsDataManager.toggleFavoriteField(idSearchField, true),
  meta: idSearchField,
})

export const removeFavorite = idSearchField => ({
  type: SEARCH_TYPES.REMOVE_FAVORITE_FIELD,
  payload: userprefsDataManager.toggleFavoriteField(idSearchField, false),
  meta: idSearchField,
})

export const setDefaultFavorites = () => async dispatch => {
  const defaultFavorites = getDefaultFavoritesByContext()
  setFavoritesCookie(defaultFavorites)
  dispatch({
    type: SEARCH_TYPES.SET_DEFAULT_FAVORITES,
    payload: { favorites: defaultFavorites },
  })
}

export const setFavoritesFromCookie = () => {
  const favorites = getFavorites()
  return {
    type: SEARCH_TYPES.SET_FAVORITES_FROM_COOKIE,
    payload: { favorites },
  }
}

export const setFavorites = favorites => {
  return {
    type: SEARCH_TYPES.SET_FAVORITES_FROM_COOKIE,
    payload: { favorites },
  }
}

export const toggleAdvancedSearch = () => ({
  type: 'TOGGLE_ADVANCED_SEARCH',
})

export const closeAdvancedSearch = () => ({
  type: 'CLOSE_ADVANCED_SEARCH',
})

export const openImageSearchMode = () => ({
  type: 'OPEN_IMAGE_SEARCH_MODE',
})

export const closeImageSearchMode = () => ({
  type: 'CLOSE_IMAGE_SEARCH_MODE',
})

export const toggleImageSearchMode = () => ({
  type: 'TOGGLE_IMAGE_SEARCH_MODE',
})

export const toggleMoreFieldsSearch = () => ({
  type: 'TOGGLE_MORE_FIELDS_SEARCH',
})

export const toggleOffices = () => ({
  type: 'TOGGLE_OFFICES',
})

export const fetchBasicSearchAutocomplete = (text, literals) => dispatch =>
  dispatch({
    type: 'FETCH_BASICSEARCH_AUTOCOMPLETE',
    payload: searchDataManager.getAutocompleteBasicSearch(text),
  }).then(response => adaptBasicSearchToLucentumData(response.value, literals))

export const fetchApplicationNumberAutocomplete = (text, literals, context) => dispatch => {
  return dispatch({
    type: 'FETCH_APPLICATION_NUMBER_AUTOCOMPLETE',
    payload: searchDataManager.getAutocompleteApplicationNumber(text),
  }).then(response => response.value)
}

export const fetchRegistrationNumberAutocomplete = (text, literals) => dispatch =>
  dispatch({
    type: 'FETCH_REGISTRATION_NUMBER_AUTOCOMPLETE',
    payload: searchDataManager.getAutocompleteRegistrationNumber(text),
  }).then(response => response.value)

export const fetchApplicantNameAutocomplete = text => dispatch =>
  dispatch({
    type: 'FETCH_APPLICANTNAME_AUTOCOMPLETE',
    payload: searchDataManager.getAutocompleteApplicantName(text),
  }).then(response => response.value)

export const initializeSearchFields = selects => ({
  type: 'INITIALIZE_SEARCH_FIELDS',
  payload: { selects },
})

export const validateEntireForm = () => ({
  type: 'VALIDATE_ENTIRE_FORM_SEARCH',
})

export const closeSearchNotification = () => ({
  type: 'CLOSE_SEARCH_NOTIFICATION',
})

export const changeLocarnoVersion = value => ({
  type: 'CHANGE_LOCARNO_VERSION',
  payload: {
    value,
  },
})

export const changeSearchForm = (field, value, errors = []) => (dispatch, getState) =>
  new Promise(resolve => {
    const { territories, offices } = getState().globals.data.selects
    dispatch({
      type: 'CHANGE_SEARCH_FORM',
      payload: { field, value, errors, territories, offices },
    })

    resolve()
  })

export const changeSearchFields = payload => ({
  type: 'CHANGE_SEARCH_FIELDS',
  payload: payload,
})

export const clearSearchForm = () => ({
  type: 'CLEAR_SEARCH_FORM',
})

export const setSearchFormToSimilarImage = () => ({
  type: 'SET_SEARCH_FORM_TO_SIMILAR_IMAGE',
})

export const resetFormState = () => ({
  type: 'RESET_FORM_STATE',
})

export const changeSearchMode = mode => ({
  type: 'CHANGE_SEARCH_MODE',
  payload: { mode },
})

export const changeIsEnterSearch = isEnter => ({
  type: 'CHANGE_IS_ENTER_BASIC_SEARCH',
  payload: isEnter,
})

export const changeViennaCodeVersion = value => ({
  type: 'CHANGE_VIENNA_CODE_VERSION',
  payload: {
    value,
  },
})

export const saveTMSearchForm = payload => ({
  type: TMSEARCH_SAVE_FORM,
  payload: payload,
})

export const clearTMSearchForm = () => ({
  type: TMSEARCH_CLEAR_FORM,
})

const loadViennaChildrenSuccess = (optionId, options) => ({
  type: 'LOAD_VIENNA_CHILDREN_SUCCESS',
  optionId,
  options,
})

const loadInitialViennaSuccess = (options, parsedLang) => ({
  type: 'LOAD_INITIAL_VIENNA_SUCCESS',
  options,
  parsedLang,
})

const loadViennaSuccess = () => ({
  type: 'LOAD_VIENNA_SUCCESS',
})

const loadSearchViennaSuccess = options => ({
  type: 'LOAD_SEARCH_VIENNA_SUCCESS',
  options,
})

const loadSearchViennaError = () => ({
  type: 'LOAD_SEARCH_VIENNA_ERROR',
})

export const loadViennaChildren = (optionId, noSave) => (dispatch, getState) => {
  const state = getState()
  const lang = state.uiState.language.language
  const version = state.uiState.search.formState.fields.viennaCodeVersion
  const parsedLang = version === USPTO ? 'en_us' : lang
  return generalDataManager
    .getViennaCodeHierarchy(parsedLang, optionId)
    .then(
      options => (!noSave ? dispatch(loadViennaChildrenSuccess(optionId, options)) : options),
      () => (!noSave ? dispatch(loadSearchViennaError()) : [])
    )
}

export const loadVienna = () => (dispatch, getState) => {
  const state = getState()
  const existingInitialViennaCodes = state.appState.viennaCodes.initialViennaCodes
  const viennaLang = state.appState.viennaCodes.language
  const lang = state.uiState.language.language
  const version = state.uiState.search.formState.fields.viennaCodeVersion
  const parsedLang = version === USPTO ? 'en_us' : lang
  const reloadVienna = existingInitialViennaCodes && viennaLang === parsedLang

  return reloadVienna
    ? dispatch(loadViennaSuccess())
    : generalDataManager
        .getViennaCodeHierarchy(parsedLang)
        .then(
          options => dispatch(loadInitialViennaSuccess(options, parsedLang)),
          () => console.error
        )
}

export const searchVienna = (input, noSave) => (dispatch, getState) => {
  const state = getState()
  const lang = state.uiState.language.language
  const version = state.uiState.search.formState.fields.viennaCodeVersion
  const parsedLang = version === USPTO ? 'en_us' : lang

  return generalDataManager
    .getViennaCodeSearch(parsedLang, input)
    .then(
      options => (!noSave ? dispatch(loadSearchViennaSuccess(options)) : options),
      () => (!noSave ? dispatch(loadSearchViennaError()) : [])
    )
}

export const searchViennaHierarchically = (input, noSave) => (dispatch, getState) => {
  const state = getState()
  const lang = state.uiState.language.language
  const version = state.uiState.search.formState.fields.viennaCodeVersion
  const parsedLang = version === USPTO ? 'en_us' : lang
  return generalDataManager
    .getViennaCodeHierarchy(parsedLang, input)
    .then(
      options => (!noSave ? dispatch(loadSearchViennaSuccess(options)) : options),
      () => (!noSave ? dispatch(loadSearchViennaError()) : [])
    )
}

export const toggleVienna = (optionId, chevron) => dispatch =>
  dispatch({
    type: 'TOOGLE_VIENNA_CHILDREN_SUCCESS',
    optionId,
    chevron,
  })

// DSView

export const fetchProductIndicationNameAutocomplete = (text, literals) => dispatch =>
  dispatch({
    type: SEARCH_TYPES.FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE,
    payload: searchDataManager.getAutocompleteProductIndicationName(text),
  }).then(response => adaptBasicSearchToLucentumData(response.value, literals))

export const fetchDesignNumberAutocomplete = text => dispatch => {
  return dispatch({
    type: SEARCH_TYPES.FETCH_DESIGN_NUMBER_AUTOCOMPLETE,
    payload: searchDataManager.getAutocompleteDesignNumber(text),
  }).then(response => response.value)
}

export const fetchDesignerNameAutocomplete = text => dispatch => {
  return dispatch({
    type: SEARCH_TYPES.FETCH_DESIGNER_NAME_AUTOCOMPLETE,
    payload: searchDataManager.getAutocompleteDesignerName(text),
  }).then(response => response.value)
}

export const fetchRepresentativeNameAutocomplete = text => dispatch => {
  return dispatch({
    type: SEARCH_TYPES.FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE,
    payload: searchDataManager.getAutocompleteRepresentativeName(text),
  }).then(response => response.value)
}

export const fetchOwnerNameAutocomplete = text => dispatch => {
  return dispatch({
    type: SEARCH_TYPES.FETCH_OWNER_NAME_AUTOCOMPLETE,
    payload: searchDataManager.getAutocompleteOwnerName(text),
  }).then(response => response.value)
}

export const fetchPreviousOwnerNameAutocomplete = text => dispatch => {
  return dispatch({
    type: SEARCH_TYPES.FETCH_OWNER_NAME_AUTOCOMPLETE,
    payload: searchDataManager.getAutocompletePreviousOwnerName(text),
  }).then(response => response.value)
}

export const loadLocarno = () => (dispatch, getState) => {
  const state = getState()
  const locarnoVersion = state.uiState.search.formState.fields.locarnoVersion
  const locarnoLanguage = state.appState.locarnoCodes.language
  const lang = state.uiState.language.language
  const parsedLang = lang || 'en_us'
  const hasInitialLocarnoCodes = !!state.appState.locarnoCodes.initialLocarnoCodes[locarnoVersion]
    .length
  const action =
    hasInitialLocarnoCodes && locarnoLanguage === parsedLang
      ? {
          type: LOCARNO_TYPES.RESTORE_INITIAL_LOCARNO,
          payload: locarnoVersion,
        }
      : {
          type: LOCARNO_TYPES.LOAD_LOCARNO,
          payload: generalDataManager.getLocarnoHierarchy(parsedLang, locarnoVersion),
          meta: { locarnoVersion, language: parsedLang },
        }
  return dispatch(action)
}

export const loadLocarnoChildren = (optionId, noSave) => (dispatch, getState) => {
  const state = getState()
  const locarnoVersion = state.uiState.search.formState.fields.locarnoVersion
  const lang = state.uiState.language.language
  const parsedLang = lang || 'en_us'
  const action = {
    type: LOCARNO_TYPES.LOAD_LOCARNO_CHILDREN,
    payload: generalDataManager.getLocarnoHierarchy(parsedLang, locarnoVersion, optionId),
    meta: {
      locarnoParent: optionId,
      locarnoVersion,
      noSave,
    },
  }

  return dispatch(action)
}

export const toggleLocarno = (optionId, chevron) => dispatch => {
  return dispatch({
    type: LOCARNO_TYPES.TOGGLE_LOCARNO,
    meta: { optionId, chevron },
  })
}

export const searchLocarno = (input, noSave, locarnoVersionParameter) => (dispatch, getState) => {
  const state = getState()
  const locarnoVersion =
    locarnoVersionParameter || state.uiState.search.formState.fields.locarnoVersion
  const lang = state.uiState.language.language
  const parsedLang = lang || 'en_us'
  return generalDataManager
    .getLocarnoAutocomplete(parsedLang, locarnoVersion, input)
    .then(
      options => (!noSave ? dispatch(loadSearchLocarnoSuccess(locarnoVersion, options)) : options),
      () => (!noSave ? dispatch(loadSearchLocarnoError()) : [])
    )
}

const loadSearchLocarnoSuccess = (locarnoVersion, options) => ({
  type: LOCARNO_TYPES.SEARCH_LOCARNO_SUCCESS,
  payload: { locarnoVersion, options },
})

const loadSearchLocarnoError = () => ({
  type: LOCARNO_TYPES.SEARCH_LOCARNO_ERROR,
})

export const loadExpandedLocarnos = () => (dispatch, getState) => {
  const state = getState()
  const currentLocarnos = state.appState.locarnoCodes.expandedLocarnos
  if (currentLocarnos.length >= 0) {
    const lang = state.uiState.language.language
    const parsedLang = lang || 'en_us'
    const action = {
      type: LOCARNO_TYPES.LOAD_EXPANDED_LOCARNO,
      payload: generalDataManager.getLocarnoHierarchy(parsedLang, null, '?expanded=true'),
    }
    return dispatch(action)
  }
}

export const setSaveSearch = (
  searchName,
  searchQuery,
  searchValues,
  createdDate,
  searchId,
  searchImage
) => ({
  type: SEARCH_TYPES.SET_SAVE_SEARCH,
  payload: userprefsDataManager.setSaveSearch(
    searchName,
    searchQuery,
    searchValues,
    createdDate,
    searchId,
    searchImage
  ),
  meta: { errors: [422] },
})

export const onlyActiveStatus = active => ({
  type: 'ONLY_ACTIVE_STATUS',
  payload: active,
})
