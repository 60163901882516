export const alert = [
  { content: '"\\e900"', color: 'rgb(248, 175, 65)' },
  { content: '"\\e974"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e975"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e976"', color: 'rgb(45, 48, 54)' },
]
export const arrowDown = '"\\e901"'
export const arrowLeft = '"\\e902"'
export const arrowRight = '"\\e903"'
export const arrowUp = '"\\e904"'
export const audio = '"\\e906"'
export const audioFile = '"\\e905"'
export const areaSelection = '"\\e969"'
export const backward = '"\\e907"'
export const bullet = '"\\e908"'
export const calendar = '"\\e909"'
export const camera = [
  { content: '"\\e90b"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e977"', color: 'rgb(28, 117, 187)' },
]
export const cameraOutline = '"\\e90a"'
export const cancelCheckoutFile = '"\\e90c"'
export const chart = '"\\e90d"'
export const check = '"\\e90f"'
export const checkCircle = '"\\e90e"'
export const checkinFile = '"\\e910"'
export const checkoutFile = '"\\e911"'
export const chevronDown = '"\\e913"'
export const chevronDownSmall = '"\\e912"'
export const chevronLeft = '"\\e914"'
export const chevronRight = '"\\e915"'
export const doubleChevronRight = '"\\00BB"'
export const chevronUp = '"\\e916"'
export const chevronUpSmall = '"\\e9a8"'
export const close = '"\\e917"'
export const code = '"\\e918"'
export const compare = [
  { content: '"\\e919"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e978"', color: 'rgb(255, 255, 255)' },
  { content: '"\\e979"', color: 'rgb(255, 255, 255)' },
  { content: '"\\e97a"', color: 'rgb(126, 65, 145)' },
  { content: '"\\e97b"', color: 'rgb(126, 65, 145)' },
]
export const copy = '"\\e91a"'
export const csv = '"\\e91b"'
export const doc = '"\\e91e"'
export const docCode = '"\\e91d"'
export const download = '"\\e91f"'
export const drag = '"\\e964"'
export const dropArrow = '"\\e920"'
export const edit = [
  { content: '"\\e921"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e97c"', color: 'rgb(45, 48, 54)' },
]
export const expand = '"\\e965"'
export const exportFile = [
  { content: '"\\e922"', color: 'rgb(0, 148, 68)' },
  { content: '"\\e97d"', color: 'rgb(45, 48, 54)' },
]
export const favorite = '"\\e924"'
export const favoriteOutline = '"\\e923"'
export const fb = '"\\e925"'
export const file = '"\\e926"'
export const filters = '"\\e927"'
export const firstpage = '"\\e928"'
export const folder = '"\\e92a"'
export const folderFiles = '"\\e929"'
export const fontResize = '"\\e92b"'
export const forward = '"\\e92c"'
export const freeSelection = '"\\e96a"'
export const frontpageArrows = '"\\e92d"'
export const gg = '"\\e92e"'
export const gridLarge = '"\\e92f"'
export const gridSmall = [
  { content: '"\\e930"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e97e"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e97f"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e980"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e981"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e982"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e983"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e984"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e985"', color: 'rgb(45, 48, 54)' },
]
export const help = '"\\e931"'
export const information = '"\\e933"'
export const inWord = '"\\e932"'
export const ipoFfices = '"\\e934"'
export const lastpage = '"\\e935"'
export const link = '"\\e936"'
export const list = [
  { content: '"\\e937"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e986"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e987"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e988"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e989"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e98a"', color: 'rgb(45, 48, 54)' },
]
export const listThin = [
  { content: '"\\e938"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e98b"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e98c"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e98d"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e98e"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e98f"', color: 'rgb(28, 117, 188)' },
]
export const logout = '"\\e939"'
export const mail = '"\\e93a"'
export const menu = '"\\e93b"'
export const minus = '"\\e93d"'
export const minusCircle = '"\\e93c"'
export const more = '"\\e93e"'
export const moveFile = '"\\e93f"'
export const multiply = '"\\e940"'
export const news = [
  { content: '"\\e941"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e96c"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e96d"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e96e"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e96f"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e970"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e971"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e972"', color: 'rgb(28, 117, 187)' },
]
export const next = '"\\e942"'
export const notification = '"\\e943"'
export const notificationOff = '"\\e968"'
export const pause = '"\\e944"'
export const pdf = '"\\e945"'
export const phone = [
  { content: '"\\e946"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e990"', color: 'rgb(253, 254, 255)' },
  { content: '"\\e991"', color: 'rgb(253, 254, 255)' },
]
export const picture = '"\\e947"'
export const pin = '"\\e949"'
export const pinOutline = '"\\e948"'
export const play = '"\\e94a"'
export const plus = '"\\e94c"'
export const plusCircle = '"\\e94b"'
export const ppt = '"\\e94d"'
export const previous = '"\\e94e"'
export const print = '"\\e94f"'
export const remove = '"\\e91c"'
export const reset = '"\\e9a7"'
export const save = '"\\e950"'
export const search = '"\\e954"'
export const searchHistory = [
  { content: '"\\e951"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e973"', color: 'rgb(45, 48, 54)' },
]
export const searchMinus = '"\\e952"'
export const searchPlus = '"\\e953"'
export const searchWipo = '"\\e967"'
export const send = '"\\e955"'
export const setting = [
  { content: '"\\e956"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e992"', color: 'rgb(0, 158, 226)' },
  { content: '"\\e993"', color: 'rgb(45, 48, 54)' },
]
export const share = '"\\e958"'
export const shareEmail = [
  { content: '"\\e957"', color: 'rgb(0, 148, 68)' },
  { content: '"\\e994"', color: 'rgb(45, 48, 54)' },
]
export const similarSearch = [
  { content: '"\\e959"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e9a1"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e9a2"', color: 'rgb(28, 117, 187)' },
]
export const stats = [
  { content: '"\\e95a"', color: 'rgb(28, 117, 188)' },
  { content: '"\\e995"', color: 'rgb(45, 48, 54)' },
]
export const stop = '"\\e95b"'
export const tag = [
  { content: '"\\e95c"', color: 'rgb(248, 129, 37)' },
  { content: '"\\e996"', color: 'rgb(45, 48, 54)' },
]
export const tw = '"\\e95d"'
export const upload = '"\\e95e"'
export const user = '"\\e95f"'
export const video = '"\\e961"'
export const videoFile = '"\\e960"'
export const warning = '"\\e962"'
export const walkthrough = '"\\e96b"'
export const xls = '"\\e963"'
export const zip = '"\\e966"'
export const addCriteria = [
  { content: '"\\e997"', color: 'rgb(45, 48, 54)' },
  { content: '"\\e998"', color: 'rgb(28, 117, 187)' },
  { content: '"\\e999"', color: 'rgb(28, 117, 187)' },
]
export const externalLink = [
  { content: '"\\e99f"', color: 'rgb(0, 148, 68)' },
  { content: '"\\e9a0"', color: 'rgb(45, 48, 54)' },
]
export const iconExpand = '"\\e99a"'
export const iconCompress = '"\\e99b"'
export const iconDrawerCompress = '"\\e99c"'
export const iconDrawerExpand = '"\\e99d"'
export const iconEnlarge = '"\\e99e"'
export const iconSuccess = '"\\e9a3"'
export const iconError = '"\\e9a4"'
export const sortingDescending = '"\\e9a5"'
export const sortingAscending = '"\\e9a6"'
