import styled, { keyframes, css } from 'styled-components'
import Block from '../Block'

const spinnerTransform = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`

const chooseDisplay = css`
  ${({ block }) => (block ? 'absolute' : 'fixed')};
`

export const BlockSpinner = styled(Block)`
  position: relative;
`

export const BackgroundSpinner = styled.div`
  ${props => !props.block && !props.row && 'height: 100vh; width: 100vw;'};
  align-items: center;
  background-color: ${({ row, theme }) => (row ? theme.colorTransparent : 'rgba(0, 0, 0, 0.6)')};
  color: white;
  flex-direction: column;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  position: ${({ row }) => (row ? 'relative' : chooseDisplay)};
  top: 0;
  display: ${({ show, row }) => (show || row ? 'inline-flex' : 'none')};
  z-index: 9999;

  & > * {
    margin: ${({ theme }) => theme.size} 0;
  }

  button {
    background-color: ${({ theme }) => theme.colorTransparent};
    border-color: ${({ theme }) => theme.colorWhite};
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const Loader = styled.div`
  animation: ${spinnerTransform} 1.1s infinite linear;
  ${({ theme, row, size }) => {
    if (row)
      return `
            border-bottom: ${size}px ${theme.colorGrayLight} solid;
            border-left: ${size}px  ${theme.colorGray} solid;
            border-right: ${size}px ${theme.colorGrayLight} solid;
            border-top: ${size}px ${theme.colorGrayLight} solid;`
    else
      return `
            border-bottom: ${theme.size} rgba(255, 255, 255, 0.2) solid;
            border-left: ${theme.size} #ffffff solid;
            border-right: ${theme.size} rgba(255, 255, 255, 0.2) solid;
            border-top: ${theme.size} rgba(255, 255, 255, 0.2) solid;`
  }};
  font-size: ${({ theme }) => theme.fontsizeSmall};
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);

  &,
  &:after {
    border-radius: 50%;
    height: ${({ theme, row, size }) => (row ? size * 5 + 'px' : `calc( ${theme.size} * 10)`)};
    width: ${({ theme, row, size }) => (row ? size * 5 + 'px' : `calc( ${theme.size} * 10)`)};
  }
`
