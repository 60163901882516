import React from 'react'
import { Column, Link, Pagination, Flex } from '@new-lucentum'
import { formatIsoStringDate } from '@app/common/utilities'
import Box from './Box'
import { Table } from './styles'
import { totalBoxEntries } from './utils'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'
import { useSelector } from 'react-redux'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { ROUTES } from '@app/containers/App/routes'
import { t } from '@app/common/translationsService'

const dateProps = {
  customRender: tableObj => formatIsoStringDate(tableObj.value),
  width: 90,
}

const getFormatedData = literals => [
  { label: literals['country.key'], labelKey: 'countryCode' },
  { label: literals['applicationdate.key'], labelKey: 'applicationDate', ...dateProps },
  {
    label: literals['tm.details.label.seniorityApplicationNumber.key'],
    labelKey: 'applicationNumber',
  },
  {
    label: literals['tm.details.label.seniorityFilingDate.key'],
    labelKey: 'filingDate',
    ...dateProps,
  },
  { label: literals['registrationnumber.key'], labelKey: 'registrationNumber' },
  {
    label: literals['tm.details.label.priorityDate.key'],
    labelKey: 'priorityDate',
    ...dateProps,
  },
  {
    label: literals['tm.details.label.internationalTradeMarkCode.key'],
    labelKey: 'internationalTradeMarkCode',
  },
  {
    label: literals['tm_details_label_PartialSeniority_key'],
    labelKey: 'partialIndicator',
    width: 85,
  },
  {
    label: literals['status.key'],
    labelKey: 'statusCode',
    labelKeyTranslated: 'statusCodeTranslated',
  },
]

const BoxSeniorityComponent = ({ inidCodes, detail = {}, officeExceptionMessage, ...props }) => {
  const [formatedData, setFormatedData] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1)
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)
  const changePage = (e, link, currentPage) => {
    e.preventDefault()
    const newPage = link !== 'first' ? (link === 'right' ? currentPage + 1 : currentPage - 1) : 1
    setCurrentPage(newPage)
  }

  React.useEffect(
    () => {
      const newFormartedData = getFormatedData(props.literals)
      const hasItems =
        Array.isArray(detail) &&
        detail.some(record => newFormartedData.some(column => record[column.labelKey]))
      hasItems !== props.hasItemsToShow && props.setHasItemsToShow('boxSeniority', hasItems)
      hasItems && setFormatedData(newFormartedData)
    },
    [detail, showOriginalLanguageAttributes]
  )
  return (
    <Box
      boxId="boxSeniority"
      title={`${t('tm.details.left.menu.seniority.key')} ${totalBoxEntries(detail)}`}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-seniority-inner"
      officeExceptionMessage={officeExceptionMessage}
    >
      {props.hasItemsToShow && formatedData.length && (
        <Flex column>
          <Table
            className="DetailTable"
            src={detail}
            outsidePagination
            hideCheckboxes
            page={currentPage - 1}
          >
            {formatedData.map(({ label, labelKey, labelKeyTranslated, ...props }) => (
              <Column
                key={labelKey}
                sortable={false}
                resizable={false}
                labelKey={
                  !showOriginalLanguageAttributes && labelKeyTranslated
                    ? labelKeyTranslated
                    : labelKey
                }
                label={label}
                customRender={row => {
                  let url = ''
                  const { st13 } = row.original
                  if (labelKey === 'registrationNumber' && row.value) {
                    url = st13
                  }
                  return !!url ? (
                    <Link
                      onClick={() => {
                        TMDSRouterService.navigate(
                          {
                            pathname: ROUTES.DETAILS,
                            state: { fromSeniority: true },
                          },
                          { id: st13 }
                        )
                      }}
                    >
                      {row.value}
                    </Link>
                  ) : (
                    row.value
                  )
                }}
                {...props}
              />
            ))}
          </Table>
          <Flex justify="flex-end">
            <Pagination
              totalPages={Math.ceil(detail.length / 20)}
              currentPage={currentPage}
              totalResults={detail.length}
              pageSize={20}
              separator={' '}
              onPageChange={(e, link) => changePage(e, link, currentPage)}
              data-test-id="detail-seniority-pagination"
            />
          </Flex>
        </Flex>
      )}
    </Box>
  )
}

export const BoxSeniority = BoxSeniorityComponent
