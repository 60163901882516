import { colours } from './theme.colors'
import { spacing } from './theme.spacing'
import { typography } from './theme.typography'

// lines
export const line = '1px'
export const lineThick = '2px'
// radius
export const radius = '2px'
// sizes
export const sizeBase = 10
export const sizeSmall = `${sizeBase * 0.5}px`
export const sizeSmaller = `${sizeBase * 0.85}px`
export const size = `${sizeBase}px`
export const sizeMidLarge = `${sizeBase * 1.2}px`
export const sizeLarge = `${sizeBase * 1.5}px`
export const sizeDouble = `${sizeBase * 2}px`
export const sizeTriple = `${sizeBase * 3}px`
export const sizeCuadruple = `${sizeBase * 4}px`
// shadow
export const dropdownShadow = `0 0 10px ${colours.colorGrayLighter}`
export const focusShadow = `0 0 10px ${colours.colorGray}`
export const focusShadowError = `inset 0 0 0 ${lineThick} ${colours.colorErrorAccent}`
// typography size
export const fontSizeSmaller = '0.85rem'
export const fontSize = '1rem'
export const fontSizeMedium = '1.15rem'
export const fontSizeLarge = '1.5rem'
export const fontSizeDouble = '2rem'
export const fontSizeBig = '2.3rem'
// typography style
export const fontFamily = 'Open Sans, sans-serif'
export const fontWeight = 'normal'
export const fontWeightSemiBold = '600'
export const fontColor = colours.colorGray
// layout
export const maxWidth = '960px'
// icons
export const iconFont = 'Lucentum Icons'
// breakpoints
export const breakpointXS = 'min-width: 400px'
export const breakpointSM = 'min-width: 550px'
export const breakpointMD = 'min-width: 750px'
export const breakpointLG = 'min-width: 1000px'
export const breakpointXL = 'min-width: 1200px'
// modal
export const smallModal = '288px'
export const mediumModal = '576px'
export const largeModal = '864px'
export const xLargeModal = '1152px'
export const modalHeaderTextColor = colours.colorGray
export const modalHeaderIconColor = colours.colorGrayLight
export const modalHeaderBorder = `${line} solid ${colours.colorGrayLight}`
export const modalTextColor = colours.colorGray
export const modalFooterBackground = colours.colorGrayAccent
export const modalOverlayBackground = 'rgba(0,0,0,0.60)'

export const theme = {
  ...colours,
  ...typography,
  spacingUnits: spacing,
  line,
  lineThick,
  radius,
  sizeBase,
  sizeSmall,
  sizeSmaller,
  size,
  sizeMidLarge,
  sizeLarge,
  sizeDouble,
  sizeTriple,
  sizeCuadruple,
  dropdownShadow,
  focusShadow,
  focusShadowError,
  fontSizeSmaller,
  fontSize,
  fontSizeMedium,
  fontSizeLarge,
  fontSizeDouble,
  fontSizeBig,
  fontFamily,
  fontWeight,
  fontWeightSemiBold,
  fontColor,
  maxWidth,
  iconFont,
  breakpointXS,
  breakpointSM,
  breakpointMD,
  breakpointLG,
  breakpointXL,
  smallModal,
  mediumModal,
  largeModal,
  xLargeModal,
  modalHeaderTextColor,
  modalHeaderIconColor,
  modalHeaderBorder,
  modalTextColor,
  modalFooterBackground,
  modalOverlayBackground,
}
