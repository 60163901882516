import { IMAGE_SEARCH_TYPES } from '@app/redux-types'

const defaultState = {
  data: {},
  fetching: false,
  fetched: false,
  error: null,
  localStoring: false,
  localStored: false,
  localStoreError: null,
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'LOCAL_STORE_IMAGE_STORING':
      return {
        ...state,
        localStoring: true,
        localStored: false,
        localStoreError: null,
      }
    case 'LOCAL_STORE_IMAGE_STORED':
      return {
        ...state,
        localStoring: false,
        localStored: true,
        localStoreError: null,
      }
    case 'LOCAL_STORE_IMAGE_ERROR':
      return {
        ...state,
        localStoring: false,
        localStored: false,
        localStoreError: payload,
      }
    case IMAGE_SEARCH_TYPES.UPLOAD_IMAGE_SEARCH_PENDING:
    case 'UPLOAD_IMAGE_AND_REDIRECT_PENDING':
      return {
        ...state,
        data: {},
        fetching: true,
        fetched: false,
        error: null,
      }
    case IMAGE_SEARCH_TYPES.UPLOAD_IMAGE_SEARCH_FULFILLED:
    case 'UPLOAD_IMAGE_AND_REDIRECT_FULFILLED':
    case IMAGE_SEARCH_TYPES.RESTORE_IMAGE_SEARCH:
      let data = {}
      if (payload) {
        const { imageData, ...rest } = payload
        data = rest
      }
      return {
        ...state,
        data,
        fetching: false,
        fetched: true,
        error: null,
      }
    case IMAGE_SEARCH_TYPES.UPLOAD_IMAGE_SEARCH_REJECTED:
    case 'UPLOAD_IMAGE_AND_REDIRECT_REJECTED':
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: payload.parsedBody,
      }
    case 'EMPTY_IMAGE_SEARCH_APP':
      return {
        ...state,
        data: {},
        fetching: false,
        fetched: false,
        error: null,
      }
    default:
      return state
  }
}
