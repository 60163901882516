import TMDSContextService from '@app/common/tmdsContextService'
import { t } from '@app/common/translationsService'
import { getFavorites } from '@app/common/utilities'
import { localStorageUtils } from '@app/common/utils'
import { shallowEqual } from 'recompose'

import {
  FIELDS,
  getFieldsetsLabelsByContext,
  INITIAL_VALUES_DSVIEW,
  INITIAL_VALUES_RESULT_TMVIEW,
  INITIAL_VALUES_TMVIEW,
} from './constants'
import { prepareQueryParamsFromValues } from './transformations/queryParamsFromValues'

export const prepareInitialValues = props => {
  const { storeFields } = props
  const initialValuesByContext = getInitialValuesByContext()
  const hasSavedForm = !shallowEqual(initialValuesByContext, storeFields)
  let preparedInitialValues = { ...initialValuesByContext }
  if (hasSavedForm) {
    preparedInitialValues = { ...preparedInitialValues, ...storeFields }
  }

  return preparedInitialValues
}

export const prepareSearchResultValues = props => {
  const { storeFields } = props
  const initialValuesByContext = INITIAL_VALUES_RESULT_TMVIEW
  const hasSavedForm = !shallowEqual(initialValuesByContext, storeFields)
  let preparedInitialValues = { ...initialValuesByContext }
  if (hasSavedForm) {
    preparedInitialValues = { ...preparedInitialValues, ...storeFields }
  }

  return preparedInitialValues
}

export const getInitialValuesByContext = () => {
  return TMDSContextService.isTmview() ? INITIAL_VALUES_TMVIEW : INITIAL_VALUES_DSVIEW
}

const prepareArrayToMultipleParams = (key, value) => {
  let arrayQueryString = ''
  for (const item of value) {
    arrayQueryString += `${key}=${item}&`
  }
  return arrayQueryString
}

const queryObjectToString = queryObject => {
  let queryString = '?'
  Object.entries(queryObject).forEach(([key, value]) => {
    queryString += Array.isArray(value)
      ? prepareArrayToMultipleParams(key, value)
      : `${key}=${value}&`
  })
  return queryString.slice(0, -1)
}

export const prepareSearchQuery = (values, imageSearchFields, pageSize) => {
  let params = {
    page: 1,
    pageSize: pageSize || 20,
    criteria: values.criteria && values.criteria.id,
    ...imageSearchFields,
  }

  const fieldsToAdd = prepareQueryParamsFromValues(values)

  params = { ...params, ...fieldsToAdd }

  const queryString = queryObjectToString(params)
  return queryString
}

export const prepareImageSearchFields = (withImageSearch, data) => {
  if (withImageSearch) {
    const commonImageFields = {
      imageId: data.imageId,
      imageName: data.imageName,
      segmentLeft: Math.round(data.segmentLeft),
      segmentRight: Math.round(data.segmentRight),
      segmentTop: Math.round(data.segmentTop),
      segmentBottom: Math.round(data.segmentBottom),
      colour: data.colour,
      ocr: data.ocr,
    }

    const selectionModeFields =
      data.selectionMode === 'FREE' && data.freeSelectionPoints
        ? {
            freeSelectionPoints: JSON.stringify(data.freeSelectionPoints),
          }
        : {}
    return { ...commonImageFields, ...selectionModeFields }
  } else {
    return {}
  }
}

export const TMSearchRenderConditions = (props, nextProps) => {
  const hasChangedStoreFields = !shallowEqual(props.storeFields, nextProps.storeFields)
  const hasChangedErrors = !shallowEqual(props.errors, nextProps.errors)
  const hasChangedImageSearchExpanded = !shallowEqual(
    props.imageSearchExpanded,
    nextProps.imageSearchExpanded
  )
  const hasChangedAdvancedSearchExpanded = !shallowEqual(
    props.advancedSearchExpanded,
    nextProps.advancedSearchExpanded
  )
  const hasChangedFavoriteFields = !shallowEqual(props.favoriteFields, nextProps.favoriteFields)
  const hasChangedSearchCriteria = !shallowEqual(
    props.values[FIELDS.SEARCH_CRITERIA],
    nextProps.values[FIELDS.SEARCH_CRITERIA]
  )

  const hasChangedEnabledFields = !shallowEqual(props.enabledFields, nextProps.enabledFields)
  const hasChangedImageSearch =
    props.values[FIELDS.IMAGE_SEARCH] !== nextProps.values[FIELDS.IMAGE_SEARCH]

  const hasChangedFormStatus = !shallowEqual(props.status, nextProps.status)
  const hasChangedFieldsErrors = !shallowEqual(
    props.values[FIELDS.FIELDS_ERRORS],
    nextProps.values[FIELDS.FIELDS_ERRORS]
  )

  const hasToUpdate =
    hasChangedStoreFields ||
    hasChangedErrors ||
    hasChangedImageSearchExpanded ||
    hasChangedAdvancedSearchExpanded ||
    hasChangedFavoriteFields ||
    hasChangedSearchCriteria ||
    hasChangedEnabledFields ||
    hasChangedImageSearch ||
    hasChangedFormStatus ||
    hasChangedFieldsErrors
  return hasToUpdate
}

export const TMSearchFieldsetRenderConditions = (props, nextProps) => {
  return (
    !shallowEqual(
      props.formCommons.errors[props.field],
      nextProps.formCommons.errors[nextProps.field]
    ) ||
    !shallowEqual(
      props.formCommons.values[FIELDS.FIELDS_ERRORS][props.field],
      nextProps.formCommons.values[FIELDS.FIELDS_ERRORS][nextProps.field]
    ) ||
    !shallowEqual(
      props.formCommons.advancedSearchExpanded,
      nextProps.formCommons.advancedSearchExpanded
    ) ||
    !shallowEqual(props.formCommons.status, nextProps.formCommons.status)
  )
}

export const ResultSearchFieldsetRenderConditions = (props, nextProps) => {
  const hasChangedStoreFields = !shallowEqual(props.storeFields, nextProps.storeFields)
  const hasChangedErrors = !shallowEqual(props.errors, nextProps.errors)

  const hasChangedSearchCriteria = !shallowEqual(
    props.values[FIELDS.SEARCH_CRITERIA],
    nextProps.values[FIELDS.SEARCH_CRITERIA]
  )

  const hasChangedFormStatus = !shallowEqual(props.status, nextProps.status)
  const hasChangedFieldsErrors = !shallowEqual(
    props.values[FIELDS.FIELDS_ERRORS],
    nextProps.values[FIELDS.FIELDS_ERRORS]
  )

  const hasToUpdate =
    hasChangedStoreFields ||
    hasChangedErrors ||
    hasChangedSearchCriteria ||
    hasChangedFormStatus ||
    hasChangedFieldsErrors
  return hasToUpdate
}

export const prepareCriteriaLiteral = criteria => {
  return t(criteria['text'])
}

const getOrderedFavoritesKey = () => `ordered_favorites_${TMDSContextService.get()}`
const getInitialOrderedFields = enabledFields => {
  const storedFavorites = localStorageUtils.get(getOrderedFavoritesKey())
  let initialOrderedFields = enabledFields
  if (storedFavorites) {
    const newFields = enabledFields.filter(field => !storedFavorites.includes(field))
    initialOrderedFields = [...storedFavorites, ...newFields]
  }
  return initialOrderedFields
}

const getMobileFavorites = () => {
  return TMDSContextService.isTmview()
    ? [FIELDS.TERRITORIES, FIELDS.GOODS_AND_SERVICES]
    : [FIELDS.TERRITORIES, FIELDS.LOCARNO]
}
export const prepareInitialSortableFields = (
  enabledFields = [],
  fieldsetsComponents,
  territoriesHidden,
  isMobile
) => {
  const initialOrderedFields = getInitialOrderedFields(enabledFields)
  const initialFavoritedFields = isMobile ? getMobileFavorites() : getFavorites()
  const FIELDSETS_LABELS = getFieldsetsLabelsByContext()
  const preparedFields = initialOrderedFields
    .map(field => {
      const label =
        field === FIELDS.TERRITORIES
          ? territoriesHidden
            ? t('offices.key')
            : `${t('advanced_search_territories_key')} / ${t('offices.key')}`
          : t(FIELDSETS_LABELS[field])
      return {
        label,
        value: field,
        shown: initialFavoritedFields.includes(field),
        component: fieldsetsComponents[field],
      }
    })
    .filter(item => enabledFields.includes(item.value) && item.component)
  return preparedFields
}

export const saveFavoriteFieldsToLocalStorage = fields => {
  const orderedFields = fields.map(field => field.value)
  localStorageUtils.set(getOrderedFavoritesKey(), orderedFields)
}
