const appendToHead = element => document.getElementsByTagName('head')[0].appendChild(element)

const createMeta = (name, content) => {
  let meta = document.createElement('meta')
  meta.setAttribute('name', name)
  return appendToHead(meta)
}

const prepareMap = {
  title: value => {
    if (document.title !== value) {
      document.title = value
    }
  },
  meta: metaTags => {
    Object.entries(metaTags).forEach(([key, value]) => {
      const metaElement = document.querySelector(`meta[name="${key}"]`) || createMeta(key)
      if (metaElement && metaElement.getAttribute(key) !== value) {
        metaElement.setAttribute('content', value)
      }
    })
  },
}

const set = seoParams => {
  Object.entries(seoParams).forEach(([key, value]) => {
    const prepare = prepareMap[key]
    !!prepare && prepare(value)
  })
}

export const TMDSSeoService = {
  set,
}
