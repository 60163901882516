import React from 'react'
import cn from 'classnames'

/**
 * Higher-Order Component to generate custom option templates
 */
const CustomOption = ({
  component,
  handleMouseOut,
  handleMouseOver,
  hiddenColumns,
  infoKey,
  nameLink,
  parentClassName,
  self,
  src,
  state,
  textClass,
  textLink,
  textSearchFor,
  textSelectAll,
  title = 'text',
  toLink,
  valueKey,
}) => ({ option, children, className, onFocus, onSelect, isFocused }) => {
  const prevent = e => e.preventDefault() && e.stopPropagation()
  const isParent = option.children

  // Select Vienna Dropdown
  const changeStateExpanded = includeOption =>
    self.setState(({ optionExpanded }) => ({
      optionExpanded: includeOption
        ? optionExpanded.filter(
            e =>
              e !== option.id &&
              !state.options.some(
                x => x.parent && x.children && x.parent.includes(option.id) && e === x.id
              )
          )
        : [...optionExpanded, option.id],
      options: includeOption
        ? [
            ...state.options.filter(
              optState => !(optState.parent && optState.parent.includes(option.id))
            ),
          ]
        : [
            ...state.options.slice(0, state.options.indexOf(option) + 1),
            ...src.filter(
              optSRC =>
                optSRC.parent &&
                optSRC.level === option.level + 1 &&
                optSRC.parent.includes(option.id)
            ),
            ...state.options.slice(state.options.indexOf(option) + 1),
          ],
    }))

  const handleMouseDropdown = e => {
    prevent(e)
    title === 'text'
      ? onSelect(option, e)
      : changeStateExpanded(state.optionExpanded.includes(option.id))
  }

  // Select Nice. Chose a class number
  const handleMouseNumber = (e, option) => {
    prevent(e)
    state.value && state.value.length
      ? !state.value.some(({ n }) => n === option[valueKey]) &&
        onSelect({ [valueKey]: option[valueKey], [infoKey]: option[infoKey] })
      : onSelect({ [valueKey]: option[valueKey], [infoKey]: option[infoKey] })
  }

  // Check if any option exist in state and remove
  const checkDuplication = checked =>
    state.value && state.value.length
      ? checked.filter(i => !state.value.some(e => e[valueKey] === i[valueKey]))
      : checked

  // Select Nice. Chose all class numbers
  const handleMouseSelectAll = e => {
    prevent(e)
    const numbers = option.numbers.map(obj => ({
      [valueKey]: obj[valueKey],
      [infoKey]: obj[infoKey],
    }))
    onSelect(checkDuplication(numbers))
  }

  // Include children of option father selected.
  const filterChildren = () => {
    const selectChildren = src.filter(
      ({ id, parent }) => id === option.id || (parent && parent.includes(option.id))
    )
    onSelect(checkDuplication(selectChildren))
  }

  const handleMouseDown = e => {
    prevent(e)
    isParent ? filterChildren() : onSelect(option)
  }

  const handleMouseEnter = e => onFocus(option, e)

  const handleMouseMove = e => !isFocused && onFocus(option, e)

  const prepareOptionComponent = () => {
    return component({
      children,
      className,
      handleMouseDown,
      handleMouseDropdown,
      handleMouseEnter,
      handleMouseMove,
      handleMouseNumber,
      handleMouseOut,
      handleMouseOver,
      handleMouseSelectAll,
      hiddenColumns,
      nameLink,
      option,
      state,
      textClass,
      textLink,
      textSearchFor,
      textSelectAll,
      title,
      toLink,
      valueKey,
    })
  }

  const shouldRenderOption = () => {
    let shouldBeRendered = true
    if (isParent) {
      const children = src.filter(
        srcOption => srcOption.parent && srcOption.parent.includes(option.id)
      )
      const someChildrenNotSelected = children.some(
        child => !state.value.some(selectedOption => selectedOption.id === child.id)
      )
      shouldBeRendered = someChildrenNotSelected
    }
    return shouldBeRendered
  }

  return title === 'text' ? (
    <div
      className={cn(className, parentClassName)}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={option[title]}
    >
      {component(children, option)}
    </div>
  ) : shouldRenderOption() ? (
    prepareOptionComponent()
  ) : null
}

export default CustomOption
