import { getCookie, getNavigatorLanguage } from '@app/common/utilities'
import { ENABLED_LANGUAGES } from '@app/common/constants'

export const getCurrentLanguage = () => {
  const languageFromCookies = getCookie('language')
  const currentLanguage = languageFromCookies || getNavigatorLanguage()
  const sanitizedLanguage = currentLanguage
  return sanitizedLanguage
}

const defaultState = {
  language: getCurrentLanguage(),
}

export default (state = defaultState, { type, meta: language }) => {
  switch (type) {
    case 'CHANGE_LANGUAGE_FULFILLED':
      /* Once put request to change language is completed, cookie which is named "language" is updated
       * using the new language. Because requested language and received language can be different,
       * we use received language after be stored in that cookie.
       */
      return { ...state, language: getCurrentLanguage() }
    default:
      return state
  }
}
