import React from 'react'
import { Group, Image, Layer, Line, Rect, Stage } from 'react-konva'

import { useSelectionFree } from '../hooks'
import { Rectangle } from './Rectangle'

const FreeSelectionComponent = ({ image, parsedInfo, onCloseFreeSelection, initialSelection }) => {
  const onFreeSelectionEnd = React.useCallback(
    selectionPoints => {
      const adj = parsedInfo.scale || 1
      const preparedPoints = selectionPoints.map(point => {
        const x = point.x / adj
        const y = point.y / adj
        return [x, y]
      })

      const points = selectionPoints.length > 0 ? preparedPoints : null
      onCloseFreeSelection(points)
    },
    [parsedInfo]
  )

  const getInitialPreparedPoints = React.useCallback(
    selectionPoints => {
      if (selectionPoints && selectionPoints.length) {
        const adj = parsedInfo.scale || 1
        const preparedPoints = selectionPoints.map(point => {
          const x = point[0] * adj
          const y = point[1] * adj
          return { x, y }
        })
        return preparedPoints
      } else {
        return []
      }
    },
    [parsedInfo]
  )

  const {
    handleMouseDown: handleMouseDownFree,
    handleMouseMove: handleMouseMoveFree,
    points,
    isClosed,
    flattenedPoints,
    areaRef,
  } = useSelectionFree(onFreeSelectionEnd, getInitialPreparedPoints(initialSelection))

  const stageProps = {
    onMouseDown: handleMouseDownFree,
    onMouseMove: handleMouseMoveFree,
    className: 'free-selection',
  }

  return (
    <div ref={areaRef}>
      {parsedInfo.width && (
        <Stage width={parsedInfo.width} height={parsedInfo.height} {...stageProps}>
          <Layer>
            <Image image={image} width={parsedInfo.width} height={parsedInfo.height} fill="white" />
          </Layer>

          {/* Free selection */}
          <Layer>
            <Group width={parsedInfo.width} height={parsedInfo.height}>
              {isClosed && (
                <Rectangle
                  shapeProps={{
                    width: parsedInfo.width,
                    height: parsedInfo.height,
                    fill: 'rgba(0, 0, 0, 0.3)',
                    id: 'selection-backdrop',
                    draggable: false,
                  }}
                />
              )}
              <Line
                points={flattenedPoints}
                stroke="red"
                fill="rgba(255,255,255,1)"
                strokeWidth={2}
                closed={isClosed}
                {...isClosed && { globalCompositeOperation: 'destination-out' }}
              />
              {isClosed && (
                <Line points={flattenedPoints} stroke="red" strokeWidth={2} closed={isClosed} />
              )}
              {points.map((point, index) => {
                const width = 6
                const x = point.x - width / 2
                const y = point.y - width / 2
                const startPointAttr =
                  index === 0
                    ? {
                        hitStrokeWidth: 12,
                        /*  onMouseOver: handleMouseOverStartPoint,
                      onMouseOut: handleMouseOutStartPoint */
                      }
                    : null
                return (
                  <Rect
                    key={index}
                    x={x}
                    y={y}
                    width={width}
                    height={width}
                    fill="white"
                    stroke="red"
                    strokeWidth={1}
                    {...startPointAttr}
                  />
                )
              })}
            </Group>
          </Layer>
        </Stage>
      )}
    </div>
  )
}

export const FreeSelection = FreeSelectionComponent
