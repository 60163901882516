import styled, { css } from 'styled-components'
import { Button, Block } from '@new-lucentum'

export const IconButton = styled(Button)`
  ${({ noHover }) =>
    noHover &&
    `
    &:hover {
      color: unset;
      background-color: unset;
    }
  `}
`

export const IconDropdown = styled(Button)`
  & > div {
    box-shadow: 1px 1px 2px 0px ${({ theme }) => theme.colorGrayLight};
    border-radius: 7px;
    margin-top: 7px;
    border-color: ${({ theme }) => theme.colorGrayLight};
    & > button {
      padding: 8px 27px;
    }
  }
`

export const IconButtonsBlock = styled(Block)`
  display: flex;
  background-color: ${({ theme }) => theme.colorAccent};
`
