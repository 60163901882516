import React from 'react'

import { Block } from '@new-lucentum'
import { GoToDropdown } from '../'
import { BOXES_TMVIEW } from '@app/common/constants/details/boxes'
import { t } from '@app/common/translationsService'

export const GoToButton = props => {
  const sections = Object.values(BOXES_TMVIEW).map(BOX => ({
    id: BOX.ID,
    description: t(BOX.TITLE),
  }))
  return (
    <Block middle auto marginRight>
      <Block auto marginRight>
        {t('tm_details_section_go_to')}:
      </Block>
      <GoToDropdown sections={sections} {...props} />
    </Block>
  )
}

export default GoToButton
