import React from 'react'
import { Block } from '@new-lucentum'
import { ListTerms, TranslatedTerm } from './styles'
import { getTermsSeparator } from '@app/common/utilities'

export default ({ ST13, translatedTerms = [], textStyle }) => {
  const separator = getTermsSeparator()
  return (
    <Block style={textStyle}>
      <ListTerms data-test-id={`indication-of-product-${ST13}`}>
        {Array.isArray(translatedTerms) &&
          translatedTerms.map(({ translationType, translatedTerm }, i, { length }) => (
            <TranslatedTerm key={i} translationType={translationType || ''}>
              {i === length - 1 ? translatedTerm : `${translatedTerm}${separator} `}
            </TranslatedTerm>
          ))}
      </ListTerms>
    </Block>
  )
}
