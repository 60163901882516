import { CertificateDataManager } from '@app/common/dataManagers/certificateDataManager'
import { TMDSToastService, TMDSUserSettingsService } from '@app/common/services'
import { t } from '@app/common/translationsService'
import { CERTIFICATE_TYPES } from '@app/redux-types'

import { toggleSpinner } from './globalSpinner'

export const getCertificateTypes = st13 => {
  return async dispatch => {
    try {
      const response = await CertificateDataManager.getCertificateTypes(st13)

      dispatch({ type: CERTIFICATE_TYPES.GET_CERTIFICATE_TYPES, payload: response })

      return response
    } catch (error) {
      TMDSToastService.showNotificationError(t('certificate_service_error'))
    }
  }
}

export const getCertificateStatus = payload => {
  return async dispatch => {
    dispatch(toggleSpinner(true))
    try {
      const response = await CertificateDataManager.getCertificateStatus(payload)

      dispatch({ type: CERTIFICATE_TYPES.GET_CERTIFICATE_STATUS, payload: response })
      dispatch(toggleSpinner(false))
    } catch (error) {
      dispatch(toggleSpinner(false))
      TMDSToastService.showNotificationError(t('certificate_service_error'))
      dispatch(resetCertificateStatus())
    }
  }
}

export const resetCertificateStatus = () => ({
  type: CERTIFICATE_TYPES.RESET_CERTIFICATE_STATUS,
})

export const requestCertificate = payload => {
  return async dispatch => {
    dispatch(toggleSpinner(true))
    try {
      const response = await CertificateDataManager.requestCertificate(payload)

      dispatch({ type: CERTIFICATE_TYPES.REQUEST_CERTIFICATE })
      dispatch(toggleSpinner(false))

      return response
    } catch (error) {
      dispatch(toggleSpinner(false))
      TMDSToastService.showNotificationError(t('certificate_service_error'))
      dispatch({ type: CERTIFICATE_TYPES.RESET_PROCESS })
    }
  }
}

export const toggleRequestCertificateModal = () => ({
  type: CERTIFICATE_TYPES.TOGGLE_REQUEST_CERTIFICATE_MODAL,
})

export const userPreferencesSubmitted = userPreferencesSubmitted => ({
  type: CERTIFICATE_TYPES.USER_PREFERENCES_SUBMITTED,
  payload: userPreferencesSubmitted,
})

export const setUserPreference = userPref => {
  return async dispatch => {
    try {
      await TMDSUserSettingsService.editSettings(userPref)
    } catch (error) {
      TMDSToastService.showNotificationError(t('certificate_service_error'))
      dispatch({ type: CERTIFICATE_TYPES.RESET_PROCESS })
    }
  }
}

export const changeCertificateCaptcha = recaptchaResponseKey => ({
  type: CERTIFICATE_TYPES.CHANGE_CERTIFICATE_CAPTCHA,
  payload: recaptchaResponseKey,
})

export const resetProcess = () => ({
  type: CERTIFICATE_TYPES.RESET_PROCESS,
})

export const setCertificateType = type => ({
  type: CERTIFICATE_TYPES.SET_CERTIFICATE_TYPE,
  payload: type,
})

export const authenticateCertificate = (st13, pdf) => {
  return async dispatch => {
    dispatch(toggleSpinner(true))
    try {
      const response = await CertificateDataManager.authenticateCertificate(st13, pdf)

      if (response.authenticated) {
        TMDSToastService.showNotificationSuccess(t('authenticate.certificate.successful'))
      } else {
        TMDSToastService.showNotificationError(t('authenticate.certificate.unsuccessful'))
      }

      dispatch(toggleSpinner(false))
      dispatch({ type: CERTIFICATE_TYPES.RESET_PROCESS })
    } catch (error) {
      dispatch(toggleSpinner(false))
      dispatch({ type: CERTIFICATE_TYPES.RESET_PROCESS })
      TMDSToastService.showNotificationError(t('certificate_service_error'))
    }
  }
}

export const downloadCertificate = certificateId => {
  return async dispatch => {
    dispatch(toggleSpinner(true))
    try {
      const response = await CertificateDataManager.downloadCertificate(certificateId)

      if (response.status >= 200 && response.status < 300) {
        dispatch({ type: CERTIFICATE_TYPES.DOWNLOAD_CERTIFICATE, payload: 'Succes' })
      } else if (response.status === 404) {
        TMDSToastService.showNotificationError(t('certificate_download_link_not_active'))
        dispatch({ type: CERTIFICATE_TYPES.DOWNLOAD_CERTIFICATE, payload: 'Failed' })
      } else {
        TMDSToastService.showNotificationError(t('certificate_service_error'))
        dispatch({ type: CERTIFICATE_TYPES.DOWNLOAD_CERTIFICATE, payload: 'Failed' })
      }

      dispatch(toggleSpinner(false))
    } catch (error) {
      dispatch(toggleSpinner(false))
      TMDSToastService.showNotificationError(t('certificate_service_error'))
      dispatch({ type: CERTIFICATE_TYPES.DOWNLOAD_CERTIFICATE, payload: 'Failed' })
    }
  }
}

export const getContactDetails = () => {
  return async dispatch => {
    dispatch(toggleSpinner(true))
    try {
      const res = await CertificateDataManager.getContactDetails()

      dispatch({ type: CERTIFICATE_TYPES.GET_CONTACT_DETAILS, payload: res })
      dispatch(toggleSpinner(false))
    } catch (error) {
      dispatch(toggleSpinner(false))
      dispatch({ type: CERTIFICATE_TYPES.RESET_PROCESS })
      TMDSToastService.showNotificationError(t('certificate_service_error'))
    }
  }
}
