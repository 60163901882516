import styled from 'styled-components'

export default styled.div`
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 0px 0px 0px 30px;

  .back {
    margin-bottom: 2px;
  }

  a {
    margin: 0 ${({ theme }) => theme.sizeSmall};
    text-decoration: none;
    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:first-child {
      i:last-child {
        margin-left: calc(-${({ theme }) => theme.size} - 3px);
      }
    }
  }

  a .back {
    display: inline;
    font-size: calc(${({ theme }) => theme.size} + 4px);
    margin-top: -2px;
  }

  i {
    font-size: calc(${({ theme }) => theme.size} + 3px);
  }

  span {
    margin-top: -${({ theme }) => theme.lineThick};
  }
`
