import React from 'react'
import { Column } from '@new-lucentum'
import { formatIsoStringDate } from '@app/common/utilities'
import { Box } from './Box'
import { Table } from './styles'
import { totalBoxEntries } from './utils'
import { t } from '@app/common/translationsService'
import { showOriginalLanguageSelector } from '@app/common/selectors/details'
import { useSelector } from 'react-redux'
import { PUBLICATION_FIELDS } from '@app/common/dataManagers/types'
const dateProps = {
  customRender: tableObj => formatIsoStringDate(tableObj.value),
}
const getFormatedData = literals => [
  { label: literals['date.key'], labelKey: PUBLICATION_FIELDS.DATE, ...dateProps },
  { label: literals['publicationidentifier.key'], labelKey: PUBLICATION_FIELDS.IDENTIFIER },
  {
    label: literals['tm_details_label_PublicationSection_key'],
    labelKey: PUBLICATION_FIELDS.SECTION,
    labelKeyTranslated: PUBLICATION_FIELDS.SECTION_TRANSLATED,
  },
  { label: literals['page.key'], labelKey: PUBLICATION_FIELDS.PAGE },
]

const BoxPublicationComponent = ({ inidCodes, detail = [], officeExceptionMessage, ...props }) => {
  const [formatedData, setFormatedData] = React.useState([])
  const showOriginalLanguageAttributes = useSelector(showOriginalLanguageSelector)

  React.useEffect(
    () => {
      const newFormatedData = getFormatedData(props.literals)
      setFormatedData(newFormatedData)
      const hasItems =
        Array.isArray(detail) &&
        detail.some(record => newFormatedData.some(column => record[column.labelKey]))
      hasItems !== props.hasItemsToShow && props.setHasItemsToShow('boxPublication', hasItems)
    },
    [detail, showOriginalLanguageAttributes]
  )

  return (
    <Box
      boxId="boxPublication"
      title={`${t('tm.details.left.menu.publication.key')} ${totalBoxEntries(detail)}`}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-publication-inner"
      officeExceptionMessage={officeExceptionMessage}
    >
      {props.hasItemsToShow && formatedData.length && (
        <Table className="DetailTable" src={detail} hideCheckboxes>
          {formatedData.map(({ label, labelKey, labelKeyTranslated, ...props }) => (
            <Column
              key={labelKey}
              sortable={false}
              resizable={false}
              labelKey={
                !showOriginalLanguageAttributes && labelKeyTranslated
                  ? labelKeyTranslated
                  : labelKey
              }
              label={label}
              {...props}
            />
          ))}
        </Table>
      )}
    </Box>
  )
}

export const BoxPublication = BoxPublicationComponent
