import React from 'react'

import Checkbox from '../Checkbox'
import Icon from '../Icon'
import Pagination from '../Pagination'
import Column from './Column'
import { CheckboxCell, Expander } from './styles'

const defaultCheckedHeader = data => {
  const tableData = data.data
  return !tableData.some(row => !row._original.checked) && !!tableData.length
}

const checkboxColumn = (headerCb, rowCb, hideCheckboxes) => ({
  width: !hideCheckboxes ? 50 : 0,
  sortable: false,
  Header: data => {
    return !hideCheckboxes && <Checkbox onChange={headerCb} checked={defaultCheckedHeader(data)} />
  },
  Cell: ({ index, original: { checked } }) =>
    !hideCheckboxes && (
      <CheckboxCell>
        <Checkbox checked={checked} onChange={() => rowCb && rowCb(index)} />
        <div className="separator" />
      </CheckboxCell>
    ),
})

const expandColumn = () => ({
  width: 30,
  expander: true,
  sortable: false,
  Expander: ({ isExpanded, original }) => {
    const { hideExpander } = original
    const icon = isExpanded ? 'chevronUp' : 'chevronDown'
    return (
      !hideExpander && (
        <Expander>
          <Icon {...{ [icon]: true }} />
        </Expander>
      )
    )
  },
})

export const checkExistsColumn = children =>
  (children && children.type === Column) ||
  (Array.isArray(children) && children.some(c => c && c.type === Column))

const getKeys = (c, source) =>
  checkExistsColumn(c)
    ? React.Children.map(
        (c || []).filter(c => c && c.type === Column),
        ({ props: { label, labelKey, width, customRender, ...props } }) => ({
          label,
          labelKey,
          width,
          customRender,
          ...props,
        })
      )
    : Object.keys(source[0]).map(labelKey => ({ labelKey }))

export const getPagination = chl =>
  React.Children.map(chl, c => c && c.type && c.type === Pagination && c)

const titlesObj = (chl, src) =>
  getKeys(chl, src).map(col => ({
    width: col.width,
    Header: col.label || col.labelKey,
    accessor: col.labelKey,
    id: col.labelKey,
    Cell: row => (col.customRender ? col.customRender(row) : row.value),
    ...col,
  }))

export const getColumns = ({
  children,
  DetailComponent,
  onSelect,
  onSelectAll,
  hideCheckboxes,
  outsidePagination,
  src,
  columns = [
    expandColumn(),
    checkboxColumn(onSelectAll, onSelect, hideCheckboxes),
    ...titlesObj(children, src),
  ],
}) =>
  // If exist method `onSelectAll` or `onSelect` when added column "checkbox"
  onSelectAll || onSelect
    ? DetailComponent
      ? columns
      : columns.slice(0, -1)
    : // If exist prop `DetailComponent` when added column "expanded"
    DetailComponent
    ? columns.slice(1)
    : outsidePagination
    ? columns.slice(1)
    : columns.slice(1, -1)
