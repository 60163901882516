import styled from 'styled-components'
import Block from '../Block'
import Button from '../Button'

export const Card = styled(Block)`
  background-color: ${({ theme }) => theme.colorGrayAccent};
  border-radius: ${({ theme }) => theme.lineThick};
  box-shadow: 1px 1px 4px rgba(160, 160, 159, 0.4);
  position: relative;
`

export const Favorite = styled(Button)`
  bottom: 1rem;
  left: 0.3rem;
  position: absolute;
  visibility: ${props => (!props.iconState ? 'hidden' : 'visible')};

  &:hover,
  &:focus {
    background-color: initial;
    color: ${({ theme }) => theme.fontColor};
  }

  ${Card}:hover & {
    visibility: visible;
  }
`
