import React from 'react'

import { P } from '../Text'
import Block from '../Block'
import Button from '../Button'
import Icon from '../Icon'

import { NotificationStyled } from './styles'

export default ({ className, children, onClose, type, ...props }) => (
  <NotificationStyled padding middle className={className} color={type} {...props}>
    <Block width11of12 middle>
      <Block middle auto paddingRight marginRight>
        {((type === 'success' || props.success) && <Icon check-circle />) ||
          ((type === 'warning' || props.warning) && <Icon warning />) ||
          ((type === 'error' || props.error) && <Icon error />) ||
          (!type && <Icon information />)}
      </Block>
      <Block auto>
        <P>{children}</P>
      </Block>
    </Block>
    {onClose && type && type !== 'error' && (
      <Block middle width1of12 right>
        <Button borderless onClick={onClose} icon="multiply" />
      </Block>
    )}
  </NotificationStyled>
)
