import styled, { css } from 'styled-components'

import { completHover } from '../ThemeProvider/utils'

export const Link = styled.a`
  ${props => props.activeKey && completHover(props.theme)};
  ${props =>
    props.active &&
    !props.activeVertical &&
    `border-bottom:solid ${props.theme.lineThick} ${props.theme.color}`};
  background-color: ${props => (props.activeKey ? props.theme.colorAccent : 'unset')};
  color: ${props => {
    if (props.disabled) {
      return props.theme.colorGrayLight
    } else if (props.activeVertical) {
      return props.theme.colorGray
    } else {
      return props.theme.color
    }
  }};
  cursor: pointer;
  font-size: inherit;
  font-weight: ${props => (props.bold || props.active ? 'bold' : 'unset')};
  pointer-events: ${props => (props.disabled ? 'none' : 'unset')};
  text-decoration: ${props => (props.activeVertical ? 'underline' : 'none')};
  word-break: break-word;

  &:hover {
    text-decoration: underline;
  }
  &:hover,
  &:focus {
    outline: none;
  }

  .external-link-icon {
    margin-left: 2px;
    font-size: 11px;
  }
`
