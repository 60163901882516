import styled from 'styled-components'
import { Block, Span } from '@new-lucentum'
import { TRANSLATION_TYPE } from '@app/common/constants'

export const ListTerms = styled(Span)`
  ${({ theme, error }) =>
    error &&
    `
    text-decoration: underline;
    ::after {
      color: ${theme.colorRed};
      content: ' *'
    }
  `}
`

export const TranslatedTerm = styled(Span)`
  color: ${({ theme, translationType }) =>
    [TRANSLATION_TYPE.GOOGLE_TRANSLATE, TRANSLATION_TYPE.DEEPL].includes(translationType)
      ? theme.colorRed
      : theme.colorGray};
  text-decoration: ${({ error }) => (error ? 'underline' : 'unset')};
`

export const Error = styled(Span)`
  color: ${({ theme }) => theme.colorRed};
`

export const Bullet = styled.div`
  border-radius: 50%;
  min-width: 15px;
  width: 15px;
  height: 15px;
  margin: 3px 10px;
  ${({ theme, red, gray }) =>
    (red || gray) && `background-color: ${red ? theme.colorRed : theme.colorGray};`};
`

export const ScrollBlock = styled(Block)`
  ${({ theme }) => theme.scroll(theme)};
  margin: 0;
  padding: 0;
  max-height: ${({ height }) => height};
  overflow: auto;
`
