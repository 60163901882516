import { TMDSAuthService } from '@app/common/services'
import TMDSRouterService from '@app/common/tmdsRouterService'

const canActivate = () => {
  return !TMDSAuthService.isLogged
}

const getFallbackPath = location => {
  TMDSRouterService.goBack()
  return false
}

export const UNAUTH_GUARD = {
  canActivate,
  getFallbackPath,
}
