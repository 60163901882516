import { CERTIFICATE_TYPES } from '@app/redux-types/certificate'

const defaultState = {
  certificate: {
    types: [],
    status: false,
    certificateSelectedType: '',
    isRequestSuccessful: false,
  },
  isOpen: false,
  isUserPrefSubmitted: false,
  reCaptchaModalEnabled: false,
  recaptchaResponseKey: null,
  downloadStatus: null,
  contactDetails: { firstName: '', lastName: '', address: '', city: '', postCode: '', country: '' },
}

const certificate = (state = defaultState, { type, payload }) => {
  switch (type) {
    case CERTIFICATE_TYPES.GET_CERTIFICATE_TYPES:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          types: payload,
        },
      }
    case CERTIFICATE_TYPES.GET_CERTIFICATE_STATUS:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          status: payload.pendingRequest,
        },
        reCaptchaModalEnabled: true,
      }
    case CERTIFICATE_TYPES.RESET_CERTIFICATE_STATUS:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          status: false,
        },
        reCaptchaModalEnabled: true,
      }
    case CERTIFICATE_TYPES.SET_CERTIFICATE_TYPE:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          certificateSelectedType: payload,
        },
      }
    case CERTIFICATE_TYPES.REQUEST_CERTIFICATE:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          isRequestSuccessful: true,
        },
        isUserPrefSubmitted: false,
      }
    case CERTIFICATE_TYPES.TOGGLE_REQUEST_CERTIFICATE_MODAL:
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    case CERTIFICATE_TYPES.USER_PREFERENCES_SUBMITTED:
      return {
        ...state,
        isUserPrefSubmitted: payload.userPreferencesSubmitted,
        reCaptchaModalEnabled: true,
      }
    case CERTIFICATE_TYPES.RESET_PROCESS:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          isRequestSuccessful: false,
        },
        isOpen: false,
        reCaptchaModalEnabled: false,
        isUserPrefSubmitted: false,
      }
    case CERTIFICATE_TYPES.CHANGE_CERTIFICATE_CAPTCHA:
      return {
        ...state,
        recaptchaResponseKey: payload.recaptchaResponseKey,
      }
    case CERTIFICATE_TYPES.DOWNLOAD_CERTIFICATE:
      return {
        ...state,
        downloadStatus: payload,
      }
    case CERTIFICATE_TYPES.GET_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: payload,
      }
    default:
  }

  return state
}

export default certificate
