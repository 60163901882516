import {
  changePassword,
  checkLoggedUser,
  login,
  logout,
  register,
  remindPassword,
} from '@app/actions/user'
import TMDSContextService from '@app/common/tmdsContextService'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { removeCookie, setCookie } from '@app/common/utilities'
import { ROUTES } from '@app/containers/App/routes'
import store from '@app/store'

import { DEFAULT_AUTH_SETTINGS, SESSION_PERSISTANCE_KEY } from './constants'
import { getJSessionId } from './utils'

const { dispatch } = store

const ROLES = {
  TMVIEW: 'RA_TMVIEW_ROLE_CLIENT_USER',
  DSVIEW: 'RA_DSVIEW_ROLE_CLIENT_USER',
}

let redirectUrl = ''
let currentAuthSettings = {}

const doCheckLogin = () => {
  return dispatch(checkLoggedUser())
}

const checkIsValidUser = value => {
  const targetRol = TMDSContextService.isTmview() ? ROLES.TMVIEW : ROLES.DSVIEW
  const isValid = value && !!value.email && value.roles && value.roles.includes(targetRol)
  return isValid
}

const init = async (authSettings = DEFAULT_AUTH_SETTINGS) => {
  currentAuthSettings = authSettings
  //const storedUser = getStoredUser(authSettings.persistance)
  const initPromise = new Promise(resolve => {
    doCheckLogin()
      .then(({ value }) => {
        const isValid = checkIsValidUser(value)
        if (isValid) {
          authSettings.onLoggedIn && authSettings.onLoggedIn()
        }
        resolve(isValid)
      })
      .catch(() => {
        resolve(false)
      })
  })
  return initPromise
}

const beforeStoreLogin = user => {
  const jSessionId = getJSessionId(user)
  jSessionId && setCookie(SESSION_PERSISTANCE_KEY, jSessionId)
}

const doLoginCas = () => {
  const contextPart = TMDSContextService.isTmview() ? 'tm' : 'ds'
  window.location = `api/users/login/${contextPart}`
}

const doLogoutCas = () => {
  const contextPart = TMDSContextService.isTmview() ? 'TM' : 'DS'
  window.location = `api/users/logout/${contextPart}`
}

const doRegisterCas = () => {
  window.location = `api/users/register`
}

const doLogin = (email, password) => {
  const loginPromise = dispatch(login(email, password, beforeStoreLogin))
  loginPromise.then(response => {
    const nextRoute = !!redirectUrl ? redirectUrl : ROUTES.HOME
    TMDSRouterService.navigate(nextRoute)
    redirectUrl = ''
    currentAuthSettings.onLoggedIn && currentAuthSettings.onLoggedIn()
  })
  return loginPromise
}

const doLogout = () => {
  const logoutPromise = dispatch(logout())
  logoutPromise.finally(() => {
    removeCookie(SESSION_PERSISTANCE_KEY)
    window.location.reload()
  })
  return logoutPromise
}

const isLogged = () => {
  const currentState = store.getState()
  const {
    appState: {
      user: { user },
    },
  } = currentState
  const isValid = checkIsValidUser(user)
  return isValid
}

const getUserToken = () => {
  const currentState = store.getState()
  const {
    appState: {
      user: { user },
    },
  } = currentState
  return user && user.token
}

const getUserInfo = () => {
  const currentState = store.getState()
  const {
    appState: {
      user: { user },
    },
  } = currentState
  return user && user.email
}

const doChangePassword = (email, oldPassword, password) => {
  return dispatch(changePassword(email, oldPassword, password))
}

const doRemindPassword = email => {
  return dispatch(remindPassword(email))
}

const doRegister = (email, password, recaptchaResponseField) => {
  return dispatch(register(email, password, recaptchaResponseField))
}

export const TMDSAuthService = {
  init,
  doLogin,
  doCheckLogin,
  doLogout,
  doLoginCas,
  doLogoutCas,
  doRegisterCas,
  doChangePassword,
  doRemindPassword,
  doRegister,
  getUserToken,
  getUserInfo,
  get isLogged() {
    return isLogged()
  },
  set redirectUrl(url) {
    redirectUrl = url
  },
}
