import styled from 'styled-components'

export const DropdownMenuMobile = styled.div`
  div[class^='Wrapper'] {
    margin: 10px;
  }
`

export const StyledModalHeader = styled.div`
  flex: auto;
  margin-bottom: 10px;
  .modal-title {
    padding-left: 5px;
    font-weight: bold;
  }
`

export const Styled = {
  DropdownMenuMobile,
}
