export const PERSISTANCE_OPTIONS = {
  LOCAL_STORAGE: 'localStorage',
  COOKIES: 'cookies',
}
export const DEFAULT_AUTH_SETTINGS = {
  persistance: PERSISTANCE_OPTIONS.COOKIES,
  onLoggedIn: () => {},
}

export const SESSION_PERSISTANCE_KEY = 'sessionCookie'
