import React from 'react'

export const tmviewFilter = label => (option, filter) =>
  option[label] && option[label].match(RegExp(`#|${filter}`, 'gi'))

/**
 * Custom option template for TMView
 */
export default ({
  title,
  option,
  children,
  className,
  textSearchFor,
  handleMouseDown,
  handleMouseMove,
  handleMouseEnter,
  hiddenColumns = [],
  ...props
}) => (
  <div
    onMouseDown={handleMouseDown}
    onMouseEnter={handleMouseEnter}
    onMouseMove={handleMouseMove}
    className={className}
  >
    {Object.keys(option).length > 2 ? (
      Object.keys(option).map(
        key =>
          !['disabled', ...hiddenColumns].includes(key) && (
            <div key={key}>{key === title ? children : option[key]}</div>
          )
      )
    ) : (
      <span {...props}>
        <strong>{textSearchFor}</strong> {children}
      </span>
    )}
  </div>
)
