import {
  defaultOrderedColumns,
  defaultOrderedColumnsDS,
  defaultOrderedFilters,
  defaultOrderedFiltersDS,
  defaultShownColumns,
  defaultShownColumnsDS,
  KEY_APP_NAME,
  KEY_DESIGNER_NAME,
  KEY_LOCARNO_CLASS,
  KEY_OWNER_NAME_EXPLICIT,
  KEY_PREFERED_IMAGE_OF_DESIGN,
  KEY_THUMBNAIL,
  MAIN_CONTEXT_VALUE,
  TMV_APPLICATION_CONTEXT,
} from '@app/common/constants'
import {
  getLocalStorageOrderColumns,
  getLocalStorageOrderFilters,
  getShownColumns,
  setLocalStorageOrderColumns,
  setLocalStorageOrderFilters,
  setShownColumns,
} from '@app/common/storeLocalStorage'
import { t } from '@app/common/translationsService'
import { RESULTS_TYPES } from '@app/redux-types/results'

const defaultState = {
  context: '',
  isSearchFilter: false,
  isChangeFilters: true,
  viewMode: '',
  viewModeUser: '',
  showFilters: true,
  arrayExpandedIndexes: [],
  filters: [],
  filtersOrder: [],
  currentPage: 1,
  currentSort: { id: '', desc: '' },
  searchFieldsFetched: {},
  shownColumns: [],
  columnsOrder: [],
  showTableMenu: false,
  selectedTradeMarks: [],
  selectedTradeMarksData: {},
  notification: {
    text: '',
    type: '',
  },
  tradeMarkPopup: {
    visible: false,
    data: {},
  },
  exportTrademarks: {
    notification: '',
    notificationType: '',
    showExportPDFOptions: false,
    showExportXLSOptions: false,
    showExportDOCOptions: false,
    recaptchaResponseKey: null,
    columns: [],
  },
  exportStatistics: {
    showExportPDFOptions: false,
    showExportDOCOptions: false,
    goodsAndServicesGraphLegends: [],
    locarnoGraphLegends: [],
    statusGraphLegends: [],
    territoriesGraphLegends: [],
    typeGraphLegends: [],
  },
  reloadFilter: false,
  expandedRows: [],
  minResultsListFormat: 1,
  filterTags: [],
  isFiltered: false,
  tabView: null,
  lastAppliedFilter: null,
}

const loadColumnsForExport = (columnNames, shownColumns) => {
  let columns = []

  // the conditions over KEY_THUMBNAIL and KEY_PREFERED_IMAGE_OF_DESIGN are included until Backend be ready to use KEY_THUMBNAIL for export
  for (let key in columnNames) {
    if (columnNames.hasOwnProperty(key)) {
      if (key !== KEY_THUMBNAIL) {
        columns.push({
          column: key,
          label: columnNames[key],
          checked: shownColumns.includes(key) || key === KEY_PREFERED_IMAGE_OF_DESIGN,
        })
      }
    }
  }
  return columns
}

const updateTableColumns = (nextColumns, context) => {
  setShownColumns(nextColumns, context)
  return nextColumns
}

export const showTableColumn = (shownColumns, fieldKey, context) => {
  if (!shownColumns.includes(fieldKey)) {
    const resultArray = [...shownColumns, fieldKey]
    setShownColumns(resultArray, context)
    return resultArray
  }
  return [...shownColumns]
}

export const hideTableColumn = (shownColumns, fieldKey, context) => {
  const resultArray = [...shownColumns]
  if (shownColumns.includes(fieldKey)) {
    resultArray.splice(resultArray.indexOf(fieldKey), 1)
  }
  setShownColumns(resultArray, context)
  return resultArray
}

const getNewDataFilter = (data, payload) => {
  return data.map(el => {
    if (el.isBoolean) {
      return payload.newValue
    } else if (el.isDate) {
      return el.dateItem !== payload.newValue.dateItem ? el : payload.newValue
    } else if (
      payload.labelKey === KEY_APP_NAME ||
      payload.labelKey === KEY_DESIGNER_NAME ||
      payload.labelKey === KEY_OWNER_NAME_EXPLICIT ||
      payload.labelKey === KEY_LOCARNO_CLASS
    ) {
      const elem = payload.newValue.find(e => e.code === el.code)
      return elem ? elem : { ...el, checked: false }
    } else {
      return el.code !== payload.newValue.code ? el : payload.newValue
    }
  })
}
const changeFilterValues = (state, payload) =>
  state.filters.map(fil => {
    if (fil.labelKey === payload.labelKey) {
      return {
        ...fil,
        data: getNewDataFilter(fil.data, payload),
      }
    } else {
      return fil
    }
  })

const resetCheckedFilters = state =>
  state.filters.map(fil => ({
    ...fil,
    data: fil.data.map(el => ({ ...el, checked: false })),
  }))

const toggleTradeMarksList = (selectedTradeMarks, ST13Array) =>
  ST13Array.reduce(
    (acc, el) =>
      acc.includes(el)
        ? [...acc.slice(0, acc.indexOf(el)), ...acc.slice(acc.indexOf(el) + 1)]
        : [...acc, el],
    [...selectedTradeMarks]
  )

const addTradeMarksToList = (selectedTradeMarks, ST13Array) =>
  ST13Array.reduce((acc, el) => (acc.includes(el) ? acc : [...acc, el]), [...selectedTradeMarks])

const removeTradeMarksFromList = (selectedTradeMarks, ST13Array) =>
  ST13Array.reduce(
    (acc, el) =>
      acc.includes(el)
        ? [...acc.slice(0, acc.indexOf(el)), ...acc.slice(acc.indexOf(el) + 1)]
        : acc,
    [...selectedTradeMarks]
  )

const showExportStatisticsFileType = (fileType, show) =>
  (fileType === 'pdf' && { showExportPDFOptions: show }) ||
  (fileType === 'xls' && { showExportXLSOptions: show }) ||
  (fileType === 'doc' && { showExportDOCOptions: show }) ||
  {}

export default (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case 'INITIALIZE_SEARCH_FIELDS': {
      return {
        ...state,
      }
    }
    case RESULTS_TYPES.FETCH_SEARCH_REJECTED:
      return {
        ...state,
        notification: {
          text: t('error_message_key'),
          type: 'error',
        },
      }
    case RESULTS_TYPES.FETCH_SEARCH_FULFILLED:
      const mainContextValue = payload[MAIN_CONTEXT_VALUE[meta.context]]
      const newViewMode =
        payload.totalResults <= state.minResultsListFormat
          ? meta.expandedListFormat
          : !!state.viewModeUser
          ? state.viewModeUser
          : meta.listFormat
      const lengthExpanded = mainContextValue ? mainContextValue.length : 0

      const newExpandedRows = Array(lengthExpanded).fill(newViewMode === meta.expandedListFormat)

      const shownColumnsLocalStorage = getShownColumns(meta.context).map(column =>
        column !== KEY_PREFERED_IMAGE_OF_DESIGN ? column : KEY_THUMBNAIL
      )

      const shownColumns = shownColumnsLocalStorage.length
        ? shownColumnsLocalStorage
        : meta.context === TMV_APPLICATION_CONTEXT
        ? defaultShownColumns
        : defaultShownColumnsDS

      const columnsLocalStorage = getLocalStorageOrderColumns(meta.context).map(column =>
        column !== KEY_PREFERED_IMAGE_OF_DESIGN ? column : KEY_THUMBNAIL
      )

      const columnsOrder = columnsLocalStorage.length
        ? columnsLocalStorage
        : meta.context === TMV_APPLICATION_CONTEXT
        ? defaultOrderedColumns
        : defaultOrderedColumnsDS

      const filtersLocalStorage = getLocalStorageOrderFilters(meta.context)

      const filtersOrder = filtersLocalStorage?.length
        ? filtersLocalStorage
        : meta.context === TMV_APPLICATION_CONTEXT
        ? defaultOrderedFilters
        : defaultOrderedFiltersDS

      return {
        ...state,
        columnsOrder,
        filtersOrder,
        context: meta.context,
        expandedRows: newExpandedRows,
        isSearchFilter: false,
        notification: defaultState.notification,
        shownColumns,
        viewMode: newViewMode,
      }
    case 'CHANGE_DISPLAY_FORMAT':
      return {
        ...state,
        viewMode: payload.viewMode,
        viewModeUser: payload.viewMode,
      }
    case 'CURRENT_TAB_VIEW':
      return {
        ...state,
        tabView: payload.tabView,
      }
    case 'TOGGLE_FILTERS':
      return {
        ...state,
        showFilters: !state.showFilters,
      }
    case 'CLEAR_FILTERS':
      return {
        ...state,
        filters: resetCheckedFilters(state),
        filterTags: [],
      }
    case 'IS_CHANGE_FILTERS':
      return {
        ...state,
        isChangeFilters: payload,
      }
    case 'IS_SHOW_SEARCH_FILTER':
      return {
        ...state,
        showFilters: state.isEnabledSearchFilter && payload,
      }
    case 'TOGGLE_SEARCH_FILTER':
      return {
        ...state,
        isEnabledSearchFilter: payload,
        showFilters: payload,
      }
    case 'CHANGE_SEARCH_FIELDS_FETCHED':
      return {
        ...state,
        searchFieldsFetched: payload.searchFields,
        fieldsStringCodesFetched: payload.fieldsStringCodes,
      }
    case 'CHANGE_SORT_COLUMN':
      return {
        ...state,
        currentSort: payload,
      }
    case RESULTS_TYPES.CLEAR_RESULTS:
      return defaultState

    case 'SHOW_TABLE_MENU':
      return {
        ...state,
        showTableMenu: true,
      }
    case 'HIDE_TABLE_MENU':
      return {
        ...state,
        showTableMenu: false,
      }
    case 'UPDATE_TABLE_COLUMNS':
      return {
        ...state,
        shownColumns: updateTableColumns(payload, meta.context),
      }
    case 'SHOW_TABLE_COLUMN':
      return {
        ...state,
        shownColumns: showTableColumn(state.shownColumns, payload.columnKey, meta.context),
      }
    case 'HIDE_TABLE_COLUMN':
      return {
        ...state,
        shownColumns: hideTableColumn(state.shownColumns, payload.columnKey, meta.context),
      }
    case 'ORDER_TABLE_COLUMN':
      setLocalStorageOrderColumns(payload.orderedColumns, state.context)
      return {
        ...state,
        columnsOrder: payload.orderedColumns,
      }
    case 'ORDER_FILTER':
      setLocalStorageOrderFilters(payload.orderedFilters, state.context)
      return {
        ...state,
        filtersOrder: payload.orderedFilters,
      }
    case 'TOGGLE_TRADEMARK_FROM_SELECTED_LIST':
      const isSelectedTrademark = state.selectedTradeMarks.includes(payload.ST13)
      return {
        ...state,
        selectedTradeMarks: toggleTradeMarksList(state.selectedTradeMarks, [payload.ST13]),
        selectedTradeMarksData: {
          ...state.selectedTradeMarksData,
          [payload.ST13]: isSelectedTrademark ? false : payload.data,
        },
      }
    case 'ADD_ALL_TRADEMARKS_TO_SELECTED_LIST':
      const preparedAllSelectedAddedData =
        payload.data &&
        payload.data.reduce((acc, current) => {
          acc[current.ST13] = current
          return acc
        }, {})
      return {
        ...state,
        selectedTradeMarks: addTradeMarksToList(state.selectedTradeMarks, payload.ST13Array),
        selectedTradeMarksData: {
          ...state.selectedTradeMarksData,
          ...preparedAllSelectedAddedData,
        },
      }
    case 'REMOVE_ALL_TRADEMARKS_FROM_SELECTED_LIST':
      const preparedAllSelectedRemovedData =
        payload.data &&
        payload.data.reduce((acc, current) => {
          acc[current.ST13] = false
          return acc
        }, {})
      return {
        ...state,
        selectedTradeMarks: removeTradeMarksFromList(state.selectedTradeMarks, payload.ST13Array),
        selectedTradeMarksData: {
          ...state.selectedTradeMarksData,
          ...preparedAllSelectedRemovedData,
        },
      }
    case 'EMPTY_SELECTED_TRADE_MARKS_LIST':
      return {
        ...state,
        selectedTradeMarks: [],
        selectedTradeMarksData: {},
      }
    case 'TOGGLE_TRADEMARK_POPUP':
      return {
        ...state,
        tradeMarkPopup: payload,
      }
    case 'SET_RESULTS_NOTIFICATION':
      return {
        ...state,
        notification: {
          text: payload.notification,
          type: payload.type,
        },
      }
    case 'CLOSE_RESULTS_NOTIFICATION':
      return {
        ...state,
        notification: {
          text: '',
          type: '',
        },
      }
    case 'SET_GRAPH_LEGENDS':
      return {
        ...state,
        exportStatistics: {
          ...state.exportStatistics,
          [payload.legendKey]: payload.legends,
        },
      }
    case 'CHANGE_EXPORT_CAPTCHA':
      const newNotificationValues = !!payload.recaptchaResponseKey
        ? { notification: '', notificationType: '' }
        : {}
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          recaptchaResponseKey: payload.recaptchaResponseKey,
          ...newNotificationValues,
        },
      }
    case 'SHOW_EXPORT_STATS_OPTIONS':
      return {
        ...state,
        exportStatistics: {
          ...state.exportStatistics,
          ...showExportStatisticsFileType(payload.fileType, payload.show),
        },
      }
    case 'SHOW_EXPORT_PDF_OPTIONS':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          recaptchaResponseKey: null,
          notification: '',
          showExportPDFOptions: true,
          columns: loadColumnsForExport(payload.displayedColumnNames, state.shownColumns),
        },
      }
    case 'SHOW_EXPORT_XLS_OPTIONS':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          recaptchaResponseKey: null,
          notification: '',
          showExportXLSOptions: true,
          columns: loadColumnsForExport(payload.displayedColumnNames, state.shownColumns),
        },
      }
    case 'SHOW_EXPORT_DOC_OPTIONS':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          recaptchaResponseKey: null,
          notification: '',
          showExportDOCOptions: true,
          columns: loadColumnsForExport(payload.displayedColumnNames, state.shownColumns),
        },
      }
    case 'SET_EXPORT_TRADEMARKS_NOTIFICATION':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          notification: payload.notification,
          notificationType: payload.notificationType,
        },
      }
    case 'CLOSE_EXPORT_TRADEMARKS_NOTIFICATION':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          notification: '',
          notificationType: '',
        },
      }
    case 'SELECT_COLUMN_FOR_EXPORT':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          columns: state.exportTrademarks.columns.map(column =>
            column.column === payload.columnSelected
              ? { ...column, checked: !column.checked }
              : column
          ),
        },
      }
    case 'TOGGLE_ALL_COLUMNS_FOR_EXPORT':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          columns: state.exportTrademarks.columns.map(column => ({
            ...column,
            checked: payload,
          })),
        },
      }
    case 'CANCEL_EXPORT':
      return {
        ...state,
        exportTrademarks: {
          ...defaultState.exportTrademarks,
          columns: state.exportTrademarks.columns,
        },
      }
    case 'EXPORT_PDF_PENDING':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          showExportPDFOptions: false,
        },
      }
    case 'EXPORT_XLS_PENDING':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          showExportXLSOptions: false,
        },
      }
    case 'EXPORT_DOC_PENDING':
      return {
        ...state,
        exportTrademarks: {
          ...state.exportTrademarks,
          showExportDOCOptions: false,
        },
      }
    case 'TOGGLE_MODAL_SHARE_BY_EMAIL':
      return {
        ...state,
        showModalShareByEmail: !state.showModalShareByEmail,
      }
    case 'CHANGE_EXPANDED_ROWS':
      return {
        ...state,
        expandedRows: payload,
      }
    case 'FETCH_GLOBALS_FULFILLED':
      return {
        ...state,
        minResultsListFormat: payload.resultsConf.minResultsListFormat,
      }
    case 'CHANGE_FILTER_TAG':
      return {
        ...state,
        filterTags: payload,
      }
    case 'IS_FILTERED':
      return {
        ...state,
        isFiltered: payload,
      }
    case RESULTS_TYPES.FETCH_SEARCH_PENDING:
      return {
        ...state,
        searchFieldsFetched: {},
      }
    case RESULTS_TYPES.CHANGE_LAST_APPLIED_FILTER:
      return {
        ...state,
        lastAppliedFilter: payload,
      }
    default:
      return state
  }
}
