import React from 'react'
import { Field } from 'formik'
import { TextAreaFormLCT } from '@app/components/SharedStyles'

export default props => <Field {...props} component={TextArea} />

const TextArea = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => <TextAreaFormLCT {...field} {...props} />
