import { FILTERS, SEARCH_QUERY_PARAMS } from '@app/common/constants'
import { pickDifference } from '@app/common/utils'
import { getCountriesByTerritory } from '@app/common/searchUtilities'

export const getQueriesAndFilters = queryObject => {
  const filterQueryParamsKeys = Object.values(FILTERS)
  const searchQueryParamsKeys = Object.values(SEARCH_QUERY_PARAMS)

  const [filterQueryParams, restQueryParams] = pickDifference(queryObject, filterQueryParamsKeys)
  const [searchQueryParams] = pickDifference(restQueryParams, searchQueryParamsKeys)

  const queriesAndFilters = { filterQueryParams, searchQueryParams }
  return queriesAndFilters
}

export const getWipoQuery = queryObject => {
  const NO_WIPO_QUERY_PARAMS = [
    SEARCH_QUERY_PARAMS.PAGE,
    SEARCH_QUERY_PARAMS.PAGE_SIZE,
    SEARCH_QUERY_PARAMS.SORT_COLUMN_ID,
    SEARCH_QUERY_PARAMS.SORT_COLUMN_ORDER,
  ]
  const [excludedQueryParams, wipoQueryParams] = pickDifference(queryObject, NO_WIPO_QUERY_PARAMS)
  return wipoQueryParams
}

const prepareArrayToMultipleParams = (key, arrayValue) => {
  return `${key}=${arrayValue.map(item => encodeURIComponent(item)).join(',')}`
}

export const queryObjectToString = (
  queryObject,
  excludedToEncode = [SEARCH_QUERY_PARAMS.FREE_SELECTION_POINTS]
) => {
  const queryStringArray = Object.entries(queryObject).map(([key, value]) => {
    return Array.isArray(value)
      ? prepareArrayToMultipleParams(key, value)
      : `${key}=${excludedToEncode.includes(key) ? value : encodeURIComponent(value)}`
  })

  return [].concat(...queryStringArray).join('&')
}

export const buildTerritoriesList = (value, list) =>
  getCountriesByTerritory(value.split(','), list).join(',')
