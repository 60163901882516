import { fullfilled, pending, rejected } from './utils/reduxPromise'
const SET_DEFAULT_FAVORITES = 'SET_DEFAULT_FAVORITES'
const SET_FAVORITES_FROM_COOKIE = 'SET_FAVORITES_FROM_COOKIE'
const ADD_FAVORITE_FIELD = 'ADD_FAVORITE_FIELD'
const REMOVE_FAVORITE_FIELD = 'REMOVE_FAVORITE_FIELD'
const FETCH_DESIGN_NUMBER_AUTOCOMPLETE = 'FETCH_DESIGN_NUMBER_AUTOCOMPLETE'
const FETCH_DESIGNER_NAME_AUTOCOMPLETE = 'FETCH_DESIGNER_NAME_AUTOCOMPLETE'
const FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE = 'FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE'
const FETCH_OWNER_NAME_AUTOCOMPLETE = 'FETCH_OWNER_NAME_AUTOCOMPLETE'
const FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE = 'FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE'
const FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE = 'FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE'
const SET_SAVE_SEARCH = 'SET_SAVE_SEARCH'

export const SEARCH_TYPES = {
  SET_DEFAULT_FAVORITES,
  SET_FAVORITES_FROM_COOKIE,
  ADD_FAVORITE_FIELD,
  ADD_FAVORITE_FIELD_FULFILLED: fullfilled(ADD_FAVORITE_FIELD),
  ADD_FAVORITE_FIELD_PENDING: pending(ADD_FAVORITE_FIELD),
  ADD_FAVORITE_FIELD_REJECTED: rejected(ADD_FAVORITE_FIELD),
  REMOVE_FAVORITE_FIELD,
  REMOVE_FAVORITE_FIELD_FULFILLED: fullfilled(REMOVE_FAVORITE_FIELD),
  REMOVE_FAVORITE_FIELD_PENDING: pending(REMOVE_FAVORITE_FIELD),
  REMOVE_FAVORITE_FIELD_REJECTED: rejected(REMOVE_FAVORITE_FIELD),
  FETCH_DESIGN_NUMBER_AUTOCOMPLETE,
  FETCH_DESIGN_NUMBER_AUTOCOMPLETE_FULFILLED: fullfilled(FETCH_DESIGN_NUMBER_AUTOCOMPLETE),
  FETCH_DESIGN_NUMBER_AUTOCOMPLETE_PENDING: pending(FETCH_DESIGN_NUMBER_AUTOCOMPLETE),
  FETCH_DESIGN_NUMBER_AUTOCOMPLETE_REJECTED: rejected(FETCH_DESIGN_NUMBER_AUTOCOMPLETE),
  FETCH_DESIGNER_NAME_AUTOCOMPLETE,
  FETCH_DESIGNER_NAME_AUTOCOMPLETE_FULFILLED: fullfilled(FETCH_DESIGNER_NAME_AUTOCOMPLETE),
  FETCH_DESIGNER_NAME_AUTOCOMPLETE_PENDING: pending(FETCH_DESIGNER_NAME_AUTOCOMPLETE),
  FETCH_DESIGNER_NAME_AUTOCOMPLETE_REJECTED: rejected(FETCH_DESIGNER_NAME_AUTOCOMPLETE),
  FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE,
  FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE_FULFILLED: fullfilled(
    FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE
  ),
  FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE_PENDING: pending(FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE),
  FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE_REJECTED: rejected(FETCH_REPRESENTATIVE_NAME_AUTOCOMPLETE),
  FETCH_OWNER_NAME_AUTOCOMPLETE,
  FETCH_OWNER_NAME_AUTOCOMPLETE_FULFILLED: fullfilled(FETCH_OWNER_NAME_AUTOCOMPLETE),
  FETCH_OWNER_NAME_AUTOCOMPLETE_PENDING: pending(FETCH_OWNER_NAME_AUTOCOMPLETE),
  FETCH_OWNER_NAME_AUTOCOMPLETE_REJECTED: rejected(FETCH_OWNER_NAME_AUTOCOMPLETE),
  FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE,
  FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE_FULFILLED: fullfilled(
    FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE
  ),
  FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE_PENDING: pending(FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE),
  FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE_REJECTED: rejected(FETCH_PREVIOUS_OWNER_NAME_AUTOCOMPLETE),
  FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE,
  FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE_FULFILLED: fullfilled(
    FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE
  ),
  FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE_PENDING: pending(
    FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE
  ),
  FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE_REJECTED: rejected(
    FETCH_PRODUCT_INDICATION_NAME_AUTOCOMPLETE
  ),
  SET_SAVE_SEARCH,
  SET_SAVE_SEARCH_FULFILLED: fullfilled(SET_SAVE_SEARCH),
  SET_SAVE_SEARCH_PENDING: pending(SET_SAVE_SEARCH),
  SET_SAVE_SEARCH_REJECTED: rejected(SET_SAVE_SEARCH),
}
