import React from 'react'

import { BoxPanel, Title } from './styles'

export default ({ title, renderTitle, children, overflow, ...props }) => (
  <BoxPanel overflow={overflow} {...props}>
    <Title padding>{!!renderTitle ? renderTitle(title) : title}</Title>
    {children}
  </BoxPanel>
)
