import { COMPARE_TYPES } from '@app/redux-types'
import { getPlainGSList } from '@app/common/goodsAndServices'

const defaultState = {
  showCompare: false,
  query: {},
  recaptchaResponseKey: null,
  niceClassVisible: true,
  niceClassExpanded: [],
  sortBy: {
    field: '',
    ascending: 'Asc',
    title: '',
  },
}

const loadNiceClassExpanded = (ST13, trademark) => {
  const goodsAndServicesList = getPlainGSList(trademark.goodsAndServicesList)

  const goodAndServices =
    goodsAndServicesList &&
    goodsAndServicesList[0] &&
    goodsAndServicesList[0].goodAndServices &&
    goodsAndServicesList[0].goodAndServices.goodAndServiceList

  return goodAndServices && goodAndServices.map
    ? goodAndServices.map(gs => ({ ST13, niceClass: gs.niceClass, expanded: true }))
    : []
}

export default (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case 'CHANGE_COMPARE_SORT_BY':
      return {
        ...state,
        sortBy: payload.compareSortBy,
      }
    case 'CHANGE_FEEDBACK_CAPTCHA':
    case 'CHANGE_EXPORT_CAPTCHA_COMPARE':
      return {
        ...state,
        recaptchaResponseKey: payload.recaptchaResponseKey,
      }
    case 'RESET_RECAPTCHA_DETAIL':
    case 'RESET_RECAPTCHA_COMPARE':
      return {
        ...state,
        recaptchaResponseKey: null,
      }
    case 'SET_QUERY_SEARCH_COMPARE':
      return {
        ...state,
        query: payload,
      }
    case 'TOGGLE_NICE_CLASS_STATE':
      return {
        ...state,
        niceClassVisible: !state.niceClassVisible,
        niceClassExpanded: state.niceClassExpanded.map(nc => ({
          ...nc,
          expanded: !state.niceClassVisible,
        })),
      }
    case COMPARE_TYPES.FETCH_DETAIL_COMPARE_FULFILLED:
      return {
        ...state,
        niceClassExpanded: [...state.niceClassExpanded, ...loadNiceClassExpanded(meta.id, payload)],
      }
    case 'SET_NICE_CLASS_EXPANDED':
      return {
        ...state,
        niceClassExpanded: state.niceClassExpanded.map(nc =>
          nc.ST13 !== payload.ST13 || nc.niceClass !== payload.niceClass
            ? nc
            : { ...nc, expanded: payload.expanded }
        ),
      }
    default:
      return state
  }
}
