import React from 'react'
import ContentLoader from 'react-content-loader'
import { TableRowContainer } from './styles'

const TABLE_ROW_ITEMS = 9
const random = Math.random() * (1 - 0.7) + 0.7
const height = 40
const elementHeight = 12
const actionElementHeight = 11
const radius = 2

const calculateCenter = size => 20 - size / 2

const TableLoaderRows = () => (
  <ContentLoader
    height={height}
    width={1060}
    primaryColor="#e8e8e8"
    secondaryColor="#f9f9f9"
    preserveAspectRatio={'xMidYMax meet'}
    speed={1}
  >
    <rect
      x="16"
      y={calculateCenter(actionElementHeight)}
      rx={radius}
      ry={radius}
      width={actionElementHeight}
      height={actionElementHeight}
    />
    <rect
      x="32"
      y={calculateCenter(actionElementHeight)}
      rx={radius}
      ry={radius}
      width={actionElementHeight}
      height={actionElementHeight}
    />
    <rect
      x="60"
      y={calculateCenter(elementHeight)}
      rx={radius}
      ry={radius}
      width={500 * random}
      height={elementHeight}
    />
    <rect
      x="633"
      y={calculateCenter(elementHeight)}
      rx={radius}
      ry={radius}
      width={23 * random}
      height={elementHeight}
    />
    <rect
      x="653"
      y={calculateCenter(elementHeight)}
      rx={radius}
      ry={radius}
      width={78 * random}
      height={elementHeight}
    />
    <rect
      x="755"
      y={calculateCenter(elementHeight)}
      rx={radius}
      ry={radius}
      width={117 * random}
      height={elementHeight}
    />
    <rect
      x="938"
      y={calculateCenter(elementHeight)}
      rx={radius}
      ry={radius}
      width={83 * random}
      height={elementHeight}
    />
    <rect x="0" y="45" rx={radius} ry={radius} width="1060" height=".3" />
  </ContentLoader>
)
const isOdd = i => i & 1
export default ({ loading = true, itemsToShow = TABLE_ROW_ITEMS, outerTableLoader = false }) => {
  const items = !itemsToShow ? TABLE_ROW_ITEMS : itemsToShow + 1
  return loading ? (
    <TableRowContainer outerTableLoader={outerTableLoader}>
      <div>
        {[...Array(items)].map((x, i) => (
          <div className={`row${isOdd(i) ? ' solid-background' : ''}`} key={i}>
            <div>
              <TableLoaderRows solidBackground={!isOdd(i)} />
            </div>
          </div>
        ))}
      </div>
    </TableRowContainer>
  ) : (
    <div />
  )
}
