@import '../../node_modules/react-table/react-table.css';
@import '../../node_modules/react-select/dist/react-select.css';
@import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
@import '../../node_modules/x3dom/x3dom.css';
@import '../../node_modules/video-react/styles/scss/video-react.scss';
@import './theme/index.css';

html {
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: normal;
  color: var(--colorGray);
  height: 100%;
}

body {
  margin: 0;
}

html,
body,
#main {
  height: 100%;
}

.container {
  background: var(--colorAccent);
}
