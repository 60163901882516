import React, { forwardRef, useCallback, useState } from 'react'
import { Input } from './Range.styles'

const getPercentValue = (value, min, max) => {
  let percent = '0'
  if (min < max && value > min) {
    percent = (((value - min) / (max - min)) * 100).toFixed(2)
  }
  return percent
}

const RangeComponent = (props, ref) => {
  const {
    isDisabled = false,
    defaultValue = 50,
    max = 100,
    min = 0,
    onChange = () => {},
    step = 1,
    value: propsValue,
    testId,
    ...rest
  } = props

  const spreadProps = { max, min, step, ...rest }

  const [value, setValue] = useState(propsValue !== undefined ? propsValue : defaultValue)

  React.useEffect(
    () => {
      if (propsValue !== undefined && value !== propsValue) {
        setValue(propsValue)
      }
    },
    [propsValue]
  )

  const handleChange = useCallback(
    e => {
      const newValue = Number(e.target.value)
      setValue(newValue)
      // Note use of newValue to ensure up=to-date value is used
      onChange(newValue)
    },
    [onChange]
  )

  const renderValue = propsValue !== undefined ? propsValue : value
  const valuePercent = getPercentValue(renderValue, min, max)

  return (
    <Input
      type="range"
      disabled={isDisabled}
      onChange={handleChange}
      value={renderValue}
      valuePercent={valuePercent}
      data-testid={testId}
      style={{ '--range-inline-width': `${value * 100}%` }}
      {...spreadProps}
    />
  )
}

export const Range = React.memo(RangeComponent)
