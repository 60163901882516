import React from 'react'
import { compose, lifecycle, withState } from 'recompose'
import { Box } from './Box'
import { DataList } from './DataItem'
import { includeinidCodesInLabels, formatData, hasItemsToShow } from '@app/common/utilities'

const getFormatedData = (literals, inidCodes, data) => {
  const detailRow = (literalKey, dataKey, inidCode, isDate) => ({
    inidCode: inidCode || dataKey,
    label: literals[literalKey],
    data: formatData(data[dataKey], isDate),
  })
  return includeinidCodesInLabels(inidCodes, [
    detailRow('advanced.search.application.date.key', 'applicationDate', null, true),
    detailRow('registration.date.key', 'codeRegistrationDate', 'registrationDate', true),
    detailRow('tm.details.label.expiryDate.key', 'expiryDate', null, true),
    detailRow(
      'tm.details.label.oppositionPeriodStartDate.key',
      'oppositionPeriodStartDate',
      null,
      true
    ),
    detailRow(
      'tm.details.label.oppositionPeriodEndDate.key',
      'oppositionPeriodEndDate',
      null,
      true
    ),
    detailRow('tm.details.label.TerminationDate.key', 'terminationDate', null, true),
  ])
}

const enhance = compose(
  withState('formatedData', 'setFormatedData', ({ literals, inidCodes, data }) =>
    getFormatedData(literals, inidCodes, data)
  ),
  lifecycle({
    componentDidMount() {
      const { formatedData, setHasItemsToShow } = this.props
      const hasItems = hasItemsToShow(formatedData)
      hasItems !== this.props.hasItemsToShow && setHasItemsToShow('boxDates', hasItems)
    },
  })
)

export default enhance(
  ({ literals, inidCodes, data = [], formatedData, officeExceptionMessage, ...props }) => (
    <Box
      boxId="boxDates"
      title={literals['filters.tabs.dates.key']}
      hasItemsToShow={props.hasItemsToShow}
      dataTestId="item-detail-dates"
      officeExceptionMessage={officeExceptionMessage}
    >
      {props.hasItemsToShow && (
        <DataList
          testId="boxDates-show-more"
          data={formatedData}
          literals={literals}
          shownItemsLimit={3}
        />
      )}
    </Box>
  )
)
