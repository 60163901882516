import styled from 'styled-components'
import Block from '../../Block'
import Button from '../../Button'

export const NiceStyled = styled(Block)`
  display: flex;
  flex-direction: row;

  & > div:first-child {
    flex: 2;

    & > div:first-child {
      margin-left: ${({ theme }) => theme.sizeSmall};

      legend {
        margin-right: ${({ theme }) => theme.size};
      }
    }
    & > div:last-child {
      display: flex;
      flex-wrap: wrap;
    }
  }
`

export const ButtonStyled = styled(Button)`
  height: calc(${({ theme }) => theme.size} * 3);
  margin: ${({ theme }) => theme.sizeSmall};
  padding: 0;
  width: calc(${({ theme }) => theme.size} * 3);

  &:disabled:hover {
    color: ${({ theme }) => theme.colorGrayLight};
    background-color: ${({ theme }) => theme.colorTransparent};
  }
`

export const LinkStyled = styled(Block)`
  margin-left: ${({ theme }) => theme.sizeSmall};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  p {
    margin-right: ${({ theme }) => theme.sizeSmall};
  }
`

export const InfoStyled = styled(Block)`
  flex: 1;
  position: relative;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  & > div {
    bottom: -250px;
    display: ${({ displayInfo }) => (displayInfo ? 'none' : 'block')};
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  ul {
    height: 90%;
    padding-left: 20px;
  }
`
