import TMDSContextService from '@app/common/tmdsContextService'
import { getSelectData, getJapanLocarnos } from './transformationsUtils'
import { FIELDS, COMPOSED_QUERY_PARAMS, JAPAN_VERSION } from '../constants'
import { SELECTS_GLOBALS } from '@app/common/constants'

export const prepareQueryParamsFromValues = values => {
  let fieldsToAdd = {}
  const QUERY_MAP = getQueryMapByContext()
  for (const field in QUERY_MAP) {
    const preparedField = getPreparedQuery(field, values)
    if (preparedField) {
      fieldsToAdd = { ...fieldsToAdd, ...preparedField }
    }
  }
  return fieldsToAdd
}

export const getCleanedFieldsToAdd = values => {
  let fieldsToAdd = {}
  const QUERY_MAP = getQueryMapByContext()
  for (const field in QUERY_MAP) {
    const value = values[field]
    const preparedField = getPreparedQuery(field, values)
    if (preparedField) {
      const queryMap = QUERY_MAP[field]
      const preparedValue = (queryMap.CLEAN && queryMap.CLEAN(value)) || value
      fieldsToAdd = { ...fieldsToAdd, [field]: preparedValue }
    }
  }
  return fieldsToAdd
}

const getPreparedQuery = (field, values) => {
  const value = values[field]
  const QUERY_MAP = getQueryMapByContext()
  const queryMap = QUERY_MAP[field]
  const prepareQueryObject = () => {
    const preparedQuery = queryMap.PREPARE(value, values)
    return queryMap.CUSTOM ? preparedQuery : { [field]: preparedQuery }
  }
  const checkDependencies = () => {
    const dependency = queryMap.DEPENDENCY
    const meetDependencies =
      !dependency || (dependency && QUERY_MAP[dependency].CHECK(values[dependency]))
    return meetDependencies
  }
  return queryMap.CHECK(value) && checkDependencies() && prepareQueryObject()
}

const getQueryMapByContext = () => {
  return TMDSContextService.isTmview() ? QUERY_MAP_TMVIEW : QUERY_MAP_DSVIEW
}

const CHECKING = {
  EXISTS: value => value,
  NOT_EMPTY_ARRAY: value => value.length > 0,
  RANGE_DATES: value => value.from && value.to,
}

const prepareLogicQuery = value => {
  return value
    .map(element => `${element.operator ? `${element.operator},` : ''}${element.value}`)
    .join(',')
}

const prepareDateQuery = (value, field) => {
  return (
    value.from &&
    value.to && {
      [field.FROM]: value.from,
      [field.TO]: value.to,
    }
  )
}

const normalize = value => {
  return encodeURIComponent(value)
}

const prepareArrayQueryAsString = (value, prop = 'id') => {
  return value.map(element => normalize(element[prop])).join(',')
}

const prepareName = value => prepareArrayQueryAsString(value, 'text')

const QUERY_MAP_COMMON = {
  [FIELDS.APPLICATION_NUMBER]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.OFFICES]: {
    PREPARE: value => prepareArrayQueryAsString(value),
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
    CLEAN: value => value.map(office => ({ id: office.id, text: office.text })),
  },
  [FIELDS.REGISTRATION_DATE]: {
    PREPARE: value => prepareDateQuery(value, COMPOSED_QUERY_PARAMS[FIELDS.REGISTRATION_DATE]),
    CHECK: CHECKING.RANGE_DATES,
    CUSTOM: true,
  },
  [FIELDS.SEARCH_CRITERIA]: {
    PREPARE: value => normalize(value.id),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.TERRITORIES]: {
    PREPARE: value => prepareArrayQueryAsString(value),
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
    CLEAN: value =>
      value.map(territory => ({ id: territory.id, description: territory.description })),
  },
}
const QUERY_MAP_TMVIEW = {
  ...QUERY_MAP_COMMON,
  [FIELDS.APPLICANT_NAME]: {
    PREPARE: prepareName,
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.APPLICATION_DATE]: {
    PREPARE: value => prepareDateQuery(value, COMPOSED_QUERY_PARAMS[FIELDS.APPLICATION_DATE]),
    CHECK: CHECKING.RANGE_DATES,
    CUSTOM: true,
  },
  [FIELDS.BASIC_SEARCH]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.CURRENTLY_OPPOSABLE]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.GOODS_AND_SERVICES]: {
    PREPARE: value => prepareLogicQuery(value),
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.REGISTRATION_NUMBER]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.SENIORITY_CLAIMED]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.TRADEMARK_STATUS]: {
    PREPARE: value => {
      const tmStatusList = getSelectData(FIELDS.TRADEMARK_STATUS)
      const parentsTmStatus = value.filter(item => item.children)
      const selectedChildrens = value.filter(item => !item.children)
      let childrenTmStatus = parentsTmStatus.map(parent =>
        tmStatusList.filter(item => item.parent && item.parent.includes(parent.id))
      )
      childrenTmStatus = [].concat(...childrenTmStatus)
      const parsedTMStatus = [...childrenTmStatus, ...selectedChildrens]
      return prepareArrayQueryAsString(parsedTMStatus)
    },
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.TRADEMARK_TYPE]: {
    PREPARE: value => prepareArrayQueryAsString(value),
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.VIENNA_CODE]: {
    PREPARE: value => prepareLogicQuery(value),
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.VIENNA_CODE_VERSION]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
    DEPENDENCY: FIELDS.VIENNA_CODE,
  },
}

const QUERY_MAP_DSVIEW = {
  ...QUERY_MAP_COMMON,
  [FIELDS.DESIGN_NUMBER]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.DESIGN_STATUS]: {
    PREPARE: value => {
      const dsStatusList = getSelectData(SELECTS_GLOBALS.DESIGN_STATUS)
      const parentsDsStatus = value.filter(item => item.children)
      const selectedChildrens = value.filter(item => !item.children)
      let childrenDsStatus = parentsDsStatus.map(parent =>
        dsStatusList.filter(item => item.parent && item.parent.includes(parent.id))
      )
      childrenDsStatus = [].concat(...childrenDsStatus)
      const parsedDsStatus = [...childrenDsStatus, ...selectedChildrens]
      return prepareArrayQueryAsString(parsedDsStatus)
    },
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.DESIGNER_NAME]: {
    PREPARE: prepareName,
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.EFFECTIVE_DATE]: {
    PREPARE: value => prepareDateQuery(value, COMPOSED_QUERY_PARAMS[FIELDS.EFFECTIVE_DATE]),
    CHECK: CHECKING.RANGE_DATES,
    CUSTOM: true,
  },
  [FIELDS.END_DEFERMENT_DATE]: {
    PREPARE: value => prepareDateQuery(value, COMPOSED_QUERY_PARAMS[FIELDS.END_DEFERMENT_DATE]),
    CHECK: CHECKING.RANGE_DATES,
    CUSTOM: true,
  },
  [FIELDS.EXHIBITION_PRIORITY]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.EXPIRY_DATE]: {
    PREPARE: value => prepareDateQuery(value, COMPOSED_QUERY_PARAMS[FIELDS.EXPIRY_DATE]),
    CHECK: CHECKING.RANGE_DATES,
    CUSTOM: true,
  },
  [FIELDS.FILING_DATE]: {
    PREPARE: value => prepareDateQuery(value, COMPOSED_QUERY_PARAMS[FIELDS.FILING_DATE]),
    CHECK: CHECKING.RANGE_DATES,
    CUSTOM: true,
  },
  [FIELDS.INDICATION_OF_PRODUCT]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.LOCARNO]: {
    PREPARE: (value, values) => {
      const isJapanVersion = values[FIELDS.LOCARNO_VERSION] === JAPAN_VERSION
      const japanLocarnos = isJapanVersion ? getJapanLocarnos() : []
      const prepareLocarno = element => element.value
      const prepareJapanLocarno = element => {
        const wipoEquivalent = japanLocarnos.find(
          japanLocarno => japanLocarno.code === element.value
        )
        const sanitizedValue = wipoEquivalent && wipoEquivalent.id
        return sanitizedValue
      }
      const prepareFn = isJapanVersion ? prepareJapanLocarno : prepareLocarno
      const targetValue = value.map(prepareFn)
      const preparedValue = isJapanVersion ? targetValue.join(',').split(',') : targetValue
      return [...new Set(preparedValue)].join(',')
    },
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.LOCARNO_VERSION]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
    DEPENDENCY: FIELDS.LOCARNO,
  },
  [FIELDS.OWNER_NAME]: {
    PREPARE: prepareName,
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.PREVIOUS_OWNER_NAME]: {
    PREPARE: prepareName,
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.PRIORITY_COUNTRY]: {
    PREPARE: value => prepareArrayQueryAsString(value),
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.PRIORITY_DATE]: {
    PREPARE: value => prepareDateQuery(value, COMPOSED_QUERY_PARAMS[FIELDS.PRIORITY_DATE]),
    CHECK: CHECKING.RANGE_DATES,
    CUSTOM: true,
  },
  [FIELDS.PRIORITY_NUMBER]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.PUBLICATION_DATE]: {
    PREPARE: value => prepareDateQuery(value, COMPOSED_QUERY_PARAMS[FIELDS.PUBLICATION_DATE]),
    CHECK: CHECKING.RANGE_DATES,
    CUSTOM: true,
  },
  [FIELDS.REPRESENTATIVE_NAME]: {
    PREPARE: prepareName,
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
  [FIELDS.VERBAL_ELEMENT]: {
    PREPARE: value => normalize(value),
    CHECK: CHECKING.EXISTS,
  },
  [FIELDS.PRIORITY_COUNTRY]: {
    PREPARE: value => prepareArrayQueryAsString(value),
    CHECK: CHECKING.NOT_EMPTY_ARRAY,
  },
}
