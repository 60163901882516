import TMDSContextService from '@app/common/tmdsContextService'
import { INITIAL_VALUES_TMVIEW, INITIAL_VALUES_DSVIEW } from './constants'
import moment from 'moment'

export const checkForTriggeredValues = values => {
  const triggeredValues = Object.keys(values).reduce((accumulator, currentValue) => {
    const nextValue = values[currentValue] === true ? 1 : 0
    return accumulator + nextValue
  }, 0)
  return triggeredValues
}

export const getBooleanKeys = values => {
  const triggeredKeys = Object.keys(values).filter(
    key => values[key] === true || values[key] === false
  )
  return triggeredKeys
}

export const prepareFieldsValues = remoteAlert => {
  const targetInitialValues = TMDSContextService.isTmview()
    ? INITIAL_VALUES_TMVIEW
    : INITIAL_VALUES_DSVIEW
  let preparedFields = {}
  Object.entries(targetInitialValues).forEach(([key, defaultValue]) => {
    const alertValue = remoteAlert[key]
    preparedFields[key] = alertValue || alertValue === 0 ? alertValue : defaultValue
  })
  return preparedFields
}

export const checkDaysLeft = stringDate => {
  const parsedStringDate = moment(stringDate)
  const current = moment().startOf('day')
  const daysLeftUntilDate = moment.duration(parsedStringDate.diff(current)).asDays()
  return daysLeftUntilDate
}
