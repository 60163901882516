import styled, { css } from 'styled-components'

import { addSpacing } from '../../utils'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${addSpacing};
  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${overflow};
    `}
`

export const Container = StyledContainer
