import React from 'react'
import { withTheme } from 'styled-components'

import Block from '../Block'
import Button from '../Button'
import {
  BlockActions,
  BlockContent,
  CancelButton,
  CloseButton,
  StyledReactModal,
  styles,
  Title,
} from './styles.mobile'

class ModalMobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isSubmitButtonActive: false, isCloseButtonActive: false }
  }
  render() {
    const {
      children,
      contentLabel,
      onRequestClose,
      onSubmit,
      textButtonClose,
      textButtonSubmit,
      title,
      contentStyle,
      reactModalContentStyle,
      submitButtonProps = {},
      closeButtonProps = {},
      modalSize,
      theme,
      customBottomLeftAction,
      ariaHideApp = false,
      showCloseButton = false,
      customTitle,
      noModalHeader = false,
    } = this.props
    const { isSubmitButtonActive, isCloseButtonActive } = this.state
    const modalWidth = theme[`${modalSize}Modal`] || theme.mediumModal
    const modalStyle = {
      fontSize: theme.fontSize,
      maxWidth: modalWidth,
      width: modalWidth,
    }
    const reactModalStyle = {
      ...styles,
      content: {
        ...styles.content,
        ...modalStyle,
        ...contentStyle,
        ...reactModalContentStyle,
      },
    }
    return (
      <StyledReactModal
        style={reactModalStyle}
        contentLabel={contentLabel}
        onRequestClose={onRequestClose}
        ariaHideApp={ariaHideApp}
        {...this.props}
      >
        <div data-test-id={this.props.dataTestId} style={this.props.divWrapperStyle}>
          {customTitle ? (
            <Block
              alignContents="center"
              marginTop
              marginBottom
              paddingRight
              className="close-modal-wrapper"
            >
              {customTitle}
              {showCloseButton && (
                <CloseButton borderless onClick={onRequestClose} icon="multiply" />
              )}
            </Block>
          ) : !noModalHeader ? (
            <Title data-test-id="modal-header">
              {title}
              {showCloseButton && (
                <CloseButton borderless onClick={onRequestClose} icon="multiply" />
              )}
            </Title>
          ) : null}
          <BlockContent
            data-test-id="modal-content"
            style={{ background: reactModalStyle.content.background, ...contentStyle }}
          >
            {children}
          </BlockContent>
          {(textButtonSubmit || textButtonClose || customBottomLeftAction) && (
            <BlockActions>
              <Block width1of2 padding={3} left>
                {customBottomLeftAction ? customBottomLeftAction : null}
              </Block>
              <Block width1of2 padding={3} right>
                {textButtonSubmit && (
                  <Button
                    active={isSubmitButtonActive}
                    onFocus={() =>
                      this.setState({
                        isSubmitButtonActive: true,
                      })
                    }
                    onBlur={() =>
                      this.setState({
                        isSubmitButtonActive: false,
                      })
                    }
                    primary
                    onClick={onSubmit}
                    title={textButtonSubmit}
                    data-test-id="submit-button"
                    {...submitButtonProps}
                  />
                )}
                {textButtonClose && (
                  <CancelButton
                    active={isCloseButtonActive}
                    onFocus={() =>
                      this.setState({
                        isCloseButtonActive: true,
                      })
                    }
                    onBlur={() =>
                      this.setState({
                        isCloseButtonActive: false,
                      })
                    }
                    title={textButtonClose}
                    onClick={onRequestClose}
                    data-test-id="close-button"
                    {...closeButtonProps}
                  />
                )}
              </Block>
            </BlockActions>
          )}
        </div>
      </StyledReactModal>
    )
  }
}

export default withTheme(ModalMobile)
