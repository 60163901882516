import comunidadAndina from '@img/regional/comunidadAndina.png'
import senapi from '@img/regional/senapi.png'
import sicColprensa from '@img/regional/sic.jpg'
import senadi from '@img/regional/senadi.png'
import indecopi from '@img/regional/indecopi.png'

import comunidadAndinaApp from '@img/regional/comunidadAndinaApp.png'

import ipKey from '@img/regional/ipKey.jpg'
import euFunded from '@img/regional/euFunded.jpg'
import euipo from '@img/regional/euipo.jpg'

const logosMap = {
  comunidadAndina,
  senapi,
  'sic-colprensa': sicColprensa,
  senadi,
  indecopi,
}

const appLogosMap = {
  1: comunidadAndinaApp,
}

const getLogo = id => {
  return logosMap[id] || ''
}

const getAppLogo = regionId => {
  return appLogosMap[regionId] || ''
}

const getFundedLinks = () => {
  return [
    { id: 'ipKey', img: ipKey, url: 'https://ipkey.eu/en/latin-america' },
    { id: 'euFunded', img: euFunded, url: 'https://ec.europa.eu' },
    { id: 'euipo', img: euipo, url: 'https://euipo.europa.eu' },
  ]
}

export const TMDSRegionalService = {
  getLogo,
  getAppLogo,
  getFundedLinks,
}
