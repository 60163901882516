import React from 'react'
import { Block, Link } from '@new-lucentum'
import { Box } from '@app/components'
import { TMDSTrackingService } from '@app/common/services'
import TMDSContextService from '@app/common/tmdsContextService'
import {
  BigIcon,
  StyledImage,
  StyledBlock,
  StyledBlock4,
  StyledBlockTmClass,
  StyledBlockAseanTmv,
  StyledBlockIpEnforcement,
  StyledBlockEu,
  StyledBlockDsView,
} from './styles'

import eu from '@img/eu.png'
import DESIGNEU from '@img/DESIGNEU.png'
import dsviewLogo from '@img/dsviewLogo.png'
import tmviewLogo from '@img/tmviewLogo.png'
import aseanTMV from '@img/aseanTMV.png'
import TMclass from '@img/TMclass.png'
import DESIGNview from '@img/DESIGNview.png'
import euipn from '@img/euipn.png'
import ipEnforcePortal from '@img/ipEnforcePortal.png'

export default ({ literalsMoreLinks, footerLinks = {} }) => (
  <Box
    renderTitle={title => (
      <Block middle center>
        <BigIcon link /> {literalsMoreLinks}
      </Block>
    )}
    overflow={'hidden'}
  >
    {!TMDSContextService.isTmview() && (
      <StyledBlock marginTop middle paddingTop={3} paddingBottom={3} width2of12>
        <Link target="_blank" href={footerLinks.tmviewLegacy} hideIcon>
          <StyledImage big src={tmviewLogo} filterGray={1} />
        </Link>
      </StyledBlock>
    )}

    <StyledBlockEu marginTop middle paddingTop={2} paddingBottom={2} width2of12>
      <Link
        target="_blank"
        href={footerLinks.eu}
        onClick={() => TMDSTrackingService.trackEurIdLink()}
        hideIcon
      >
        <StyledImage big src={eu} filterGray={1} />
      </Link>
    </StyledBlockEu>
    <StyledBlock4 marginTop middle paddingTop={2} paddingBottom={2} width2of12>
      <Link target="_blank" href={footerLinks.euipn} hideIcon>
        <StyledImage big src={euipn} filterGray={1} />
      </Link>
    </StyledBlock4>

    {TMDSContextService.isTmview() && (
      <StyledBlockDsView marginTop middle paddingTop={2} paddingBottom={2} width2of12>
        <Link target="_blank" href={footerLinks.designView} hideIcon>
          <StyledImage big src={dsviewLogo} filterGray={1} />
        </Link>
      </StyledBlockDsView>
    )}

    <StyledBlockTmClass middle paddingBottom={2} width2of12>
      <Link target="_blank" href={footerLinks.tmClass} hideIcon>
        <StyledImage big src={TMclass} filterGray={1} />
      </Link>
    </StyledBlockTmClass>
    <StyledBlockAseanTmv middle paddingBottom={2} width2of12>
      <Link target="_blank" href={footerLinks.aseanTmv} hideIcon>
        <StyledImage big src={aseanTMV} filterGray={1} />
      </Link>
    </StyledBlockAseanTmv>
    <StyledBlockIpEnforcement middle paddingBottom={2} width2of12>
      <Link target="_blank" href={footerLinks.ipEnforcement} hideIcon>
        <StyledImage big src={ipEnforcePortal} filterGray={1} />
      </Link>
    </StyledBlockIpEnforcement>
  </Box>
)
