import React from 'react'
import { Block } from '@new-lucentum'
import { getTermsSeparator } from '@app/common/utilities'
import { GSTerm } from '../GoodsAndServices'

const renderTerms = (goodServices, separator, typeTerm) =>
  goodServices.goodsAndServices.map((gs, i, { length }) => {
    const gsTerms = i === length - 1 ? `${gs[typeTerm]}` : `${gs[typeTerm]}${separator} `
    return (
      <GSTerm key={i} translationType={gs.goodsAndServicesTranslationType}>
        {gsTerms}
      </GSTerm>
    )
  })

export const ClassificationsTranslatedTerms = ({
  niceClass,
  terms,
  termsOriginal,
  languageTranslation,
  originalLanguage,
}) => {
  const separatorTranslate = getTermsSeparator(languageTranslation.code)
  const separatorOriginal = getTermsSeparator(originalLanguage)

  return (
    <Block padding>
      <Block width1of12>{niceClass}</Block>
      <Block width5of12>{renderTerms(termsOriginal, separatorOriginal, 'term')}</Block>
      {terms &&
      terms.goodsAndServices &&
      terms.goodsAndServices.filter(term => term.translatedTerm).length ? (
        <Block width6of12 paddingLeft>
          {renderTerms(terms, separatorTranslate, 'translatedTerm')}
        </Block>
      ) : (
        ''
      )}
    </Block>
  )
}

export default ClassificationsTranslatedTerms
