import styled from 'styled-components'

export const BubbleContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 10px;
`

export const BubbleLabel = styled.span`
  display: inline-block;
  margin-left: 3px;
`

export const BubbleItem = styled.span`
  position: absolute;
  display: inline-block;
  min-width: 12px;
  min-height: 12px;
  top: -8px;
  right: -18px;
  padding: 0px 0.4em;
  background-color: red;
  color: white;
  font-size: 0.65em;
  border-radius: 50%;
  box-shadow: 1px 1px 1px #e2e2e2;
  text-align: center;
`
