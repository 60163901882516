import { Col } from './Col'
import { Container } from './Container'
import { Group } from './Group'
import { GroupMobile } from './GroupMobile'
import { Row } from './Row'
import { Spacing } from './Spacing'

export const Grid = {
  Container,
  Row,
  Col,
  Spacing,
  Group,
  GroupMobile,
}
