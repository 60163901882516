import { ADDRESS_DETAILS, SHARED_FIELDS } from '@app/common/dataManagers/types'
import { TMDSGlobalsService } from '@app/common/services/tmdsGlobalsService'
import { t } from '@app/common/translationsService'
import { formatData, includeinidCodesInLabels, parseLineBreak } from '@app/common/utilities'
import { isEmpty, path } from 'ramda'

const parseDataKey = key => key.split('.') || ['']

const prepareRawValue = value => value
const prepareData = (field, data) => {
  const { regionalEnabled } = TMDSGlobalsService.getRegionalConfig()
  const originalLanguage = regionalEnabled
    ? true
    : JSON.parse(localStorage.getItem('originalLanguage'))
  const parsedDataKey =
    !originalLanguage && field.pathTranslated
      ? field.pathTranslated.split('.')
      : !!field.path
      ? field.path.split('.')
      : ['']
  const translateFunction = field.isLiteral ? t : prepareRawValue
  const displayFunction = field.templateFunction || translateFunction
  const value = path(parsedDataKey, data)
  let preparedValue
  if (field.isArray && !originalLanguage) {
    preparedValue = value && Array.isArray(value) && value.map(item => displayFunction(item))
  } else {
    preparedValue = displayFunction(value)
  }
  return formatData(preparedValue, !!field.isDate)
}

export const getFormatedData = (fields, data, inidCodes, extraProps = {}) => {
  const detailRow = field => {
    let preparedData = data && prepareData(field, data)
    return {
      inidCode: field.inidCode || field.path,
      label: t(field.label),
      data: preparedData,
      type: field.type || 'default',
      testId: field.testId || '',
      ...extraProps,
    }
  }
  return includeinidCodesInLabels(inidCodes, fields.map(field => detailRow(field)))
}

export const parseAddress = (data = {}) => {
  const addressDetails = data[ADDRESS_DETAILS]
  let preparedData = { ...data }
  const isEmptyAddressDetails = isEmpty(addressDetails)
  const hasAddressDetailsAddress =
    data && path(parseDataKey(SHARED_FIELDS.ADDRESS_DETAILS.ADDRESS), data)
  const showFullAddress = isEmptyAddressDetails || !hasAddressDetailsAddress

  preparedData[SHARED_FIELDS.FULL_ADDRESS] = showFullAddress
    ? parseLineBreak(preparedData[SHARED_FIELDS.FULL_ADDRESS])
    : false

  return preparedData
}

export const parseData = (key, data, isDate) => {
  const preparedPath = Array.isArray(key) ? key : key.split('.')
  return data && formatData(path(preparedPath, data), !!isDate)
}

export const totalBoxEntries = data => {
  return data && Array.isArray(data) && data.length > 1 ? `(${data.length})` : ''
}
