import styled from 'styled-components'
import { BigIcon as StyledBigIcon } from '@app/components'
import { Image, Block } from '@new-lucentum'

export const BigIcon = styled(StyledBigIcon)`
  transform: scaleX(-1);
  margin-right: ${({ theme }) => theme.size};
`

export const StyledImage = styled(Image)`
  max-width: 100%;
  width: 100%;
`

export const StyledBlock = styled(Block)`
  padding-left: 30px;
  padding-right: 20px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const StyledBlockEu = styled(Block)`
  padding-left: 30px;
  padding-right: 10px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const StyledBlock4 = styled(Block)`
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const StyledBlockDsView = styled(Block)`
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const StyledBlockTmClass = styled(Block)`
  padding-left: 10px;
  padding-right: 30px;
  margin-top: 10px;
  padding-top: 20px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`
export const StyledBlockAseanTmv = styled(Block)`
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
  padding-top: 20px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const StyledBlockIpEnforcement = styled(Block)`
  padding-left: 10px;
  padding-right: 30px;
  margin-top: 10px;
  padding-top: 20px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`
