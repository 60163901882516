import React from 'react'
import { Block } from '@new-lucentum'
import { ListTerms } from './styles'

export default ({ ST13, text, textStyle, error, onClickText }) => (
  <Block style={textStyle}>
    <ListTerms data-test-id={`indication-of-product-${ST13}`} error={error} onClick={onClickText}>
      {text}
    </ListTerms>
  </Block>
)
