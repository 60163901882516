import React from 'react'
import { compose, lifecycle, withState, withHandlers } from 'recompose'
import { hasItemsToShowInArray } from '@app/common/utilities'
import { Box } from './Box'
import { DataList } from './DataItem'
import { ShowMore } from './ShowMore'
import { getFormatedData, parseAddress } from './utils'
import { representativeFields } from './fields'
import { DataRow } from './styles'
import { totalBoxEntries } from './utils'

const SHOWN_ROWS_LIMIT = 1

const enhance = compose(
  withState('showMore', 'setShowMore', false),
  withHandlers({
    formatedData: ({ inidCodes }) => dataRow => {
      return getFormatedData(representativeFields, dataRow, inidCodes)
    },
    toggleShowMore: ({ showMore, setShowMore }) => () => setShowMore(!showMore),
  }),
  lifecycle({
    componentDidMount() {
      const { data, formatedData, setHasItemsToShow } = this.props
      const hasItemsToShow = hasItemsToShowInArray(data, formatedData)
      hasItemsToShow !== this.props.hasItemsToShow &&
        setHasItemsToShow('boxRepresentative', hasItemsToShow)
    },
  })
)

export default enhance(
  ({
    literals,
    inidCodes,
    data = [],
    hasItemsToShow,
    formatedData,
    officeExceptionMessage,
    ...props
  }) => {
    const BoxData =
      hasItemsToShow &&
      data.map((dataRow, index) => {
        if (index < SHOWN_ROWS_LIMIT || props.showMore)
          return (
            <DataRow key={index}>
              <DataList
                testId="boxRepresentative-show-more2"
                data={formatedData(parseAddress(dataRow))}
                literals={literals}
                shownItemsLimit={99}
              />
              {index + 1 !== data.length && props.showMore && <hr />}
            </DataRow>
          )
      })

    return (
      <Box
        boxId="boxRepresentative"
        title={`${literals['tm.details.left.menu.representative.key']} ${totalBoxEntries(data)}`}
        hasItemsToShow={hasItemsToShow}
        dataTestId="item-detail-representative"
        officeExceptionMessage={officeExceptionMessage}
      >
        {data && !!data.length && (
          <span>
            {BoxData}
            {hasItemsToShow && data.length > SHOWN_ROWS_LIMIT && (
              <ShowMore
                testId="boxRepresentativeOwner-show-more"
                literals={literals}
                showMore={props.showMore}
                showMoreHandle={props.toggleShowMore}
              />
            )}
          </span>
        )}
      </Box>
    )
  }
)
