import React from 'react'
import ReactTable from 'react-table'

import Block from '../Block'

import Table from './styles'
import { getColumns, getPagination } from './utils'

export default ({
  children,
  DetailComponent,
  fetching,
  minRows = 1,
  onSelect,
  onSelectAll,
  hideCheckboxes,
  outsidePagination = false,
  page,
  src = [],
  ...props
}) => {
  return (
    <Table>
      <ReactTable
        data={src}
        minRows={minRows}
        showPagination={false}
        page={page}
        pageSize={20}
        SubComponent={DetailComponent && (({ row }) => React.cloneElement(DetailComponent, row))}
        columns={getColumns({
          children,
          src,
          onSelectAll,
          onSelect,
          hideCheckboxes,
          DetailComponent,
          outsidePagination,
        })}
        {...props}
      />
      {children && (
        <Block paddingTop right>
          {getPagination(children)}
        </Block>
      )}
    </Table>
  )
}
