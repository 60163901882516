export const PRIVATE_ENABLED = true
export const TMV_CONTEXT_TYPE = 'tmv'
export const DSV_CONTEXT_TYPE = 'dsv'

export const DSV_APPLICATION_CONTEXT = 'dsview'
export const TMV_APPLICATION_CONTEXT = 'tmview'

export const NO_TRANSLATE = 'NO_TRANSLATE'
export const NO_DATA_CHARACTER = '-'
export const NO_NICE_CLASS_CODE = 99
export const NO_NICE_CLASS_PARAM = 'EMPTY'
export const ADVANCED_SEARCH_FILTER_DATE_FORMAT = 'DD/MM/YYYY'
export const MAX_LOCARNO_CLASS_TO_SHOW = 5
export const MAX_WIDTH_IMAGE_SEARCH_HOLDER = 265
export const MAX_WIDTH_SHOW_CROPPED_IMAGE = 80
export const EVENT_KEY_CODES = {
  COMMA: 188,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  SPACE_TAB: 9,
  UP: 38,
  KEYCODE_SPACE: 62,
  KEYCODE_COMMA: 55,
}

export const KEY_APP_NAME = 'appName'
export const KEY_APPLICANT_NAME = 'applicantName'
export const KEY_APPLICATION_LANGUAGE_CODE = 'applicationLanguageCode'
export const KEY_APPLICATION_NUMBER = 'applicationNumber'
export const KEY_APPLICATION_DATE = 'applicationDate'
export const KEY_CURRENTLY_OPPOSABLE = 'currentlyOpposable'
export const KEY_DESIGN_NUMBER = 'designNumber'
export const KEY_DESIGN_STATUS = 'designStatus'
export const KEY_DESIGNER_NAME = 'designerName'
export const KEY_DESIGNS = 'designs'
export const KEY_DESIGN_RESULTS = 'designResults'
export const KEY_DS_NAME = 'dsName'
export const KEY_EFFECTIVE_DATE = 'effectiveDate'
export const KEY_END_OF_DEFERMENT_DATE = 'endOfDefermentDate'
export const KEY_EXHIBITION_PRIORITY = 'exhibitionPriority'
export const KEY_EXPIRY_DATE = 'expiryDate'
export const KEY_IMAGE_URLS = 'imageUrls'
export const KEY_INDICATION_OF_PRODUCT = 'indicationOfProduct'
export const KEY_LOCARNO_CLASS = 'locarnoClass'
export const KEY_MARK_URI = 'markImageURI'
export const KEY_NICE_CLASS = 'niceClass'
export const KEY_OFFICE = 'office'
export const KEY_OFFICES = 'offices'
export const KEY_OPPOSITION_DEAD_LINE = 'oppositionDeadLine'
export const KEY_OPPOSITION_PERIOD_START = 'oppositionPeriodStart'
export const KEY_OPPOSITION_PERIOD = 'oppositionPeriod'
export const KEY_OPPOSITION_PERIOD_END = 'oppositionPeriodEnd'
export const KEY_OWNER_NAME = 'applicantName'
export const KEY_PREFERED_IMAGE_OF_DESIGN = 'preferredImageUrl'
export const KEY_PREVIOUS_APPLICANT_NAME = 'previousApplicantName'
export const KEY_PRIORITY_COUNTRY = 'priorityCountry'
export const KEY_PRIORITY_DATE = 'priorityDate'
export const KEY_PRIORITY_NUMBER = 'priorityNumber'
export const KEY_PUBLICATION_DATE = 'publicationDate'
export const KEY_REGISTRATION_DATE = 'registrationDate'
export const KEY_REGISTRATION_NUMBER = 'registrationNumber'
export const KEY_REPRESENTATIVE_NAME = 'representativeName'
export const KEY_SECOND_LANGUAGE_CODE = 'secondLanguageCode'
export const KEY_SENIORITY_CLAIMED = 'seniorityClaimed'
export const KEY_T_PROTECTION = 'tProtection'
export const KEY_THUMBNAIL = 'thumbnailUrl'
export const KEY_TERRITORIES = 'territories'
export const KEY_TM_NAME = 'tmName'
export const KEY_TM_OFFICE = 'tmOffice'
export const KEY_TM_STATUS = 'tmStatus'
export const KEY_TM_TYPE = 'tmType'
export const KEY_TRADE_MARK_STATUS = 'tradeMarkStatus'
export const KEY_TRADE_MARK_TYPE = 'tradeMarkType'
export const KEY_TRADEMARKS = 'tradeMarks'
export const KEY_VERBAL_ELEMENT = 'verbalElement'
export const KEY_VIENNA_CODE = 'viennaCode'
export const KEY_VIENNA_CODES = 'viennaCodes'
export const KEY_CRITERIA = 'criteria'
export const KEY_ACCURACY = 'accuracy'
export const KEY_BASIC_SEARCH = 'basicSearch'

export const KEY_OWNER_NAME_EXPLICIT = 'ownerName'

export const TYPE_SELECT = 'select'

export const FIELDS = {
  END_DEFERMENT_DATE: {
    FROM: 'endDefermentFrom',
    TO: 'endDefermentTo',
  },
  EFFECTIVE_DATE: {
    FROM: 'effectiveFrom',
    TO: 'effectiveTo',
  },
  EXPIRY_DATE: {
    FROM: 'expiryFrom',
    TO: 'expiryTo',
  },
  PRIORITY_DATE: {
    FROM: 'priorityFrom',
    TO: 'priorityTo',
  },
  PUBLICATION_DATE: {
    FROM: 'publicationFrom',
    TO: 'publicationTo',
  },
  FILING_DATE: {
    FROM: 'filingFrom',
    TO: 'filingTo',
  },
}

// KEEP THIS ORDER, DEFAULT RESULTS COLUMNS FOR TMVIEW
export const defaultShownColumns = [
  KEY_MARK_URI,
  KEY_TM_NAME,
  KEY_TM_OFFICE,
  KEY_APPLICATION_NUMBER,
  KEY_APPLICATION_DATE,
  KEY_TRADE_MARK_STATUS,
  KEY_NICE_CLASS,
]

export const defaultColumnsMobileTM = [
  KEY_APPLICANT_NAME,
  KEY_T_PROTECTION,
  KEY_MARK_URI,
  KEY_TM_NAME,
  KEY_TM_OFFICE,
  KEY_APPLICATION_NUMBER,
  KEY_APPLICATION_DATE,
  KEY_TRADE_MARK_STATUS,
  KEY_NICE_CLASS,
]

// KEEP THIS ORDER, DEFAULT RESULTS COLUMNS FOR DSVIEW
export const defaultShownColumnsDS = [
  KEY_THUMBNAIL,
  KEY_INDICATION_OF_PRODUCT,
  KEY_OFFICE,
  KEY_APPLICATION_NUMBER,
  KEY_APPLICATION_DATE,
  KEY_DESIGN_STATUS,
  KEY_LOCARNO_CLASS,
]

// KEEP THIS ORDER
export const defaultOrderedColumns = [
  KEY_MARK_URI,
  KEY_TM_NAME,
  KEY_T_PROTECTION,
  KEY_APPLICATION_DATE,
  KEY_NICE_CLASS,
  KEY_TRADE_MARK_STATUS,
  KEY_TM_OFFICE,
  KEY_APPLICATION_NUMBER,
  KEY_REGISTRATION_NUMBER,
  KEY_APPLICANT_NAME,
  KEY_TRADE_MARK_TYPE,
  KEY_VIENNA_CODES,
  KEY_OPPOSITION_PERIOD_START,
  KEY_OPPOSITION_DEAD_LINE,
  KEY_REGISTRATION_DATE,
  KEY_SENIORITY_CLAIMED,
]

// KEEP THIS ORDER
export const defaultOrderedColumnsDS = [
  KEY_THUMBNAIL,
  KEY_INDICATION_OF_PRODUCT,
  KEY_T_PROTECTION,
  KEY_OFFICE,
  KEY_DESIGN_NUMBER,
  KEY_APPLICATION_NUMBER,
  KEY_APPLICATION_DATE,
  KEY_DESIGN_STATUS,
  KEY_LOCARNO_CLASS,
  KEY_VERBAL_ELEMENT,
  KEY_APPLICANT_NAME,
  KEY_PREVIOUS_APPLICANT_NAME,
  KEY_DESIGNER_NAME,
  KEY_REPRESENTATIVE_NAME,
  KEY_EXPIRY_DATE,
  KEY_REGISTRATION_DATE,
  KEY_PUBLICATION_DATE,
  KEY_PRIORITY_DATE,
  KEY_EFFECTIVE_DATE,
  KEY_END_OF_DEFERMENT_DATE,
  KEY_PRIORITY_NUMBER,
  KEY_PRIORITY_COUNTRY,
  KEY_EXHIBITION_PRIORITY,
]

// KEEP THIS ORDER
export const defaultOrderedFilters = [
  KEY_OFFICES,
  KEY_NICE_CLASS,
  KEY_VIENNA_CODE,
  KEY_TM_STATUS,
  KEY_TM_TYPE,
  KEY_APP_NAME,
  KEY_APPLICATION_DATE,
  KEY_REGISTRATION_DATE,
  KEY_CURRENTLY_OPPOSABLE,
  KEY_SENIORITY_CLAIMED,
]

// KEEP THIS ORDER
export const defaultOrderedFiltersDS = [
  KEY_OFFICES,
  KEY_LOCARNO_CLASS,
  KEY_DESIGN_STATUS,
  KEY_OWNER_NAME_EXPLICIT,
  KEY_DESIGNER_NAME,
  KEY_APPLICATION_DATE,
  KEY_REGISTRATION_DATE,
]

export const SEARCH_FIELD_NAMES = Object.freeze({
  CURRENTLY_OPPOSABLE: KEY_CURRENTLY_OPPOSABLE,
  SENIORITY_CLAIMED: KEY_SENIORITY_CLAIMED,
})

export const STATS_CHARTS_IDS = Object.freeze({
  STATUS: 'statusPieChart',
  NICE_CLASS: 'niceClassBarChart',
  TYPE: 'typeBarChart',
  TERRITORIES: 'territoriesMapChart',
  LOCARNO_CLASS: 'locarnoClassBarChart',
  APPLICANTS: 'applicantsBarChart',
  OWNER_NAME: 'ownerNameBarChart',
})

export const STATS_CHARTS_TABS_TITLE = Object.freeze({
  STATUS: 'status',
  NICE_CLASS: 'nice-class',
  TYPE: 'type',
  TERRITORIES: 'territories',
  LOCARNO_CLASS: 'locarnoClass',
  APPLICANTS: 'applicantNameOffices',
  OWNER_NAME: 'ownerNameOffices',
})

export const ENABLED_LANGUAGES = ['es', 'de', 'en', 'fr', 'it', 'ar']

export const AREA_HANDLERS = {
  AREA_HANDLER: 'area-handler',
  LEFT: 'left',
  TOP_LEFT: 'top-left',
  TOP: 'top',
  TOP_RIGHT: 'top-right',
  RIGHT: 'right',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottom-left',
}

export const TRANSLATION_TYPE = {
  ORIGINAL: '',
  GOOGLE_TRANSLATE: 'GOOGLE',
  TMCLASS: 'TMCLASS',
  DEEPL: 'DEEPL',
}

export const STATUS = {
  ENDED_KEY: 'Ended',
  EXPIRED_KEY: 'Expired',
  FILED_KEY: 'Filed',
  REGISTERED_KEY: 'Registered',
}

export const DESIGN_STATUS = {
  APPLICATION_PUBLISHED_KEY: 'Application published',
  FILED_KEY: 'Filed',
  REGISTERED_AND_FULLY_PUBLISHED_KEY: 'Registered and fully published',
  REGISTERED_AND_SUBJECT_TO_DEFERMENT_KEY: 'Registered and subject to deferment',
  EXPIRING_KEY: 'Expiring',
  INVALIDITY_PROCEDURE_PENDING_KEY: 'Invalidity procedure pending',
  REGISTERED_KEY: 'Registered',
  DESIGN_DECLARED_INVALID_KEY: 'Design declared invalid',
  DESIGN_SURRENDERED_KEY: 'Design surrendered',
  LACK_OF_EFFECTS_KEY: 'Lack of effects',
  ENDED_KEY: 'Ended',
  DESIGN_LAPSED_KEY: 'Design lapsed',
  EXPIRED_KEY: 'Expired',
  ONLY_ACTIVE_STATUSES_KEY: 'Only active statuses',
}

export const MAIN_CONTEXT_VALUE = {
  [DSV_APPLICATION_CONTEXT]: [KEY_DESIGN_RESULTS],
  [TMV_APPLICATION_CONTEXT]: [KEY_TRADEMARKS],
}
export const MAX_PAGE_NUMBER_ALLOWED = 100

export const SELECTS_GLOBALS = {
  DESIGN_STATUS: 'dsStatus',
  TRADEMARK_TYPE: 'tmType',
  OFFICES: 'offices',
  TERRITORIES: 'territories',
  GOODS_AND_SERVICES: 'nice',
  VIENNA_CODES: 'viennaCodes',
}

export const TERRITORIES_FIELDS = [KEY_TERRITORIES, KEY_PRIORITY_COUNTRY]
