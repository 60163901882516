import historyBg from '@img/history_background.png'
import { Content as ContentLCT } from '@new-lucentum'
import ReactTable from 'react-table'
import styled, { css } from 'styled-components'

export const StyledHistoryTable = styled(ReactTable)`
  .verbalText {
    color: #2f509a;
    font-weight: 700;
    font-size: 16px;
  }

  .ReactTable {
    background: #ffffff;
    box-shadow: 0px 2px 6px -1px rgba(60, 63, 88, 0.7);
    border-radius: 4px;
  }

  .rt-tr {
    position: static;
    height: 44px;
    left: 0px;
    top: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #e2e4e9;
    &:hover {
      background: #e9edf2;
    }
  }

  .rt-thead .rt-tr {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
  }

  .rt-tr {
    box-shadow: inset 0px -1px 0px #e2e4e9;
  }

  #pagination {
    background: #ffff;
    box-shadow: none;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .rt-th {
    text-align: left;
    color: #171c26;
  }

  .rt-tbody .rt-td {
    border-right: none !important;
    color: #2c2f35;
  }

  .rt-tr {
    box-shadow: inset 0px -1px 0px #e2e4e9;
  }

  .-sort-asc {
    box-shadow: none !important;
  }
  .-sort-desc {
    box-shadow: none !important;
  }

  .data-col {
    color: #2c2f35;
    text-align: right;
  }
`

export const Content = styled(ContentLCT)`
  // background-color: ${({ theme }) => theme.colorBackground};
`

export const StyledHistoryPage = styled.div`
  padding-bottom: 30px;
  min-height: 66vh;
  background: ${({ theme }) => theme.colorBackground} url(${historyBg}) no-repeat center 250px;
  background-size: 1500px 600px;
  .chevron {
    margin: 0 5px 0 5px;
    height: 3px;
    color: #586071;
  }

  .block-table {
    box-shadow: 0px 2px 6px -1px rgba(60, 63, 88, 0.7);
    border-radius: 4px;
    background: white;
    padding: 20px 30px;
    margin: 20px 40px;
  }

  .services-table {
    height: 110px;
    padding: 20px 30px;
    background-color: #d9e0e8;
    margin: 40px;
    border-radius: 4px;
  }

  .services-header {
    font-size: 24px;
  }

  .services-info {
    padding-top: 20px;
    font-size: 14px;
    color: #686c73;
    font-style: italic;
  }

  #red-color {
    color: #d52d2c;
  }

  .history-disclaimer {
    padding-left: 30px;
    color: #686c73;
  }

  .h3 {
    font-weight: 600;
    padding-top: 15px;
  }

  .arrow {
    color: #586071;
    padding-left: 5px;
  }

  .kLuNbU:before {
    color: #586071;
  }

  .header {
    background: #e9edf2;
    padding: 20px;
    box-shadow: inset 0px -1px 0px #2c2f35;
  }

  .history-text {
    font-size: 24px;
    padding-left: 43px;
  }

  .disclaimer {
    padding-left: 10px;
    font-size: 20px;
    color: #2f509a;
    text-decoration: underline;
    :hover {
      cursor: pointer;
    }
  }

  #history-id-data {
    white-space: nowrap;
    opacity: 0.5;
    margin-top: -13px;
  }

  .name {
    font-size: 26px;
    color: #000000;
    text-align: left;
  }

  .color-gray {
    color: #2c2f35;
  }

  .image {
    width: 200px;
    height: auto;
    max-height: 200px;
  }

  .imageContainer {
    width: 300px;
    height: 250px;
  }

  .link-number {
    text-decoration: none;
    color: #2f509a;
    :hover {
      cursor: pointer;
    }
  }

  .column {
    padding-right: 90px;
  }

  .chevron {
    :hover {
      cursor: pointer;
    }
  }
`
export const TableRow = styled.div`
  display: flex;

  #image-col {
    max-width: 300px;
  }
`

export const TableCol = styled.div`
  color: #686c73;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 800px;
  padding: 7px;

  ${({ blueText, normalWeight }) =>
    css`
      ${blueText &&
        css`
          color: #2f509a;
        `}
      ${normalWeight &&
        css`
          font-weight: normal;
        `}
    `}
`

export const StyledExpandedView = styled.div`
  z-index: 1000;
  padding: 20px;

  .null-data-text {
    text-align: center;
    padding: 40px 0 40px 0;
  }
`
