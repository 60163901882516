import styled from 'styled-components'
import { Icon } from '@new-lucentum'
import { Image } from '@new-lucentum'

export const StyledArrow = styled(Icon)`
  font-size: ${({ fontSizeIcon }) => (fontSizeIcon ? '25px' : '45px')};
  color: ${({ theme }) => theme.colorGrayLight};
  font-weight: 900;
  cursor: pointer;
`

export const StyledCarousel = styled.div`
  .BrainhubCarousel {
    img {
      width: auto;
      max-width: 100%;
      max-height: ${({ maxHeight }) => maxHeight};
    }
  }
  .BrainhubCarousel__dots {
    flex-wrap: wrap;
    li {
      max-width: 80px;
      img {
        max-width: 100%;
        max-height: 100px;
      }
    }
  }
`
export const StyledCarouselImage = styled(Image)`
  ${({ src }) => !!src && 'cursor:pointer;'};
`
