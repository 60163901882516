import { t } from '@app/common/translationsService'
import { Flex, Icon } from '@new-lucentum'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .error-message {
    display: block;
    background-color: rgba(185, 74, 72, 0.1);
    border: 1px solid #b94a48;
    clear: both;
    color: red;
    font-size: ${({ theme }) => theme.fontSizeSmaller};
    margin-top: 20px;
    padding: 0.6em 0;
    text-align: center;
  }
`
const StyledPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 44px;
  background-color: white;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color};
  display: flex;
  align-items: center;
  justify-content: center;

  .placeholder-wrapper {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color};

    i {
      font-size: 30px;
    }

    span {
      margin-right: 5px;
    }
  }
`

const PlaceholderComponent = ({ onDrop, errorMessage }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <PlaceholderWrapper>
      <StyledPlaceholder {...getRootProps()}>
        <input {...getInputProps()} className="placeholder-input" />
        <div className="placeholder-wrapper">
          <Icon camera />
          <b>{t('image_search_by')}</b>
        </div>

        {isDragActive && (
          <div className="drag-active">
            <Flex alignItems="center" justify="center" fullHeight>
              <Icon camera />
              <span>{t('image.drop.file')}</span>
            </Flex>
          </div>
        )}
      </StyledPlaceholder>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </PlaceholderWrapper>
  )
}

export const PlaceholderMobile = PlaceholderComponent
