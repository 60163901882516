import React, { Component } from 'react'
import yeast from 'yeast'
import Highlighter from 'react-highlight-words'
import { Block, Checkbox, Link, P, TooltipWrapper } from '@new-lucentum'
import { NO_DATA_CHARACTER } from '@app/common/constants'
import { ItemStyled, Image } from './styles'
import ImageCarrouselOption from './ImageCarrouselOption'

class Item extends Component {
  state = { selected: false }

  handleCheckbox = src => {
    this.setState(
      ({ selected }) => ({ selected: !selected }),
      () => this.props.onCheckbox && this.props.onCheckbox(src, this.state)
    )
  }

  render() {
    const {
      src,
      titleKey,
      linkKey,
      imageKey,
      imageUrlsKey,
      hasImageCarrouselOption,
      onShowImageCarrousel,
      templateTooltip,
      renderDetail,
      hrefOnClick,
    } = this.props
    const idTooltip = `gallery-tooltip-${src.ST13 || yeast()}`
    const onClick = e => hrefOnClick && hrefOnClick(e, src)

    return (
      <TooltipWrapper
        tooltipId={idTooltip}
        place="top"
        renderTooltip={templateTooltip(src)}
        delayHide={500}
      >
        <ItemStyled
          padding
          data-tip
          data-for={idTooltip}
          selected={this.state.selected}
          data-test-id="test-gallery-result"
        >
          <Block middle>
            <Checkbox checked={src.checked} onChange={() => this.handleCheckbox(src)} />
            {titleKey && linkKey && src[linkKey] ? (
              <Link href={src[linkKey]}>
                <Highlighter
                  searchWords={[this.props.matchingValue]}
                  textToHighlight={src[titleKey] || ''}
                  highlightClassName="highlighted"
                />
              </Link>
            ) : (
              titleKey && src[titleKey] && <P>{src[titleKey]}</P>
            )}
          </Block>
          <Block marginTop marginBottom style={{ display: 'flex', justifyContent: 'center' }}>
            {imageKey && src[imageKey] ? (
              <div style={{ position: 'relative', width: 'auto' }}>
                {hasImageCarrouselOption && (
                  <ImageCarrouselOption
                    imageUrls={src[imageUrlsKey]}
                    onShowImageCarrousel={onShowImageCarrousel}
                  />
                )}
                <Link onClick={onClick} href={src[linkKey]}>
                  <Image src={src[imageKey]} />
                </Link>
              </div>
            ) : (
              NO_DATA_CHARACTER
            )}
          </Block>
          <Block>{renderDetail && renderDetail(src)}</Block>
        </ItemStyled>
      </TooltipWrapper>
    )
  }
}

export default Item
