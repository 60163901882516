import { combineReducers } from 'redux'

import alerts from './alerts'
import compare from './compare'
import detail from './detail'
import news from './news'
import imageSearch from './imageSearch'
import pages from './pages'
import results from './results'
import search from './search'
import tags from './tags'
import user from './user'
import viennaCodes from './viennaCodes'
import locarnoCodes from './locarnoCodes'
import history from './history'

export default combineReducers({
  alerts,
  compare,
  detail,
  imageSearch,
  news,
  pages,
  results,
  search,
  tags,
  user,
  viennaCodes,
  locarnoCodes,
  history,
})
