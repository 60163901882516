import React from 'react'
import { t } from '@app/common/translationsService'
import { TMDSGlobalsService } from '@app/common/services'
import { Block } from '@new-lucentum'
import { Bullet } from './styles'

export default ({ language = 'en', ...props }) => {
  const translationProvider = TMDSGlobalsService.getTranslationProvider(language)
  return (
    <Block middle {...props}>
      {t('translations_provided_by')}
      <Bullet gray />
      {t('translate_dsclass')}
      <Bullet red />
      {translationProvider === 'DEEPL' ? 'DeepL' : t('translate_google')}
    </Block>
  )
}
