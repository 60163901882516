import React from 'react'
import { Link } from './styles'
import { t } from '@app/common/translationsService'
import TMDSContextService from '@app/common/tmdsContextService'

export const ShowMore = ({ showMore, showMoreHandle, testId = '' }) => {
  const showMoreKey = TMDSContextService.isTmview()
    ? 'tm_details_show_more'
    : 'results_details_show_more'
  const showLessKey = TMDSContextService.isTmview() ? 'tm_details_show_less' : 'filters_show_less'
  return (
    <Link data-test-id={testId} onClick={showMoreHandle}>
      {!showMore ? t(showMoreKey) : t(showLessKey)}
    </Link>
  )
}

export default ShowMore
