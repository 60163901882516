import React from 'react'

import Base from './Base'
// Custom options
import viennaOption, { viennaFilter, viennaKeyDown } from './customOption'

export default ({ type, src, collapsedFilter, ...props }) => (
  <Base
    src={src}
    collapsed={true}
    collapsedFilter={collapsedFilter}
    labelKey="description"
    valueKey="code"
    placeholder="Select Vienna classes"
    filterOption={viennaFilter(props.labelKey || 'code')}
    optionClassName="vienna"
    customOption={viennaOption}
    onKeyDown={viennaKeyDown}
    {...props}
  />
)
