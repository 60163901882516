import store from '@app/store'

export const getSelectData = field => {
  const {
    globals: {
      data: {
        selects: { [field]: fieldValue },
      },
    },
  } = store.getState()
  return fieldValue
}

export const getJapanLocarnos = () => {
  const {
    appState: {
      locarnoCodes: {
        initialLocarnoCodes: { japan },
      },
    },
  } = store.getState()
  return japan
}
