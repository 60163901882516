import { Button, Grid } from '@new-lucentum'
import React from 'react'

import ModalMobile from '../Modal/Modal.mobile'

export const CalendarErrorModal = ({ isModalOpen, toggleModal, title }) => {
  const prepareTitle = () => {
    return <div dangerouslySetInnerHTML={{ __html: title }} />
  }

  return (
    <ModalMobile
      isOpen={isModalOpen}
      onRequestClose={() => {
        toggleModal()
      }}
      showCloseButton={false}
      customTitle={prepareTitle()}
    >
      <Grid.Spacing mb={1} />
      <Button
        primary
        onClick={() => {
          toggleModal()
        }}
        title={'ok'}
        style={{ width: '100%' }}
      />
    </ModalMobile>
  )
}
