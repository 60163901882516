import { t } from '@app/common/translationsService'
import { DatePickerLCT } from '@app/components/SharedStyles'
import { Field } from 'formik'
import { identity, memoizeWith } from 'ramda'
import React from 'react'
import { compose, lifecycle, shallowEqual, shouldUpdate, withHandlers } from 'recompose'

import { checkFieldErrors, getDateRanges } from './utils'

const pureGetDateRanges = memoizeWith(identity, () => getDateRanges())

const validateFieldErrors = function(value) {
  const {
    name,
    fieldsErrorsKey,
    formik: { values },
  } = this
  const fieldErrors = values[fieldsErrorsKey][name]
  return fieldErrors && fieldErrors.map(errorLiteral => t(errorLiteral))
}
export default props => {
  return <Field {...props} validate={validateFieldErrors} component={enhanced(DatePicker)} />
}

const DatePicker = ({
  field: { ...field }, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <DatePickerLCT
      {...field}
      {...props}
      isResetting={form.status && form.status.isResetting}
      ranges={pureGetDateRanges()}
      startDate={field.value.from}
      endDate={field.value.to}
      onChange={props.handleOnChange}
      onErrorsChange={props.handleOnErrorsChange}
      onLeaving={props.handleOnLeaving}
      onBlur={props.handleOnBlur}
    />
  )
}

const enhanced = compose(
  lifecycle({
    async componentWillUnmount() {
      const {
        form: { setFieldValue },
        fieldsErrorsKey,
        field,
      } = this.props
      await setFieldValue(`${fieldsErrorsKey}.${field.name}`, false)
    },
  }),
  shouldUpdate((props, nextProps) => {
    return (
      !shallowEqual(props.field.value, nextProps.field.value) ||
      !shallowEqual(props.form.errors[props.field.name], nextProps.form.errors[props.field.name]) ||
      !shallowEqual(
        props.form.values[props.fieldsErrorsKey],
        nextProps.form.values[props.fieldsErrorsKey]
      ) ||
      !shallowEqual(props.form.status, nextProps.form.status)
    )
  }),
  withHandlers({
    handleOnChange: props => async value => {
      const { field, form } = props
      const { range } = value
      const preparedValue = {
        from: range && range.startDate,
        to: range && range.endDate,
      }
      await form.setFieldValue(field.name, preparedValue)
    },
    handleOnErrorsChange: props => errors => {
      const { fieldsErrorsKey, form, field } = props
      checkFieldErrors(errors, fieldsErrorsKey, form.values, field, form.setFieldValue)
    },
    handleOnBlur: props => value => {},
    handleOnLeaving: props => value => {
      const { field, form } = props
      form.setFieldTouched(field.name, true)
      if (props.validateOnBlur) {
        form.validateField(field.name)
      }
    },
  })
)
