const _temp0 = require("../img/DESIGNEU.png");

const _temp1 = require("../img/DESIGNview.png");

const _temp2 = require("../img/DESIGNview_WIPO_button.svg");

const _temp3 = require("../img/Globe-01.svg");

const _temp4 = require("../img/Hero.png");

const _temp5 = require("../img/TMclass.png");

const _temp6 = require("../img/TMview-blue-beta-01.png");

const _temp7 = require("../img/TMview-logo-white.svg");

const _temp8 = require("../img/TMview-logo.svg");

const _temp9 = require("../img/TMview_WIPO_button.svg");

const _temp10 = require("../img/WIPO_Global_Brand.svg");

const _temp11 = require("../img/aseanTMV.png");

const _temp12 = require("../img/background-tmdsview.jpg");

const _temp13 = require("../img/beta-word-optimized.png");

const _temp14 = require("../img/beta-word.png");

const _temp15 = require("../img/chevron.svg");

const _temp16 = require("../img/dsview-logo-white.svg");

const _temp17 = require("../img/dsview-logo.svg");

const _temp18 = require("../img/dsviewLogo.png");

const _temp19 = require("../img/dsviewLogoLegacy.png");

const _temp20 = require("../img/eu.png");

const _temp21 = require("../img/euipn.png");

const _temp22 = require("../img/history_background.png");

const _temp23 = require("../img/icon-certificates.svg");

const _temp24 = require("../img/ipEnforcePortal.png");

const _temp25 = require("../img/layout_column.gif");

const _temp26 = require("../img/layout_frontpage.png");

const _temp27 = require("../img/layout_gallery.gif");

const _temp28 = require("../img/layout_row.gif");

const _temp29 = require("../img/news_background.svg");

const _temp30 = require("../img/no-image.jpg");

const _temp31 = require("../img/sound_example.png");

const _temp32 = require("../img/tmdn.png");

const _temp33 = require("../img/tmviewLogo.png");

const _temp34 = require("../img/tmviewLogoLegacy.png");

const _temp35 = require("../img/tmview_worldmap.svg");

const _temp36 = require("../img/worldmap.svg");

const _temp37 = require("../img/x3d_background.jpg");

module.exports = {
  "DESIGNEU":   {
    "png": _temp0
  },
  "DESIGNview":   {
    "png": _temp1
  },
  "DESIGNview_WIPO_button":   {
    "svg": _temp2
  },
  "Globe-01":   {
    "svg": _temp3
  },
  "Hero":   {
    "png": _temp4
  },
  "TMclass":   {
    "png": _temp5
  },
  "TMview-blue-beta-01":   {
    "png": _temp6
  },
  "TMview-logo-white":   {
    "svg": _temp7
  },
  "TMview-logo":   {
    "svg": _temp8
  },
  "TMview_WIPO_button":   {
    "svg": _temp9
  },
  "WIPO_Global_Brand":   {
    "svg": _temp10
  },
  "aseanTMV":   {
    "png": _temp11
  },
  "background-tmdsview":   {
    "jpg": _temp12
  },
  "beta-word-optimized":   {
    "png": _temp13
  },
  "beta-word":   {
    "png": _temp14
  },
  "chevron":   {
    "svg": _temp15
  },
  "dsview-logo-white":   {
    "svg": _temp16
  },
  "dsview-logo":   {
    "svg": _temp17
  },
  "dsviewLogo":   {
    "png": _temp18
  },
  "dsviewLogoLegacy":   {
    "png": _temp19
  },
  "eu":   {
    "png": _temp20
  },
  "euipn":   {
    "png": _temp21
  },
  "history_background":   {
    "png": _temp22
  },
  "icon-certificates":   {
    "svg": _temp23
  },
  "ipEnforcePortal":   {
    "png": _temp24
  },
  "layout_column":   {
    "gif": _temp25
  },
  "layout_frontpage":   {
    "png": _temp26
  },
  "layout_gallery":   {
    "gif": _temp27
  },
  "layout_row":   {
    "gif": _temp28
  },
  "news_background":   {
    "svg": _temp29
  },
  "no-image":   {
    "jpg": _temp30
  },
  "sound_example":   {
    "png": _temp31
  },
  "tmdn":   {
    "png": _temp32
  },
  "tmviewLogo":   {
    "png": _temp33
  },
  "tmviewLogoLegacy":   {
    "png": _temp34
  },
  "tmview_worldmap":   {
    "svg": _temp35
  },
  "worldmap":   {
    "svg": _temp36
  },
  "x3d_background":   {
    "jpg": _temp37
  }
}