import * as React from 'react'

import Language from '../Language'
import { Header, Top } from './styles'
import Block from '../Block'
import Button from '../Button'
import Content from '../Content'
import Icon from '../Icon'

export default ({
  children,
  currentLang,
  fontSizes,
  language,
  login,
  onLanguage,
  onResize,
  onSignIn,
  onSignOut,
  onSignUp,
  textSignIn = 'Sign In',
  textSignOut = 'Sign Out',
  textSignUp = 'Sign Up',
  signInTestId,
  signUpTestId,
  languageSelectTestId,
  ...props
}) => (
  <Header {...props}>
    {(fontSizes || language || login) && (
      <Top>
        <Content>
          <Block paddingTop paddingBottom right>
            {fontSizes && (
              <Button
                onDropdown={e => onResize && onResize(e)}
                primary
                title={<Icon font-resize />}
                type="dropdown"
              >
                {(fontSizes || []).map(({ value, size }) => (
                  <Button key={size} title={value} size={size} />
                ))}
              </Button>
            )}
            {language && (
              <Language
                src={language}
                primary
                onLanguage={onLanguage}
                currentLang={currentLang}
                testId={languageSelectTestId}
              />
            )}
            {login && login.username ? (
              <Button
                onDropdown={e =>
                  e.key === textSignOut && onSignOut
                    ? onSignOut(e)
                    : (document.location.href = e.props.src)
                }
                primary
                title={login.username}
                type="dropdown"
              >
                {[...login.options, { label: textSignOut }].map(({ label, src }) => (
                  <Button key={label} src={src}>
                    {label}
                  </Button>
                ))}
              </Button>
            ) : (
              <div>
                <Button data-test-id={signInTestId} primary onClick={onSignIn}>
                  {textSignIn}
                </Button>
                <Button data-test-id={signUpTestId} primary onClick={onSignUp}>
                  {textSignUp}
                </Button>
              </div>
            )}
          </Block>
        </Content>
      </Top>
    )}
    <Block paddingTop paddingBottom>
      <Content>
        <Block base>{children}</Block>
      </Content>
    </Block>
  </Header>
)
