import {
  changeCertificateCaptcha,
  requestCertificate,
  resetProcess,
} from '@app/actions/certificate'
import { t } from '@app/common/translationsService'
import { Block, Modal, Notification } from '@new-lucentum'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch, useSelector } from 'react-redux'

import { INITIAL_VALUES as initialValues } from '../constants'
import CertificateRequestForm from './CertificateRequestForm'

const CertificatesMandatoryInfoModal = ({ st13 }) => {
  const reCaptchaModalEnabled = useSelector(
    state => state.uiState.certificate.reCaptchaModalEnabled
  )
  const certificateSelectedType = useSelector(
    state => state.uiState.certificate.certificate.certificateSelectedType
  )
  const reCaptchaSiteKey = useSelector(state => state.globals.data.reCaptcha.reCaptchaSiteKey)
  const reCaptchaEnabled = useSelector(state => state.globals.data.reCaptcha.reCaptchaEnabled)
  const userPref = useSelector(state => state.settings.certificateLegalNotesShown)
  const isOpen = useSelector(state => state.uiState.certificate.isOpen)
  const enableProceess = reCaptchaModalEnabled || (!userPref && isOpen)

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [showRecaptchaError, setShowRecaptchaError] = useState(false)
  const [recaptchaResponseKey, setRecaptchaResponseKey] = useState(null)
  const [formIsValid, setFormIsValid] = useState(false)
  const [formikValues, setFormikValues] = useState(initialValues)
  const dispatch = useDispatch()

  const changeHandler = value => {
    setIsSubmitButtonDisabled(false)
    setRecaptchaResponseKey(value)
    dispatch(changeCertificateCaptcha({ recaptchaResponseKey: value }))
  }

  const resetState = () => {
    setIsSubmitButtonDisabled(true)
    dispatch(resetProcess())
  }

  const submitHandler = () => {
    if (recaptchaResponseKey === null && reCaptchaEnabled) {
      setShowRecaptchaError('visibility')
      return false
    }

    setIsSubmitButtonDisabled(true)

    dispatch(
      requestCertificate({
        st13,
        certificateType: certificateSelectedType,
        contactDetails: formikValues,
        recaptchaKey: recaptchaResponseKey,
      })
    )
  }

  const errorHandler = () => {
    setIsSubmitButtonDisabled(true)
  }

  return (
    <Modal
      dataTestId="security-check-modal"
      isOpen={enableProceess}
      onSubmit={() => submitHandler()}
      onRequestClose={() => resetState()}
      textButtonClose={t('close.key')}
      textButtonSubmit={t('submit.key')}
      submitButtonProps={{ disabled: isSubmitButtonDisabled || !formIsValid }}
      title={t('certificates.mandatory.information.modal.title')}
    >
      <Block center marginBottom={2}>
        <span>
          {t('certificates.request.requirements.desc')}
          <br />
          <br />
          {t('certificates.contact.details.notification.desc')}
        </span>
      </Block>
      <Block center marginBottom={3}>
        <CertificateRequestForm setFormIsValid={setFormIsValid} onChange={setFormikValues} />
      </Block>
      {reCaptchaModalEnabled && (
        <Block center>
          <ReCAPTCHA
            sitekey={reCaptchaSiteKey}
            onChange={value => changeHandler(value)}
            onExpired={errorHandler}
            onError={errorHandler}
          />
        </Block>
      )}
      {showRecaptchaError && !recaptchaResponseKey && (
        <Notification error onClose={() => setShowRecaptchaError(false)}>
          {t('recaptcha_missing')}
        </Notification>
      )}
    </Modal>
  )
}

export default CertificatesMandatoryInfoModal
