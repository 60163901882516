export class EXHIBITION_PRIORITY_FIELDS {
  static COUNTRY_CODE = 'countryCode'
  static CITY_NAME = 'cityName'
  static NAME = 'name'
  static EXHIBITION_DATE = 'exhibitionDate'
  static PARTIAL_PRIORITY = 'partialPriority'
  static PARTIAL_PRIORITY_INDICATOR = 'partialPriorityIndicator'
  static STATUS_CODE = 'statusCode'
  static STATUS_CODE_TRANSLATED = 'statusCodeTranslated'
  static FIRST_DISPLAY_DATE = 'firstDisplayDate'
}
