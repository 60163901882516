import getActionPromise from '@app/common/getActionPromise'

const defaultValues = {
  fetching: false,
  fetched: false,
  error: null,
}

const defaultState = {
  globals: defaultValues,
  literals: defaultValues,
  basicSearchAutocomplete: defaultValues,
  detail: defaultValues,
  termsTranslation: defaultValues,
  search: defaultValues,
  searchWithFilters: defaultValues,
  login: defaultValues,
  results: defaultValues,
  tagsByMarks: defaultValues,
  alertsByMarks: defaultValues,
  tagsGroups: defaultValues,
  availableTags: defaultValues,
  tagGroups: defaultValues,
  manageAlerts: defaultValues,
  searchTm: defaultValues,
  detailExpanded: defaultValues,
  applicationOffices: defaultValues,
  changePassword: defaultValues,
  remindPassword: defaultValues,
  register: defaultValues,
}

export default (state = defaultState, { type, payload }) => {
  const { storeName, status } = getActionPromise(type)

  if (!defaultState[storeName]) return state

  switch (status) {
    case 'PENDING':
      return {
        ...state,
        [storeName]: {
          fetching: true,
          fetched: false,
          error: null,
        },
      }
    case 'REJECTED':
      return {
        ...state,
        [storeName]: {
          fetching: false,
          fetched: false,
          error: payload,
        },
      }
    case 'FULFILLED':
      return {
        ...state,
        [storeName]: {
          fetching: false,
          fetched: true,
          error: null,
        },
      }
    case 'RESET':
      return {
        ...state,
        [storeName]: defaultValues,
      }
    default:
      return state
  }
}
