import { Icon, Tooltip } from '@new-lucentum'
import camel from 'lodash.camelcase'
import { clone, concat, prop, uniqBy } from 'ramda'
import * as React from 'react'
import ReactDOM from 'react-dom'
import Highlighter from 'react-highlight-words'
import Select from 'react-select'

import customOption from './Options'
import { BlockTooltip, Wrapper } from './styles'
import customValue from './Values'

const defaultProps = {
  customOption: child => child,
  collapsed: false,
  collapsedFilter: e => e.level === 1,
  icon: 'chevron-down',
  labelKey: 'text',
  multi: true,
  onBlurResetsInput: false,
  placeholder: 'Select a value',
  src: [],
  textClass: 'Class',
  textSearchFor: 'search for',
  textSelectAll: 'Select All',
  valueKey: 'text',
  closeOnSelect: false,
  rtl: false,
}

export default class extends React.PureComponent {
  onSearch = false
  onSearchOptions = clone(this.props.src).map(o => ({ ...o, level: 1, children: false }))
  ReactSelect = Select
  src = clone(this.props.src)
  collapsedOptions = []
  state = {
    value: clone(this.props.value),
    options: this.src,
    optionExpanded: [],
    focus: false,
  }

  static defaultProps = defaultProps

  UNSAFE_componentWillMount() {
    if (this.props.async) {
      this.ReactSelect = Select.Async
    } else if (this.props.creatable) {
      this.ReactSelect = Select.Creatable
    } else if (this.props.asyncCreatable) {
      this.ReactSelect = Select.AsyncCreatable
    }
    if (this.props.collapsed) {
      const collapsedOptions = this.src.filter(option => this.props.collapsedFilter(option))
      const expandedOptions = collapsedOptions
        .filter(option => option.parent)
        .map(option => option.parent[0])
      this.setState({
        options: collapsedOptions,
        optionExpanded: expandedOptions,
      })
    } else {
      this.setState({ optionExpanded: this.src.filter(prop('children')).map(prop('id')) })
    }
  }

  UNSAFE_componentWillReceiveProps({ value, src }) {
    value && this.setState({ value: clone(value) })
    src && (this.src = clone(src))
  }

  onClose = () => {
    this.setState({ focus: false })
    this.inputValue = null
    this.props.onClose && this.props.onClose()
  }

  // When you remove a father value, restore children inside options.
  handlerClick = (val, onRemove) => {
    if (this.props.optionClassName === 'vienna') {
      const allSelected =
        val.children &&
        Array.isArray(this.state.value) &&
        this.state.value.filter(
          x => !(x.id === val.id) && !(x.parent && x.parent.some(y => y === val.id))
        )

      allSelected ? this.setState({ value: allSelected }) : onRemove(val)

      this.props.onChange && allSelected && this.props.onChange(this.removeAllChildren(allSelected))
    } else {
      onRemove(val)
    }
  }

  addChildren = () =>
    this.props.multi && this.state.value && this.props.optionClassName === 'vienna'
      ? this.src.filter(
          ({ id, parent }) =>
            (this.state.value || []).some(v => v.id === id) ||
            (parent && (this.state.value || []).some(p => parent.includes(p.id)))
        )
      : this.state.value

  // If the value has children, remove children of the array for notificate only father in onChange.
  removeAllChildren = value =>
    Array.isArray(value) &&
    value.filter(
      x => !x.parent || (x.parent && !x.parent.some(y => (value || []).some(e => e.id === y)))
    )

  onChange = value => {
    if (this.props.optionClassName === 'nice') {
      value = value.filter(x => x.num)
    }

    this.props.max
      ? value.length <= this.props.max && this.setState({ value })
      : this.setState({ value })

    this.props.onChange &&
      (this.props.optionClassName && this.props.optionClassName === 'vienna'
        ? this.props.onChange(this.removeAllChildren(value))
        : this.props.onChange(value))
  }

  setOptions = val => {
    if (!this.onSearch && !!val) {
      this.onSearch = true
      this.collapsedOptions = [...this.state.options]
      this.setState({ options: this.onSearchOptions })
    } else if (this.onSearch && !val) {
      this.onSearch = false
      this.setState({ options: [...this.collapsedOptions] })
    }
  }

  onInputChange = val => {
    this.setOptions(val)
    this.inputValue = val
    if (this.props.onInputChange) {
      const newVals = this.props.onInputChange(val, this.props) || []
      newVals.length && this.setState(({ value }) => ({ value: [...value, ...newVals] }))
    } else {
      return this.inputValue
    }
  }

  onKeyDown = e => {
    const { onKeyDown, src, valueKey } = this.props
    if (onKeyDown) {
      const opts = onKeyDown(e, src)
      if (opts) {
        e.preventDefault()
        this.setState(({ value }) => ({
          value: value ? uniqBy(prop(valueKey), concat(value, opts)) : opts,
        }))
      }
    }
  }
  onBlur = e => {
    this.props.onBlur && this.props.onBlur(e, this.state.value)
  }
  arrowRenderer = () => <Icon {...{ [camel(this.props.icon)]: true }} />
  optionRenderer = option => (
    <Highlighter
      searchWords={[this.inputValue]}
      textToHighlight={option[this.props.labelKey] || ''}
      highlightClassName="highlighted"
    />
  )

  handleMouseOver = ({ num, info }) => this.setState({ info, num, visible: true })

  handleMouseOut = () => this.setState({ visible: false })

  render = () => {
    const { ReactSelect } = this
    return (
      <Wrapper type={this.props.optionClassName}>
        <div
          data-tip
          data-for={`${this.props.name}Tooltip`}
          data-test-id={`advance-search-${this.props.name}`}
        >
          <ReactSelect
            rtl={this.props.rtl}
            multi={this.props.multi}
            options={this.props.options || this.state.options}
            valueComponent={customValue(this)}
            // $FlowFixMe
            optionComponent={customOption({
              component: this.props.customOption,
              handleMouseOut: this.handleMouseOut,
              handleMouseOver: this.handleMouseOver,
              hiddenColumns: this.props.hiddenColumns,
              infoKey: this.props.infoKey,
              nameLink: this.props.nameLink,
              parentClassName: this.props.optionClassName,
              self: this,
              src: this.src,
              state: this.state,
              textClass: this.props.textClass,
              textLink: this.props.textLink,
              textSearchFor: this.props.textSearchFor,
              textSelectAll: this.props.textSelectAll,
              title: this.props.labelKey,
              toLink: this.props.toLink,
              valueKey: this.props.valueKey,
            })}
            arrowRenderer={this.arrowRenderer}
            optionRenderer={this.optionRenderer}
            {...this.props}
            value={this.addChildren()}
            onClose={this.onClose}
            onChange={this.onChange}
            onInputKeyDown={this.onKeyDown}
            onInputChange={this.onInputChange}
            onBlur={this.onBlur}
            onOpen={() => {
              const node = ReactDOM.findDOMNode(this)
              if (
                node instanceof HTMLElement &&
                this.props.outerPrefix &&
                this.props.outerPrefix.length > 0
              ) {
                const child = node.querySelector('.Select-menu-outer')
                child.setAttribute('data-test-id', `select_${this.props.outerPrefix}_outer`)
              }
              this.setState({ focus: true })
            }}
            ref={this.props.inputRef}
            onFocus={this.onFocus}
            clearRenderer={() => <Icon close />}
          />
        </div>
        {this.props.tooltip && !this.state.focus && (
          <Tooltip
            id={`${this.props.name}Tooltip`}
            offset={{ right: this.props.tooltipRight ? this.props.tooltipRight : 0 }}
            place="right"
          >
            <BlockTooltip>{this.props.tooltip()}</BlockTooltip>
          </Tooltip>
        )}
        {this.props.errorMessage && !this.state.focus && (
          <Tooltip
            id={`${this.props.name}Tooltip`}
            offset={{ right: this.props.tooltipRight ? this.props.tooltipRight : 0 }}
            place="right"
            type="error"
          >
            <BlockTooltip>{this.props.errorMessage}</BlockTooltip>
          </Tooltip>
        )}
        {this.props.max && this.props.maxMessage && !this.state.focus && (
          <Tooltip
            id={`${this.props.name}Tooltip`}
            offset={{ right: this.props.tooltipRight ? this.props.tooltipRight : 0 }}
            place="right"
            type="warning"
          >
            <BlockTooltip>{this.props.maxMessage}</BlockTooltip>
          </Tooltip>
        )}
      </Wrapper>
    )
  }
}
