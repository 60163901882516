import { cleanGoodsAndServicesList } from '@app/common/utilities'

const sortByNiceClass = ({ niceClass: niceClass1 = '' }, { niceClass: niceClass2 = '' }) => {
  if (isNaN(niceClass1)) return -1
  if (isNaN(niceClass2)) return 1
  return parseInt(niceClass1) - parseInt(niceClass2)
}

export const getPlainGSList = gsList =>
  Array.isArray(gsList)
    ? gsList.reduce(
        (acc, gs, index) =>
          index > 0
            ? acc[0].goodAndServices &&
              acc[0].goodAndServices.goodAndServiceList &&
              Array.isArray(acc[0].goodAndServices.goodAndServiceList) &&
              gs.goodAndServices &&
              gs.goodAndServices.goodAndServiceList &&
              Array.isArray(gs.goodAndServices.goodAndServiceList) && [
                {
                  ...acc[0],
                  goodAndServices: {
                    ...acc[0].goodAndServices,
                    goodAndServiceList: cleanGoodsAndServicesList(
                      acc[0].goodAndServices.goodAndServiceList
                    )
                      .concat(gs.goodAndServices.goodAndServiceList)
                      .sort(sortByNiceClass),
                  },
                },
              ]
            : [
                gs.goodAndServices && Array.isArray(gs.goodAndServices.goodAndServiceList)
                  ? {
                      ...gs,
                      goodAndServices: {
                        ...gs.goodAndServices,
                        goodAndServiceList: cleanGoodsAndServicesList(
                          gs.goodAndServices.goodAndServiceList
                        ).sort(sortByNiceClass),
                      },
                    }
                  : gs,
              ],
        []
      )
    : []
