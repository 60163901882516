import TMDSContextService from '@app/common/tmdsContextService'
import { FIELDS } from './fields'

export const getHelpTooltipsByContext = () => {
  return TMDSContextService.isTmview() ? HELP_TOOLTIPS_TMVIEW : HELP_TOOLTIPS_DSVIEW
}

const HELP_TOOLTIPS_COMMON = {
  [FIELDS.APPLICATION_NUMBER]: {
    TITLE: 'help_search_application_number_title',
    DESCRIPTION: 'help_search_application_number',
  },
  [FIELDS.REGISTRATION_DATE]: {
    TITLE: 'help_search_reg_date_title',
    DESCRIPTION: 'help_search_reg_date',
  },
  [FIELDS.TERRITORIES]: {
    TITLE: 'help_search_territories_title',
    DESCRIPTION: 'help_search_territories',
  },
  [FIELDS.OFFICES]: {
    TITLE: 'help_search_territories_title',
    DESCRIPTION: 'help_search_territories',
  },
}

const HELP_TOOLTIPS_TMVIEW = {
  ...HELP_TOOLTIPS_COMMON,
  [FIELDS.APPLICANT_NAME]: {
    TITLE: 'help_search_app_name_title',
    DESCRIPTION: 'help_search_app_name',
  },
  [FIELDS.APPLICATION_DATE]: {
    TITLE: 'help_search_app_date_title',
    DESCRIPTION: 'help_search_app_date',
  },
  [FIELDS.GOODS_AND_SERVICES]: {
    TITLE: 'help_search_nice_class_title',
    DESCRIPTION: 'help_search_nice_class',
  },
  [FIELDS.REGISTRATION_NUMBER]: {
    TITLE: 'help_search_registration_number_title',
    DESCRIPTION: 'help_search_registration_number',
  },
  [FIELDS.TRADEMARK_STATUS]: {
    TITLE: 'help_search_tm_status_title',
    DESCRIPTION: 'help_search_tm_status',
  },
  [FIELDS.TRADEMARK_TYPE]: {
    TITLE: 'help_search_tm_type_title',
    DESCRIPTION: 'help_search_tm_type',
  },
  [FIELDS.VIENNA_CODE]: {
    TITLE: 'help_search_vienna_code_title',
    DESCRIPTION: 'help_search_vienna_code',
  },
}

const HELP_TOOLTIPS_DSVIEW = {
  ...HELP_TOOLTIPS_COMMON,
  [FIELDS.DESIGNER_NAME]: {
    TITLE: 'help_search_designer_name_title',
    DESCRIPTION: 'help_search_designer_name',
  },
  [FIELDS.DESIGN_NUMBER]: {
    TITLE: 'help_search_design_number_title',
    DESCRIPTION: 'help_search_design_number',
  },
  [FIELDS.DESIGN_STATUS]: {
    TITLE: 'help_search_design_status_title',
    DESCRIPTION: 'help_search_design_status',
  },
  [FIELDS.EXHIBITION_PRIORITY]: {
    TITLE: 'help_search_exhibition_priority_title',
    DESCRIPTION: 'help_search_exhibition_priority',
  },
  [FIELDS.LOCARNO]: {
    TITLE: 'indication.clasification.key',
    DESCRIPTION: 'help_search_locarno',
  },
  [FIELDS.OWNER_NAME]: {
    TITLE: 'help_search_owner_name_title',
    DESCRIPTION: 'help_search_owner_name',
  },
  [FIELDS.PREVIOUS_OWNER_NAME]: {
    TITLE: 'help_search_previous_owner_name_title',
    DESCRIPTION: 'help_search_previous_owner_name',
  },
  [FIELDS.PRIORITY_COUNTRY]: {
    TITLE: 'help_search_priority_country_title',
    DESCRIPTION: 'help_search_priority_country',
  },
  [FIELDS.PRIORITY_NUMBER]: {
    TITLE: 'help_search_priority_number_title',
    DESCRIPTION: 'help_search_priority_number',
  },
  [FIELDS.REPRESENTATIVE_NAME]: {
    TITLE: 'help_search_representative_name_title',
    DESCRIPTION: 'help_search_representative_name',
  },
  [FIELDS.VERBAL_ELEMENT]: {
    TITLE: 'help_search_verbal_element_title',
    DESCRIPTION: 'help_search_verbal_element',
  },
  [FIELDS.END_DEFERMENT_DATE]: {
    TITLE: 'help_search_end_deferment_date_title',
    DESCRIPTION: 'help_search_end_deferment_date',
  },
  [FIELDS.EFFECTIVE_DATE]: {
    TITLE: 'help_search_effective_date_title',
    DESCRIPTION: 'help_search_effective_date',
  },
  [FIELDS.EXPIRY_DATE]: {
    TITLE: 'help_search_expiry_date_title',
    DESCRIPTION: 'help_search_expiry_date',
  },
  [FIELDS.FILING_DATE]: {
    TITLE: 'help_search_filing_date_title',
    DESCRIPTION: 'help_search_filing_date',
  },
  [FIELDS.PRIORITY_DATE]: {
    TITLE: 'help_search_priority_date_title',
    DESCRIPTION: 'help_search_priority_date',
  },
  [FIELDS.PUBLICATION_DATE]: {
    TITLE: 'help_search_publication_date_title',
    DESCRIPTION: 'help_search_publication_date',
  },
}
