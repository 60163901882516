/* eslint-disable */
import {
  changeResetImage,
  closeImageSearch,
  emptyImageSearchApp,
  emptyImageSearchUI,
  setResetImage,
  updateImageSearchUiData,
  abortableUploadImage,
  uploadImage,
  setImageData,
  clearImageData,
} from '@app/actions/imageSearch'
import {
  getFileExtension,
  getFileType,
  validateFilesSize,
  validateFilesType,
} from '@app/common/utilities'
import { ImageSearchNew } from '@app/components/ImageSearchNew'
import { PropTypes } from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

class ImageSearchContainerComponent extends React.Component {
  // Not to be confused with "appState" that is saved in redux store
  state = {}

  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      imageData: null,
      errorMessage: '',
      uiData: null,
      abort: null,
    }
    this.handleInModal = this.handleInModal.bind(this)
  }

  componentDidMount() {
    const imgData = this.props.uiState.imageSearch.imageData

    if (this.props.resetImage) {
      this.handleEmptyImageSearchApp()
      this.props.emptyImageSearchUI()
    }
    this.props.changeResetImage()
    if (imgData) {
      this.setState({ imageData: imgData })
    }
    if (this.props.setHandleInModal) {
      this.props.setHandleInModal(this.handleInModal)
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props['preloadedImage'] !== nextProps['preloadedImage']) {
      this.setState({ imageData: nextProps.preloadedImage })
      this.handleImageLoaded()
    }
    if (
      this.props['resettingFromOutside'] !== nextProps['resettingFromOutside'] &&
      nextProps['resettingFromOutside'] === true
    ) {
      this.handleEmptyImageSearchApp()
    }
  }

  resetImageSearch() {
    this.handleEmptyImageSearchApp()
    this.props.emptyImageSearchUI()
    this.setState({ imageData: null })
    if (this.props.onResetImageSearch) {
      this.props.onResetImageSearch()
    }
  }

  handleInModal() {
    const { updateImageSearchUiData } = this.props
    this.props.setImageData(this.state.imageData)
    updateImageSearchUiData(this.state.uiData)
    return this.state.uiData
  }

  readAndSaveFile(file) {
    let { inModal } = this.props
    if (file instanceof Blob) {
      const imgURL = URL.createObjectURL(file)

      this.setState({ imageData: imgURL }, () => {
        if (!inModal) {
          this.props.setImageData(this.state.imageData)
        }
      })
    } else if (file.imageData) {
      this.setState({ imageData: file.imageData }, () => {
        if (!inModal) {
          this.props.setImageData(this.state.imageData)
        }
      })
    }
  }

  handleImageLoaded() {
    const imageSearchUI = this.props.uiState.imageSearch
    const imageSearchApp = this.props.appState.imageSearch

    const selectedSegment = imageSearchApp.data?.segments?.find(
      segment => segment.isSelected === true
    )

    imageSearchUI.formData.imageId
      ? this.handleImageAndSegmentsChange(imageSearchUI.formData)
      : selectedSegment &&
        this.handleImageAndSegmentsChange({
          imageId: imageSearchApp.data.imageId,
          imageName: imageSearchApp.data.imageName,
          segmentSelectedId: selectedSegment.id,
          segmentLeft: Math.round(selectedSegment.left),
          segmentRight: Math.round(selectedSegment.right),
          segmentTop: Math.round(selectedSegment.upper),
          segmentBottom: Math.round(selectedSegment.lower),
        })
  }

  handleDrop(files) {
    /* Validation is done before upload file in order to
     * avoid fatal error due to a heap out of memory
     */
    const filesConf = this.props.globals.imageSearchConf.filesConf
    const sizeValidation = validateFilesSize(Array.from(files), 2, filesConf)
    const types = filesConf && Object.keys(filesConf)

    const hasValidSize = sizeValidation.result
    const hasValidType = validateFilesType(Array.from(files), types)
    if (hasValidSize && hasValidType) {
      this.startUploadImage(files)
    } else {
      if (!hasValidSize) {
        this.setErrorMessage(this.generateSizeErrorMessage(sizeValidation.maxSize))
      }

      if (!hasValidType) {
        this.setErrorMessage(this.generateExtensionErrorMessage(files))
      }
    }
  }

  startUploadImage = files => {
    let { abortableUploadImage, onImageUploaded, literals } = this.props
    this.setState({
      fileName: files[0].name,
    })
    if (getFileType(files[0]) !== 'TIFF') {
      // Store file as is
      this.readAndSaveFile(files[0])
    }
    const { promise: imageUploadPromise, abort } = abortableUploadImage(files)
    this.setState({
      abort,
    })
    imageUploadPromise.then(response => {
      if (response?.errorCode) {
        this.handleAbort()
        this.setErrorMessage(literals['error_message_key'])
      } else {
        if (response) {
          if (onImageUploaded) {
            onImageUploaded()
          }
          if (response.imageData) {
            // Store converted image provided by the backend
            this.readAndSaveFile({ ...files[0], imageData: response.imageData })
          }
          this.handleImageLoaded()
        }
      }
    })
  }

  setErrorMessage(message) {
    this.setState({ errorMessage: message })
    setTimeout(() => this.setState({ errorMessage: '' }), 10000) //Remove error message after 10 s
  }

  generateSizeErrorMessage(maxSize) {
    const { literals } = this.props
    return literals['image.search.file.size.too.large'].replace(new RegExp(/\{0\}/, 'g'), maxSize)
  }

  generateExtensionErrorMessage(files) {
    const { literals } = this.props
    const extension = getFileExtension(files[0].name)
    return literals['image.search.extension.not.supported'].replace(
      new RegExp(/\{0\}/, 'g'),
      extension
    )
  }

  handleImageAndSegmentsChange(data) {
    const { inModal } = this.props
    let updatedUIData = { ...data }
    const imageStored = this.props.appState.imageSearch.data
    if (!!updatedUIData.imageId && updatedUIData.imageId !== imageStored.imageId) {
      const UiDataFromImageDataStored = {
        imageId: imageStored.imageId,
        imageName: imageStored.imageName,
        segmentSelectedId: null,
        segmentTop: 0,
        segmentLeft: 0,
        segmentBottom: imageStored.imageH,
        segmentRight: imageStored.imageW,
      }
      updatedUIData = { ...updatedUIData, ...UiDataFromImageDataStored }
    }

    this.setState({ uiData: updatedUIData })
    if (!inModal) {
      this.props.updateImageSearchUiData(updatedUIData)
    }
  }

  handleEmptyImageSearchApp() {
    this.props.clearImageData()
    this.props.setResetImage()
    this.props.emptyImageSearchApp()
  }

  handleAbort() {
    if (this.state.abort) {
      this.state.abort()
      this.resetImageSearch()
      this.handleEmptyImageSearchApp()
    }
  }

  render() {
    const {
      closeImageSearch,
      emptyImageSearchUI,
      appState,
      uiState,
      literals,
      globals,
    } = this.props

    const { filesConf } = globals.imageSearchConf
    const _segmentSelectedId = this.state.uiData && this.state.uiData.segmentSelectedId
    const {
      segmentSelectedId,
      segmentLeft,
      segmentRight,
      segmentTop,
      segmentBottom,
    } = uiState.imageSearch.formData
    return (
      <ImageSearchNew
        isUploadingImage={appState.imageSearch.fetching}
        data={this.state.imageData}
        handleDrop={files => this.handleDrop(files)}
        handleRemove={() => this.resetImageSearch()}
        handleSelectionUpdate={data => this.handleImageAndSegmentsChange(data)}
        handleFreeSelection={freeSelectionPoints => {
          this.setState({ uiData: { ...this.state.uiData, freeSelectionPoints } })
          this.props.updateImageSearchUiData({ freeSelectionPoints })
        }}
        handleAdvancedOptionsChange={advancedOptions =>
          this.handleImageAndSegmentsChange(advancedOptions)
        }
        handleAbort={() => this.handleAbort()}
        errorMessage={this.state.errorMessage}
        standalone={this.props.inModal}
        initialAdvancedSearchValues={{
          colour: uiState.imageSearch.formData.colour,
          ocr: uiState.imageSearch.formData.ocr,
        }}
        initialSelections={{
          free: uiState.imageSearch.formData.freeSelectionPoints,
          area: {
            segmentSelectedId,
            left: segmentLeft,
            right: segmentRight,
            upper: segmentTop,
            lower: segmentBottom,
          },
        }}
      />
    )
  }
}

const mapStateToProps = ({
  globals: { data: globals },
  literals: { data: literals },
  appState,
  uiState,
}) => ({
  globals,
  literals,
  appState,
  uiState,
})

const mergeProps = ({ ...stateProps }, { ...actionProps }, { ...ownProps }) => ({
  ...stateProps,
  ...ownProps,
  ...actionProps,
})

const mapDispatchToProps = {
  uploadImage,
  abortableUploadImage,
  emptyImageSearchApp,
  setResetImage,
  closeImageSearch,
  updateImageSearchUiData,
  emptyImageSearchUI,
  changeResetImage,
  setImageData,
  clearImageData,
}

ImageSearchContainerComponent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
}

const ImageSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ImageSearchContainerComponent)

export default ImageSearchContainer
