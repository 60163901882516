/* eslint-disable simple-import-sort/imports */
import TMDSContextService from '@app/common/tmdsContextService'
import { Column } from '@new-lucentum'
import React from 'react'
import moment from 'moment'
import {
  always,
  compose,
  equals,
  ifElse,
  isEmpty,
  isNil,
  join,
  lensPath,
  reject,
  set,
  view,
  when,
} from 'ramda'

import { statisticsColors } from '../theme'

export const removeNullEmptyOrFalseProperties = obj =>
  Object.keys(obj)
    .filter(
      key =>
        obj[key] !== null &&
        ((!Array.isArray(obj[key]) && obj[key] !== '' && obj[key] !== false) || obj[key].length)
    )
    .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {})

export const mbToBytes = mb => mb * 1024 * 1024

export const removeEmptyProperties = obj =>
  Object.keys(obj).reduce((o, key) => (obj[key] ? { ...o, [key]: obj[key] } : o), {})

export function getFileType(file) {
  let fileType = ''
  fileType = file && file.type && file.type.split('/')
  if (fileType !== '') {
    fileType = (fileType[1] || fileType[0]).toUpperCase()
  }
  return fileType
}

export function validateFileType(file, types) {
  let fileType = getFileType(file)

  if (fileType === 'JPEG') {
    fileType = 'JPG'
  }

  return types.includes(fileType)
}

export function validateFilesType(files, types) {
  if (!types || types.length === 0) {
    return true
  } else {
    return files.every(file => validateFileType(file, types))
  }
}

export const validateFileSize = (file, maxSizeMB = 0, filesConf = {}) => {
  let maxSize = maxSizeMB
  let fileType = ''

  if (!isNil(filesConf) && !isEmpty(filesConf)) {
    fileType = file && file.type && file.type.split('/')
    if (fileType !== '') {
      fileType = (fileType[1] || fileType[0]).toUpperCase()
    }

    if (fileType === 'JPEG') {
      fileType = 'JPG'
    }

    maxSize = filesConf[fileType]?.maxSize
  }
  return {
    result: !maxSize || maxSize < 0 ? true : file.size < mbToBytes(maxSize),
    type: fileType,
    maxSize,
  }
}

export function validateFilesSize(files, maxSizeMB, filesConf) {
  const fileInvalid = files
    .map(file => validateFileSize(file, maxSizeMB, filesConf))
    .find(fileResult => !fileResult.result)

  return fileInvalid ? fileInvalid : { result: true, type: null, maxSize: null }
}

export const getFileExtension = fileName =>
  fileName
    .split('.')
    .pop()
    .toUpperCase()

const FILENAME_REGEX = /filename=['"]?([^;][\w .-]*)['"]?/

const safeRegex = reg => str => (reg.exec(str) || []).slice(1)

export const getHeaderFileName = headers => {
  return safeRegex(FILENAME_REGEX)(headers.get('Content-Disposition'))[0]
}

export const formatIsoStringDate = dateString => {
  if (!dateString) {
    return ''
  }
  if (!isEmpty(dateString) && dateString.length === 10) {
    return dateString
      .split('-')
      .reverse()
      .join('/')
  } else if (!isEmpty(dateString)) {
    const timezoneDate = new Date(dateString)
    const date = new Date(timezoneDate.getTime() + timezoneDate.getTimezoneOffset() * 60000)
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    let dt = date.getDate()

    if (dt < 10) {
      dt = '0' + dt
    }
    if (month < 10) {
      month = '0' + month
    }

    return `${dt}/${month}/${year}`
  }
  return ''
}

export const daysDiff = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const date1WithoutTime = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const date2WithoutTime = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
  return Math.round((date1WithoutTime.getTime() - date2WithoutTime.getTime()) / oneDay)
}

const prepareArrayToMultipleParams = (key, value) => {
  let arrayQueryString = ''
  for (const item of value) {
    arrayQueryString += `${key}=${encodeURIComponent(item)}&`
  }
  return arrayQueryString
}

// TO DO: Refactor app query params
export const queryObjectToString = (queryObject, initialCharacter = '?') => {
  let queryString = initialCharacter
  Object.entries(queryObject).forEach(([key, value]) => {
    queryString += Array.isArray(value)
      ? prepareArrayToMultipleParams(key, value)
      : `${key}=${encodeURIComponent(value)}&`
  })
  return queryString.slice(0, -1)
}

export const buildQueryParams = (obj, initialCharacter) => {
  return queryObjectToString(obj, initialCharacter)
}

export const obtainLabelFromSelect = (codeValue, data, key, textKey) => {
  const objFound = data && data.find(el => el[key] === codeValue)
  return typeof objFound !== 'undefined' ? objFound[textKey] : ''
}

export const obtainObjectFromSelect = (codeValue, data, key) => {
  const objFound = data.find(el => el[key] === codeValue)
  return typeof objFound !== 'undefined' ? objFound : {}
}

export const autocompleteFetchFunction = (
  minKeyTextLength,
  dispatchFetchFunc,
  literals,
  context
) => (text, callback) => {
  if (text.length >= minKeyTextLength) {
    return dispatchFetchFunc(text, literals, context).then(results => ({ options: results }))
  } else {
    callback(null, { options: [] })
  }
}

export const autocompleteFetchFunctionRefactor = (minKeyTextLength, dispatchFetchFunc) => (
  text,
  callback
) => {
  if (text.length >= minKeyTextLength) {
    return dispatchFetchFunc(text).then(results => ({ options: results }))
  } else {
    callback(null, { options: [] })
  }
}

// Returns version of IE or false, if browser is not Internet Explorer
export const isIE = () => {
  var userAgent = window.navigator.userAgent
  // Test values; Uncomment to check result …
  // IE 10  userAgent = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  // IE 11  userAgent = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  // Edge 12 (Spartan)  userAgent = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  // Edge 13  userAgent = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = userAgent.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10)
  }

  var trident = userAgent.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    var rv = userAgent.indexOf('rv:')
    return parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10)
  }

  var edge = userAgent.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10)
  }

  // other browser
  return false
}

export const isLogged = userApp => userApp && userApp.user && userApp.user.email

export const replaceInLiteral = (str = '', values = []) =>
  values.reduce((acc, val, i) => {
    return acc.split('{' + i + '}').join(val)
  }, str)

export const buildBackendErrorMessage = payload => {
  const data =
    isNil(payload.parsedBody.details) || isEmpty(payload.parsedBody.details)
      ? []
      : payload.parsedBody.details
  return {
    error: true,
    errorMessage: payload.parsedBody.message,
    errorDetails: [...data],
  }
}

export const changeNameAttributeCreatedTags = tags => {
  return tags.map(tag => {
    return tag.className ? { id: tag.text, text: tag.text } : tag
  })
}

export const formatData = (data, isDate = false) =>
  data ? (!isDate ? data : formatIsoStringDate(data)) : ''

export const getCookie = key => {
  const cookies = document.cookie
  if (cookies === '') return undefined
  const specificCookie = cookies
    .replace(/; /g, ';')
    .split(';')
    .filter(cookie => cookie.includes('=') && cookie.split('=')[0] === key)
  return specificCookie.length ? specificCookie[0].split('=')[1] : undefined
}

export const setCookie = (key, value, days = 1, path = '/') => {
  let expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = `${key}=${value}; expires=${expirationDate}; path=${path}`
}

export const setAppCookie = (key, value, days = 1) => {
  const path = window.location.pathname
  const parsedPath = path.replace('beta/', '')
  setCookie(key, value, days, parsedPath)
}

export const removeCookie = key => {
  setCookie(key, '', -1)
}

export const getNewPage = (e, currentPage) => {
  let newPage = e.target.value
  if (!newPage) {
    const step = e.target.className.includes('right') ? 1 : -1
    newPage = currentPage + step
  }
  return newPage
}

export const getDateColumn = (labelKey, label, otherProps = {}) => (
  <Column
    labelKey={labelKey}
    label={label}
    customRender={tableObj => formatIsoStringDate(tableObj.value)}
    {...otherProps}
  />
)
export const getDateColumnWithoutSort = (labelKey, label, otherProps = {}) => (
  <Column
    labelKey={labelKey}
    label={label}
    sortable={false}
    resizable={false}
    customRender={tableObj => formatIsoStringDate(tableObj.value)}
    {...otherProps}
  />
)

// getDateRanges returns the prop ranges to overwrite Lucentum's defaultRanges.js
// lucentum/src/components/DatePicker/defaultRanges.js
export const getDateRanges = (literals, nMonths = 6, nYears = [2, 5, 10]) => ({
  [literals['filters.date.lastMonth.key']]: {
    startDate: now => now.add(-1, 'months').add(1, 'days'),
    endDate: now => now,
  },
  [literals['filters.date.lastNMonths.key'] &&
  literals['filters.date.lastNMonths.key'].replace('{N}', nMonths)]: {
    startDate: now => now.add(-nMonths, 'months').add(1, 'days'),
    endDate: now => now,
  },
  [literals['filters.date.lastYear.key']]: {
    startDate: now => now.add(-1, 'years').add(1, 'days'),
    endDate: now => now,
  },
  [literals['filters.date.lastNYears.key'] &&
  literals['filters.date.lastNYears.key'].replace('{N}', nYears[0])]: {
    startDate: now => now.add(-nYears[0], 'years').add(1, 'days'),
    endDate: now => now,
  },
  [literals['filters.date.lastNYears.key'] &&
  literals['filters.date.lastNYears.key'].replace('{N}', nYears[1])]: {
    startDate: now => now.add(-nYears[1], 'years').add(1, 'days'),
    endDate: now => now,
  },
  [literals['filters.date.lastNYears.key'] &&
  literals['filters.date.lastNYears.key'].replace('{N}', nYears[2])]: {
    startDate: now => now.add(-nYears[2], 'years').add(1, 'days'),
    endDate: now => now,
  },
})

export const getFixedOrderedArray = (fixedSortedKeys, arrayToSort, key) => {
  return fixedSortedKeys
    .map(filterKey => {
      return arrayToSort.find(filterObj => {
        return filterObj[key] === filterKey
      })
    })
    .filter(sortedElement => sortedElement !== undefined)
}

export const changeLanguageSelector = languageList =>
  languageList.map(ele => {
    const longLang = `${ele.lang} (${ele.code})`
    return { ...ele, title: longLang, lang: longLang }
  })

export const getDigitsFromString = string => {
  const match = string.match(/\d+/) || ''
  return Array.isArray(match) && match.length > 0 ? match[0] : ''
}

export const searchDateFormat = isoDate => {
  const dateObj = moment(isoDate, 'YYYY-MM-DD')
  return dateObj.isValid() ? dateObj.format('YYYY-MM-DD') : ''
}

export const includeinidCodesInLabels = (inidCodes, rows) =>
  rows.map(({ label, ...row }) => {
    const key = row.inidCode || row.labelKey
    return {
      ...row,
      label: inidCodes[key] ? `${inidCodes[key].replace(/\(|\)/g, '')} ${label}` : label,
    }
  })

export const setFieldToValid = fieldKey => ({
  [fieldKey]: {
    isValid: true,
    errorMessage: '',
  },
})

export const setInvalidFieldKey = (state, fieldKey, errorMessage) => ({
  ...state,
  formState: {
    ...state.formState,
    isValid: false,
    errors: {
      ...state.formState.errors,
      [fieldKey]: {
        isValid: false,
        errorMessage,
      },
    },
  },
})

export const setFieldValues = (payload, state) => ({
  ...state,
  formState: {
    ...state.formState,
    fields: {
      ...state.formState.fields,
      ...state.formState.form.setFieldValues(payload),
    },
  },
})

export const validateEntireForm = state => ({
  ...state,
  formState: {
    ...state.formState,
    isValid: !Object.values(state.formState.errors).find(({ isValid }) => !isValid),
  },
})

export const validateIndividualState = (state, fieldKey) => {
  const fieldValidation = setFieldToValid(fieldKey)
  return {
    ...state,
    formState: {
      ...state.formState,
      isValid: !state.formState.isValid ? false : fieldValidation[fieldKey].isValid,
      errors: {
        ...state.formState.errors,
        ...fieldValidation,
      },
    },
  }
}

export const validationMessage = (state, payload) =>
  when(
    () => equals('VALIDATION', view(lensPath(['parsedBody', 'type']), payload)),
    set(lensPath(['backEndErrorMessage']), buildBackendErrorMessage(payload)),
    state
  )

const getFavoritesCookieName = () => {
  return TMDSContextService.isTmview() ? 'favorite' : 'dsv_favorite'
}

export const getFavorites = () => {
  const cookieName = getFavoritesCookieName()
  const favorites = getCookie(cookieName)
  return favorites && favorites.length ? favorites.split('_') : []
}

export const setFavoritesCookie = favorites => {
  const cookieName = getFavoritesCookieName()
  const cookieValue = favorites.join('_')
  setCookie(cookieName, cookieValue, 365)
}

export const isFavoriteCookieSet = () => {
  const cookieName = getFavoritesCookieName()
  const favorites = getCookie(cookieName)
  return typeof favorites !== 'undefined'
}

export const isNilOrEmpty = param => isNil(param) || isEmpty(param)

export const noBX = ifElse(
  isEmpty,
  always(''),
  compose(
    join(', '),
    reject(equals('BX'))
  )
)
export const fetchAllTranslation = (
  goodServices,
  sourceLanguage,
  targetLanguage,
  classificationKind,
  fetchTranslations,
  ST13
) => {
  goodServices.forEach((item, index) => {
    setTimeout(function() {
      fetchTranslations(sourceLanguage, targetLanguage, classificationKind, item, ST13)
    }, index * 200)
  })
}

export const getNavigatorLanguage = () =>
  navigator.language.includes('-')
    ? navigator.language.substring(0, navigator.language.indexOf('-'))
    : navigator.language

export const isRTLLanguage = language =>
  ['ar', 'he', 'arc', 'dv', 'fa', 'ha', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'].includes(language)

export const getStaticColour = index => statisticsColors[index % statisticsColors.length]

export const calculatePercentage = (x, y) => (x / y) * 100
export const fetchAsBlob = url => fetch(url).then(response => response.blob())

export const getExtensionFromBase64 = content => content.split(';')[0].split('/')[1]

export const blobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

export const urlToFile = (url, filename, mimeType) =>
  fetch(url)
    .then(res => res.arrayBuffer())
    .then(
      buf => new File([buf], filename, { type: mimeType || (url.match(/^data:([^;]+);/) || '')[1] })
    )

export const getContextFromPathname = pathname => pathname.replace(/^\/|\/$/g, '').substring(0, 6)

export const getTermsSeparator = (language = '') =>
  language && language.toUpperCase() === 'EL' ? '·' : ';'

export const cleanGoodsAndServicesList = goodsAndServicesList =>
  goodsAndServicesList.reduce((acc, value) => {
    return !!value.goodsAndServices &&
      value.goodsAndServices.length === 1 &&
      Object.keys(value.goodsAndServices[0]).length === 0
      ? [...acc]
      : [...acc, value]
  }, [])

export const getNumberItemsToShow = (formatedData = []) =>
  Array.isArray(formatedData) && formatedData.filter(item => item.data).length

export const hasItemsToShow = (formatedData = []) =>
  Array.isArray(formatedData) && formatedData.some(item => item.data)

export const hasItemsToShowInArray = (data = [], formatFunction) =>
  Array.isArray(data) && data.some(record => hasItemsToShow(formatFunction(record)))

export const pad = (n, width, z = '0') => {
  const s = String(n)
  return String(z).repeat(Math.max(0, width - s.length)) + s
}

const checkEnv = () => {
  return TMDSContextService.isTmview() ? 'tmviewImage' : 'dsviewImage'
}

export const getApiDomain = () => {
  const href = window.location.href
  const hash = window.location.hash
  const appDomain = href.replace(hash, '')
  return `${appDomain}api`
}

export const prepareActiveLanguages = languages => {
  const activeLanguages =
    languages &&
    languages.map(language => {
      return {
        ...language,
        enabled: true,
      }
    })
  const sortedLanguages =
    activeLanguages &&
    activeLanguages.sort((a, b) => {
      return b.enabled - a.enabled
    })
  return sortedLanguages
}

export const addThousands = num => !!num && Number(num).toLocaleString()

export const isArrayEmpty = param => {
  return !param && isEmpty(param)
}

export const parseLineBreak = text =>
  text &&
  text.split &&
  text.split(/\r|\n/g).map((line, i, { length }) => (
    <span key={i}>
      {line}
      {i !== length - 1 && <br />}
    </span>
  ))

export const percentualDiff = (value, limit) =>
  100 * Math.abs((limit - value) / ((limit + value) / 2))

export const prepareResultsFields = columns => {
  const COMPOSED_FIELDS = TMDSContextService.isTmview()
    ? {}
    : {
        indicationOfProduct: ['indicationOfProduct', 'indicationOfProductLanguage'],
        thumbnailUrl: ['preferredImageUrl', 'imageUrls'],
        preferredImageUrl: ['preferredImageUrl', 'imageUrls'],
      }

  const composedFieldsKeys = Object.keys(COMPOSED_FIELDS)

  const parsedFields = composedFieldsKeys.reduce((acc, item) => {
    columns.includes(item) && COMPOSED_FIELDS[item].map(composedField => acc.push(composedField))
    return acc
  }, [])

  const parsedColumns = columns.filter(column => !composedFieldsKeys.includes(column))
  return [...parsedColumns, ...parsedFields]
}
