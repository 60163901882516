import { generalDataManager } from '@app/common/dataManagers'

export const changeLanguage = (language, fetchLiterals, fetchGlobals, context) => dispatch =>
  dispatch({
    type: 'CHANGE_LANGUAGE',
    payload: generalDataManager.changeLanguage(language),
    meta: language,
  }).then(() => {
    fetchLiterals()
    fetchGlobals()
  })
