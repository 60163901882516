import {
  TMDSAuthService,
  TMDSGlobalsService,
  TMDSTagsService,
  TMDSToastService,
} from '@app/common/services'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t, Translation } from '@app/common/translationsService'
import { arrayUtils } from '@app/common/utils'
import { ROUTES } from '@app/containers/App/routes'
import { Badge, Dropdown, Link, TooltipTrigger } from '@new-lucentum'
import React, { useCallback, useEffect } from 'react'
import { compose, lifecycle, shallowEqual, shouldUpdate, withHandlers, withState } from 'recompose'

import { IconButton } from '../IconButton'
import { TagDropdownMenu } from './components'

const ButtonTooltip = ({ selectionsNumber }) => {
  const loginUrl = TMDSRouterService.getPreparedRoute(ROUTES.LOGIN)
  const registerUrl = TMDSRouterService.getPreparedRoute(ROUTES.REGISTER)

  return (
    <div style={{ pointerEvents: 'auto' }}>
      <strong>{t('results_button_tags_tooltip_title')}</strong>
      {!TMDSAuthService.isLogged && (
        <div>
          <Translation
            literalKey="login_needed_tooltip"
            params={{
              loginLink: <Link to={loginUrl}>{t('login.key')}</Link>,
              registerLink: <Link to={registerUrl}>{t('registration.key')}</Link>,
            }}
          />
        </div>
      )}
      {TMDSAuthService.isLogged && selectionsNumber < 1 && (
        <div>{t('results_button_tags_tooltip_select')}</div>
      )}
    </div>
  )
}

const onTryCloseDropdown = menuActions => {
  return true
}

const TagButtonComponent = props => {
  const {
    st13List,
    noBadge,
    icon = 'tag',
    borderless,
    isDisabled = false,
    resetOnSubmit = false,
    inheritPosition,
    buttonProps = { isExpanded: false },
  } = props
  const {
    badgeNumber,
    setBadgeNumber,
    preloadedValues,
    setPreloadedValues,
    applyDisabled,
    setApplyDisabled,
    availableTags,
    handleApply,
    preloadOnDemand,
    handlePreloadedValues,
  } = props
  const {
    alertsConfig: { maxAlertsCreation },
  } = TMDSGlobalsService.get()
  const hasNoSt13 = Array.isArray(st13List) && st13List.length < 1
  const hasMoreSt13ThanAllowed = Array.isArray(st13List) && st13List.length > maxAlertsCreation
  const isDisabledCondition =
    !TMDSAuthService.isLogged || hasNoSt13 || hasMoreSt13ThanAllowed || isDisabled

  const handlePreloadOnDemand = useCallback(
    () => {
      if (preloadOnDemand === true) {
        handlePreloadedValues(props)
      } else if (preloadOnDemand === false) {
        setPreloadedValues([])
      }
    },
    [preloadOnDemand]
  )

  useEffect(
    () => {
      handlePreloadOnDemand()
    },
    [preloadOnDemand]
  )

  return (
    <Dropdown
      menuComponent={TagDropdownMenu}
      menuExtraProps={{
        preloadedValues,
        setPreloadedValues,
        st13List,
        setBadgeNumber,
        noBadge,
        applyDisabled,
        setApplyDisabled,
        resetOnSubmit,
        inheritPosition,
        availableTags,
        handleApply,
      }}
      onTryCloseDropdown={onTryCloseDropdown}
    >
      {({ toggleDropdown, isDropdownOpen }) => (
        <TooltipTrigger
          tooltipId={`tag-button-tooltip-${st13List[0]}`}
          place="bottom"
          renderTooltip={!isDropdownOpen && <ButtonTooltip selectionsNumber={st13List.length} />}
          delayHide={500}
        >
          <Badge badgeContent={badgeNumber} customPosition={{ x: '5%', y: '0%' }}>
            <IconButton
              ghost={!borderless}
              alignLeft
              onClick={toggleDropdown}
              disabled={isDisabledCondition}
              icon={icon}
              title={!borderless && t('results_button_tags_tooltip_title')}
              borderless={borderless}
              isExpanded={buttonProps.isExpanded}
              style={borderless && { fontSize: 20 }}
            />
          </Badge>
        </TooltipTrigger>
      )}
    </Dropdown>
  )
}

export const TagButton = compose(
  withState('applyDisabled', 'setApplyDisabled', true),
  withState('preloadedValues', 'setPreloadedValues', false),
  withState('availableTags', 'setAvailableTags', []),
  withState('badgeNumber', 'setBadgeNumber', 0),
  withHandlers({
    handleExternalValues: props => () => {
      const {
        externalValues,
        noBadge,
        setBadgeNumber,
        setPreloadedValues,
        setAvailableTags,
        setApplyDisabled,
      } = props
      if (TMDSAuthService.isLogged) {
        const triggeredValues = externalValues.appliedTags.length
        triggeredValues > 0 && setApplyDisabled(false)

        if (!noBadge) {
          setBadgeNumber(triggeredValues)
        }
        setPreloadedValues(externalValues.appliedTags)
        setAvailableTags(externalValues.availableTags)
      }
    },
    handlePreloadedValues: props => () => {
      const { st13List, noBadge } = props
      const { setPreloadedValues, setBadgeNumber, setAvailableTags, setApplyDisabled } = props
      if (TMDSAuthService.isLogged && Array.isArray(st13List)) {
        if (st13List.length === 1) {
          Promise.all([
            TMDSTagsService.getAppliedTagGroups(st13List),
            TMDSTagsService.getAvailableTagGroups(),
          ]).then(([{ tags: appliedTags }, { tags: availableTags }]) => {
            const triggeredValues = appliedTags.length
            triggeredValues > 0 && setApplyDisabled(false)
            setPreloadedValues(appliedTags)
            setAvailableTags(availableTags)
            if (!noBadge) {
              setBadgeNumber(triggeredValues)
            }
          })
        } else {
          TMDSTagsService.getAvailableTagGroups().then(({ tags: availableTags }) => {
            setAvailableTags(availableTags)
          })
        }
      }
    },
    handleApply: props => (selectedTags, toggleDropdown) => {
      const {
        setPreloadedValues,
        setAvailableTags,
        setBadgeNumber,
        setApplyDisabled,
        availableTags,
        st13List,
        onSetTagsSuccess,
        noBadge,
        preloadedValues,
        resetOnSubmit = false,
        isBulk = false,
      } = props

      const preparedTags = selectedTags.map(selectedTag => {
        return selectedTag.className ? { value: selectedTag.value } : selectedTag
      })

      const applyPromise = TMDSTagsService.setTags(preparedTags, st13List, isBulk)
      applyPromise.then(response => {
        const { tags } = response
        const triggeredValues = tags.length
        const afterApplyValues = resetOnSubmit ? [] : tags
        setPreloadedValues(afterApplyValues)
        const newAvailableTags = arrayUtils.mergeUnique('id', availableTags, tags)
        setAvailableTags(newAvailableTags)
        triggeredValues && setApplyDisabled(false)
        toggleDropdown()
        onSetTagsSuccess && onSetTagsSuccess(tags, st13List)
        if (!noBadge) {
          setBadgeNumber(selectedTags.length)
        }

        const toastMessage = triggeredValues > 0 ? 'tag_selected_edited' : 'tags_removed'

        if (preloadedValues.length > 0) {
          TMDSToastService.showToast(t(toastMessage))
        }
      })
      return applyPromise
    },
  }),
  lifecycle({
    componentDidMount() {
      const { preload, externalValues } = this.props
      const { handlePreloadedValues, handleExternalValues } = this.props
      preload && !externalValues && handlePreloadedValues()
      externalValues && handleExternalValues()
    },
    componentDidUpdate(prevProps) {
      const { externalValues } = this.props
      const { handleExternalValues } = this.props
      if (externalValues && !shallowEqual(prevProps.externalValues, externalValues)) {
        handleExternalValues()
      }
    },
  }),
  shouldUpdate((props, nextProps) => {
    return (
      !shallowEqual(props.buttonProps, nextProps.buttonProps) ||
      !shallowEqual(props.st13List, nextProps.st13List) ||
      !shallowEqual(props.preloadedValues, nextProps.preloadedValues) ||
      !shallowEqual(props.availableTags, nextProps.availableTags) ||
      !shallowEqual(props.externalValues, nextProps.externalValues) ||
      props.badgeNumber !== nextProps.badgeNumber
    )
  })
)(TagButtonComponent)
