import { isIE } from '@app/common/utilities'
import { TypeaheadNew } from '@app/components/Typeahead/TypeaheadNew'
import ViennaCodes from '@app/components/ViennaCodes'
import { DatePicker, Fieldset, Select, TextField } from '@app/new-lucentum'
import styled, { css } from 'styled-components'

export const InputFormLCT = styled(TextField)`
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${({ theme }) => theme.colorGrayAccent} !important;
  color: ${({ theme }) => theme.colorGray};
  font-size: ${({ theme }) => `${theme.fontSizeMedium} !important`};
  height: ${({ theme }) => theme.sizeCuadruple};
  padding: ${({ theme }) => `${theme.sizeSmall} 20px`} !important;

  &:focus {
    box-shadow: none !important;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colorGrayLight};
    font-size: 14px;
  }
`
export const FieldsetLCT = styled(Fieldset)`
  margin-bottom: 16px;
  margin-top: 0px;
  ${({ relative }) => relative && 'position: relative;'}

  .input-block {
    margin-top: 6px;
  }
`

export const TextAreaFormLCT = styled.textarea`
  display: block;
  width: 95%;
  min-height: 200px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colorGrayAccent};
  border-radius: ${({ theme }) => theme.radius};
  color: ${({ theme }) => theme.colorGray};
  padding: ${({ theme }) => `${theme.sizeSmall} ${theme.size}`} !important;
  font-size: ${({ theme }) => theme.fontSize};
  font-family: ${({ theme }) => theme.fontFamily};
  /* &:hover {
    border-bottom-color: ${({ theme }) => theme.colorLight};
  }
  */
  &:focus {
    outline: none !important;
    border-color: ${({ theme }) => theme.colorGrayAccent};
    border-width: 1px;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colorGrayLight};
    font-size: 14px;
  }
`

const inputHome = ({
  theme: {
    color,
    colorGray,
    colorGrayBG,
    colorGrayLight,
    colorLighter,
    line,
    lineThick,
    radius,
    sizeCuadruple,
  },
}) => css`
  & {
    color: ${colorGrayLight};

    input,
    .form-control,
    .Select-control {
      background-color: white;
      border: 0;
      box-shadow: unset !important;
      padding-top: 10px;
      padding-bottom: 10px;
      &:hover,
      &:focus {
        box-shadow: inherit;
        border-color: ${({ theme }) => theme.colorGrayAccent};
      }
    }

    input::-ms-clear {
      display: none;
    }
  }

  &.has-value {
    & .Select-control {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &.Select-value {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  & .datepicker-textfield,
  & .form-control,
  & .Select-control {
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
    border-color: ${({ theme }) => theme.colorGrayAccent};
    padding-left: 20px;
    padding-right: 20px;

    ${({ theme }) =>
      theme.responsive(css`
        padding-left: 10px;
        padding-right: 10px;
      `)};
  }

  & .datepicker-textfield:hover,
  & .form-control:hover,
  & .Select-control:hover {
    border-color: ${({ theme }) => theme.colorGrayAccent};
  }

  & .datepicker-textfield:focus,
  & .form-control.focus,
  &.is-focused .Select-control {
    background-color: white !important;
    box-shadow: inherit;
    border-color: ${({ theme }) => theme.colorGrayAccent} !important;
  }
  & .rbt-input {
    box-shadow: inherit;
  }

  .Select-placeholder {
    ${({ theme }) => theme.baseText};
    color: ${({ theme }) => theme.colorGrayLight};
  }

  .react-select-comp-boolean__dropdown-indicator {
    margin-left: 5px;
  }
`

const removeStyleSelect = ({ theme }) => css`
  .Select-control::after {
    border-color: transparent !important;
  }
  .Select-placeholder {
    padding-top: 9px;
    padding-left: 20px;
    ${theme.responsive(css`
      padding-left: 10px;
    `)}
  }
  .Select-value-label {
    display: flex;
    color: ${theme.colorGray};
    ${theme.baseText};
    padding: 0px 7px;
    padding-left: 10px;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .Select-value-icon {
    line-height: 1.15;
  }
  .Select-input {
    margin-left: ${theme.sizeSmall};
    padding-left: 0;
    padding-right: 0;

    & input {
      padding: 0;
      border: none;
    }
  }
  ${isIE() && '.Select-multi-value-wrapper {display: inline-table;}'};
`

export const SelectLCT = styled(Select)`
  ${inputHome};
  ${removeStyleSelect}
`

export const TypeaheadLCT = styled(TypeaheadNew)`
  ${inputHome};
  .rbt-input {
    padding-left: 20px;

    ::placeholder {
      color: ${({ theme }) => theme.colorGrayLight};
      font-size: 14px;
    }
  }
  .rbt-aux {
    padding-right: 20px;
  }
  .rbt-input-hint {
    display: none;
  }
`

export const DatePickerLCT = styled(DatePicker)`
  ${inputHome};

  & .datepicker-textfield {
    padding-left: 8px;
    ${({ theme }) => theme.baseText};
  }

  .calendar-block {
    width: 180%;
  }
`

export const ViennaCodesLCT = styled(ViennaCodes)`
  ${inputHome};
  ${removeStyleSelect}
`
