import { TMDSAlertsService, TMDSAuthService, TMDSGlobalsService } from '@app/common/services'
import TMDSRouterService from '@app/common/tmdsRouterService'
import { t, Translation } from '@app/common/translationsService'
import { ROUTES } from '@app/containers/App/routes'
import { Badge, Dropdown, Link, TooltipTrigger } from '@new-lucentum'
import React, { useCallback, useEffect } from 'react'
import { compose, lifecycle, shallowEqual, shouldUpdate, withHandlers, withState } from 'recompose'

import { IconButton } from '../IconButton'
import { AlertDropdownMenu } from './components'
import { checkForTriggeredValues, prepareFieldsValues } from './utils'

const ButtonTooltip = ({ selectionsNumber }) => {
  const loginUrl = TMDSRouterService.getPreparedRoute(ROUTES.LOGIN)
  const registerUrl = TMDSRouterService.getPreparedRoute(ROUTES.REGISTER)

  return (
    <div style={{ pointerEvents: 'auto' }}>
      <strong>{t('alert.column.key')}</strong>
      {!TMDSAuthService.isLogged && (
        <div>
          <Translation
            literalKey="login_needed_tooltip"
            params={{
              loginLink: <Link to={loginUrl}>{t('login.key')}</Link>,
              registerLink: <Link to={registerUrl}>{t('registration.key')}</Link>,
            }}
          />
        </div>
      )}
      {TMDSAuthService.isLogged && selectionsNumber < 1 && (
        <div>{t('alerts_button_tooltip_message')}</div>
      )}
    </div>
  )
}

const onTryCloseDropdown = menuActions => {
  //const { submitForm, validateForm } = menuActions
  return true
  /* return new Promise((resolve, reject) => {
    submitForm()
      // Formik bug: https://github.com/jaredpalmer/formik/issues/1580
      // Causing twice validation necessary to check if form
      .then(validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve()
        } else {
          reject()
        }
      })
  }) */
}

const AlertButtonComponent = props => {
  const {
    st13List,
    noBadge,
    icon = 'alert',
    borderless,
    isDisabled = false,
    resetOnSubmit = false,
    resourceData,
    inheritPosition,
    buttonProps = { isExpanded: false },
  } = props
  const {
    badgeNumber,
    setBadgeNumber,
    preloadedValues,
    setPreloadedValues,
    onSetAlertSuccess,
    applyDisabled,
    setApplyDisabled,
    preloadOnDemand,
    handlePreloadedValues,
  } = props
  const {
    alertsConfig: { maxAlertsCreation },
  } = TMDSGlobalsService.get()
  const hasNoSt13 = Array.isArray(st13List) && st13List.length < 1
  const hasMoreSt13ThanAllowed = Array.isArray(st13List) && st13List.length > maxAlertsCreation

  const handlePreloadOnDemand = useCallback(
    () => {
      if (preloadOnDemand === true) {
        handlePreloadedValues(props)
      } else if (preloadOnDemand === false) {
        setPreloadedValues({})
      }
    },
    [preloadOnDemand]
  )

  useEffect(
    () => {
      handlePreloadOnDemand()
    },
    [preloadOnDemand]
  )

  return (
    <Dropdown
      menuComponent={AlertDropdownMenu}
      menuExtraProps={{
        preloadedValues,
        setPreloadedValues,
        st13List,
        setBadgeNumber,
        noBadge,
        applyDisabled,
        setApplyDisabled,
        onSetAlertSuccess,
        resetOnSubmit,
        resourceData,
        inheritPosition,
        preloadOnDemand,
      }}
      onTryCloseDropdown={onTryCloseDropdown}
    >
      {({ toggleDropdown, isDropdownOpen }) => (
        <TooltipTrigger
          tooltipId={`alert-button-tooltip-${st13List[0]}`}
          place="bottom"
          renderTooltip={!isDropdownOpen && <ButtonTooltip selectionsNumber={st13List.length} />}
          delayHide={500}
        >
          <Badge badgeContent={badgeNumber} customPosition={{ x: '5%', y: '0%' }}>
            <IconButton
              ghost={!borderless}
              onClick={toggleDropdown}
              disabled={
                !TMDSAuthService.isLogged || hasNoSt13 || hasMoreSt13ThanAllowed || isDisabled
              }
              icon={icon}
              borderless={borderless}
              title={!borderless && t('alert.column.key')}
              isExpanded={buttonProps.isExpanded}
              style={borderless && { fontSize: 20 }}
            />
          </Badge>
        </TooltipTrigger>
      )}
    </Dropdown>
  )
}

export const AlertButton = compose(
  withState('applyDisabled', 'setApplyDisabled', true),
  withState('preloadedValues', 'setPreloadedValues', false),
  withState('badgeNumber', 'setBadgeNumber', 0),
  withHandlers({
    handleExternalValues: props => () => {
      const {
        externalValues,
        noBadge,
        setBadgeNumber,
        setPreloadedValues,
        setApplyDisabled,
      } = props
      if (TMDSAuthService.isLogged) {
        const preparedValues = prepareFieldsValues(externalValues)
        const triggeredValues = checkForTriggeredValues(preparedValues)
        triggeredValues > 0 && setApplyDisabled(false)

        if (!noBadge) {
          setBadgeNumber(triggeredValues)
        }
        setPreloadedValues && setPreloadedValues(preparedValues)
      }
    },
    handlePreloadedValues: props => () => {
      const { preload, st13List, noBadge } = props
      const { setPreloadedValues, setBadgeNumber, setApplyDisabled } = props
      TMDSAuthService.isLogged &&
        preload &&
        Array.isArray(st13List) &&
        st13List.length === 1 &&
        TMDSAlertsService.getAlertsByIds(st13List).then(response => {
          const alerts = response && response.value

          Array.isArray(alerts) &&
            alerts.forEach(alert => {
              const preparedValues = prepareFieldsValues(alert)
              const triggeredValues = checkForTriggeredValues(preparedValues)
              triggeredValues > 0 && setApplyDisabled(false)
              if (!noBadge) {
                setBadgeNumber(triggeredValues)
              }

              setPreloadedValues && setPreloadedValues(preparedValues)
            })
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { preload, externalValues } = this.props
      const { handlePreloadedValues, handleExternalValues } = this.props
      preload && handlePreloadedValues()
      externalValues && handleExternalValues()
    },
    componentDidUpdate(prevProps) {
      const { externalValues } = this.props
      const { handleExternalValues } = this.props
      if (externalValues && !shallowEqual(prevProps.externalValues, externalValues)) {
        handleExternalValues()
      }
    },
  }),
  shouldUpdate((props, nextProps) => {
    const shouldUpdate =
      !shallowEqual(props.buttonProps, nextProps.buttonProps) ||
      !shallowEqual(props.st13List, nextProps.st13List) ||
      !shallowEqual(props.preloadedValues, nextProps.preloadedValues) ||
      !shallowEqual(props.externalValues, nextProps.externalValues) ||
      props.badgeNumber !== nextProps.badgeNumber
    return shouldUpdate
  })
)(AlertButtonComponent)
