import cn from 'classnames'
import React, { Children, cloneElement, Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { connect } from 'react-redux'

import Button from './Button'
import { Options, Parent } from './styles'

const KEY_ARROW_UP = 38
const KEY_ARROW_DOWN = 40
const KEY_ENTER = 13
const CLOSE = 'close'

const update = (arr, index) => [...arr.slice(0, index), true, ...arr.slice(index + 1)]

class Dropdown extends Component {
  state = { focus: false, index: -1, selected: [] }
  initial = Array((this.props.children || []).length).fill(false)

  static defaultProps = {
    icon: 'chevron-down-small',
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.disableOnClickOutside()
    }, 0)
  }

  handlerClick = e => {
    this.setFocus(!this.state.focus)
    this.props.onClick && this.props.onClick(e)
  }

  handlerKeyDown(e, onDropdown) {
    if (e.keyCode === KEY_ARROW_DOWN && this.state.index < this.state.selected.length - 1) {
      e.preventDefault()
      this.setState(({ index }) => ({
        index: index + 1,
        selected: update(this.initial, index + 1),
      }))
    } else if (e.keyCode === KEY_ARROW_UP && this.state.index > -1) {
      e.preventDefault()
      this.setState(({ index }) => ({
        index: index - 1,
        selected: index - 1 < 0 ? this.initial : update(this.initial, index - 1),
      }))
    } else if (e.keyCode === KEY_ENTER && onDropdown && this.state.index > -1) {
      e.preventDefault()
      onDropdown((this.props.children || [])[this.state.index] || {})
      !this.props.dontCloseOnClick && this.handleClickOutside()
    }
  }

  onReset = () => this.setState({ selected: this.initial, index: -1 })

  setFocus = focusValue => {
    this.props.onFocus && this.props.onFocus(focusValue)
    this.setState({ focus: focusValue }, () => {
      !focusValue ? this.props.disableOnClickOutside() : this.props.enableOnClickOutside()
    })
  }

  handleClickOutside = () => {
    this.setState({ selected: this.initial, index: -1 })
    this.setFocus(false)
    this.props.onClose && this.props.onClose()
  }

  UNSAFE_componentWillMount = () => this.setState({ selected: this.initial })

  renderElement = (onDropdown, dontCloseOnClick) =>
    Children.map(this.props.children, (child, index) =>
      cloneElement(child, {
        activeKey: this.state.selected[index],
        id: index,
        onMouseOver: () => this.onReset(),
        onClick: e => {
          onDropdown && onDropdown((this.props.children || [])[index] || {})
          child.props.onClick && child.props.onClick(e)
          !dontCloseOnClick && this.handleClickOutside()
        },
      })
    )

  render() {
    const {
      altText,
      borderless,
      disabled,
      dontCloseOnClick,
      icon,
      onDropdown,
      primary,
      testId,
      title,
      tooltipId,
      dropdownOptionsProps = {},
      dataTip,
      language,
      ghost,
      className,
      optionsFooter,
      ...props
    } = this.props
    const { focus } = this.state
    const iconProp = ghost ? 'icon' : 'iconAfter'
    return (
      <Parent focus={focus} {...props} ghost={ghost} className={cn('dropdown-parent', className)}>
        <Button
          altText={altText}
          borderless={borderless}
          data-for={tooltipId}
          data-test-id={testId}
          data-tip={dataTip}
          disabled={disabled}
          onClick={this.handlerClick}
          onKeyDown={e => this.handlerKeyDown(e, onDropdown)}
          primary={primary}
          title={title}
          ghost={ghost}
          {...{ [iconProp]: icon }}
          {...props.dropdownButtonProps}
        />
        <Options
          {...testId && { 'data-test-id': `${testId}-options` }}
          focus={focus}
          onClick={e => {
            e.target.name === CLOSE && this.setFocus(false)
            e.target.name === 'forceClose' && this.handleClickOutside()
          }}
          {...dropdownOptionsProps}
          {...props}
          className="dropdown-options"
        >
          <div className="options-options">{this.renderElement(onDropdown, dontCloseOnClick)}</div>

          {optionsFooter && <div className="options-footer">{optionsFooter}</div>}
        </Options>
      </Parent>
    )
  }
}

const mapStateToProps = ({ uiState: { language } }) => ({
  language,
})

export default connect(mapStateToProps)(onClickOutside(Dropdown))
