import TMDSContextService from '@app/common/tmdsContextService'

import { FIELDS } from './fields'

export const INITIAL_SEARCH_CRITERIA_TMVIEW = {
  id: 'C',
  text: 'basic_search_criteria_c',
}

export const FUZZY_PERCENTAGE_LIST = [
  { id: 0.6, text: '60%' },
  { id: 0.7, text: '70%' },
  { id: 0.8, text: '80%' },
  { id: 0.9, text: '90%' },
  { id: 1, text: '100%' },
]

export const INITIAL_SEARCH_CRITERIA_DSVIEW = { id: 'W', text: 'basic_search_criteria_w' }
export const WIPO_VERSION = 'wipo'
export const USPTO_VERSION = 'uspto'
export const JAPAN_VERSION = 'japan'
export const INITIAL_DATE = {
  from: '',
  to: '',
}
export const INITIAL_VALUES_RESULT_TMVIEW = {
  [FIELDS.BASIC_SEARCH]: '',
  [FIELDS.SEARCH_CRITERIA]: INITIAL_SEARCH_CRITERIA_TMVIEW,
}

export const INITIAL_VALUES_TMVIEW = {
  [FIELDS.APPLICANT_NAME]: [],
  [FIELDS.APPLICATION_DATE]: INITIAL_DATE,
  [FIELDS.APPLICATION_NUMBER]: '',
  [FIELDS.BASIC_SEARCH]: '',
  [FIELDS.CURRENTLY_OPPOSABLE]: false,
  [FIELDS.FIELDS_ERRORS]: {},
  [FIELDS.GOODS_AND_SERVICES]: [],
  [FIELDS.IMAGE_SEARCH]: false,
  [FIELDS.OFFICES]: [],
  [FIELDS.REGISTRATION_DATE]: INITIAL_DATE,
  [FIELDS.REGISTRATION_NUMBER]: '',
  [FIELDS.SEARCH_CRITERIA]: INITIAL_SEARCH_CRITERIA_TMVIEW,
  [FIELDS.SENIORITY_CLAIMED]: false,
  [FIELDS.TERRITORIES]: [],
  [FIELDS.TRADEMARK_STATUS]: [],
  [FIELDS.TRADEMARK_TYPE]: [],
  [FIELDS.VIENNA_CODE_VERSION]: WIPO_VERSION,
  [FIELDS.VIENNA_CODE]: [],
}

export const INITIAL_VALUES_DSVIEW = {
  [FIELDS.APPLICATION_NUMBER]: '',
  [FIELDS.DESIGN_NUMBER]: '',
  [FIELDS.DESIGN_STATUS]: [],
  [FIELDS.DESIGNER_NAME]: [],
  [FIELDS.EFFECTIVE_DATE]: INITIAL_DATE,
  [FIELDS.END_DEFERMENT_DATE]: INITIAL_DATE,
  [FIELDS.EXHIBITION_PRIORITY]: '',
  [FIELDS.EXPIRY_DATE]: INITIAL_DATE,
  [FIELDS.FIELDS_ERRORS]: {},
  [FIELDS.FILING_DATE]: INITIAL_DATE,
  [FIELDS.INDICATION_OF_PRODUCT]: '',
  [FIELDS.LOCARNO]: [],
  [FIELDS.LOCARNO_VERSION]: WIPO_VERSION,
  [FIELDS.OFFICES]: [],
  [FIELDS.OWNER_NAME]: [],
  [FIELDS.PREVIOUS_OWNER_NAME]: [],
  [FIELDS.PRIORITY_COUNTRY]: [],
  [FIELDS.PRIORITY_DATE]: INITIAL_DATE,
  [FIELDS.PRIORITY_NUMBER]: '',
  [FIELDS.PUBLICATION_DATE]: INITIAL_DATE,
  [FIELDS.REGISTRATION_DATE]: INITIAL_DATE,
  [FIELDS.REPRESENTATIVE_NAME]: [],
  [FIELDS.SEARCH_CRITERIA]: INITIAL_SEARCH_CRITERIA_DSVIEW,
  [FIELDS.TERRITORIES]: [],
  [FIELDS.VERBAL_ELEMENT]: '',
  [FIELDS.IMAGE_SEARCH]: false,
}

export const getInitialValuesByContext = () => {
  return TMDSContextService.isTmview() ? INITIAL_VALUES_TMVIEW : INITIAL_VALUES_DSVIEW
}
