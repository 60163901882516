import { Checkbox } from '@new-lucentum'
import React from 'react'
import { compose, withHandlers, withState } from 'recompose'

const enhance = compose(
  withState('isActive', 'setIsActive', false),
  withHandlers({
    toggle: ({ isActive, setIsActive, toggleFn }) => () => {
      const nextState = !isActive
      setIsActive(nextState)
      toggleFn(nextState)
    },
  })
)
const ToggleCheckBox = ({ label, toggleFn, ...props }) => {
  const { toggle } = props
  return <Checkbox onClick={toggle} label={label} />
}

export default enhance(ToggleCheckBox)
