import { DESIGNS_FIELDS } from '@app/common/dataManagers/types'
export const recordFields = [
  { label: 'applicationnumber.key', path: DESIGNS_FIELDS.applicationNumber },
  { label: 'tm.details.label.applicationDate.key', path: DESIGNS_FIELDS.applicationDate },
  { label: 'tm.details.label.registrationNumber.key', path: DESIGNS_FIELDS.registrationNumber },
  { label: 'ds.details.label.designNumber.key', path: DESIGNS_FIELDS.designNumber },
  { label: 'ds.details.label.designStatus.key', path: DESIGNS_FIELDS.designStatus },
  { label: 'ds.details.label.designerName.key', path: DESIGNS_FIELDS.designerName },
  { label: 'ds.details.label.effectiveDate.key', path: DESIGNS_FIELDS.effectiveDate, isDate: true },
  {
    label: 'ds.details.label.endOfDefermentDate.key',
    path: DESIGNS_FIELDS.endOfDefermentDate,
    isDate: true,
  },
  { label: 'ds.details.label.exhibitionPriority.key', path: DESIGNS_FIELDS.exhibitionPriority },
  { label: 'ds.details.label.expiryDate.key', path: DESIGNS_FIELDS.expiryDate, isDate: true },
  { label: 'ds.details.label.office.key', path: DESIGNS_FIELDS.office },
  {
    label: 'ds.details.label.previousApplicantName.key',
    path: DESIGNS_FIELDS.previousApplicantName,
  },
  { label: 'ds.details.label.priorityCountry.key', path: DESIGNS_FIELDS.priorityCountry },
  { label: 'ds.details.label.priorityDate.key', path: DESIGNS_FIELDS.priorityDate, isDate: true },
  { label: 'ds.details.label.priorityNumber.key', path: DESIGNS_FIELDS.priorityNumber },
  {
    label: 'ds.details.label.registrationDate.key',
    path: DESIGNS_FIELDS.registrationDate,
    isDate: true,
  },
  {
    label: 'ds.details.label.registrationNumber.key',
    path: DESIGNS_FIELDS.registrationNumber,
    isDate: true,
  },
  { label: 'ds.details.label.representativeName.key', path: DESIGNS_FIELDS.representativeName },
  { label: 'ds.details.label.verbalElement.key', path: DESIGNS_FIELDS.verbalElement },
]
