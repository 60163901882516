import { TMDSTrackingService } from '@app/common/services'
import { addThousands } from '@app/common/utilities'
import { isRTL } from '@new-lucentum/components/ThemeProvider/utils.js'
import PropTypes from 'prop-types'
import React from 'react'

import Icon from '../Icon'
import PaginationStyled, { Div, Link, Tooltip } from './styles'

const LINK_FIRST = 'first'
const LINK_LEFT = 'left'
const LINK_RIGHT = 'right'

const Pagination = ({
  onPageChange,
  currentPage,
  totalPages,
  totalResults,
  pageSize,
  separator,
  maxPageNumberAllowed,
  maxPageNumberReachedMessage,
  ...props
}) => {
  const rangeStart = currentPage * pageSize - pageSize + 1
  const rangeEnd = Math.min(currentPage * pageSize, totalResults)
  const maxPageNumberReached = maxPageNumberAllowed > 0 && currentPage >= maxPageNumberAllowed
  const unsetLinkPointerEvents = maxPageNumberReached && !!maxPageNumberReachedMessage
  const rtl = isRTL({})
  const forward = rtl ? <Icon chevron-left /> : <Icon chevron-right />
  const backward = rtl ? <Icon chevron-left /> : <Icon chevron-left />

  return (
    totalResults > 0 && (
      <PaginationStyled {...props}>
        <Div>
          <Link
            disabled={currentPage === 1}
            onClick={e => {
              !e.preventDefault() && currentPage > 1 && onPageChange(e, LINK_FIRST)
              TMDSTrackingService.trackResultsPaginationClickEvents(LINK_FIRST)
            }}
          >
            <Icon firstpage />
          </Link>
          <Link
            className="link-left"
            disabled={currentPage === 1}
            onClick={e => {
              !e.preventDefault() && currentPage > 1 && onPageChange(e, LINK_LEFT)
              TMDSTrackingService.trackResultsPaginationClickEvents(LINK_LEFT)
            }}
          >
            {backward}
          </Link>
        </Div>
        <span>
          {`${addThousands(rangeStart)}-${addThousands(rangeEnd)} ${separator}
          ${addThousands(totalResults)}`}
        </span>
        <Link
          data-tip={maxPageNumberReachedMessage}
          unsetLinkPointerEvents={unsetLinkPointerEvents}
          disabled={currentPage >= totalPages || maxPageNumberReached}
          onClick={e => {
            !e.preventDefault() &&
              currentPage < totalPages &&
              !maxPageNumberReached &&
              onPageChange(e, LINK_RIGHT)
            TMDSTrackingService.trackResultsPaginationClickEvents(LINK_RIGHT)
          }}
        >
          {forward}
          {maxPageNumberReached && !!maxPageNumberReachedMessage && <Tooltip />}
        </Link>
      </PaginationStyled>
    )
  )
}

Pagination.propTypes = {
  maxPageNumberAllowed: PropTypes.number,
  maxPageNumberReachedMessage: PropTypes.string,
}

export default Pagination
